/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseIdolCard} from 'base/Cards/IdolCard';
import {CardEventActionsBox} from 'components/BaseCardEventActionsBox';

/* assets */
import videocall from 'assets/icons/video-chat.svg';
import meet from 'assets/icons/meet.svg';
import dollar from "assets/icons/dollar.svg";
import {styles} from './styles';


export const UpcomingMeetCard = ({
                                   title,
                                   date,
                                   timeStart,
                                   timeEnd,
                                   price,
                                   meetingPoints,
                                   onLaunch,
                                   onDismiss,
                                   eventType,
                                   upcoming,
                                   primaryBtnStyle = {},
                                   secondaryBtnStyle = {},
                                   primaryBtnSize = "large",
                                   secondaryBtnSize = "large",
                                 }) => {

  const baseProps = {
    title,
    icon: eventType === 'idolcall' ? videocall : meet,
    cardStyles: styles.card,
    headerStyles: styles.header,
    titleHeaderStyles: styles.header_text,
    uppercaseTitle: false,
  };

  const actionProps = {
    approveText: upcoming ? 'Upcoming' : 'Launch',
    approveDisabled: upcoming,
    dismissText: 'Dismiss',
    approveAction: onLaunch,
    dismissAction: onDismiss,
    approveStyles: upcoming ? styles.upcomingButton : styles.button,
    dismissStyles: styles.button,
    approveTextStyles: styles.button_text,
    dismissTextStyles: styles.button_text,
    containerStyles: styles.actions,
    primaryBtnStyle: primaryBtnStyle,
    secondaryBtnStyle: secondaryBtnStyle,
    primaryBtnSize,
    secondaryBtnSize
  };

  const formattedPrice = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(price);

  return (
    <Box sx={styles.container}>
      <BaseIdolCard {...baseProps}>
        <Box sx={styles.content_container}>
          <Box sx={styles.content}>
            <img src={dollar} style={styles.dollar_img}/>
            <Box sx={styles.time_info_container}>
              <Typography sx={styles.time_info}>{date}</Typography>
              <Typography sx={styles.time_info}>{timeStart} - {timeEnd}</Typography>
            </Box>
          </Box>
          <Box sx={styles.price_container}>
            <Typography sx={styles.price}>{formattedPrice}</Typography>
            <Typography sx={styles.points}>{`+${meetingPoints} idp`}</Typography>
          </Box>
        </Box>
        <CardEventActionsBox {...actionProps} />
      </BaseIdolCard>
    </Box>
  )
}

UpcomingMeetCard.propTypes = {
  date: PropTypes.string.isRequired,
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  meetingPoints: PropTypes.number.isRequired,
  eventType: PropTypes.string.isRequired,
  upcoming: PropTypes.bool.isRequired,
};

