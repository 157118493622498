import {InputAdornment} from "@mui/material";
import * as React from "react";
import {StyledTextField} from "./styles";
import SearchIcon from 'assets/icons/Search_Icon.svg';
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
const {white} = colors;

export const SearchBox = (props) => {
  const {
    placeholder,
    handleSearch,
    inputStyles,
    showAdornment = true,
    ...rest
  } = props;

  return <StyledTextField
    placeholder={placeholder}
    fullWidth
    autoComplete='off'
    type={"text"}

    inputProps={{ maxLength: 20 }}
    onKeyDown={e => {
      handleSearch && handleSearch(e.target.value)
    }}
    endAdornment={
      showAdornment && <InputAdornment position="end"><img alt='src' src={SearchIcon} style={styles.icon} /></InputAdornment>
    }
    sx={[styles.input, inputStyles]}
    {...rest}
  />
}


const styles = {
  input: {
    height: pxToRem(38),
    width: pxToRem(289),
  },
  icon: {
    height: pxToRem(16),
    width: pxToRem(16),
  },
}
