export const relatedFilter = {
    all:'all',
    idolizing:"idolizing",
    idolizers:"idolizers",
    following:"following",
    followers:"followers"
}
export const relatedFilterReducer = (state, action) => {
  switch (action.type) {
    case relatedFilter.all: {
      return {
        all: true,
        followers:false,
        idolizers:false,
        idolizing:false
      }
    }
    case relatedFilter.followers: {
      return {
        all: false,
        followers:true,
        idolizers:false
      }
    }
    case relatedFilter.idolizers: {
      return {
        all: false,
        followers:false,
        idolizers:true
      }
    }
    case relatedFilter.idolizing: {
      return {
        all: false,
        followers:false,
        idolizing:true
      }
    }
    case relatedFilter.following: {
      return {
        all: false,
        followers:false,
        following:true
      }
    }
  }

  throw Error('Unknown action.');

}

export const initialRelatedState = {
  all: true,
  followers:false,
  idolizers:false
}
export const initialRelatedStateIdolizer = {
  all: true,
  following:false,
  idolizing:false
}

export const initialCounters = {
  all: 0,
  followers:0,
  idolizers:0
}

export const initialCountersIdolizer = {
  all: 0,
  followers:0,
  idolizing:0
}

export const initialSummary = {
  idolPay: 0,
  idolPoints:0,
  idolizers:0
}
