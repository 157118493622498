/* dependencies */
import React, { memo } from "react";
import PropTypes from "prop-types";

/* mui components */
import {Card, Box} from "@mui/material";

/* components */
import {BaseCardIdolInfo} from "base/Cards/InfoCards/IdolizerInfoCard";

/* assets */
import {styles} from "./styles";
import {PrimaryButton} from "components/PrimaryButton";
import colors from "assets/theme/base/colors";

export const IdolizerCard = memo(
  ({
    avatar,
    idolizerName,
    idolPoints,
    isVerified,
    isIdolizer,
    hasButton,
    userType,
    containerStyles,
    buttonContainerStyles,
    buttonStyles,
    ...rest
  }) => {
    const baseProps = {
      avatar,
      idolizerName,
      idolPoints,
      isVerified,
      isIdolizer,
      centerPoints: true,
      nameStyles: styles.name,
      idpStyles: styles.points,
      pointStyles: styles.points
    };
    const backgroundColor = {
      Idolizing: colors.primary,
      Following: colors.buttons.button2,
    };

    return (
      <Card sx={[styles.card, containerStyles]}>
        <BaseCardIdolInfo {...baseProps} />
        {hasButton && (
          <Box sx={[styles.buttonContainer, buttonContainerStyles]}>
            <PrimaryButton
              mainColor={backgroundColor[userType]}
              text={userType}
              size="small"
              {...buttonStyles}
            />
          </Box>
        )}
      </Card>
    );
  }
);

IdolizerCard.propTypes = {
  avatar: PropTypes.string,
  idolizerName: PropTypes.string,
  idolPoints: PropTypes.number,
  isVerified: PropTypes.bool,
  isIdolizer: PropTypes.bool,
  hasButton: PropTypes.bool,
  userType: PropTypes.string,
};
