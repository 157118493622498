import colors from "assets/theme/base/colors"
const {white, red, transparent, modal} = colors;

export const styles = {
    container: {
        display: 'flex', 
        justifyContent: 'space-between',
    },
    text: {
        color: white.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: '0.15rem',
    },
    meeting_info: {
        color: red.main,
        fontWeight: 'bold',
    },
    paddingButton: '8px',
    dismissButton: {
        background: transparent.main,
        color: modal.buttons.cancel,
        width: '30%'
    },
    buttonText: {
        color:  modal.buttons.cancel,
        fontSize: '1rem'
    },
    placement_info: {
        alignItems: 'end',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '3%',
    }
}