import * as Yup from "yup";

const REQUIRED_FIELD_MSG = "This field is required"

export const feesCardValues = [
  {
    title: 'Minimum tip',
    name: 'MINIMUM_TIP_VALUE',
    label: 'Fee',
    lg: 4
  },
  {
    title: 'Maximum tip',
    name: 'MAXIMUM_TIP_VALUE',
    label: 'Fee',
    lg: 4
  },
  {
    title: 'Minimum subscription',
    name: 'MINIMUM_SUBSCRIPTION_VALUE',
    label: 'Fee',
    lg: 4
  },
  {
    title: 'Maximum subscription',
    name: 'MAXIMUM_SUBSCRIPTION_VALUE',
    label: 'Fee',
    lg: 4
  },
  {
    title: 'Idolizer Verification',
    name: 'FEE_IDOLIZER_VERIFICATION',
    label: 'Fee (USD)',
    lg: 4
  },
  {
    title: 'Subscription',
    name: 'FEE_SUBSCRIPTION',
    label: 'Fee (%)',
    lg: 4
  },
  {
    title: 'Pay Par View',
    name: 'FEE_PER_PER_VIEW',
    label: '(%)',
    lg: 3
  },
  {
    title: 'Tips Fee',
    name: 'FEE_TIP',
    label: '(%)',
    lg: 3
  },
  {
    title: 'Idol Call Fee',
    name: 'FEE_IDOL_CALL',
    label: '(%)',
    lg: 3
  },
  {
    title: 'Idol Meeting Fee',
    name: 'FEE_IDOL_MEETING',
    label: '(%)',
    lg: 3
  },
  {
    title: '',
    name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
    label: 'IdolIdol Fee - Digital Wallet',
    lg: 6
  },
  {
    title: '',
    name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
    label: 'IdolIdol Fee - Digital Wallet',
    lg: 6
  },
  {
    title: '',
    name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
    label: 'IdolIdol Fee - Digital Wallet',
    lg: 6
  },
  {
    title: '',
    name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
    label: 'IdolIdol Fee - Digital Wallet',
    lg: 6
  },
]
export const refundCards = [
  {
    title: 'REFUNDS',
    group: [
      {
        title: 'Idolizer cancels live more than 3 days before',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3',
            label: 'Idol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3',
            label: 'IdolIdol Fee - Payment Method',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3',
            label: 'Idol Fee - Payment Method',
            lg: 6
          },
        ]
      },
      {
        title: 'Idolizer cancels live call after 3 days, before 24H',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2',
            label: 'Idol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2',
            label: 'IdolIdol Fee - Payment Method',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2',
            label: 'Idol Fee - Payment Method',
            lg: 6
          },
        ]
      },
      {
        title: 'Idolizer cancels meet before 24H',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1',
            label: 'Idol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1',
            label: 'IdolIdol Fee - Payment Method',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1',
            label: 'Idol Fee - Payment Method',
            lg: 6
          },
        ]
      },
    ]
  },
  {
    title: 'MEET & GREET',
    group: [
      {
        title: 'Idolizer cancels live more than 5 days before',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            title: '',
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
        ]
      },
      {
        title: 'Idolizer cancels live more than 5 days before, before 24H',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            title: '',
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
        ]
      },
      {
        title: 'Idolizer cancels before 24h',
        fields: [
          {
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            title: '',
            name: 'FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
          {
            name: 'FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1',
            label: 'IdolIdol Fee - Digital Wallet',
            lg: 6
          },
        ]
      }
    ]
  }
]
export const validationSchema = Yup.object({
  MINIMUM_TIP_VALUE: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  MAXIMUM_TIP_VALUE: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  MINIMUM_SUBSCRIPTION_VALUE: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  MAXIMUM_SUBSCRIPTION_VALUE: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOLIZER_VERIFICATION: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_SUBSCRIPTION: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_PER_PER_VIEW: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_TIP: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_CALL: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_MEETING: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),

  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),


  FEE_IDOL_CANCEL_CALL: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  // FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3: Yup.object({
  //   description: Yup.mixed().required(REQUIRED_FIELD_MSG),
  //   key: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  //   value: Yup.mixed().required(REQUIRED_FIELD_MSG).nullable(),
  // }),
});
export const initialValues = (values) => {
  return ({
    MINIMUM_TIP_VALUE: values ? {...values.MINIMUM_TIP_VALUE, value: parseInt(values.MINIMUM_TIP_VALUE.value)} : {},
    MAXIMUM_TIP_VALUE: values ? {...values.MAXIMUM_TIP_VALUE, value: parseInt(values.MAXIMUM_TIP_VALUE.value)} : {},
    MINIMUM_SUBSCRIPTION_VALUE: values ? {...values.MINIMUM_SUBSCRIPTION_VALUE, value: parseInt(values.MINIMUM_SUBSCRIPTION_VALUE.value)}  : {},
    MAXIMUM_SUBSCRIPTION_VALUE: values ? {...values.MAXIMUM_SUBSCRIPTION_VALUE, value: parseInt(values.MAXIMUM_SUBSCRIPTION_VALUE.value)} : {},
    FEE_IDOLIZER_VERIFICATION: values ? {...values.FEE_IDOLIZER_VERIFICATION, value: parseInt(values.FEE_IDOLIZER_VERIFICATION.value)} : {},
    FEE_SUBSCRIPTION: values ? {...values.FEE_SUBSCRIPTION, value: parseInt(values.FEE_SUBSCRIPTION.value)} : {},
    FEE_PER_PER_VIEW: values ? {...values.FEE_PER_PER_VIEW, value: parseInt(values.FEE_PER_PER_VIEW.value)} : {},
    FEE_TIP: values ? {...values.FEE_TIP, value: parseInt(values.FEE_TIP.value)} : {},
    FEE_IDOL_CALL: values ? {...values.FEE_IDOL_CALL, value: parseInt(values.FEE_IDOL_CALL.value)}  : {},
    FEE_IDOL_MEETING: values ? {...values.FEE_IDOL_MEETING, value: parseInt(values.FEE_IDOL_MEETING.value)} : {},
    FEE_IDOL_CANCEL_CALL: values ? {...values.FEE_IDOL_CANCEL_CALL, value: parseInt(values.FEE_IDOL_CANCEL_CALL.value)}  : {},

    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1.value)}  : {},
    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1.value)}  : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2.value)} : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3.value)}  : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1.value)}  : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2.value)}  : {},
    FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3: values ? {...values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3, value: parseInt(values.FEE_ADMIN_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3.value)}  : {},


    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_1.value)}: {},
    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_2.value)}  : {},
    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_CALL_RANGE_3.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_1.value)}: {},
    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_2.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_EXTERNAL_MEET_RANGE_3.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_1.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_2.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_CALL_RANGE_3.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_1.value)}  : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2: values ? {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_2.value)} : {},
    FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3: values ?  {...values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3, value: parseInt(values.FEE_IDOL_IDOLIZER_CANCEL_INTERNAL_MEET_RANGE_3.value)} : {},
  })
}

