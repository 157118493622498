export const styles = {

  logoContainer:{
    width: "44px",
    cursor: "pointer",
    position: "absolute",
    zIndex: 99,
    left: "31px",
    bottom: "142px",
  },

  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none!important",
  },
  containerMobile: {
    marginTop: "20px",
    border: "none!important",
  },
  content: {
    // width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    bottom: "70px",
    justifyContent: "center",
  },
  mainImg: {
    height:"100vh",
    objectFit: "cover",
  },
  mainImgMobile: {
    width:"100vw",
    objectFit: "cover",
  },
  xIcon: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
    position: "fixed",
    zIndex: 99,
    right: 31,
    top: 40,
  },
  buttonsContainer: {
    width: "44px",
    cursor: "pointer",
    position: "absolute",
    zIndex: 99,
    right: "31px",
    bottom: "142px",
  },
  button: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },

  descriptionContainer: {
    position: "absolute",
    zIndex: 99,
    bottom: "95px",
    width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
  descriptionText: {
    color: "#fff",
    fontWeight: 400,
    marginTop:"24px",
    fontSize: "20px",
    lineHeight: "23px",
    textAlign:"center"
  },
  unlockButton: {
    width:"292px",
    height:"40px",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "5px",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
    fontSize: "14px",
    padding: "15px",
    textAlign: "center",
    marginTop: "37px",
    cursor: "pointer",
    "&:hover": {
      color: "#dbdbdb",
      border: "1px solid #dbdbdb",
    }
  },
};
