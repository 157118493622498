/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* mui components */
import {Box, CardHeader, Typography} from '@mui/material';

/* components */
import {UnverifiedIdolAvatar, VerifiedIdolAvatar} from './IdolAvatars';
import {IdolizerText} from './IdolizerText';

/* assets */
import DefaultAvatar from 'assets/images/explore-default.png';
import {styles} from './styles';

export const MessageInfoCard = ({
                                   avatar,
                                   nameDisplayed,
                                   isVerified,
                                   isIdolizer,
                                   isMobile = false,
                                   pointStyles,
                                   infoCardStyles,
                                   pointContainerStyles,
                                   nameStyles,
                                   centerPoints,
                                   avatarStyles,
                                   idpStyles,
                                   avatarContainerStyle,
                                   verifiedIconStyle,
                                   onClick,
                                 }) => {
  let mainStyle = isMobile? styles.mobile_header : styles.header
  mainStyle = {...mainStyle, ...infoCardStyles}
  return (
    <Box sx={styles.container}>
    <CardHeader
      onClick={()=>{
        if(onClick){
          onClick()
        }
      }}
      avatar={
        !isVerified ? (
          <UnverifiedIdolAvatar avatarSrc={avatar} avatarStyles={avatarStyles} isMobile={isMobile} />
        ) : (
          <VerifiedIdolAvatar avatarSrc={avatar}
                              avatarContainerStyle={avatarContainerStyle}
                              verifiedIconStyle={verifiedIconStyle}
                              avatarStyles={avatarStyles} isMobile={isMobile} />
        )
      }
      sx={mainStyle}
      action={isIdolizer && <IdolizerText />}
    />
    <CardHeader
      pointStyles={pointStyles}
      centerPoints={centerPoints}
      containerStyles={pointContainerStyles}
      isMobile={isMobile}
      idpStyles={idpStyles}
      title={<Typography sx={[styles.name, isMobile && styles.mobile_name, nameStyles]}>{nameDisplayed}</Typography>}
    />
    </Box>

  );
};

MessageInfoCard.propTypes = {
  idolizerName: PropTypes.string.isRequired,
  isVerified: PropTypes.bool,
  isIdolizer: PropTypes.bool,
  pointStyles: PropTypes.object,
  nameStyles: PropTypes.object,
}

MessageInfoCard.defaultProps = {
  isVerified: false,
  isIdolizer: false,
  avatar: DefaultAvatar,
}
