/* dependencies */
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseActionsEventModal} from '../BaseActionModalEvent/';
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import {styles} from './styles';

export const CancelMeetingModal = ({
                                     open,
                                     onClose,
                                     avatar,
                                     idolizerName,
                                     idolPoints,
                                     isVerified,
                                     date,
                                     timeStart,
                                     timeEnd,
                                     price,
                                     onCancel,
                                     title = 'CANCEL MEETING',
                                     buttonText = 'CANCEL MEETING',
                                     meetingPlace = 'San Diego, CA',
                                     eventType = 'idolmeet',
                                     modalType = 'cancel'
                                   }) => {

  const baseProps = {
    open,
    onClose,
    title,
    avatar,
    idolizerName,
    idolPoints,
    isVerified,
    date,
    timeStart,
    timeEnd,
    price,
  };

  return (
    <BaseActionsEventModal {...baseProps}>
      {modalType === 'cancel' &&
        <Typography sx={styles.text} variant='h5'>Are you sure you want to cancel this meeting?</Typography>}
      <Box sx={styles.container}>
        <SecondaryButton
          text={buttonText}
          onClick={onCancel}
          padding={styles.paddingButton}
          width={styles.dismissButton.width}
          backgroundColor={styles.dismissButton.background}
          textStyles={styles.buttonText}
          borderColor={styles.dismissButton.color}
        />
        <Box sx={styles.placement_info}>
          <Typography variant='subtitle2' sx={styles.meeting_info}>{meetingPlace}</Typography>
          <Typography variant='subtitle2' sx={styles.meeting_info}>{eventType}</Typography>
        </Box>
      </Box>
    </BaseActionsEventModal>
  )
}

CancelMeetingModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  meetingPlace: PropTypes.string,
  eventType: PropTypes.string,
}

export const MeetingDetailsModal = ({
                                      open,
                                      onClose,
                                      onCancel,
                                      title = 'MEETING DETAILS',
                                      buttonText = 'CLOSE',
                                      meetingPlace = 'San Diego, CA',
                                      eventType = 'details',
                                      modalType = 'details',
                                      item,
                                    }) => {
  if (!item) {
    return
  }
  const {
    id,
    user,
    action_user,
    category,
    message,
    is_read,
    created_at,
    meet
  } = item

  if (!meet) {
    return
  }

  const {
    id_meet,
    name,
    description,
    fee,
    from_user,
    start_datetime,
    end_datetime,
    to_user,
    room,
    meeting_type,
    duration,
    is_approved
  } = meet

  const avatar = action_user.image
  const idolizerName = action_user.username
  const idolPoints = action_user.idol_points
  const isVerified = action_user.is_validated
  const date = created_at
  const timeStart = start_datetime
  const timeEnd = end_datetime
  const price = fee

  const baseProps = {
    open,
    onClose,
    title,
    avatar,
    idolizerName,
    idolPoints,
    isVerified,
    date,
    timeStart,
    timeEnd,
    price,
  };

  return (
    <BaseActionsEventModal {...baseProps}>
      <Box sx={styles.container}>
        <Box sx={styles.placement_info}>
          <Typography variant='subtitle2' sx={styles.meeting_info}>{name}</Typography>
          <Typography variant='subtitle2' sx={styles.meeting_info}>{description}</Typography>
        </Box>
      </Box>
    </BaseActionsEventModal>
  )
}