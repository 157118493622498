import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors'
const {white, red, card} = colors;

export const styles = {
    baseCard: {
        background: card.meeting,
        // height: 260,
        paddingTop: 1,
        paddingBottom: 2,
        width: 300,
    },
    baseCardWide: {
        background: card.meeting,
        // height: 260,
        paddingTop: 1,
        paddingBottom: 2,
    },
    buttonContainer: {
      height: pxToRem(40)
    },
    idolInfo: {
        marginTop: 5.5,
    },
    name: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(26),
        height: pxToRem(26),
    },
    points: {
        fontSize: pxToRem(14),
    },
    header: {
        paddingX: pxToRem(22),
        paddingTop: pxToRem(14)
    },
    headerText: {
        fontSize: pxToRem(14),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        // gap: 2,
        paddingX: pxToRem(22),
        marginTop: pxToRem(10),
    },
    contentWide: {
        display: 'flex',
        flexDirection: 'column',
        // gap: 2,
        paddingRight: pxToRem(40),
        paddingLeft: pxToRem(92),
        marginTop: pxToRem(10),
    },
    general_info: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
    },
    datetime_info_container: {
        alignItems: "center",
        display: "flex",
        gap: 1,
    },
    datetime_info_text: {
        color: white.main,
        fontWeight: 'light',
        fontSize: pxToRem(12),
        lineHeight: pxToRem(14),

    },
    timezoneContainer: {
        alignItems: 'center',
        marginTop: pxToRem(10),
    },
    meetingType: {
        color: red.main,
        paddingRight: '4%',
        fontWeight: 'bold',
        fontSize: '0.9rem',
    },
    actions_container: {
        marginX: pxToRem(22),
        marginTop: pxToRem(16)
    },
    actions_containerWide: {
        marginX: pxToRem(40),
        marginTop: pxToRem(16)
    },
    meeting_price: {
        color: white.main,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(19),
        fontWeight: 400,
    },
};
