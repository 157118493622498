import { Box, Grid } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import { CreateMeetingTypeModal } from "../../../components/CreateMeetingTypeModal";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { SimpleConfirmModal } from "../../../components/SimpleConfirmModal";
import { moneyFormat } from "../../../services/helpers";
import { notifyError, notifySuccess } from "../../../services/notification";
import { humanizeDuration } from "../../../utils/dateUtils";
import { useApi } from "../../../utils/hooks";
import { MeetingTypeCard } from "./components/MeetingTypeCard";
import { LiveCalOptions, MeetAndGreetOptions } from "./constants";

const initialEvent = {
    name: "",
    description: "",
    duration: "15", //LiveCalOptions[0], //durationOptions[0].value,
    fee: 0,
    meeting_type: "LC"//MeetingTypeEnum.liveCall,
  };

export const MeetingTypesPage = observer(() => {
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(initialEvent);
  const [isEdit, setIsEdit] = useState(false);
  const [durationOptions, setDurationOptions] = useState([]);
  const [modalManager, setModalManager] = useState({
    base: false,
    createEvent: false,
    cancelMeeting: false,
    locationDetails: false,
  });
  const api = useApi();

  const getEventTypes = () => {
    setLoading(true)
    api.getEventTypes().then((result) => {
      if (result.kind === "ok") {
        setEvents(result.data)
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
      notifyError()
    });
  }

  const updateEventType = (data) => {
    api.updateEventType(data).then((result) => {
      if (result.kind === "ok") {
        notifySuccess("Meeting type updated successfully");
        setModalManager({...modalManager, createEvent: false});
        getEventTypes();
      } else {
        notifyError("An error ocurred.")
      }
    }).catch(() => {
      notifyError("An error ocurred.")
    });
  }

  const createEventType = (data) => {
    api.createEventType(data).then((result) => {
      if (result.kind === "ok") {
        notifySuccess("Meeting type added successfully");
        setModalManager({...modalManager, createEvent: false});
        getEventTypes();       
      } else {
        notifyError("An error ocurred.")
      }
    }).catch(() => {
      notifyError("An error ocurred.")
    });
  }

  const deleteEventType = (eventId) => {
    api.deleteEventType(eventId).then((result) => {
      if (result.kind === "ok") {
        notifySuccess("Meeting type deleted successfully");
        setModalManager({...modalManager, cancelMeeting: false})
        getEventTypes()
      } else {
        notifyError("An error ocurred.")
      }
    }).catch(() => {
      notifyError("An error ocurred.")
    })
  }
  
  const {base, createEvent, cancelMeeting, locationDetails} = modalManager;  

  const buttonsStyle = {
    approveButtonTextStyles: {
    fontSize: pxToRem(12)
  },
    dismissButtonTextStyles: {
    fontSize: pxToRem(12)
  }
  }

  useEffect(() => {
    getEventTypes();
    setDurationOptions([...LiveCalOptions, ...MeetAndGreetOptions]);
  }, [])

  const parseEvent = (selectedEvent) => {
    let newInitialEvent = {...selectedEvent};
    if (newInitialEvent.duration) {      
      const humanizedDuration = humanizeDuration(newInitialEvent.duration).toUpperCase();
      const newDuration = durationOptions.find((lco, idx) => lco.label.toUpperCase() === humanizedDuration);     
      newInitialEvent.duration = newDuration.value;    
    }
    newInitialEvent.fee = Number(newInitialEvent.fee);
    newInitialEvent.duration = newInitialEvent.duration + "";
    return newInitialEvent;
  }

  return (
    <DashboardLayout isAdmin hideFooter>
      <Box sx={{p:3}}>
        <BasePostCard
          title='MEETING TYPES'
          titleStyles={{fontSize:"48px"}}
          titleContainerStyles={{height:"73px!important", fontWeight:"400"}}
          headerControlsStyle={{height: "40px", mt:"8px" }}
          headerControls={(
            <Box sx={{}}>
              <SecondaryButton text={"ADD NEW MEETING TYPE"}
                                 textStyles={{fontSize: "12px", fontWeight: 700}}
                                 type={"button"}
                                 onClick={()=>{
                                    setIsEdit(false);
                                    setSelectedEvent(initialEvent);
                                    setModalManager({...modalManager, createEvent: true});
                                  }}
                                 />
            </Box>
          )}
        >
          <Box sx={{marginTop: 3}}>
            <Grid container spacing={6}>
              {events.length > 0 && events.map((event, mti) =>
                <Grid item md={6} lg={6} key={"gi-"+mti}>
                  <MeetingTypeCard
                    key={"mtc-"+mti}
                        title={event.name}
                        description={event.description}
                        duration={humanizeDuration(event.duration)}                   
                        price={moneyFormat(event.fee)}
                        meetingType={event.meeting_type}
                        //
                        isIdol={true}
                        onDismiss={() => {
                          setSelectedEvent(event)
                          setModalManager({...modalManager, cancelMeeting: true})
                        }}
                        onApprove={() => {
                         setIsEdit(true);
                         setSelectedEvent(parseEvent(event));
                         setModalManager({...modalManager, createEvent: true})
                        }}
                        {...buttonsStyle}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        {/*  */}
         <CreateMeetingTypeModal
            open={createEvent}
            onClose={() => setModalManager({...modalManager, createEvent: false})}
            onSave={()=>{
              setModalManager({...modalManager, createEvent: false})
              getEventTypes()
            }}
            selectedEvent={selectedEvent}
            submitFunction={!isEdit ? createEventType: updateEventType}
          />

          {selectedEvent && (
            <SimpleConfirmModal
              open={cancelMeeting}
              title={"Cancel Event"}
              message={"Are you sure that you want to cancel this Event"}
              onClose={() => setModalManager({...modalManager, cancelMeeting: false})}
              onConfirm={() => deleteEventType(selectedEvent.id)}
              confirmAction={()=>{}} 
            />
          )}
        </BasePostCard>
      </Box>
    </DashboardLayout>
  )
})


export const styles = {
  tabs:{
    display:"flex",
    flexDirection:"row",
    width:"500px",
    gap:"15px",
    alignItems:"flex-end !important",
    justifyContent:"flex-end !important",
    height:"75px !important",
    marginBottom:"-15px",
    marginRight:"50px"
    // margin:"0px"
  },
  tab:{
    height:"65px !important",
    borderRadius:"10px 10px 0px 0px",
    fontSize:"14px"
  },



};
