import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {CustomFormikTextField} from "../../../components/TextField";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {BasicCheckbox} from "../../../components/BasicCheckbox";
import {FormHelperText, Stack, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CustomAutocompleteFormik} from "../../../components/AutocompleteFormik";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useOnboardingStore} from "../../../utils/hooks";
import {getFormikValidationFields, getFormikValidationBooleanFields, useStores} from "../../../models";
import {notifyError, notifySuccess} from "../../../services/notification";
import {showAllErrors} from "../../../services/helpers";
import {UserTypes} from "../../../constants";
import {IdolizerPaths} from "../../../paths/idolizerPaths";
import {guestRoutes} from "../../../routes/guestRoutes";
import {GuestPaths} from "../../../paths/guestPaths";

const {white} = colors;

const UpdatePassword = observer(() => {
  const onboardingStore = useOnboardingStore()
  const {loginStore} = useStores()
  const api = onboardingStore.environment.api
  const navigate = useNavigate()
  const {state} = useLocation();
  const theme = useTheme();
  const [Genders, setGenders] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const updatePasswordFields = [
    'password',
    'repeat_password',
  ];
  const initialValues = {}
  updatePasswordFields.forEach(field => initialValues[field] = "");

  const sendData = (values) => {
    api.resetPasswordConfirm(values)
      .then(res => {
        console.log("resetPasswordConfirm", res)
        if (res.kind === 'ok') {
          navigate(GuestPaths.login);
          loginStore.setEmailPasswordToken(null);
          loginStore.setPasswordToken(null);
          notifySuccess('Password changed successfully!');         
        } else if (res.kind === 'bad-data') {
          showAllErrors(res?.errors)
        } else {
          notifyError('Something wrong happen!')
        }
      })
      .catch(e => {
        notifyError('Something wrong happen!')
        console.log(e)
      })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getFormikValidationFields(updatePasswordFields),
    onSubmit: (values) => {
      let newValues = {...values}
      newValues.new_password = values.password
      newValues.repeat_new_password = values.repeat_password
      newValues.email = loginStore.email_password_token;
      newValues.token = loginStore.password_token;
      sendData(newValues)
    },
  });


  return (
    <OnboardingLayout titleBold={"UPDATE"} titleNormal={"PASSWORD"} backTo={(state && state.fromURL) || GuestPaths.tokenPassword}>
      <MDBox mt={isMobile ? 4 : 2} width={isMobile ? "100%" : "400px"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>           
            <CustomFormikTextField name="password" formik={formik}/>
            <CustomFormikTextField name="repeat_password" formik={formik}/>           
          </Stack>
          <MDBox mb={4} mt={4}>
            <PrimaryButton type={"submit"} text={"UPDATE"}/>
          </MDBox>
        </form>
      </MDBox>     
    </OnboardingLayout>
  );
});

const styles = {
  defaultText: {
    color: white.main,
    fontSize: 16,
  },
  link: {
    textDecoration: "underline",
    textAlign: "right",
  },
   subtitle:{
    fontSize:14,
    letterSpacing:"0.1em",
    width:"480px",
    color:colors.white.main
  },
  subtitleMobile:{
    fontSize:12,
    letterSpacing:"0.1em",
    width:"100%",
    color:colors.white.main
  },
}

export default UpdatePassword;
