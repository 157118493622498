/* components */
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {RecordCard} from 'components/RecordCard/RecordCard';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

/* utils */
import {IdolTypesInformation} from "constants.js";

/* assets */
import RedLogo from "assets/images/idol_red.png";
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white, date, background, tertiary, modal: {confirm_payment}} = colors;

export const IdolVerificationCard = ({user, navigateToUserVerification}) => {
  
  const recordProps = {
    showOptionsCard: false,
    containerStyles: styles.record,
    contentContainerStyles: styles.contents_container,
    cardContentsStyle: styles.contents,
    cardThirdContentStyles: styles.third_content,
    iconStyles: styles.third_content_icon
  };

  const styleProps = {
    infoCardStyles: styles.info_card,
    avatarStyles: styles.avatar,
    nameStyles: styles.name,
    pointStyles: styles.points,
    idpStyles: styles.points,
  };

  return (
    <RecordCard
      {...recordProps}
      cardFirstContent={
        <BaseCardIdolInfo
          {...{
            idolPoints: user?.idol_points,
            idolizerName: `${user.first_name || user?.username} ${user.last_name ?? ''}`,
            avatar: user?.image,
          }}
          {...styleProps}
        />
      }
      cardSecondContent={
        <MDBox sx={styles.second_content}>
          <MDBox sx={styles.idol_type_info}>
            <img src={IdolTypesInformation[user?.idol_type]?.logo} style={styles.idol_type_icon} />
            <MDTypography sx={styles.idol_type_initials}>
              {IdolTypesInformation[user?.idol_type]?.name}
            </MDTypography>
            <MDBox style={styles.divider}>|</MDBox>
          </MDBox>
          <MDTypography sx={styles.email}>{user?.email}</MDTypography>
        </MDBox>
      }
      iconCard={RedLogo}
      cardThirdContent={
        <MDBox sx={styles.view_box} onClick={() => navigateToUserVerification(user?.id)}>
          <MDTypography
            sx={styles.view_box_text} 
          >
            View
          </MDTypography>
        </MDBox>
      }
    />
  );
}

const styles = {
  record: {
    marginTop: 0,
  },
  contents_container: {
    display: "grid",
    gridTemplateColumns: "1.5fr 0.1fr 1.5fr",
    justifyContent: "flex-start",
  },
  contents: {
    justifyContent: "flex-start",
    width: "100%",
  },
  info_card: {
    padding: 0,
  },
  name: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  points: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  avatar: {
    width: pxToRem(49),
    height: pxToRem(49),
  },
  second_content: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  idol_type_info: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: `${pxToRem(34)} ${pxToRem(30)} 1fr`,
    gap: pxToRem(24),
  },
  idol_type_icon: {
    height: pxToRem(35),
    width: pxToRem(35),
  },
  idol_type_initials: {
    color: white.main,
    fontSize: pxToRem(14),
    fontWeight: 400,
    marginTop: pxToRem(7),
  },
  divider: {
    backgroundColor: background.default,
    color: background.default,
    width: "8px",
    height: "52px",
    borderRadius: "8px",
    opacity: 0.2,
  },
  email: {
    color: date,
    fontSize: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
    width: "100%",
  },
  third_content: {
    background: confirm_payment.background,
    gap: pxToRem(28),
    justifyContent: "initial",
    width: pxToRem(250),
    padding: `${pxToRem(22)} ${pxToRem(12)}`,
    left: 0,
  },
  third_content_icon: {
    order: -1,
  },
  view_box: {
    alignItems: "center",
    background: tertiary.main,
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: pxToRem(32),
    justifyContent: "center",
    padding: `${pxToRem(8)} ${pxToRem(20)}`,
    width: pxToRem(122),
  },
  view_box_text: {
    color: white.main,
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
};