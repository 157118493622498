import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import { ReportUserForm } from "./blockUserForm";
import { useIsMobile, useUserStore } from "../../utils/hooks";
import { ReportUserDesktop } from "./reportUserDesktop";
import { ReportUserMobile } from "./reportUserMobile";


export const ReportUserPage = memo((props) => {
  const location = useLocation();
  const [data, setData] = useState()
  const isMobile = useIsMobile()
  const userStore = useUserStore()


  useEffect(() => {
    setData({
      name: `${userStore.first_name} ${userStore.last_name}`,
      email: userStore.email,
      type: location.state.type.value,
      typeDescription: location.state.type.description,
      userId: location.state.userId,
      fromURL: location.state.fromURL,
      username: location.state.username
    })
  },[])




  return (
    <BaseProtectedComponentPage loginRequired={false}>
      {data && (
        isMobile? <ReportUserMobile data={data} /> : <ReportUserDesktop data={data} />
      )}
    </BaseProtectedComponentPage>);
})



