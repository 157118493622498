import PriceBaseCard from "../../../base/Cards/PriceBaseCard/PriceBaseCard";
import { useApi, useIsMobile } from "../../../utils/hooks";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import IdolMarca from "../../../assets/icons/idolmarca.svg";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { FieldArray, Form, Formik } from "formik";
import { BundlesMonths, PromotionDays } from "./constants";
import { ItemRow } from "./ItemRow";
import { notifyError, notifySuccess } from "../../../services/notification";
import { PriceItemSchema } from "./schemas";
import { moneyFormat } from "../../../services/helpers";
import { SectionRow } from "./sectionRow";


export const IdolPromotionsSection = ({monthlyCharge})=>{
  const api = useApi()
  const isMobile = useIsMobile()
  const [InitialValues, setInitialValues] = useState(null)

  const getPromotions = ()=>{
    api.getPromotions().then(response => {
      if (response.kind === "ok") {
        const bundles = []
        if (response.data){
          bundles.push({
            duration:response.data.days_of_validity,
            discount_percentage:parseInt(response.data.discount_percentage),
            id:response.data.id
          })
        }
        setInitialValues({items:bundles})
      }else{
        setInitialValues({items:[]})
      }
    }).catch(reason => {
      console.log(reason)
    })
  }
  const removePromotion = ({id, remove, index})=>{
      api.deletePromotion(id).then(response => {
        if (response.kind === "ok") {
          notifySuccess("Deleted successfully")
          remove(index)
        }else{
          notifyError("Error deleting this item")
        }
      }).catch(reason => {
        console.log(reason)
      })
  }

  const savePromotion = (data)=>{
    api.createPromotion(data).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Saved successfully")
        getPromotions()
      }else{
        notifyError("Error saving this item")
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  useEffect(() => {
    getPromotions()
  },[])

  const getFooterText = (item)=>{
    return `Monthly subscription for 
    ${moneyFormat(monthlyCharge * (1 - (item.discount_percentage/100)))}`
  }


  return (
    <PriceBaseCard title='Your Idol Promotions' isMobile={isMobile}>
      {InitialValues && (
        <Formik
          validateOnChange={false}
          initialValues={InitialValues}
          validationSchema={PriceItemSchema}
          onSubmit={async (values) => {
            if(values.items.length > 0){
              savePromotion({
                days_of_validity: parseInt(values.items[0].duration),
                discount_percentage: parseInt(values.items[0].discount_percentage)
              })
            }
          }}
        >
          {({ values ,setFieldValue, errors, touched}) => (
            <Form style={styles.form}>
              <Typography sx={styles.subtitle}>
                Offer a subscription discount and the expiry time of the offer.
              </Typography>
              <Box sx={{ flexGrow: 1 ,display:"flex", flexDirection:"row"}}>
                <FieldArray name={`items`} style={{width:"100%"}}>
                  {( {  remove, push }) => (
                    <Box style={{display:"flex", flexDirection:"column", width:"100%"}}>
                      {values.items.map((item, index) => (
                        <ItemRow durationOptions={PromotionDays}
                                 footerText={getFooterText(item)}
                                 isMobile={isMobile}
                                 item={item}
                                 touched={touched}
                                 errors={errors}
                                 remove={(index)=>removePromotion({index, id: values.items[index].id, remove})}
                                 onChange={({value, fieldName})=>{
                                   setFieldValue(fieldName, value)
                                 }}
                                 index={index}
                                 key={"item-"+index}/>)
                      )}
                      <SectionRow isMobile={isMobile}
                                  text={"+ ADD PROMOTION"}
                                  push={push}
                                  showSaveButton={values.items.length > 0}
                                  showAddButton={values.items.length === 0}/>
                    </Box>
                  )}
                </FieldArray>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </PriceBaseCard>
  )
}
