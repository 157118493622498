import Login from "../pages/auth/login";
import Logout from "../pages/auth/logout";
import { AdminPaths } from "../paths/adminPaths";
import { AllUsersAdminPage } from "../pages/admin/all-users";
import { GuestPaths } from "../paths/guestPaths";
import { GetStarted } from "pages/onboarding";
import { LiveChatPage } from "pages/admin/live-chat";
import { AdminIdolPointsPage } from "pages/admin/idol-points";
import { MeetingTypesPage } from "pages/admin/meeting-types";
import { AdminEarningsPage } from "pages/admin/earnings";
import { AdminReportedUsersPage } from "pages/admin/reported-users"
import { AdminTransactionsPage } from "pages/admin/transactions"
import { AdminReservedNamesPage } from "pages/admin/reserve-name";
import {AdminImportedContentPage} from "pages/admin/imported-content";
import {AdminApproveContentPage} from "pages/admin/imported-content/approveContent";
import {AdminFeedbackPage} from "pages/admin/feedback";
import {AdminFeesPage} from "../pages/admin/fees";
import {AdminListVerificationPage} from "pages/admin/verification";
import {AdminManageUserVerificationPage} from "pages/admin/verification/manage-verification";
import {Redirecting} from "../pages/redirecting";

export const adminRoutes = {
  login: {
    path: AdminPaths.login,
    element: <Login/>,
  },
  liveChat:{
    path: AdminPaths.liveChat,
    element:<LiveChatPage />
  },
  home:{
    path: AdminPaths.home,
    element:<AllUsersAdminPage/>
  },
  idolPoints:{
    path: AdminPaths.idolPoints,
    element:<AdminIdolPointsPage />
  },
   meetingTypes:{
    path: AdminPaths.meetingTypes,
    element:<MeetingTypesPage />
  },
  logout: {
    path: AdminPaths.logout,
    element: <Logout/>,
  },
  getStarted: {
    path: GuestPaths.getStarted,
    element: <GetStarted/>,
  },
  earnings: {
    path: AdminPaths.earnings,
    element: <AdminEarningsPage/>,
  },
  fees: {
    path: AdminPaths.fees,
    element: <AdminFeesPage/>,
  },
  reservedNames: {
    path: AdminPaths.reservedNames,
    element: <AdminReservedNamesPage />,
  },
  importedContent: {
    path: AdminPaths.importedContent,
    element: <AdminImportedContentPage />,
  },
  reportedUsers: {
    path: AdminPaths.reportedUsers,
    element: <AdminReportedUsersPage />,
  },
  transactions: {
    path: AdminPaths.transactions,
    element: <AdminTransactionsPage />,
  },
  approveContent: {
    path: AdminPaths.approveContent,
    element: <AdminApproveContentPage />,
  },
  feedback: {
    path: AdminPaths.feedback,
    element: <AdminFeedbackPage />,
  },
  verification: {
    path: AdminPaths.verification,
    element: <AdminListVerificationPage />,
  },
  manageVerification: {
    path: AdminPaths.manageVerification,
    element: <AdminManageUserVerificationPage />
  }
}

