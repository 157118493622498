import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem';
const {transparent, modal} = colors;

export const styles = {
    actions: {
        alignItems: 'center',
        display: 'flex',
    },
    logo_container: {
        paddingLeft:"15px",
        alignItems: 'center',
        display: 'flex',
    },
    logo_img: {
        width: pxToRem(30),
        height: '100%',
    },
    buttons_container: {
        display: 'flex',
        gap: pxToRem(13),
        justifyContent: 'space-between',
        width: '100%',
    },
    buttons_containerWide: {
        display: 'flex',
        gap: pxToRem(32),
        justifyContent: 'space-between',
        width: '100%',
    },
    button: {
        width: '50%',
        height: pxToRem(40),
        padding: 0,
    },
    dismissButton: {
        background: transparent.main,
        color: modal.buttons.cancel,
    },
}
