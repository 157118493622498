import {Box, Typography} from "@mui/material";
import {BaseCardIdolInfo} from "../../base/Cards/InfoCards/IdolizerInfoCard";
import moment from "moment";
import {NotificationCategories} from "../../constants";

export const NotificationsMobile = ({
                                      item,
                                      setSelected,
                                      openModal,
                                      avatar,
                                      idolizerName,
                                      idolPoints,
                                      isVerified,
                                      category,
                                      tipAmount,
                                      dateTime,
                                    }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.idolContainer}>
        <BaseCardIdolInfo idolizerName={idolizerName} isMobile={true} isVerified={isVerified} avatar={avatar}
                          idolPoints={idolPoints}/>
        <Typography sx={styles.dateTime}>{moment().format("D MMM YYYY - HH:mm A").toUpperCase()}</Typography>
      </Box>
      <Box sx={styles.messageContainer}>
        <Typography sx={styles.message}>{NotificationCategories[category].message}</Typography>
        <Box sx={styles.priceContainer}>
          {tipAmount && <Typography sx={styles.amount}>${tipAmount}</Typography>}
          {category === "meet" &&
            <Typography sx={styles.view} onClick={() => [setSelected(item), openModal()]}>View</Typography>}
        </Box>
      </Box>
      <Box component={"img"} src={NotificationCategories[category].icon} alt={"dollar-icon"} sx={styles.icon}/>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#162334",
    borderRadius: "5px",
    position: "relative",
    marginBottom: "12px"
  },
  idolContainer: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    padding: "14px"
  },
  dateTime: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#767E88",
    paddingBottom: "18px",
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0px 0px 20px 85px",
    gap: "40px"
  },
  message: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#fff",
    width: "150px"
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    gap: "18px",
    width: "65px",
  },
  amount: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#fff",
  },
  view: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#D9243D",
    width: "40px"
  },
  icon: {
    position: "absolute",
    right: 12,
    bottom: 24,
    width: "18px",
    height: "18px"
  }
}