/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {collapseIcon, collapseIconBox, collapseItem, collapseText,} from "base/Sidenav/styles/sidenavCollapse";
import ArrowDownIcon from "assets/icons/arrow_down.svg";
import ArrowUpIcon from "assets/icons/arrow_up.svg";
// Material Dashboard 2 PRO React context
import {useMaterialUIController} from "context";

function SidenavCollapse({icon, name, children, active, isMobile, noCollapse, open, ...rest}) {
  const [controller] = useMaterialUIController();
  const {miniSidenav, darkMode} = controller;

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme) =>
            collapseItem(theme, {active, darkMode})
          }
        >
          <ListItemIcon
            sx={(theme) => collapseIconBox(theme, {darkMode, isMobile})}>
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, {active, isMobile})}>icon</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            style={{fontWeight: "bolder"}}
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                active,
              })
            }
          />
          {isMobile && children && (
            <img src={open ? ArrowUpIcon : ArrowDownIcon} style={{marginRight: "10px"}}/>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
