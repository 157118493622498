import React, { memo, useEffect, useMemo, useState } from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import EventsIcon from "assets/icons/events.svg";
import { Box } from "@mui/material";
import { PrimaryButton } from "../../../components/PrimaryButton";
import colors from "assets/theme/base/colors"
import { useApi, useInfiniteScroll } from "../../../utils/hooks";
import { generateMoment } from "../../../utils/dateUtils";
import { IdolMeetingCard } from "../../../components/IdolMeetingCard";
import Grid from "@mui/material/Grid";
import moment from "moment";
import pxToRem from "assets/theme/functions/pxToRem";
import { useStores } from "models";
import { notifyError, notifySuccess } from "../../../services/notification";
import { moneyFormat } from "../../../services/helpers";
import { ConfirmPaymentModal } from "../../../components/ConfirmPaymentModal";
import InfiniteScroll from "react-infinite-scroll-component";

const MeetingsCard = memo((props) => {
  const {loginStore } = useStores();
  const isIdol = loginStore.isIdol;
  const [Upcoming, setUpcoming] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [selectedMeeting, setSelectedMeeting] = useState(null)
  const api = useApi()
  const apiName = useMemo(() => isIdol? "rejectMeetingRequest" : "cancelMeetingRequest",[isIdol])

  const offset = new Date().getTimezoneOffset();

  const onApiCall = ({page})=>{
    let data = {page}
    return api.getPendingMeetings(data)
  }

  const {
    items,
    fetchNextPage,
    hasNextPage,
    extraData,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "upcoming"})


  const cardProps = {
    approveButtonTextStyles: styles.button,
    dismissButtonTextStyles: styles.button,
  };

  const IdolButtons = [
    <PrimaryButton text={'PENDING'}
                   size='small'
                   onClick={()=>{
                     setUpcoming(false)
                   }}
                   mainColor={!Upcoming?colors.primary: colors.buttons.button2}
                   width={"130px"}
    />,
    <PrimaryButton text={'UPCOMING'}
                   size='small'
                   onClick={()=>{
                     setUpcoming(true)
                   }}
                   mainColor={Upcoming?colors.primary: colors.buttons.button2}
                   width={"130px"}
    />
  ]



  const approveMeeting = (meet)=>{
    api.approveMeeting(meet.id).then(response => {
      if(response.kind === "ok"){
        refetch({refetchPage: (page, index) => index === 0})
        notifySuccess(` Approved successfully` )
      } else{
        let message = "Error Approving this meeting"
        if(response.errors?.error) message = response.errors.error
        notifyError(message)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError(`Error Canceling this meeting`)
    })
  }

  const dismiss = ()=>{
    api[apiName](selectedMeeting.id).then(response => {
      if(response.kind === "ok"){
        notifySuccess(`Dismissed successfully` )
        setSelectedMeeting(null)
      } else{
        notifyError(`There is something wrong`)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError(`There is something wrong`)
    })
  }

  return (

    <BasePostCard
      title='Meetings'
      icon={EventsIcon}
      headerControls={(
        <Box sx={{display:"flex", flexDirection:"row", width:"500px", gap:"15px", justifyContent:"flex-end"}}>
          { isIdol ?? IdolButtons}
        </Box>
      )}>
      {selectedMeeting && (
        <ConfirmPaymentModal
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          confirmAction={()=>dismiss()}
          title='CONFIRM'
          confirmLabel={"CONFIRM"}
          message={`Are you sure to ${isIdol?"REJECT":"CANCEL"} this Meet request`}
        />
      )}


      <Box sx={{width:"100%", height:"450px", paddingTop:"10px",  overflowY:"scroll"}}>
        <InfiniteScroll

          dataLength={items?.length}
          next={() => {
            if (!isFetchingNextPage) {
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={null}
        >


          <Grid container spacing={6}>
            {items.map((meeting, index)=> {
              const user = loginStore.id === meeting.from_user.id? meeting.to_user : meeting.from_user
              return (
                <Grid item md={6} lg={6} key={"Grid-"+index}>
                  <IdolMeetingCard
                    key={"IdolMeetingCard-"+index}
                    title={meeting.name}
                    avatar={user.image}
                    idolizerName={user.username}
                    idolPoints={user.idol_points}
                    isVerified={user.is_validated}
                    price={moneyFormat(meeting.fee)}
                    // date={'23 MAR 2022 ' }
                    date={ moment(meeting.start_datetime).format("D MMM YYYY").toUpperCase()}
                    timeStart={moment(meeting.start_datetime).format('HH:mm A')}
                    timeEnd={moment(meeting.end_datetime).format('HH:mm A')}
                    timeZone={meeting.location}
                    meetingType={meeting.meeting_type}
                    isIdol={isIdol}
                    onDismiss={() => {
                      setSelectedMeeting(meeting)
                      setOpenConfirmModal(true)
                    }}
                    onApprove={() => {
                      approveMeeting(meeting)
                    }}
                    {...cardProps}
                  />
                </Grid>)
            })}
          </Grid>
        </InfiniteScroll>
      </Box>
    </BasePostCard>

  );
})

MeetingsCard.propTypes = {

};

const styles = {

  button: {
    fontSize: pxToRem(12)
  }
}

export default MeetingsCard;
