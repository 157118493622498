import {primaryButtonStyle, primaryButtonTextStyle} from "./styles";
import MDTypography from "../MDTypography";
import {Button} from "@mui/material";
import MDBox from "../MDBox";


export const PrimaryButtonWithCounter = (props) => {
  const {text, counter, onClick, size, fontWeight, buttonStyle, mainColor, textStyles, ...rest} = props
  // size = large, small, medium
  return (
    <Button
      {...rest}
      onClick={onClick}
      size={size}
      sx={[(theme) => primaryButtonStyle(theme, buttonStyle, size, mainColor), {...rest}]}
    >
      <MDBox sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", width:"100%"}}>
      <MDTypography sx={[(theme) => primaryButtonTextStyle(theme, size, fontWeight), {...textStyles}]}>
        {text}
      </MDTypography>
      <MDTypography sx={[(theme) => primaryButtonTextStyle(theme, size, fontWeight), {...textStyles}]}>
        {counter}
      </MDTypography>
      </MDBox>
    </Button>
  )
}
