import { Box } from "@mui/material";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import React from "react";
import { AddNewPost } from "./AddNewPost";
import {useIsMobile} from "../../utils/hooks";
import {AddNewPostMobile} from "./AddNewPostMobile";


export const AddNewPostPage = () => {
  const isMobile = useIsMobile();
  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {isMobile ? <AddNewPostMobile /> :  <AddNewPost />}
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
};
