import moment from "moment";
import Avatar1 from "assets/images/avatar.png"
import Avatar2 from "assets/images/alex.png"

export const ROOM_LIST = [
  {
    id:1,
    user:{
      name:"ScarletRubby",
      idol_points:1234,
      is_validated: true,
      avatar: Avatar1
    },
    messages:[
      {
        from: "ScarletRubby",
        to: "admin",
        message:"Congue nisi cursus malesuada tempus sed nunc velit cursus.",
        date: moment().format("hh:mm a")
      },
      {
        from: "ScarletRubby",
        to: "admin",
        message:"Congue nisi cursus malesuada tempus sed nunc velit cursus.",
        date: moment().format("hh:mm a")
      },
      {
        to: "ScarletRubby",
        from: "admin",
        message:"Hello, my name is Mark, how can I help you today?",
        date: moment().format("hh:mm a")
      }
    ]
  },

  {
    id:2,
    user:{
      name:"PerryCollier",
      idol_points:1234,
      is_validated: false,
      avatar: Avatar2
    },
    messages:[
      {
        to: "admin",
        from: "PerryCollier",
        message:"Congue nisi cursus malesuada tempus sed nunc velit cursus.",
        date: moment().format("hh:mm a")
      },
      {
        to: "PerryCollier",
        from: "admin",
        message:"Hello, my name is Mark, how can I help you today?",
        date: moment().format("hh:mm a")
      }
    ]
  }

]
