import { CardForm } from "../components/CardForm";
import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/TextField";
import { SaveButton } from "../components/SaveButton";
import React, { Fragment } from "react";
import { globalStyles } from "../globalStyles";
import * as Yup from "yup";
import { requiredMessage, TabName } from "../constants";
import { Form, Formik } from "formik";
import { TextField } from "../components/TextField";

const idolValidationSchema = Yup.object({
  POINTS_APPROVE_IDOL_CALL: Yup.string().required(requiredMessage),
  POINTS_APPROVE_IDOL_MEET: Yup.string().required(requiredMessage),
  POINTS_COMPLETE_IDOL_CALL: Yup.string().required(requiredMessage),
  POINTS_COMPLETE_IDOL_MEET: Yup.string().required(requiredMessage),
});

const idolizerValidationSchema = Yup.object({
  POINTS_BOOK_IDOL_CALL: Yup.string().required(requiredMessage),
  POINTS_BOOK_IDOL_MEET: Yup.string().required(requiredMessage),
  POINTS_COMPLETE_IDOL_CALL: Yup.string().required(requiredMessage),
  POINTS_COMPLETE_IDOL_MEET: Yup.string().required(requiredMessage),
});

export const MeetingForm = ({config, onSave, tab})=>{
  return (
    <CardForm  title={"Meeting"}>
      <Formik
        initialValues={{
          POINTS_APPROVE_IDOL_CALL: config?.POINTS_APPROVE_IDOL_CALL ?? 0,
          POINTS_APPROVE_IDOL_MEET: config?.POINTS_APPROVE_IDOL_MEET ?? 0,
          POINTS_BOOK_IDOL_CALL: config?.POINTS_BOOK_IDOL_CALL ?? 0,
          POINTS_BOOK_IDOL_MEET: config?.POINTS_BOOK_IDOL_MEET ?? 0,
          POINTS_COMPLETE_IDOL_CALL: config?.POINTS_COMPLETE_IDOL_CALL ?? 0,
          POINTS_COMPLETE_IDOL_MEET: config?.POINTS_COMPLETE_IDOL_MEET ?? 0,
        }}
        validationSchema={tab === TabName.idolizers? idolizerValidationSchema : idolValidationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Fragment>
            <Box component={Form} sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
              {tab === TabName.idols ? (
                <Fragment>
                  <Box sx={{...globalStyles.container}} >
                    <Box sx={globalStyles.inputGroup}>
                      <TextField name={"POINTS_APPROVE_IDOL_CALL"}
                                 inputLabel={"Create an Idolcall"}
                                 sx={globalStyles.input}
                                 max={1000000}
                                 type={"number"}
                                 customEndAdornmentText={"idp"} />

                      <CustomTextField
                        inputLabel={"Deleted "}
                        sx={globalStyles.input}
                        value={"-"+props.values.POINTS_APPROVE_IDOL_CALL}
                        customEndAdornmentText={"idp"} />
                    </Box>
                  </Box>

                  <Box sx={{...globalStyles.container, mt:2}} >
                    <Box sx={globalStyles.inputGroup}>
                      <TextField name={"POINTS_APPROVE_IDOL_MEET"}
                                 inputLabel={"Create an Idolmeet"}
                                 sx={globalStyles.input}
                                 max={1000000}
                                 type={"number"}
                                 customEndAdornmentText={"idp"} />

                      <CustomTextField
                        inputLabel={"Deleted "}
                        sx={globalStyles.input}
                        value={"-"+props.values.POINTS_APPROVE_IDOL_MEET}
                        customEndAdornmentText={"idp"} />
                    </Box>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <Box sx={{...globalStyles.container}} >
                    <Box sx={globalStyles.inputGroup}>
                      <TextField name={"POINTS_BOOK_IDOL_CALL"}
                                 inputLabel={"Book an Idolcall"}
                                 sx={globalStyles.input}
                                 max={1000000}
                                 type={"number"}
                                 customEndAdornmentText={"idp"} />

                      <CustomTextField
                        inputLabel={"Give to Idol "}
                        sx={globalStyles.input}
                        value={props.values.POINTS_BOOK_IDOL_CALL}
                        customEndAdornmentText={"idp"} />


                      <CustomTextField
                        inputLabel={"Unbook"}
                        sx={globalStyles.input}
                        value={"-"+props.values.POINTS_BOOK_IDOL_CALL}
                        customEndAdornmentText={"idp"} />
                    </Box>
                  </Box>

                  <Box sx={{...globalStyles.container, mt:2}} >
                    <Box sx={globalStyles.inputGroup}>
                      <TextField name={"POINTS_BOOK_IDOL_MEET"}
                                 inputLabel={"Create an Idolmeet"}
                                 sx={globalStyles.input}
                                 max={1000000}
                                 type={"number"}
                                 customEndAdornmentText={"idp"} />

                      <CustomTextField
                        inputLabel={"Give to Idol "}
                        sx={globalStyles.input}
                        value={props.values.POINTS_BOOK_IDOL_MEET}
                        customEndAdornmentText={"idp"} />


                      <CustomTextField
                        inputLabel={"Unbook"}
                        sx={globalStyles.input}
                        value={"-"+props.values.POINTS_BOOK_IDOL_MEET}
                        customEndAdornmentText={"idp"} />
                    </Box>
                  </Box>
                </Fragment>
              )}

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_COMPLETE_IDOL_CALL"}
                             inputLabel={"Complete an Idolcall"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Give to Idol"}
                      sx={globalStyles.input}
                      customEndAdornmentText={"idp"}
                      value={props.values.POINTS_COMPLETE_IDOL_CALL}
                    />
                  )}

                </Box>
              </Box>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_COMPLETE_IDOL_MEET"}
                             inputLabel={"Complete an Idolmeet"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Give to Idol"}
                      sx={globalStyles.input}
                      customEndAdornmentText={"idp"}
                      value={props.values.POINTS_COMPLETE_IDOL_MEET}
                    />
                  )}
                </Box>
              </Box>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <CustomTextField
                    inputLabel={"Cancel Booked Idolcall "}
                    sx={globalStyles.input}
                    name="name"
                    value={"-$ value"}
                  />
                </Box>
              </Box>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <CustomTextField
                    inputLabel={"Cancel Booked Idolmeet "}
                    sx={globalStyles.input}
                    name="name"
                    value={"-$ value"}
                  />
                </Box>

                <Box sx={{mt:2}}>
                  <SaveButton />
                </Box>
              </Box>
            </Box>
          </Fragment>
        )}
      </Formik>

    </CardForm>
  )
}

