import colors from "assets/theme/base/colors";
const {white, red} = colors;

export const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        // paddingRight: '15%',
    },
    title: {
        color: white.main,
        fontWeight: 'normal',
    },
    container: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '1rem',
    },
    meeting_info: {
        color: red.main,
        fontWeight: 'bold',
    },
    error: {
        color: white.main,
        fontSize: 14,
        paddingBottom: 2
    },
    button: {
        width: '50%',
    },
    placement_info: {
        alignItems: 'end',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '3%',
    },
    footer_container: {
        width: '55%',
    },
    footer: {
        fontSize: '0.8rem'
    },
};
