import MDBox from "../../../components/MDBox";
import { setLayout, useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import * as React from "react";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { OnboardingLayout } from "../../../components/OnboardingLayout";
import RoundCheck from "../../../components/RoundCheck";
import { useOnboardingStore } from "../../../utils/hooks";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LayoutNames, UserTypes } from "../../../constants";
import { GuestPaths } from "../../../paths/guestPaths";

export const WhoAreYou = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {pathname} = useLocation();
  const [Idol, setIdol] = useState(true)
  const onboardingStore = useOnboardingStore()
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');

  const goToNext = () => {
    onboardingStore.setData(Idol ? {user_type: UserTypes.Idol} : {user_type: UserTypes.Idolizer})
    navigate(Idol ? GuestPaths.selectIdolType : GuestPaths.signup,
      {state: {fromURL: GuestPaths.whoAreYou}}
    )
  }

  useEffect(() => {
    setLayout(dispatch, LayoutNames.onboarding);
  }, [pathname]);

  useEffect(() => {
    onboardingStore.setLastURLVisited(GuestPaths.whoAreYou)
    setIdol(onboardingStore.user_type === UserTypes.Idol)
  }, [])

  return (
    <OnboardingLayout titleBold={"WHO"} titleNormal={"ARE YOU?"} backTo={GuestPaths.getStarted}>
      <Box style={{
        display: isMobile ? "block" : "unset"
      }}>
        <MDBox style={{width: "100%"}}>
          <MDBox mt={10} mx={isMobile ? 2 : ""}>
            <RoundCheck label="I am an idol" checked={Idol} onClick={(e) => {
              setIdol(true)
            }} size={""}/>
            <RoundCheck label="I am an idolizer" checked={!Idol} onClick={() => setIdol(false)} size={""}/>
          </MDBox>
          <MDBox sx={isMobile ? isMobileH ? {
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 20,
            paddingX: "30px"
          } : {marginTop: 10, marginBottom: 3} : {marginTop: 15}}>
            <PrimaryButton text={"CONTINUE"} fontWeight={700} onClick={() => goToNext()}/>
          </MDBox>
        </MDBox>
      </Box>
    </OnboardingLayout>
  )
}
