// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {notifyError} from "services/notification";
import {useApi} from "utils/hooks";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import PDFIcon from "assets/images/pdf_icon.png";

export const DownloadFile = ({data}) => {

  const api = useApi();
  const downloadDocument = () => {
    api.getUserDocument(data,{responseType: 'arraybuffer'})
      .then((result) => {
        if (result.kind === 'ok') {
          let contentType = { type: 'image/png' }
          if (data?.document_type === 'w8_w9_file' || data?.document_type === 'consent_form') {
            contentType = { type: 'application/pdf' }
          }
          if (result.kind === "ok") {
            const file = new Blob([result.data], contentType)
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL, '_blank')
          } else {
            notifyError();
          }
        } else {
          notifyError();
        }
      }).catch((error) => {
      notifyError(error.message)
    });
  }
  return (
    <MDBox
      width={pxToRem(514)}
      height={pxToRem(53)}
      borderRadius='10px'
      p={`${pxToRem(9)} ${pxToRem(15)}`}
      sx={({palette: {dark}}) => ({background: dark.secondary, cursor: 'pointer'})}
      onClick={downloadDocument}
    >
      <MDBox display='flex' alignItems='center' gap={pxToRem(28)}>
        <MDBox component='img' src={PDFIcon} alt='file' />
        <MDTypography color='light' fontWeight='regular' size='sm' sx={{textDecorationLine: 'underline'}}>
          File name and Link to open the ID
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}