import {Box} from "@mui/system";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import colors from "../../../assets/theme/base/colors";
import Dollar from 'assets/icons/dollar_bg_dark.svg';
import {Typography} from "@mui/material";

const card = colors


export const WithdrawalsMobile = ({withdrawals}) => {
  return (
    <Box>
      <Box sx={{...styles.container}}>
        {withdrawals.map((withdrawal) => (
        <Box sx={{...styles.withdrawalContainer}}>
          <Box sx={{...styles.innerContainer}}>
            <Box sx={{...styles.imageContainer}}>
              <img src={Dollar} style={{...styles.image}}/>
            </Box>
            <Box sx={{...styles.descriptionContainer}}>
              <Typography sx={{...styles.descriptionText}}>
                Funds Withdrawn
              </Typography>
            </Box>
          </Box>
          <Box sx={{...styles.infoContainer}}>
            <Box sx={{...styles.dateContainer}}>
              <Typography sx={{...styles.dateText}}>
                {withdrawal.date}
              </Typography>
            </Box>
            <Box sx={{...styles.amountContainer}}>
              <Typography sx={{...styles.amountText}}>
                ${withdrawal.amount}.00
              </Typography>
            </Box>
          </Box>
        </Box>
        ))}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    background: card.background.container,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '380px',
    overflow: 'auto',
    gap: pxToRem(12),
    marginTop: '1rem',
    padding: `${pxToRem(12)} ${pxToRem(9)}`,
  },
  withdrawalContainer: {
    background: card.dark.secondary,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingX: "12px"
  },
  image: {
    height: "27px",
    width: "27px",
  },
  imageContainer: {
    marginY: "23px",
    height: "27px",
    width: "27px",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    maxWidth: "90px",
    paddingY: "15px"
  },
  descriptionText: {
    color: "#C4C4C4",
    fontSize: pxToRem(18),
    lineHeight: pxToRem(21),
    fontWeight: 400,
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  dateContainer: {
    paddingTop: "10px",
  },
  dateText: {
    color: colors.white.main,
    opacity: "0.6",
    fontSize: "10px",
    lineHeight: "12px",
    textAlign: "right",
  },
  amountContainer: {
    paddingTop: "10px",
  },
  amountText: {
    color: colors.white.main,
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "right",

  },
}
