import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';

/* components */
import {BaseModal} from "base/BaseModal";
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import {desktopStyles, mobileStyles} from './styles';

export const ConfirmPaymentModal = ({
                                      open,
                                      onClose,
                                      title,
                                      confirmAction,
                                      message,
                                      confirmLabel,
                                      cancelLabel,
                                      cancelAction,
                                      children = null,
                                      disabled = false,
                                      hideCancel = false
                                    }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = isMobile ? mobileStyles : desktopStyles

  const baseProps = {
    open,
    onClose,
    title,
    containerStyles: styles.container,
    modalWidth: styles.container.width,
    headerStyles: styles.header,
    ...styles.content_container,
  };

  return (
    <BaseModal {...baseProps} >
      <Typography
        sx={[styles.message]}
      >
        {message}
      </Typography>
      {children ?? null}
      <Box sx={styles.actions_container}>
        <SecondaryButton
          text={confirmLabel}
          disabled={disabled}
          onClick={confirmAction}
          textStyles={styles.confirm_text}
          {...styles.confirm}
        />
        {!hideCancel &&
          <Typography
            onClick={cancelAction ?? onClose}
            sx={styles.cancel}
          >
            {cancelLabel}
          </Typography>
        }
      </Box>
    </BaseModal>
  );
}

ConfirmPaymentModal.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

ConfirmPaymentModal.defaultProps = {
  title: 'CONFIRM',
  message: 'Please confirm this action',
  confirmLabel: 'PAY',
  cancelLabel: 'CANCEL',
};
