import { CardForm } from "../components/CardForm";
import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/TextField";
import { SaveButton } from "../components/SaveButton";
import React, { Fragment } from "react";
import { globalStyles } from "../globalStyles";
import * as Yup from "yup";
import { requiredMessage } from "../constants";
import { Form, Formik } from "formik";
import { TextField } from "../components/TextField";

const validationSchema = Yup.object({
  POINTS_NEW_IDOL_PROFILE: Yup.string().required(requiredMessage),
  POINTS_NEW_IDOLIZER_PROFILE: Yup.string().required(requiredMessage),
});

export const ReferralForm = ({config, onSave})=>{
  return (
    <CardForm  title={"Referral program"}>
      <Formik
        initialValues={{
          POINTS_NEW_IDOL_PROFILE: config?.POINTS_NEW_IDOL_PROFILE ?? 0,
          POINTS_NEW_IDOLIZER_PROFILE: config?.POINTS_NEW_IDOLIZER_PROFILE ?? 0,
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Fragment>
            <Box component={Form} sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_NEW_IDOL_PROFILE"}
                             inputLabel={"Onboard a new Idol"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={"Give to New Profile "}
                    sx={globalStyles.input}
                    name="name"
                    value={props.values.POINTS_NEW_IDOL_PROFILE}
                    customEndAdornmentText={"idp"} />

                </Box>
              </Box>


              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_NEW_IDOLIZER_PROFILE"}
                             inputLabel={"Onboard a new Idol"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={"Give to New Profile "}
                    sx={globalStyles.input}
                    name="name"
                    value={props.values.POINTS_NEW_IDOLIZER_PROFILE}
                    customEndAdornmentText={"idp"} />

                </Box>

                <Box sx={{mt:2}}>
                  <SaveButton />
                </Box>
              </Box>

            </Box>
          </Fragment>
        )}
      </Formik>

    </CardForm>
  )
}

