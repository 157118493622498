export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  price: {
    backgroundColor: "#3F4857",
    border: "none",
    borderRadius: "5px",
    fontSize: "18px",
    height: "40px",
    color: "#fff",
    padding: "2px 0px 2px 32px",
    margin: "5px 0px 5px 0px",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
        fontSize: "18px",
        marginTop: "5px",
      },
    },
  },
  mobilePrice: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    padding: "3px 0px 2px 32px",
  },
  inputContainer: {
    position: "relative",
  },
  tipsText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#fff",
    lineHeight: "140%",
  },
  dollar: {
    position: "absolute",
    zIndex: 99,
    bottom: 18,
    left: 20,
  },
  tipsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  saveButtonContainer: {
    display: "flex",
    gap: "11px",
  },
  buttonStyles: {
    minHeight: '32px!important',
    height: "32px",
    minWidth: '120px',
    width: '120px',
  },
};
