import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import { BlockedUsers } from "./BlockedUsers";
import {useStores} from "../../../models";
import {useEffect} from "react";

export const BlockedUsersPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("BLOCKED USERS")
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>
        <DashboardLayout>
          <BlockedUsers/>
        </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
