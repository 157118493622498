import { Box } from "@mui/material";
import { PrimaryButton } from "../../../../components/PrimaryButton";
import IdolMarca from "../../../../assets/icons/idolmarca.svg";
import React, { memo } from "react";

export const SaveButton = memo(({onClick})=>{
  return (
    <Box sx={styles.saveButtonContainer}>
      <PrimaryButton
        buttonStyle={{ ...styles.buttonStyles, width: "132px" }}
        textStyles={{ fontSize: "12px" }}
        text="SAVE"
        type={"submit"}
      />
      <Box component="img" src={IdolMarca} alt="idol-marca" width="30px"/>
    </Box>
  )
})

const styles = {
  saveButtonContainer: {
    display: "flex",
    gap: "11px",
  },
  buttonStyles: {
    minHeight: '32px!important',
    height: "32px",
    minWidth: '120px',
    width: '120px',
  },
}
