import colors from "assets/theme/base/colors";

export const styles = {
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "",
        maxWidth: '522px',
        marginTop: 5,
    },
    actions: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        paddingBottom: 5,
        alignSelf: 'center'
    },
    skip: {
        cursor: 'pointer',
        color: colors.white.main,
        textAlign: 'center',
    },
};
