import { Box, Typography } from "@mui/material";
import * as React from "react";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { BaseModal } from "../../../base/BaseModal";
import colors from "../../../assets/theme/base/colors";
import { moneyFormat } from "../../../services/helpers";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import moment from "moment";
import ClockIcon from "assets/icons/clock.svg";
import SmallLogo from "assets/icons/small_logo.svg";
import { MeetingTypeEnum } from "../../../utils/constants";
import { BaseCardIdolInfo } from "../../../base/Cards/InfoCards/IdolizerInfoCard";

const Type = {
  LC:"IDOLCALL",
  MG:"MEET & GREET"
}

export const MeetRequestedModal = ({ open, onClose, onConfirm, meet})=>{
  if(meet === null){
    return null
  }
  return (
    <BaseModal
      open={open}
      title={`${meet.to_user.username.toUpperCase()} | ${Type[meet.meeting_type]}`}
      containerStyles={{width:"660px", height:"386px",  backgroundColor:colors.background.default}}
      onClose={() => onClose()}
    >
      <Box style={styles.body}>
        <Box style={styles.section1}>
          <BaseCardIdolInfo
            avatar={meet.to_user.image}
            isVerified={meet.to_user.is_validated}
            idolPoints={meet.to_user.idol_points}
            idolizerName={meet.to_user.username}/>

          <Box style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"flex-start", gap:"20px"}}>
            <Typography style={styles.idolText}>{meet.meeting_type.codename === MeetingTypeEnum.liveCall? "idolcall":"idolmeet"}</Typography>
            <Box component="img" sx={{width:"55px", height:"55px"}} src={SmallLogo} />
          </Box>
        </Box>
        <Box style={styles.section2}>
          <Typography style={styles.description}>
            You scheduled a {meet.meeting_type.codename === MeetingTypeEnum.liveCall? "video call":"meet"} with {meet.to_user.username}
          </Typography>

          <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
            <Box style={styles.datetimeContainer}>
              <Box component="img" sx={styles.clockIcon} src={ClockIcon} />
              <Box style={{display:"flex", flexDirection:"column", width:200}}>
                <Typography style={styles.datetimeText}>
                  {moment(meet.from_time).format("DD MMMM Y").toUpperCase()}
                </Typography>
                <Typography style={styles.datetimeText}>
                  {moment(meet.start_datetime).format("HH:mm A")} -
                  {moment(meet.end_datetime).format("HH:mm A")}
                </Typography>
              </Box>
            </Box>
            <Box style={{height:"100%", paddingTop:"20px"}}>
              <Typography style={styles.priceText}>
                {moneyFormat(meet.fee)}
              </Typography>
            </Box>

          </Box>

          <Box style={styles.status}>
            <Typography style={{fontWeight:600, fontSize:16}}>
              Status:
            </Typography>
            <Typography style={{fontWeight:400, fontSize:16, marginLeft:4}}>
              Please wait until {meet.to_user.username} confirms the scheduled time
            </Typography>
          </Box>

          <SecondaryButton
            buttonStyle={{...styles.button}}
            text="OK"
            onClick={()=>onConfirm()}
            padding={0.5}
          />
        </Box>
      </Box>
    </BaseModal>
  )
}

const styles = {
  description:{
    fontWeight:400,
    fontSize:16,
    marginLeft:4,
    width:"100%",
    textAlign:"left"
  },
  idolText:{
    color: colors.primary.main,
    letterSpacing: "0.1em",
    fontSize:12
  },
  clockIcon: {
    width: "14px",
    height: "14px",
    marginRight: "10px",
  },
  priceText:{
    fontSize:22,
    fontWeight:400
  },
  datetimeText:{
    fontWeight:300,
    fontSize:14
  },
  datetimeContainer:{
    display:"flex",
    alignItems:"center",
    flexDirection:"row",
    justifyContent:"flex-start",
    width:200
  },
  section1:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
    padding:"0 24px 0 24px"
  },
  section2:{
    padding:"0px 54px 0px 54px",
    display:"flex",
    flexDirection:"column",
    alignItems:"center"
  },
  status:{
    marginTop:pxToRem(10),
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start"
  },
  text:{
    fontSize:16,
    textAlign:"center",
    letterSpacing:"0.1em",
    color:colors.white.main,
    width:"292px",
  },
  button:{
    width:"292px",
    height:pxToRem(40),
    marginTop:pxToRem(26),
    marginBottom:pxToRem(0)
  },
  body:{
    marginTop:"-25px",
    display:"flex",
    flexDirection:"column",
    height:"100%",
    justifyContent:"space-between",
    alignItems:"center",
  }
}
