import {InputAdornment} from "@mui/material";
import * as React from "react";
import {StyledTextField} from "./styles";
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import SearchIcon from "assets/icons/searchLeft.svg";
import CloseIcon from "assets/icons/X.svg";
const {white} = colors;
export const SearchBoxMobile = (props) => {
  const {
    placeholder,
    handleSearch,
    inputStyles,
    value,
    ...rest
  } = props;

  return (
    <StyledTextField
      placeholder={placeholder}
      fullWidth
      autoComplete='off'
      type={"text"}
      value={value}
      inputProps={{ maxLength: 20 }}
      onChange={e => {
        handleSearch(e.target.value)
      }}
      startAdornment={
        <InputAdornment position="start">
          <img src={SearchIcon} style={styles.icon} />
        </InputAdornment>
      }
      endAdornment={   <InputAdornment sx={{visibility: value? "visible": "hidden"}} position="end">
        <img src={CloseIcon} style={styles.icon} onClick={()=>handleSearch("")}/>
      </InputAdornment>}
      sx={[styles.input, inputStyles]}
      {...rest}
    />
  )
}


const styles = {
  input: {
    height: pxToRem(38),
    width: pxToRem(289),
  },
  icon: {
    height: pxToRem(16),
    width: pxToRem(16),
  },
}
