import {Box} from "@mui/material";
import {boxStyles, containerStyles, labelStyles} from "./styles";
import MDTypography from "../MDTypography";
import {useRef, useState} from "react";

const UploadDocument = ({
                          text,
                          onClick,
                          src,
                          alt,
                          onlyPDF = false
                        }) => {
  const inputRef = useRef(`input_${Math.random().toString().substring(2, 15)}`)
  const [fileName, setFileName] = useState(text)
  const accept = onlyPDF ? "application/pdf" : "application/pdf,image/*"

  const handleDocumentUpload = (event) => {
    if (event.target.files && event.target.files.length) {
      setFileName(event.target.files[0].name)
      onClick(event.target.files[0]);
    } else {
      setFileName(text)
    }
  }

  return <Box sx={containerStyles}>
    <input type="file" accept={accept} id={inputRef.current} style={{display: "none"}}
           onChange={handleDocumentUpload}/>
    <label htmlFor={inputRef.current}>
      <Box padding="1rem" sx={boxStyles}>
        <img src={src} alt={alt}/>
        <MDTypography color={"text"} sx={labelStyles}>{fileName}</MDTypography>
      </Box>
    </label>
  </Box>
}

export default UploadDocument;
