import rgba from "../../../../assets/theme/functions/rgba";
import colors from "../../../../assets/theme/base/colors";

export const desktopStyles = {
  step2Main:{},
  buttonTextCancel:{
    color: rgba(colors.white.main, 0.25),
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700,
    "&:hover": {
      color: rgba(colors.white.main, 0.25),
    },
  },
  buttonContinue:{
    width:"290px",
    height:"40px",
  },
  buttonCancel:{
    width:"145px",
    height:"40px",
    border:`2px solid ${rgba(colors.white.main, 0.25)}`,
    "&:hover": {
      border:`2px solid  ${rgba(colors.white.main, 0.25)}`,
    },
  },
  buttonText:{
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700
  },
  buttonsContainer:{
    marginTop:"70px",
    width:"100%",
    gap:"20px",
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignContent:"center",
  },
  timeZoneContainer:{
    display:"flex",
    flexDirection:"row",
    width:"420px",
    alignItems:"center",
    marginLeft:"90px",
    justifyContent:"flex-start",
    marginBottom:"20px"
  },
  calendarContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    width:"420px",
    background: colors.darkBlue["700"],
    borderRadius: "16px",
    marginTop: "24px"
  },
  timeZone:{
    fontSize:14,
    fontWeight:300,
    color:"white"
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  listContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignContent:"center",
    marginTop:"34px",
    // paddingLeft:"20%",
    // paddingRight:"20%",
    // gap:"30px"
  },
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"20px",
    fontSize:20,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1"
  }
}

export const mobileStyles = {
  step2Main:{
    marginBottom:"200px"
  },
  buttonTextCancel:{
    color: rgba(colors.white.main, 0.25),
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700,
    "&:hover": {
      color: rgba(colors.white.main, 0.25),
    },
  },
  buttonContinue:{
    width:"290px",
    height:"40px",
  },
  buttonCancel:{
    width:"145px",
    height:"40px",
    border:`2px solid ${rgba(colors.white.main, 0.25)}`,
    "&:hover": {
      border:`2px solid  ${rgba(colors.white.main, 0.25)}`,
    },
  },
  buttonText:{
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700
  },
  buttonsContainer:{
    // marginBottom:"50px",
    width:"100%",
    gap:"20px",
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignContent:"center",
    height:"200px"
  },
  timeZoneContainer:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    width:"90vw",
    marginLeft: "16px",
    justifyContent:"flex-start",
    marginBottom:"20px"
  },
  calendarContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    background: colors.darkBlue["700"],
    borderRadius: "16px",
    marginTop: "24px"
  },
  timeZone:{
    fontSize:14,
    fontWeight:300,
    color:"white"
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  listContainer:{
    padding:"0px 16px 0px 16px",
    width:"100%",
    alignContent:"center",
    marginTop:"34px",
    gap:"30px"
  },
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"20px",
    fontSize:20,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1"
  }
}

export const datePickerCss = `
.rdp {
    --rdp-cell-size: 44px;
    --rdp-accent-color: var(--primary);
    --rdp-background-color: var(--dark-blue-950);
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}
.rdp-day{
    border-radius: 6px!important;
}
.rdp-cell{
    padding: 2px!important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected, .rdp-nav_button_next, .rdp-nav_button_previous) {
    background-color: #1b2737!important;
    border: 2px solid white;
}

.rdp-button:not([disabled]):not(.rdp-day_selected, .rdp-nav_button_next, .rdp-nav_button_previous) {
    background-color: var(--primary)!important;
}

.rdp-button.rdp-day_selected {
    background-color: var(--primary)!important;
    border: 2px solid white;
}

.rdp-button {
    font-size: 18px;
    color:white;
}

.rdp-day_selected {
    background: var(--primary);
    color: white;
    border: 1px solid white;
}

.rdp-head_cell{
    opacity: 0.2;
}

.fc-day-today{
    border: 1px solid white!important;
}

.rdp-caption_label{
    color:white;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.1em;
    border-radius: 8px;
    background: var(--dark-blue-700);
    padding-left: 12px;
    padding-right: 12px;

}
.rdp-head_cell{
    color: white;
}
`;
