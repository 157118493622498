import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import {useIsMobile} from "../../utils/hooks";

const {white} = colors;
export const getMainBoxStyle = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: pxToRem(22)
  }
}

export const getIsValidatedUserBoxStyle = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: pxToRem(20),
  }
}

export const getProfilePictureStyle = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

export const getUserNameStyle = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: pxToRem(64),
  }
}

export const getNameStyle = (theme) => {
  return {
    fontSize: pxToRem(64),
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: pxToRem(80),
    color: white.main,
  }
}
export const getNameMobileStyle = (theme) => {
  return {
    fontSize: pxToRem(36),
    fontWeight: 300,
    lineHeight: pxToRem(49),
    color: white.main,
    lineBreak: 'anywhere',
  }
}

export const styles = {
  user_info: {},
  color_white: {
    color: white.main,
  },
  avatar_img_container: {
    width: pxToRem(147),
    height: pxToRem(147),
  },
  img: {
    width: pxToRem(40),
    marginLeft: pxToRem(10),
  },
  imgMobile: {
    width: pxToRem(20),
    marginLeft: pxToRem(10),
  },
  avatar_img: {
    borderRadius: '256px'
  },
  name_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    fontSize: pxToRem(64),
    fontWeight: 300,
    lineHeight: pxToRem(87),
  },
  is_verified_container: {
    width: pxToRem(40),
    height: pxToRem(40),
  },
  input: {
    width: 0
  },
  upload_photo_text: {
    fontSize: pxToRem(14),
    cursor: 'pointer',
    textAlign: 'center',
  },
};
