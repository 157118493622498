import { OnboardingLayout } from "components/OnboardingLayout";
import { CustomFormikTextField } from "components/TextField";
import MDBox from "components/MDBox";
import { PrimaryButton } from "components/PrimaryButton";
import MDTypography from "components/MDTypography";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useOnboardingStore } from "../../../utils/hooks";
import { useTheme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import { useFormik } from "formik";
import {
  getFirstPhaseFromFieldList,
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
  useStores,
} from "../../../models";
import { useEffect, useState } from "react";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { notifyError } from "../../../services/notification";
import { UserTypes } from "../../../constants";
import {setFormikErrorsFromResponse, showAllErrors} from "../../../services/helpers";
import { GuestPaths } from "../../../paths/guestPaths";
import {parsePhoneNumber} from "libphonenumber-js";

const subtitle = `Twitter can be used to verify an individual's identity. For example, if someone provides their twitter
                  handle, Twitter can compare the account information to the information provided and confirm that they 
                  are who they say they are. This helps to ensure that people are communicating with the correct person
                   online and that their information is safe and secure.`

export const OnboardingIdolTwitter = () => {
  const onboardingStore = useOnboardingStore()
  const api = onboardingStore.environment.api
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');
  const [userTypes, setUserTypes] = useState([])
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolTwitter)

  const sendData = (data, files, goToNextPage) => {
    api.signUpIdolMultipart(data, files)
      .then(res => {
        if (res.kind === 'ok') {
          goToNextPage()
        } else if (res.kind === 'bad-data') {
          const errors = res?.errors
          showAllErrors(errors)
          navigate(getFirstPhaseFromFieldList(Object.keys(errors)), {state: {formikErrors: errors}})
        } else {
          notifyError('Something wrong happen!')
        }
      })
      .catch(e => {
        notifyError('Something wrong happen!')
        console.log(e)
      })
  }

  const getOnboardingData = (goToNextPage) => {
    const user_type = userTypes.find(it => it.codename === UserTypes.Idol)
    const data = onboardingStore.getIdolDataForBackend()
    const files = onboardingStore.getIdolFilesForBackend()
    data.user_type = user_type.id
    sendData(data, files, goToNextPage)
  }

  const submitValues = (values) => {
    onboardingStore.setData({...values})
    getOnboardingData(() => navigate(NextPage))
  }

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(OnboardingPhases.Twitter, onboardingStore),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Twitter, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  const skip = () => {
    getOnboardingData(() => navigate(GuestPaths.idolReady))
  }

  const getUserTypes = () => {
    api.getUserTypes().then(res => {
      if (res.kind === 'ok') {
        setUserTypes(res.data.results)
      } else {
        notifyError("Could not get data from server")
      }
    })
  }

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage);
    getUserTypes()
  }, [])

  return (
    <OnboardingLayout
      titleBold='IDOL'
      titleNormal='VERIFY'
      subtitle={subtitle}
      progressBarPercent={"100%"}
      backTo={PreviousPage}
      subtitleStyle={{width: "430px"}}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box paddingTop={pxToRem(24)}>
          <CustomFormikTextField name="verify_twitter_username" formik={formik}/>
        </Box>
        {isMobile ?
          isMobileH ?
            <MDBox sx={{position: "absolute", right: 0, left: 0, bottom: 20, paddingX: "30px"}}>
              <MDBox mb={2} mt={4}>
                <MDTypography
                  sx={styles.skip}
                  onClick={skip}
                >
                  SKIP
                </MDTypography>
              </MDBox>
              <MDBox mb={4}>
                <PrimaryButton
                  text={"SUBMIT"}
                  type={"submit"}
                />
              </MDBox>
            </MDBox>
            :
            <MDBox>
              <MDBox mb={2} mt={4}>
                <MDTypography
                  sx={styles.skip}
                  onClick={skip}
                >
                  SKIP
                </MDTypography>
              </MDBox>
              <MDBox mb={4}>
                <PrimaryButton
                  text={"SUBMIT"}
                  type={"submit"}
                />
              </MDBox>
            </MDBox>
          :
          <MDBox>
            <MDBox mb={2} mt={4}>
              <MDTypography
                sx={styles.skip}
                onClick={skip}
              >
                SKIP
              </MDTypography>
            </MDBox>
            <MDBox mb={4}>
              <PrimaryButton
                text={"SUBMIT"}
                type={"submit"}
              />
            </MDBox>
          </MDBox>
        }
      </form>
    </OnboardingLayout>
  )
}

