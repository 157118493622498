import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {useStores} from "../../../../../models";

import BaseProtectedComponentPage from "../../../../../components/BaseProtectedComponentPage";
import {useEffect, useState} from "react";
import {ChargeWallet} from "../../../../../components/ChargeWallet";
import WalletRed from "assets/icons/wallet-idol-bank-red.svg";
import {useIsMobile} from "../../../../../utils/hooks";
import {Box} from "@mui/system";
import {MobileSectionHeader} from "../../../../../components/Mobile/MobileIdolDashboardSectionHeader";
import pxToRem from "../../../../../assets/theme/functions/pxToRem";
import {SavedCardMobile} from "../../../../bank-wallet/mobile/SavedCardMobile";
import AddCreditCardMobile from "../../../../bank-wallet/mobile/AddCreditCardMobile/AddCreditCardMobile";
import {IdolizerBalance} from "../../../../bank-wallet/mobile/idolizer/balance";
import {AddMoneyModal} from "../../../../bank-wallet/mobile/idolizer/addMoneyModal";
import {moneyFormat} from "../../../../../services/helpers";
import {notifyError, notifySuccess, notifyWarning} from "../../../../../services/notification";
import {NavigationMenu} from "../../../../../constants";
import {observer} from "mobx-react";

export const IdolizerWallet = observer(() => {

  const {loginStore} = useStores();
  const [Balance, setBalance] = useState({amount: 0, credits: 0});
  const [UserCards, setUserCards] = useState([]);
  const api = loginStore.environment.api
  const [cardSelected, setCardSelected] = useState('');
  const [selectedCardIdolizer, setSelectedCardIdolizer] = useState('');
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile()
  const [Amount, setAmount] = useState(0);

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("WALLET");
  }, []);

  const addMoney = () => {
    api.addMoney({money_to_add: Amount, card_id: cardSelected.id}).then(response => {
      if (response.kind === "ok") {
        getBalance()
        notifySuccess("Successful transaction")
        setAmount(0)
      } else {
        notifyError("Error adding Money to your wallet")
      }
    }).catch(reason => {
      notifyError("Error adding Money to your wallet")
    })
  }

  const getBalance = () => {
    api.getBalance().then(res => {
      if (res.kind === "ok") {
        setBalance(res.data)
      } else {
        console.log("error", res)
      }
    })
      .catch(reason => console.log(reason))
  };


  const getUserCards = () => {
    api.getUserCards().then(res => {
      if (res.kind === "ok") {
        const cardList = []
        for (const card of res.data.results) {
          cardList.push({
            ...card,
            card_number: card.hashed_card_number
          })
        }
        setUserCards(cardList)
      } else {
        console.log("error", res)
      }
    })
      .catch(reason => console.log(reason))
  }


  const deleteCard = (id) => {
    api.deleteCard(id).then(response => {
      if (response.kind === "ok") {
        getUserCards()
      } else {
        notifyError("Error deleting this card")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error deleting this card")
    })
  }


  const containerStyles = {
    marginTop: pxToRem(14)
  }


  useEffect(() => {
    getBalance()
    getUserCards()
  }, [])

  const buy = () => {

  }

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={WalletRed}>
        {isMobile ? (
          <Box>
            {/* Idolizer */}
            <Box sx={{marginTop: "24px"}}>
              <IdolizerBalance
                balance={Balance.amount}
                selectedCard={selectedCardIdolizer}
                onBuy={() => selectedCardIdolizer === '' ? notifyWarning('Must select a card first') : setShowModal(true)}
              />
            </Box>
            <Box sx={{marginTop: "24px"}}>
              <MobileSectionHeader
                containerStyles={containerStyles}
                ShowLogo={false}
                Title={"Saved Cards"}
              />

              {UserCards.map((card) => (
                <SavedCardMobile
                  key={card.id}
                  card={card}
                  isMobile={isMobile}
                  cardSelected={selectedCardIdolizer}
                  setCardSelected={setSelectedCardIdolizer}
                  deleteCard={deleteCard}
                />
              ))}
            </Box>
            <Box sx={{marginTop: "24px"}}>
              <AddCreditCardMobile/>
            </Box>
            <AddMoneyModal
              open={showModal}
              onClose={() => setShowModal(false)}
              selectedCard={selectedCardIdolizer}
              balance={Balance.amount}
              chageWalletCallback={getBalance}
            />
          </Box>
        ) : (
          <Box>
            <ChargeWallet
              buttonTitle="BUY"
              title="Idol Bank"
              isBuy={true}
              cardSelected={cardSelected}
              setCardSelected={setCardSelected}
              currentBalance={moneyFormat(Balance.amount)}
              lifetimeBalance={moneyFormat(Balance.credits)}
              inputAmount={Amount}
              buyActionHandler={() => addMoney()}
              userCards={UserCards}
              refreshList={() => getUserCards()}
              deleteCard={deleteCard}
              changeInputAmount={e => setAmount(parseFloat(e.target.value))}
            />

          </Box>
        )}
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
})

