import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import { Box, Typography } from "@mui/material";
import React from "react";
import rgba from "../../../assets/theme/functions/rgba";
import colors from "../../../assets/theme/base/colors";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import ChatProvider from "./ChatProvider";
import { RoomList } from "./components/RoomList";
import { ChatView } from "./components/ChatView";


export const LiveChatPage = () => {


  return (
    <DashboardLayout isAdmin hideFooter>
      <ChatProvider>
        <Box sx={{display:"flex", flexDirection:"row"}}>
          <RoomList/>
          <ChatView />
        </Box>
      </ChatProvider>
    </DashboardLayout>
  )
}
