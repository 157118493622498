import React from 'react';
import {useFormik} from 'formik';

/* MUI components */
import {Typography, Box} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {CustomTextField} from 'components/TextField';
import {SecondaryButton} from 'components/SecondaryButton';

/* utils */
import { formatNumberToCurrency } from 'utils/functions';
import { useApi } from 'utils/hooks';
import { notifySuccess, notifyError } from 'services/notification';

/* assets */
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {background, white} = colors;

export const AddMoneyModal = ({open, onClose, selectedCard, balance, chageWalletCallback}) => {
  const api = useApi()
  const [Loading, setLoading] = React.useState(false);

  const modalProps = {
    open,
    onClose,
    title: 'ADD MONEY TO WALLET',
    containerStyles: styles.modal_content_container,
    centerContent: false,
    ...styles.content
  };
  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    onSubmit: (values) => addMoney({ money_to_add: values.amount, card_id: selectedCard.id })
  });

  const addMoney = (values) => {
    setLoading(true)
    api.addMoney(values).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Payment successfull")
        chageWalletCallback.call()
        onCloseModal()

      } else {
        notifyError("Error adding Money to your wallet")
      }
    }).catch(reason => {
      notifyError("Error adding Money to your wallet")
    }).finally(() => {
      setLoading(false)
    })

  }

  const onCloseModal = () => {
    onClose();
    formik.resetForm();
  }

  return (
    <BaseModal {...modalProps}>
      <Typography sx={styles.balance}>{formatNumberToCurrency(balance)}</Typography>
      <Box sx={styles.inputs_container}>
        <CustomTextField
          type="number"
          name="amount"
          onChange={formik.handleChange}
          startAdornment={
            <Typography sx={[styles.adornment, styles.adornment_light]}>
              for <span style={styles.adornment_bold}>$</span>
            </Typography>
          }
          sx={styles.input}
        />

        <CustomTextField
          value={formik.values.amount}
          startAdornment={
            <Typography sx={[styles.adornment, styles.adornment_light, { width: {xs: "100%", lg: "51%"} }]}>
              you will get
            </Typography>
          }
          endAdornment={<Typography sx={[styles.adornment, styles.adornment_bold]}>idp</Typography>}
          sx={styles.input}
        />
      </Box>
      <Box sx={styles.actions_container}>
        <SecondaryButton
          text="Continue"
          {...styles.button}
          textStyles={styles.button_text}
          onClick={formik.handleSubmit}
          disabled={formik.values.amount === "" || Loading}
          loading={Loading}
        />
        <Typography sx={styles.cancel} onClick={onCloseModal}>
          CANCEL
        </Typography>
      </Box>
    </BaseModal>
  );
}

const styles = {
    modal_content_container: {
        background: background.default,
        height: pxToRem(413),
        marginBlockStart: 0,
        marginBlockEnd: 0,
        overflow: 'hidden',
        width: pxToRem(330),
    },
    content: {
        marginInline: pxToRem(24),
    },
    balance: {
        color: white.main,
        fontSize: pxToRem(36),
        fontWeight: 100,
        lineHeight: '36px',
        marginInline: pxToRem(59),
    },
    inputs_container: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(20),
        marginTop: pxToRem(47),
    },
    input: {
        color: white.main,
        fontWeight: 'bold',
    },
    adornment: {
        display: 'flex',
        color: white.main,
        fontSize: pxToRem(14),
        gap: '5px'
    },
    adornment_light: {
        fontWeight: 300,
    },
    adornment_bold: {
        fontWeight: 'bold'
    },
    actions_container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(14),
        justifyContent: 'center',
        marginTop: pxToRem(40),
    },
    button: {
        height: pxToRem(42),
        padding: 0,
        width: pxToRem(196),
    },
    button_text: {
        fontSize: pxToRem(12),
        fontWeight: 700,
        letterSpacing: '0.1em',
        lineHeight: '93.75%'
    },
    cancel: {
        color: white.main,
        fontSize: pxToRem(12),
        fontWeight: 400,
        lineHeight: '127.25%',
        letterSpacing: '0.1em'
    },
};
