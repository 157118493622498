import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withEnvironment} from "../extensions/with-environment";
import {withRootStore} from "../extensions/with-root-store";
import {IdolTypes, Units, UserTypes} from "../../constants";
import {LoginStoreFieldList, LoginStoreFields} from "../fields";

/**
 * Model description here for TypeScript hints.
 */
const getViewsLoginStoreModel = (self: any) => ({
  get getActiveRoute() {
    return self.activeRoute
  },
  get getMobileActiveRoute() {
    return self.mobileActiveRoute || ""
  },
  get isLoggedIn() {
    if (!self.id) {
      const api = self.environment.api.apisauce
      api?.deleteHeader('Authorization')
      return false
    }
    return true
  },
  get fullName() {
    return self.first_name + ' ' + self.last_name
  },
  get isAdmin() {
    return self.user_type_codename === UserTypes.Admin
  },
  get isIdol() {
    return self.user_type_codename === UserTypes.Idol
  },
  get isIdolizer() {
    return self.user_type_codename === UserTypes.Idolizer
  },
  get isInfluencer() {
    return self.idol_type === IdolTypes.Influencer
  },
  get isProfessional() {
    return self.idol_type === IdolTypes.Professional
  },
  get isEntrepreneur() {
    return self.idol_type === IdolTypes.Entrepreneur
  },
  get userType() {
    return self.user_type_codename
  },
  get isMetric() {
    return self.measurement_type === Units.Metric.id
  },
  get isImperial() {
    return self.measurement_type === Units.Imperial.id
  },
  get MeasurementType() {
    if (self.measurement_type === Units.Imperial.id) {
      return Units.Imperial
    }
    if (self.measurement_type === Units.Metric.id) {
      return Units.Metric
    }
    return Units.Metric
  },
  get ProfilePictureVersion() {
    return self.imageVersion
  },
})

const getActionsLoginStoreModel = (self: any) => ({
  setApiToken(token: string | null) {
    const api = self.environment.api.apisauce
    self.token = token
    if (token) {
      api?.setHeader('Authorization', 'JWT ' + token)
    } else {
      api?.deleteHeader('Authorization')
    }
  },
  setActiveRoute(route: string) {
    self.activeRoute = route
  },
  setMobileActiveRoute(route: string) {
    self.mobileActiveRoute = route
  },
  setUserImage(img: string) {
    self.image = img
  },
  setReferralCode(code: string) {
    self.referral_code = code
  },
  setTwilioToken(twilio_token: string | null) {
    self.twilio_token = twilio_token
  },
  setEmailPasswordToken(email_password_token: string | null) {
    self.email_password_token = email_password_token
  },
  setPasswordToken(password_token: string | null) {
    self.password_token = password_token
  },
  setProfilePictureVersion(imageVersion: number) {
    self.imageVersion = imageVersion
  },
  setData(data: any) {
    Object.keys(data).forEach(key => {
      try {
        self[key] = data[key]
      } catch (e) {
        console.log("key", key)
        console.log("data[key]", data[key])
      }
    })
  },
  setUser(userData: any) {
    const StringToFloat = ['height', 'hips', 'bust', 'waist', 'shoe_size']
    const ObjectIDToObject = ['user_type_codename']
    const IgnoreFieldUpdate = ['token', 'activeRoute', 'mobileActiveRoute']
    const ProfilePictureVersion = ['imageVersion']
    const specialCases = [...StringToFloat, ...ObjectIDToObject, ...IgnoreFieldUpdate, ...ProfilePictureVersion]
    LoginStoreFieldList
      .forEach((field: string) => {
        if (specialCases.includes(field)) {
          return
        }
        // @ts-ignore
        self[field] = userData[field]
      })
    StringToFloat.forEach(field => {
      self[field] = parseFloat(userData[field])
    })
    self.user_type_codename = userData.user_type.codename
    self.full_name = userData.first_name + ' ' + userData.last_name
  },
  reset() {
    Object.keys(LoginStoreFields).forEach((field: string) => {
      // @ts-ignore
      self[field] = null
    })
  },
})

export const LoginStoreModel = types
  .model("LoginStore")
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({...LoginStoreFields})
  .views(getViewsLoginStoreModel)
  .actions(getActionsLoginStoreModel)

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type LoginStoreType = Instance<typeof LoginStoreModel>

export interface LoginStore extends LoginStoreType {
}

type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>

export interface LoginStoreSnapshot extends LoginStoreSnapshotType {
}
