import MDBox from "../../../components/MDBox";
import {setLayout, useMaterialUIController} from "context";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {SecondaryButton} from "../../../components/SecondaryButton";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {Box, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useStores} from "../../../models";
import { LayoutNames } from "../../../constants";
import { GuestPaths } from "../../../paths/guestPaths";

export const GetStarted = () => {
  const rootStore = useStores()
  const [controller, dispatch] = useMaterialUIController();
  const location = useLocation();
  const {pathname} = location;
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');

  useEffect(() => {
    setLayout(dispatch, LayoutNames.onboarding);
  }, [pathname]);

  useEffect(() => {
    rootStore.reset()
  }, [])

  return (
    <OnboardingLayout mt={0} titleBold={"GET"} titleNormal={"STARTED"} backTo={GuestPaths.getStarted}>
      <Box style={{
        display: isMobile ? "block" : "unset"
      }}>
        <MDBox mt={15} width={isMobile ? "100%" : "21.5rem"} mr={15}>
          <PrimaryButton text={"SIGN UP"} onClick={() => navigate(GuestPaths.whoAreYou)} fontWeight={700}/>
        </MDBox>
        <MDBox mt={4} width={isMobile ? "100%" : "21.5rem"} mr={15}>
          <PrimaryButton text={"LOG IN"} onClick={() => navigate(GuestPaths.login)} fontWeight={700}/>
        </MDBox>
        <MDBox sx={isMobile ? isMobileH ? {
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 20,
          paddingX: "30px"
        } : {marginTop: 8, marginBottom: 3} : {marginTop: 8, marginBottom: 3}} width={isMobile ? "100%" : "21.5rem"}>
          <SecondaryButton text={`ENTER AS A GUEST`} onClick={() => navigate(GuestPaths.explore)} fontWeight={700}/>
        </MDBox>
      </Box>
    </OnboardingLayout>
  )
}
