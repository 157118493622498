import { Box, FormHelperText, Input, Typography } from "@mui/material";
import CustomSelect from "components/CustomSelect";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import { styles } from "./styles";
import { CustomTextField } from "../TextField";
import { useStores } from "../../models";

function BillingDetails({ formik }) {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const api = loginStore.environment.api;
  const [Countries, setCountries] = useState([]);
  
  const parseCountries = (countries) => {
  const parsedCountries = countries.map((country , idx) => ({value: country.id, label: country.name}));
  return parsedCountries;
  }

  const getCountries = (event, search = "") => {
    let countries = [];
    api
      .getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {          
          countries = res.data.results;
          countries = parseCountries(countries);
          setCountries(countries);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCountries();
  }, []);


  return (
    <Box sx={styles.billingDetails}>
      <Typography sx={styles.titleDetails}>Billing Details</Typography>


      <CustomTextField
        type="text"
        sx={styles.input}
        name="address1"
        value={formik.values.address1}
        onChange={formik.handleChange}
        placeholder="Address 1"
        id={`id_address1`}
        error={formik.touched.address1 && Boolean(formik.errors.address1)}
        helperText={formik.touched.address1 && formik.errors.address1 } />


      <CustomTextField
        type="text"
        sx={styles.input}
        name="address2"
        value={formik.values.address2}
        onChange={formik.handleChange}
        placeholder="Address 2"
        id={`id_address2`}
        error={formik.touched.address2 && Boolean(formik.errors.address2)}
        helperText={formik.touched.address2 && formik.errors.address2 } />


      <Box sx={styles.doubleInput}>
        <CustomTextField
          type="text"
          sx={styles.input}
          name="stateProvice"
          value={formik.values.stateProvice}
          onChange={formik.handleChange}
          placeholder="State/Province"
          id={`id_address2`}
          error={formik.touched.stateProvice && Boolean(formik.errors.stateProvice)}
          helperText={formik.touched.stateProvice && formik.errors.stateProvice } />



        <CustomTextField
          type="text"
          sx={styles.input}
          name="ZIP"
          value={formik.values.ZIP}
          onChange={formik.handleChange}
          placeholder="ZIP"
          id={`ZIP`}
          error={formik.touched.ZIP && Boolean(formik.errors.ZIP)}
          helperText={formik.touched.ZIP && formik.errors.ZIP } />


      </Box>
      <CustomSelect
        onChange={(e) => {
          formik.setFieldValue('country', parseInt(e.target.value))
        }}
        options={[
          {value: 0, label: "Country"},
          ...Countries
        ]}/>

      {formik.touched.country && Boolean(formik.errors.country) && (
        <FormHelperText>
          <MDTypography sx={{fontSize: 14}}
                        color={"error"}>{formik.touched.country && formik.errors.country}</MDTypography>
        </FormHelperText>
      )}
    </Box>
  );
}

export default BillingDetails;
