import {Box, Input} from "@mui/material";
import React from "react";
import dollar from "../../../assets/icons/dollar-currency.svg";
import { styles } from "../../../base/Cards/PriceCard/styles";
import MDTypography from "../../../components/MDTypography";
import colors from "../../../assets/theme/base/colors";
import MDBox from "../../../components/MDBox";

const  FeesCard = ({ name, value, error, isMobile, label, inDollars = true, setFieldValue }) => {
  return (
    <MDBox display={'flex'} justifyContent={'center'} flexDirection={'column'} width={'100%'}>
      <MDBox sx={{ position: 'relative' }}>
        {inDollars  && <Box component="img" src={dollar} alt="dollar" sx={{position: "absolute", zIndex: 99, bottom: 18, left: 20 }} />}
          <MDTypography varian={'h6'} color={'white'}>{label}</MDTypography>
          <Input
            fullWidth
            name={name}
            sx={isMobile ? { ...styles.price, ...styles.mobilePrice } : styles.price}
            value={value}
            onChange={(e) => setFieldValue(name, {value: e.target.value, key: name})}
          />
      </MDBox>
      {error && (
        <MDTypography style={{color: colors.error.main, fontSize:"12px"}}>{error}</MDTypography>
      )}
    </MDBox>
  );
}
export default FeesCard;
