import { ScreenType, UserTypes } from "../constants";
import { Typography } from "@mui/material";
import { idolDesktopMenu, idolInfluencerDesktopMenu } from "./idolDesktopMenu";
import { idolizerDesktopMenu } from "./idolizerDesktopMenu";
import { idolInfluencerMobileMenu, idolMobileMenu } from "./idolMobileMenu";
import { idolizerMobileMenu } from "./idolizerMobileMenu";
import { Paths } from "../paths";
import { adminDesktopMenu } from "./adminDesktopMenu";

const getCollapsed = (userType, entries = {}, itemParentName = null) => {
  return Object.keys(entries).map((menuEntry, index) => {
    if (entries[menuEntry].type === 'collapse') {
      return {
        key: `navigation_${index}`,
        type: entries[menuEntry].type,
        noCollapse: entries[menuEntry].noCollapse,
        icon: entries[menuEntry].icon,
        name: <Typography style={{fontSize: 16, fontWeight: "bold", color:entries[menuEntry].color??"white"}}>{entries[menuEntry].name}</Typography>,
        path: Paths[userType][menuEntry],
        collapse: entries[menuEntry].collapse ? getCollapsed(userType, entries[menuEntry].collapse, `navigation_${index}`) : null,
        itemParentName: itemParentName,
      }
    } else if (entries[menuEntry].type === 'divider') {
      return {
        key: `divider_${index}`,
        ...entries[menuEntry],
      }
    }
  })
}

export const MENUS = {
  [ScreenType.DESKTOP]: {
    [UserTypes.Idol]: {
      influencer:{
        dashboard: getCollapsed(UserTypes.Idol, idolInfluencerDesktopMenu.dashboard),
        settings: getCollapsed(UserTypes.Idol,idolInfluencerDesktopMenu.settings),
      },
      other:{
        dashboard: getCollapsed(UserTypes.Idol, idolDesktopMenu.dashboard),
        settings: getCollapsed(UserTypes.Idol, idolDesktopMenu.settings),
      }
    },
    [UserTypes.Idolizer]: {
      dashboard: getCollapsed(UserTypes.Idolizer, idolizerDesktopMenu.dashboard),
      settings: getCollapsed(UserTypes.Idolizer, idolizerDesktopMenu.settings),
    },
    [UserTypes.Guest]: {
      dashboard: [],
      settings: [],
    },
    [UserTypes.Admin]:{
      dashboard: getCollapsed(UserTypes.Admin, adminDesktopMenu.dashboard),
      settings:[]
    }
  },
  [ScreenType.MOBILE]: {
    [UserTypes.Idol]: {
      influencer:{
        main: getCollapsed(UserTypes.Idol, idolInfluencerMobileMenu.main)
      },
      other:{
        main: getCollapsed(UserTypes.Idol, idolMobileMenu.main)
      }
    },
    [UserTypes.Idolizer]: {
      main: getCollapsed(UserTypes.Idolizer, idolizerMobileMenu.main)
    },
    [UserTypes.Guest]: {
      main: [],
    },
  },
}

