import React, {useState} from 'react'

/* @mui components */
import {Accordion, AccordionSummary, AccordionDetails, CircularProgress} from '@mui/material';

/* components */
import { RecordCard } from 'components/RecordCard/RecordCard';
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

/* icons */
import { ArrowForwardIosRounded } from "@mui/icons-material";
import RedLogo from "assets/images/idol_red.png";
import GreyLogo from "assets/images/idol_grey.png";
import DeleteIcon from "assets/icons/trash_red.svg";
import SuspendIcon from "assets/icons/suspend_icon.svg";

/* assets */
import CheckIcon from '@mui/icons-material/Check';
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
const {background, tertiary, card} = colors;

export const UserCard =
  ({
     user,
     setUserAction,
     selectedUserId,
     setModalManager,
     handleSelectUser,
     loadingProfile,
     children,
  }) => {

  const [expandAccordion, setExpandAccordion] = useState(false);
  const isAccordionExpanded = selectedUserId === user?.id && expandAccordion;

  const recordProps = {
    contentContainerStyles: styles.content_container,
    cardContentsStyle: styles.content,
    showOptionsCard: false,
    cardThirdContentStyles: styles.third_content,
    containerStyles: styles.container,
  };

  const baseInfoProps = {
    idolizerName: user?.username,
    idolPoints: user?.idol_points,
    isVerified: user?.is_validated,
    avatar: user?.image,
    nameStyles: styles.user_name,
    infoCardStyles: styles.infocard,
    pointStyles: styles.points,
    idpStyles: styles.points,
  };

  const userHandleClick = (id) => {
    if(selectedUserId === id) {
      handleSelectUser(null);
      return setExpandAccordion(false);
    }
    if(!expandAccordion) {
      setExpandAccordion(true);
    }
    handleSelectUser(id);
  }

  const handleOpenModal = (e, field) => {
    e.stopPropagation();
    setModalManager(prev => ({...prev, [field]: true}));
  }

  return (
    <Accordion sx={styles.accordion} expanded={isAccordionExpanded}>
      <AccordionSummary sx={styles.accordion_summary}>
        <RecordCard
          {...recordProps}
          onClick={() => userHandleClick(user.id)}
          cardFirstContent={
            <MDBox sx={styles.first_content}>
              <MDBox sx={styles.user_info_container}>
                <ArrowForwardIosRounded
                  color="white"
                  size={30}
                  sx={[styles.arrow, {transform: isAccordionExpanded ? "rotate(-90deg)" : null}]}
                />
                <BaseCardIdolInfo {...baseInfoProps} />
              </MDBox>
              <MDBox sx={styles.logo_container}>
                <img
                  src={user?.user_type?.codename === "zee" ? RedLogo : GreyLogo}
                  style={styles.logo}
                  alt="Logo"
                />
              </MDBox>
            </MDBox>
          }
          cardSecondContent={<MDTypography color='grayLight' size='md' fontWeight='regular'>{user.email}</MDTypography>}
          cardThirdContent={
            <>
              <MDBox
                sx={styles.action_box(user?.is_deleted)}
                onClick={(e) => {
                  handleOpenModal(e, 'delete');
                  setUserAction(user);
                }}
              >
                {!user.is_deleted ? <img src={DeleteIcon} alt="Delete Icon" /> : <CheckIcon color='primary' />}
                <MDTypography color='primary' size='sm' fontWeight='regular'>
                  {!user.is_deleted ? 'Delete' : 'Reinstate'}
                </MDTypography>
              </MDBox>
              <MDBox
                sx={styles.action_box(user?.is_suspended)}
                onClick={(e) => {
                  handleOpenModal(e, 'suspend');
                  setUserAction(user);
                }}
              >

                {!user.is_suspended ? <img src={SuspendIcon} alt="Suspend Icon" /> : <CheckIcon color='disabled' />}
                <MDTypography color='grayLight' size='sm' fontWeight='regular'>
                  {!user.is_suspended ? 'Suspend' : 'Reinstate'}
                </MDTypography>
              </MDBox>
            </>
          }
        />
      </AccordionSummary>
      <AccordionDetails sx={styles.accordion_details}>
        <MDBox display='flex' justifyContent='center' width='100%' mt={pxToRem(20)}>
          {loadingProfile
            ? <CircularProgress />
            : (
              <MDBox width={pxToRem(562)} display='flex' flexDirection='column' gap={pxToRem(14)}>
                {children}
              </MDBox>
            )
          }
        </MDBox>
      </AccordionDetails>
    </Accordion>
  );
}

const styles = {
  accordion: {
    background: card.content,
    padding: 0,
    margin: 0,
    borderRadius: '5px',
  },
  accordion_summary: {
    borderRadius: '5px',
    height: pxToRem(77),
    padding: 0,
  },
  accordion_details: {
    background: card.header,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    borderRadius: '0 0 5px 5px',
  },
  container: {
    background: card.content,
    borderRadius: '5px',
    marginTop: 0,
  },
  first_content: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: pxToRem(20),
    width: "100%",
  },
  user_info_container: {
    alignItems: "center",
    display: "flex",
    gap: pxToRem(19),
  },
  content_container: {
    display: "grid",
    gridTemplateColumns: "2.5fr 0.1fr 1.5fr",
  },
  content: {
    width: "100%",
  },
  arrow: {
    cursor: "pointer",
    transition: "0.5s",
  },
  infocard: {
    padding: 0,
  },
  user_name: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  points: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  logo_container: {
    display: "flex",
  },
  logo: {
    height: pxToRem(29),
    width: pxToRem(29),
  },
  third_content: {
    background: background.default,
    borderRadius: "5px",
    display: "flex",
    gap: pxToRem(12),
    justifyContent: "center",
    left: 0,
    paddingBlock: pxToRem(18),
    paddingInline: pxToRem(12),
    width: pxToRem(270),
  },
  action_box: (isModified) => ({
    alignItems: "center",
    background: tertiary.main,
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    gap: pxToRem(isModified ? 2 : 8),
    height: pxToRem(40),
    justifyContent: "center",
    padding: `${pxToRem(8)} ${pxToRem(10)}`,
    width: pxToRem(92),
  }),
};