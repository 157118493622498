import React, {useState} from "react";
import {useFormik} from "formik";

/* MUI components */
import {Grid} from "@mui/material";

/* components */
import {ProfileCard} from "../../../edit-profile/components";
import MDBox from "components/MDBox";
import {CustomFormikDateField, CustomFormikTextField, DownloadDocument} from "components/TextField";
import {DownloadFile} from "components/DownloadFileCard";
import MDTypography from "components/MDTypography";

/* utils */
import {IdolTypesInformation, Units} from "constants.js";
import {tabSection} from "../constants";

/* assets */
import NoImage from 'assets/images/explore-default.png';
import pxToRem from "assets/theme/functions/pxToRem";
import {getIdolProfileValues, getIdolIncValues, getIdolBankValues, getIdolTaxValues} from "../utils";

export const IdolVerification = ({user}) => {
  const [currentTab, setCurrentTab] = useState(tabSection.None);

  const handleChangeTab = (value) => {
    if(currentTab === value) return setCurrentTab(tabSection.None);
    setCurrentTab(value);
  }

  const formik = {};

  formik.IdolProfile = useFormik({
    initialValues: getIdolProfileValues(user),
    enableReinitialize: true,
  })

  formik.IdolInc = useFormik({
    initialValues: getIdolIncValues(user),
    enableReinitialize: true,
  })

  formik.IdolBank = useFormik({
    initialValues: getIdolBankValues(user),
    enableReinitialize: true,
  })

  formik.IdolTax = useFormik({
    initialValues: getIdolTaxValues(user),
    enableReinitialize: true,
  })


  return (
    <MDBox mt={pxToRem(24)}>
      <MDBox display='flex' alignItems='center' gap={pxToRem(65)}>
        <MDBox display='flex' alignItems='center' gap={pxToRem(27)}>
          <MDBox
            alignItems='center'
            display='flex'
            flexDirection='column'
            gap={pxToRem(9)}
            height={pxToRem(76)}
            justifyContent='center'
            width={pxToRem(71)}
            sx={({palette: {darkBlue}}) => ({backgroundColor: darkBlue[200], borderRadius: '10px'})}
          >
            <MDBox component='img' alt='logo' src={IdolTypesInformation?.[user?.idol_type]?.logo} width={pxToRem(35)} height={pxToRem(35)}/>
            <MDTypography color='light' variant='button'>{IdolTypesInformation?.[user?.idol_type]?.name}</MDTypography>
          </MDBox>
          <MDBox display='flex' alignItems='center' gap={pxToRem(21)}>
            <MDBox component='img' src={user?.image ?? NoImage} width={pxToRem(49)} height={pxToRem(49)} sx={{borderRadius: '50px'}} alt={`${user?.username} image`}/>
            <MDBox display='flex' flexDirection='column' gap={pxToRem(5)}>
              <MDTypography color='light' size='md' fontWeight='semiMedium'>
                {`${user?.first_name || user?.username} ${user?.last_name}`}
              </MDTypography>
              <MDTypography color='primary' variant='button' fontWeight='semiMedium'>{user?.idol_points} idp</MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDTypography color='gray' size='md' fontWeight='regular'>{user?.email}</MDTypography>
      </MDBox>
      <MDBox mt={pxToRem(34)} maxHeight={pxToRem(500)} overflow='hidden auto' width='100%'>
        <MDBox display='flex' flexDirection='column' gap={pxToRem(14)} width={pxToRem(562)}>

          {/*IDOL PROFILE*/}
          <ProfileCard title='IDOL Profile' isExpanded={currentTab === tabSection.IdolProfile} background='light' onChange={() => handleChangeTab(tabSection.IdolProfile)}>
            <CustomFormikTextField name="username" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
            <CustomFormikTextField name="idol_id_name" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
            <>
              <MDTypography sx={{fontSize: pxToRem(16), mb: pxToRem(4), color: "#FFFFFF"}}>{"Tags"}</MDTypography>
              <Grid container spacing={2}>
                <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                  <CustomFormikTextField name="tag_1" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
                </Grid>
                <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                  <CustomFormikTextField name="tag_2" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
                </Grid>
                <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                  <CustomFormikTextField name="tag_3" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
                </Grid>
              </Grid>
            </>
            <CustomFormikTextField
              name="biography"
              inputProps={{readOnly: true}} formik={formik.IdolProfile}
            />
            <CustomFormikTextField name={'gender'} inputProps={{readOnly: true}} formik={formik.IdolProfile} />
            <CustomFormikTextField name="age" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
            {/*TODO*/}
            {/*<Grid container spacing={2}>*/}
            {/*  <Grid item xs={6}>*/}
            {/*    <PrimaryButton*/}
            {/*      text={"Metric"}*/}
            {/*      size={"small"}*/}
            {/*      buttonStyle={{height: "30px"}}*/}
            {/*      onClick={handleClickOnMetricUnits}*/}
            {/*      mainColor={CurrentUnits === Units.Metric.id ? colors.primary : colors.tertiary}/>*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={6}>*/}
            {/*    <PrimaryButton*/}
            {/*      text={"Imperial"}*/}
            {/*      size={"small"}*/}
            {/*      buttonStyle={{height: "30px"}}*/}
            {/*      onClick={handleClickOnImperialUnits}*/}
            {/*      mainColor={CurrentUnits === Units.Imperial.id ? colors.primary : colors.tertiary}/>*/}
            {/*  </Grid>*/}
            {/*  {CurrentUnits === null ?*/}
            {/*    <Grid item sm={12}><MDTypography sx={(theme) => {*/}
            {/*      return {*/}
            {/*        color: theme.palette.primary.main,*/}
            {/*        fontSize: pxToRem(14),*/}
            {/*        textAlign: "center",*/}
            {/*      }*/}
            {/*    }}>Please select the measurement*/}
            {/*      unit</MDTypography></Grid>*/}
            {/*    :*/}
            {/*    <>*/}
            {/*      {CurrentUnits === Units.Metric.id &&*/}
            {/*        <Grid item md={6} sm={12}>*/}
            {/*          <CustomFormikTextField*/}
            {/*            name="height"*/}
            {/*            inputProps={readOnly: true}}*/}
            {/*            customEndAdornmentText={Units.Metric.measurementUnit}*/}
            {/*            onChange={(evt) => {*/}
            {/*              const inches = (evt.target.value / 2.54) % 12*/}
            {/*              const feets = Math.floor((evt.target.value / 2.54) / 12)*/}
            {/*              formik.IdolProfile.setFieldValue('height_ft', feets.toFixed(2))*/}
            {/*              formik.IdolProfile.setFieldValue('height_in', inches.toFixed(2))*/}
            {/*            }}*/}
            {/*            inputProps={{maxLength: 6}}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      }*/}
            {/*      {CurrentUnits === Units.Imperial.id && [*/}
            {/*        <Grid item md={4} sm={12}>*/}
            {/*          <CustomFormikTextField*/}
            {/*            name="height_ft"*/}
            {/*            inputProps={readOnly: true}}*/}
            {/*            customEndAdornmentText={'ft'}*/}
            {/*            onChange={(evt) => {*/}
            {/*              const centimeters = evt.target.value * 30.48 + formik.IdolProfile.values.height_in * 2.54*/}
            {/*              formik.IdolProfile.setFieldValue('height', centimeters.toFixed(2))*/}
            {/*            }}*/}
            {/*            inputProps={{maxLength: 6}}*/}
            {/*          />*/}
            {/*        </Grid>,*/}
            {/*        <Grid item md={4} sm={12}>*/}
            {/*          <CustomFormikTextField*/}
            {/*            name="height_in"*/}
            {/*            inputProps={readOnly: true}}*/}
            {/*            customEndAdornmentText={Units.Imperial.measurementUnit}*/}
            {/*            onChange={(evt) => {*/}
            {/*              const centimeters = evt.target.value * 2.54 + formik.IdolProfile.values.height_ft * 30.48*/}
            {/*              formik.IdolProfile.setFieldValue('height', centimeters.toFixed(2))*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        </Grid>*/}
            {/*      ]}*/}

            {/*      <Grid item md={(CurrentUnits === Units.Metric.id) ? 6 : 4} xs={12}>*/}
            {/*        <CustomFormikTextField*/}
            {/*          inputProps={{maxLength: 6}}*/}
            {/*          name="shoe_size"*/}
            {/*          customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : "US"}*/}
            {/*          inputProps={readOnly: true}}*/}
            {/*        />*/}
            {/*      </Grid>*/}
            {/*    </>*/}
            {/*  }*/}
            {/*  {(CurrentUnits !== null) && [*/}
            {/*    <Grid item md={4} sm={12}>*/}
            {/*      <CustomFormikTextField*/}
            {/*        name="bust"*/}
            {/*        inputProps={readOnly: true}}*/}
            {/*        customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}*/}
            {/*      />*/}
            {/*    </Grid>,*/}
            {/*    <Grid item md={4} sm={12}>*/}
            {/*      <CustomFormikTextField*/}
            {/*        name="waist"*/}
            {/*        inputProps={readOnly: true}}*/}
            {/*        customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}*/}
            {/*      />*/}
            {/*    </Grid>,*/}
            {/*    <Grid item md={4} sm={12}>*/}
            {/*      <CustomFormikTextField*/}
            {/*        name="hips"*/}
            {/*        inputProps={readOnly: true}}*/}
            {/*        customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*  ]}*/}
            {/*</Grid>*/}
            <CustomFormikTextField name="location" inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
            <CustomFormikTextField name={'ethnicity'} inputProps={{readOnly: true}} formik={formik.IdolProfile} />
            <CustomFormikTextField name={'language'} inputProps={{readOnly: true}} formik={formik.IdolProfile}/>
          </ProfileCard>
          {/*IDOL PROFILE*/}

          {/*IDOL INC*/}
          <ProfileCard title='IDOL Inc' isExpanded={currentTab === tabSection.IdolInc} background='light' onChange={() => handleChangeTab(tabSection.IdolInc)}>
            <CustomFormikTextField name="email" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="inc_first_name" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="inc_middle_name" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="inc_last_name" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikDateField name="inc_date_of_birth" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="inc_address_line_1" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="inc_address_line_2" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'inc_country'} formik={formik.IdolInc} inputProps={{readOnly: true}}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'inc_state'} formik={formik.IdolInc} inputProps={{readOnly: true}}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'inc_city'} formik={formik.IdolInc} inputProps={{readOnly: true}}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name="inc_zip_code" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
              </Grid>
            </Grid>
            <CustomFormikTextField name="inc_phone_number" formik={formik.IdolInc} inputProps={{readOnly: true}}/>
          </ProfileCard>
          {/*IDOL INC*/}

          {/*IDOL BANK*/}
          <ProfileCard title='IDOL Bank' isExpanded={currentTab === tabSection.IdolBank} background='light' onChange={() => handleChangeTab(tabSection.IdolBank)}>
            <CustomFormikTextField name="bank_name" formik={formik.IdolBank} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="bank_account_number" formik={formik.IdolBank} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="bank_routing_number" formik={formik.IdolBank} inputProps={{readOnly: true}}/>
          </ProfileCard>
          {/*IDOL BANK*/}

          {/*IDOL TAX*/}
          <ProfileCard title='IDOL Tax' isExpanded={currentTab === tabSection.IdolTax} background='light' onChange={() => handleChangeTab(tabSection.IdolTax)}>
            <CustomFormikTextField name="tax_social_security" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <DownloadDocument name={"tax_w8_w9_file"} formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <DownloadDocument name={"tax_article_of_incorporation"} formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="tax_company_name" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="tax_id" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'tax_country'} formik={formik.IdolTax} inputProps={{readOnly: true}}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'tax_state'} formik={formik.IdolTax} inputProps={{readOnly: true}}/>
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name={'tax_city'} formik={formik.IdolTax} inputProps={{readOnly: true}} />
              </Grid>
              <Grid item md={6} sm={12}>
                <CustomFormikTextField name="tax_zip_code" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
              </Grid>
            </Grid>
            <CustomFormikTextField name="tax_address_line_1" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="tax_address_line_2" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
            <CustomFormikTextField name="tax_phone_number" formik={formik.IdolTax} inputProps={{readOnly: true}}/>
          </ProfileCard>
          {/*IDOL TAX*/}

          {/*IDOL VERIFY*/}
          <ProfileCard title='IDOL Verify' isExpanded={currentTab === tabSection.IdolVerify} background='light' onChange={() => handleChangeTab(tabSection.IdolVerify)}>
            <DownloadFile />
            <DownloadFile />
          </ProfileCard>
          {/*IDOL VERIFY*/}

        </MDBox>
      </MDBox>
    </MDBox>
  );
};

