import { Box } from "@mui/material";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import iconRed from "../../../assets/images/idol_red.png";
import iconGrey from "../../../assets/images/idol_grey.png";
import { MoreVert as ThreeDotsIcon } from "@mui/icons-material";
import React, { useState } from "react";
import colors from "assets/theme/base/colors";
import { IdolsActions } from "pages/portal/dashboard/idolizer/idols/actions";
import CancelSubscriptionModal from "../MobileModals/CancelSubscriptionModal/CancelSubscriptionModal";

const IdolizerCardContainer = ({
  isMobile,
  avatar,
  isVerified,
  idolPoints,
  idolizerName,
  type,
  subscriptionPrice,
  actionsFn
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const popoverProps = {
    anchorEl,
    onClose: () => setAnchorEl(null),
    open: Boolean(anchorEl),
    ...actionsFn
  };

  const handleToggleShowOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const idolImgType = {
    following: iconGrey,
    idolizing: iconRed,
  };
  const idolSubscriptionPrice = subscriptionPrice === 0 ? "Free" : `$${subscriptionPrice}.00`;

  return (
    <Box sx={styles.container}>
      {open && (
        <CancelSubscriptionModal
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleOpen={handleOpen}
          type={type}
          isMobile={isMobile}
          avatar={avatar}
          isVerified={isVerified}
          idolPoints={idolPoints}
          idolizerName={idolizerName}
        />
      )}
      <Box width="180px">
        <BaseCardIdolInfo
          isMobile={isMobile}
          avatar={avatar}
          isVerified={isVerified}
          idolPoints={idolPoints}
          idolizerName={idolizerName}
        />
      </Box>
      <Box sx={styles.dotsContainer}>
        <Box sx={styles.divider}>|</Box>
        <Box component="img" src={idolImgType[type]} sx={styles.idolTypeImg} />
        <Box sx={styles.idolPriceContainer}>
          {type === "idolizing" ? <Box sx={styles.idolPrice}>{idolSubscriptionPrice}</Box> : <></>}
        </Box>
      </Box>
      <ThreeDotsIcon sx={styles.threeDots} onClick={(e) => handleToggleShowOptions(e)} />
      <IdolsActions {...popoverProps} type={type} />
    </Box>
  );
};

export default IdolizerCardContainer;

const styles = {
  container: {
    backgroundColor: "#152233",
    height: "73px",
    padding: "16px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "9px",
  },
  divider: {
    backgroundColor: colors.background.default,
    color: colors.background.default,
    width: "5px",
    height: "46px",
    borderRadius: "8px",
    opacity: 0.2,
  },
  idolTypeImg: {
    width: "30px",
    height: "30px",
  },
  idolPriceContainer: {
    width: "40px",
    display: "flex",
    justifyContent: "center",
  },
  idolPrice: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#fff",
  },
  threeDots: {
    color: "#fff",
    height: "32px",
    width: "32px",
  },
};
