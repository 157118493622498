import * as React from "react";
import {Box, FormControl, FormHelperText, InputAdornment, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {getEndAdornmentTextStyle, iconStyle, StyledTextField} from "./styles";
import MDTypography from "../MDTypography";
import colors from "assets/theme/base/colors";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useIsMobile} from "utils/hooks";
import {FormikFields} from "../../models/fields/models";
import InputMask from "react-input-mask";
import {downloadDocumentLabelStyle, downloadDocumentStyle} from "../../pages/edit-profile/styles";
import DocumentUpload from "../../assets/icons/uploadfile.svg";
import {useStores} from "../../models";

const {white} = colors;

const CustomTextField = (props) => {
  const {
    type = "text",
    error,
    helperText,
    onChange,
    min,
    max,
    inputLabel,
    labelStyle,
    inputBase = true,
    customEndAdornmentText = null,
    customStartAdornmentText = null,
    errorTextStyles,
    formStyles,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const isMobile = useIsMobile();

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const getEndAdornment = (type) => {
    if (type === "password") {
      return <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff sx={(theme) => iconStyle(theme, error)}/> :
            <Visibility sx={(theme) => iconStyle(theme, error)}/>}
        </IconButton>
      </InputAdornment>
    }
    if (customEndAdornmentText) {
      return <InputAdornment position="end">
        <Typography sx={getEndAdornmentTextStyle}>{customEndAdornmentText}</Typography>
      </InputAdornment>
    }
  }

  return (
    <FormControl variant="outlined" fullWidth={true} sx={{...formStyles}}>
      {inputLabel &&
        <MDTypography sx={{
          fontSize: !isMobile ? pxToRem(16) : pxToRem(12),
          mb: pxToRem(1),
          color: white.main, ...labelStyle
        }}>{inputLabel}</MDTypography>
      }
      <StyledTextField
        inputBase={inputBase}
        onChange={(event, x, y) => {
          if (type === "number") {
            let v = parseInt(event.target.value)
            if (min !== null && min !== undefined && v !== null && v !== undefined && v < min) {
              event.target.value = min;
            }
            if (max !== null && max !== undefined && v !== null && v !== undefined && v > max) {
              event.target.value = max;
            }
          }
          if (onChange) {
            onChange(event)
          }
        }}
        fullWidth
        autoComplete='off'
        type={showPassword ? "text" : type}
        endAdornment={getEndAdornment(type)}
        {...rest}
      />
      {error && (
        <FormHelperText>
          <MDTypography
            sx={[{fontSize: !isMobile ? pxToRem(14) : pxToRem(12), color: colors.error.main}, errorTextStyles]}
            variant='span'>{helperText}</MDTypography>
        </FormHelperText>
      )}
    </FormControl>
  )
}

const CustomFormikTextField = (props) => {
  const {name, formik, onChange, key, ...rest} = props
  const inputLabel = FormikFields[name].label
  const type = FormikFields[name].type
  const placeholder = FormikFields[name].placeholder
  const numberRange = {}

  if (type === 'number') {
    if (typeof FormikFields[name].min === 'number') {
      numberRange['min'] = FormikFields[name].min
    }
    if (typeof FormikFields[name].max === 'number') {
      numberRange['max'] = FormikFields[name].max
    }
  }

  const handleChange = (evt) => {
    if (onChange) {
      onChange(evt)
    }
    formik.handleChange(evt)
  }

  return <CustomTextField
    // id={`fmkfld_${name}_${key}`}
    name={name}
    inputLabel={inputLabel}
    type={type}
    placeholder={placeholder}
    value={formik.values[name]}
    onChange={handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    {...numberRange}
    {...rest}
  />
}

const CustomFormikDateField = (props) => {
  const {name, formik} = props

  const content = () => (
    <CustomTextField
      fullWidth
      inputLabel={FormikFields[name].label}
      name={name}
      placeholder={"MM/DD/YYYY"}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  )

  return <InputMask
    mask="99/99/9999"
    value={formik.values[name]}
    onChange={formik.handleChange}
    id={"inc_date_of_birth"}
  >
    {content}
  </InputMask>
}

const DownloadDocument = (props) => {
  const {loginStore} = useStores()
  const userId = {...loginStore}.id;
  const downloadDocument = (data) => {
    loginStore.environment.api
      .getUserDocument(data,{responseType: 'arraybuffer'})
      .then((result) => {
        if (result.kind === 'ok') {
          let contentType = { type: 'image/png' }
          if (data.document_type === 'w8_w9_file' || data.document_type === 'consent_form') {
            contentType = { type: 'application/pdf' }
          }
          if (result.kind === "ok") {
            const file = new Blob([result.data], contentType)
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL, '_blank')
          } else {
            Notification.Error()
          }
        } else {
          Notification.Error()
        }
      }).catch((error) => {
      Notification.Error()
    });
  }
  const {name, formik} = props
  return (<Box onClick={() => window.open(formik.values[name], '_blank')} sx={downloadDocumentStyle}>
    <img src={DocumentUpload} alt={'Doc'}/>
    <Box>
      <MDTypography sx={downloadDocumentLabelStyle}>{FormikFields[name].placeholder}</MDTypography>
    </Box>
  </Box>)
}

export {
  CustomTextField,
  CustomFormikTextField,
  CustomFormikDateField,
  DownloadDocument,
}
