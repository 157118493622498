import React from "react";
import { ReportUserForm } from "./blockUserForm";
import { Box } from "@mui/material";

export const ReportUserMobile = ({ data }) => {
  return (
    <Box sx={{mt:4, ml:2, mr:2}}>
      <ReportUserForm data={data} isMobile={true}/>
    </Box>
  );
};
