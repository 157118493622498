import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export default function Loader({style, show= true}) {
  return (
    <>
      {show && (
        <Box sx={{
          display: 'flex',
          position:"fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          ...style
        }}>
          <CircularProgress />
        </Box>
      )}
    </>

  );
}
