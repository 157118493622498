import React, {useEffect} from "react";
import {observer} from "mobx-react";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {EventsCard} from "./EventsCard";
import {UpcomingsCard} from "./UpcomingsCard";
import {UpcomingsCardMobile} from "./UpcomingsCardMobile";
import {Box} from "@mui/system";
import EventsIcon from "assets/icons/events.svg"
import EventsIconRed from "assets/icons/events-red.svg"
import UpcomingIcon from "assets/icons/upcoming-meets.svg"
import {useIsMobile} from "../../../utils/hooks";
import {MobileSectionHeader} from "../../../components/Mobile/MobileIdolDashboardSectionHeader";
import {EventsMobile} from "./mobile/EventsMobile";
import MobileMeetings from "../dashboard/mobile/mobileMeetings";
import {useStores} from "../../../models";
import {NavigationMenu} from "../../../constants";


export const EventsPage = observer(props => {
  const isMobile = useIsMobile()
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setMobileActiveRoute("EVENTS")
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
  }, []);
  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={EventsIconRed}>
        {isMobile ? (
            <Box>
              <Box>
                <MobileSectionHeader
                  Icon={EventsIcon}
                  Title={"Events"}
                />
                <EventsMobile/>
              </Box>
              <UpcomingsCardMobile isIdolizer={false}></UpcomingsCardMobile>
            </Box>
          )
          :
          <Box sx={{display: "flex", flexDirection: "column", gap: "40px"}}>
            <EventsCard/>
            <UpcomingsCard/>
          </Box>
        }
      </DashboardLayout>
    </BaseProtectedComponentPage>)
})

