import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {red, white, transparent, background, buttons: {mobile}, modal} = colors;

export const styles = {
    modal: {
        background: background.default,
        height: pxToRem(781),
        marginBlockStart: pxToRem(62),
        marginBlockEnd: 0,
        maxHeight: '100%',
        overflowY: 'auto',
        width: pxToRem(390),
        marginInline: 0,
    },
    content_container: {
        marginInline: pxToRem(30),
        marginBlock: `${pxToRem(86)} ${pxToRem(12)}`,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(20),
        paddingInline: '1.5rem'
    },
    error: {
        color: red.main,
        fontSize: pxToRem(12),
        height: pxToRem(16),
        marginLeft: pxToRem(14)
    },
    price_city_section: {
        alignItems: 'flex-end',
        display: 'flex',
        gap: pxToRem(14),
    },
    input_error_box: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    middle_width: {
        width: '50%',
    },
    title_section: {
        color: white.main,
        fontSize: pxToRem(12),
        marginBottom: pxToRem(2),
    },
    date_range_section: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(16),
    },
    form_root: {
        marginRight: 0,
    },
    date_range_inputs_container: {
        alignItems: 'flex-end',
        display: 'flex',
        flex: 1,
        gap: pxToRem(14),
    },
    date_range_input_days: {
        width: pxToRem(126),
    },
    select_box: {
        width: pxToRem(158),
        flexGrow: 1,
    },
    actions: {
        display: 'flex',
        gap: pxToRem(20),
        marginTop: pxToRem(10),
        marginBottom: pxToRem(30),
    },
    cancel_button: {
        backgroundColor: transparent.main,
        borderColor: modal.buttons.cancel,
        height: pxToRem(42),
        padding: 0,
        width: pxToRem(114),
    },
    cancel_button_text: {
        color: modal.buttons.cancel,
        fontSize: pxToRem(12),
    },
    save_button: {
        height: pxToRem(42),
        width: pxToRem(196),
    },
    save_button_text: {
        fontSize: pxToRem(12),
    },
};
