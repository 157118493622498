import colors from "assets/theme/base/colors";

export const styles = {
    inputContainer: {
        width: '100%',
        maxWidth: '514px'
    },
    actions: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        paddingTop: '40px',
        alignSelf: 'center'
    },
    skip: {
        cursor: 'pointer',
        color: colors.white.main,
        textAlign: 'center'
    },
};
