import { Box } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import React, { memo } from "react";
import colors from "../../../../assets/theme/base/colors";

export const ExploreTab = memo(({selected, title, width="105px", onClick})=>{
  return (
    <Box style={{...styles.tab, width:width}} onClick={onClick}>
      <MDTypography style={selected? styles.tabSelectedText : styles.tabText}>
        {title}
      </MDTypography>
      {selected && (
        <Box style={styles.underline} />
      )}
    </Box>
  )
})


const styles = {
  underline:{
    width:"33px",
    height:"1px",
    backgroundColor:colors.primary.main
  },
  tab:{
    width: "105px",
    marginTop:"5px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems:"center"
  },

  tabText:{
    color:colors.white.main,
    fontSize:14,
    fontWeight:600,
    letterSpacing:"0.1em"
  },
  tabSelectedText:{
    color:colors.primary.main,
    fontSize:14,
    fontWeight:600,
    letterSpacing:"0.1em"
  }
}
