import { EXPLORE_ACTIONS } from "./constants";

export const exploreReducer = (state, action) => {
  switch (action.type) {

    case EXPLORE_ACTIONS.SET_LIST_REFRESH:
      return {
        ...state,
        refreshList: action.payload,
      };

    case EXPLORE_ACTIONS.SET_LAYOUT:
      return {
        ...state,
        exploreLayout: action.payload,
      };

    case EXPLORE_ACTIONS.SET_EXPLORE_FILTER:
      return {
        ...state,
        exploreFilter: action.payload,
      };
    case EXPLORE_ACTIONS.SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload,
      };
    case EXPLORE_ACTIONS.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
};
