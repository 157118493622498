import React, { useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PostCategoryEnum } from "../../../constants";
import { Box } from "@mui/material";
import IdolProfileDesktopLayout from "./layout/layout";
import { useApi, useInfiniteScroll, useUserStore } from "../../../utils/hooks";
import { styles } from "./styles";
import Loader from "../../../components/Loader";
import { IdolProfileSummary } from "./IdolProfileSummary";
import { filterField, filtersReducer, initialFilters } from "../reducers";
import NavFilterRow from "./navFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import { ConfirmPaymentModal } from "../../../components/ConfirmPaymentModal";
import { SpecificPostModal } from "../../../components/PostsGrill/SpecificPostModal";
import { MeetRequestedModal } from "../commons/meetRequestedModal";
import { notifyError, notifySuccess } from "../../../services/notification";
import { moneyFormat } from "../../../services/helpers";
import { EditPost } from "../../edit-post/EditPost";
import { AuthenticatedPaths, UnauthenticatedPaths } from "../../../paths/paths";
import PostContent from "../../../components/PostContent/PostContent";
import { IdolTypeEnum } from "../../../utils/constants";
import { NeedLoginModal } from "../../../components/NeedLoginModal";
import {BlockedUserModal} from "components/BlockedUserModal"

export const IdolProfileDesktop = () => {
  const api = useApi()
  const user = useUserStore()
  const location = useLocation()
  const myProfileId = user.idol_id_name
  const [values, setValues] = useState()
  const {id, idol_id_name} = useParams()
  const activeRoute = user.getActiveRoute
  const atProfile = (activeRoute === "profile")
  const isMyProfile = idol_id_name === myProfileId
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)

  const [editing, setEditing] = useState(false)
  const [openPost, setOpenPost] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null)

  const [isInfluencer, setIsInfluencer] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [openConfirmUnlockModal, setOpenConfirmUnlockModal] = useState(false);
  const [showMeetRequestedModal, setShowMeetRequestedModal] = useState(false)
  const [openConfirmPaymentModal, setOpenConfirmPaymentModal] = useState(false);
  const [blockedUserModalDescription, setBlockedUserModalDescription] = useState('');
  const [blockedUserModalOpen, setBlockedUserModalOpen] = useState(false);

  const scrollEl = useRef()

  const [filters, dispatch] = useReducer(
    filtersReducer,
    initialFilters
  );

  const onApiCall = ({page}) => {
    let data = {user_id: profile.id, page, idol_id_name}

    if (filters.typeName !== filterField.all) {
      data["category_codename"] = filters.typeName
    }
    if (filters.contentName !== filterField.all) {
      data["type_codename"] = filters.contentName
    }
    return api.getIdolPosts(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall: onApiCall, queryKey: "posts", filters})

  const navigate = useNavigate()
  const [Counters, setCounters] = useState({all: 0, videos: 0, photos: 0})

  useEffect(() => {
    if (extraData.images) {
      setCounters({
        photos: extraData.images,
        videos: extraData.videos,
        all: extraData.images + extraData.videos
      })
    }

  }, [extraData])

  useEffect(() => {
    if (location.state?.meet) {
      setShowMeetRequestedModal(true)
      // window.history.replaceState({}, document.title)

    }
    getProfile()

  }, [])

  const getProfile = () => {

    api.getIdolProfile(idol_id_name).then(response => {
      if (response.kind === "ok") {
        if (response?.data?.blocked) {
          setBlockedUserModalOpen(true)
          setBlockedUserModalDescription(response?.data?.message)
          setProfile({ username: response?.data?.username })
        } else {
          const data = response.data
          const tags = []
          for (const obj of [data.tag_1, data.tag_2, data.tag_3]) {
            if (obj) {
              tags.push(obj.toUpperCase())
            }
          }

          setIsInfluencer(data.idol_type === IdolTypeEnum.influencer)

          setProfile({
            ...data,
            blocked: data.blocked,
            followers: data.followers,
            idolizers: data.subscribers,
            idolPoints: data.idol_points,
            isVerified: data.is_validated,
            tags: tags,
            bio: data.biography
          })
        }

      } else {
        notifyError("Error getting profile")
      }

    }).catch(reason => {
      // notifyError("Error getting profile")
      console.log(reason)
    })
  }


  useEffect(() => {
    setLoading(false)
  }, [items])


  const handleOpen = (post) => {
    const isVideo = post.post_category.codename === PostCategoryEnum.video
    setSelectedPost({
      ...post,
      tipPrice: post.tip_price,
      isVideo: isVideo,
      isLocked: !post.is_visible,
      idolPost: isVideo ? post.file : post.image
    });
  };


  const confirmTipPayment = () => {
    api.sendTip(selectedPost.id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Tip sent successfully")
        getProfile()
        setOpenConfirmPaymentModal(false)

      } else {
        notifyError("Error sending Tip")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error sending Tip")
    })
  }

  const deletePost = () => {
    api.deletePost(selectedPost.id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Post deleted")
        getProfile()
        setSelectedPost(null)
        setTimeout(() => {
          refetch({refetchPage: (page, index) => index === 0})
        }, 200);
      } else {
        notifyError("Error deleting post")
      }
    }).catch(reason => {
      notifyError(`${reason}`)
    })
  }

  const goToMeetMe = () => {
    if(user?.id){
      navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", selectedPost.user.idol_id_name))
    }else{
      setShowLoginModal(true)
    }
  }

  const unlockPost = ()=>{
    if(user?.id){
      api.additionalFeatureAccessPay(selectedPost.id).then((res) => {
        if (res.kind === "ok"){
          notifySuccess("Photo unlocked successfully")
          const image = res.response.post.image
          window.location.reload()
        }else{
          console.log(res)
          let msg = ""
          Object.keys(res.errors).forEach(function(key,index) {
            msg += res.errors[key]
          });
          if(!msg){
            msg = "Error unlocking this post"
          }
          notifyError(msg)
        }
      }).catch(reason => {
        console.log(reason)
        notifyError("Can't unlock this post")
      })
    }else{
      setShowLoginModal(true)
    }

  }

  const goToEditPost = () => {
    setOpenPost(false)
    setOpenConfirmPaymentModal(false)
    setEditing(true)
  }

  const handleClose = () => {
    setOpenPost(false);
    setOpenConfirmPaymentModal(false);
    setSelectedPost(null)
  };
  useEffect(() => {
    setOpenPost(true)
  }, [selectedPost]);

  const goBack = () => {
    if (location.state?.fromURL) {
      navigate(location.state.fromURL)
    } else {
      navigate(UnauthenticatedPaths.explore)
    }
  }
  const handleClean = () => {
    setSelectedPost(null)
    setEditing(false)
  }

  const editPost = (post_id, formData) =>{
    api.editPost(post_id, formData)
      .then(res => {
        if (res.kind === 'ok') {
          refetch({refetchPage: (page, index) => index === 0})
          setEditing(false)
          notifySuccess("You successfully edited your post", {autoHideDuration: 2000})
        } else if (res.kind === "bad-data") {
          const { errors } = res;
          Object.values(errors).map(e => notifyError(`${e}`, {autoHideDuration: 3000}))
        }
        console.log("Response", res)
      })
      .catch((error) => {
        console.log(error)
      });
  }


  const handleSave = ({values, post_id}) => {
    const formData = new FormData();
    formData.append("description", values.description);

    if(values.price){
      formData.append("additional_price", values.price);
    }
    formData.append("post_type", values.tier); //tier_1/tier_2/tier_3
    editPost(post_id, formData)
  }


  return (
    <React.Fragment>
      {showLoginModal && (<NeedLoginModal open={showLoginModal} onClose={()=>setShowLoginModal(false)} />)}
      <IdolProfileDesktopLayout
        goBack={goBack}
        avatar={profile.image}
        myProfileId={myProfileId}
        idol_id_name={idol_id_name}
        isMyProfile={isMyProfile}
        atProfile={atProfile}
      >
        <Loader show={loading}/>
        {showMeetRequestedModal && (
          <MeetRequestedModal
            open={showMeetRequestedModal}
            meet={location.state.meet}
            onClose={() => setShowMeetRequestedModal(false)}
            onConfirm={() => setShowMeetRequestedModal(false)}
          />
        )}

        <BlockedUserModal
          open={blockedUserModalOpen}
          title={profile?.username}
          description={blockedUserModalDescription}
          onClose={() => setBlockedUserModalOpen(false)}
        />

        <ConfirmPaymentModal
          open={openConfirmUnlockModal}
          message={`Are you sure you want to unlock this post?`}
          confirmAction={() => {
            unlockPost()
            setOpenConfirmUnlockModal(false)
          }}
          confirmLabel={"CONFIRM"}
          cancelAction={() => setOpenConfirmUnlockModal(false)}
        />

        <ConfirmPaymentModal
          open={openConfirmPaymentModal}
          confirmAction={confirmTipPayment} //Mientras solo cierra el modal
          onClose={() => setOpenConfirmPaymentModal(false)} //Mientras solo cierra el modal
          message={`Are you sure you want to tip this user for ${moneyFormat(selectedPost?.tip_price)}?`}
        />
        {selectedPost && (
          <SpecificPostModal
            isExplorePage={false}
            profile={profile}
            content={selectedPost}
            openPost={openPost}
            unlockPost={()=>{
              if(user?.id){
                setOpenConfirmUnlockModal(true)
              }else{
                setShowLoginModal(true)
              }
            }}
            goToMeetMe={goToMeetMe}
            goToEditPost={goToEditPost}
            deletePost={deletePost}
            handleClose={handleClose}
            handleConfirmPaymentModal={() => {
              if(user?.id){
                setOpenConfirmPaymentModal(true)
              }else{
                setShowLoginModal(true)
              }
            }}
          />
        )}
        <Box style={styles.mainContainer}>
          <IdolProfileSummary profile={profile} getProfile={getProfile}/>
          <Box style={styles.rightColumn}>
            {editing ?
              <EditPost
                post={selectedPost}
                handleSave={handleSave}
                sendValues={setValues}
                handleClean={handleClean}
              />
              :
              <>
                <NavFilterRow
                  filters={filters}
                  counters={Counters}
                  loading={loading}
                  user={user}
                  dispatch={dispatch}
                  profile={profile}
                />
                {filters && profile && (
                  <Box style={styles.postsContainer} id={"scroll-content"}>
                    <InfiniteScroll
                      ref={scrollEl}
                      dataLength={items.length}
                      next={() => {
                        if (!isFetchingNextPage) {
                          return fetchNextPage()
                        }
                      }}
                      hasMore={!!hasNextPage}
                      loader={<Loader show={isLoading}/>}
                      scrollableTarget={"scroll-content"}
                    >
                      <Box sx={styles.container}>
                        {items.map(post => <PostContent handleOpen={handleOpen} key={"post-" + post.id} post={post}/>)}
                      </Box>
                    </InfiniteScroll>
                  </Box>
                )}
              </>
            }
          </Box>
        </Box>
      </IdolProfileDesktopLayout>
    </React.Fragment>
  )
}
