/* dependencies */
import PropTypes from 'prop-types';
import moment from 'moment';

/* MUI components */
import {Box} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {MessageInfoCard} from 'base/Cards/InfoCards/MessageInfoCard';

/* assets */
import {styles} from './styles';

export const BaseMessageModal = ({
  open,
  onClose,
  title, 
  avatar,
  nameDisplayed,
  isVerified,
  children,
}) => {
  const baseProps = {
    open, 
    onClose, 
    title,
    centerContent: false,
    modalWidth: '45rem',
    marginInline: 0,
    marginBlockStart: 8,
  };
  const infoProps = {
    nameDisplayed,
    isVerified,
    avatar,
    avatarStyles: styles.avatar,
  };
  return (
    <BaseModal {...baseProps}>
      <Box sx={styles.header}>
        <MessageInfoCard {...infoProps} />
      </Box>
      <Box sx={styles.info_container}>
        {children}
      </Box>
    </BaseModal>
  )
}

BaseMessageModal.propTypes = {
  children: PropTypes.node.isRequired,
}
