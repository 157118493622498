import colors from "../../../assets/theme/base/colors";

export const checkboxStyles = (checked)=>{
  return {
    '&.MuiCheckbox-root .MuiSvgIcon-root ':{
      borderColor: checked?colors.secondary.main : colors.secondary.main,
      color: checked?colors.primary.main:colors.white.main,
    },
    marginLeft:"0px !important",
  }
}
const rowCenter = {
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center"
}
const columnCenter = {
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center"
}

export const styles = {
  rangeFieldError:{
    fontSize:"14px",
    marginTop:"-8px",
    textAlign:"center",
    marginRight:"100px"
  },
  actionButton:{
    width:"30px",
    minWidth:"30px",
    padding:0,
    margin:0
  },
  unavailable:{
    ...rowCenter,
    background:colors.inputBackgroundColor,
    borderRadius:"5px",
    height:"30px"
  },
  rowCenter:rowCenter,
  columnCenter:columnCenter,
  modal:{
    title:{
      fontSize:"16px",
      fontWeight:400,
      textAlign:"center",
      lineHeight:"127%",
      color:colors.white.main
    },
    footerTitle:{
      marginTop:"24px",
      fontSize:"16px",
      fontWeight:400,
      lineHeight:"26px",
      color:colors.white.main
    }
  },
  headerControls:{
    container:{
      display:"flex",
      flexDirection:"row",
      width:"420px",
      justifyContent:"space-between"
    },
    primaryButton:{
      minWidth:"145px",
      maxWidth:"165px",
      height:"40px",
      paddingLeft:"0px",
      paddingRight:"0px",
      letterSpacing:"0.1rem"
    },
    navButtons:{
      display:"flex",
      flexDirection:"row",
      width:"150px",
      justifyContent:"space-between"
    }
  },
  calendarContainer:{
    mainRow:{
      display:"flex",
    }
  },
  availabilityHeaderMobileText: {
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: 400,
    color:colors.grey[480],
  },
  availabilityHeaderWebText: {
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: 400,
    color:colors.grey[480],
  },
  headerWebText: {
    fontSize:"22px",
    lineHeight: "26px",
    color:colors.grey[480]
  },
  headerMobileText: {
    fontSize:"16px",
    lineHeight: "26px",
    color:colors.grey[480]
  },

}

