import React, {useState, useEffect, useRef, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from 'yup';

/* layouts */
import DashboardLayout from "base/LayoutContainers/DashboardLayout";

/* MUI components */
import {Box, Typography, Card, Grid} from '@mui/material';

/* components */
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {ImportContent} from "./ImportContentCard";
import {SecondaryButton} from "components/SecondaryButton";

/* data */
import {contentsData} from "../dashboard/dummyData";

/* assets */
import {styles} from './styles';
import {useApi} from "../../../utils/hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {ThinButtonTransparent} from "../../../components/ThinButtonTransparent";
import {AuthenticatedPaths} from "../../../paths/paths";
import {ProfileCard} from "../../edit-profile/components";
import {CustomFormikTextField} from "../../../components/TextField";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {Form, useFormik} from "formik";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import {showMessage} from "../../../services/helpers";

const REQUIRED_FIELD_MSG = "This field is required"
const scrapperValidationSchema = Yup.object({
  username: Yup.string('').required(REQUIRED_FIELD_MSG),
  email: Yup.string('').required(REQUIRED_FIELD_MSG),
  password: Yup.string('').required(REQUIRED_FIELD_MSG),
});

export const ImportContentPage = () => {
  const api = useApi()
  const scrollRef = useRef()
  const [contents, setContents] = useState([]);
  const [loadingTrigger, setLoadingTrigger] = useState(0);
  const [firstRefresh, setFirstRefresh] = useState(true)
  const [loading, setLoading] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [checkedContents, setCheckedContents] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const navigate = useNavigate();

  const importContentHandler = () => {
    const postsToImport = {import_posts: formik.values.import_posts}
    setLoading(true)
    // console.log('data ', data)
    api.scrapeRequestSavePosts(postsToImport)
      .then(resp => {
        if (resp.kind === 'ok') {
          showMessage('Posts imported successfully', 'success')
        } else {
          showMessage()
        }
        setOpenConfirmModal(false)
      })
      .catch(error => {
        showMessage()
        setOpenConfirmModal(false)
      })
      .finally(() => {
        setLoading(false)
        setTimeout( () => getContents(), 500)
      });
  }

  useEffect(() => {
    getContents()
    const timer = setInterval(() => {
        setLoadingTrigger(+new Date())
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // if (currentRequest === null && currentRequest?.done === false) {
      getContents()
    // }
  }, [loadingTrigger]);

  const getContents = () => {
    setLoading(true)
    api.getScraperRequest().then(resp => {
      setLoading(false)
      if (resp.kind === 'ok') {
        if (JSON.stringify(resp.data) === JSON.stringify(currentRequest)) return
        setCurrentRequest(resp.data)
        formik.setFieldValue('import_posts', [...resp?.data?.posts])
        if (resp?.data?.done) {
          setContents(resp?.data?.posts)
        }else{
          if (scrollRef.current){
            setTimeout(() => (
                scrollRef.current.scrollIntoView()
            ),200)
          }
        }
      }
    }).catch(error => {
      // TODO error
      setLoading(false)
    })
  };

  const requestScrape = (values) => {
    setLoading(true)
    api.createScraperRequest(values)
      .then(resp => {
        if (resp.kind === 'ok') {
          setCurrentRequest(resp.response)
          getContents()
        }
      })
      .catch(error => {
        // TODO error
        // setLoading(false)
      })
      .finally(() => {
        setLoading(false)
        setTimeout( () => getContents(), 500)
      });
  };

  useEffect(() => {
    getContents()
  }, [])

  const formik = useFormik({
    initialValues: {username: '', email: '', password: '', import_posts: []},
    validationSchema: scrapperValidationSchema,
    onSubmit: requestScrape
  })

  const loader = (loading && <CircularProgress sx={styles.scrapperLoader}/>)

  const done = useMemo(()=> (
    <>
      <Box sx={styles.header_container}>
        <Typography sx={styles.header_title}>Import your content </Typography>{loader}
        <Box sx={styles.actions}>
          <SecondaryButton
            text="CANCEL"
            textStyles={{...styles.cancel_button_text, ...styles.button}}
            {...styles.cancel_button}
            onClick={() => [navigate('/profile'), setCheckedContents([])]}
          />
          <SecondaryButton
            text="IMPORT"
            disabled={formik.values.import_posts.length === 0}
            textStyles={styles.button}
            {...styles.import_button}
            onClick={() => setOpenConfirmModal(true)}
          />
        </Box>
      </Box>
      <Box sx={styles.card_container}>
        <Card sx={styles.card}>
          <Box sx={styles.content_container}>
            <Grid container sx={styles.fields_titles_container}>
              <Grid item lg={2}><Typography sx={styles.field_title}>Import</Typography></Grid>
              <Grid item lg={1}><Typography sx={styles.field_title}>Preview</Typography></Grid>
              <Grid item lg={5}><Typography sx={styles.field_title}>Description</Typography></Grid>
              <Grid item lg={2}><Typography sx={styles.field_title}>Tier</Typography></Grid>
              <Grid item lg={2}><Typography sx={styles.field_title}>Price</Typography></Grid>
            </Grid>
          </Box>
          {contents.map((content, index) => {
            return (
              <ImportContent
                key={index}
                index={index}
                content={content}
                checkedContents={checkedContents}
                formik={formik}
                baseFormikName={'import_posts'}
              />
            );
          })}
        </Card>
      </Box>
    </>
  ), [currentRequest, formik.values])

  const start = useMemo(()=> (
    <>
      <Box sx={styles.header_container}>
        <Typography sx={styles.header_title}>Import your content {loader}</Typography>
      </Box>
      {!loading &&
        <Box sx={{...styles.card_container, maxWidth: '562px',}}>
          <Card sx={styles.card}>
            <form onSubmit={formik.handleSubmit}>
              <ProfileCard title={''}>
                <CustomFormikTextField name="username" formik={formik}/>
                <CustomFormikTextField type="email" name="email" formik={formik}/>
                <CustomFormikTextField name="password" formik={formik}/>
              </ProfileCard>
              <Box sx={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: pxToRem(24),
                padding: '0px 20px 0px 20px',
              }}>
                <ThinButtonTransparent onClick={() => navigate(AuthenticatedPaths.profile)}
                                       buttonStyle={{width: '40%'}}
                                       color="secondary" text={"CANCEL"}/>
                <ThinButtonTransparent type="submit" buttonStyle={{width: '40%'}} text={"START"}/>
              </Box>
            </form>
          </Card>
        </Box>
      }
    </>
  ), [currentRequest, formik.values])


  const pending = useMemo(()=> (
    <>
      <Box sx={styles.header_container}>
        <Typography sx={styles.header_title}>Importing content... {loader}</Typography>
      </Box>
      <Box sx={styles.card_container}>
        <Card sx={{...styles.card, px: 3}}>
          <Typography sx={styles.pre}>Current progress:</Typography>
          <Box sx={{...styles.pre, p: 3}}>
            {(currentRequest && !currentRequest.done) && currentRequest.logs}
          </Box>
        </Card>
        <Box ref={scrollRef}>&nbsp;</Box>
      </Box>
    </>
  ), [currentRequest])

  return (
    <BaseProtectedComponentPage loginRequired={true}>
      <DashboardLayout>
        <ConfirmPaymentModal
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          cancelLabel="CANCEL"
          confirmLabel="CONFIRM"
          title="Import posts"
          message="Are you sure you want to import this posts?"
          cancelAction={() => setOpenConfirmModal(false)}
          confirmAction={importContentHandler}
        />
        {currentRequest
          ? currentRequest?.done ? done : pending
          : start
        }
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
}
