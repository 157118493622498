import { AuthenticatedPaths, UnauthenticatedPaths } from "./paths";

export const IdolizerPaths = {
  home: AuthenticatedPaths.dashboard,
  wallet: AuthenticatedPaths.dashboardWallet,
  transactions: AuthenticatedPaths.dashboardTransactions,
  meetings:AuthenticatedPaths.dashboardMeetings,
  idols:AuthenticatedPaths.dashboardIdols,
  blockedUsers:AuthenticatedPaths.blockedUsers,
  termsAndConditions: AuthenticatedPaths.termsAndConditions,
  privacyPolicy: AuthenticatedPaths.privacyPolicy,
  deactivateAccount:AuthenticatedPaths.deactivateAccount,
  notifications:AuthenticatedPaths.notifications,
  profile: AuthenticatedPaths.profile,
  root:UnauthenticatedPaths.root,
  explore:UnauthenticatedPaths.explore,
  viewIdolProfile: UnauthenticatedPaths.viewIdolProfile,
  reportUser: AuthenticatedPaths.reportUser,
  meetMe:AuthenticatedPaths.meetMe,
  videoCall:AuthenticatedPaths.videoCall,
  logout: AuthenticatedPaths.logout,
  getVerified: AuthenticatedPaths.getVerified
}
