import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {Box, Divider, Grid} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {BasePostCard} from "../../../base/Cards/PostCard";
import colors from "../../../assets/theme/base/colors";
import {useApi} from "../../../utils/hooks";
import MDTypography from "../../../components/MDTypography";
import rgba from "../../../assets/theme/functions/rgba";
import {fecha_fmt, moneyFormat} from "../../../services/helpers";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {notifyError, notifySuccess} from "../../../services/notification";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import MDBox from "../../../components/MDBox";

const columnTitles = [
  {title: "From", width: 3},
  {title: 'Date', width: 2},
  {title: 'To', width: 3},
  {title: 'Amount', width: 2},
  {title: 'Action', width: 2}
]

export const AdminTransactionsPage = () => {
  const [Transactions, setTransactions] = useState([])

  const [loading, setLoading] = useState(false)
  const [Page, setPage] = useState(1)
  const [Pages, setPages] = useState(1)

  const [ModalInfo, setModalInfo] = useState({});
  const [showModal, setShowModal] = useState(false);


  const api = useApi()

  const getTransactionsAdmin = (page) => {
    setLoading(true)
    setPage(page)
    api.getTransactionsAdmin('', page).then((result) => {
      if (result.kind === "ok") {
        setTransactions([...Transactions, ...result.data.results])
        setPages(result.data.total_pages)
      }
    }).catch(() => {
      notifyError()
    }).finally(() => setLoading(false))
  }

  const closeModal = () => {
    setModalInfo({})
    setShowModal(false)
  }

  useEffect(() => {
    getTransactionsAdmin(Page)
  }, [])

  const modalContent = <>
    <MDTypography sx={styles.textModal}>
      {`From: ${ModalInfo?.user?.username ? ModalInfo?.user?.username : '-'}`}
    </MDTypography>
    <MDTypography sx={styles.textModal}>
    {`Date: ${fecha_fmt(ModalInfo.invoice_date, 'MM/DD/YYYY HH:mm:ss')}`}
    </MDTypography>
    <MDTypography sx={styles.textModal}>
      {`To: ${ModalInfo?.counterpart?.username ? ModalInfo?.counterpart?.username : '-'}`}
    </MDTypography>
    <MDTypography sx={styles.textModal}>
      {`Amount: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(ModalInfo.amount)}`}
    </MDTypography>
    <MDTypography sx={styles.textModal}>
    {`Description: ${ModalInfo?.description ? ModalInfo?.description : '-'}`}
    </MDTypography>
  
  
 
    {ModalInfo?.card &&
      <MDBox sx={styles.actionButtonContainer}>
        <PrimaryButton
          text="PDF"
          textStyles={styles.actionButtonText}
          buttonStyle={{ ...styles.actionButton, top: '20px' }}
          onClick={() => window.open(
            `${process.env.REACT_APP_API_BASE_URL}api/v1/transaction-pdf/${ModalInfo.id}/`,
            '_blank'
          )}
        />
      </MDBox>
}

  </>

  const renderRow = (row) => {
    return (
      <Grid container my={1} sx={styles.row} key={'id-' + row.id}>
        <Grid item md={2.9} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{row?.user?.username ? row?.user?.username : '-'}</MDTypography>
        </Grid>

        <Grid item md={.1}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={1.9} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{fecha_fmt(row.invoice_date, 'MM/DD/YYYY HH:mm:ss')}</MDTypography>
        </Grid>

        <Grid item md={.1}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={2.9} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{row?.counterpart?.username ? row?.counterpart?.username : '-'}</MDTypography>
        </Grid>

        <Grid item md={.1}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={1.9} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.amount)}</MDTypography>
        </Grid>
        <Grid item md={.1}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={2} sx={styles.actionContainer}>
          <PrimaryButton
            text="SHOW MORE"
            textStyles={styles.actionButtonText}
            buttonStyle={styles.actionButton}
            onClick={() => {
              setModalInfo(row)
              setShowModal(true)
            }}
            disabled={loading}
          />
        </Grid>

      </Grid>
    )
  }

  const loadMoreButton = () => {
    if (Page !== Pages) {
    return <Grid container my={1}>
      <Grid item md={3} />
      <Grid item md={6} sx={{marginTop: '40px'}} display='flex' justifyContent={'center'}>
        <PrimaryButton
          text="LOAD MORE"
          textStyles={styles.actionButtonText}
          buttonStyle={styles.actionButton}
          loading={loading}
          disabled={loading}
          onClick={() => getTransactionsAdmin(Page + 1)}
        />
      </Grid>
      <Grid item md={3} />
    </Grid>
    }
  }

  return (
    <DashboardLayout isAdmin hideFooter>
      <Box sx={{p: 3}}>
        <BasePostCard
          title='TRANSACTIONS'
          titleStyles={{fontSize: "48px"}}
          titleContainerStyles={{height: "73px!important", fontWeight: "400"}}
          headerControlsStyle={{height: "40px", mt: "8px"}}
          icon={null}
        >
          <ConfirmPaymentModal
            disabled={loading}
            open={showModal}
            title={`DETAILS`}
            message=''
            confirmAction={closeModal}
            confirmLabel={"CLOSE"}
            hideCancel={true}
            children={
              modalContent
            }
          />

          <Grid container mt={2}>
            {columnTitles.map((value) => (
              <Fragment>
                <Grid item md={value.width}>
                  <MDTypography textAlign={"center"} fontSize={14} color={"white"}>{value.title}</MDTypography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          {Transactions.map((item) => renderRow(item))}
          {loadMoreButton()}
        </BasePostCard>
      </Box>
    </DashboardLayout>
  )
}

const styles = {
  container: {
    backgroundColor: colors.card.header,
    height: "174px",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginTop: "20px"
  },
  verticalDivider: {
    width: "4px",
    height: "60%",
    background: rgba(colors.background.default, 1)
  },
  divider: {
    height: "98%",
    width: "5px",
    borderRadius: "8px",
    backgroundColor: `${colors.verticalDivider} !important`,
    border: "0px green solid",
  },
  cardContainer: {
    margin: '0 auto',
  },
  title: {
    color: colors.white.main,
    fontWeight: 'lighter',
    textDecoration: "uppercase",
    fontSize: "36px",
    borderBottom: `2px solid ${colors.primary.main}`,
  },
  row: {
    background: colors.card.header,
    borderRadius: "5px",
    height: '60px',
  },
  text: {
    color: rgba("white", .6),
    fontSize: 16,
    fontWeight: "400"
  },
  textModal: {
    color: rgba("white", .6),
    fontSize: 18,
    fontWeight: "700",
    marginTop: '15px'
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  actionContainer: {
    background: colors.background.default,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  actionButton: {
    borderRadius: "10px",
    width: "70%",
    height: "32px"
  },
  actionButtonText: {
    fontSize: 14,
    fontWeight: 400
  }
}
