import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const {white, red} = colors;

export const styles = {
    header: {
        paddingTop: pxToRem(14),
        paddingX: pxToRem(22),
        paddingBottom: 0,
    },
    headerWide: {
        paddingTop: pxToRem(14),
        paddingLeft: pxToRem(22),
        paddingRight: pxToRem(40),
        paddingBottom: 0,
    },
    avatar: {
        height: pxToRem(55),
        width: pxToRem(55),
        cursor: 'pointer',
    },
    subheader: {
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
    },
    name: {
        color: white.main,
    },
    points: {
        color: red.main,
        fontWeight: 'bold',
        fontSize: pxToRem(14)
    },
    points_idp: {
        color: red.main,
        fontSize: pxToRem(10),
        fontWeight: 'bold',
    },
    event: {
        color: red.main,
        fontSize: pxToRem(12),
        fontWeight: 'bold',
    },
    centered_points_idp: {
        paddingTop: '7px',
    },
    verified_text_container: {
        padding: '20px 10px',
    },
    verified_text: {
        color: '#abafb5'
    },
    checked_img: {
        position: 'absolute',
        right: 0,
        top: 40,
        width: '40%',
    },
}
