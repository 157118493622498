import { AuthenticatedPaths, UnauthenticatedPaths } from "./paths";

export const IdolPaths  = {
  home: AuthenticatedPaths.dashboard,
  idolBank: AuthenticatedPaths.dashboardBank,
  idolPay: AuthenticatedPaths.dashboardIdolPay,
  events: AuthenticatedPaths.dashboardEvents,
  calendar: AuthenticatedPaths.dashboardCalendar,
  prices: AuthenticatedPaths.dashboardPrices,
  idolizers: AuthenticatedPaths.dashboardIdolizers,
  getVerified: AuthenticatedPaths.getVerified,
  referralLink: AuthenticatedPaths.referralLink,
  blockedUsers: AuthenticatedPaths.blockedUsers,
  termsAndConditions: AuthenticatedPaths.termsAndConditions,
  privacyPolicy: AuthenticatedPaths.privacyPolicy,
  deactivateAccount: AuthenticatedPaths.deactivateAccount,
  notifications: AuthenticatedPaths.notifications,
  profile: AuthenticatedPaths.profile,
  root: UnauthenticatedPaths.root,
  explore: UnauthenticatedPaths.explore,
  addNewPost: AuthenticatedPaths.addNewPost,
  viewIdolProfile: UnauthenticatedPaths.viewIdolProfile,
  reportUser: AuthenticatedPaths.reportUser,
  meetMe: AuthenticatedPaths.meetMe,
  videoCall: AuthenticatedPaths.videoCall,
  logout: AuthenticatedPaths.logout,
  idolReady: AuthenticatedPaths.idolReady,
  wallet: AuthenticatedPaths.dashboardWallet,
  transactions: AuthenticatedPaths.dashboardTransactions,
  meetings:AuthenticatedPaths.dashboardMeetings,
}
