import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';
const {card, white} = colors;

export const styles = {
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
    cardHeader:{
      background: card.header,
      borderRadius: '10px 10px 0 0',
      position:'absolute',
      top: 0,
      width: '100%',
      ".MuiCardHeader-action": {
        alignItems: 'center',
        display: 'flex',
        margin: 0,
      }
    },
    color: white.main,
    icon_smaller: {
      width: pxToRem(24),
      height: pxToRem(24),
    },
    icon_greater: {
      width: pxToRem(30),
      height: pxToRem(30)
    }
};
