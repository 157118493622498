import { Box, Typography } from "@mui/material";
import PriceTagIcon from "assets/icons/price-tag.svg";
import colors from "assets/theme/base/colors";
import { defaultBundle } from "components/AddBundle/utils/defaultBundle";
import { MobileSectionHeader } from "components/Mobile/MobileIdolDashboardSectionHeader";
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { BaseModal } from "base/BaseModal";
import PriceBaseCard from "base/Cards/PriceBaseCard/PriceBaseCard";
import PriceCard from "base/Cards/PriceCard/PriceCard";
import AddBundle from "components/AddBundle/AddBundle";
import removeBundle from "components/AddBundle/utils/removeBundle";
import { PrimaryButton } from "components/PrimaryButton";
import { SecondaryButton } from "components/SecondaryButton";
import { notifyError, notifySuccess } from "services/notification";
import { useApi, useIsMobile } from "utils/hooks";
import { IdolBundlesSection } from "./IdolBundlesSection";
import { IdolPromotionsSection } from "./IdolPromotionsSection";

const {card} = colors;


export const PricesCardMobile = memo((props) => {
  const [showDeleteBundleModal, setShowDeleteBundleModal] = useState(false)
  const api = useApi()
  const [Tip, setTip] = useState(0)
  const [Subcription, setSubcription] = useState(0)

  const [Fees, setFees] = useState({
    tip:{
      value:0
    },
    subscription:{
      value:0
    }})
  const [MinimumPrices, setMinimumPrices] = useState({tip: 0, subscription:0})
  const [modalManager, setModalManager] = useState({
    base: false,
    createEvent: false,
    cancelMeeting: false,
    locationDetails: false,
  });


  const {base, createEvent, cancelMeeting, locationDetails} = modalManager;

  const getFees  = ()=>{
    api.getPrices().then(response => {
      if (response.kind === "ok") {
        let fees = {}
        for (const obj of response.data.fees){
          fees[obj.operation_concept.codename] = {
            value: obj.value,
            operation_concept: obj.operation_concept.id
          }
        }
        setTip(parseFloat(fees.tip.value))
        setSubcription(fees.subscription.value)
        setFees(fees)
        setMinimumPrices({
          tip: response.data.minimum_tip,
          subscription: response.data.minimum_subscription,
        })
      }
    }).catch(reason => {
      console.log(reason)
    })
  }



  const saveFees = (data)=>{
    api.patchPrices(data).then(response => {
      if (response.kind === "ok") {
        notifySuccess('Saved Successfully')
        getFees()
      }else{
        notifyError('Error Saving amount')
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  useEffect(() => {
    getFees()
  },[])

  const handleDelete = (bundles, bundleId, setBundles)=>{
    setShowDeleteBundleModal(false)
    removeBundle(bundles, bundleId, setBundles)
    notifySuccess("Bundle deleted successfully!")

  }
  const saveTip = (amount)=>{
    const payload = {fees: [
        {
          operation_concept: Fees.tip.operation_concept,
          value: amount
        },
        {
          ...Fees.subscription
        }
      ]}
    saveFees(payload)
  }

  const saveSubscription = (amount)=>{
    const payload = {fees: [
        {
          ...Fees.tip
        },
        {
          operation_concept: Fees.subscription.operation_concept,
          value: amount
        }
      ]}
    saveFees(payload)
  }



  return (

    <Box>
      <MobileSectionHeader Icon={PriceTagIcon} Title="Prices" />
      <BaseModal
        open={showDeleteBundleModal}
        onClose={()=>setShowDeleteBundleModal(false)}
        title={"Remove Bundle"}>
        <Box sx={styles.deleteModalContainer}>
          <Typography>Are you sure that you want to delete this bundle?</Typography>
          <Box sx={{  display: 'flex', justifyContent: 'center', marginTop: '40px', width:"400px", gap: '40px' }}>

            <SecondaryButton
              size='small'
              buttonStyle={{width:"250px"}}
              text='Cancel'
              onClick={()=> setShowDeleteBundleModal(false)}
            />

            <PrimaryButton
              size='small'
              buttonStyle={{width:"250px"}}
              text='Confirm'
              onClick={() => handleDelete()}
            />
          </Box>
        </Box>
      </BaseModal>

      <Box sx={styles.container}>
        <PriceBaseCard isMobile={true} title='Tips' >
          {Tip && (
            <PriceCard title={`Set price ${Tip}`}
                       tipAmount={Tip}
                       minimum={MinimumPrices.tip}
                       subtitle={`Minimum tip is ${MinimumPrices.tip} $`}
                       save={(amount)=>saveTip(amount)}
            />
          )}
        </PriceBaseCard>
        <PriceBaseCard isMobile={true} title='Monthly Charge'>
          {Subcription && (
            <PriceCard title='Set price'
                       tipAmount={Subcription}
                       minimum={MinimumPrices.subscription}
                       save={(amount)=>saveSubscription(amount)}
                       subtitle={`Minimum price is ${MinimumPrices.subscription}$ 
                            ${parseInt(MinimumPrices.subscription) === 0? "(free)":""}`}/>
          )}
        </PriceBaseCard>
      </Box>


      <Box sx={{ ...styles.rowCenter, width: '100%', gap: '20px', marginBottom:"100px"}}>
        <IdolBundlesSection monthlyCharge={Subcription} />
        <IdolPromotionsSection monthlyCharge={Subcription} />
      </Box>
    </Box>

  );
})


export const styles = {
  deleteModalContainer:{
    display: 'flex',
    justifyContent: 'center',
    flexDirection:"column",
    alignItems:"center"
  },
  container:{
    display: 'flex',
    justifyContent: 'center',
    flexDirection:"column",
    width: '100%',
  },
  bundlesContainer:{
    width:"100%",
    height:"350px",
    marginTop:"30px",
    overflowY:"scroll"
  },
  headerControls:{
    display:"flex",
    flexDirection:"row",
    width:"500px",
    gap:"15px",
    alignItems:"flex-end !important",
    justifyContent:"flex-end !important",
    height:"75px !important",
    marginBottom:"-15px",
    marginRight:"50px"
    // margin:"0px"
  },

  button: {
    height:"75px !important",
    borderRadius:"10px 10px 0px 0px"
  },

};
