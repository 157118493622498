/* dependencies */
import React from "react";
import PropTypes from "prop-types";

/* MUI components */
import { Box, Typography } from "@mui/material";

/* components */
import { BaseIdolCard } from "base/Cards/IdolCard";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { CardEventActionsBox } from "components/BaseCardEventActionsBox";
import { DatePriceEvent } from "components/DatePriceEventTracker";

/* assets */
import { styles } from "./styles";
import world from "assets/images/map.png";

export const IdolMeetingCard = ({
                                  title,
                                  idolizerName,
                                  idolPoints,
                                  avatar,
                                  isVerified,
                                  price,
                                  date,
                                  timeStart,
                                  timeEnd,
                                  timeZone,
                                  meetingType,
                                  onDismiss,
                                  onApprove,
                                  approveButtonTextStyles,
                                  dismissButtonTextStyles,
                                  idolcall,
                                  isIdol,
                                }) => {
  const cardProps = {
    title,
    cardStyles: styles.baseCard,
    headerStyles: styles.header,
  };

  const infoProps = {
    idolizerName,
    idolPoints,
    isVerified,
    nameStyles: styles.name,
    pointStyles: styles.points,
    avatar: avatar,
  };

  const eventProps = {
    price,
    date,
    timeStart,
    timeEnd
  };

  return (
    <BaseIdolCard {...cardProps}>
      <Box sx={styles.idolInfo}>
        <BaseCardIdolInfo {...infoProps} />
        <Typography sx={styles.idolcallText}>{idolcall}</Typography>
      </Box>
      <Box sx={styles.content}>
        <DatePriceEvent {...eventProps} />
        <Box sx={[styles.general_info, styles.timezoneContainer]}>
          <Box sx={styles.datetime_info_container}>
            <Box>
              <img src={world} alt="world icon" title="world" />
            </Box>
            <Box>
              <Typography sx={styles.datetime_info_text} variant='subtitle2'>{timeZone ? timeZone : 'not specified'}</Typography>
            </Box>
          </Box>
          <Typography sx={styles.meetingType} variant='subtitle1'>
            {meetingType}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.actions_container}>
        <CardEventActionsBox
          approveDisabled={!isIdol}
          approveText={!isIdol?'Pending':'Approve'}
          dismissText={!isIdol?'Cancel':'Dismiss'}
          approveAction={onApprove}
          dismissAction={onDismiss}
          approveTextStyles={approveButtonTextStyles}
          dismissTextStyles={dismissButtonTextStyles}
        />
      </Box>
    </BaseIdolCard>
  );
};

IdolMeetingCard.propTypes = {
  title: PropTypes.string.isRequired,
  idolizerName: PropTypes.string.isRequired,
  idolPoints: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  meetingType: PropTypes.oneOf(['idolevent', 'idolcall']).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  avatar: PropTypes.string,
  isVerified: PropTypes.bool,
};
