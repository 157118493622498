import AllUsersIcon from "../assets/icons/all_users.svg";
import VerificationIcon from "../assets/icons/verification.svg";
import EarningIcon from "../assets/icons/earnings.svg";
import IdolPointsIcon from "../assets/icons/star.svg";
import VideoChatIcon from "../assets/icons/video-chat-tabs-sm.svg";
import ReservedNamesIcon from "../assets/icons/reserved-names-icon.svg";
import ImportedContentIcon from "assets/icons/import-content.svg";
import FeedbackIcon from "assets/icons/feedback.svg";
import LiveChatIcon from "../assets/icons/live_chat.svg";
import FeesIcon from "assets/icons/fees.svg";
import WalletIcon from "assets/icons/wallet-idol-bank.svg";
import ReportedIcon from "assets/icons/Prohibit.svg";

export const adminDesktopMenu = {
  dashboard: {
    home: {
      noCollapse: true,
      name: 'All Users',
      icon: AllUsersIcon,
      type: 'collapse',
    },
    verification: {
      noCollapse: true,
      name: 'Verification',
      icon: VerificationIcon,
      type: 'collapse',
    },
    earnings: {
      noCollapse: true,
      name: 'Earnings',
      icon: EarningIcon,
      type: 'collapse',
    },
    fees: {
      noCollapse: true,
      name: 'Fees',
      icon: FeesIcon,
      type: 'collapse',
    },
    idolPoints: {
      noCollapse: true,
      name: 'Idol Points',
      icon: IdolPointsIcon,
      type: 'collapse',
    },
    meetingTypes: {
      noCollapse: true,
      name: 'Meeting Types',
      icon: VideoChatIcon,
      type: 'collapse',
    },
    importedContent: {
      noCollapse: true,
      name: "Imported Content",
      icon: ImportedContentIcon,
      type: "collapse",
    },
    reportedUsers: {
      noCollapse: true,
      name: "Reported Users",
      icon: ReportedIcon,
      type: "collapse",
    },
    transactions: {
      noCollapse: true,
      name: "Transactions",
      icon: WalletIcon,
      type: "collapse",
    },
    reservedNames: {
      noCollapse: true,
      name: "Reserved Names",
      icon: ReservedNamesIcon,
      type: "collapse",
    },
    feedback: {
      noCollapse: true,
      name: "Feedback",
      icon: FeedbackIcon,
      type: "collapse",
    },
    liveChat: {
      noCollapse: true,
      name: 'Live Chat',
      icon: LiveChatIcon,
      type: 'collapse',
    },
  },
  settings: {

  }
}
