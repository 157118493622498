import React from 'react';

/* MUI components */
import {Box} from '@mui/system';

/* components */
import {MobileSectionHeader} from "components/Mobile/MobileIdolDashboardSectionHeader";

/* assets */
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {card} = colors;

export const BaseMobileTipsTransactionsCard = ({Title, Icon, children}) => {
  const headerProps = {Title, Icon};
  return (
    <Box>
      <Box sx={styles.container}>
        {children}
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    background: card.background,
    borderRadius: '10px',
    display: 'flex',
    height: '390px',
    overflow: 'auto',
    flexDirection: 'column',
    gap: pxToRem(12),
    marginTop: pxToRem(12),
    padding: `${pxToRem(12)} ${pxToRem(9)}`,
  },
};
