import MDBox from "../../../components/MDBox";
import colors from "assets/theme/base/colors"
import {CardNumberItem} from "../../../components/CardNumberItem";
import {Box} from "@mui/material";
import {useUserStore} from "../../../utils/hooks";
import {memo, useEffect, useMemo, useState} from "react";
import {addCommas} from "../../../utils/functions";

export const Summary = memo((props)=>{
  const userStore = useUserStore()
  const {data, isInfluencer} = props
  return (
    <Box style={styles.container}>
      { (userStore.isIdol && !isInfluencer) && (
        <>
            <CardNumberItem
              content={`$${addCommas(data.idolPay)}`}
              secondaryContent='idolpay'
              containerStyles={styles.card_number_container}
            />
          <Box sx={styles.divider} />
        </>
      )}

      <CardNumberItem
        content={`${addCommas(data.idolPoints)}`}
        secondaryContent='idolpoints'
        containerStyles={styles.card_number_container}
      />

      { (userStore.isIdol && !isInfluencer) && (
        <>
          <Box sx={styles.divider} />

          <CardNumberItem
            content={`${addCommas(data.idolizers)}`}
            secondaryContent='idolizers'
            containerStyles={styles.card_number_container}
          />
        </>
      )}
    </Box>
  )
})

const styles = {
  container:{
    backgroundColor:colors.background.container,
    height:"174px",
    borderRadius:"10px",
    padding:"20px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
    paddingLeft:"30px",
    paddingRight:"30px",
  },
  divider:{
    height: "98%",
    width:"5px",
    borderRadius:"8px",
    backgroundColor:`${colors.verticalDivider} !important`,
    border:"0px green solid",
  },
  card_number_container: {
    margin: '0 auto'
  }
}
