import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
export const DATE_FORMAT = "YYYY-MM-DD"
export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const TIME_FORMAT = "HH:mm"


export const isFormat = (checked_date, date_format)=>{
  return moment(checked_date, date_format).format(date_format) === checked_date

}

export const checkTimeOverlap = (ranges)=>{

  const intervals = []
  for(const obj of ranges){
    intervals.push(moment.range(AMPMtimeToMoment(obj.from_time), AMPMtimeToMoment(obj.to_time)))
  }
  let overlap = false
  let cleanedData = [intervals[0]]
  for (let i  = 1; i < intervals.length; i++){
    const obj = intervals[i]
    const aux = [...cleanedData]
    for(let j = 0; j < aux.length; j++){
      const cleaned = aux[j]
      overlap = obj.overlaps(cleaned, { adjacent: false })
      if(!overlap){
        cleanedData.push(obj)
      }else{
        return false
      }
    }
  }
  return true
}


export const AMPMtimeToMoment = (time)=>{
  /*
   if the time is UTC+3
   AMPMtimeToMoment("05:00 AM") => moment instance
  */
  return moment(moment().format('YYYY-MM-DD ') + time);
}


export const timeZoneTimezoneConverter = (data, toUTC=false)=>{
  const offset = new Date().getTimezoneOffset();
  let res = null
  if (toUTC){
    res =  moment(data).add('minutes',offset)
  }else{
    res =  moment(data).subtract('minutes',offset)
  }
  return res
}

const timeZoneConverter = (data, toUTC=false)=>{
  const offset = new Date().getTimezoneOffset();
  let res = moment().format('yyyy-MM-DD')
  if (toUTC){
    res =  moment(`${res} ${data}`).add('minutes',offset)
  }else{
    res =  moment(`${res} ${data}`).subtract('minutes',offset)
  }
  return res
}

export const timeToLocal = (data)=>{

  return timeZoneConverter(data).format(' hh:mm A')
}

export const timeToUTC = (data)=>{
  return timeZoneConverter(data, true)
}

export const datetimeToLocal = (data)=>{
  const offset = new Date().getTimezoneOffset();
  return  moment(data).add('minutes',offset)
}
export const getDisabledDaysBetween = ({ fromDate, toDate, dates })=>{
  let arrDays = [];
  let currentDate = moment(fromDate).startOf("month")

  const days = dates.map((value)=> moment(value).format(DATE_FORMAT))
  while (currentDate.format(DATE_FORMAT) <= toDate.format(DATE_FORMAT)){
    if(days.indexOf(currentDate.format(DATE_FORMAT)) === -1){
      arrDays.push(currentDate.toDate());
    }
    currentDate = currentDate.add("day", 1)
  }
  return arrDays
}


export const getDaysFromWeekdays = ({weekdays, fromDate, toDate}) =>{
  let arrDays = [];
  let currentDate = moment(fromDate)

  while (currentDate.format(DATE_FORMAT) !== toDate.format(DATE_FORMAT)){
    if(weekdays.indexOf(currentDate.day()) >= 0){
      arrDays.push(currentDate.toDate());
    }
    currentDate = currentDate.add("day", 1)
  }
  return arrDays
}

export const generateMoment = (target_date, time)=>{
  const offset = new Date().getTimezoneOffset();
  const aux =  moment(target_date).format("yyyy-MM-DD ")+time
  return moment(aux).subtract("minutes", offset)

}

const isOverlapping = (meetings, start, end )=>{
  let overlaping = false
  for(const meet of meetings){
    if(moment(meet.from_time).isBetween(start,end, undefined, '[]')){
      overlaping = true
      break
    }else if(moment(meet.to_time).isBetween(start,end, undefined,'[]' )){
      overlaping = true
      break
    }else if( start.isBetween(moment(meet.from_time), moment(meet.to_time), undefined,'[]' )){
      overlaping = true
      break
    }else if( end.isBetween(moment(meet.from_time), moment(meet.to_time), undefined,'[]' )){
      overlaping = true
      break
    }
  }
  return overlaping
}

const fixTimezoneIssue = (datetime, time) => {
  try {
    const d = datetime.split('T')
    let date = `${d[0]}T${time}`
    return date
  } catch {
    return datetime
  }
}

export const splitAvailableTimes = ({timeRanges, event_date, event_duration, event_step,
                                      approvedMeetings})=>{
  const midnight = moment().startOf('day');
  const duration = moment(event_duration,'HH:mm').diff(midnight, "minutes")
  const step = moment(event_step,'HH:mm').diff(midnight, "minutes")
  let times = []

  console.log(' splitAvailableTimes ===>>> ', {
    timeRanges,
    event_date,
    event_duration,
    event_step,
    approvedMeetings,
    duration,
    midnight,
  })

  // when the idol has custom hours set sould override the weekly
  const had_custom = timeRanges.find(t => t.time_range_type_code === 'day')
  const timeRangesAvaliable = had_custom
    ? timeRanges.filter(t => t.time_range_type_code === 'day')
    : timeRanges

  const offset = new Date().getTimezoneOffset();

  for (const range of timeRangesAvaliable){
    
    let start = range.from_datetime ? moment(fixTimezoneIssue(range.from_datetime, range.from_time)).subtract("minutes", offset) : generateMoment(event_date, range.from_time)
    const end = range.to_datetime ? moment(fixTimezoneIssue(range.to_datetime, range.to_time)).subtract("minutes", offset) : generateMoment(event_date, range.to_time)
    
    console.log(' offset ===>>> ', {offset, start, end})

    let start_minutes = start.diff(midnight,'minutes')
    let end_minutes = end.diff(midnight,'minutes')

    while (start_minutes < end_minutes){
      if(!isOverlapping(approvedMeetings, start, start.clone().add("minutes", duration))){
        times.push({
          from: moment(start, "America/Asuncion").format('hh:mm a'),
          start:start.clone(),
          end: start.clone().add("minutes", duration)
        })
      }
      start.add("minutes", duration + step )
      start_minutes += duration + step
    }
  }
  // remove duplicate
  times = [...new Map(times.map(item => [item.from, item])).values()]
  return times

}


export const formatDurationFromMinutes = (number_of_minutes) => {
  // Convert minutes to milliseconds
  const milliseconds = number_of_minutes * 60 * 1000;

  // Create a Moment.js duration object
  const duration = moment.duration(milliseconds);

  // Format the duration as 'd HH:mm:ss'
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${days} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export const humanizeDuration = (durationString) => {
  let days = 0;
  let hours, minutes, seconds;

  // Check if the durationString contains days
  if (durationString.includes(' ')) {
    [days, durationString] = durationString.split(' ');
  }

  // Extract hours, minutes, and seconds from the input string
  [hours, minutes, seconds] = durationString.split(':');

  // Build the formatted duration string
  const duration = [];

  if (parseInt(days) > 0) {
    duration.push(`${parseInt(days)} ${parseInt(days) === 1 ? 'day' : 'days'}`);
  }
  if (parseInt(hours) > 0) {
    duration.push(`${parseInt(hours)} ${parseInt(hours) === 1 ? 'hour' : 'hours'}`);
  }
  if (parseInt(minutes) > 0) {
    duration.push(`${parseInt(minutes)} ${parseInt(minutes) === 1 ? 'minute' : 'minutes'}`);
  }
  if (parseInt(seconds) > 0) {
    duration.push(`${parseInt(seconds)} ${parseInt(seconds) === 1 ? 'second' : 'seconds'}`);
  }

  // Join the duration components and return the result
  return duration.join(', ');
};