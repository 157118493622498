import pxToRem from 'assets/theme/functions/pxToRem';

export const styles = {
    selector_styles: {
        display: 'flex',
        gap: pxToRem(40),
        marginTop: pxToRem(16),
    },
    card_container: {
        padding: `${pxToRem(11)} 0px`
    },
    avatar: {
        height: pxToRem(55),
        width: pxToRem(54.86),
    },
    name: {
        fontSize: pxToRem(14)
    },
    points_container: {
        alignItems: 'flex-end',
    },
    card: {
        width: pxToRem(267),
        height: pxToRem(77),
    },
    scroll:{
        padding:"0px 25px 0px 10px",
        margin:"24px -10px 0px -10px",
        maxHeight:"80vh",
        minHeight:"300px"
    },
};
