/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* mui components */
import {CardHeader, Typography} from '@mui/material';

/* components */
import {SubHeader} from './SubHeader';
import {UnverifiedIdolAvatar, VerifiedIdolAvatar} from './IdolAvatars';
import {IdolizerText} from './IdolizerText';

/* assets */
import DefaultAvatar from 'assets/images/explore-default.png';
import {styles} from './styles';

export const BaseCardIdolInfo =
  ({
     avatar,
     idolizerName,
     idolPoints,
     isVerified,
     isIdolizer,
     isMobile = false,
     pointStyles,
     infoCardStyles,
     pointContainerStyles,
     nameStyles,
     centerPoints,
     avatarStyles,
     idpStyles,
     avatarContainerStyle,
     verifiedIconStyle,
     onClick,
     showPoints = true,
   }) => {
  let mainStyle = isMobile? styles.mobile_header : styles.header
  mainStyle = {...mainStyle, ...infoCardStyles}
  return (
    <CardHeader
      onClick={()=>{
        if(onClick){
          onClick()
        }
      }}
      avatar={
        !isVerified ? (
          <UnverifiedIdolAvatar avatarSrc={avatar} avatarStyles={avatarStyles} isMobile={isMobile} />
        ) : (
          <VerifiedIdolAvatar avatarSrc={avatar}
                              avatarContainerStyle={avatarContainerStyle}
                              verifiedIconStyle={verifiedIconStyle}
                              avatarStyles={avatarStyles} isMobile={isMobile} />
        )
      }
      sx={mainStyle}
      title={<Typography sx={[styles.name, isMobile && styles.mobile_name, nameStyles]}>{idolizerName}</Typography>}
      subheader={
        showPoints &&
        <SubHeader
          data={idolPoints}
          pointStyles={pointStyles}
          centerPoints={centerPoints}
          containerStyles={pointContainerStyles}
          isMobile={isMobile}
          idpStyles={idpStyles}
        />
      }
      action={isIdolizer && <IdolizerText />}
    />
  );
};

BaseCardIdolInfo.propTypes = {
  idolizerName: PropTypes.string.isRequired,
  idolPoints: PropTypes.number.isRequired,
  isVerified: PropTypes.bool,
  isIdolizer: PropTypes.bool,
  pointStyles: PropTypes.object,
  nameStyles: PropTypes.object,
}

BaseCardIdolInfo.defaultProps = {
  isVerified: false,
  isIdolizer: false,
  avatar: DefaultAvatar,
}
