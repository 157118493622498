import { Toolbar } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import { useLocation, Link } from "react-router-dom"
import MDTypography from "../../../../components/MDTypography";
import NavbarLogo from "assets/images/navbar-logo.svg";
import { navbarContainer, navbarRow } from "base/Navbars/DashboardNavbar/styles";
import {useStores} from "../../../../models";
import {AuthRoutes} from "../../../../routes";
import { Fragment } from "react";


export const DesktopMainToolbar = ({isAdmin}) => {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const activeRoute = loginStore.getActiveRoute
  const IsIdol = loginStore.isIdol
  const IsIdolizer = loginStore.isIdolizer
  let toolbarAlignment = loginStore.id?"space-around":"space-between"
  if(isAdmin){
    toolbarAlignment = "flex-end"
  }

  return (
    <Toolbar sx={(theme) => navbarContainer(theme)} >
      <MDBox color="inherit" mb={{ xs: 1, md: 0 }} style={{flexGrow:1}} width={"100%"}  sx={(theme) => navbarRow(theme,{isMini:false} )}>
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          justifyContent={toolbarAlignment}
          ml={loginStore.id?0:10}
          mr={loginStore.id && !isAdmin?0:10}

          width={"100%"}
          zIndex={1}
        >
          {isAdmin ? (
            <Link to={`${AuthRoutes.logout}`}>
              <MDTypography color={"white"}>LOGOUT</MDTypography>
            </Link>
          ) : (
            <Fragment>
              {loginStore.id && (
                <Link to={"/dashboard/home"}>
                  <MDTypography color={"white"} fontWeight={(activeRoute === 'dashboard') && 'bold'}>
                    DASHBOARD
                  </MDTypography>
                </Link>
              )}

              <Link to="/explore/">
                <MDTypography color={"white"} fontWeight={(activeRoute === 'explore') && 'bold'}>
                  EXPLORE
                </MDTypography>
              </Link>

              {!loginStore.id && (
                <Link to={`${AuthRoutes.login}`}>
                  <MDTypography color={"white"}>LOGIN</MDTypography>
                </Link>
              )}

              {loginStore.id && (
                <Fragment>
                  <Link to="/notifications/">
                    <MDTypography color={"white"} fontWeight={(activeRoute === 'notifications') && 'bold'}>
                      NOTIFICATIONS
                    </MDTypography>
                  </Link>

                  <Link to={IsIdol && ("/settings/get-verified/") || IsIdolizer && ("/settings/blocked-users/")}>
                    <MDTypography color={"white"} fontWeight={(activeRoute === "settings") && 'bold'}>
                      SETTINGS
                    </MDTypography>
                  </Link>

                  <Link to={`${AuthRoutes.logout}`}>
                    <MDTypography color={"white"}>LOGOUT</MDTypography>
                  </Link>
                </Fragment>
              )}
            </Fragment>
          )}


        </MDBox>
      </MDBox>
      <MDBox style={{marginTop: -45, marginBottom:-10, position: 'absolute', right: 0, top: 0}} mr={0} pr={3}>
        <img src={NavbarLogo} width={"520px"} />
      </MDBox>
    </Toolbar>
  )
}
