import React, {useState} from 'react';
import {Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {useStores} from "../../../../models";
import {Box, Stack} from "@mui/material";
import {notifyError} from "../../../../services/notification";
// @ts-ignore
import pxToRem from 'assets/theme/functions/pxToRem';
// @ts-ignore
import {PrimaryButton} from "components/PrimaryButton";
// @ts-ignore
import {SecondaryButton} from "components/SecondaryButton";
import LocalVideoPreview from "./LocalVideoPreview/LocalVideoPreview";
import {AuthRoutes} from "../../../../routes";
import {useNavigate} from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    // border: '2px solid #aaa',
    // margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
    color: 'white',
  },
}));

interface DeviceSelectionScreenProps {
  room: string;
}

export default function DeviceSelectionScreen({room}: DeviceSelectionScreenProps) {
  const rootStore = useStores();
  const {loginStore, meetingStore} = rootStore;
  const api = loginStore.environment.api;
  const [isFetching, setIsFetching] = useState(false);
  const classes = useStyles();
  const {connect: chatConnect} = useChatContext();
  const {connect: videoConnect, isAcquiringLocalTracks, isConnecting} = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const navigate = useNavigate();

  const getMeeting = () => {
    api.getMeetingByRoomName(room).then(res => {
      if (res.kind === 'ok') {
        const meeting = res.data
        meetingStore.setCurrentMeeting(meeting)
        // @ts-ignore
        meetingStore.setIsHost(meeting.to_user.id === loginStore.id)
        startMeeting()
      } else {
        meetingStore.reset()
        notifyError("You cannot enter into this meeting")
      }
    })
  }

  const startMeeting = () => {
    if (isFetching) return

    setIsFetching(true)
    api.getVideoToken(room)
      .then(res => {
        if (res.kind === "ok") {
          videoConnect(res.response.token);
        }
      })
      .catch(reason => console.log(reason))
      .finally(() => setIsFetching(false))
  }

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{height: '100%'}}>
        <div>
          <CircularProgress variant="indeterminate"/>
        </div>
        <div>
          <Typography variant="body2" style={{fontWeight: 'bold', fontSize: '16px'}}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  const handleLogout = () => {
    // @ts-ignore
    navigate(`${AuthRoutes.logout}`)
  }

  return (
    <Stack flexDirection={"column"} alignItems={"center"} justifyContent={"center"} gap={pxToRem(14)}>
      <Box width={'80%'}>
        <LocalVideoPreview user={loginStore}/>
      </Box>
      <SettingsMenu mobileButtonClass={classes.mobileButton}/>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
        <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons}/>
        <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons}/>
      </Stack>
      <Stack width={'60%'} flexDirection={"column"} justifyContent={"space-between"} gap={pxToRem(14)}>
        <PrimaryButton text={"Join"} onClick={getMeeting}/>
        <SecondaryButton text={"Logout"} onClick={handleLogout}/>
      </Stack>
    </Stack>
  );
}
