import { Box, FormHelperText, Input, Typography } from "@mui/material";
import CustomSelect from "components/CustomSelect";
import MDTypography from "components/MDTypography";
import React, { useState, useEffect } from "react";
import colors from "../../../../assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {AutocompleteFormik} from "../../../../components/AutocompleteFormik";
import MDBox from "../../../../components/MDBox";
import { useStores } from "../../../../models";

const CustomTextFieldMobile = ({
    type, sx, name, value, onChange, placeholder, error, helperText
  }) => {
    return <Box>
     <Input
          type={type}
          sx={sx}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
       {error && (
        <FormHelperText>
          <Typography
            sx={[{fontSize: pxToRem(12), color: colors.error.main}]}
            variant='span'>{helperText}</Typography>
        </FormHelperText>
      )}
    </Box>
}

function BillingDetailsMobile({ formik }) {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const api = loginStore.environment.api;
  const [Countries, setCountries] = useState([]);
  
  const parseCountries = (countries) => {
  const parsedCountries = countries.map((country , idx) => ({value: country.id, label: country.name}));
  return parsedCountries;
  }

  const getCountries = (event, search = "") => {
    let countries = [];
    api
      .getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {          
          countries = res.data.results;
          countries = parseCountries(countries);
          setCountries(countries);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Box sx={styles.billingDetails}>

      <Typography sx={styles.titleDetails}>Billing Details</Typography>
     
      <CustomTextFieldMobile 
        type="text"
        sx={styles.input}
        name="address1"
        value={formik.values.Address1}
        onChange={formik.handleChange}
        placeholder="Street 1"
        error={formik.touched.Address1 && Boolean(formik.errors.Address1)}
        helperText={formik.touched.Address1 && formik.errors.Address1 }
      />    
       <CustomTextFieldMobile 
        type="text"
        sx={styles.input}
        name="address2"
        value={formik.values.Address2}
        onChange={formik.handleChange}
        placeholder="Street 2"
        error={formik.touched.Address2 && Boolean(formik.errors.Address2)}
        helperText={formik.touched.Address2 && formik.errors.Address2 }
      />
      <Box sx={styles.doubleInput}>       
        <CustomTextFieldMobile 
          type="text"
          sx={styles.input}
          name="stateProvice"
          value={formik.values.stateProvice}
          onChange={formik.handleChange}
          placeholder="State/Province"
          error={formik.touched.stateProvice && Boolean(formik.errors.stateProvice)}
          helperText={formik.touched.stateProvice && formik.errors.stateProvice }
        />
        <CustomTextFieldMobile 
          type="text"
          sx={styles.input}
          name="ZIP"
          value={formik.values.ZIP}
          onChange={formik.handleChange}
          placeholder="ZIP"
          error={formik.touched.ZIP && Boolean(formik.errors.ZIP)}
          helperText={formik.touched.ZIP && formik.errors.ZIP }
        />        
      </Box>
      <Box>
          <CustomSelect
            onChange={(e) => {
              formik.setFieldValue('country', e.target.value ? parseInt(e.target.value) : null)
            }}
            options={[
              {value: 0, label: "Country"},
              ...Countries
            ]}
          />
            {formik.touched.country && Boolean(formik.errors.country) && (
              <FormHelperText>
                <MDTypography sx={{fontSize: 14}}
                              color={"error"}>{formik.touched.country && formik.errors.country}</MDTypography>
              </FormHelperText>
            )}
        </Box>
    </Box>
  );
}

export default BillingDetailsMobile;

const styles = {
  title: {
    alignSelf: "flex-start",
    color: "#fff",
    fontWeight: "400",
    fontSize: "25px",
  },
  accordion: {
    backgroundColor: "#182536",
    boxShadow: "0px 0px 0px 0px",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
  },
  expandIcon: {
    color: "#fff",
    width: "30px",
    height: "30px",
  },
  inputsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "30px",
  },
  titleDetails: {
    color: "#fff",
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
  },
  cardDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  doubleInput: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
  },
  billingDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
    marginTop: "20px"
  },
  input: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    padding: "15px",
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    width: "100%",
    color: "#fff",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  button: {
    backgroundColor: colors.primary.main,
    height: "42px",
    width: "193px",
    color: "#fff",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: colors.withdrawlcard.greybutton,
      color: "#fff",
    },
    "&:focus": {
      color: "#fff!important",
    },
  },
};


