import React, {useEffect, useReducer, useRef, useState} from "react";
import {Box, Modal, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import idolRed from "../../../assets/icons/idol_red.svg";
import kike from "../../../assets/images/kike.png";
import xIcon from "../../../assets/icons/X.svg";
import arrowLeft from "../../../assets/icons/chevron_back.svg";
import isVerified from "../../../assets/icons/checked.svg";
import {AuthenticatedPaths, UnauthenticatedPaths} from "../../../paths/paths";
import {IdolProfileSummary} from "../desktop/IdolProfileSummary";
import {notifyError, notifySuccess} from "../../../services/notification";
import { useApi, useInfiniteScroll, useIsMobile, useUserStore } from "../../../utils/hooks";
import NavFilterRow from "../desktop/navFilter";
import {filterField, filtersReducer, initialFilters} from "../reducers";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/Loader";
import PostContent from "../../../components/PostContent/PostContent";
import {PostCategoryEnum} from "../../../constants";
import {SpecificPostModal} from "../../../components/PostsGrill/SpecificPostModal";
import { NeedLoginModal } from "../../../components/NeedLoginModal";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import {AvatarOverImage} from "../../../components/AvatarOverImage";
import {BlockedUserModal} from "components/BlockedUserModal"


export const IdolProfileMobile = props => {
  const api = useApi()
  const user = useUserStore()
  const scrollEl = useRef()
  const isMobile = useIsMobile()
  const location = useLocation();
  const navigate = useNavigate();
  const activeRoute = user.getActiveRoute
  const atProfile = (activeRoute === "profile")
  const {id, idol_id_name} = useParams();
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  const [openPost, setOpenPost] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [openConfirmPaymentModal, setOpenConfirmPaymentModal] = useState(false);
  const [openConfirmUnlockModal, setOpenConfirmUnlockModal] = useState(false);
  const [openConfirmDeletePost, setOpenConfirmDeletePost] = useState(false);
  const [blockedUserModalOpen, setBlockedUserModalOpen] = useState(false);
  const [blockedUserModalDescription, setBlockedUserModalDescription] = useState('');
  const myProfileId = user.idol_id_name
  const isMyProfile = idol_id_name === myProfileId
  const [filters, dispatch] = useReducer(
    filtersReducer,
    initialFilters
  );
  const [Counters, setCounters] = useState({all: 0, videos: 0, photos: 0})

  console.log("profile ====>", profile)

  const onApiCall = ({page}) => {
    let data = {user_id: profile.id, page, idol_id_name}

    if (filters.typeName !== filterField.all) {
      data["category_codename"] = filters.typeName
    }
    if (filters.contentName !== filterField.all) {
      data["type_codename"] = filters.contentName
    }
    return api.getIdolPosts(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall: onApiCall, queryKey: "posts", filters})

  useEffect(() => {
    if (extraData.images) {
      setCounters({
        photos: extraData.images,
        videos: extraData.videos,
        all: extraData.images + extraData.videos
      })
    }

  }, [extraData])

  useEffect(() => {
    setOpenPost(true)
  }, [selectedPost]);

  const handleOpen = (post) => {
    const isVideo = post.post_category.codename === PostCategoryEnum.video
    setSelectedPost({
      ...post,
      tipPrice: post.tip_price,
      isVideo: isVideo,
      isLocked: !post.is_visible,
      idolPost: isVideo ? post.file : post.image
    });
  };
  const confirmTipPayment = () => {
    api.sendTip(selectedPost.id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Tip sent successfully")
        getProfile()
        setOpenConfirmPaymentModal(false)

      } else {
        notifyError("Error sending Tip")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error sending Tip")
    })
  }

  const deletePost = () => {
    api.deletePost(selectedPost.id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Post deleted")
        getProfile()
        setSelectedPost(null)
        setTimeout(() => {
          refetch({refetchPage: (page, index) => index === 0})
        }, 200);
      } else {
        notifyError("Error deleting post")
      }
    }).catch(reason => {
      notifyError(`${reason}`)
    })
  }

  const goToMeetMe = () => {
    if(user?.id){
      navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", selectedPost.user.idol_id_name))
    }else{
      setShowLoginModal(true)
    }
  }

  const unlockPost = ()=>{
    api.additionalFeatureAccessPay(selectedPost.id).then((res) => {
      if (res.kind === "ok"){
        notifySuccess("Photo unlocked successfully")
        const image = res.response.post.image
        window.location.reload()
      }else{
        console.log(res)
        let msg = ""
        Object.keys(res.errors).forEach(function(key,index) {
          msg += res.errors[key]
        });
        if(!msg){
          msg = "Error unlocking this post"
        }
        notifyError(msg)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Can't unlock this post")
    })
  }

  const goToEditPost = () => {
    setOpenPost(false)
    setOpenConfirmPaymentModal(false)
    setEditing(true)
  }

  const handleClose = () => {
    setOpenPost(false);
    setOpenConfirmPaymentModal(false);
    setSelectedPost(null)
  };

  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = (event) => {
    if (event.target.id !== "avatar") {
      setOpenProfile(false)
    }
  };
  const goBack = () => {
    if (location.state?.fromURL) {
      navigate(location.state.fromURL)
    } else {
      navigate(UnauthenticatedPaths.explore)
    }
  }

  const getProfile = () => {

    api.getIdolProfile(idol_id_name).then(response => {
      console.log(' response ====> ', response)
      if (response.kind === "ok") {
        if (response?.data?.blocked) {
          setBlockedUserModalOpen(true)
          setBlockedUserModalDescription(response?.data?.message)
          setProfile({username: response?.data?.username})
        } else {
          const data = response.data
          const tags = []
          for (const obj of [data.tag_1, data.tag_2, data.tag_3]) {
            if (obj) {
              tags.push(obj.toUpperCase())
            }
          }
          setProfile({
            ...data,
            blocked: data.blocked,
            followers: data.followers,
            idolizers: data.subscribers,
            idolPoints: data.idol_points,
            isVerified: data.is_validated,
            tags: tags,
            bio: data.biography,
            image: data.image
          })
        }

      } else {
        notifyError("Error getting profile")
      }

    }).catch(reason => {
      notifyError("Error getting profile")
      console.log(reason)
    })
  }


  useEffect(() => {
    getProfile()
  }, [])

  return (
    <Box>
      {showLoginModal && (<NeedLoginModal open={showLoginModal} onClose={()=>setShowLoginModal(false)} />)}
      <Modal open={openProfile} onClose={(e) => handleCloseProfile(e)} onClick={(e) => handleCloseProfile(e)}>
        <Box sx={styles.modal}>
          <Box component={"img"} src={xIcon} alt={"close-icon"}
               sx={{width: "25px", height: "25px", position: "absolute", top: 60, right: 30}}
               onClick={(e) => handleCloseProfile(e)}/>
          <Box id={'avatar'} component={"img"} src={profile.image} alt={"avatar"} sx={styles.avatarModal}/>
        </Box>
      </Modal>
      <Box component={"img"} src={arrowLeft} alt={"avatar"} sx={styles.arrowLeft} onClick={goBack}/>
      <Box sx={styles.imgContainer}>
        {/*<Box component={"img"} src={idolRed} alt={"avatar"} sx={styles.idolRed}/>*/}
        {/*<Box component={"img"} src={profile.image} alt={"avatar"} sx={styles.avatar} onClick={handleOpenProfile}/>*/}
        <AvatarOverImage avatarImage={profile.image}
                         myProfileId={myProfileId}
                         idol_id_name={idol_id_name}
                         isMyProfile={isMyProfile}
                         atProfile={atProfile}
                         isMobile={isMobile}
        />
      </Box>

      <ConfirmPaymentModal
        open={openConfirmUnlockModal}
        message={`Are you sure you want to unlock this post?`}
        confirmAction={() => {
          unlockPost()
          setOpenConfirmUnlockModal(false)
        }}
        confirmLabel={"CONFIRM"}
        cancelAction={() => setOpenConfirmUnlockModal(false)}
        onClose={() => setOpenConfirmUnlockModal(false)}
      />

      <ConfirmPaymentModal
        open={openConfirmPaymentModal}
        message={`Are you sure you want to tip this post?`}
        confirmAction={() => {
          confirmTipPayment()
          setOpenConfirmPaymentModal(false)
        }}
        confirmLabel={"CONFIRM"}
        cancelAction={() => setOpenConfirmPaymentModal(false)}
        onClose={() => setOpenConfirmPaymentModal(false)}
      />

      <ConfirmPaymentModal
        open={openConfirmDeletePost}
        message={`Are you sure you want to delete this post?`}
        confirmAction={() => {
          deletePost()
          setOpenConfirmDeletePost(false)
        }}
        confirmLabel={"CONFIRM"}
        cancelAction={() => setOpenConfirmDeletePost(false)}
        onClose={() => setOpenConfirmDeletePost(false)}
      />

      <BlockedUserModal
        open={blockedUserModalOpen}
        title={profile?.username}
        description={blockedUserModalDescription}
        onClose={() => setBlockedUserModalOpen(false)}
      />

      {selectedPost && (
        <SpecificPostModal
          isExplorePage={false}
          profile={profile}
          content={selectedPost}
          openPost={openPost}
          unlockPost={()=>{
            if(user?.id){
              setOpenConfirmUnlockModal(true)
            }else{
              setShowLoginModal(true)
            }
          }}
          goToMeetMe={goToMeetMe}
          goToEditPost={goToEditPost}
          deletePost={()=>{
            setOpenConfirmDeletePost(true)
          }}
          handleClose={handleClose}
          handleConfirmPaymentModal={() => {
            if(user?.id){
              setOpenConfirmPaymentModal(true)
            }else{
              setShowLoginModal(true)
            }
          }}
          isMobile={isMobile}
        />
      )}
      <Box sx={styles.idolDetailsContainer}>
        <IdolProfileSummary profile={profile} getProfile={getProfile}/>
      </Box>
      <Box>
        <NavFilterRow filters={filters}
                      counters={Counters}
                      loading={loading}
                      dispatch={dispatch}/>
      </Box>
      <Box>
        {filters && profile && (
          <Box className={"noScrollbarDisplay"} style={styles.postsContainer} id={"scroll-content"}>
            <InfiniteScroll
              ref={scrollEl}
              dataLength={items.length}
              next={() => {
                if (!isFetchingNextPage) {
                  return fetchNextPage()
                }
              }}
              hasMore={!!hasNextPage}
              loader={<Loader show={isLoading}/>}
              scrollableTarget={"scroll-content"}
            >
              <Box sx={styles.container}>
                {items.map(post => <PostContent handleOpen={handleOpen} key={"post-" + post.id} post={post}/>)}
              </Box>
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    // position: "relative",
    flexDirection:"row",
    justifyContent:"center",
    gap:"2px"
  },
  postsContainer:{
    height:"calc(100vh - 70px)",
    boxSizing:"content-box",
    overflowY:"scroll",
    "noScrollbar::-webkit-scrollbar": {
      display: "none"
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: "auto 0",
    transform: 'translate(-50%, -50%)',
    width: "100%",
    height: "100vh",
    border: "none",
    boxShadow: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "44px 0px 20px 0px",
    position: "relative"
  },
  arrowLeft: {
    width: "10px",
    height: "20px",
    position: "absolute",
    top: 60,
    left: 30,
    zIndex: 99
  },
  idolRed: {
    width: "191px", height: "183px"
  },
  avatarModal: {
    width: "277px",
    height: "277px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
  },
  avatar: {
    width: "108px",
    height: "108px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
    position: "absolute",
    top: 15,
    bottom: 0,
    margin: "auto 0",
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  idolDetailsContainer: {
    margin: "16px",
  },
  idolName: {
    color: "#fff",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "33px",
    paddingRight: "6px"
  },
  checked: {
    width: "23px",
    height: "23px"
  },
}
