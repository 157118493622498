import React from 'react';

/* MUI components */
import {useMediaQuery, useTheme} from '@mui/material';

/* components */
import {BaseModal} from "base/BaseModal";

/* assets */
import {desktopStyles, mobileStyles} from './styles';

export const GenericModal = ({
                               open,
                               title,
                               children = null,
                               ...rest
                             }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = isMobile ? mobileStyles : desktopStyles

  const baseProps = {
    open,
    title,
    containerStyles: styles.container,
    modalWidth: styles.container.width,
    headerStyles: styles.header,
    ...styles.content_container,
  };

  return (
    <BaseModal {...baseProps} {...rest} >
      {children}
    </BaseModal>
  );
}
