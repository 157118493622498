import { useIsMobile } from "utils/hooks";

export const ButtonStyles = (theme, currentFilterSelected) => {
  const isMobile = useIsMobile();
  return {
    width: isMobile ? '104px' : "100%",
    letterSpacing: '0.1rem',
    fontWeight: '400',
    padding: isMobile && '0',
    backgroundColor: currentFilterSelected
      ? theme.palette.primary.main
      : theme.palette.dark.secondary,
    borderRadius: 1,
    color: theme.palette.grey['100'],
    "&:hover": {
      backgroundColor: currentFilterSelected
        ? theme.palette.primary.hover
        : theme.palette.withdrawlcard.greybutton,
      color: '#fff'
    },
    '&:focus': {
      color: '#fff!important',
    },

  }
}
