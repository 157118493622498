import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white} = colors;

export const styles = {
    container: {
        display: 'grid',
        gridTemplateColumns: '4fr 4fr 0.5fr',
        marginBlock: 1,
    },
    saved_card_container: {
        alignItems: 'center',
        display: 'flex',
        gap: 1,
    },
    form_control: {
        display: 'flex',
        padding: '10px 0 10px 25px',
        ".MuiFormControlLabel-root": {marginRight: 0},
    },
    img: {
        width: pxToRem(20),
        height: pxToRem(20),
    },
    color_white: {
        color: white.main,
        fontSize: pxToRem(14),
        lineHeight: pxToRem(18),
    },
    expiration: {
        display: 'flex',
        alignItems: 'center',
    },
    delete: {
        alignItems: 'flex-end',
        cursor: 'pointer',
        display: 'flex',
    },
};
