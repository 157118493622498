import { useApi, useInfiniteScroll } from "../../../../utils/hooks";
import { useExploreApp } from "../utils/hooks";
import React, { memo, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader";
import PostContent from "../../../../components/PostContent/PostContent";
import { SearchMediaContainer } from "./SearchMediaContainer";
import { ConfirmPaymentModal } from "../../../../components/ConfirmPaymentModal";
import { moneyFormat } from "../../../../services/helpers";
import { SpecificPostModal } from "../../../../components/PostsGrill/SpecificPostModal";
import { useNavigate } from "react-router-dom";
import { AuthenticatedPaths } from "../../../../paths/paths";
import { notifyError, notifySuccess } from "../../../../services/notification";
import { PostCategoryEnum } from "../../../../constants";


export const SearchPosts = memo(()=>{
  const {searchValue, setExploreFilter, setExploreLayout} = useExploreApp()
  const [selectedPost, setSelectedPost] = useState()
  const [showPayConfirmModal, setShowPayConfirmModal] = useState(false)
  const navigate = useNavigate()

  const api = useApi()

  const onApiCall = ({page})=>{

    let data = {page}
    data["search_description"] = searchValue
    return api.getIdolPosts(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "posts"})


  useEffect(() => {
    refetch({ refetchPage: (page, index) => index === 0 })
  }, [searchValue])


  const goToMeetMe = () => {
    navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", selectedPost.user.idol_id_name))
  }


  const onClickPost = (post)=>{
    const isVideo = post.post_category.codename === PostCategoryEnum.video
    setSelectedPost({
      ...post,
      tipPrice: post.tip_price,
      isVideo: isVideo,
      isLocked: !post.is_visible,
      idolPost: isVideo? post.file : post.image
    })
  }

  const confirmTipPayment = ()=>{
    api.sendTip(selectedPost.id).then(response => {
      if(response.kind === "ok"){
        notifySuccess("Tip sent successfully")
        setShowPayConfirmModal(false)
      }else{
        notifyError("Error sending Tip")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error sending Tip")
    })
  }

  return (
    <React.Fragment>
      {selectedPost && (
        <>
          <ConfirmPaymentModal
            open={showPayConfirmModal}
            confirmAction={confirmTipPayment} //Mientras solo cierra el modal
            onClose={()=> {
              setShowPayConfirmModal(false)
            }} //Mientras solo cierra el modal
            message={`Are you sure you want to tip this user for ${moneyFormat(selectedPost?.tipPrice??0)}?`}
          />
          <SpecificPostModal
            isMobile
            isExplorePage={true}
            content={selectedPost}
            openPost={!!selectedPost}
            goToMeetMe={()=>goToMeetMe()}
            handleClose={()=>setSelectedPost(null)}
            handleConfirmPaymentModal={()=> {
              setShowPayConfirmModal(true)
            }}
          />

        </>
      )}


      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          if(!isFetchingNextPage){
            return fetchNextPage()
          }
        }}
        hasMore={!!hasNextPage}
        loader={<Loader show={isLoading}/> }
      >
        <Grid container xs={12} spacing={1} md={12}  sx={styles.scroll}>
          {items.map(post => (
            <Grid item xs={6} md={6} sx={{p:0, m:0}}>
              <SearchMediaContainer key={"post-"+post.id} post={post} onClick={()=>onClickPost(post)} />
            </Grid>
          ))}
        </Grid>

      </InfiniteScroll>
    </React.Fragment>

  )

})

const styles = {
  scroll:{
    padding:"15px 5px 15px 15px",
    // marginTop:"10px",
    height:"100vh",
    "&::-webkit-scrollbar": {display: "none"},
  },
}
