import { OnboardingLayout } from "components/OnboardingLayout";
import MDBox from "components/MDBox";
import { PrimaryButton } from "components/PrimaryButton";
import MDTypography from "components/MDTypography";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { getIdolOnboardingNavigationPages } from "../../../models";
import { useEffect } from "react";
import { useOnboardingStore } from "../../../utils/hooks";
import { GuestPaths } from "../../../paths/guestPaths";

export const OnboardingReady = () => {
  const onboardingStore = useOnboardingStore()
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolReady)

  const Launch = () => {
    navigate(NextPage)
  }

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage);
  }, [])

  return (
    <OnboardingLayout
      titleBold='IDOL'
      titleNormal='READY'
      backTo={PreviousPage}
    >
      <MDBox>
        <MDTypography sx={isMobile ? styles.textMobile : styles.text}>
          You're almost done with the signup process!
        </MDTypography>

        <MDTypography sx={isMobile ? styles.textMobile : styles.text}>
          Once we've verified your identity you'll be able to start publishing content and making money on idolidol.
        </MDTypography>

        <MDTypography sx={isMobile ? styles.subtitleMobile : styles.subtitle}>
          We can't wait to see what you create!
        </MDTypography>
      </MDBox>

      {isMobile ?
        isMobileH ?
          <MDBox sx={{position: "absolute", right: 0, left: 0, bottom: 20, paddingX: "30px"}}>
            <PrimaryButton
              text={"Launch"}
              onClick={Launch}
            />
          </MDBox>
          :
          <MDBox sx={styles.launchMobile}>
            <PrimaryButton
              text={"Launch"}
              onClick={Launch}
            />
          </MDBox>
        :
        <MDBox sx={styles.launch}>
          <PrimaryButton
            text={"Launch"}
            onClick={Launch}
          />
        </MDBox>
      }

    </OnboardingLayout>
  )
}


