/* Dependencies */
import React, { useState, memo, useEffect } from "react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

/* Layouts */
import {BaseModal} from 'base/BaseModal';

/* Components */
import {CustomTextField} from 'components/TextField';
import {CustomAutocompleteFormik} from "components/AutocompleteFormik";
import {SecondaryButton} from "components/SecondaryButton";
import MDBox from 'components/MDBox';
import Select from 'components/CustomSelect';
import RoundCheck from 'components/RoundCheck';
import MDTypography from 'components/MDTypography';

/* Assets */
import brandWhite from "assets/images/dashboard_logo.png";
import {styles} from './styles';
import colors from "assets/theme/base/colors";
import { notifyError, notifySuccess } from "../../services/notification";
import AvailabilityModal from "../AvailabilityModal";
// import { initialValues } from "../AddCreditCard/initialValues";
import { IdolTypeEnum, MeetingTypeEnum } from "../../utils/constants";
import { FormHelperText } from "@mui/material";
import pxToRem from "../../assets/theme/functions/pxToRem";
import DateRangeModalPicker from "../DateRangeModal";
import { DateRangeOption, LiveCalOptions, MeetAndGreetOptions } from "./constants";
import { rangesSchema } from "../AvailabilityModal/schemas";
import { useApi } from "../../utils/hooks";
import moment from "moment";
import availability from "../../pages/portal/calendar/availability";
import { formatDurationFromMinutes } from "../../utils/dateUtils";
const {modal, white} = colors;


const DateRangeSchema = Yup.object({
  from: Yup.date(),
  to: Yup.date(),
}).nullable()

export const AvailabilitySchema = Yup.object().shape({
  custom:Yup.array()
    .of(Yup.object().shape({
      date: Yup.string().required('The event name is required'),
      ranges: Yup.array().of(Yup.object().shape({
        from_time: Yup.string(),
        to_time: Yup.string()
      }))
    })),
  timezone: Yup.string()
}).nullable()

const ValidationSchema = Yup.object().shape({
  type: Yup.string().required('The event type is required'),
  name: Yup.string().required('The event name is required'),
  description: Yup.string().required('The event description is required'),
  duration: Yup.number().required('Event duration is required'),
  price: Yup.number().required('Price of the event is required'),
  city: Yup.object().nullable()
    .when("type", {
      is: MeetingTypeEnum.meetAndGreet,
      then: Yup.object().nullable()
        .required("This field is required"),
      otherwise: Yup.object().nullable()
    }),
  dateRange: Yup.string().required('Please choose one option')
    .required("This field is required"),
  dateRangeValue: DateRangeSchema.notRequired().when("dateRange", {
    is: DateRangeOption.date_range,
    then: DateRangeSchema
      .required("This field is required"),
    otherwise:DateRangeSchema.notRequired()
  }),
  availability:AvailabilitySchema.notRequired().when("dateRange", {
    is: DateRangeOption.calendar_days,
    then: AvailabilitySchema
      .required("This field is required"),
    otherwise:AvailabilitySchema.notRequired()
  }),


});


const CreateEvent = ({
                       open,
                       onClose,
                       onSave,
                       idolType,
                     }) => {

  const [durationOptions, setDurationOptions] = useState(LiveCalOptions)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowDateRangeModal, setShowDateRangeModal] = useState(false)
  const [Cities, setCities] = useState([]);
  const api = useApi()


  const initialValues = {
    name: "",
    type: MeetingTypeEnum.liveCall,
    description: "",
    duration: durationOptions[0].value,
    price: "",
    city: null,
    dateRange: "",
    dateRangeValue:"",
    availability:null,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:ValidationSchema,
    onSubmit: values => {
      saveEvent(values)
    }
  })

  const saveAvailability = (data) => {
    api.saveUserAvailability(data).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Saved Successfully")
      } else {
        notifyError("There is something wrong")
      }
    }).catch(reason => console.log(reason))
  }

  const getCities = (region, search = "") => {
    const countryName = "United States";
    api
      .getCities(search, region?.id, countryName)
      .then((res) => {
        if (res.kind === "ok") {
          setCities(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };
  /*

   fields = ["id", "user", "name", "description", "duration", "fee", "date_range_type", "meeting_type",
                  "start_date", "calendar_days", "from_date", "to_date", "indefinitely", "created_at"]

   */
  const saveEvent = (values)=>{
    let data = {
      name: values.name,
      description: values.description,
      fee: values.price,
      date_range_type_code: values.dateRange,
      meeting_type: values.type,
      city: !!values.city ? values.city.id : null
    }
    console.log("values", values)
    console.log("data", data)
    //
    let duration = moment().startOf("day")
    if(values.type === MeetingTypeEnum.liveCall){
      duration.add("minutes", parseInt(values.duration))
    }else{
      duration.add("minutes", parseInt(values.duration) * 60)
    }
    data["duration"] = formatDurationFromMinutes(+values.duration)
    if(values.dateRange === DateRangeOption.date_range){
      data["from_date"] = moment(values.dateRangeValue.from).format('yyyy-MM-DD')
      data["to_date"] = moment(values.dateRangeValue.to).format('yyyy-MM-DD')
    }else if(values.dateRange === DateRangeOption.indefinitely){
      data["indefinitely"] = true
    }

    api.createEventType(data).then(response => {
      if(response.kind === "ok"){
        if(values.dateRange === DateRangeOption.calendar_days){
          saveAvailability({...values.availability, event_type_id : response.response.id})
          onSave()
        }else{
          notifySuccess("Saved Successfully")
          onSave()
        }

      }else{
        notifyError("Error saving the Event")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error saving the Event")
    })
  }

  useEffect(()=> {
    getCities();
  }, [])


  return (
    <BaseModal open={open} onClose={onClose} title="CREATE EVENT" centerContent={false}>
      <form onSubmit={formik.handleSubmit} style={{padding:"0 50px 0 50px"}}>
        <MDBox style={{display:"flex", flexDirection:"row", justifyContent:"center", gap:"24px"}}>
          <RoundCheck
            radioButtonSize="18px"
            label="Live Call"
            labelFontSize="1.2rem"
            onClick={() => {
              formik.setFieldValue('type', MeetingTypeEnum.liveCall)
              formik.setFieldValue('duration', LiveCalOptions[0].value)
              setDurationOptions(LiveCalOptions)

            }}
            checked={formik.values.type === MeetingTypeEnum.liveCall}
          />
          {(idolType === IdolTypeEnum.entrepreneur || idolType ===IdolTypeEnum.professional) && (
            <RoundCheck
              radioButtonSize="18px"
              label="Meet & Greet"
              labelFontSize="1.2rem"
              onClick={() => {
                setDurationOptions(MeetAndGreetOptions)
                formik.setFieldValue('type', MeetingTypeEnum.meetAndGreet)
                formik.setFieldValue('duration', MeetAndGreetOptions[0].value)
              }}
              checked={formik.values.type === MeetingTypeEnum.meetAndGreet}
            />
          )}

        </MDBox>

        <MDBox mt={2}>
          <CustomTextField
            inputLabel="Event Name"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </MDBox>

        <MDBox mt={2}>
          <CustomTextField
            inputLabel="Description"
            id="description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </MDBox>

        <MDBox mt={2}>

          <Select
            label="Duration"
            defaultValue=''
            name={"duration"}
            id={"duration"}
            onChange={formik.handleChange}
            options={[...durationOptions, ]}
            error={formik.touched.duration && Boolean(formik.errors.duration)}
            helperText={formik.touched.duration && formik.errors.duration}
          />
        </MDBox>

        <MDBox sx={styles.flex_section} mt={2}>
          <MDBox sx={{width: '50%'}}>
            <CustomTextField
              inputLabel="Price"
              id="price"
              name="price"
              placeholder="$"
              onChange={formik.handleChange}
              value={formik.values.price}
              type='number'
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />


          </MDBox>

          <MDBox sx={{width: '50%'}}>
            {formik.values.type === MeetingTypeEnum.meetAndGreet && (
              <CustomAutocompleteFormik
                  name={'city'}
                  formik={formik}
                  options={Cities}
                  value={formik.values.city}
                  onClear={() => {
                    getCities(formik.values.city)
                    formik.setFieldValue("city", null)
                  }}
                />
            )}

          </MDBox>
        </MDBox>

        <MDBox sx={[styles.date_range_section ]} mt={4}>
          <MDTypography sx={styles.dateRangeTitle}>Date Range</MDTypography>

          {formik.touched.dateRange && Boolean(formik.errors.dateRange) && (
            <FormHelperText>
              <MDTypography
                sx={[{fontSize: 12, color: colors.error.main, marginTop:"16px"}]}
                variant='span'>{formik.errors.dateRange}</MDTypography>
            </FormHelperText>
          )}


          <MDBox style={{display:"flex", flexDirection:"column", justifyContent:"center", gap:"8px", marginTop:"24px"}}>
            <RoundCheck
              radioButtonSize="18px"
              label='Within a date range'
              labelFontSize="1.2rem"
              onClick={() => {
                formik.setFieldValue('dateRange', DateRangeOption.date_range)
                setShowDateRangeModal(true)
              }}
              checked={formik.values.dateRange === DateRangeOption.date_range}
            />
            {formik.touched.dateRange && Boolean(formik.errors.dateRangeValue?.from || formik.errors.dateRangeValue?.to) && (
              <FormHelperText>
                <MDTypography
                  sx={[{fontSize: 12, color: colors.error.main}]}
                  variant='span'>{"You must specify a date range"}</MDTypography>
              </FormHelperText>
            )}

            <RoundCheck
              radioButtonSize="18px"
              label={'Custom Availability'}
              labelFontSize="1.2rem"
              onClick={() => {
                formik.setFieldValue('dateRange', DateRangeOption.calendar_days)
                formik.setFieldValue('dateRangeValue',null)

                setShowModal(true)
              }}
              checked={formik.values.dateRange ===  DateRangeOption.calendar_days}
            />
            {formik.touched.dateRange && Boolean(formik.errors.availability) && (
              <FormHelperText>
                <MDTypography
                  sx={[{fontSize: 12, color: colors.error.main}]}
                  variant='span'>{"You must specify the availability"}</MDTypography>
              </FormHelperText>
            )}



            <RoundCheck
              radioButtonSize="18px"
              label="Indefinitely into the future"
              labelFontSize="1.2rem"
              onClick={() => {
                formik.setFieldValue('dateRange', DateRangeOption.indefinitely)
                formik.setFieldValue('dateRangeValue',"")
                formik.setFieldValue('availability',"")
              }}
              checked={formik.values.dateRange ===  DateRangeOption.indefinitely}
            />

          </MDBox>


        </MDBox>



        <MDBox sx={styles.actions_section} mt={4}>
          <SecondaryButton
            text="Cancel"
            onClick={() => [onClose(), formik.resetForm()]}
            padding={0.5}
            backgroundColor={colors.transparent.main}
            borderColor={modal.buttons.cancel}
            textStyles={{ color: modal.buttons.cancel }}
          />
          <SecondaryButton type="submit" text="Apply" padding={0.5}  />
        </MDBox>


      </form>

      <DateRangeModalPicker open={ShowDateRangeModal}
                            onClose={()=>{
                              setShowDateRangeModal(false)
                              formik.setFieldValue('dateRangeValue', null)
                            }}
                            onApply={(range)=>{

                              formik.setFieldValue('availability',null)
                              formik.setFieldValue('dateRangeValue.from', range.from)
                              formik.setFieldValue('dateRangeValue.to', range.to)
                              setShowDateRangeModal(false)
                            }}
      />


      <AvailabilityModal open={ShowModal}
                         isSpecific
                         onApply={(data)=>{
                           formik.setFieldValue('availability', data)
                           setShowModal(false)
                         }}


                         onClose={()=>setShowModal(false)}
      />
    </BaseModal>
  );
};

export const CreateEventModal = memo(CreateEvent);

