import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import colors from "../../../assets/theme/base/colors";
import rgba from "../../../assets/theme/functions/rgba";


export const BodyShapeCard = ({value, icon})=>{
  return (
    <Card sx={styles.shapeCard}>
      <CardContent sx={styles.shapeCardContent}>
       <Box display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            gap={"16px"}
            alignItems={"center"}>
         <Box src={icon} component={"img"} />
         <Typography style={styles.shapeValue}>
           {value} in
         </Typography>
       </Box>
      </CardContent>
    </Card>
  )
}

export const SizeBox = ({value, label})=>{
  return (
    <Box style={styles.sizeMain}>
      <Typography style={styles.sizeValue}> {value}</Typography>
      <Box style={styles.divider} />
      <Typography style={styles.sizeLabel}> {label}</Typography>
    </Box>

  )
}

export const DetailCard = ({label, content})=>{
  return (
    <Card sx={styles.detailCard}>
        <Box display={"flex"} flexDirection={"row"}
             alignItems={"center"} height={"inherit"} >
          <Box style={{width:"30%"}}>
            <Typography style={styles.detailText}>
              {label}
            </Typography>
          </Box>

          <Box style={styles.verticalDivider} />

          <Typography style={styles.detailValue}>
            {content}
          </Typography>
        </Box>


    </Card>
  )
}

const styles = {
  verticalDivider: {
    height:"46px",
    width:"5px",
    borderRadius:"8px",
    margin:"5%",
    background:rgba(colors.background.default, 0.2)
  },
  detailText:{
    paddingLeft:"16px",
    color:rgba(colors.white.main, 0.6),
    fontWeight:600,
    fontSize:16
  },
  detailValue:{
    paddingLeft:"16px",
    color:"white",
    fontWeight:400,
    fontSize:16,
    overflow:"wrap"
  },
  divider:{
    height: "2px",
    marginTop: "5px",
    background:colors.primary.main,
  },
  sizeMain: {
    width:"90px",
    height:"50px",
    textAlign:"center"
  },
  sizeValue:{
    fontSize:24,
    fontWeight:300,
    color: "white",
    padding:0,
    margin:0,
    height: 39,
  },
  sizeLabel:{
    marginTop:"7px",
    fontSize:12,
    fontWeight:500,
    letterSpacing:"0.1em",
    color: colors.primary.main
  },
  shapeValue:{
    fontSize:16,
    fontWeight:400,
    color: "white",
    padding:0,
    margin:0
  },
  shapeCard: {
    width: "127px",
    height:"70px",
    borderRadius:3,
    padding:0,
    backgroundColor:rgba(colors.darkBlue[200], 0.25)
  },
  shapeCardContent:{
    width: "127px",
    height:"70px",
    paddingLeft:1,
    paddingRight:1.5,
    paddingTop:2
  },
  detailCard: {
    width: "100%",
    height:"70px",
    borderRadius:3,
    padding:0,
    backgroundColor:rgba(colors.darkBlue[200], 0.25)
  },
  detailCardContent:{
    width: "100%",
    height:"70px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    padding:0,
    paddingBottom:0
  }

}
