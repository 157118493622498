import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {red, white, transparent, background, buttons: {mobile}} = colors;

export const styles = {
    modal_content_container: {
        background: background.default,
        height: pxToRem(598),
        marginBlockStart: 0,
        marginBlockEnd: 0,
        overflow: 'hidden',
        width: pxToRem(330),
    },
    content_container: {
        marginInline: pxToRem(24),
        marginBlockStart: pxToRem(86),
    },
    idol_info_container: {
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
        gap: pxToRem(49),
    },
    name: {
        fontSize: pxToRem(18),
        lineHeight: pxToRem(21),
    },
    points: {
        fontSize: pxToRem(14),
    },
    event_type: {
        color: red.main,
        fontSize: pxToRem(12),
        fontWeight: 500,
    },
    time_container: {
        marginTop: pxToRem(24),
    },
    timezone_price_container: {
        alignItems: 'center',
        display: 'flex',
        gap: pxToRem(49),
        justifyContent: 'space-between',
        marginTop: pxToRem(4),
    },
    timezone_container: {
        display: 'flex',
        gap: pxToRem(8),
    },
    world_icon: {
        width: pxToRem(15),
        height: pxToRem(15),
    },
    timezone: {
        color: white.main,
        fontSize: pxToRem(12),
        fontWeight: 300,
        lineHeight: pxToRem(14),
    },
    price: {
        fontSize: pxToRem(16),
        fontWeight: 400,
    },
    inputs_container: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: pxToRem(36),
        gap: pxToRem(10),
    },
    inputLabel: {
        fontSize: pxToRem(14),
    },
    small_message: {
        color: white.main,
        fontSize: pxToRem(12),
        fontWeight: 300,
        marginTop: pxToRem(14),
    },
    action_container: {
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: '16px',
        width: '100%', 
    },
    action_button: {
        width: pxToRem(196),
        height: pxToRem(42),
        padding: 0,
    },
    action_button_text: {
        fontSize: pxToRem(12),
        fontWeight: 700,
    },
    error: {
        fontSize: pxToRem(12),
    },
};