import React, { memo, useEffect, useState } from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import CalendarIcon from "assets/icons/calendar1.svg";
import { Box } from "@mui/material";
import { useApi, useUserStore } from "../../../utils/hooks";
import Grid from "@mui/material/Grid";
import { styles } from "../../../components/UpcomingMeetsCard/styles";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { UpcomingMeetCard } from "../../../components/UpcomingMeetsCard/MeetCard";
import { RecordCard } from "../../../components/RecordCard/RecordCard";
import { BaseCardIdolInfo } from "../../../base/Cards/InfoCards/IdolizerInfoCard";
import { notifyError, notifySuccess } from "../../../services/notification";
import { UpcomingHeaderControls } from "./UpcomingHeaderControls";
import { MeetingTypeEnum } from "../../../utils/constants";
import moment from "moment";
import { CancelMeetingModal } from "../../../components/ActionsEventModal/Cancel";
import { moneyFormat } from "../../../services/helpers";
import { AuthenticatedPaths } from "../../../paths/paths";


export const UpcomingsCard = memo((props) => {
  const [meetings, setMeetings] = useState([])
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [selectedMeet, setSelectedMeet] = useState(null)
  const api = useApi()
  const userStore = useUserStore()

  const getMeetings = () => {
    api.getUpcomingMeetings().then(response => {
      if (response.kind === "ok") {
        setMeetings(response.data.results)
        setShowCancelModal(false)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  const cancelMeeting = (id) => {
    api.cancelMeeting(id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Meeting cancelled successfully")
        getMeetings()
      } else {
        notifyError("Error canceling this meet")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error canceling this meet")
    })
  }

  const checkIfCanEnter = (meeting, minutes) => {
    const meeting_start_datetime = new Date(meeting.start_datetime).getTime()
    const now = new Date().getTime()
    const initialGapInMiliseconds = minutes * 60 * 1000
    return (now > (meeting_start_datetime - initialGapInMiliseconds))
  }

  const isUpcoming = (meeting, minutes) => {
    return !checkIfCanEnter(meeting, minutes)
  }

  const launch = (meeting) => {
    if (checkIfCanEnter(meeting, 10)) {
      window.open(`${AuthenticatedPaths.videoCall}?room=${meeting.room}`, '_blank');
    } else {
      notifyError("You cannot enter into this meeting yet")
    }
  }

  useEffect(() => {
    getMeetings()
  }, [])


  return (
    <BasePostCard
      title='Upcoming'
      icon={CalendarIcon}
      headerControls={(
        <UpcomingHeaderControls/>
      )}>
      {selectedMeet && (
        <CancelMeetingModal
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onCancel={() => cancelMeeting(selectedMeet.id)}
          avatar={selectedMeet.from_user.image}
          idolizerName={selectedMeet.from_user.username}
          idolPoints={selectedMeet.from_user.idol_points}
          isVerified={selectedMeet.from_user.is_validated}
          date={selectedMeet.start_datetime}
          timeStart={selectedMeet.start_datetime}
          timeEnd={selectedMeet.end_datetime}
          price={moneyFormat(selectedMeet.fee)}
        />
      )}
      <Box sx={{width: "100%", height: "350px", paddingTop: "10px", overflowY: "scroll"}}>
        <Grid container spacing={6}>
          {meetings.map((meeting, index) => {
            const user = userStore.id === meeting.from_user.id? meeting.to_user : meeting.from_user
            return (
              <Grid item md={12} lg={12} key={"Grid-" + index}>
                <RecordCard
                  showCard={false}
                  alignEndContent={true}
                  containerStyles={styles.container}
                  contentContainerStyles={styles.content_container}
                  cardFirstContent={[
                    <ArrowForwardIosRounded size={30}/>,
                    <BaseCardIdolInfo idolizerName={user.username}
                                      idolPoints={user.idol_points}
                                      isVerified={user.is_validated}
                                      avatar={user.image}

                    />,
                  ]}
                  cardSecondContent={(
                    <UpcomingMeetCard
                      title={meeting.name}
                      date={moment(meeting.start_datetime).format("D MMM YYYY").toUpperCase()}
                      timeStart={moment(meeting.start_datetime).format('hh:mm A')}
                      timeEnd={moment(meeting.end_datetime).format('hh:mm A')}
                      price={meeting.fee}
                      meetingPoints={2450}
                      eventType={MeetingTypeEnum.liveCall === "LC" ? "idolcall" : "idolmeet"}
                      upcoming={isUpcoming(meeting, 10)}
                      primaryBtnSize={"small"}
                      secondaryBtnSize={"small"}
                      onLaunch={() => launch(meeting)}
                      onDismiss={() => {
                        setSelectedMeet(meeting)
                        setShowCancelModal(true)
                      }}/>
                  )}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </BasePostCard>
  );
})

