import React, {useState, useEffect} from 'react';

// @mui components
import {CircularProgress, Grid} from "@mui/material";

// Components
import {BasePostCard} from 'base/Cards/PostCard';
import DashboardLayout from 'base/LayoutContainers/DashboardLayout';
import Select from "components/CustomSelect";
import {UserCard} from './user-card';
import {SearchBox} from "components/SearchBox";
import {ConfirmPaymentModal as ConfirmActionModal} from "components/ConfirmPaymentModal";
import {UserProfileTabsForm} from "./user-card/profileForms";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {PrimaryButton} from "components/PrimaryButton";

// Utils
import {useApi} from "utils/hooks";
import {notifyError, notifySuccess} from "services/notification";
import {filterOptions, filterRules, tabSection} from "./constants";
import {usersModifierHandler} from "./utils";
import moment from "moment";

// Assets
import pxToRem from 'assets/theme/functions/pxToRem';

// TODO: change password, infinite scroll, save data (backend appears not work)
export const AllUsersAdminPage = () => {
  const api = useApi();
  const [modalManager, setModalManager] = useState({delete: false, suspend: false});
  const [users, setUsers] = useState([]);
  const [currentFilter, setCurrentFilter] = useState({});
  const [search, setSearch] = useState("");
  const [Countries, setCountries] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [Cities, setCities] = useState([]);
  const [LanguageList, setLanguageList] = useState([]);
  const [GenderList, setGenderList] = useState([]);
  const [EthnicityList, setEthnicityList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabSection.None);
  const [loading, setLoading] = useState(false);
  const [Page, setPage] = useState(1)
  const [Pages, setPages] = useState(1)
  const [loadingProfile, setLoadingProfile] = useState(false);

  const getUsers = (page) => {
    setLoading(true);
    api.getUserProfiles(search, page, currentFilter)
    .then(response => {
      if (response.kind === "ok") {
        const data = response.data?.results;
        const dataSorted = data.sort((prev, next) => prev?.username > next?.username ? 1 : -1)
          setPage(page)
          setUsers(page !== Page ? [...users, ...dataSorted] : [...dataSorted]);
        }
      }).catch(error => notifyError(error.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const getUserProfile = (userId) => {
    setLoadingProfile(true);
    api.getProfile(userId)
      .then(response => {
        if(response.kind === "ok") {
          setUserProfile(response.data);
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setLoadingProfile(false))
  }

  const getCountries = (event, search = "") => {
    api.getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {
          console.log(res);
          setCountries(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  }

  const getRegions = (country, search = "") => {
    if (!country) {
      return;
    }
    api.getRegions(search, country?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setRegions(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (region, search = "") => {
    if (!region) {
      return;
    }
    api.getCities(search, region?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setCities(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getLanguages = () => {
    api.getLanguages()
      .then((res) => {
        if (res.kind === "ok") {
          setLanguageList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getGenders =() => {
    api.getGenders()
      .then((res) => {
        if (res.kind === "ok") {
          setGenderList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getEthnicity = () => {
    api.getEthnicity()
      .then((res) => {
        if (res.kind === "ok") {
          setEthnicityList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getUserRelatedLocations = () => {
    if(userProfile?.inc_country) {
      getRegions(userProfile.inc_country);
    }

    if(userProfile?.inc_state) {
      getCities(userProfile.inc_state);
    }
  }

  const handleChangeFilters = (e) => {
    setCurrentFilter(filterRules[e.target.value]);
  }

  const onSearch = (e) => {
    setSearch(e.target.value);
  }

  const submitValues = (values) => {
    const dataForBackend = {...values}
    const fieldList = [
      'username',
      'idol_id_name',
      'tag_1',
      'tag_2',
      'tag_3',
      'biography',
      'age',
      'height',
      'shoe_size',
      'bust',
      'waist',
      'hips',
      'gender',
      'location',
      'ethnicity',
      'language',
      'bank_name',
      'bank_account_number',
      'bank_routing_number',
    ]
    const objectFields = [
      'ethnicity',
      'gender',
    ]
    const arrayFields = [
      'language',
    ]
    const valuesFieldList = Object.keys(dataForBackend)
    valuesFieldList
      .filter(element => fieldList.includes(element))
      .forEach(field => dataForBackend[field] = values[field])
    valuesFieldList
      .filter(element => objectFields.includes(element))
      .forEach(field => {
        try {
          dataForBackend[field] = values[field].id
        } catch (e) {
          console.log("field", field)
          console.log("self[field]", values[field])
        }
      })
    valuesFieldList
      .filter(element => arrayFields.includes(element))
      .forEach(field => dataForBackend[field] = values[field].map((el) => el.id))
    valuesFieldList
      .filter(element => arrayFields.includes(element))
      .forEach(field => dataForBackend[field] = values[field].map((el) => el.id))



    
    if (dataForBackend.inc_country) dataForBackend.inc_country = dataForBackend?.inc_country?.id
    if (dataForBackend.inc_state) dataForBackend.inc_state = dataForBackend?.inc_state?.id
    if (dataForBackend.inc_city) dataForBackend.inc_city = dataForBackend?.inc_city?.id
    if (dataForBackend.inc_date_of_birth) dataForBackend.inc_date_of_birth = moment(dataForBackend.inc_date_of_birth).format('YYYY-MM-DD')

    if (dataForBackend.tax_country) dataForBackend.tax_country = dataForBackend?.tax_country?.id
    if (dataForBackend.tax_state) dataForBackend.tax_state = dataForBackend?.tax_state?.id
    if (dataForBackend.tax_city) dataForBackend.tax_city = dataForBackend?.tax_city?.id
    
      dataForBackend['user_edition'] = true
      
      api.editUserProfile(dataForBackend).then(res => {
      if (res.kind === 'ok') {
        notifySuccess("Your profile data was updated")
      } else if (res.kind === 'bad-data') {
        if (typeof (res?.errors?.[0]) === 'string') notifyError(res?.errors?.[0])
        else {
          const key = Object.keys(res?.errors)?.[0]
          const msg = `${key}: ${res?.errors?.[key]?.[0]}`
          notifyError(msg)
        }
      }
    })
  }

  const deleteUser = () => {
    api.deleteUser(userAction.id)
      .then(response => {
        if(response.kind === "ok") {
          const usersModified = usersModifierHandler(users, userAction.id, 'is_deleted', true)
          setUsers(usersModified);
          notifySuccess("User deleted successfully");
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setModalManager({...modalManager, delete: false}));
  }

  const suspendUser = () => {
    api.suspendUser(userAction.id)
      .then(response => {
        if(response.kind === "ok") {
          const usersModified = usersModifierHandler(users, userAction.id, 'is_suspended', true);
          setUsers(usersModified);
          notifySuccess("User suspended successfully");
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setModalManager({...modalManager, suspend: false}));
  }

  const undeleteUser = () => {
    api.reinstateUser(userAction.id)
      .then(response => {
        if(response.kind === "ok") {
          const usersModified = usersModifierHandler(users, userAction.id, 'is_deleted', false);
          setUsers(usersModified);
          notifySuccess("User reinstated successfully!");
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setModalManager({...modalManager, delete: false}))
  }

  const unsuspendUser = () => {
    api.unsuspendUser(userAction.id)
      .then(response => {
        if(response.kind === "ok") {
          const usersModified = usersModifierHandler(users, userAction.id, 'is_suspended', false);
          setUsers(usersModified);
          notifySuccess("User unsuspend successfully!");
        }
      })
  }

  const handleSelectUser = (id) => {
    setCurrentTab(null);
    setSelectedUserId(id);
  }

  const handleChangeTab = (e, value) => {
    if (currentTab === value) return setCurrentTab(null);
    setCurrentTab(value);
  }

  const handleCloseDeleteUserModal = () => {
    setModalManager({...modalManager, delete: false});
  }

  const handleCloseSuspendUserModal = () => {
    setModalManager({...modalManager, suspend: false})
  }

  const selectOptions = Object.keys(filterOptions).map(value => {
    return {label: filterOptions[value], value: value}
  })

  useEffect(() => {
    getUsers(Page);
  }, [currentFilter, search])

  useEffect(() => {
    getCountries("");
    getLanguages();
    getGenders();
    getEthnicity();
  }, []);

  useEffect(() => {
    if(selectedUserId) {
      getUserProfile(selectedUserId);
    }
  }, [selectedUserId])

  const loadMoreButton = () => {
    if (Page !== Pages) {
    return <Grid container my={1}>
      <Grid item md={3} />
      <Grid item md={6} sx={{marginTop: '40px'}} display='flex' justifyContent={'center'}>
        <PrimaryButton
          text="LOAD MORE"
          textStyles={styles.actionButtonText}
          buttonStyle={styles.actionButton}
          loading={loading}
          disabled={loading}
          onClick={() => getUsers(Page + 1)}
        />
      </Grid>
      <Grid item md={3} />
    </Grid>
    }
  }

  useEffect(() => {
   getUserRelatedLocations();
  }, [userProfile])

  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox my={pxToRem(34)} mx={pxToRem(102)}>
        <BasePostCard
          {...{
            title: 'ALL USERS',
            cardStyles: {p: pxToRem(24)},
            titleContainerStyles: {alignItems: "flex-end", height: pxToRem(56)},
            titleStyles: {fontSize: pxToRem(48), fontWeight: 100},
          }}
        >
          <MDBox display='flex' gap={pxToRem(24)} mt={pxToRem(14)}>
            <SearchBox
              placeholder="Search users..."
              inputStyles={{width: pxToRem(402)}}
              onChange={onSearch}
              value={search}
            />
            <Select
              options={selectOptions}
              defaultValue={selectOptions[0]}
              selectStyles={{width: pxToRem(129)}}
              onChange={handleChangeFilters}
            />
          </MDBox>
          <MDBox display='flex' flexDirection='column' gap={pxToRem(14)} mt={pxToRem(24)} minHeight={pxToRem(50)}
            maxHeight={pxToRem(570)} pr={pxToRem(25)} overflow='hidden auto'>
            {loading
              ? <MDBox display='flex' justifyContent='center'><CircularProgress /></MDBox>
              : users.length === 0
                ? <MDTypography color='light' size='md'>No users found</MDTypography>
                : <>
                  {users.map((user, i) => (
                    <UserCard key={i} {...{ user, selectedUserId, setUserAction, loadingProfile, setModalManager, handleSelectUser }}>
                      <UserProfileTabsForm
                        {...
                        {
                          user,
                          userProfile,
                          selectedUserId,
                          Countries,
                          Regions,
                          Cities,
                          LanguageList,
                          GenderList,
                          EthnicityList,
                          currentTab,
                          tabSection,
                          handleChangeTab,
                          getRegions,
                          getCities,
                          submitValues,
                        }}
                      />
                    </UserCard>
                  ))
                  }
                  {loadMoreButton()}
                </>
            }
          </MDBox>
        </BasePostCard>
      </MDBox>
      <ConfirmActionModal
        open={modalManager.delete}
        onClose={handleCloseDeleteUserModal}
        title={!userAction?.is_deleted ? 'DELETE USER' : 'REINSTATE USER'}
        message={`Are you sure that you want to ${!userAction?.is_deleted ? 'delete' : 'reinstate'} this user?`}
        confirmLabel='CONFIRM'
        confirmAction={!userAction?.is_deleted ? deleteUser : undeleteUser}
        cancelAction={handleCloseDeleteUserModal}
      />
      <ConfirmActionModal
        open={modalManager.suspend}
        onClose={handleCloseSuspendUserModal}
        title={!userAction?.is_suspended ? 'SUSPEND USER' : 'UNSUSPEND USER'}
        message={`Are you sure that you want to ${!userAction?.is_suspended ? 'suspend' : 'unsuspend'} this user?`}
        confirmLabel='CONFIRM'
        confirmAction={!userAction?.is_suspended ? suspendUser : unsuspendUser}
        cancelAction={handleCloseSuspendUserModal}
      />
    </DashboardLayout>
  );
}



const styles = {
  actionButton: {
    borderRadius: "10px",
    width: "70%",
    height: "32px"
  },
  actionButtonText: {
    fontSize: 14,
    fontWeight: 400
  }
}
