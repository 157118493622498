import colors from 'assets/theme/base/colors';
import pxToRem from "../../assets/theme/functions/pxToRem";
const {white, red} = colors;

export const primaryTextStyle = (isMobile)=>{
    let defaultStyle = {...styles.primary_content}
    if(isMobile){
        defaultStyle = {
            ...defaultStyle,
            fontSize: pxToRem(36),
            fontWeight: 100

        }
    }
    return defaultStyle
}

export const secondaryTextStyle = (isMobile)=>{
    let defaultStyle = {...styles.secondary_content}
    if(isMobile){
        defaultStyle = {
            ...defaultStyle,
            fontSize: pxToRem(15),
        }
    }
    return defaultStyle
}

export const primaryContainer = (isMobile)=>{
    let defaultStyle = {...styles.primary_content_container}
    if(isMobile){
        defaultStyle = {
            ...defaultStyle,
            paddingBottom: 0
        }
    }
    return defaultStyle
}


export const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    primary_content_container: {
        display: 'flex',
        borderBottom: `2px solid ${red.main}`,
        justifyContent: 'center',
        paddingBottom: 1,
        paddingInline: 2.5,
    },
    primary_content: {
        color: white.main,
        fontWeight: 'lighter',
        fontSize:  pxToRem(75),

    },
    secondary_content: {
        color: red.main,
        fontWeight: 'bold',
        fontSize: '1rem',
        paddingTop: 1,
        textAlign: 'center',
    },
}
