import React from 'react';
import ExploreContext from "./ExploreContext";
import { EXPLORE_ACTIONS, ExploreLayout } from "./utils/constants";
import { FilterType, SearchFilterType } from "../constants";
import { exploreReducer } from "./utils/reducers";

const init = (appState) => appState;


const ExploreProvider = ({children}) => {

    const [exploreApp, setExploreApp] = React.useReducer(exploreReducer, {
        refreshList: false,
        searchFilter: SearchFilterType.users,
        exploreFilter: FilterType.all,
        exploreLayout: ExploreLayout.explore,
        searchValue:""
    }, init);

    const setRefreshList = React.useCallback((refresh) => {
        setExploreApp({type: EXPLORE_ACTIONS.SET_LIST_REFRESH, payload: refresh})
    }, [setExploreApp]);

    const setSearchFilter = React.useCallback((filterName) => {
        setExploreApp({type: EXPLORE_ACTIONS.SET_SEARCH_FILTER, payload: filterName})
    }, [setExploreApp]);


    const setExploreFilter = React.useCallback((filterName) => {
        setExploreApp({type: EXPLORE_ACTIONS.SET_EXPLORE_FILTER, payload: filterName})
    }, [setExploreApp]);

    const setExploreLayout = React.useCallback((layoutName) => {
        setExploreApp({type: EXPLORE_ACTIONS.SET_LAYOUT, payload: layoutName})
    }, [setExploreApp]);

    const setSearchValue = React.useCallback((value) => {
        setExploreApp({type: EXPLORE_ACTIONS.SET_SEARCH_VALUE, payload: value})
    }, [setExploreApp]);


    const contextValue = React.useMemo(() => ({
        ...exploreApp,
        setRefreshList,
        setSearchFilter,
        setExploreFilter,
        setExploreLayout,
        setSearchValue
    }), [exploreApp]);

    return (
      <ExploreContext.Provider value={contextValue}>
          {children}
      </ExploreContext.Provider>
    );
};

export default ExploreProvider;
