import colors from 'assets/theme/base/colors';
import typography from "../../assets/theme/base/typography";
import pxToRem from 'assets/theme/functions/pxToRem';

const {card, buttons: {filterButton}, tab, tertiary} = colors;

const getTabsStyles = (theme) => {
  return {
    background: theme.palette.transparent.main,
  }
}

const getTabStyles = (theme) => {
  return {
    background: `${theme.palette.transparent.main}`,
    color: `${theme.palette.background.main}!important`,
  }
}

const getCardStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    padding: pxToRem(24),
    background: card.background,
    gap: pxToRem(12),
  }
}

const getAccordionStyles = (theme, background) => {
  return {
    borderRadius: "10px",
    paddingY: pxToRem(15),
    paddingX: pxToRem(24),
    background: background === 'bold' ? card.background : tertiary.main,
  }
}

const getTitleStyles = () => {
  return {
    fontSize: pxToRem(22),
    fontWeight: typography.fontWeightRegular,
    color: colors.grey['480'],
  }
}

const getHeaderStyles = () => {
  return {
    padding: '0px 0px 10px 0px',
    borderBottom: `2px solid ${colors.primary.main}`,
  }
}

const getAccordionSummaryStyles = () => {
  return {
    padding: 0,
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column'
    }
  }
}

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '562px',
    maxWidth: {xs: '100%', lg: '70%'},
    marginLeft: {xs: '0px', lg: '95px'},
    marginTop: {xs: '0px', lg: '50px'},
    gap: pxToRem(28),
  },
  tabs: {
    background: 'transparent',
    '.MuiTabs-flexContainer': {
      paddingBottom: pxToRem(17),
    },
    '.MuiTabs-indicator': {
      background: 'transparent',
      borderBottom: `2px ${filterButton.selected} solid`,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
  selected_tab: {
    color: `${filterButton.selected} !important`,
    fontSize: pxToRem(14)
  },
  unselected_tab: {
    color: `${tab.unselected} !important`,
    fontSize: pxToRem(14)
  },
  unitsContainer:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    height:"100%",
    marginY: "14px"
  },
};

const downloadDocumentStyle = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: pxToRem(13)
  }
}

const downloadDocumentLabelStyle = (theme) => {
  return {
    fontSize: pxToRem(14),
    color: '#FFFFFF',
    fontWeight: 400
  }
}

const getExpandIconStyles = (theme) => {
  return {
    color: theme.palette.grey['480']
  }
}

const getFormButtonsBoxStyles = {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "space-between",
    gap: {xs: "20px", lg: "25px"},
    marginTop: pxToRem(24),
    padding: {xs: "0px 20px 0px 20px", lg: "0px 0px 0px 105px"}
}

export {
  styles,
  getHeaderStyles,
  getTitleStyles,
  getCardStyles,
  getTabStyles,
  getTabsStyles,
  downloadDocumentStyle,
  downloadDocumentLabelStyle,
  getAccordionStyles,
  getAccordionSummaryStyles,
  getExpandIconStyles,
  getFormButtonsBoxStyles,
};
