import {UserTypes} from "constants.js";

export const tabName = {
  [UserTypes.Idol]: {is_idol: true},
  [UserTypes.Idolizer]: {is_idol: false},
}


export const tabSection = {
  None: 0,
  IdolProfile: 1,
  IdolBank: 2,
  IdolTax: 3,
  IdolInc: 4,
  IdolVerify: 5,
  IdolizerVerify: 6,
  IdolizerDetails: 7,
};
