/* dependencies */
import PropTypes from 'prop-types';

/* MUI components */
import Modal from '@mui/material/Modal';

/* components */
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

/* assets */
import {styles} from './styles';
import {useIsMobile} from 'utils/hooks';

export const BaseModal = ({
  open,
  onClose,
  title,
  containerStyles,
  headerStyles,
  titleStyles,
  modalWidth,
  headerTitleStyles,
  children,
  removeBullshit,
  removeBullshitAgain,
  bodyExtraStyle,
  disableBackdropClick=false,
  ...rest
}) => {
  const isMobile = useIsMobile();
  let bodyStyle = {...styles.modal_content_container, rest}
  if (removeBullshit){
    bodyStyle = {...styles.modal_content_container, marginInline: ""}
  }
  if (removeBullshitAgain){
    bodyStyle = {...styles.modal_content_container, marginBlockEnd: "",}
  }
  if (bodyExtraStyle){
    bodyStyle = {...bodyStyle, ...bodyExtraStyle}
  }

  const handleClose = (event, reason)=>{
    if(disableBackdropClick){
      if (reason === "backdropClick") {
        event.stopPropagation();
        return false;
      } else {
        onClose();
      }
    }else{
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={handleClose} sx={styles.modal}>
      <MDBox sx={[styles.modal_container, { width: modalWidth }, containerStyles]}>
        <MDBox sx={[styles.modal_header, headerStyles]}>
          <MDTypography
            sx={[
              styles.modal_header_title,
              headerTitleStyles,
              isMobile && styles.modal_header_title_mobile,
            ]}
          >
            {title}
          </MDTypography>
        </MDBox>
        <MDBox sx={bodyStyle}>
          <MDBox sx={[styles.colorWhite]}>{children}</MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
};

BaseModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    centerContent: PropTypes.bool,
    containerStyles: PropTypes.object,
};

BaseModal.defaultProps = {
  modalWidth: '47%',
};

