
export const basicCheckboxStyle = (theme, borderNone, extraStyle) => {
  const {palette} = theme

  let defaultStyle =  {
    borderColor:"transparent",
    transform: "scale(1.3)",
    '&.MuiCheckbox-root .MuiSvgIcon-root ':{
      borderColor:palette.secondary.main,
      color: palette.primary.main,
      border: borderNone && 'none'
    },
    marginLeft: '16px',
  }
  if(extraStyle){
    defaultStyle = {...defaultStyle,...extraStyle}
  }
  return defaultStyle
}
