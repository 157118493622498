import {useMaterialUIController, setLayout} from "context";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {backButtonContainer, leftContainerStyle, textContainer} from "./styles";
import MDBox from "../MDBox";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import OnboardingLogo from "assets/images/onboarding_logo.png";
import MobileOnboardingLogo from "assets/images/mobile_onboarding_logo.png";
import MDTypography from "../MDTypography";
import {Box, Button, useMediaQuery} from "@mui/material";
import colors from "assets/theme/base/colors";
import {useTheme} from "@mui/material/styles";
import { LayoutNames } from "../../constants";

export const OnboardingLayout = ({children,
                                   titleBold,
                                   titleNormal,
                                   subtitle= null,
                                   backTo=null,
                                   mt=12,
                                   ml=15,
                                   progressBarPercent,
                                   leftStyle={},
                                   subtitleStyle={}})=>{
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();
  const navigate  = useNavigate()
  const theme = useTheme();
  const { palette } = theme;


  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setLayout(dispatch, LayoutNames.onboarding);
  }, [pathname]);

  return (
    <Box sx={{display:"flex", flexDirection:isMobile?"column":"row"}}>
      {!isMobile && (
        <MDBox sx={(theme)=>leftContainerStyle(theme, leftStyle)} />
      )}

      {!isMobile && (
        <MDBox style={{marginTop:120, marginLeft:-84}}>
          <Box sx={{position: "sticky", top: 120}}>
          <img src={OnboardingLogo} />
          </Box>
        </MDBox>
      )}


      <MDBox sx={(theme)=>backButtonContainer(isMobile)}>
        <Button onClick={()=>{
          if(backTo){
            navigate(backTo)
          }
        }}>
          <ArrowBackIosNewIcon sx={{height:isMobile?20:30, width:isMobile?20:30}}
                               color={"white"} />
        </Button>

        {isMobile && (
          <>
          <MDBox>
            <img src={MobileOnboardingLogo} width={"54px"} height={"51px"}  />
          </MDBox>
          <MDBox style={{width:"20%"}}/>
          </>
        )}
      </MDBox>

      <MDBox sx={()=>textContainer(isMobile)}>
        {typeof titleBold === "string" && (
          <MDTypography style={{color:colors.white.main}}
                        fontWeight={"bold"}
                        sx={{fontSize:isMobile?48:94}}>{titleBold}  </MDTypography>
        ) || (
          <>{titleBold}</>
        )}
        {typeof titleNormal === "string" && (
          <MDTypography style={{color:colors.white.main}}
                        fontWeight={"light"}
                        sx={{fontSize:isMobile?48:94, mt:isMobile?-3:-9}}>
            {titleNormal}
          </MDTypography>
        ) || (
          <>{titleNormal}</>
        )}
        {progressBarPercent && (
          <MDBox sx={{backgroundColor:colors.inputBackgroundColor,
            height:"4px", borderRadius:"50px", mb:4}}>
            <MDBox style={{
              backgroundColor:colors.primary.main,
              height:"4px",
              width:progressBarPercent,
              borderRadius:"50px"}} />
          </MDBox>
        )}


        {subtitle && (
          <MDTypography
            fontWeight={"light"}
            sx={{ ...styles.subtitle, ...subtitleStyle, width: isMobile?"100%":"480px", letterSpacing: "0.1em", fontSize: isMobile?"12px":"14px"}}>
            {subtitle}
          </MDTypography>
        )}
        <MDBox style={styles.body}>
          {children}
        </MDBox>
      </MDBox>
    </Box>)
}

const styles = {
  body:{
    display:"flex",
    flexDirection:"column",
    // alignItems:"center"
  },

  subtitle: {
    color: colors.white.main,
    width:517,
    textAlign: 'justify',
    letterSpacing: 0,
    lineHeight: 1.3,
    fontWeight: 400
  }
}
