import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

// @mui components
import {CircularProgress} from "@mui/material";

// Components
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {BasePostCard} from "base/Cards/PostCard";
import {RecordCard} from "components/RecordCard/RecordCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {SecondaryButton} from "components/SecondaryButton";
import {BaseCardIdolInfo} from "base/Cards/InfoCards/IdolizerInfoCard";

// Utils
import {useApi} from "utils/hooks";
import {showMessage} from "services/helpers";
import {IdolTypesInformation} from "../../../constants";

// Assets
import LogoGrey from "assets/images/idol_grey.png";
import pxToRem from "assets/theme/functions/pxToRem";

export const AdminImportedContentPage = () => {

  const navigate = useNavigate();
  const api = useApi();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getContents = () => {
    setLoading(true);
    api.usersPendingPostsModeration()
      .then(response => {
        if(response.kind === "ok") {
          setUsers(response.data);
        }
      })
      .catch(error => showMessage(error.message))
      .finally(() => setLoading(false))
  }

  const goToUserPosts = (id) => {
    navigate(`${id}`);
  }

  useEffect(() => {
    getContents();
  }, [])

  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox my={pxToRem(34)} mx={pxToRem(102)}>
        <BasePostCard title="IMPORTED CONTENT" cardStyles={{width: "100%"}}>
          <MDBox mt={pxToRem(14)} width='100%' minHeight={pxToRem(50)} maxHeight={pxToRem(700)} pr={pxToRem(25)} overflow={`hidden auto`}>
            {loading
              ? <MDBox display='flex' justifyContent='center'><CircularProgress /></MDBox>
              : (
               <MDBox display='flex' flexDirection='column' gap={pxToRem(14)}>
                 { users.length === 0
                   ? <MDTypography color='light' size='md'>No data found</MDTypography>
                   : users.map(u => (
                   <RecordCard
                     key={u.id}
                     {...{
                       cardFirstContent: (
                         <BaseCardIdolInfo
                           avatar={u?.image ?? u.thumbnail}
                           idolizerName={u?.username}
                           idolPoints={u?.idol_points}
                           infoCardStyles={{padding: 0}}
                           nameStyles={{fontSize: pxToRem(16)}}
                           idpStyles={{fontSize: pxToRem(14)}}
                           pointStyles={{fontSize: pxToRem(14)}}
                         />
                       ),
                       cardSecondContent: (
                         <MDBox display='flex' alignItems='center' justifyContent='space-between' width='100%'>
                           <MDBox display='flex' alignItems='center'>
                             <MDBox mx={pxToRem(23)} display='flex' alignItems='flex-end' gap={pxToRem(21)}>
                               <MDBox component='img' src={IdolTypesInformation[u?.idol_type]?.logo} width={pxToRem(35)} height={pxToRem(35)} pt={pxToRem(2)} />
                               <MDBox mb={pxToRem(0)}>
                                 <MDTypography variant='button' color='light'>{IdolTypesInformation[u?.idol_type]?.name}</MDTypography>
                               </MDBox>
                             </MDBox>
                             <MDBox
                               width={pxToRem(8)}
                               height={pxToRem(52)}
                               sx={({palette: {background}}) => ({
                                 background: background.default,
                                 borderRadius: "8px",
                                 opacity: 0.2
                               })}
                             />
                           </MDBox>
                           <MDBox margin='auto'>
                            <MDTypography color='light' variant='h6' fontWeight='regular'>{u?.email}</MDTypography>
                           </MDBox>
                         </MDBox>
                       ),
                       cardThirdContent: (
                         <MDBox display='flex' alignItems='center' gap={pxToRem(28)}>
                            <MDBox component='img' src={LogoGrey} alt='logo' />
                            <SecondaryButton
                              text='View'
                              textStyles={{fontSize: pxToRem(14), color: 'white !important'}}
                              backgroundColor='#243040'
                              border='none'
                              width={pxToRem(122)}
                              buttonStyle={{minHeight: `${pxToRem(32)} !important`}}
                              onClick={() => goToUserPosts(u?.id)}
                            />
                         </MDBox>
                       ),
                       showOptionsCard: false,
                       secondContentStyle: {justifyContent: 'flex-start'},
                       cardThirdContentStyles: {width: pxToRem(240)},
                       containerStyles: {mt: 0, height: '100%'}
                     }}
                   />
                 ))}
               </MDBox>
              )
            }
          </MDBox>
        </BasePostCard>
      </MDBox>
    </DashboardLayout>
  )
}