import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white, modal} = colors;

export const desktopStyles = {
    modal: {
        width: pxToRem(660),
        height: pxToRem(293),
    },
    container: {
        background: modal.confirm_payment.background,
        width: { xs: "90%", lg: "30%" }
    },
    header: {
        background: modal.confirm_payment.header,
    },
    content_container: {
        marginInline: 'auto',
        marginBlockEnd: pxToRem(34),
    },
    message: {
        color: white.main,
        letterSpacing: '0.1em',
        lineHeight: '127.25%',
        textAlign: 'center',
        fontSize: pxToRem(16),
    },
    actions_container: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(20),
        marginTop: pxToRem(52),
    },
    confirm: {
        alignSelf: 'center',
        fontSize: pxToRem(14),
        fontWeight: 700,
        height: pxToRem(40),
        lineHeight: '93.75%',
        letterSpacing: '0.1rem',
        width: {xs: pxToRem(192), lg: pxToRem(292)}
    },
    confirm_text: {
        fontSize: pxToRem(14),
    },
    cancel: {
        color: white.main,
        fontSize: pxToRem(14),
        cursor: 'pointer',
        textAlign: 'center',
    },
};


export const mobileStyles = {
    modal: {
        width: pxToRem(660),
        height: pxToRem(293),
    },
    container: {
        background: modal.confirm_payment.background,
        width: { xs: "90%", lg: "30%" }
    },
    header: {
        background: modal.confirm_payment.header,
    },
    content_container: {
        marginInline: 'auto',
        marginBlockEnd: pxToRem(34),
    },
    message: {
        color: white.main,
        letterSpacing: '0.1em',
        lineHeight: '127.25%',
        textAlign: 'center',
        fontSize: pxToRem(14),
    },

    actions_container: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(20),
        marginTop: pxToRem(52),
    },
    confirm: {
        alignSelf: 'center',
        fontSize: pxToRem(14),
        fontWeight: 700,
        height: pxToRem(40),
        lineHeight: '93.75%',
        letterSpacing: '0.1rem',
        width: {xs: pxToRem(192), lg: pxToRem(292)}
    },
    confirm_text: {
        fontSize: pxToRem(14),
    },
    cancel: {
        color: white.main,
        fontSize: pxToRem(14),
        cursor: 'pointer',
        textAlign: 'center',
    },
};
