

export const leftContainerStyle = (theme,leftStyle) => {
  const { palette } = theme;

  return {
    width:"30%",
    minHeight:"100vh",
    backgroundColor: palette.primary.main,
    ...leftStyle,
  }
}

export const backButtonContainer = (isMobile)=>{
  let style = {}
  if(isMobile) {
    style = {
      mt: 4,
      ml:1,
      display:"flex",
      flexDirection:"row",
      justifyContent:"space-between",
      alignItems:"center"
    }
  }else{
    style  = {
      mt: 18,
      ml:15,
      marginLeft: '10%'
    }
  }

  return style
}

export const textContainer = (isMobile)=>{
  let style = {}
  if(isMobile){
    style = {
      pl:4,
      pr:4,

    }
  }else{
    style  = {
      ml: '30px',
      mt:6,
      paddingRight: '50px'
    }
  }
  return style
}

