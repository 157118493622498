import React from 'react'
import OrderingButton from "components/WithdrawButton/OrderingButton";
import { Box } from "@mui/material";

const OrderByType = ({selectedFilterOrder, setSelected}) => {
  return (
    <OrderingButton
      text='TYPE'
      currentFilterSelected={selectedFilterOrder === 'TYPE'}
      onClick={() => setSelected('TYPE')}
    />
  )
}

const OrderByDate = ({selectedFilterOrder, setSelected}) => {
    return (
        <OrderingButton
          text='DATE'
          currentFilterSelected={selectedFilterOrder === 'DATE'}
          onClick={() => setSelected('DATE')}
        />
    )
}

const OrderByPoints = ({selectedFilterOrder, setSelected}) => {
    return (
        <OrderingButton
          text='POINTS'
          currentFilterSelected={selectedFilterOrder === 'POINTS'}
          onClick={() => setSelected('POINTS')}
        />
    )
}

export const UpcomingHeaderControls = (props) => {

    const {setSelectedFilterOrder, selectedFilterOrder} = props;

    const setSelected = (value) => {

        if(selectedFilterOrder === value) return
        setSelectedFilterOrder(value)
    }

    const headersProps = {
        ...props,
        setSelected,
    };

    return (
      <Box sx={{
          display: "flex",
          flexDirection: "row",
          width: "360px",
          gap: "25px",
          justifyContent: "flex-end"
      }}>
        <OrderByType {...headersProps} />
        <OrderByDate {...headersProps} />
        <OrderByPoints {...headersProps} />
      </Box>

)
}
