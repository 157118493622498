/* dependencies */
import PropTypes from 'prop-types';

/* components */
import {Typography, Box} from '@mui/material';

/* assets */
import {styles} from './styles';

export const SubHeader = ({data, pointStyles, centerPoints = true, containerStyles, isMobile, idpStyles, ...rest}) => {
    return (
      <Box sx={[styles.subheader, { ...containerStyles }]}>
        <Typography
          sx={[
            styles.points,
            !isMobile ? styles.points_desktop : styles.mobile_points,
            { ...pointStyles },
          ]}
        >
          {data}
        </Typography>
        <Typography
          sx={[
            styles.points_idp,
            !isMobile ? styles.points_idp_desktop : styles.points_idp_mobile,
            !centerPoints && styles.centered_points_idp,
            idpStyles,
          ]}
        >
          idp
        </Typography>
      </Box>
    );
}

SubHeader.propTypes = {
    data: PropTypes.number.isRequired,
    pointStyles: PropTypes.object,
}


