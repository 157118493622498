export const filteringFields = {
  Type: "type",
  Points: "points"
}

export const tab = {
  Followers: "Followers",
  Idolizers: "Idolizers",
  Following: "Following",
  Idolizing: "Idolizing",
}

export const ErrorMessage = <div><span>NO RECORDS FOUND</span></div>
