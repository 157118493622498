/* dependencies */
import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {observer} from "mobx-react";

/* MUI components */
import {Box} from '@mui/material';

/* base components */
import DashboardLayout from "base/LayoutContainers/DashboardLayout";

/* components */
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {UserHeaderInfo} from "components/UserHeaderInfo";
import {CustomTextField} from 'components/TextField';
import {SecondaryButton} from 'components/SecondaryButton';

/* models */
import {useStores} from 'models';

/* assets */
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import {NavigationMenu} from "../../../constants";
import {useApi} from "../../../utils/hooks";
import {notifyError, notifySuccess} from "../../../services/notification";
import {toBase64} from "../../../utils/functions";
const {transparent, admin} = colors;

export const IdolizerProfilePage = observer(() => {
  const {loginStore} = useStores();
  const api = useApi()

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.EDITING_PROFILE);
    loginStore.setMobileActiveRoute("EDIT PROFILE")
  }, [])

  const ProfilePicture = loginStore.image;
  const formik = useFormik({
    initialValues: {
        username: loginStore.username ?? '',
        email: loginStore.email ?? '',
        password: '',
        newPassword: '',
        newPasswordRepeat: '',
    },
    onSubmit: (values) => {
      editProfile(values)
    },
  });

  const getProfile = (userId) => {
    loginStore.environment.api.getProfileDetail(userId)
      .then((result) => {
        if (result?.kind === 'ok') {
          loginStore.setUser(result.data)
        }
      })
  };

  //TODO: FIX THIS ENDPOINT KENNETH
  const editProfile = (values) => {
    const formData = {
      username: values.username,
      email: values.email,
      old_password: values.password || null,
      new_password: values.newPassword || null,
      confirm_new_password: values.newPasswordRepeat || null,
    }
    api.editProfile(formData).then((result) => {
      if (result.kind === "ok") {
        getProfile(loginStore?.id)
        notifySuccess("Successfully updated your information")
        // history.push("/my-profile");
      } else if (result.kind === "bad-data") {
        // loginStore.setUser(loginStore)
        const { errors } = result
        Object.values(errors).map(e => notifyError(`${e}`, {autoHideDuration: 5000}))
      }

    }).catch((error) => {
      Object.values(error).map(e => notifyError(`${e}`, {autoHideDuration: 5000}))
    })
  }

  const updateProfilePicture = (image) => {
    loginStore.environment.api.editProfilePicture(image).then(res => {
      if (res.kind === 'ok') {
        notifySuccess("Profile picture updated")
      } else {
        notifyError("Something wrong happenned")
      }
    }).finally(() => loginStore.setProfilePictureVersion(Date.now()))
  }

  const onCancel = () => {};

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        <Box sx={styles.container}>
          <UserHeaderInfo
            profile_photo={`${ProfilePicture}?q=${loginStore.ProfilePictureVersion}`}
            handleUpdateProfilePicture={async (file) => {
            const b64file = await toBase64(file)
            updateProfilePicture({
              fileName: file.name,
              file: b64file,
            })
          }}
            username={loginStore.username}
            is_validated={loginStore.is_validated}
          />
          <Box sx={styles.fields_container}>
            <CustomTextField
              name="username"
              inputLabel="Username"
              value={formik.values.username}
              onChange={formik.handleChange}
            />

            <CustomTextField
              name="email"
              inputLabel="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              disabled={true}
            />

            <CustomTextField
              name="password"
              inputLabel="Password"
              onChange={formik.handleChange}
              type="password"
            />

            <CustomTextField
              name="newPassword"
              inputLabel="New Password"
              onChange={formik.handleChange}
              type="password"
            />

            <CustomTextField
              name="newPasswordRepeat"
              inputLabel="Repeat New Password"
              onChange={formik.handleChange}
              type="password"
            />

            <Box sx={styles.actions_container}>
              <SecondaryButton
                text="CANCEL"
                buttonStyle={styles.deny}
                textStyles={styles.deny.text}
                onClick={onCancel}
              />
              <SecondaryButton
                text="SAVE"
                buttonStyle={styles.save}
                textStyles={styles.save.text}
                onClick={formik.handleSubmit}
                disabled={!formik.values.email}
              />
            </Box>
          </Box>
        </Box>
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
})

const styles = {
  container: {
    margin: {lg: `${pxToRem(60)}`},
  },
  fields_container: {
    display: "flex",
    flexDirection: "column",
    gap: pxToRem(24),
    marginTop: pxToRem(45),
  },
  actions_container: {
    alignSelf: {xs: 'center', lg: 'end'},
    display: "flex",
    flexDirection: {xs: 'column', lg: 'row'},
    gap: pxToRem(24),
  },
  deny: {
    background: transparent.main,
    borderColor: admin.deny_button,
    padding: 0,
    width: {xs: '100%', lg: pxToRem(145)},
    "&:hover": { background: "inherit" },
    text: {
      color: admin.deny_button,
      fontSize: pxToRem(12),
      fontWeight: 500,
      "&:hover": { color: admin.deny_button },
    },
  },
  save: {
    background: transparent.main,
    height: pxToRem(40),
    padding: 0,
    width: pxToRem(292),
    text: {
      fontSize: pxToRem(14),
      fontWeight: 700,
    },
  },
};
