import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem';
const {transparent, modal} = colors;

export const styles = {
    actions: {
        alignItems: 'center',
        display: 'flex',
        gap: 4,
        marginTop: 0.2,
        paddingInline: '0 3.3%',
    },
    logo_container: {
        paddingLeft:"15px",
        alignItems: 'center',
        display: 'flex',
    },
    logo_img: {
        width: pxToRem(30),
        height: '100%',
    },
    buttons_container: {
        display: 'flex',
        gap: '21px',
        justifyContent: 'space-between',
        width: '100%',
    },
    button: {
        width: '50%',
        padding: 0,
    },
    dismissButton: {
        background: transparent.main,
        color: modal.buttons.cancel,
    },
}
