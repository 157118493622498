import React, { useState } from "react";
import {BasePostCard} from 'base/Cards/PostCard';
import {WithdrawlCardDetailedRecord} from "components/RecordCard/RecordCard";

/* assets */
import icon from 'assets/icons/withdrawls.svg';
import pricetag from 'assets/icons/price-tag.svg';
import eventIcon from 'assets/icons/events.svg';
import meet from 'assets/icons/meet_white.svg';
import dollar from 'assets/icons/dollar_white.svg';
import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';
import { useApi, useInfiniteScroll } from "../../../../../utils/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../../components/Loader";

const Icons = {
  subscription:pricetag,
  deposit:pricetag,
  post:pricetag,
  meet_greet:meet,
  live_call:eventIcon,
  tip:dollar
}

export const IdolizerTransactionsPage = () => {
  const [loading, setLoading] = useState(true)
  const api = useApi()

  const onApiCall = ({page})=>{
    setLoading(true)
    let data = {page}
    return api.getTransactions(data)
  }


  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "transactions"})


  return (
    <BasePostCard title="TRANSACTIONS" icon={icon}>
      <InfiniteScroll
        dataLength={items.length}
        style={styles.scroll}
        next={() => {
          if(!isFetchingNextPage){
            return fetchNextPage()
          }
        }}
        hasMore={!!hasNextPage}
        loader={<Loader show={isLoading}/> }
      >
        {items.map((transaction, index)=>(
          <WithdrawlCardDetailedRecord
            titleStyle={{fontSize:16}}
            dateStyle={{fontSize:14}}
            key={transaction.id}
            record={transaction}
            iconCard={Icons[transaction.origin_type]??Icons.meet}
            showArrow={false}
            centered={false}
            amountStyles={styles.amount}
          />
        ))}
      </InfiniteScroll>
    </BasePostCard>
  );
}

const styles = {
  scroll:{
    padding:"0px 25px 0px 10px",
    margin:"24px -10px 0px -10px",
    maxHeight:"80vh"
  },
  amount: {
    color: colors.white.main,
    fontSize:22,
    paddingLeft: pxToRem(59),
  },
}
