import {observer} from "mobx-react";
import {Box, Grid, Stack, Tab, Tabs, Typography, useMediaQuery} from '@mui/material';
import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import DashboardLayout from "../../base/LayoutContainers/DashboardLayout";
import * as React from "react";
import {useEffect, useState} from "react";
import {UserHeaderInfo} from "../../components/UserHeaderInfo";
import {getFormikValidationFields, getInitialFormValuesFromStore, ProfileCards, useStores} from "../../models";
import {ProfileCard} from "./components";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {getFormButtonsBoxStyles, styles} from "./styles"
import {useFormik} from "formik";
import {CustomAutocompleteFormik, CustomAutocompleteWithChipFormik} from "../../components/AutocompleteFormik";
import MDTypography from "../../components/MDTypography";
import colors from "../../assets/theme/base/colors";
import {PrimaryButton} from "../../components/PrimaryButton";
import {useTheme} from "@mui/material/styles";
import {Units} from "../../constants";
import {ThinButtonTransparent} from "../../components/ThinButtonTransparent";
import {CustomFormikDateField, CustomFormikTextField, DownloadDocument} from "components/TextField";
import {useNavigate} from "react-router-dom";
import {AuthenticatedPaths} from "../../paths/paths";
import {toBase64} from "../../utils/functions";
import UpgradeAccountIcon from "../../assets/icons/upgradeAccount.svg"
import {notifyError, notifySuccess, notifyWarning} from "../../services/notification";
import MDBox from "components/MDBox";
import {SecondaryButton} from "components/SecondaryButton";
import moment from "moment";

export const ProfilePage = observer(() => {
  const {loginStore} = useStores()
  const api = loginStore.environment.api
  const tab = {
    GeneralInformation: 0,
    AccountInformation: 1
  }
  const tabSection = {
    None: 0,
    IdolProfile: 1,
    IdolBank: 2,
    IdolTax: 3,
  }
  const [currentTab, setCurrentTab] = useState(tab.GeneralInformation);
  const ProfilePicture = loginStore.image;
  const [Countries, setCountries] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [Cities, setCities] = useState([]);
  const [LanguageList, setLanguageList] = useState([]);
  const [GenderList, setGenderList] = useState([]);
  const [EthnicityList, setEthnicityList] = useState([]);
  const [CurrentUnits, setCurrentUnits] = useState(loginStore.measurement_type); // ***
  const [currentExpandedTabSection, setCurrentExpandedTabSection] = useState(tabSection.None)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const displayIfCurrentTab = (tab) => {
    return {
      display: currentTab === tab ? 'flex' : 'none',
      flexDirection: 'column',
      gap: pxToRem(28),
    }
  }

  const updateProfilePicture = (image) => {
    loginStore.environment.api.editProfilePicture(image).then(res => {
      if (res.kind === 'ok') {
        notifySuccess("Profile picture updated")
      } else {
        notifyError("Something wrong happenned")
      }
    }).finally(() => loginStore.setProfilePictureVersion(Date.now()))
  }

  const submitValues = (values) => {
    const dataForBackend = {...values}
    const fieldList = [
      'username',
      'idol_id_name',
      'tag_1',
      'tag_2',
      'tag_3',
      'biography',
      'age',
      'height',
      'shoe_size',
      'bust',
      'waist',
      'hips',
      'gender',
      'location',
      'ethnicity',
      'language',
      'bank_name',
      'bank_account_number',
      'bank_routing_number',
    ]
    const objectFields = [
      'ethnicity',
      'gender',
    ]
    const arrayFields = [
      'language',
    ]
    const valuesFieldList = Object.keys(dataForBackend)
    valuesFieldList 
      .filter(element => fieldList.includes(element))
      .forEach(field => dataForBackend[field] = values[field])
    valuesFieldList
      .filter(element => objectFields.includes(element))
      .forEach(field => {
        try {
          dataForBackend[field] = values[field].id
        } catch (e) {
          console.log("field", field)
          console.log("self[field]", values[field])
        }
      })
    valuesFieldList
      .filter(element => arrayFields.includes(element))
      .forEach(field => dataForBackend[field] = values[field].map((el) => el.id))
    valuesFieldList
      .filter(element => arrayFields.includes(element))
      .forEach(field => dataForBackend[field] = values[field].map((el) => el.id))
    dataForBackend.id = loginStore.id

    if (dataForBackend.inc_country) dataForBackend.inc_country = dataForBackend?.inc_country?.id
    if (dataForBackend.inc_state) dataForBackend.inc_state = dataForBackend?.inc_state?.id
    if (dataForBackend.inc_city) dataForBackend.inc_city = dataForBackend?.inc_city?.id
    if (dataForBackend.inc_date_of_birth) dataForBackend.inc_date_of_birth = moment(dataForBackend.inc_date_of_birth).format('YYYY-MM-DD')

    if (dataForBackend.tax_country) dataForBackend.tax_country = dataForBackend?.tax_country?.id
    if (dataForBackend.tax_state) dataForBackend.tax_state = dataForBackend?.tax_state?.id
    if (dataForBackend.tax_city) dataForBackend.tax_city = dataForBackend?.tax_city?.id
    
    api.editUserProfile(dataForBackend).then(res => {
      if (res.kind === 'ok') {
        notifySuccess("Your profile data was updated")
        getProfile(loginStore?.id) 
      } else if (res.kind === 'bad-data') {
        if (typeof (res?.errors?.[0]) === 'string') notifyError(res?.errors?.[0])
        else {
          const key = Object.keys(res?.errors)?.[0]
          const msg = `${key}: ${res?.errors?.[key]?.[0]}`
          notifyError(msg)
        }
      }
    })
  }

  const getProfile = (userId) => {
    loginStore.environment.api.getProfileDetail(userId)
      .then((result) => {
        if (result?.kind === 'ok') {
          if (result.data.inc_date_of_birth)  result.data.inc_date_of_birth = moment(result.data.inc_date_of_birth).format("MM/DD/YYYY")
          loginStore.setUser(result.data)
        }
      })
  };

  const specialCasesGeneralInformation = [
    ['gender', {...loginStore.gender}],
    ['ethnicity', {...loginStore.ethnicity}],
    ['language', loginStore.language.map((e) => {
      return {...e}
    })],
    ['height_ft', Math.floor(loginStore.height / 12).toFixed(2)],
    ['height_in', (loginStore.height % 12).toFixed(2)],
  ]

  const formik = {}

  formik.GeneralInformation = useFormik({
    initialValues: getInitialFormValuesFromStore(loginStore, ProfileCards.GeneralInformation, specialCasesGeneralInformation),
    validationSchema: getFormikValidationFields(ProfileCards.GeneralInformation),
    onSubmit: submitValues
  })

  formik.IdolProfile = useFormik({
    initialValues: getInitialFormValuesFromStore(loginStore, ProfileCards.Incorporated),
    validationSchema: getFormikValidationFields(ProfileCards.Incorporated),
    onSubmit: submitValues
  })

  formik.IdolBank = useFormik({
    initialValues: getInitialFormValuesFromStore(loginStore, ProfileCards.Bank),
    validationSchema: getFormikValidationFields(ProfileCards.Bank),
    onSubmit: submitValues
  })

  formik.IdolTax = useFormik({
    initialValues: getInitialFormValuesFromStore(loginStore, ProfileCards.Tax),
    validationSchema: getFormikValidationFields(ProfileCards.Tax),
    onSubmit: submitValues
  })

  const getCountries = (event, search = "") => {
    api
      .getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {
          setCountries(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRegions = (country, search = "") => {
    if (!country) {
      return;
    }
    api
      .getRegions(search, country?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setRegions(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (region, search = "") => {
    if (!region) {
      return;
    }
    api
      .getCities(search, region?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setCities(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getLanguages = () => {
    api
      .getLanguages()
      .then((res) => {
        if (res.kind === "ok") {
          setLanguageList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getGenders = () => {
    api
      .getGenders()
      .then((res) => {
        if (res.kind === "ok") {
          setGenderList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getEthnicity = () => {
    api
      .getEthnicity()
      .then((res) => {
        if (res.kind === "ok") {
          setEthnicityList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const handleClickOnUnits = (units) => {
    setCurrentUnits(units)
    formik.GeneralInformation.setFieldValue('measurement_type', units)
  }

  const handleClickOnMetricUnits = () => {
    if (CurrentUnits !== Units.Metric.id) {
      formik.GeneralInformation.setFieldValue('bust', (formik.GeneralInformation.values.bust * 2.54).toFixed(2))
      formik.GeneralInformation.setFieldValue('waist', (formik.GeneralInformation.values.waist * 2.54).toFixed(2))
      formik.GeneralInformation.setFieldValue('hips', (formik.GeneralInformation.values.hips * 2.54).toFixed(2))
    }
    handleClickOnUnits(Units.Metric.id)
  }

  const handleClickOnImperialUnits = () => {
    if (CurrentUnits !== Units.Imperial.id) {
      formik.GeneralInformation.setFieldValue('bust', (formik.GeneralInformation.values.bust / 2.54).toFixed(2))
      formik.GeneralInformation.setFieldValue('waist', (formik.GeneralInformation.values.waist / 2.54).toFixed(2))
      formik.GeneralInformation.setFieldValue('hips', (formik.GeneralInformation.values.hips / 2.54).toFixed(2))
    }
    handleClickOnUnits(Units.Imperial.id)
  }

  const handleSectionExpandedChange = (tab) => (event, isExpanded) => {
    setCurrentExpandedTabSection(isExpanded ? tab : tabSection.None);
  };

  const SubmitButtonBox = () => (
    <Box sx={getFormButtonsBoxStyles}>
      <ThinButtonTransparent onClick={() => navigate(AuthenticatedPaths.dashboard)} buttonStyle={{width: '30%'}}
                             color="secondary" text={"CANCEL"}/>
      <ThinButtonTransparent type="submit" buttonStyle={{width: '70%'}} text={"SAVE"}/>
    </Box>)

  const handleUpgradeAccount = () => {
    notifyWarning("This feature will be available in the future")
  }

  useEffect(() => {
    getCountries()
    getLanguages()
    getGenders()
    getEthnicity()
  }, [])

  return <BaseProtectedComponentPage loginRequired={false}>
    <DashboardLayout>
      <Box style={styles.mainContainer}>
        <UserHeaderInfo
          profile_photo={`${ProfilePicture}?q=${loginStore.ProfilePictureVersion}`}
          handleUpdateProfilePicture={async (file) => {
            const b64file = await toBase64(file)
            updateProfilePicture({
              fileName: file.name,
              file: b64file,
            })
          }}
          username={loginStore.username}
          is_validated={loginStore.is_validated}
        />
        <Box padding={pxToRem(26)}>
          <ProfileCard>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography sx={{
                  "fontWeight": "600",
                  "fontSize": "18px",
                  "lineHeight": "21px",
                  "color": "#FFFFFF",
                  marginBottom: isMobile ? pxToRem(12) : 0
                }}>Import
                  content from your OnlyFans Account</Typography>
              </Grid>
              <Grid item sm={3}></Grid>
              <Grid item xs={12} sm={3}>
                <ThinButtonTransparent onClick={() => navigate(AuthenticatedPaths.importContent)} text={"CONNECT"}/>
              </Grid>
            </Grid>
          </ProfileCard>
        </Box>

        <Tabs value={currentTab} onChange={handleChange} sx={styles.tabs}>
          <Tab
            value={tab.GeneralInformation}
            label="General Information"
            sx={currentTab === tab.GeneralInformation ? styles.selected_tab : styles.unselected_tab}
          />
          <Tab
            value={tab.AccountInformation}
            label="Account Information"
            sx={currentTab === tab.AccountInformation ? styles.selected_tab : styles.unselected_tab}
          />
        </Tabs>
        <Box sx={() => displayIfCurrentTab(tab.GeneralInformation)}>
          {!loginStore.isEntrepreneur && (<ProfileCard>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}
                     alignItems={isMobile ? "center" : "flex-start"}>
                  <Typography sx={{"fontWeight": "800", "fontSize": "16px", "lineHeight": "26px", "color": "#FFFFFF"}}>
                    {loginStore.isInfluencer && "INFLUENCER"}
                    {loginStore.isProfessional && "PROFESSIONAL"}
                  </Typography>
                  <Typography sx={{"fontWeight": "800", "fontSize": "16px", "lineHeight": "26px", "color": "#FFFFFF"}}>
                    IDOL
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}
                    style={{background: '#243040', borderRadius: pxToRem(10)}}
                    paddingY={pxToRem(16)}
                    paddingX={pxToRem(14)}
              >
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Stack flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                      <img src={UpgradeAccountIcon}/>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={isMobile ? "center" : "flex-start"}
                      marginY={isMobile ? pxToRem(10) : 0}
                    >
                      <Typography
                        sx={{"fontWeight": "400", "fontSize": "16px", "lineHeight": "26px", "color": "#FFFFFF"}}>
                        UPGRADE TO
                      </Typography>
                      <Typography
                        sx={{"fontWeight": "800", "fontSize": "16px", "lineHeight": "26px", "color": "#FFFFFF"}}>
                        {loginStore.isInfluencer && " PROFESSIONAL"}
                        {loginStore.isProfessional && " ENTREPRENEUR"}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Box height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                      <ThinButtonTransparent onClick={handleUpgradeAccount} text={"UPGRADE"}/>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ProfileCard>)
          }
          <form onSubmit={formik.GeneralInformation.handleSubmit}>
            <ProfileCard>
              <CustomFormikTextField name="username" formik={formik.GeneralInformation}/>
              <CustomFormikTextField name="idol_id_name" formik={formik.GeneralInformation} disabled={true} />
              <>
                <MDTypography sx={{fontSize: pxToRem(16), mb: pxToRem(4), color: "#FFFFFF"}}>{"Tags"}</MDTypography>
                <Grid container spacing={2}>
                  <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_1"
                                           formik={formik.GeneralInformation}/>
                  </Grid>
                  <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_2"
                                           formik={formik.GeneralInformation}/>
                  </Grid>
                  <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_3"
                                           formik={formik.GeneralInformation}/>
                  </Grid>
                </Grid>
              </>
              <CustomFormikTextField
                name="biography"
                formik={formik.GeneralInformation}
                style={{minHeight: pxToRem(110)}}
                multiline
                rows={4}
              />
              <CustomAutocompleteFormik name={'gender'} formik={formik.GeneralInformation} options={GenderList}/>
              <CustomFormikTextField name="age" formik={formik.GeneralInformation}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PrimaryButton
                    text={"Metric"}
                    size={"small"}
                    buttonStyle={{height: "30px"}}
                    onClick={handleClickOnMetricUnits}
                    mainColor={CurrentUnits === Units.Metric.id ? colors.primary : colors.tertiary}/>
                </Grid>
                <Grid item xs={6}>
                  <PrimaryButton
                    text={"Imperial"}
                    size={"small"}
                    buttonStyle={{height: "30px"}}
                    onClick={handleClickOnImperialUnits}
                    mainColor={CurrentUnits === Units.Imperial.id ? colors.primary : colors.tertiary}/>
                </Grid>
                {CurrentUnits === null ?
                  <Grid item sm={12}><MDTypography sx={(theme) => {
                    return {
                      color: theme.palette.primary.main,
                      fontSize: pxToRem(14),
                      textAlign: "center",
                    }
                  }}>Please select the measurement
                    unit</MDTypography></Grid>
                  :
                  <>
                    {CurrentUnits === Units.Metric.id &&
                      <Grid item md={6} sm={12}>
                        <CustomFormikTextField
                          name="height"
                          formik={formik.GeneralInformation}
                          customEndAdornmentText={Units.Metric.measurementUnit}
                          onChange={(evt) => {
                            const inches = (evt.target.value / 2.54) % 12
                            const feets = Math.floor((evt.target.value / 2.54) / 12)
                            formik.GeneralInformation.setFieldValue('height_ft', feets.toFixed(2))
                            formik.GeneralInformation.setFieldValue('height_in', inches.toFixed(2))
                          }}
                          inputProps={{maxLength: 6}}
                        />
                      </Grid>
                    }
                    {CurrentUnits === Units.Imperial.id && [
                      <Grid item md={4} sm={12}>
                        <CustomFormikTextField
                          name="height_ft"
                          formik={formik.GeneralInformation}
                          customEndAdornmentText={'ft'}
                          onChange={(evt) => {
                            const centimeters = evt.target.value * 30.48 + formik.GeneralInformation.values.height_in * 2.54
                            formik.GeneralInformation.setFieldValue('height', centimeters.toFixed(2))
                          }}
                          inputProps={{maxLength: 6}}
                        />
                      </Grid>,
                      <Grid item md={4} sm={12}>
                        <CustomFormikTextField
                          name="height_in"
                          formik={formik.GeneralInformation}
                          customEndAdornmentText={Units.Imperial.measurementUnit}
                          onChange={(evt) => {
                            const centimeters = evt.target.value * 2.54 + formik.GeneralInformation.values.height_ft * 30.48
                            formik.GeneralInformation.setFieldValue('height', centimeters.toFixed(2))
                          }}
                        />
                      </Grid>
                    ]}

                    <Grid item md={(CurrentUnits === Units.Metric.id) ? 6 : 4} xs={12}>
                      <CustomFormikTextField
                        inputProps={{maxLength: 6}}
                        name="shoe_size"
                        customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : "US"}
                        formik={formik.GeneralInformation}
                      />
                    </Grid>
                  </>
                }
                {(CurrentUnits !== null) && [
                  <Grid item md={4} sm={12}>
                    <CustomFormikTextField
                      name="bust"
                      formik={formik.GeneralInformation}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                    />
                  </Grid>,
                  <Grid item md={4} sm={12}>
                    <CustomFormikTextField
                      name="waist"
                      formik={formik.GeneralInformation}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                    />
                  </Grid>,
                  <Grid item md={4} sm={12}>
                    <CustomFormikTextField
                      name="hips"
                      formik={formik.GeneralInformation}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                    />
                  </Grid>
                ]}
              </Grid>
              <CustomFormikTextField name="location" formik={formik.GeneralInformation}/>
              <CustomAutocompleteFormik name={'ethnicity'} formik={formik.GeneralInformation} options={EthnicityList}/>
              <CustomAutocompleteWithChipFormik name={'language'} formik={formik.GeneralInformation}
                                                options={LanguageList}/>

            </ProfileCard>
            {SubmitButtonBox()}
          </form>
        </Box>

        <Box sx={() => displayIfCurrentTab(tab.AccountInformation)}>
          <form onSubmit={formik.IdolProfile.handleSubmit}>
            <ProfileCard
              isExpanded={currentExpandedTabSection === tabSection.IdolProfile}
              onChange={handleSectionExpandedChange(tabSection.IdolProfile)}
              title={"IDOL Profile"}
            >
              <CustomFormikTextField name="email" formik={formik.IdolProfile} disabled={true} />  

              {/* <CustomFormikTextField name="password" formik={formik.IdolProfile}/> */}
              <CustomFormikTextField name="old_password" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="new_password" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="confirm_new_password" formik={formik.IdolProfile}/>

              <CustomFormikTextField name="inc_first_name" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="inc_middle_name" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="inc_last_name" formik={formik.IdolProfile}/>
              <CustomFormikDateField name="inc_date_of_birth" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="inc_address_line_1" formik={formik.IdolProfile}/>
              <CustomFormikTextField name="inc_address_line_2" formik={formik.IdolProfile}/>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'inc_country'}
                    formik={formik.IdolProfile}
                    options={Countries}
                    onChange={(e, v) => {
                      formik.IdolProfile.setFieldValue("inc_country", v)
                      getRegions(v)
                      setCities([])
                      formik.IdolProfile.setFieldValue("inc_state", null)
                      formik.IdolProfile.setFieldValue("inc_city", null)
                    }}
                    onClear={() => {
                      setRegions([])
                      setCities([])
                      formik.IdolProfile.setFieldValue("inc_state", null)
                      formik.IdolProfile.setFieldValue("inc_city", null)
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'inc_state'}
                    formik={formik.IdolProfile}
                    options={Regions}
                    onChange={(e, v) => {
                      formik.IdolProfile.setFieldValue("inc_state", v)
                      getCities(v)
                      formik.IdolProfile.setFieldValue("inc_city", null)
                    }}
                    onClear={() => {
                      setCities([])
                      formik.IdolProfile.setFieldValue("inc_city", null)
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'inc_city'}
                    formik={formik.IdolProfile}
                    options={Cities}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomFormikTextField name="inc_zip_code" formik={formik.IdolProfile}/>
                </Grid>
              </Grid>
              <CustomFormikTextField name="inc_phone_number" formik={formik.IdolProfile}/>

            </ProfileCard>
            {currentExpandedTabSection === tabSection.IdolProfile ? SubmitButtonBox() : null}
          </form>
          <form onSubmit={formik.IdolBank.handleSubmit}>
            <ProfileCard
              isExpanded={currentExpandedTabSection === tabSection.IdolBank}
              onChange={handleSectionExpandedChange(tabSection.IdolBank)}
              title={"IDOL Bank"}
            >
              <CustomFormikTextField name="bank_name" formik={formik.IdolBank}/>
              <CustomFormikTextField name="bank_account_number" formik={formik.IdolBank}/>
              <CustomFormikTextField name="bank_routing_number" formik={formik.IdolBank}/>
            </ProfileCard>
            {currentExpandedTabSection === tabSection.IdolBank ? SubmitButtonBox() : null}
          </form>
          <form onSubmit={formik.IdolTax.handleSubmit}>
            <ProfileCard
              isExpanded={currentExpandedTabSection === tabSection.IdolTax}
              onChange={handleSectionExpandedChange(tabSection.IdolTax)}
              title={"IDOL Tax"}
            >
              <CustomFormikTextField name="tax_social_security" formik={formik.IdolTax}/>
              <DownloadDocument name={"tax_w8_w9_file"} formik={formik.IdolTax}/>
              <DownloadDocument name={"tax_article_of_incorporation"} formik={formik.IdolTax}/>
              <CustomFormikTextField name="tax_company_name" formik={formik.IdolTax}/>
              <CustomFormikTextField name="tax_id" formik={formik.IdolTax}/>

              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'tax_country'}
                    formik={formik.IdolTax}
                    options={Countries}
                    onChange={(e, v) => {
                      formik.IdolTax.setFieldValue("tax_country", v)
                      getRegions(v)
                      setCities([])
                      formik.IdolTax.setFieldValue("tax_state", null)
                      formik.IdolTax.setFieldValue("tax_city", null)
                    }}
                    onClear={() => {
                      setRegions([])
                      setCities([])
                      formik.IdolTax.setFieldValue("tax_state", null)
                      formik.IdolTax.setFieldValue("tax_city", null)
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'tax_state'}
                    formik={formik.IdolTax}
                    options={Regions}
                    onChange={(e, v) => {
                      formik.IdolTax.setFieldValue("tax_state", v)
                      getCities(v)
                      formik.IdolTax.setFieldValue("tax_city", null)
                    }}
                    onClear={() => {
                      setCities([])
                      formik.IdolTax.setFieldValue("tax_city", null)
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomAutocompleteFormik
                    name={'tax_city'}
                    formik={formik.IdolTax}
                    options={Cities}
                  />
                </Grid>
                <Grid item md={6} sm={12}>
                  <CustomFormikTextField name="tax_zip_code" formik={formik.IdolTax}/>
                </Grid>
              </Grid>
              <CustomFormikTextField name="tax_address_line_1" formik={formik.IdolTax}/>
              <CustomFormikTextField name="tax_address_line_2" formik={formik.IdolTax}/>
              <CustomFormikTextField name="tax_phone_number" formik={formik.IdolTax}/>
            </ProfileCard>
            {currentExpandedTabSection === tabSection.IdolTax ? SubmitButtonBox() : null}
          </form>
        </Box>
      </Box>
    </DashboardLayout>
  </BaseProtectedComponentPage>
})
