import { Box, Typography } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import { CustomTextField } from "../TextField";
import PhoneNumberField from "../PhoneNumberField";
import InputMask from "react-input-mask";

function CardDetails({ formik }) {
  return (
    <Box sx={styles.cardDetails}>
      <Typography sx={styles.titleDetails}>Card Details</Typography>

      <CustomTextField
        type="text"
        sx={styles.input}
        name="cardHolder"
        value={formik.values.cardHolder}
        onChange={formik.handleChange}
        placeholder="Card Holder Name"
        id={`id_cardHolder`}
        error={formik.touched.cardHolder && Boolean(formik.errors.cardHolder)}
        helperText={formik.touched.cardHolder && formik.errors.cardHolder } />


      <Box sx={styles.doubleInput}>
        <InputMask mask={"99/9999"}
                   name="expiryDate"
                   value={formik.values.expiryDate}
                   onChange={(e)=> formik.setFieldValue('expiryDate', e.target.value)}
        >
          {() => (
            <CustomTextField
              type="text"
              sx={styles.input}
              placeholder="Expiry date"
              id={`id_expiryDate`}
              error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)}
              helperText={formik.touched.expiryDate && formik.errors.expiryDate } />
          )}
        </InputMask>

        <InputMask mask={"999"}
                   name="CVV"
                   value={formik.values.CVV}
                   onChange={(e)=>formik.setFieldValue('CVV', e.target.value)} >
          {() => (
            <CustomTextField
              type="text"
              sx={styles.input}
              placeholder="CVV"
              error={formik.touched.CVV && Boolean(formik.errors.CVV)}
              helperText={formik.touched.CVV && formik.errors.CVV } />
          )}
        </InputMask>

      </Box>     

      <InputMask mask={"9999-9999-9999-999?"}
                 formatChars={{
                   "9": "[0-9]",
                   "?": "[0-9]"
                 }}
                 name="cardNumber"
                 value={formik.values.cardNumber}
                 onChange={(e)=>formik.setFieldValue('cardNumber', e.target.value)} >
        {() => (
        <CustomTextField
            type="text"
            sx={styles.input}
            placeholder="Card Number"
            id={`id_cardNumber`}
            error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
            helperText={formik.touched.cardNumber && formik.errors.cardNumber } />
          )}
      </InputMask>

      <PhoneNumberField 
              country={'us'}
              inputStyle={styles.phoneInput}
              buttonStyle={styles.phoneInputButton}
              dropdownStyle={styles.phoneDropdown}
              placeholder={"Phone Number"}
              value={formik.values.phoneNumber}
              onChange={(phone)=>
                formik.setFieldValue('phoneNumber', phone)
              }
              id={`id_phoneNumber`}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber } />
    </Box>
  );
}

export default CardDetails;
