import pxToRem from "../../assets/theme/functions/pxToRem";


function secondaryButtonStyle(theme, size, buttonStyle = {}, color = 'primary') {
  const {palette} = theme;
  let sizeStyle = {}
  if (size === "small") {
    sizeStyle = {minHeight: "25px !important", height: "25px !important"}
  }
  const currentColor = {
    primary: {
      main: palette.primary.main,
      hover: palette.primary.hover,
    },
    secondary: {
      main: palette.grey['550'],
      hover: palette.grey['500'],
    }
  }
  return {
    borderRadius: 1,
    width: "100%",
    backgroundColor: palette.transparent.main,
    border: `1px solid ${currentColor[color].main}`,
    "&:hover": {
      border: `1px solid ${currentColor[color].hover}`,
    },
    ...buttonStyle,
    ...sizeStyle,
  }
}

function secondaryButtonTextStyle(theme, size, fontWeight = 500, color = "primary") {
  const {palette} = theme;
  const fontSize = size === "small" ? pxToRem(12) : pxToRem(12)
  const currentColor = {
    primary: {
      main: palette.primary.main,
      hover: palette.primary.hover,
    },
    secondary: {
      main: palette.grey['550'],
      hover: palette.grey['500'],
    }
  }
  return {
    borderRadius: 0,
    fontWeight: fontWeight,
    fontSize: fontSize,
    color: currentColor[color].main,
    "&:hover": {
      color: currentColor[color].hover,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "center",

  }
}

export {secondaryButtonStyle, secondaryButtonTextStyle};
