const avatarStyles = (theme, size) => {
  return {width: size, height: size}
}

const labelStyles = (theme) => {
  return {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.white.main,
    paddingY: "1rem",
    textAlign: "center"
  }
}

const boxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    background: "rgba(255, 255, 255, 0.2)",
    border: "2px dashed #FFFFFF",
    borderRadius: "5px"
  }
}

const containerStyles = (theme) => {
  return {
    width: '100%',
    paddingY: '1rem'
  }
}

export {avatarStyles, labelStyles, boxStyles, containerStyles};
