import colors from "assets/theme-dark/base/colors";

export const IdolTaxStyles = {
  text:{
    fontSize:"14px",
    color: colors.white.main
  },
  link:{
    color: colors.primary.main,
    fontSize:"14px",
    textDecoration:"underline",
    fontStyle:"italic"
  }
}
