import { Button, Typography } from "@mui/material";
import React from "react";
import { styles } from './styles';

const FollowersButton = ({ followerIdolsNumber, followerOrIdols, ...props }) => {
  return (
    <Button sx={styles.button} {...props}>
      <Typography sx={styles.buttonNumbers}>{followerIdolsNumber}</Typography>
      <Typography sx={styles.buttonText}>{followerOrIdols}</Typography>
    </Button>
  );
};

export default FollowersButton;
