import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {Box, Divider, Grid, Input} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import colors from "../../../assets/theme/base/colors";
import { useApi } from "../../../utils/hooks";
import MDTypography from "../../../components/MDTypography";
import avatar from "assets/images/avatar.png"
import avatar2 from "assets/images/avatar_example.png"
import avatar3 from "assets/images/monica_blackwell.png"
import { BaseCardIdolInfo } from "../../../base/Cards/InfoCards/IdolizerInfoCard";
import rgba from "../../../assets/theme/functions/rgba";
import {checkMoneyFormat, moneyFormat, showMessage} from "../../../services/helpers";
import { PrimaryButton } from "../../../components/PrimaryButton";
import PriceBaseCard from "../../../base/Cards/PriceBaseCard/PriceBaseCard";
import FeesCard from "../../../base/Cards/FeesCard";
import {isMobile} from "../../VideoCall/utils";
import numeral from "numeral";
import {Form, Formik} from "formik";
import {
  feesCardValues,
  idolFeesInitialValues,
  idolFeesValidationSchema,
  initialValues,
  refundCards,
  validationSchema
} from "./constants";
import {useStores} from "../../../models";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import {notifyError, notifySuccess} from "../../../services/notification";

const columnTitles = [
  { title: "User", width: 3 },
  { title: 'Subscription Fee', width: 2.6},
  { title: 'Tips Fee', width: 2.6 },
  { title: 'Action', width: 2.6 },
]

export const AdminFeesPage = () => {
  const rootStore = useStores();
  const {loginStore} = rootStore
  const api = useApi()
  const [inputFields, setInputFields] = useState(null);
  const [idols, setIdols] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [Page, setPage] = useState(1)
  const [Pages, setPages] = useState(1)

  const getConfigSubscriptionData = () => {
    loginStore.environment.api.getConfigVariables()
      .then(result => {
        if (result.kind === "ok") {
          const {data} = result
          setInputFields(data)
        }
      })
  }
  const updateConfigVariables = (values) => {
    setIsLoading(true)
    loginStore.environment.api.updateConfigVariables(values)
      .then(result => {
        if (result.kind === "ok") {
          getConfigSubscriptionData()
          notifySuccess('Fee prices have been successfully updated')
        } else {
          notifyError("Fee prices couldn't be updated")
        }
      }).catch(err => {
      notifyError("Fee prices couldn't be updated")
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const fetchIdols = (searchData, page, params) => {
    setIsLoading(true)
    setPage(page)
    loginStore.environment.api.getUserFees(searchData, page, params).then((result) => {
      if (result.kind === "ok") {
        const {count, results = []} = result.data
        setIdols([...idols, ...results]);
        setPages(result.data.total_pages)
      }
      setIsLoading(false)
    }).catch((error) => {
      setIsLoading(false)
      notifyError("No idols found")
    }).finally(() => setIsLoading(false))
  }

  const updateUserFee = (id, values) => {
    setIsLoading(true)
    loginStore.environment.api.updateUserFee(id, values)
      .then(result => {
        if (result.kind === "ok") {
          getConfigSubscriptionData()
          notifySuccess('Idol fees have been successfully updated')
        } else {
          notifyError("Idol fees couldn't be updated")
        }
      }).catch(err => {
      notifyError("Idol fees couldn't be updated")
    }).finally(() => {
      setIsLoading(false)
    })
  }
  const handleEditIdolFees = (id, values) => {
    setEditIndex(null)
    updateUserFee(id, values)
  }

  useEffect(() => {
    getConfigSubscriptionData();
    fetchIdols('', Page);
    api.getEarnings().then(value => {
      console.log(value)
    }).catch(reason => console.log(reason))

  },[])
  const renderRow = (row, key)=>{
    return (
      <Grid container my={1} sx={styles.row} key={`idol-id${row.id}`}>
        <Grid item md={3}>
          <BaseCardIdolInfo
            idolizerName={row.username}
            avatar={`${row.image}`}
            isVerified={row.is_validated}
            isIdolizer={false}
            idolPoints={row.idol_points}
          />
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={2.6} sx={styles.textContainer} >
          {editIndex === row.id &&
            <Input
              type={'number'}
              sx={{ ...styles.text, pl: '116px' }}
              value={row.subscription_fee}
              onChange={(event) => {
                let temp = [...idols];
                temp[key].subscription_fee = event.target.value;
                setIdols(temp);
              }}
            />
          }
          {editIndex !== row.id && <MDTypography sx={styles.text}>{row.subscription_fee}</MDTypography>}
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider} />
        </Grid>

        <Grid item md={2.6} sx={styles.textContainer}>
          {editIndex === row.id &&
            <Input
              type={'number'}
              sx={{ ...styles.text, pl: '116px'  }}
              value={row.tip_fee}
              onChange={(event) => {
                let temp = [...idols];
                temp[key].tip_fee = event.target.value;
                setIdols(temp);
              }}
            />}
          {editIndex !== row.id && <MDTypography sx={styles.text}>{row.tip_fee}</MDTypography>}
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider} />
        </Grid>
        <Grid item md={2.6} sx={styles.actionContainer}>
          <PrimaryButton text={editIndex === row.id ? "Save" : "Edit"} onClick={() => editIndex === row.id ? handleEditIdolFees(row.id, row) : setEditIndex(row.id)} textStyles={styles.actionButtonText} buttonStyle={styles.actionButton}/>
        </Grid>
      </Grid>
    )
  }

  const loadMoreButton = () => {
    if (Page !== Pages) {
    return <Grid container my={1}>
      <Grid item md={3} />
      <Grid item md={6} sx={{marginTop: '40px'}} display='flex' justifyContent={'center'}>
        <PrimaryButton
          text="LOAD MORE"
          textStyles={styles.actionButtonText}
          buttonStyle={styles.actionButtonLoad}
          loading={isLoading}
          disabled={isLoading}
          onClick={() => fetchIdols('', Page + 1)}
        />
      </Grid>
      <Grid item md={3} />
    </Grid>
    }
  }


  const renderFeeCards = (formik) => {
    return feesCardValues.slice(0,10).map((fee, index ) => (
      <Grid item xs={12} lg={fee.lg} key={`id-${index}`}>
        <PriceBaseCard title={fee.title} titleStyles={{fontSize: '26px'}}>
          <FeesCard
            name={fee.name}
            value={formik.values[fee.name].value}
            label={fee.label}
            error={formik.errors?.[fee.name]}
            setFieldValue={formik.setFieldValue}
          />
        </PriceBaseCard>
      </Grid>
    ))
  }

  const renderOtherCards = (formik) => refundCards.map((obj, key ) => (
      <Fragment key={`obj_${key}`}>
        <MDTypography variant="h1" sx={[styles.title]} color={"white"} mt={3}>
          {obj.title}
        </MDTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            {obj.group.map((group, key2) => (
              <PriceBaseCard title={group.title} key={`group_${key}_${key2}`}>
                <Grid container spacing={2}>
                  {group.fields.map((field, key3) => (
                    <Grid item xs={12} lg={field.lg} key={`group_${key}_${key2}_${key3}`}>
                      <FeesCard
                        name={field.name}
                        value={formik.values[field.name].value}
                        error={formik.errors?.[field.name]}
                        label={field.label}
                        setFieldValue={formik.setFieldValue}
                      />
                    </Grid>
                  ))}
                </Grid>
              </PriceBaseCard>
            ))}
            </Grid>
        </Grid>
      </Fragment>
    ))

  return (
    <DashboardLayout isAdmin hideFooter>
      <Box  sx={{p:3}}>
        <BasePostCard
          title='FEES'
          titleStyles={{fontSize:"48px"}}
          titleContainerStyles={{height:"73px!important", fontWeight:"400"}}
          headerControlsStyle={{height: "40px", mt:"8px" }}
          icon={null}
        >
          <Formik
            initialValues={initialValues(inputFields)}
            validationSchema={validationSchema}
            onSubmit={values => updateConfigVariables(values)}
            enableReinitialize={true}
          >
            {(formik) => {
              return (
              <Form>
                <Grid container spacing={2}>
                  {renderFeeCards(formik)}
                </Grid>
                {renderOtherCards(formik)}
                <Grid container item lg={12} justifyContent="flex-end" pt={3}>
                  <Grid item xs={12} lg={3}>
                    <PrimaryButton
                      type={'submit'}
                      buttonStyle={
                        isMobile ? styles.buttonStyles : { ...styles.buttonStyles }
                      }
                      textStyles={isMobile ? { fontSize: "10px" } : { fontSize: "12px" }}
                      text="SAVE"
                    />
                  </Grid>
                </Grid>
              </Form>
            )}}

          </Formik>

          <MDTypography variant="h1" sx={[styles.title]} color={"white"} mt={3}>
            USER PAYMENT MANAGEMENT
          </MDTypography>

          <Grid container mt={2} justifyContent={'center'}>
            {columnTitles.map((value)=>(
              <Fragment>
                <Grid item md={value.width}>
                  <MDTypography  textAlign={"center"} fontSize={14} color={"white"}>{value.title}</MDTypography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          {idols && idols.map((item, key)=>renderRow(item, key))}
          {loadMoreButton()}
        </BasePostCard>
      </Box>
    </DashboardLayout>
  )
}

const styles = {
  container:{
    backgroundColor:colors.card.header,
    height:"174px",
    borderRadius:"10px",
    padding:"20px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
    paddingLeft:"30px",
    paddingRight:"30px",
    marginTop:"20px"
  },
  verticalDivider:{
    width:"4px",
    height:"60%",
    background: rgba(colors.background.default, 1)
  },
  divider:{
    height: "98%",
    width:"5px",
    borderRadius:"8px",
    backgroundColor:`${colors.verticalDivider} !important`,
    border:"0px green solid",
  },
  cardContainer: {
    margin: '0 auto',
  },
  title: {
    color: colors.white.main,
    fontWeight: 'lighter',
    textDecoration:"uppercase",
    fontSize:"36px",
    borderBottom: `2px solid ${colors.primary.main}`,
  },
  row:{
    background: colors.card.header,
    borderRadius:"5px"
  },
  text:{
    color:rgba("white", .6),
    fontSize:16,
    fontWeight:"400"
  },
  textContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems: "center"
  },
  actionContainer:{
    background:colors.background.default,
    borderRadius:"5px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  actionButtonLoad: {
    borderRadius: "10px",
    width: "70%",
    height: "32px"
  },
  actionButton:{
    borderRadius:"10px",
    width:"122px",
    height:"32px"
  },
  actionButtonText:{
    fontSize:14,
    fontWeight:400
  }
}
