import { DashboardPage } from "../pages/portal/dashboard";
import { BankWalletPage } from "../pages/bank-wallet";
import { TransactionsPage } from "../pages/transactions";
import { EventsPage } from "../pages/portal/events";
import { CalendarPage } from "../pages/portal/calendar";
import { PricesPage } from "../pages/portal/prices";
import People from "../pages/people";
import { GetVerifiedPage } from "../pages/settings/GetVerified";
import { NavigationMenu } from "../constants";
import { ReferralLinkPage } from "../pages/settings/ReferralLink";
import { BlockedUsersPage } from "../pages/settings/BlockedUsers";
import { TermsNConditionsPage } from "../pages/settings/Terms&Conditions";
import { PrivacyPolicyPage } from "../pages/settings/PrivacyPolicy";
import { DeactivateAccountPage } from "../pages/settings/DeactivateAccount";
import NotificationsPage from "../pages/notifications";
import { ProfilePage } from "../pages/edit-profile";
import { ExplorePage } from "../pages/explore";
import { AddNewPostPage } from "../pages/add-new-post";
import { IdolProfilePage } from "../pages/idolProfile";
import { ReportUserPage } from "../pages/report-user";
import { MeetMePage } from "../pages/portal/meetMe";
import VideoCall from "../pages/VideoCall";
import Logout from "../pages/auth/logout";
import { OnboardingReady } from "../pages/onboarding";
import { IdolPaths } from "../paths/idolPaths";
import {IdolizerWallet} from "../pages/portal/dashboard/idolizer/wallet";
import {IdolizerTransactions} from "../pages/portal/dashboard/idolizer/transactions";
import {Meetings} from "../pages/portal/dashboard/Meetings";

export const idolInfluencerRoutes = {
  home: {
    path: IdolPaths.home,
    element: <DashboardPage/>,
  },
  wallet: {
    path: IdolPaths.wallet,
    element: <IdolizerWallet/>,
  },
  transactions: {
    path: IdolPaths.transactions,
    element: <IdolizerTransactions/>,
  },
  meetings: {
    path:IdolPaths.meetings,
    element: <Meetings/>,
  },
  idolizers: {
    path: IdolPaths.idolizers,
    element: <People/>,
  },
  getVerified: {
    path: IdolPaths.getVerified,
    element: (<GetVerifiedPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  referralLink: {
    path: IdolPaths.referralLink,
    element: (<ReferralLinkPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  blockedUsers: {
    path: IdolPaths.blockedUsers,
    element: (<BlockedUsersPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  termsAndConditions: {
    path: IdolPaths.termsAndConditions,
    element: (<TermsNConditionsPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  privacyPolicy: {
    path: IdolPaths.privacyPolicy,
    element: (<PrivacyPolicyPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  deactivateAccount: {
    path: IdolPaths.deactivateAccount,
    element: (<DeactivateAccountPage currentNavMenu={NavigationMenu.SETTINGS}/>)
  },
  notifications: {
    path: IdolPaths.notifications,
    element: <NotificationsPage/>,
  },
  meetMe: {
    path: IdolPaths.meetMe,
    element: <MeetMePage/>,
  },
  profile: {
    path: IdolPaths.profile,
    element: <ProfilePage/>,
  },
  // root: {
  //   path: IdolPaths.explore,
  //   element: <ExplorePage/>,
  // },
  explore: {
    path: IdolPaths.explore,
    element: <ExplorePage/>,
  },
  addNewPost: {
    path: IdolPaths.addNewPost,
    element: <AddNewPostPage/>,
  },
  viewIdolProfile: {
    path: IdolPaths.viewIdolProfile,
    element: <IdolProfilePage/>,
  },
  reportUser: {
    path: IdolPaths.reportUser,
    element: <ReportUserPage/>,
  },

  logout: {
    path: IdolPaths.logout,
    element: <Logout/>,
  },
  idolReady: {
    path: IdolPaths.idolReady,
    element: <OnboardingReady/>,
  }
}

export const idolRoutes =  {
  ...idolInfluencerRoutes,
  idolBank: {
    path: IdolPaths.idolBank,
    element: <BankWalletPage/>,
  },
  idolPay: {
    path: IdolPaths.idolPay,
    element: <TransactionsPage/>,
  },
  prices: {
    path: IdolPaths.prices,
    element: <PricesPage/>,
  },
  events: {
    path: IdolPaths.events,
    element: <EventsPage/>,
  },
  calendar: {
    path: IdolPaths.calendar,
    element: <CalendarPage/>,
  },
  meetMe: {
    path: IdolPaths.meetMe,
    element: <MeetMePage/>,
  },
  videoCall: {
    path: IdolPaths.videoCall,
    element: <VideoCall/>,
  },
}
