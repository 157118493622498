import React from 'react';
import moment from "moment";

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {RecordCard} from "components/RecordCard/RecordCard";
import {BaseCardIdolInfo} from "base/Cards/InfoCards/IdolizerInfoCard";

/* utils */
import {formatNumberToCurrency} from "utils/functions";

/* assets */
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import {NotificationCategories} from "../../constants";

const {white, red} = colors;

export const Notifications = ({
                                item, setSelected, openModal, category, avatar,
                                idolizerName,
                                idolPoints,
                                isVerified,
                                tipAmount,
                                dateTime
                              }) => {
  const baseInfoProps = {
    nameStyles: styles.base,
    pointStyles: styles.base,
    idpStyles: styles.base,
  };

  const recordProps = {
    containerStyles: styles.record_container,
    cardThirdContentStyles: styles.third_card,
    showOptionsCard: false,
  };

  return (
    <>
      <RecordCard
        cardFirstContent={<BaseCardIdolInfo avatar={avatar} idolizerName={idolizerName} idolPoints={idolPoints}
                                            isVerified={isVerified} {...baseInfoProps} />}
        cardSecondContent={
          <Typography sx={styles.date}>
            {moment(new Date(dateTime)).format("DD MMM YYYY - h:mm A").toUpperCase()}
          </Typography>
        }
        cardThirdContent={
          <>
            <Typography sx={styles.description}>{NotificationCategories[category].message}</Typography>
            <Box sx={styles.price_container}>
              {category === "meet" &&
                <Typography
                  sx={[styles.price, styles.view_meet]}
                  onClick={() => [setSelected(item), openModal()]}
                >
                  View
                </Typography>
              }
              {tipAmount &&
                <Typography sx={styles.price}>
                  {formatNumberToCurrency(tipAmount)}
                </Typography>
              }
              <img src={NotificationCategories[category].icon} style={styles.icon}/>
            </Box>
          </>
        }
        {...recordProps}
      />
    </>
  );
};

const styles = {
  record_container: {
    display: 'grid',
    gridTemplateColumns: '4fr 2.5fr',
  },
  base: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  date: {
    color: colors.date,
    fontSize: pxToRem(14),
    fontWeight: 400,
    textAlign: 'center',
  },
  third_card: {
    width: '100%',
    height: '95%',
    marginBottom: pxToRem(4),
    paddingBlock: pxToRem(25),
    paddingInline: `${pxToRem(25)} ${pxToRem(12)}`
  },
  description: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: '26px',
  },
  price_container: {
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(20),
  },
  price: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: '26px',
  },
  view_meet: {
    color: red.main,
    cursor: 'pointer',
  },
  icon: {
    width: pxToRem(24),
    height: pxToRem(24)
  },
};

