import React, {useEffect} from "react";
import { observer } from "mobx-react";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import { PricesCard } from "./PricesCard";
import prices from "assets/icons/price-red.svg";
import { useIsMobile } from "utils/hooks";
import { Box } from "@mui/material";
import { PricesCardMobile } from "./PricesCardMobile";
import {useStores} from "../../../models";
import {NavigationMenu} from "../../../constants";

export const PricesPage = observer(() => {
  const isMobile = useIsMobile();

  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("PRICES");
  }, []);
  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={prices}>{isMobile ? <PricesCardMobile /> : <PricesCard />}</DashboardLayout>
    </BaseProtectedComponentPage>
  );
});
