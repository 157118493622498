import {Box, Typography} from "@mui/material";
import colors from "assets/theme/base/colors";
import {MobileSectionHeader} from "components/Mobile/MobileIdolDashboardSectionHeader";
import {SecondaryButton} from "components/SecondaryButton";
import React from "react";
import {useApi, useIsMobile} from "utils/hooks";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {notifySuccess} from "../../../services/notification";
import {useNavigate} from "react-router-dom";
import {useStores} from "../../../models";
import {routes} from "../../../routes";
import {UserTypes} from "../../../constants";

export const DeactivateAccount = () => {
  const rootStore = useStores()
  const isMobile = useIsMobile()
  const api = useApi()
  const navigate = useNavigate()

  const deactivateAcc = () => {
    api.deactivateAcc()
      .then(({data}) => {
        rootStore.reset()
        navigate(routes[UserTypes.Guest].getStarted.path)
        notifySuccess("Your account has been deactivated")
      })
      .catch((error) => {
        console.log(error)
      });
  };

  return (
    <Box sx={styles.container}>
      {isMobile && <MobileSectionHeader Title="DEACTIVATE ACCOUNT" ShowLogo={false}/>}
      <Typography sx={styles.headTitle}>DEACTIVATE ACCOUNT</Typography>
      <Typography sx={styles.headSubtitle}>Put a confirmation in this page</Typography>
      <Box sx={styles.content}>
        <Box sx={styles.header}>
          <Typography sx={styles.title}>DEACTIVATE ACCOUNT</Typography>
        </Box>
        <Typography sx={styles.message}>
          This will make your account permanently unusable.
          <br/>
          Are you sure you want to perform this action?
        </Typography>
        <Box sx={styles.actionsContainer}>
          <SecondaryButton
            text="DEACTIVATE"
            onClick={() => deactivateAcc()}
            textStyles={styles.confirmText}
            {...styles.confirm}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "100vh",
    padding: {xs: "0px 14px 0px 14px", lg: "0px"},
  },
  content: {
    width: {xs: "100%", lg: "660px"},
    backgroundColor: colors.modal.confirm_payment.background,
    borderRadius: "10px",
    boxShadow: "3px 4px 21px rgba(0, 0, 0, 0.71)",
    margin: {xs: "30px 0px 0px 0px", lg: "0px"},
  },
  header: {
    display: {xs: "none", lg: "flex"},
    background: colors.modal.confirm_payment.header,
    height: "70px",
    borderRadius: "10px 10px 0px 0px",
    justifyContent: "center",
    alignItems: "center",
  },
  headTitle: {
    display: {xs: "none", lg: "block"},
    fontSize: "22px",
    fontWeight: 700,
    lineHeight: "127%",
    letterSpacing: "0.1em",
    color: "#fff",
    padding: "0px 0px 50px 0px"
  },
  headSubtitle: {
    display: {xs: "none", lg: "block"},
    paddingBottom: "35px",
    fontSize: "16px",
    fontWeight: 300,
    color: "#fff",
    lineHeight: "26px"
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "127%",
    letterSpacing: "0.1em",
    color: "#fff",
  },
  message: {
    color: colors.white.main,
    fontSize: "16px",
    letterSpacing: "0.1em",
    lineHeight: {xs: "26px", lg: "40px"},
    textAlign: "center",
    padding: {xs: "30px 38px 21px 39px", lg: "34px 0px 48px 0px"},
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: pxToRem(21),
    padding: {xs: "0px", lg: "0px 0px 48px 0px"},
  },
  confirm: {
    backgroundColor: {xs: "#D9243D", lg: "#0C192B"},
    alignSelf: "center",
    fontSize: pxToRem(14),
    fontWeight: 700,
    height: pxToRem(40),
    lineHeight: "93.75%",
    letterSpacing: "0.1rem",
    width: {xs: "80%", lg: pxToRem(292)},
  },
  confirmText: {
    fontSize: pxToRem(14),
    letterSpacing: "0.1rem",
    color: {xs: "#fff", lg: "#D9243D"}
  },
  cancelText: {
    color: colors.white.main,
    fontSize: "16px",
    letterSpacing: "0.1em",
    lineHeight: {xs: "26px", lg: "40px"},
    textAlign: "center",
    padding: {xs: "30px 38px 21px 39px", lg: "34px 0px 48px 0px"},
  }
};
