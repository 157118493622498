import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white} = colors;

export const styles = {
    popover: {
      ".MuiPopover-paper": {
        background: 'black',
        overflow: 'auto',
        padding: 0,
        position: 'relative',
        width: pxToRem(171),
      },
    },
    arrow: {
      background: 'black',
      borderBottom: '10px solid black',
      borderLeft: '12px solid black', 
      borderRight: '12px solid black', 
      position: 'absolute', 
      right: '37.5%',
      top: 0,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: pxToRem(16),
      justifyContent: 'flex-start',
      padding: `${pxToRem(21)} ${pxToRem(28)}`,
    },
    text: {
      color: white.main,
      cursor: 'pointer',
      fontSize: pxToRem(14),
      fontWeight: 400,
      lineHeight: pxToRem(16),
    },
  };
