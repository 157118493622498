import React, {useState} from "react";
import {Box, FormHelperText, Link, Typography} from "@mui/material";
import UploadDocument from "components/UploadDocument";
import DocumentUpload from "../../../assets/icons/uploadfile.svg";
import {SecondaryButton} from "components/SecondaryButton";
import checked from "../../../assets/icons/checked.png";
import {useApi, useIsMobile, useOnboardingStore, useUserStore} from "utils/hooks";
import {toBase64} from "../../../utils/functions";
import DocumentIDUpload from "../../../assets/icons/uploadCI.svg";
import MDTypography from "../../../components/MDTypography";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {useFormik} from "formik";
import {
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
  useStores
} from "../../../models";
import {notifyError} from "../../../services/notification";

export const GetVerified = () => {
  const isMobile = useIsMobile();
  const api = useApi()
  const userStore = useUserStore()

  const [id_front, setId_front] = useState()
  const [consent_form, setConsent_form] = useState()

  const initialValues = {
    verify_gov_id: "",
    verify_consent_form: ""
  }

  const submitValues = (values) => {
    setId_front(values.verify_gov_id)
    setConsent_form(values.verify_consent_form)
    uploadFiles({id_front, consent_form})
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitValues
  })


  const getProfileDetail = () => {
    api.getProfileDetail(userStore.id).then(response => {
      if (response.kind === "ok") {
        userStore.setUser(response.data)
      }
    }).catch(reason => {
      notifyError('There is something wrong!')
    })
  }


  const uploadFiles = (data, keys) => {
    api.uploadFilesAdditionalPage(data, [keys])
      .then((res) => {
        getProfileDetail()
        console.log("upload additional docs success", res);
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   title: "Upload failed.",
        //   description: `${err.message}: ${err.response.data.detail}`,
        //   status: "error",
        //   duration: 9000,
        //   isClosable: true,
        // });
      });
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.title}>Get Verified</Typography>
          <Box component="img" src={checked} alt="verified_icon" sx={styles.checked}/>
        </Box>
        <Typography sx={styles.subtitle}>
          We take branding and security seriously here, as our idols are real businesspeople. This platform
          is designed specifically for them to engage with their idolizers in a variety of ways.
          Before we can explore the full experience of idolidol, we require all talent to sign a release form.
          Additionally, we require real proof of identity to ensure that everyone on idolidol is who they say
          they are.
        </Typography>
        <Box>
          {/*TODO: FALTA CONEXION .VC*/}
          <form onSubmit={formik.handleSubmit}>
            <Box width="100%" paddingY="1rem">
              <UploadDocument
                text={"Click here to drop files or upload your Issued GOV ID"}
                onClick={async (file) => formik.setFieldValue('verify_gov_id', file)}
                src={DocumentIDUpload}
                alt=""/>
              {formik.touched.verify_gov_id && Boolean(formik.errors.verify_gov_id) && (
                <FormHelperText>
                  <MDTypography sx={{fontSize: 14}}
                                color={"error"}>{formik.touched.verify_gov_id && formik.errors.verify_gov_id}</MDTypography>
                </FormHelperText>
              )}
              <UploadDocument
                text={"Click here to drop files or upload your Consent Form"}
                onClick={async (file) => formik.setFieldValue('verify_consent_form', file)}
                src={DocumentUpload}
                alt=""
                onlyPDF={true}/>
              {formik.touched.verify_consent_form && Boolean(formik.errors.verify_consent_form) && (
                <FormHelperText>
                  <MDTypography sx={{fontSize: 14}}
                                color={"error"}>{formik.touched.verify_consent_form && formik.errors.verify_consent_form}</MDTypography>
                </FormHelperText>
              )}
            </Box>
            <MDTypography sx={styles.buttonSubtitle}>Click the link to generate your Consent Form</MDTypography>
            <Link target="_blank" href="https://insignia-30234.botics.co/static/documents/2257.pdf" mb={"50px"}>
              <MDTypography sx={{...styles.buttonSubtitle, textDecoration: "underline"}}>Generate Consent Form</MDTypography>
            </Link>
            {isMobile ?
              <Box sx={styles.buttonsContainer}>
                <Typography sx={styles.buttonSubtitle}>
                  Blandit elementum mauris, id metus maecenas.
                </Typography>
                <SecondaryButton
                  onClick={()=>{
                    uploadFiles()
                  }}
                  text="SUBMIT"
                  textStyles={styles.textStyles}
                  buttonStyle={styles.buttonStyles}
                />
              </Box>
              :
              <Box sx={styles.buttonsContainer}>
                <SecondaryButton
                  type={"submit"}
                  text="SUBMIT"
                  textStyles={styles.textStyles}
                  buttonStyle={styles.buttonStyles}
                />
                <Typography sx={styles.buttonSubtitle}>
                  Blandit elementum mauris, id metus maecenas.
                </Typography>
              </Box>
            }
          </form>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  container: {
    width: {xs: "100%", md: "623px", lg: "623px"},
    backgroundColor: "#192436",
    borderRadius: "10px",
    padding: {xs: "16px", lg: "24px"},
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "10px",
    borderBottom: "2px solid #D9243D",
  },
  title: {
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#fff",
  },
  checked: {
    width: "20px",
    height: "20px",
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#fff",
    paddingTop: "24px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: {xs: "center", lg: "flex-end"},
    gap: "8px",
    paddingTop: '12px'
  },
  textStyles: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "93%",
  },
  buttonStyles: {
    width: {xs: "193px", lg: "292px"},
    height: "40px",
    backgroundColor: 'transparent',
  },
  buttonSubtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#D9243D",
  },
};
