import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white} = colors;

export const styles = {
    general_info: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
    },
    meeting_price: {
        color: white.main,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        paddingRight: '4%',
    },
    datetime_info_container: {
        alignItems: "center", 
        display: "flex", 
        gap: 1,
    },
    datetime_info_text: {
        color: white.main,
        fontWeight: 'light',
        fontSize: pxToRem(14),
        lineHeight: 1.3,

    },
}
