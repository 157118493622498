import { BaseIdolCard } from "../../../base/Cards/IdolCard";
import videocallIcon from "../../../assets/icons/video-chat.svg";
import meetIcon from "../../../assets/icons/meet.svg";
import colors from "../../../assets/theme/base/colors";
import { MeetingTypeEnum } from "../../../utils/constants";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import logo from "../../../assets/icons/small_logo.svg";
import React from "react";
import { moneyFormat } from "../../../services/helpers";
import { desktopStyles, mobileStyles } from "./styles/eventTypeCardStyles";

const MeetType = {
  [MeetingTypeEnum.liveCall]: "idolcall",
  [MeetingTypeEnum.meetAndGreet]: "idolmeet"
}
export const EventTypeCard = ({eventType, isMobile, onSelect})=>{
  const styles = isMobile? mobileStyles : desktopStyles

  return (
    <BaseIdolCard title={eventType.name}
                  onClick={onSelect}
                  cardStyles={styles.baseCard}
                  headerStyles={styles.headerCard}
                  icon={eventType.meeting_type === MeetingTypeEnum.liveCall? videocallIcon :meetIcon}>

      <Box style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between", padding:"23px", marginTop: pxToRem(35)}}>
        <Typography style={{fontWeight:400, color: colors.white.main, fontSize:14}}>
          {moment(eventType.duration, "HH:mm:ss").minutes().toString()+" minutes"}
        </Typography>
        <Box style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          {eventType.location && (
            <Typography style={styles.location}>
              {eventType.location}
            </Typography>
          )}
          <Typography style={styles.meetType}>
            {MeetType[eventType.meeting_type]}
          </Typography>
        </Box>
      </Box>
      <Typography style={styles.description}>
        {eventType.description}
      </Typography>


      <Box style={styles.footer}>
        <Box sx={styles.logoContainer}>
          <img src={logo} alt="logo" title="logo" style={styles.logo_img} />
        </Box>
        <Typography style={styles.fee} color={"white"}>{moneyFormat(eventType.fee)}</Typography>

      </Box>
    </BaseIdolCard>
  )
}

