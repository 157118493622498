import colors from 'assets/theme/base/colors';
import pxToRem from "../../../assets/theme/functions/pxToRem";
const {card, white, red} = colors;

export const styles = {
    card: {
      background: card.background,
      padding:"10px 40px 40px 40px"
    },
    container: {
      alignItems: "center",
      borderBottom: `2px solid ${red.main}`,
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
    },
    image_container: {
      width: '2.5rem',
    },
    img: {
      alignSelf: 'center',
      display: 'flex',
      width: '3rem',
    },
    title_container: {
      alignItems: 'center',
      display: 'flex',
      gap: 4,
      justifyContent: 'space-between',
      height:"90px"
    },
    title: {
      color: white.main,
      fontWeight: 'lighter',
      fontSize: pxToRem(50),
    },
    controls_container: {
      alignItems: 'center',
      display: 'flex',
      gap: '25px',
      justifyContent: 'space-between',
      maxHeight: '80px',
    },
    fixed_controls: {
      bottom: 3,
      marginRight: '3rem',
      position: 'absolute',
      right: 0,
    },
};
