import * as Yup from "yup";

export const PriceItemSchema = Yup.object().shape({
  items:Yup.array()
    .of(
      Yup.object().shape({
        duration: Yup.number().required("this field is required")
          .test("not null", "You must select an option", (data)=>  data !== 0),
        discount_percentage: Yup.number().required("this field is required")
          .test("not null", "You must select an option", (data)=>  data !== 0)
      })
    )
})
