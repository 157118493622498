import React from 'react';
import {Theme, Typography, useMediaQuery} from '@material-ui/core';
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import {LocalVideoTrack} from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import {Box, Stack} from "@mui/material";
import pxToRem from "../../../../../assets/theme/functions/pxToRem";


const AvatarDesktop = (props: any) => {
  const {image} = props
  return (<>
    <Stack
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)'
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(50% - 5vw)'}
      left={'calc(50% - 5vw)'}
      height={'10vw'}
      width={'10vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </>)
}

const AvatarMobile = (props: any) => {
  const {image} = props
  return (<>
    <Stack
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)'
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(50% - 15vw)'}
      left={'calc(50% - 15vw)'}
      height={'30vw'}
      width={'30vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </>)
}

export default function LocalVideoPreview({user}: any) {
  const {username, image} = user
  const {localTracks} = useVideoContext();
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div style={{
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      paddingTop: `${(9 / 16) * 100}%`,
      background: 'black',
    }}>
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal/>
        ) : (
          isMobile ? <AvatarMobile image={image}/> : <AvatarDesktop image={image}/>
        )}
      </div>

      <div style={{
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
      }}>
        <span style={{
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '0.18em 0.3em',
          margin: 0,
          display: 'flex',
          alignItems: 'center',
        }}>
          <LocalAudioLevelIndicator/>
          <Typography variant="body1" color="inherit" component="span">
            {username}
          </Typography>
        </span>
      </div>
    </div>
  );
}
