import React, {useState} from 'react';
import {styled, Theme} from '@material-ui/core/styles';
import PreJoinScreens from './PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './RecordingNotifications/RecordingNotifications';
import Room from './Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import {makeStyles} from "@material-ui/core";
import {notifyError} from "../../services/notification";
import {AuthRoutes} from "../../routes";
import {useNavigate} from "react-router-dom";
import {useStores} from "../../models";
import { AuthenticatedPaths } from "../../paths/paths";

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({theme}: { theme: Theme }) => ({
  overflow: 'hidden',
  // paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: '#0C192B',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'transparent',
    display: 'flex',
  },
  title1: {
    color: 'white',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: '26px'
  },
}));

export default function App() {
  const roomState = useRoomState();
  const navigate = useNavigate()
  const height = useHeight();
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [roomName] = useState<string>(urlParams.get('room') || '');
  const referrer = encodeURIComponent(`${AuthenticatedPaths.videoCall}/?room=${roomName}`)

  if (!loginStore.isLoggedIn) {
    rootStore.reset()
    notifyError("Your need to log in first")
    // @ts-ignore
    navigate(`${AuthRoutes.login}?referrer=${referrer}`)
  }

  return (
    <Container style={{height}}>
      {roomState === 'disconnected' ? (
        <PreJoinScreens/>
      ) : (
        <Main>
          <ReconnectingNotification/>
          <RecordingNotifications/>
          <Room/>
        </Main>
      )}
    </Container>
  );
};
