import { Box, FormControl, FormHelperText, Input, Typography } from "@mui/material";
import { PrimaryButton } from "components/PrimaryButton";
import numeral from "numeral";
import React, { useState } from "react";
import dollar from "../../../assets/icons/dollar-currency.svg";
import IdolMarca from "../../../assets/icons/idolmarca.svg";
import { styles } from "../../../base/Cards/PriceCard/styles";
import MDTypography from "../../../components/MDTypography";
import colors from "../../../assets/theme/base/colors";
import { checkMoneyFormat } from "../../../services/helpers";

const  PriceCard = ({ isMobile, title, subtitle, tipAmount, minimum=0, save }) => {
  const [number, setNumber] = useState(numeral(tipAmount).format("0.00"));
  const [error, setError] = useState("");

  const handleChange = (num) => {
    const isValid = num !== "" ? checkMoneyFormat(num) : true;
    return !!isValid ? setNumber(num) : null;
  };

  const validate = ()=>{
    if(parseFloat(number) < parseFloat(minimum)){
      setError(`The minimum is $${numeral(minimum).format("0.00")}`)
    }else{
      save(number)
    }

  }
  return (
    <Box sx={styles.container}>
      <Typography variant={isMobile ? "h6" : "h5"} color="#fff">
        {title}
      </Typography>
      <FormControl sx={styles.inputContainer}>
        <Box component="img" src={dollar} alt="dollar" sx={styles.dollar} />
        <Input
          sx={isMobile ? { ...styles.price, ...styles.mobilePrice } : styles.price}
          type="number"
          placeholder="0.00"
          value={number}
          onChange={(e) => handleChange(e.target.value)}
        />

      </FormControl>
      {error && (
        <MDTypography style={{color: colors.error.main, fontSize:"12px"}}>{error}</MDTypography>
      )}

      <Box sx={isMobile ? styles.tipsContainer : { ...styles.tipsContainer, marginTop: "13px" }}>
        {subtitle? (
          <Typography sx={styles.tipsText}>{subtitle}</Typography>
        ) : (
          <Typography sx={styles.tipsText}>Minimum tip is ${tipAmount}</Typography>
        )}
        <Box sx={styles.saveButtonContainer}>
          <PrimaryButton
            buttonStyle={
              isMobile ? styles.buttonStyles : { ...styles.buttonStyles, width: "132px" }
            }
            onClick={()=>validate()}
            textStyles={isMobile ? { fontSize: "10px" } : { fontSize: "12px" }}
            text="SAVE"
          />
          <Box component="img" src={IdolMarca} alt="idol-marca" />
        </Box>
      </Box>
    </Box>
  );
}

export default PriceCard;
