import React from 'react'
import OrderingButton from "components/WithdrawButton/OrderingButton";

const OrderByType = ({selectedFilterOrder, setSelected}) => {
  return (
    <OrderingButton 
      text='TYPE' 
      currentFilterSelected={selectedFilterOrder === 'TYPE'} 
      onClick={() => setSelected('TYPE')}
    />
  )
}

const OrderByDate = ({selectedFilterOrder, setSelected}) => {
    return (
        <OrderingButton 
          text='DATE' 
          currentFilterSelected={selectedFilterOrder === 'DATE'} 
          onClick={() => setSelected('DATE')}
        />
    )
}

const OrderByPoints = ({selectedFilterOrder, setSelected}) => {
    return (
        <OrderingButton 
          text='POINTS' 
          currentFilterSelected={selectedFilterOrder === 'POINTS'} 
          onClick={() => setSelected('POINTS')}
        />
    )
}

export const HeaderControls = (props) => {

    const {setSelectedFilterOrder, selectedFilterOrder} = props;

    const setSelected = (value) => {

        if(selectedFilterOrder === value) return 
        setSelectedFilterOrder(value)
    }

    const headersProps = {
        ...props, 
        setSelected,
    };

    return (
    [
        <OrderByPoints {...headersProps} />,
        <OrderByDate {...headersProps} />,
        <OrderByType {...headersProps} />,
    ])
}