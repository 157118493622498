import colors from "assets/theme/base/colors";
const {card, white} = colors;

export const styles = {
    card:{
      background: card.background,
      height: 500,
    },
    cardHeader:{
      padding: 2,
      textAlign: 'center',
    },
    img_container: {
      margin: '5rem auto 0 auto',
    },
    content: {
      color: white.main, 
      marginInline: 6,
    },
    content_text: {
      color: white.main,
      fontSize: '1rem',
      textAlign: 'center',
    },
    footer_text: {
      color: card.footerText,
      fontSize: '0.8rem',
      textAlign: 'center',
    },
  }