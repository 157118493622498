import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
const {background, red, white, card} = colors;

export const styles = {
    container: {
        width: pxToRem(378),
        top: 0,
        height: pxToRem(155),
        position: 'absolute',
    },
    card: {
        background: background.default,
        paddingBlock: '7px',
    },
    header: {
        borderBottom: `2px solid ${red.main}`,
        background: background.default,
        padding: 1,
    },
    header_text: {
        fontSize: pxToRem(16),
    },
    content_container: {
        alignItems: 'center',
        display: 'flex',
        gap: 2,
        justifyContent: 'space-between',
        paddingLeft: '3%',
        paddingTop: '10%',
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        gap: 2,
    },
    dollar_img: {
        width: pxToRem(30),
        height: pxToRem(30),
    },
    time_info_container: {
        display: 'flex',
        flexDirection: 'column',
    },
    time_info: {
        color: white.main,
        fontSize: pxToRem(12),
        fontWeight: 300,
    },
    price_container: {
        paddingRight: '3%',
        paddingTop:"10px"
    },
    price: {
        color: white.main,
        fontSize: pxToRem(22),
        fontWeight: 400,
    },
    points: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: red.main,
        fontSize: pxToRem(12),
    },
    actions: {
        paddingBottom: '0px',
    },
    button: {
        width: pxToRem(132),
        padding: 0,
    },
    button_text: {
        fontSize: pxToRem(12),
    },
    upcomingButton: {
        // background: card.upcomingButton,
        borderColor: card.upcomingButton,
        padding: 0,
        width: pxToRem(132),
    },
};
