import {Api} from "../services/api"
import {Client} from "@twilio/conversations";


/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
    constructor() {
        this.api = new Api()
    }

    setupTwilioClient(token: string, callback?: any) {
        this.adminUserTwilioChat = "AdminUserChat"
        this.twilioClientReady = false
        this.twilioClient = new Client(token)

        this.twilioClient.on('stateChanged', (state) => {
            if (state === 'initialized') {
                this.twilioClientReady = true
                if (callback) {
                    callback()
                }
            }
            if (state !== 'initialized') {
                this.twilioClientReady = false
            }
        })
    }

    shutdownTwilioClient() {
        this.twilioClientReady = false
        this.twilioClient.shutdown()
    }

    setRootStore(rootStore: any) {
        this.api.setRootStore(rootStore)
    }

    async setup() {
        this.twilioClientReady = false
        await this.api.setup()
        // allow each service to setup

    }


    /**
     * Our api.
     */
    api: Api
    twilioClient: Client
    twilioClientReady: boolean
    adminUserTwilioChat: string
}
