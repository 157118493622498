import { Box } from "@mui/material";
import React from "react";
import { CardForm } from "../components/CardForm";
import MDTypography from "../../../../components/MDTypography";
import { SaveButton } from "../components/SaveButton";
import { CustomTextField } from "../../../../components/TextField";
import { globalStyles } from "../globalStyles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { requiredMessage } from "../constants";
import { TextField } from "../components/TextField";


const validationSchema = Yup.object({
  POINTS_PER_USD: Yup.string().required(requiredMessage),
});


export const WalletForm = ({ config, onSave })=>{

  return (
    <CardForm  title={"Idol Wallet"}>
      <Formik
        initialValues={{ POINTS_PER_USD: config.POINTS_PER_USD??0 }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Box component={Form}  sx={globalStyles.container}>
            <Box  sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
              <CustomTextField
                sx={globalStyles.input}
                name="name"
                readonly={true}
                value={"$1.00"}
              />
              <MDTypography color={"white"} sx={{fontSize:"32px", fontWeight:"400", mx:3}}>=</MDTypography>

              <TextField name={"POINTS_PER_USD"}
                         sx={globalStyles.input}
                         max={1000000}
                         type={"number"}
                         customEndAdornmentText={"idp"} />
            </Box>
            <SaveButton />
          </Box>
        )}
      </Formik>


    </CardForm>
  );
}

