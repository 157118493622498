import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
export const styles = {
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "9px",
    marginTop: "14px",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#162334",
    heigth: pxToRem(58),
    width: "100%",
    textTransform: "lowercase",
    color: colors.buttons.filterButton.main,
    "&:hover": {
      color: colors.buttons.filterButton.selected,
    },
    "&:focus": {
      backgroundColor: "#162334",
    },
    padding: "10px 0px 10px 0px",
  },
  buttonNumbers: {
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "93.75%",
    paddingBottom: "8px",
    alignText: "center",
  },
  buttonText: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "93.75%",
    letterSpacing: "0.1rem",
  },
  filterButtonsContainer: {
    display: "flex",
    gap: "13px",
    marginTop: "14px",
  },
  thirdCard: {
    backgroundColor: "#243141",
    boxShadow: "none",
    height: "77px",
    left: 0,
  },
  container: {
    backgroundColor: "#152233",
    height: "73px",
    padding: "16px",
    borderRadius: "5px",
    marginTop: "14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  idolCard: {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: '18px',
    height: '400px',
    marginTop: "1rem",
    padding: pxToRem(12),
    overflow: 'auto'
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "9px",
  },
  divider: {
    backgroundColor: colors.background.default,
    color: colors.background.default,
    width: "5px",
    height: "46px",
    borderRadius: "8px",
    opacity: 0.2,
  },
  threeDots: {
    color: "#fff",
    height: "32px",
    width: "32px",
  },
};
