import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from "react";
import IdolMarca from "../../assets/icons/idolmarca.svg";
import { styles } from './styles';
import addNewBundle from './utils/addNewBundle';
import { defaultBundle } from './utils/defaultBundle';
import RenderBundles from './utils/RenderBundles';
import RenderBundlesWithButtons from './utils/RenderBundlesWithButtons';


function AddBundle({ isMobile, subtitle, buttonName, bundleDurationType, removeBundle, currentBundles=[], formik}) {
  const [bundles, setBundles] = useState(currentBundles);

  useEffect(() => {
    setBundles(currentBundles)
  }, [currentBundles])
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography sx={styles.subtitle}>
        {subtitle}
      </Typography>
      <Grid container rowSpacing={1} spacing={12} >
        <Grid item xs={12} md={5.8}>
          {bundles.map(bundle => RenderBundles(isMobile, bundles, bundle.preId, setBundles, removeBundle, bundleDurationType, formik))}
        </Grid>
        <Grid item xs={12} md={5.8}>
          {bundles.map(bundle => (
            <RenderBundlesWithButtons
              isMobile={isMobile}
              formik={formik}
              removeBundle={()=>{
                removeBundle(bundles, bundle.preId, setBundles)
              }}
            />
          ))}
        </Grid>
      </Grid>
      <Box sx={styles.addButtonContainer}>
        <Typography sx={styles.addButton} onClick={() => addNewBundle(bundles, setBundles, defaultBundle)} >+ ADD ANOTHER BUNDLE</Typography>
        <Box component="img" src={IdolMarca} alt="idol-marca" />
      </Box>
    </Box>
  )
}

export default AddBundle;
