export const LiveCalOptions = [
  {
    label:"15 Minutes",
    value: 15
  },
  {
    label:"30 Minutes",
    value: 30
  },
  {
    label:"60 Minutes",
    value: 60
  }
]

export const MeetAndGreetOptions = [
  {
    label:"1 Hour",
    value: 1
  },
  {
    label:"1.5 Hours",
    value: 90
  },
  {
    label:"2 Hours",
    value: 120
  },
  {
    label:"3 Hours",
    value: 180
  },
  {
    label:"4 Hours",
    value: 240
  },
  {
    label:"5 Hours",
    value: 300
  },
  {
    label:"6 Hours",
    value: 360
  },
  {
    label:"12 Hours",
    value: 720
  },
  {
    label:"24 Hours",
    value: 1440
  },
  {
    label:"2 days",
    value: 2880
  },
  {
    label:"3 days",
    value: 4320
  },
  {
    label:"7 days",
    value: 10080
  },
]
