import { Box } from "@mui/material";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { MoreVert as ThreeDotsIcon } from "@mui/icons-material";
import React, { useState } from "react";
import colors from "assets/theme/base/colors";
import { IdolsActions } from "pages/portal/dashboard/idolizer/idols/actions";
import iconRed from "../../../assets/images/idol_red.png";
import iconGrey from "../../../assets/images/idol_grey.png";
import CancelSubscriptionModal from "../MobileModals/CancelSubscriptionModal/CancelSubscriptionModal";

const IdolizerCardContainerSummary = ({
  isMobile,
  avatar,
  isVerified,
  idolPoints,
  idolizerName,
  type,
  secondContentStyles,
  containerStyles,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const popoverProps = {
    anchorEl,
    onClose: () => setAnchorEl(null),
    open: Boolean(anchorEl),
    onReport: () => console.log("reported"),
    onBlock: () => console.log("blocked"),
    onUnfollow: () => handleOpen(),
    onUnSubscribe: () => handleOpen(),
  };

  const handleToggleShowOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const idolImgType = {
    following: iconGrey,
    idolizing: iconRed,
  };

  const baseProps = {
    isMobile, 
    avatar,
    isVerified,
    idolPoints,
    idolizerName,
    infoCardStyles: styles.baseInfo,
  }

  return (
    <Box sx={[styles.container, containerStyles]}>
      {open && (
        <CancelSubscriptionModal
          open={open}
          handleClose={handleClose}
          type={type}
          isMobile={isMobile}
          avatar={avatar}
          isVerified={isVerified}
          idolPoints={idolPoints}
          idolizerName={idolizerName}
        />
      )}
      <BaseCardIdolInfo {...baseProps} />
      <Box sx={[styles.dotsContainer, secondContentStyles]}>
        <Box component="img" src={idolImgType[type]} sx={{ width: "30px", height: "30px" }} />
        <ThreeDotsIcon sx={styles.threeDots} onClick={(e) => handleToggleShowOptions(e)} />
        <IdolsActions {...popoverProps} type={type} />
      </Box>
    </Box>
  );
};

export default IdolizerCardContainerSummary;

const styles = {
  container: {
    backgroundColor: colors.dark.secondary,
    height: "73px",
    padding: "16px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dotsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "9px",
  },
  threeDots: {
    color: "#fff",
    cursor: 'pointer',
    height: "32px",
    width: "32px",
  },
  baseInfo: {
    padding: 0,
  }
};
