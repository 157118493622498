import pxToRem from "assets/theme/functions/pxToRem"

export const styles = {
    points: {
        fontSize: pxToRem(20),
    },
    container: {
        height: pxToRem(148),
    },
    content_container: {
        position: 'relative',
        '&:first-of-type': {
            alignItems: 'center'
        },
    }
};
