import { PrimaryButton } from "components/PrimaryButton";
import UploadDocument from "../../../components/UploadDocument";
import DocumentIDUpload from "../../../assets/icons/uploadCI.svg";
import DocumentUpload from "../../../assets/icons/uploadfile.svg";
import { useOnboardingStore } from "../../../utils/hooks";
import { Box, FormHelperText, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDTypography from "../../../components/MDTypography";
import { IdolTaxStyles as styles } from "../idolTax/styles";
import * as React from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import {
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
} from "../../../models";
import { toBase64 } from "../../../utils/functions";
import { OnboardingLayout } from "../../../components/OnboardingLayout";
import { GuestPaths } from "../../../paths/guestPaths";

const subtitle = <p>We take branding and security seriously here, as our idols are real businesspeople. This platform
  is designed specifically for them to engage with their idolizers in a variety of ways.<br/>
  Before we can explore the full experience of idolidol, we require all talent to sign a release form.
  Additionally, we require real proof of identity to ensure that everyone on idolidol is who they say
  they are.</p>

export const UploadDocuments = () => {
  const navigate = useNavigate()
  const onboardingStore = useOnboardingStore()
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolDocuments)

  const submitValues = (values) => {
    onboardingStore.setData({...values})
    navigate(NextPage)
  }

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(OnboardingPhases.Verify, onboardingStore),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Verify, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage);
  }, [])

  return (
    <OnboardingLayout
      titleBold='IDOL'
      titleNormal='VERIFY'
      subtitle={subtitle}
      backTo={PreviousPage}
      progressBarPercent={"100%"}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box width="100%" paddingY="1rem">
          <UploadDocument
            text={"Click here to drop files or upload your Issued GOV ID"}
            onClick={async (file) => {
              const b64file = await toBase64(file)
              formik.setFieldValue('verify_gov_id', {fileName: file.name, file: b64file})
            }}
            src={DocumentIDUpload}
            alt=""/>
          {formik.touched.verify_gov_id && Boolean(formik.errors.verify_gov_id) && (
            <FormHelperText>
              <MDTypography sx={{fontSize: 14}}
                            color={"error"}>{formik.touched.verify_gov_id && formik.errors.verify_gov_id}</MDTypography>
            </FormHelperText>
          )}
          <UploadDocument
            text={"Click here to drop files or upload your Consent Form"}
            onClick={async (file) => {
              const b64file = await toBase64(file)
              formik.setFieldValue('verify_consent_form', {fileName: file.name, file: b64file})
            }}
            src={DocumentUpload}
            alt=""
            onlyPDF={true}/>
          {formik.touched.verify_consent_form && Boolean(formik.errors.verify_consent_form) && (
            <FormHelperText>
              <MDTypography sx={{fontSize: 14}}
                            color={"error"}>{formik.touched.verify_consent_form && formik.errors.verify_consent_form}</MDTypography>
            </FormHelperText>
          )}
        </Box>
        <MDTypography sx={styles.text}>Click here to generate your Consent Form</MDTypography>
        <Link target="_blank" href="https://insignia-30234.botics.co/static/documents/2257.pdf" mb={"50px"}>
          <MDTypography sx={styles.link}>Generate Consent Form</MDTypography>
        </Link>
        <Box mb={4} mt={4}>
          <PrimaryButton
            text={"NEXT"}
            type={"submit"}
          />
        </Box>
      </form>
    </OnboardingLayout>
  )
}

