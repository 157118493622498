const getIdolProfileInitialValues = (profile) => ({
  id: profile ? profile?.id : null,
  username: profile ? profile?.username : "",
  idol_id_name: profile ? profile?.idol_id_name : "",
  tag_1: profile ? profile?.tag_1 : "",
  tag_2: profile ? profile?.tag_2 : "",
  tag_3: profile ? profile?.tag_3 : "",
  biography: profile ? profile?.biography : "",
  age: profile ? profile?.age : 0,
  height: profile ? profile?.height : 0,
  shoe_size: profile ? profile?.shoe_size : 0,
  bust: profile ? profile?.bust : "",
  waist: profile ? profile?.waist : "",
  hips: profile ? profile?.hips : 0,
  gender: profile ? profile?.gender : "",
  location: profile ? profile?.location : "",
  ethnicity: profile ? profile?.ethnicity : "",
  language: profile ? profile?.language : "",
})

const getIdolIncInitialValues = (profile) => ({
  id: profile ? profile?.id : null,
  email: profile ? profile?.email : "",
  password: "",
  repeat_password: "",
  inc_first_name: profile ? profile?.inc_first_name : "",
  inc_middle_name: profile ? profile?.inc_middle_name : "",
  inc_last_name: profile ? profile?.inc_last_name : "",
  inc_date_of_birth: profile ? profile?.inc_date_of_birth : "",
  inc_address_line_1: profile ? profile?.inc_address_line_1 : "",
  inc_address_line_2: profile ? profile?.inc_address_line_2 : "",
  inc_country: profile ? profile?.inc_country : "",
  inc_state: profile ? profile?.inc_state : "",
  inc_city: profile ? profile?.inc_city : "",
  inc_zip_code: profile ? profile?.inc_zip_code : "",
  inc_phone_number: profile ? profile?.inc_phone_number : "",
});

const getIdolBankInitialValues = (profile) => ({
  id: profile ? profile?.id : null,
  bank_name: profile ? profile?.bank_name : "",
  bank_account_number: profile ? profile?.bank_account_number : 0,
  bank_routing_number: profile ? profile?.bank_routing_number : 0,
})

const getIdolTaxInitialValues = (profile) => ({
  id: profile ? profile?.id : null,
  tax_social_security: profile ? profile?.tax_social_security : "",
  tax_w8_w9_file: profile ? profile?.tax_w8_w9_file : "",
  tax_article_of_incorporation: profile ? profile?.tax_article_of_incorporation : "",
  tax_company_name: profile ? profile?.tax_company_name : "",
  tax_id: profile ? profile?.tax_id : 0,
  tax_zip_code: profile ? profile?.tax_zip_code : "",
  tax_address_line_1: profile ? profile?.tax_address_line_1 : "",
  tax_address_line_2: profile ? profile?.tax_address_line_2 : "",
  tax_country: profile ? profile?.tax_country : "",
  tax_state: profile ? profile?.tax_state : "",
  tax_city: profile ? profile?.tax_city : "",
  tax_phone_number: profile ? profile?.tax_phone_number : 0,
})

const usersModifierHandler = (users, id, field, value) => {
  const deletedUserIndex = users.findIndex(u => u.id === id);
  const usersModified = [...users];
  usersModified[deletedUserIndex][field] = value;
  return usersModified;
}

export {
  getIdolProfileInitialValues,
  getIdolIncInitialValues,
  getIdolBankInitialValues,
  getIdolTaxInitialValues,
  usersModifierHandler,
}