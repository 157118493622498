import { AuthenticatedPaths, UnauthenticatedPaths } from "./paths";

export const AdminPaths = {
  login: UnauthenticatedPaths.login,
  logout: AuthenticatedPaths.logout,
  home: AuthenticatedPaths.allUsers,
  liveChat: AuthenticatedPaths.liveChat,
  idolPoints: AuthenticatedPaths.idolPoints,
  meetingTypes: AuthenticatedPaths.meetingTypes,
  earnings: AuthenticatedPaths.earnings,
  fees: AuthenticatedPaths.fees,
  reservedNames: AuthenticatedPaths.reservedNames,
  importedContent: AuthenticatedPaths.importedContent,
  reportedUsers: AuthenticatedPaths.reportedUsers,
  transactions: AuthenticatedPaths.transactions,
  approveContent: AuthenticatedPaths.approveContent,
  feedback: AuthenticatedPaths.feedback,
  verification: AuthenticatedPaths.verification,
  manageVerification: AuthenticatedPaths.manageVerification,
}
