import { AuthenticatedPaths, UnauthenticatedPaths } from "./paths";

export const GuestPaths = {
  login: UnauthenticatedPaths.login,
  signup: UnauthenticatedPaths.signUp,
  getStarted: UnauthenticatedPaths.getStarted,
  forgotPassword: UnauthenticatedPaths.forgotPassword,
  tokenPassword: UnauthenticatedPaths.tokenPassword,
  updatePassword: UnauthenticatedPaths.updatePassword,
  whoAreYou: UnauthenticatedPaths.whoAreYou,
  selectIdolType: UnauthenticatedPaths.selectIdolType,
  idolProfile: UnauthenticatedPaths.idolProfile,
  idolIncorporated: UnauthenticatedPaths.idolIncorporated,
  idolBank: UnauthenticatedPaths.idolBank,
  idolTax: UnauthenticatedPaths.idolTax,
  idolDocuments: UnauthenticatedPaths.idolDocuments,
  idolTwitter: UnauthenticatedPaths.idolTwitter,
  idolReady: AuthenticatedPaths.idolReady,
  explore: UnauthenticatedPaths.explore,
  root: UnauthenticatedPaths.root,
  viewIdolProfile: UnauthenticatedPaths.viewIdolProfile,
  logout: AuthenticatedPaths.logout,
  termsAndConditions: UnauthenticatedPaths.termsAndConditions,
  privacyPolicy: UnauthenticatedPaths.privacyPolicy,
}
