import {useStores} from "../models";
import {useMediaQuery} from "@mui/material";
import theme from "../assets/theme";
import {useInfiniteQuery} from "@tanstack/react-query";
import React, {useEffect, useMemo, useState} from "react";


export const useApi = () => {
  const rootStore = useStores()
  return rootStore.environment.api
}

export const useOnboardingStore = () => {
  const rootStore = useStores()
  return rootStore.onboarding
}

export const useUserStore = () => {
  const rootStore = useStores()
  return rootStore.loginStore
}

export const useIsMobile = () => {
  return useMediaQuery(theme.breakpoints.down('sm'));
}


export const useInfiniteScroll = ({apiCall, queryKey, filters}) => {
  const queryKeyList = [queryKey]
  if (filters) {
    queryKeyList.push(filters)
  }
  const {data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, refetch} = useInfiniteQuery(
    queryKeyList,
    ({pageParam = 1}) => {
      return apiCall({page: pageParam, filters:filters})
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data?.page === lastPage.data?.total_pages) {
          return false
        }
        return lastPage.data.page + 1
      }
    }
  )

  const items = useMemo(() => data?.pages.reduce(
    ((previousValue, page) => {
      return previousValue.concat(page.data?.results ?? [])
    }), []
  ) ?? [], [data])

  const extraData = useMemo(() => {
    let extra = {}
    if (data?.pages?.length > 0) {
      extra = {...data.pages[0].data}
      delete extra["results"]
    }
    return extra
  }, [data])

  return {items, extraData, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage, refetch}
}


const useCountdown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

const ShowTimeLeft = ({hours, minutes, seconds, className}) => {
  return (<span className={className}>{`${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`}</span>);
};

export const Countdown = ({targetDate, className}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <span className={className} style={{color: '#D9243D'}}>Time expired!</span>
  }

  return (
    <ShowTimeLeft
      className={className}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
}

