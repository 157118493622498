import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import colors from "assets/theme/base/colors";
import React, { memo, useReducer, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BasePostCard } from "base/Cards/PostCard";
import { PrimaryButton } from "components/PrimaryButton";
import { initialRelatedStateIdolizer, relatedFilter, relatedFilterReducer } from "./reducers";
import { IdolCard } from "./idolCard/idolCard";
import { useApi, useInfiniteScroll } from "../../../utils/hooks";
import { BlockUserModal } from "../../../components/BlockUserModal";
import { ReportUserModal } from "../../../components/ReportUserModal";
import { notifyError, notifySuccess } from "../../../services/notification";
import { useNavigate } from "react-router-dom";
import { UnauthenticatedPaths } from "../../../paths/paths";

export const IdolsListCard = memo((props) => {
  const {title, profile} = props
  const [filter, dispatchFilter] = useReducer(relatedFilterReducer, initialRelatedStateIdolizer)
  const api = useApi()
  const [selected, setSelected] = useState(1);
  const [showReportUserModal, setShowReportUserModal] = useState(false)
  const [showBlockUserModal, setShowBlockUserModal] = useState(false)
  const navigate = useNavigate()


  const getRelation = ()=>{
    let relation = ""
    if(filter.all){
      relation = "following_idolizing"
    }else if(filter.following){
      relation = "following"
    }else{
      relation = "idolizing"
    }
    return relation
  }

  const onApiCall = ({page})=>{
    let data = {page, relation: getRelation()}
    return api.getRelatedUsers(data)
  }

  const {
    items,
    fetchNextPage,
    extraData,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users", filters:filter})



  const Loader = () => {
    return (
      <Typography sx={{fontSize: "12px", mt: 2, color: colors.secondary.main}}>Loading more...</Typography>
    )
  }


  const unfollow = (userId) => {
    if (!userId) {
      notifyError("No Idol information to unfollow")
      return
    }
    api.unfollow(userId).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Unfollowing " + selected.username)
      } else {
        notifyError("Error unfollowing " + selected.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unfollowing " + selected.username)
    })
  }


  return (
    <BasePostCard
      title={title}
      headerControls={(
        <Box sx={styles.headerBox}>
          <PrimaryButton text={(extraData.all??0) + ' ALL '}
                         size='small'
                         onClick={() => {
                           dispatchFilter({type: relatedFilter.all})
                         }}
                         mainColor={filter.all ? colors.primary : colors.buttons.button2}
                         width={"100px"}
          />
          <PrimaryButton text={profile.number_of_following + ' FOLLOWING'}
                         size='small'
                         onClick={() => {
                           dispatchFilter({type: relatedFilter.following})
                         }}
                         mainColor={filter.following ? colors.primary : colors.buttons.button2}
                         width={"180px"}
          />
          <PrimaryButton text={profile.number_of_subscribed + ' IDOLIZING'}
                         size='small'
                         onClick={() => {
                           dispatchFilter({type: relatedFilter.idolizing})
                         }}
                         mainColor={filter.idolizing ? colors.primary : colors.buttons.button2}
                         width={"130px"}
          />
        </Box>
      )}>
      {selected && (
        <React.Fragment>
          <BlockUserModal user={selected}
                          open={showBlockUserModal}
                          onBlock={() => {
                            setShowBlockUserModal(false)
                            refetch({ refetchPage: (page, index) => index === 0 })
                          }}
                          onClose={() => setShowBlockUserModal(false)}/>

          <ReportUserModal user={selected}
                           open={showReportUserModal}
                           fromURL={ '/dashboard/home'}
                           onClose={() => setShowReportUserModal(false)}/>
        </React.Fragment>
      )}

      <Box sx={styles.bodyBox}>
        <InfiniteScroll
          styles={styles.scroll}
          dataLength={items?.length}
          next={() => {
            if (!isFetchingNextPage) {
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={<Loader/>}
        >
          <Grid container spacing={2}>
            {
              items.map((user, index) => {
                let extraProps = {}
                if(user.following){
                  extraProps["onUnfollow"] = ()=>{
                    setSelected(user)
                    unfollow(user.id)
                  }
                }

                return (
                    <Grid item md={6} lg={6} key={"grid-" + index}>
                      <IdolCard
                        key={"IdolizerCard-" + index}
                        avatar={user.image}
                        {...extraProps}
                        onClickUser={()=> {
                          navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", user.idol_id_name))
                        }}
                        onReport={()=>{
                          setSelected(user)
                          setShowReportUserModal(true)
                        }}
                        onBlock={()=>{
                          setSelected(user)
                          setShowBlockUserModal(true)
                        }}
                        idolizerName={user.username}
                        idolPoints={user.idol_points}
                        isVerified={user.is_validated}
                        iconColor={user.idolizing?"red":"gray"}
                        type={user.user_type.codename} // Just for now it's a hardcoded string then from backend
                      />
                    </Grid>
                  )
                }
              )
            }
          </Grid>
        </InfiniteScroll>

      </Box>
    </BasePostCard>
  );
})

const styles = {
  headerBox: {
    display: "flex",
    flexDirection: "row",
    width: "500px",
    gap: "15px",
    justifyContent: "flex-end"
  },
  scroll:{
    padding:"0px 25px 0px 10px",
    margin:"24px -10px 0px -10px",
    maxHeight:"80vh",
    minHeight:"300px"
  },
  bodyBox: {
    background: "transparent !important",
    width: "100%",
    height: "400px",
    paddingTop: "24px",
    overflowY: "scroll"
  }
}

export default IdolsListCard;
