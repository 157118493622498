import { observer } from "mobx-react";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import { useApi, useIsMobile } from "../../../utils/hooks";
import React, { useEffect, useState } from "react";
import { setLayout, useMaterialUIController } from "../../../context";
import { LayoutNames } from "../../../constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../services/notification";
import colors from "../../../assets/theme/base/colors";
import rgba from "../../../assets/theme/functions/rgba";
import Loader from "../../../components/Loader";
import { Box, Typography } from "@mui/material";
import { LeftColumn } from "./LeftColumn";
import { MeetStep1 } from "./step1";
import { MeetStep2 } from "./step2";
import { MeetStep3 } from "./step3";
import { ConfirmPaymentModal } from "../../../components/ConfirmPaymentModal";
import moment from "moment";
import { UnauthenticatedPaths } from "../../../paths/paths";
import {moneyFormat} from "../../../services/helpers";


export const MeetMePage = observer(()=>{
  const isMobile = useIsMobile()
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const [controller, dispatch] = useMaterialUIController();
  const [profile, setProfile] = useState({})
  const { idol_id_name }  = useParams();
  const api = useApi()
  const [loading, setLoading] = useState(false)
  const [showConfirmPayment, setShowConfirmPayment] = useState(false)
  const [CurrentStep, setCurrentStep] = useState(1)
  const [selectedEventType, setSelectedEventType] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [Times, setTimes] = useState([])
  const [userId, setUserID] = useState(null)


  const styles = isMobile? mobileStyles : desktopStyles

  const onSelectEventType = (evenType)=>{
    setSelectedEventType(evenType)
    setCurrentStep(value => ++value)
  }
  const onSelectDate = (times)=>{
    setTimes(times)
    setCurrentStep(value => ++value)
  }

  const goBack = ()=>{
    setCurrentStep(value => --value)
  }

  useEffect(() => {
    getProfile()
  },[])

  useEffect(() => {
    setLayout(dispatch, LayoutNames.meetMe);
  }, [pathname]);

  const getProfile = ()=> {
    api.getIdolProfile(idol_id_name).then(response => {
      if (response.kind === "ok") {
        const data = response.data
        setUserID(data.id)
        const tags = []
        for (const obj of [data.tag_1, data.tag_2, data.tag_3]) {
          if (obj) {
            tags.push(obj.toUpperCase())
          }
        }

        setProfile({
          ...data,
          idolPoints: data.idol_points,
          isVerified: data.is_validated,
          tags: tags,
          bio: data.biography,
          shoeSize: data.shoe_size
        })
      } else {
        notifyError("Error getting profile")
      }
    }).catch(reason => {
      notifyError("Error getting profile")
      console.log(reason)
    })
  }

  const createMeetRequest = ()=>{
    const data = {
      event_type:selectedEventType.id,

      start_datetime:  moment(`${selectedDay} ${moment(selectedTime.start).format("HH:mm")}`),
      end_datetime:  moment(`${selectedDay} ${moment(selectedTime.end).format("HH:mm")}`),

      name: selectedEventType.name,
      fee: selectedEventType.fee,
      time_zone:moment.tz.guess()
    }

    api.createMeeting(data).then(value => {
      console.log(value)
      if(value.kind === "ok"){
        notifySuccess("Meeting Request created successfully")
        navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", idol_id_name),
          {
            state:{
              meet:{
                ...data,
                meeting_type:selectedEventType.meeting_type,
                to_user:{
                  username:profile.username,
                  idol_points: profile.idol_points,
                  image: profile.image,
                  is_validated: profile.is_validated
                }
              }
            }
          })
      }else{
        notifyError("Can't create the meeting request")
      }
    })

  }

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <Loader show={loading}/>
      <ConfirmPaymentModal
        open={showConfirmPayment}
        confirmAction={()=>createMeetRequest()}
        onClose={() => setShowConfirmPayment(false)}
        title='PAYMENT'
        message={`Your card will be charged ${moneyFormat(selectedEventType?.fee)} once the idol confirms the scheduled time`}
      />
      <Box style={styles.main}>
        <Box style={styles.leftContainer}>
          <LeftColumn profile={profile} isMobile={isMobile} />
        </Box>
        <Box style={styles.rightColumn}>
          <Typography variant={"h1"} style={styles.title}>
            MEET ME
          </Typography>
          {CurrentStep === 1 && userId && (
            <MeetStep1 onSelect={onSelectEventType} isMobile={isMobile} userId={userId} />
          )}

          {CurrentStep === 2 && selectedEventType && userId && (
            <MeetStep2
              goBack={goBack}
              onSelect={onSelectDate}
              event={selectedEventType}
              isMobile={isMobile}
              userId={userId}
              onSelectDay={setSelectedDay}
            />
          )}
          {CurrentStep === 3 && Times && (
            <MeetStep3 goBack={goBack} times={Times} isMobile={isMobile}
                       onSelect={(selected)=>{
                         setSelectedTime(selected)
                         setShowConfirmPayment(true)
                       }}/>
          )}
        </Box>
      </Box>


    </BaseProtectedComponentPage>
  )
})

const desktopStyles = {
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"90px",
    fontSize:48,
    fontWeight:300,
    color: colors.white.main
  },
  rightColumn: {
    display:"flex",
    flexDirection:"column ",
    background:colors.background.default,
    width:"60vw"
  },
  leftContainer:{
    display:"flex",
    flexDirection:"column ",
    background:rgba(colors.darkBlue[200], 0.5),
    width:"40vw"
  },
  main:{
    display:"flex",
    flexDirection:"row",
    minHeight:"100vh",
  }
}


const mobileStyles = {
  title:{
    textAlign:"left",
    fontSize:36,
    fontWeight:300,
    color: colors.white.main,
    padding:"24px 16px 0px 16px"
  },

  main:{
    display:"flex",
    flexDirection:"column",
    height:"100vh"
  },
  leftContainer: {
  },
}
