const formControlLabelStyle = (labelFontSize, labelColor) => {
  return {
    "& .MuiFormControlLabel-label": {
      fontSize: `${labelFontSize}`,
      fontWeight: `400`,
      lineHeight: `26px`,
      color: `${labelColor}!important`
    }
  }
}

export default formControlLabelStyle