import { Box, Button, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import rgba from "../../../../assets/theme/functions/rgba";
import colors from "../../../../assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import { useChatApp } from "../utils/hooks";
import { ROOM_LIST } from "../data";
import { BaseCardIdolInfo } from "../../../../base/Cards/InfoCards/IdolizerInfoCard";
import { useApi } from "../../../../utils/hooks";
import { Client as ConversationsClient } from "@twilio/conversations";

let twilioClient = null
let idolTwilioClient = null
const idolToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzMxNWJjMDI5ZGJlYWQ4YjZmNDRiOTNiZmFhMjU5YWZiLTE2ODAxMDEyNTAiLCJncmFudHMiOnsiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTMGNjZDVkM2JmZTUyNGZmN2IwMjNiNTJiYmI2NWNkMmYifSwiaWRlbnRpdHkiOiJEd2lnaHRTY2hydXRlIn0sImlzcyI6IlNLMzE1YmMwMjlkYmVhZDhiNmY0NGI5M2JmYWEyNTlhZmIiLCJleHAiOjE2ODAxODc2NTAsIm5iZiI6MTY4MDEwMTI1MCwic3ViIjoiQUNmYTljZGQ4YmNiNjVlZmFmNjExMmI3ZjI0OWViYzczNyJ9.Da9j7oSiWfq_z0X5zSthGiy7c2zclK1uLOpSnW2tfuY"
export const RoomList  = observer(()=>{
  const {setSelectedConversation, selectedConversation, setRecipient, 
    setLoading, loading, setIncomingMessage, incomingMessage} = useChatApp()
  const [roomList, setRoomList] = useState([])
  const api = useApi()
  const [conversations, setConversations] = useState([])

  const withIdolTokenExample = async ()=>{
    // this is an existing conversation sid created in backend
    const sid = "CHf5e74646446c45c1a8afc99d709a2b27"

    idolTwilioClient = new ConversationsClient(idolToken)

    try {
      const dwight = await twilioClient.getConversationBySid(sid``)
      console.log("idol", dwight )

    }catch (e) {
      console.log(e)
    }

    // all the idols conversations
    const aux_list = await idolTwilioClient.getSubscribedConversations()

    console.log(aux_list.items.map(value => ({
      sid: value.sid,
      friendlyName: value.friendlyName,
      participating: value.status,
      createdBy: value._internalState.createdBy
    })))

  }

  const initConversations = async (token)=> {
    // this part is getting all the conversations created by the AdminUserChat
    twilioClient = new ConversationsClient(token)
    const aux_list = await twilioClient.getSubscribedConversations()

    console.log(aux_list.items.map(value => ({
      sid: value.sid,
      friendlyName: value.friendlyName,
      participating: value.status,
      createdBy: value._internalState.createdBy
    })))

    const users  = await twilioClient.getSubscribedUsers()
    console.log("users", users)
    setConversations(aux_list.items)
  }

  const onSelectConversation = async (room)=> {
    setSelectedConversation(room);
    let conversation = await twilioClient.getConversationBySid(room.sid);
    // Join the participant to the conversation
    try {
      await conversation.join();
      // Participant joined the conversation
    } catch (error) {
      // console.error('Error joining participant to conversation:', error);
    }
    // Getting incoming messages
    conversation.on("messageAdded", async (message) => {
      setIncomingMessage(message);
      // setLoading(false);
    });
    //
    setRecipient(conversation.friendly_name)
    

  }

  useEffect(() => {
    setLoading(true)
    getTwilioToken()
  }, [])

  const getTwilioToken = ()=> {
    // this functions get the twilio token of the admin  in order to get all the admin conversations
    api.getTwilioToken().then(response => {
      if(response.kind === "ok"){
        // withIdolTokenExample()
        initConversations(response.data.twilio_token)
      }
    }).catch(reason => console.log(reason))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setLoading(true)
    api.getSupportConversations().then(response => {
      if (response.kind === "ok"){
        setRoomList(response.data.conversations)
        setLoading(false)
      }
    }).catch(reason => console.log(reason))
  }, [])

  const RoomItem = ({item, index})=>{
    let itemStyle = styles.roomItem
    if(selectedConversation?.id === item.id){
      itemStyle = {...itemStyle, ...styles.roomItemSelected}
    }
    return (
      <Box onClick={()=> onSelectConversation(item)} sx={itemStyle}>
        <BaseCardIdolInfo
          idolizerName={item.idol.username}
          avatar={`${item.idol.image}`}
          isVerified={item.idol.is_validated}
          isIdolizer={false}
          idolPoints={item.idol.idol_points}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={styles.main}>
        <Box sx={{mx:"24px"}}>
          <Typography sx={styles.title}>LIVE CHAT</Typography>
          <Box sx={styles.divider} />
        </Box>

        <Box sx={styles.roomList}>
          {roomList.map((item, index)=>{
            return <RoomItem item={item} index={index} key={"room-"+index} />
          })}
        </Box>


      </Box>
    </Box>
  )
})

const styles = {
  main:{
    background:rgba(colors.background.card, 0.5),
    width:"392px",
    height:`calc(100vh - ${pxToRem(107)})`,
    pt:"8px"
  },
  title:{
    fontWeight:100,
    fontSize:"48px",
    fontFamily:"Roboto",
    color:rgba("white", 1)
  },
  divider:{
    height: "2px",
    width: "100%",
    background: colors.primary.main
  },  
  roomList: {
    overflowY:"scroll", 
    maxHeight:`calc(100vh - ${pxToRem(240)})`
  },
  roomItem:{
    height:"95px",
    px:"24px",
    cursor:"pointer"
  },
  roomItemSelected:{
    background: rgba("white", 0.1)
  }

}


