import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {CustomTextField} from 'components/TextField';
import Select from 'components/CustomSelect';
import RoundCheck from 'components/RoundCheck';
import {SecondaryButton} from 'components/SecondaryButton';

/* utils */
import {notifySuccess} from "services/notification";
import {CreateEventUtils} from './utils';

/* assets */
import {styles} from './styles';

export const MobileCreateEventModal = ({open, onClose, saveEventAction}) => {
  const modalProps = {
    open,
    onClose,
    title: "NEW MEETING TYPE",
    containerStyles: styles.modal,
    ...styles.content_container,
    removeBullshit: true
  };
  const [data, setData] = useState({ durations: [], cities: [], dateRange: [] });
  const { durations, cities, dateRange } = data;
  const [dateRangeValue, setDateRangeValue] = useState("Indefinetely into the future");
  const [dateRangeDaysOptions, setDateRangeDaysOptions] = useState({ days: "", optionDays: "" });
  const { days, optionDays } = dateRangeDaysOptions;
  const { initialValues, validationSchema, durationsData, citiesData, dateRangeData} = CreateEventUtils();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values["dateRange"] === "days") {
        values["dateRange"] = `${days} days - ${optionDays}`;
      }
      notifySuccess("Event created successfully");
      onClose();
    },
  });

  const manageDateRange = (value, setFieldValue) => {
    if (dateRangeValue === value) return;
    setDateRangeValue(value);
    setFieldValue("dateRange", value);
  };

  const setDateRangeFieldValues = () => {
    if (dateRangeValue !== "days") {
      setDateRangeValue("days");
      manageDateRange("days", formik.setFieldValue);
    }
  }

  const getMeetDurations = () => {
    // const durations = [
    //   { value: "30 minutes", label: "30 minutes" },
    //   { value: "1 hour", label: "1 hour" },
    //   { value: "2 hours", label: "2 hours" },
    // ];
    // setData({ ...data, durations: durations });
  };

  const getCities = () => {
    // const cities = [
    //   { value: "New York", label: "New York" },
    //   { value: "Los Angeles", label: "Los Angeles" },
    // ];
    // setData({ ...data, cities: cities });
  };

  const getOptionDays = () => {
    // const dateRange = [
    //   { value: "Calendar days", label: "Calendar days" },
    //   { value: "Option2", label: "Option2" },
    //   { value: "Option3", label: "Option3" },
    // ];
    // setData({ ...data, dateRange: dateRange });
  };

  useEffect(() => {
    getMeetDurations();
    getCities();
    getOptionDays();
  }, []);

  return (
    <BaseModal {...modalProps}>
      <Box sx={styles.content}>
        <CustomTextField
          inputLabel="Event Name"
          name="name"
          onChange={formik.handleChange}
          error={formik.errors.name && formik.touched.name}
          helperText={formik.touched.name && formik.errors.name}
        />
        <CustomTextField
          inputLabel="Description"
          name="description"
          onChange={formik.handleChange}
          error={formik.errors.description && formik.touched.description}
          helperText={formik.touched.description && formik.errors.description}
          inputBase={false}
          multiline
          rows={4}
        />
        <Box sx={styles.input_error_box}>
          <Select
            label="Duration"
            isMobile={true}
            defaultValue=""
            options={durationsData}
            name="duration"
            onChange={formik.handleChange}
          />
          {formik.errors.duration && formik.touched.duration && (
            <Typography sx={styles.error}>{formik.errors.duration}</Typography>
          )}
        </Box>
        <Box sx={styles.price_city_section}>
          <Box sx={[styles.middle_width, styles.input_error_box]}>
            <CustomTextField
              inputLabel="Price"
              placeholder="$"
              name="price"
              onChange={formik.handleChange}
            />
            <Typography sx={styles.error}>
              {formik.errors.price && formik.touched.price && formik.errors.price}
            </Typography>
          </Box>
          <Box sx={[styles.middle_width, styles.input_error_box]}>
            <Select
              label="City"
              isMobile={true}
              defaultValue=""
              options={citiesData}
              name="city"
              onChange={formik.handleChange}
            />
            <Typography sx={styles.error}>
              {formik.errors.city && formik.touched.city && formik.errors.city}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography sx={styles.title_section}>Date Range</Typography>
          <Box sx={styles.date_range_section}>
            <Box sx={{ display: "flex" }}>
              <RoundCheck
                radioButtonSize="16px"
                onClick={() => manageDateRange("days", formik.setFieldValue)}
                checked={dateRangeValue === "days"}
                formStyles={styles.form_root}
              />
              <Box sx={styles.date_range_inputs_container}>
                <CustomTextField
                  placeholder='30'
                  formStyles={styles.date_range_input_days}
                  onChange={(e) => {
                    setDateRangeDaysOptions({ ...dateRangeDaysOptions, days: e.target.value });
                    setDateRangeFieldValues()
                  }}
                />
                <Box sx={styles.select_box}>
                  <Select
                    defaultValue=""
                    options={dateRangeData}
                    onChange={(e) => {
                      setDateRangeDaysOptions({
                        ...dateRangeDaysOptions,
                        optionDays: e.target.value,
                      });
                      setDateRangeFieldValues()
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <RoundCheck
              radioButtonSize="16px"
              label="Within a date range"
              labelFontSize="14px"
              onClick={() => manageDateRange("Within a date range", formik.setFieldValue)}
              checked={dateRangeValue === "Within a date range"}
            />
            <RoundCheck
              radioButtonSize="16px"
              label="Indefinetely into the future"
              labelFontSize="14px"
              onClick={() => manageDateRange("Indefinetely into the future", formik.setFieldValue)}
              checked={dateRangeValue === "Indefinetely into the future"}
            />
          </Box>
        </Box>
        <Box sx={styles.actions}>
          <SecondaryButton
            text="CANCEL"
            onClick={() => [onClose(), formik.resetForm()]}
            textStyles={styles.cancel_button_text}
            {...styles.cancel_button}
          />
          <SecondaryButton
            text="APPLY"
            onClick={formik.handleSubmit}
            textStyles={styles.save_button_text}
            {...styles.save_button}
          />
        </Box>
      </Box>
    </BaseModal>
  );
}
