import colors from 'assets/theme/base/colors';
import pxToRem from 'assets/theme/functions/pxToRem';
const {white, red, card} = colors;

export const styles = {
  baseCard: {
    paddingBlock: `${pxToRem(30)} ${pxToRem(8)}`,
    background: card.event,
  },
  headerCard: {
    borderRadius: '13px 13px 0 0'
  },
  container: {
    display: 'grid',
    // gridTemplateRows: '0.8fr repeat(3, 1fr)',
    padding: '10% 5% 2% 5%',
  },
  event_date_info_container: {
    display: 'grid',
    gridTemplateColumns: '2.5fr 2fr',
    height:pxToRem(30)
  },
  duration: {
    color: white.main,
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
  event_meet_container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  event_type: {
    color: red.main,
    fontWeight: 500,
    justifySelf: 'end',
    fontSize: pxToRem(12),
  },
  event_type_meet: {
    alignSelf: 'end',
  },
  description: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    width: pxToRem(345),
  },
  price_container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon_dollar: {

      width: pxToRem(29),
      height: pxToRem(30),
  },
  price: {
    color: white.main,
    fontSize: pxToRem(22),
    fontWeight: 400,
  },
  actions_container: {
    paddingInline: 0,
    marginTop: 0,
    paddingTop: '3%',
  },
};
