import { Box, Grid, Typography } from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import React, { useState } from "react";
import { SecondaryButton } from "../../../components/SecondaryButton";
import moment from "moment";
import GlobeIcon from "../../../assets/icons/mundo.svg";
import {desktopStyles, mobileStyles} from "./styles/step3Styles"




export const MeetStep3 = ({isMobile, onSelect, goBack, event, times})=>{
  const BROWSER_TIMEZONE = moment.tz.guess()

  const styles = isMobile? mobileStyles : desktopStyles
  const currentTime = moment().format("hh:mm A")

  const [selected, setSelected] = useState([]);

  const TimeContainer = ({value, selected, index})=>{
    const background = selected?colors.primary.main : colors.darkBlue[700]
    return (
      <Grid item
        onClick={()=>setSelected(index)}
        style={ {...styles.timesContainer, background}}>
        <Typography style={styles.timeText}>
          {value}
        </Typography>
      </Grid>
    )
  }

  return (
    <Box style={styles.mainContainer}>

      <Box style={styles.timeZoneContainer}>
        <Box component={"img"} src={GlobeIcon} style={{width:"13px", height:"13px", marginRight:"8px"}} />
        <Typography variant={"h3"} style={styles.timeZone}>
          {BROWSER_TIMEZONE}
        </Typography>
        <Typography style={{...styles.timeZone,fontWeight:"400", marginLeft:"8px"}}>{currentTime}</Typography>
      </Box>

      <Typography variant={"h1"} style={styles.title}>
        3. SELECT TIME
      </Typography>

      <Grid container style={styles.timesGrid}>
      
        {times.map((time, index)=>(
          <TimeContainer key={"time_container"+index} value={moment.tz(time.start, "America/Asuncion").format('hh:mm a')} selected={selected === index} index={index} />
        ))}
      </Grid>

      <Box style={styles.buttonsContainer}>
        <SecondaryButton text={"CONTINUE"}
                         disabled={!(selected >= 0)}
                         onClick={()=>{
                           onSelect(times[selected])
                         }}
                         textStyles={styles.buttonText}
                         buttonStyle={styles.buttonContinue} />
        <SecondaryButton text={"CANCEL"}
                         onClick={goBack}
                         textStyles={styles.buttonTextCancel}
                         buttonStyle={styles.buttonCancel} />
      </Box>

    </Box>

  )
}
