import React, {useEffect, useState} from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import useVideoContext from '../hooks/useVideoContext/useVideoContext';
import {notifyError} from "../../../services/notification";


export default function PreJoinScreens() {
  const {getAudioAndVideoTracks} = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const roomName = urlParams.get('room') || ''
  const itsOk = roomName !== ''

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
        notifyError('No camera detected')
      });
    }
  }, [mediaError]);

  useEffect(() => {
    getAudioAndVideoTracks()
  }, []);

  return (
    <IntroContainer>
      {itsOk && <DeviceSelectionScreen room={roomName}/>}
    </IntroContainer>
  );
}
