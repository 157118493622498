import colors from "assets/theme/base/colors";
export const styles = {
  title: {
    alignSelf: "flex-start",
    color: "#fff",
    fontWeight: "400",
    fontSize: "25px",
  },
  accordion: {
    backgroundColor: "#182536",
    boxShadow: "0px 0px 0px 0px",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
  },
  expandIcon: {
    color: "#fff",
    width: "30px",
    height: "30px",
  },
  inputsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "30px",
  },
  titleDetails: {
    color: "#fff",
    fontWeight: "600",
  },
  cardDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  doubleInput: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
  },
  billingDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  input: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    padding: "15px",
    fontSize: "18px",
    width: "100%",
    color: "#fff",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  phoneInput: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    fontSize: "18px",
    width: "100%",
    color: "#fff",
    border: "0px",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  phoneInputButton: {
    backgroundColor: colors.inputBackgroundColor,
    border: "0px",
  },
  phoneDropdown: {
    backgroundColor: colors.inputBackgroundColor,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  button: {
    backgroundColor: colors.primary.main,
    height: "42px",
    width: "193px",
    color: "#fff",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: colors.withdrawlcard.greybutton,
      color: "#fff",
    },
    "&:focus": {
      color: "#fff!important",
    },
  },
};
