import React from 'react';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {MobileDatePriceEvent} from 'components/Mobile/DatePriceEventTrackerMobile';
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import {styles} from './styles';

export const MobileCancelMeetingModal = ({
    open, 
    onClose,
    idolizerName,
    idolPoints,
    avatar,
    isVerified,
    date,
    timeStart,
    timeEnd,
    price,
    cancelAction,
    meetingType,
}) => {

  const modalProps = {
    open, 
    onClose,
    title: 'CANCEL MEETING',
    containerStyles: styles.modal_content_container,
    ...styles.content_container
  };

  const cardInfoProps = {
    idolizerName,
    idolPoints,
    avatar,
    isVerified,
    isMobile: true,
    nameStyles: styles.name,
    pointStyles: styles.points,
    idpStyles: styles.points,
  };

  const timeProps = {
    date,
    timeStart,
    timeEnd,
  };

  return (
    <BaseModal {...modalProps}>
        <Box sx={styles.idol_info_container}>
            <BaseCardIdolInfo {...cardInfoProps} />
            <Typography sx={styles.event_type}>{meetingType}</Typography>
        </Box>
        <Box sx={[styles.idol_info_container, styles.date_price_info_container]}>
            <MobileDatePriceEvent {...timeProps} />
            <Typography sx={styles.price}>{price}</Typography>
        </Box>
        <Box sx={styles.action_container}>
            <Typography sx={styles.action_message}>
                Are you sure you want to cancel this meeting?
            </Typography>
            <SecondaryButton 
              text='CANCEL MEETING' 
              onClick={cancelAction ?? onClose}
              textStyles={styles.action_button_text}
              {...styles.action_button}
            />
        </Box>
    </BaseModal>
  );
}
