import { FormControl, FormHelperText } from "@mui/material";
import colors from "assets/theme/base/colors";
import * as React from "react";
import PhoneInput from 'react-phone-input-international';
import { useIsMobile } from "utils/hooks";
import pxToRem from "../../assets/theme/functions/pxToRem";
import MDTypography from "../MDTypography";


const PhoneNumberField = (props) => {
  const {   
    country,
    inputStyle,
    buttonStyle,
    dropdownStyle,
    placeholder = "Phone Number",
    value,
    onChange,
    error,
    helperText,
    errorTextStyles,
  } = props;
  const isMobile = useIsMobile();

  return (
    <FormControl variant="outlined" fullWidth={true}> 
        <PhoneInput
              country={country}
              inputStyle={inputStyle}
              buttonStyle={buttonStyle}
              dropdownStyle={dropdownStyle}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              />
              {error && (
                <FormHelperText>
                  <MDTypography
                    sx={[{fontSize: !isMobile ? pxToRem(14) : pxToRem(12), color: colors.error.main}, errorTextStyles]}
                    variant='span'>{helperText}</MDTypography>
                </FormHelperText>
             )}
      </FormControl>
  )
}

export default PhoneNumberField;
