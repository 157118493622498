import React from 'react';
import UnsupportedBrowserWarning from "./UnsupportedBrowserWarning";
import {MuiThemeProvider} from '@material-ui/core/styles';
import theme from './theme';
import {VideoProvider} from "./VideoProvider";
import ErrorDialog from "./ErrorDialog/ErrorDialog";
import {ParticipantProvider} from "./ParticipantProvider";
import {ChatProvider} from "./ChatProvider";
import AppStateProvider, {useAppState} from "./state";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import App from "./App";
import {useUserStore} from "../../utils/hooks";

const VideoApp = () => {
  const {error, setError} = useAppState();
  const connectionOptions = useConnectionOptions();
  const userStore = useUserStore()
  if (!userStore.isLoggedIn) {
    userStore.reset()
  }

  // @ts-ignore
  return (<VideoProvider options={connectionOptions} onError={setError}><ErrorDialog dismissError={() => setError(null)} error={error}/><ParticipantProvider><ChatProvider><App/></ChatProvider></ParticipantProvider></VideoProvider>
  );
};

export default function VideoCall() {
  return (
    <MuiThemeProvider theme={theme}>
      <UnsupportedBrowserWarning>
        <AppStateProvider>
          <VideoApp/>
        </AppStateProvider>
      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
  );
}
