import { DashboardIdolizerPage } from "../pages/portal/dashboard/idolizer";
import { NavigationMenu } from "../constants";
import { IdolizerWallet } from "../pages/portal/dashboard/idolizer/wallet";
import { IdolizerTransactions } from "../pages/portal/dashboard/idolizer/transactions";
import { Meetings } from "../pages/portal/dashboard/Meetings";
import { IdolsListPage } from "../pages/portal/dashboard/idolizer/IdolsList";
import { BlockedUsersPage } from "../pages/settings/BlockedUsers";
import { TermsNConditionsPage } from "../pages/settings/Terms&Conditions";
import { PrivacyPolicyPage } from "../pages/settings/PrivacyPolicy";
import { DeactivateAccountPage } from "../pages/settings/DeactivateAccount";
import NotificationsPage from "../pages/notifications";
import { IdolizerProfilePage } from "../pages/edit-profile/idolizer";
import { ExplorePage } from "../pages/explore";
import { IdolProfilePage } from "../pages/idolProfile";
import { ReportUserPage } from "../pages/report-user";
import { MeetMePage } from "../pages/portal/meetMe";
import VideoCall from "../pages/VideoCall";
import Logout from "../pages/auth/logout";
import { IdolizerPaths } from "../paths/idolizerPaths";
import {IdolizerVerification} from "../pages/verification/IdolizerVerification";
import {VerificationIdolizer} from "../pages/verification";

export const idolizerRoutes =  {
  home: {
    path: IdolizerPaths.home,
    element: (<DashboardIdolizerPage currentNavMenu={NavigationMenu.DASHBOARD}/>),
  },
  wallet: {
    path: IdolizerPaths.wallet,
    element: <IdolizerWallet/>,
  },
  transactions: {
    path: IdolizerPaths.transactions,
    element: <IdolizerTransactions/>,
  },
  meetings: {
    path:IdolizerPaths.meetings,
    element: <Meetings/>,
  },
  idols: {
    path: IdolizerPaths.idols,
    element: <IdolsListPage/>,
  },
  blockedUsers: {
    path: IdolizerPaths.blockedUsers,
    element: (<BlockedUsersPage currentNavMenu={NavigationMenu.SETTINGS}/>),
  },
  termsAndConditions: {
    path: IdolizerPaths.termsAndConditions,
    element: (<TermsNConditionsPage currentNavMenu={NavigationMenu.SETTINGS}/>),
  },
  privacyPolicy: {
    path: IdolizerPaths.privacyPolicy,
    element: (<PrivacyPolicyPage currentNavMenu={NavigationMenu.SETTINGS}/>),
  },
  deactivateAccount: {
    path: IdolizerPaths.deactivateAccount,
    element: (<DeactivateAccountPage currentNavMenu={NavigationMenu.SETTINGS}/>),
  },
  getVerified: {
    path: IdolizerPaths.getVerified,
    element: <VerificationIdolizer currentNavMenu={NavigationMenu.SETTINGS}/>,
  },
  notifications: {
    path: IdolizerPaths.notifications,
    element: <NotificationsPage/>,
  },
  profile: {
    path: IdolizerPaths.profile,
    element: <IdolizerProfilePage/>,
  },
  // root: {
  //   path: IdolizerPaths.explore,
  //   element: <ExplorePage/>,
  // },
  explore: {
    path: IdolizerPaths.explore,
    element: <ExplorePage/>,
  },
  viewIdolProfile: {
    path:IdolizerPaths.viewIdolProfile,
    element: <IdolProfilePage/>,
  },
  reportUser: {
    path: IdolizerPaths.reportUser,
    element: <ReportUserPage/>,
  },
  meetMe: {
    path: IdolizerPaths.meetMe,
    element: <MeetMePage/>,
  },
  videoCall: {
    path: IdolizerPaths.videoCall,
    element: <VideoCall/>,
  },
  logout: {
    path: IdolizerPaths.logout,
    element: <Logout/>,
  },
}
