import example_image from 'assets/images/tip_example.png';

export const transactions = [
    {
        id: 0,
        description: 'Subscribed to Gabrixelle',
        invoice_date: new Date(2022, 11, 22),
        amount: 250,
        type: 'subscription'
    },
    {
        id: 1,
        description: 'Tip for Ema Fox',
        invoice_date: new Date(2022, 10, 22),
        amount: 15,
        type: 'tip',
    },
    {
        id: 2,
        description: 'Subscribed to Bambi Val',
        invoice_date: new Date(2021, 9, 10),
        amount: 75,
        type: 'subscription'
    },
    {
        id: 3,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
    {
        id: 4,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
    {
        id: 5,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
    {
        id: 6,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
    {
        id: 7,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
    {
        id: 8,
        description: 'Booked Meeting with Gabrixelle',
        invoice_date: new Date(2021, 2, 3),
        amount: 8000,
        type: 'meet'
    },
];

export const idols = [
    {
        id: 0,
        idolizerName: 'Alex Rich',
        idolPoints: 1500,
        isVerified: false,
        status: 'idolizing',
        userType: 'Idolizing',
        subscriptionPrice: 0


    },
    {
        id: 1,
        idolizerName: 'Alexa Armas',
        idolPoints: 1250,
        isVerified: true,
        status: 'following',
        userType: 'Following',
        subscriptionPrice: 12.00

    },
    {
        id: 2,
        idolizerName: 'Anne Wiche',
        idolPoints: 1500,
        isVerified: false,
        status: 'following',
        userType: 'Following',
        subscriptionPrice: 0

    },
    {
        id: 3,
        idolizerName: 'Stelle Harmon',
        idolPoints: 12500,
        isVerified: true,
        status: 'idolizing',
        userType: 'Idolizing',
        subscriptionPrice: 19
    },
    {
        id: 4,
        idolizerName: 'Harry Potter',
        idolPoints: 1500,
        isVerified: false,
        status: 'idolizing',
        userType: 'Idolizing',
        subscriptionPrice: 14
    },
];

export const tips = [
    {
        id: 0,
        idolizerName: 'Wade Warren',
        idolPoints: 13500,
        amount: 5,
        isVerified: true,
        asset: example_image,
    },
    {
        id: 1,
        idolizerName: 'Guy Hawkins',
        idolPoints: 1357,
        amount: 5,
        isVerified: true,
        asset: example_image,
    },
    {
        id: 2,
        idolizerName: 'Robert Fox',
        idolPoints: 1376,
        amount: 5,
        isVerified: false,
        asset: example_image,
    },
    {
        id: 3,
        idolizerName: 'Robert Patrick',
        idolPoints: 1526,
        amount: 6,
        isVerified: true,
        asset: example_image,
    },
    {
        id: 4,
        idolizerName: 'Robert De Niro',
        idolPoints: 1586,
        amount: 7,
        isVerified: false,
        asset: example_image,
    },
    {
        id: 5,
        idolizerName: 'Robert De Niro',
        idolPoints: 1586,
        amount: 7,
        isVerified: false,
        asset: example_image,
    },
];

export const idolTransactions = [
    {
        id: 0,
        idolizerName: 'Wade Warren',
        idolPoints: 13500,
        amount: 2500,
        isVerified: true,
        idolPointsEarned: 2500,
        type: 'videocall',
    },
    {
        id: 1,
        idolizerName: 'Guy Hawkins',
        idolPoints: 1357,
        amount: 15,
        isVerified: true,
        idolPointsEarned: 1500,
        type: 'tip',
    },
    {
        id: 2,
        idolizerName: 'Robert Fox',
        idolPoints: 1376,
        amount: 75,
        isVerified: false,
        idolPointsEarned: 7500,
        type: 'subscription',
    },
    {
        id: 3,
        idolizerName: 'Robert Fox',
        idolPoints: 1376,
        amount: 75,
        isVerified: false,
        idolPointsEarned: 7500,
        type: 'subscription',
    },
    {
        id: 4,
        idolizerName: 'Robert Fox',
        idolPoints: 1376,
        amount: 75,
        isVerified: false,
        idolPointsEarned: 7500,
        type: 'subscription',
    },
    {
        id: 5,
        idolizerName: 'Robert Fox',
        idolPoints: 1376,
        amount: 75,
        isVerified: false,
        idolPointsEarned: 7500,
        type: 'subscription',
    },
];

export const upcomingMeetings = [
    {
      id: 0,
      title: 'Rainforest Escape!',
      idolizerName: 'Micheal Hernandez',
      idolPoints: 12500,
      isVerified: true,
      price: 100,
      date: new Date(2022, 1, 10),
      timeStart: new Date().setHours(new Date().getHours()),
      timeEnd: new Date().setHours(new Date().getHours() + 1),
      upcoming: true,
      meetingType: 'idolevent',
      meetingPoints: 2500,
      timeZone: 'Rio de Janeiro, Brasil'
    },
    {
      id: 2,
      title: 'Rainforest Escape!',
      idolizerName: 'Micheal Hernandez',
      idolPoints: 12500,
      isVerified: true,
      price: 100,
      date: new Date(2022, 1, 10),
      timeStart: new Date().setHours(new Date().getHours()),
      timeEnd: new Date().setHours(new Date().getHours() + 1),
      upcoming: true,
      meetingType: 'idolevent',
      meetingPoints: 2500,
      timeZone: 'Rio de Janeiro, Brasil'
    },
    {
      id: 3,
      title: 'Rainforest Escape!',
      idolizerName: 'Micheal Hernandez',
      idolPoints: 12500,
      isVerified: true,
      price: 100,
      date: new Date(2022, 1, 10),
      timeStart: new Date().setHours(new Date().getHours()),
      timeEnd: new Date().setHours(new Date().getHours() + 1),
      upcoming: true,
      meetingType: 'idolevent',
      meetingPoints: 2500,
      timeZone: 'Rio de Janeiro, Brasil'
    },
];
