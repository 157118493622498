import { Box, Button, Stack, Typography } from "@mui/material";
import { SecondaryButton } from "components/SecondaryButton";
import { CustomTextField } from "components/TextField";
import {UploadPhotoWithCropper} from "components/UploadPhoto";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import upload from "../../assets/icons/upload_grey.svg";
import {useStores} from "../../models";
import {notifyError, notifySuccess} from "../../services/notification";
import {useApi} from "../../utils/hooks";
import {useIsMobile} from "../../utils/hooks";
import {useNavigate} from "react-router-dom";
import {IdolTypeEnum} from "../../utils/constants";

const initialValues = {
  price: "",
  file: "",
  description: "",
  tier: 1,
};
export const AddNewPostMobile = () => {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const isInfluencer = loginStore.idol_type === IdolTypeEnum.influencer
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);
  const [sendVideo, setSendVideo] = useState(false)
  const [videoFile, setVideoFile] = useState({});
  const [tempFile, setTempFile] = useState({});
  const [fileName, setFileName] = useState("temp.jpg");

  const [photo, setPhoto] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);

  const [payPerViewInputOpen, setPayPerViewInputOpen] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      handleClean()
      uploadImage()
      // alert(JSON.stringify(values, null, 2));
    },
  });
  const isMobile = useIsMobile();
  const handlePostState = (openClose, lockedUnlockedPayPerView) => {
    setPayPerViewInputOpen(openClose);
    formik.setFieldValue("tier", lockedUnlockedPayPerView);
  };
  const handleUploadPhoto = (file) => {
    // Aqui se obtiene la imagen del input drag and drop
    formik.setFieldValue("file", file);
  };
  const description = formik.values.description;
  const file = formik.values.file;
  const additionalPrice = formik.values.price;
  const postTier = formik.values.tier;
  const isDisabled = ((file !== "" && postTier !== "pay per view" && postTier !== "") || (file !== "" && postTier === "pay per view" && additionalPrice !== null && additionalPrice > 0)) ? false : true;
  const [openModal, setOpenModal] = useState(false)
  const handleClose = () => {
    setOpenModal(false)
  }
  const handleConfirm = () => {
    setOpenModal(false)
    //set the result of the crop as the image preview
  }
  const handleOpen = () => {
    setOpenModal(true)
  }

  const uploadingName = (name) => {
    setFileName(name)
  }
  const checkValues = () => {
    return (file === '');
  }

  const uploadImage = () => {
    setIsLoading(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    if (sendVideo === true) {
      notifySuccess('Uploading new post, please wait!', {autoHideDuration: 2000})
      formData.append("file", file, fileName)
      formData.append("post_category", 2); //image/video
    } else if (sendVideo === false) {
      formData.append("image", file, fileName);
      formData.append("post_category", 1); //image/video
    }
    formData.append("description", description);
    formData.append("additional_price", additionalPrice);

    formData.append(
      "post_type", postTier
    ); //tier_1/tier_2/tier_3

    // formData.append("description", "description")
    // formData.append("additional_price", "50")
    // formData.append("post_type", "tier_1")
    // console.log(formData.getAll("file"))
    // console.log(formData.getAll("image"))
    // console.log(formData.getAll("post_category"))
    // console.log(formData.getAll("description"))
    // console.log(formData.getAll("additional_price"))
    // console.log(formData.getAll("post_type"))


    api.uploadImagePost(formData).then(response => {
      if (response.kind === "ok") {
        navigate(`/i/${loginStore.idol_id_name}`)
        notifySuccess("You uploaded a new post", {autoHideDuration: 1000})
        handleClean()
      } else if (response.kind === "bad-data") {
        const { errors } = response;
        // Shame?
        Object.values(errors).map(e => {
          let a = e.length
          let n = 0
          while (a !== 0) {
            notifyError(`${e[n]}`, {autoHideDuration: 5000})
            --a
            ++n
          }
        })
      } else if (response.kind === "forbidden") {
        console.log("forbidden", loginStore.is_validated)
        if (!loginStore.is_validated) {
          notifyError("Your account needs to be verified.", {autoHideDuration: 2000})
        }
      } else {

      }
      console.log("Response", response)
    })
      .catch(reason => console.log(reason))
      .finally(() => {
        setIsLoading(false)
      })

      .catch((error) => {
        console.log(error)
        const errors = JSON.parse(JSON.stringify(error.response.data))
        console.log(errors.non_field_errors)
        if (errors.non_field_errors.includes("2PALA") === true) {
          // setShowMessage(true)
          // console.log(showMessage)
        }
        setIsLoading(false);
        notifyError("error lmao")
      });
  };

  const handleClean = () => {
    console.log("cleaning")
    formik.setFieldValue("price", "")
    formik.setFieldValue("file", "")
    formik.setFieldValue("description", "")
    formik.setFieldValue("tier", 1)
    setPhoto(null)
    setVideoSrc(null)
    console.log(sendVideo)
  }

  return (
    <Box sx={styles.container}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.content}>
        <Typography sx={styles.title}>Add new post</Typography>
        <Box sx={styles.inputsContainer}>
          <Box sx={styles.uploadContainer}>
            <UploadPhotoWithCropper
              text={"Select photos or videos to upload or drag and drop files"}
              src={upload}
              formik={formik}
              handleUploadPhoto={handleUploadPhoto}
              containerStyles={styles.uploadStyles}
              boxStyles={styles.boxStyles}
              openModal={openModal}
              handleClose={handleClose}
              handleConfirm={handleConfirm}
              handleOpen={handleOpen}
              uploadingName={uploadingName}
              sendVideo={setSendVideo}
              setVideoSrc={setVideoSrc}
              videoSrc={videoSrc}
              setPhoto={setPhoto}
              photo={photo}
            >
              <Box sx={styles.uploadBtnContainer}>
                <Typography sx={styles.resolutionText}>720x1280 resolution or higher</Typography>
                <Typography sx={styles.uploadBtnText}>SELECT FILE</Typography>
              </Box>
            </UploadPhotoWithCropper>
          </Box>
          <Box sx={styles.descriptionContainer}>
            <Box>
              <Typography sx={styles.subtitle}>Description</Typography>
              <CustomTextField
                placeholder="Compose new post..."
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                sx={styles.descriptionInput}
              />
              <Box sx={styles.tierInputsContainer}>
                <Typography sx={styles.subtitle}>Select tier</Typography>
                <Stack direction="row" spacing={1.5} sx={{ marginBottom: "24px" }}>
                  <Button
                    sx={postTier === 1 ? {...styles.tierInput, backgroundColor: "#D9243D"} :  styles.tierInput}
                    name="tier"
                    onClick={() => handlePostState(false, 1)}
                  >
                    Unlocked
                  </Button>
                  {!isInfluencer && (
                    <>
                      <Button
                        sx={postTier === 2 ? {...styles.tierInput, backgroundColor: "#D9243D"} :  styles.tierInput}
                        name="tier"
                        onClick={() => handlePostState(false, 2)}
                      >
                        Locked
                      </Button>
                      <Button
                        sx={postTier === 3 ? {...styles.tierInput, backgroundColor: "#D9243D"} :  styles.tierInput}
                        name="tier"
                        onClick={() => handlePostState(true, 3)}
                      >
                        Pay Per View
                      </Button>
                    </>
                  )}
                </Stack>
                {payPerViewInputOpen && (
                  <Box sx={styles.payPerViewContainer}>
                    <Typography sx={styles.subtitle}>Set price</Typography>
                    <CustomTextField
                      placeholder="$0.00"
                      name="price"
                      type="number"
                      required
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      sx={styles.textField}
                    />
                  </Box>
                )}
              </Box>
            </Box>
              <SecondaryButton
                text="POST"
                type="submit"
                disabled={checkValues() || isLoading}
                textStyles={styles.textStyles}
                buttonStyle={styles.buttonStyle}
              />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
  },
  content: {
    borderRadius: "10px",
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "140%",
    color: "#fff",
    paddingBottom: "24px",
    textAlign: "center"
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "140%",
    color: "#fff",
    paddingBottom: "11px",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  uploadBtnContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "150px",
  },
  boxStyles: {
    padding: "20px",
    justifyContent: "center",
  },
  uploadStyles: {
    width: "297px",
    minWidth: "295px!important",
    height: "100%",
  },
  resolutionText: {
    fontSize: "14px",
    color: "#59626F",
    fontWeight: 400,
    lineHeight: "16px",
    paddingTop: "20px",
    paddingBottom: "10px",
  },

  descriptionInput: {
    width: "100%",
    height: "107px",
    backgroundColor: "#243040",
  },
  tierInputsContainer: {
    marginTop: "25px"
  },
  tierInput: {
    width: "100%",
    height: "56px",
    backgroundColor: "#243040",
    borderRadius: "5px",
    border: "none",
    fontsize: "14px",
    padding: "0px",
    color: "#fff",
    fontWeight: 300,
    lineHeight: "24px",
    letterSpacing: "0.12px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#D9243D",
      color: "#fff!important",
    },
    "&:focus": {
      color: "#fff!important",
    },
  },
  descriptionContainer: {
    width: "100%",
    marginTop: "28px",
    padding: "0px 14px 0px 14px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  uploadBtnText: {
    fontSize: "12px",
    color: "#D9243D",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
    border: "2px solid #D9243D",
    width: "120px",
    textAlign: "center",
    padding: "10px",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      border: `2px solid #f52642`,
      color: "#f52642!important",
    },
  },
  buttonStyle: {
    height: "40px",
    minHeight: "40px",
    backgroundColor: "#192537",
    textTransform: "capitalize",
    marginBottom: "50px",
  },
  textStyles: {
    fontSize: "12px",
    color: "#D9243D",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
  },
  textField: {
    width: "100%",
    height: "40px",
  },
  payPerViewContainer: {
    padding: "24px 0px",
  },
};
