import { Box } from "@mui/material";
import colors from "../../assets/theme/base/colors";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useState } from "react";
import { BaseModal } from "../../base/BaseModal";
import { SecondaryButton } from "../SecondaryButton";
import { useApi } from "../../utils/hooks";
import { PrimaryButton } from "../PrimaryButton";
import { useNavigate } from "react-router-dom";
import { AuthenticatedPaths, UnauthenticatedPaths } from "../../paths/paths";

const ContentTypes = [
  {
    value:10,
    description:"Content is offensive or violates “IDOLIDOL” Terms or Service"
  },
  {
    value:20,
    description:"This content contains stolen material (DMCA)"
  },
  {
    value:30,
    description:"This content is spam"
  },
  {
    value:40,
    description:"Report Abuse"
  }
]
export const ReportUserModal = ({user, open, onClose, fromURL})=>{
  const api = useApi()
  const [selected, setSelected] = useState(null)
  const navigate = useNavigate()

  const continueReport = ()=>{
    navigate(`${AuthenticatedPaths.reportUser}`, {
      state: {
        userId: user.id,
        fromURL:fromURL??UnauthenticatedPaths.idolProfile.replace(":idol_id_name", user.idol_id_name),
        type: ContentTypes[selected],
        username: user.idol_id_name
      }})
  }

  const ContentButton = ({option, index,selected})=>{
    return (
      <PrimaryButton
        mainColor={selected?colors.primary : {main:colors.darkBlue[700], hover:colors.darkBlue[700]}}
        buttonStyle={{borderRadius:"10px",     width: {xs: "100%", lg: "612px"}, height:"64px", marginTop:"24px"}}
        textStyles={styles.buttonText}
        text={option.description}
        onClick={()=> setSelected(index)}
      />
    )
  }


  return (
    <BaseModal
      open={open}
      title={'REPORT USER'}
      bodyExtraStyle={{marginInline:0, marginBlockStart:"70px"}}
      containerStyles={{width: {xs: "100%",lg: "660px"},     height:"600px",  backgroundColor:colors.background.default}}
      onClose={() => onClose()}
    >
      <Box style={styles.body}>
        <Box sx={{display:"flex", flexDirection:"column", width: {xs: '100%', lg: "612px"}}}>
          {ContentTypes.map((option, index)=>(
            <ContentButton key={"contentbutton"+index} option={option} index={index} selected={selected === index} />
          ))}
        </Box>
        <Box sx={{mt:"48px"}} >
          <SecondaryButton
            sx={{...styles.button, marginTop:"292px"}}
            text="CONTINUE"
            disabled={selected === null}
            onClick={()=> continueReport()}
            padding={0.5}
          />
          <Typography
            onClick={onClose}
            style={{...styles.text, fontSize:14, cursor:"pointer", marginTop:"20px"}}>CANCEL</Typography>
        </Box>
      </Box>

    </BaseModal>

  )
}



const styles = {
  text:{
    fontSize:16,
    textAlign:"center",
    letterSpacing:"0.1em",
    color:colors.white.main,
    width:"292px",
  },
  button:{
    width: { xs: '200px', lg: "292px"},
    height:"40px"
  },
  buttonText:{
    fontWeight:400,
    letterSpacing: "0.1em",
    fontSize: {xs: 12, lg: 18},
    textAlign:"left",
    paddingLeft:"40px",
    paddingRight:"40px",
    lineHeight:"1.36"
  },
  body:{
    backgroundColor:colors.background.default,
    width:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
  }
}
