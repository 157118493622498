/* dependencies */
import React from "react";
import PropTypes from "prop-types";

/* MUI components */
import { Box, Typography } from "@mui/material";

/* components */
import { BaseIdolCard } from "base/Cards/IdolCard";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { CardEventActionsBox } from "components/BaseCardEventActionsBox";
import { DatePriceEvent } from "components/DatePriceEventTracker";

/* assets */
import { styles } from "./styles";
import dollar from "assets/icons/dollar_bg_dark.svg";

export const MeetingTypeCard = ({
                                  title,
                                  description,
                                  duration,
                                  price,                                 
                                  meetingType,
                                  //
                                  onDismiss,
                                  onApprove,
                                  approveButtonTextStyles,
                                  dismissButtonTextStyles,
                                  isIdol
                                }) => {
  const cardProps = {
    title,
    cardStyles: styles.baseCard,
    headerStyles: styles.header,
  };

  return (
    <BaseIdolCard {...cardProps}>
      <Box sx={styles.container}>
        <Box sx={styles.event_date_info_container}>
          <Typography sx={styles.duration}>{duration} Event</Typography>
          <Typography sx={styles.event_type}>{meetingType}</Typography>
        </Box>
        <Typography sx={styles.description}>{description}</Typography>
        <Box sx={styles.price_container}>
          <img
            src={dollar}
            alt='icon dollar'
            title={`$ icon for ${title} event`}
          />
          <Typography sx={styles.price}>{price}</Typography>
        </Box>
        <CardEventActionsBox
            approveDisabled={!isIdol}
            approveText={'Edit'}
            dismissText={'Delete'}
            approveAction={onApprove}
            dismissAction={onDismiss}
            approveTextStyles={approveButtonTextStyles}
            dismissTextStyles={dismissButtonTextStyles}
          />
      </Box>
    </BaseIdolCard>
  );
};


MeetingTypeCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  meetingType: PropTypes.string.isRequired, //PropTypes.oneOf(['idolevent', 'idolcall']).isRequired,
  //
  onDismiss: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
};
