import React, {useState} from 'react';
import {Button} from "@mui/material";
import { ButtonStyles } from "./styles";
import {ExpandLess, ExpandMore} from '@mui/icons-material';

const OrderingButton = (props) => {
  const {text, currentFilterSelected, onClick, size, backgroundDark = false} = props
  const [ascSorted, setAscSorted] = useState(true)

  const handleClick = (evt) => {
    setAscSorted(v => !v)
    onClick(ascSorted)
  }

  return (
    <Button
      sx={(theme) => ButtonStyles(theme, currentFilterSelected, backgroundDark)}
      onClick={handleClick}
      size={size}
      endIcon={
        ascSorted ? (
          <ExpandMore sx={{ width: "20px", height: "20px", paddingBottom: "2px" }} />
        ) : (
          <ExpandLess sx={{ width: "20px", height: "20px", paddingBottom: "2px" }} />
        )
      }
    >
      {text}
    </Button>
  );
};

export default OrderingButton;
