import React, {useState} from "react";
import {useFormik} from "formik";

// @mui components
import {Grid} from "@mui/material";

// Components
import {ProfileCard} from "../../../edit-profile/components";
import MDBox from "components/MDBox";
import {
    CustomAutocompleteFormik,
    CustomAutocompleteWithChipFormik
} from "components/AutocompleteFormik";
import {CustomFormikDateField, CustomFormikTextField, DownloadDocument} from "components/TextField";
import {PrimaryButton} from "components/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton";
import MDTypography from "components/MDTypography";

// Utils
import {
    getIdolIncInitialValues,
    getIdolProfileInitialValues,
    getIdolBankInitialValues,
    getIdolTaxInitialValues
} from "../utils";
import {getFormikValidationFields, ProfileCards} from "models";
import {Units} from "../../../../constants";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";

export const UserProfileTabsForm =
    ({
         user,
         userProfile,
         selectedUserId,
         submitValues,
         currentTab,
         tabSection,
         handleChangeTab,
         getRegions,
         getCities,
         Countries,
         Regions,
         Cities,
         LanguageList,
         GenderList,
         EthnicityList,
         setRegions,
         setCities,
     }) => {

        const [CurrentUnits, setCurrentUnits] = useState(null);

        const formik = {};

        formik.IdolProfile = useFormik({
            initialValues: getIdolProfileInitialValues(userProfile),
            validationSchema: getFormikValidationFields(ProfileCards.GeneralInformation),
            onSubmit: submitValues,
            enableReinitialize: true,
        })

        formik.IdolInc = useFormik({
            initialValues: getIdolIncInitialValues(userProfile),
            validationSchema: getFormikValidationFields(ProfileCards.Incorporated),
            onSubmit: submitValues,
            enableReinitialize: true,
        })

        formik.IdolBank = useFormik({
            initialValues: getIdolBankInitialValues(userProfile),
            validationSchema: getFormikValidationFields(ProfileCards.Bank),
            onSubmit: submitValues,
            enableReinitialize: true,
        })

        formik.IdolTax = useFormik({
            initialValues: getIdolTaxInitialValues(userProfile),
            validationSchema: getFormikValidationFields(ProfileCards.Tax),
            onSubmit: submitValues,
            enableReinitialize: true,
        })

        const handleClickOnUnits = (units) => {
            setCurrentUnits(units)
            formik.IdolProfile.setFieldValue('measurement_type', units)
        }

        const handleClickOnMetricUnits = () => {
            if (CurrentUnits !== Units.Metric.id) {
                formik.IdolProfile.setFieldValue('bust', (formik.IdolProfile.values.bust * 2.54).toFixed(2))
                formik.IdolProfile.setFieldValue('waist', (formik.IdolProfile.values.waist * 2.54).toFixed(2))
                formik.IdolProfile.setFieldValue('hips', (formik.IdolProfile.values.hips * 2.54).toFixed(2))
            }
            handleClickOnUnits(Units.Metric.id)
        }

        const handleClickOnImperialUnits = () => {
            if (CurrentUnits !== Units.Imperial.id) {
                formik.IdolProfile.setFieldValue('bust', (formik.IdolProfile.values.bust / 2.54).toFixed(2))
                formik.IdolProfile.setFieldValue('waist', (formik.IdolProfile.values.waist / 2.54).toFixed(2))
                formik.IdolProfile.setFieldValue('hips', (formik.IdolProfile.values.hips / 2.54).toFixed(2))
            }
            handleClickOnUnits(Units.Imperial.id)
        }

        if (selectedUserId === user?.id) {
            return (
                <>
                    {/*IDOL Profile*/}
                    <form onSubmit={formik.IdolProfile.handleSubmit}>
                        <ProfileCard title='IDOL Profile'
                                     isExpanded={(currentTab === tabSection.IdolProfile && selectedUserId === user?.id)}
                                     onChange={(e) => handleChangeTab(e, tabSection.IdolProfile)}>
                            {(currentTab === tabSection.IdolProfile && selectedUserId === user?.id) &&
                                <>
                                    <CustomFormikTextField key={user.id} name="username" formik={formik.IdolProfile}/>
                                    <CustomFormikTextField key={user.id} name="idol_id_name"
                                                           formik={formik.IdolProfile}/>
                                    <>
                                        <MDTypography sx={{
                                            fontSize: pxToRem(16),
                                            mb: pxToRem(4),
                                            color: "#FFFFFF"
                                        }}>{"Tags"}</MDTypography>
                                        <Grid container spacing={2}>
                                            <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                                                <CustomFormikTextField key={user.id} inputProps={{maxLength: 12}}
                                                                       name="tag_1"
                                                                       formik={formik.IdolProfile}/>
                                            </Grid>
                                            <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                                                <CustomFormikTextField key={user.id} inputProps={{maxLength: 12}}
                                                                       name="tag_2"
                                                                       formik={formik.IdolProfile}/>
                                            </Grid>
                                            <Grid style={{paddingTop: 0}} item sm={4} xs={12} marginBottom={1}>
                                                <CustomFormikTextField key={user.id} inputProps={{maxLength: 12}}
                                                                       name="tag_3"
                                                                       formik={formik.IdolProfile}/>
                                            </Grid>
                                        </Grid>
                                    </>
                                    <CustomFormikTextField key={user.id}
                                                           name="biography"
                                                           formik={formik.IdolProfile}
                                                           style={{minHeight: pxToRem(110)}}
                                                           multiline
                                                           rows={4}
                                    />
                                    <CustomAutocompleteFormik
                                        isOptionEqualToValue={(option, value) => true}
                                        name={'gender'}
                                        formik={formik.IdolProfile}
                                        options={GenderList}
                                    />
                                    <CustomFormikTextField key={user.id} name="age" formik={formik.IdolProfile}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <PrimaryButton
                                                text={"Metric"}
                                                size={"small"}
                                                buttonStyle={{height: "30px"}}
                                                onClick={handleClickOnMetricUnits}
                                                mainColor={CurrentUnits === Units.Metric.id ? colors.primary : colors.tertiary}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <PrimaryButton
                                                text={"Imperial"}
                                                size={"small"}
                                                buttonStyle={{height: "30px"}}
                                                onClick={handleClickOnImperialUnits}
                                                mainColor={CurrentUnits === Units.Imperial.id ? colors.primary : colors.tertiary}/>
                                        </Grid>
                                        {CurrentUnits === null ?
                                            <Grid item sm={12}><MDTypography sx={(theme) => {
                                                return {
                                                    color: theme.palette.primary.main,
                                                    fontSize: pxToRem(14),
                                                    textAlign: "center",
                                                }
                                            }}>Please select the measurement
                                                unit</MDTypography></Grid>
                                            :
                                            <>
                                                {CurrentUnits === Units.Metric.id &&
                                                    <Grid item md={6} sm={12}>
                                                        <CustomFormikTextField key={user.id}
                                                                               name="height"
                                                                               formik={formik.IdolProfile}
                                                                               customEndAdornmentText={Units.Metric.measurementUnit}
                                                                               onChange={(evt) => {
                                                                                   const inches = (evt.target.value / 2.54) % 12
                                                                                   const feets = Math.floor((evt.target.value / 2.54) / 12)
                                                                                   formik.IdolProfile.setFieldValue('height_ft', feets.toFixed(2))
                                                                                   formik.IdolProfile.setFieldValue('height_in', inches.toFixed(2))
                                                                               }}
                                                                               inputProps={{maxLength: 6}}
                                                        />
                                                    </Grid>
                                                }
                                                {CurrentUnits === Units.Imperial.id && [
                                                    <Grid item md={4} sm={12}>
                                                        <CustomFormikTextField key={user.id}
                                                                               name="height_ft"
                                                                               formik={formik.IdolProfile}
                                                                               customEndAdornmentText={'ft'}
                                                                               onChange={(evt) => {
                                                                                   const centimeters = evt.target.value * 30.48 + formik.IdolProfile.values.height_in * 2.54
                                                                                   formik.IdolProfile.setFieldValue('height', centimeters.toFixed(2))
                                                                               }}
                                                                               inputProps={{maxLength: 6}}
                                                        />
                                                    </Grid>,
                                                    <Grid item md={4} sm={12}>
                                                        <CustomFormikTextField key={user.id}
                                                                               name="height_in"
                                                                               formik={formik.IdolProfile}
                                                                               customEndAdornmentText={Units.Imperial.measurementUnit}
                                                                               onChange={(evt) => {
                                                                                   const centimeters = evt.target.value * 2.54 + formik.IdolProfile.values.height_ft * 30.48
                                                                                   formik.IdolProfile.setFieldValue('height', centimeters.toFixed(2))
                                                                               }}
                                                        />
                                                    </Grid>
                                                ]}

                                                <Grid item md={(CurrentUnits === Units.Metric.id) ? 6 : 4} xs={12}>
                                                    <CustomFormikTextField key={user.id}
                                                                           inputProps={{maxLength: 6}}
                                                                           name="shoe_size"
                                                                           customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : "US"}
                                                                           formik={formik.IdolProfile}
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        {(CurrentUnits !== null) && [
                                            <Grid item md={4} sm={12}>
                                                <CustomFormikTextField key={user.id}
                                                                       name="bust"
                                                                       formik={formik.IdolProfile}
                                                                       customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                                                />
                                            </Grid>,
                                            <Grid item md={4} sm={12}>
                                                <CustomFormikTextField key={user.id}
                                                                       name="waist"
                                                                       formik={formik.IdolProfile}
                                                                       customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                                                />
                                            </Grid>,
                                            <Grid item md={4} sm={12}>
                                                <CustomFormikTextField key={user.id}
                                                                       name="hips"
                                                                       formik={formik.IdolProfile}
                                                                       customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                                                />
                                            </Grid>
                                        ]}
                                    </Grid>
                                    <CustomFormikTextField key={user.id} name="location" formik={formik.IdolProfile}/>
                                    <CustomAutocompleteFormik
                                        isOptionEqualToValue={(option, value) => true}
                                        name={'ethnicity'}
                                        formik={formik.IdolProfile}
                                        options={EthnicityList}
                                    />

                                    <MDBox display='flex' justifyContent='flex-end' mt={pxToRem(12)}>
                                        <SecondaryButton type='submit' text='SAVE' width={pxToRem(250)}
                                                         height={pxToRem(40)}
                                                         textStyles={{fontSize: pxToRem(14)}}/>
                                    </MDBox>

                                </>
                            }
                        </ProfileCard>
                    </form>
                    {}

                    {/*IDOL Inc*/}
                    <form onSubmit={formik.IdolInc.handleSubmit}>
                        <ProfileCard title='IDOL Inc'
                                     isExpanded={(currentTab === tabSection.IdolInc && selectedUserId === user?.id)}
                                     onChange={(e) => handleChangeTab(e, tabSection.IdolInc)}>
                            {(currentTab === tabSection.IdolInc && selectedUserId === user?.id) &&
                                <>
                                    <CustomFormikTextField key={user.id} name="email" formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="password" formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="repeat_password"
                                                           formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="inc_first_name" formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="inc_middle_name"
                                                           formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="inc_last_name" formik={formik.IdolInc}/>
                                    <CustomFormikDateField name="inc_date_of_birth" formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="inc_address_line_1"
                                                           formik={formik.IdolInc}/>
                                    <CustomFormikTextField key={user.id} name="inc_address_line_2"
                                                           formik={formik.IdolInc}/>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                isOptionEqualToValue={(option, value) => true}
                                                name={'inc_country'}
                                                formik={formik.IdolInc}
                                                options={Countries}
                                                onChange={(e, v) => {
                                                    formik.IdolInc.setFieldValue("inc_country", v)
                                                    getRegions(v)
                                                    setCities([])
                                                    formik.IdolInc.setFieldValue("inc_state", null)
                                                    formik.IdolInc.setFieldValue("inc_city", null)
                                                }}
                                                onClear={() => {
                                                    setRegions([])
                                                    setCities([])
                                                    formik.IdolInc.setFieldValue("inc_country", null)
                                                    formik.IdolInc.setFieldValue("inc_state", null)
                                                    formik.IdolInc.setFieldValue("inc_city", null)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                isOptionEqualToValue={(option, value) => true}
                                                name={'inc_state'}
                                                formik={formik.IdolInc}
                                                options={Regions}
                                                onChange={(e, v) => {
                                                    formik.IdolInc.setFieldValue("inc_state", v)
                                                    getCities(v)
                                                    formik.IdolInc.setFieldValue("inc_city", null)
                                                }}
                                                onClear={() => {
                                                    setCities([])
                                                    formik.IdolInc.setFieldValue("inc_city", null)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                name={'inc_city'}
                                                formik={formik.IdolInc}
                                                options={Cities}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomFormikTextField key={user.id} name="inc_zip_code"
                                                                   formik={formik.IdolInc}/>
                                        </Grid>
                                    </Grid>
                                    <CustomFormikTextField key={user.id} name="inc_phone_number"
                                                           formik={formik.IdolInc}/>
                                    <MDBox display='flex' justifyContent='flex-end' mt={pxToRem(12)}>
                                        <SecondaryButton type='submit' text='SAVE' width={pxToRem(250)}
                                                         height={pxToRem(40)}
                                                         textStyles={{fontSize: pxToRem(14)}}/>
                                    </MDBox>
                                </>
                            }
                        </ProfileCard>
                    </form>
                    {/*IDOL Inc*/}

                    {/*IDOL Bank*/}
                    <form onSubmit={formik.IdolBank.handleSubmit}>
                        <ProfileCard title='IDOL Bank'
                                     isExpanded={(currentTab === tabSection.IdolBank && selectedUserId === user?.id)}
                                     onChange={(e) => handleChangeTab(e, tabSection.IdolBank)}>
                            {(currentTab === tabSection.IdolBank && selectedUserId === user?.id) &&
                                <>
                                    <CustomFormikTextField name="bank_name" formik={formik.IdolBank}/>
                                    <CustomFormikTextField name="bank_account_number" formik={formik.IdolBank}/>
                                    <CustomFormikTextField name="bank_routing_number" formik={formik.IdolBank}/>
                                    <MDBox display='flex' justifyContent='flex-end' mt={pxToRem(12)}>
                                        <SecondaryButton type='submit' text='SAVE' width={pxToRem(250)}
                                                         height={pxToRem(40)}
                                                         textStyles={{fontSize: pxToRem(14)}}/>
                                    </MDBox>
                                </>
                            }
                        </ProfileCard>
                    </form>
                    {/*IDOL Bank*/}

                    {/*IDOL Tax*/}
                    <form onSubmit={formik.IdolTax.handleSubmit}>
                        <ProfileCard title='IDOL Tax'
                                     isExpanded={(currentTab === tabSection.IdolTax && selectedUserId === user?.id)}
                                     onChange={(e) => handleChangeTab(e, tabSection.IdolTax)}>
                            {(currentTab === tabSection.IdolTax && selectedUserId === user?.id) &&
                                <>
                                    <CustomFormikTextField key={user.id} name="tax_social_security"
                                                           formik={formik.IdolTax}/>
                                    <DownloadDocument name={"tax_w8_w9_file"} formik={formik.IdolTax}/>
                                    <DownloadDocument name={"tax_article_of_incorporation"} formik={formik.IdolTax}/>
                                    <CustomFormikTextField key={user.id} name="tax_company_name"
                                                           formik={formik.IdolTax}/>
                                    <CustomFormikTextField key={user.id} name="tax_id" formik={formik.IdolTax}/>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                isOptionEqualToValue={(option, value) => true}
                                                name={'tax_country'}
                                                formik={formik.IdolTax}
                                                options={Countries}
                                                onChange={(e, v) => {
                                                    formik.IdolTax.setFieldValue("tax_country", v)
                                                    getRegions(v)
                                                    setCities([])
                                                    formik.IdolTax.setFieldValue("tax_state", null)
                                                    formik.IdolTax.setFieldValue("tax_city", null)
                                                }}
                                                onClear={() => {
                                                    setRegions([])
                                                    setCities([])
                                                    formik.IdolTax.setFieldValue("tax_state", null)
                                                    formik.IdolTax.setFieldValue("tax_city", null)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                isOptionEqualToValue={(option, value) => true}
                                                name={'tax_state'}
                                                formik={formik.IdolTax}
                                                options={Regions}
                                                onChange={(e, v) => {
                                                    formik.IdolTax.setFieldValue("tax_state", v)
                                                    getCities(v)
                                                    formik.IdolTax.setFieldValue("tax_city", null)
                                                }}
                                                onClear={() => {
                                                    setCities([])
                                                    formik.IdolTax.setFieldValue("tax_city", null)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomAutocompleteFormik
                                                isOptionEqualToValue={(option, value) => option.name === value}
                                                name={'tax_city'}
                                                formik={formik.IdolTax}
                                                options={Cities}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <CustomFormikTextField key={user.id} name="tax_zip_code"
                                                                   formik={formik.IdolTax}/>
                                        </Grid>
                                    </Grid>
                                    <CustomFormikTextField key={user.id} name="tax_address_line_1"
                                                           formik={formik.IdolTax}/>
                                    <CustomFormikTextField key={user.id} name="tax_address_line_2"
                                                           formik={formik.IdolTax}/>
                                    <CustomFormikTextField key={user.id} name="tax_phone_number"
                                                           formik={formik.IdolTax}/>
                                    <MDBox display='flex' justifyContent='flex-end' mt={pxToRem(12)}>
                                        <SecondaryButton type='submit' text='SAVE' width={pxToRem(250)}
                                                         height={pxToRem(40)} textStyles={{fontSize: pxToRem(14)}}/>
                                    </MDBox>
                                </>
                            }
                        </ProfileCard>
                    </form>
                    {/*IDOL Tax*/}
                </>
            )
        }
    }
