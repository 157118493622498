import {primaryButtonStyle, primaryButtonTextStyle} from "./styles";
import MDTypography from "../MDTypography";
import CircularProgress from '@mui/material/CircularProgress'
import {Button} from "@mui/material";


export const PrimaryButton = (props) => {
  const {text, onClick, size, fontWeight, buttonStyle, mainColor, textStyles, variant, loading=false, ...rest} = props
  // size = large, small, medium
  return (
    <Button
      {...rest}
      onClick={onClick}
      size={size}
      sx={[(theme) => primaryButtonStyle(theme, buttonStyle, size, mainColor, variant), { ...rest }]}
    >
      {loading
        ? <CircularProgress color={'white'} size={20} />
        : <MDTypography sx={[(theme) => primaryButtonTextStyle(theme, size, fontWeight), { ...textStyles }]}>
          {text}
        </MDTypography>
      }
    </Button>
  )
}
