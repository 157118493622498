import * as Yup from "yup";
import moment from "moment";
import { AMPMtimeToMoment, checkTimeOverlap } from "../../utils/dateUtils";

export const TIME_REGEX = `\\b((1[0-2]|0?[1-9]):(00||15||30||45) ([AaPp][Mm]))`

const checkValidFormat = (ranges)=>{
  let isValid = true
  for(const frame of ranges){
    if(!AMPMtimeToMoment(frame.from_time).isValid() || !AMPMtimeToMoment(frame.to_time).isValid()){
      isValid = false
      break
    }
  }
  return isValid
}
export const rangesSchema = Yup.object().shape({
  ranges:Yup.array()
  // .min(1, " ")
    .of(
      Yup.object().shape({
        from_time: Yup.string().required("start time cannot be empty")
          .matches(TIME_REGEX,
            'start time must be in "hh:mm AM/PM" format. \n Minutes can be only 00, 15, 30 or 45'
          ),
        to_time: Yup
          .string()
          .required("end time cannot be empty")
          .matches(TIME_REGEX,
            'end time must be in "hh:mm AM/PM" format. \n Minutes can be only 00, 15, 30 or 45'
          )
          .when('from_time', {
            is: (val)=>AMPMtimeToMoment(val).isValid(),
            then: (schema) => schema.test("is-greater", "end time should be greater", function(value) {
              const { from_time } = this.parent;
              if(!AMPMtimeToMoment(from_time).isValid()){
                return false
              }
              return moment(value, "HH:mm A").isAfter(moment(from_time, "HH:mm A"));
            })
          }),
      })
    ).test("is-overlapping", "One or more time frames is overlapping", function(ranges) {
      if(ranges.length <= 1){
        return true
      }
      if(checkValidFormat(ranges)){
        return checkTimeOverlap(ranges)
      }
     return false

    })
})

export const availabilitySchema = Yup.object().shape({
  weekdays:Yup.array()
    .of(rangesSchema)

})
