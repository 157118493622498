import {Box, Button, Input, Typography} from "@mui/material";
import {SecondaryButton} from "components/SecondaryButton";
import idolIcon from "../../../assets/icons/idolmarca.svg";
import React from "react";
import colors from "assets/theme/base/colors";

export const ReferralLink = ({codeValue, newCode}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>Share your code</Typography>
      </Box>
      <Typography sx={styles.subtitle}>Code</Typography>
      <Box sx={styles.inputContainer}>
        <Input
          sx={styles.codeInput}
          type="text"
          value={codeValue}
          readOnly
          endAdornment={
            <Button
              sx={styles.endAdornment}
              onClick={() => navigator.clipboard.writeText(codeValue)}
            >
              Copy
            </Button>
          }
        />
        <Box component="img" src={idolIcon} alt="idol-icon" sx={styles.idolIcon}/>
      </Box>
      <Box sx={styles.buttonsContainer}>
        <SecondaryButton
          onClick={() => {
            newCode()
          }}
          text="GET A NEW CODE"
          textStyles={styles.textStyles}
          buttonStyle={styles.buttonStyles}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: {xs: "100%", md: "623px", lg: "623px"},
    backgroundColor: "#192436",
    borderRadius: "10px",
    padding: {xs: "16px", lg: "24px"},
  },
  titleContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "10px",
    borderBottom: "2px solid #D9243D",
  },
  title: {
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26px",
    color: colors.grey['480'],
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "140%",
    color: "#fff",
    padding: '24px 0px 9px 0px'
  },
  inputContainer: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center',
  },
  codeInput: {
    backgroundColor: "#3F4857",
    border: "none",
    width: '100%',
    borderRadius: "5px",
    height: "40px",
    color: "#fff",
    padding: "10px 0px 10px 12px",
  },
  endAdornment: {
    textTransform: 'capitalize',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#fff',
    "&:hover": {
      color: "#D9243D",
    },
    "&:focus": {
      color: "#D9243D!important",
    },

  },
  idolIcon: {
    display: {xs: 'none', lg: 'block'}
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: {xs: "center", lg: "flex-end"},
    gap: "8px",
    paddingTop: {xs: '22px', lg: '32px'}
  },
  textStyles: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: '0.1rem'
  },
  buttonStyles: {
    width: {xs: "193px", lg: "292px"},
    height: "40px",
    backgroundColor: 'transparent',
  },
}
