import {Box} from "@mui/material";
import colors from "assets/theme/base/colors";
import {PrimaryButton} from "components/PrimaryButton";
import OrderingButton from "components/WithdrawButton/OrderingButton";
import {ErrorMessage, filteringFields, tab} from "pages/people/constants";
import {idols} from "pages/portal/dashboard/idolizer/dummyData";
import React, {useState,useEffect} from "react";
import besode3 from "../../../assets/icons/people.svg";
import avatar from "../../../assets/images/alex.png";
import {MobileSectionHeader} from "../MobileIdolDashboardSectionHeader";
import FollowersButton from "./FollowersButton";
import IdolizerCardContainer from "./IdolizerCardContainer";
import { styles } from "./styles";
import { useApi, useInfiniteScroll } from '../../../utils/hooks'
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";

const IdolizersMobileScreen = (props) => {
    const api = useApi()

    const { currentTab, setCurrentTab, actionsFn, isInfluencer } = props

    const [orderPeopleBy, setOrderPeopleBy] = useState(filteringFields.Type);
    const [orderPeopleByValue, setOrderPeopleByValue] = useState(filteringFields.Type);
    const [Followers, setFollowers] = useState([]);
    const [Idolizers, setIdolizers] = useState([]);
    const [Following, setFollowing] = useState([]);

    const [searchText, setSearchText] = useState('')

    let currentTabRecords;

    switch (currentTab) {
        case tab.Followers:
            currentTabRecords = Followers;
            break;
        case tab.Idolizers:
            currentTabRecords = Idolizers;
            break;
        case tab.Following:
            currentTabRecords = Following;
            break;
        default:
            currentTabRecords = [];
    }

    const getFollowers = (data) => {
        api.getFollowers(data)
          .then((res) => {
            if (res.kind === "ok") {
              setFollowers(
                res.data.results.map((e) => {
                  let tmp = { ...e.from_user };
                  tmp.created_at = e.created_at;
                  return tmp;
                })
              );
            } else console.log("error", res);
          })
          .catch((reason) => console.log(reason));
      };
      const getIdolizers = (data) => {
        api.getIdolizers(data)
          .then((res) => {
            if (res.kind === "ok") {
              setIdolizers(
                res.data.results.map((e) => {
                  let tmp = { ...e.from_user }
                  tmp.created_at = e.created_at
                  return tmp
                })
              );
            } else console.log("error", res);
          })
          .catch((reason) => console.log(reason));
      };
      const getFollowing = (data) => {
        api.getFollowing(data)
          .then((res) => {
            if (res.kind === "ok") {
              setFollowing(
                res.data.results.map((e) => {
                  let tmp = { ...e.to_user };
                  tmp.created_at = e.created_at;
                  return tmp;
                })
              );
            } else console.log("error", res);
          })
          .catch((reason) => console.log(reason));
      };

      const onApiCall = ({page})=>{
        let data = {page, search: searchText, ordering: orderPeopleByValue}
        return ([
          getFollowers(data),
          getIdolizers(data),
          getFollowing(data)
        ])
      }

    const orderByType = (sorted) => {
        setOrderPeopleBy(filteringFields.Type);
        setOrderPeopleByValue(sorted ? `${filteringFields.Type}` : `-${filteringFields.Type}`);
      };

      const orderByPoints = (sorted) => {
        setOrderPeopleBy(filteringFields.Points);
        setOrderPeopleByValue(sorted ? `${filteringFields.Points}` : `-${filteringFields.Points}`);
      };

      const { fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users" })

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, "200");
  }, [searchText, orderPeopleByValue])

    return (
        <Box>
            <MobileSectionHeader
            Icon={besode3}
            Title="Idolizers"
            SearchProps={{
                value: searchText,
                onChange: e => setSearchText(e.target.value)
              }}
            />
            <Box sx={styles.buttonsContainer}>
                {/*TODO: default active filter should be "followers"*/}
                <FollowersButton
                    followerIdolsNumber={Followers.length}
                    followerOrIdols="followers"
                    onClick={() => setCurrentTab(tab.Followers)}
                />
                {!isInfluencer && <FollowersButton
                  followerIdolsNumber={Idolizers.length}
                  followerOrIdols="idolizers"
                  onClick={() => setCurrentTab(tab.Idolizers)}
                />}
                {/*<FollowersButton*/}
                {/*    followerIdolsNumber={Idolizers.length}*/}
                {/*    followerOrIdols="idolizers"*/}
                {/*    onClick={() => setCurrentTab(tab.Idolizers)}*/}
                {/*/>*/}
                <FollowersButton
                    followerIdolsNumber={Following.length}
                    followerOrIdols="following"
                    onClick={() => setCurrentTab(tab.Following)}
                />
            </Box>
            <Box sx={styles.filterButtonsContainer}>
                <OrderingButton
                    size="small"
                    text="Type"
                    onClick={orderByType}
                    currentFilterSelected={orderPeopleBy === filteringFields.Type}
                />
                <OrderingButton
                    size="small"
                    text="Points"
                    onClick={orderByPoints}
                        currentFilterSelected={orderPeopleBy === filteringFields.Points}
                />
            </Box>

            <InfiniteScroll
                dataLength={[]?.length}
                // style={styles.scroll}
                next={() => {
                    if (!isFetchingNextPage) {
                        return fetchNextPage()
                    }
                }}
                hasMore={!!hasNextPage}
            >
                <Box sx={styles.idolCard}>
                    {currentTabRecords.length > 0 && currentTabRecords
                        .map((i, idx) => (
                            <IdolizerCardContainer key={`wc_${idx}`} isMobile={true}
                                avatar={avatar}
                                isVerified={i.is_validated}
                                idolPoints={i.idol_points}
                                idolizerName={i.username}
                                type={i.user_type}
                                subscriptionPrice={i.subscription_amount}
                                actionsFn={actionsFn}
                                />
                        ))}
                    {currentTabRecords.length < 1 ?? ErrorMessage}
                </Box>
            </InfiniteScroll>


        </Box>
    );
};

export default IdolizersMobileScreen;
