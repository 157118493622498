import HomeIcon from "../assets/icons/home-icon.svg";
import IdolBankIcon from "../assets/icons/wallet-idol-bank.svg";
import IdolPayIcon from "../assets/icons/idol-pay.svg";
import CalendarIcon from "../assets/icons/calendar1.svg";
import PeopleIcon from "../assets/icons/people.svg";
import ProhibitIcon from "../assets/icons/Prohibit.svg";
import ClipboardIcon from "../assets/icons/ClipboardText.svg";
import TrashIcon from "../assets/icons/trash.svg";
import GetVerifiedIcon from "../assets/icons/CheckCircle.svg";

export const idolizerDesktopMenu = {
  dashboard: {
    home: {
      noCollapse: true,
      name: 'Home',
      icon: HomeIcon,
      type: 'collapse',
    },
    wallet: {
      noCollapse: true,
      name: 'Wallet',
      icon: IdolBankIcon,
      type: 'collapse',
    },
    transactions: {
      noCollapse: true,
      name: 'Transactions',
      icon: IdolPayIcon,
      type: 'collapse',
    },
    meetings: {
      noCollapse: true,
      name: 'Meetings',
      icon: CalendarIcon,
      type: 'collapse',
    },
    idols: {
      noCollapse: true,
      name: 'Idols',
      icon: PeopleIcon,
      type: 'collapse',
    },
  },
  settings: {
    getVerified: {
      noCollapse: true,
      name: 'Get Verified',
      icon: GetVerifiedIcon,
      type: 'collapse',
    },
    blockedUsers: {
      noCollapse: true,
      name: 'Blocked Users',
      icon: ProhibitIcon,
      type: 'collapse',
    },
    termsAndConditions: {
      noCollapse: true,
      name: 'Terms & Conditions',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    privacyPolicy: {
      noCollapse: true,
      name: 'Privacy Policy',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    deactivateAccount: {
      noCollapse: true,
      name: 'Deactivate Account',
      icon: TrashIcon,
      type: 'collapse',
    },
  }
}
