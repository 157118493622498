import Login from "../pages/auth/login";
import SignUp from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/forgot-password";
import TokenPassword from "../pages/auth/token-password";
import UpdatePassword from "../pages/auth/update-password";

import {
  GetStarted,
  IdolBank,
  IdolIncorporated,
  OnboardingIdolProfile,
  OnboardingIdolTax,
  OnboardingIdolTwitter,
  OnboardingReady,
  SelectIdolType,
  UploadDocuments,
  WhoAreYou,
} from "../pages/onboarding";
import {ExplorePage} from "../pages/explore";
import {IdolProfilePage} from "../pages/idolProfile";
import Logout from "../pages/auth/logout";
import {GuestPaths} from "../paths/guestPaths";
import {GuestPrivacyPage, GuestTermsAndConditionsPage} from "../pages/termAndConditionsGuest";

export const guestRoutes = {
  login: {
    path: GuestPaths.login,
    element: <Login/>,
  },
  signup: {
    path: GuestPaths.signup,
    element: <SignUp/>,
  },
  forgotPassword: {
    path: GuestPaths.forgotPassword,
    element: <ForgotPassword/>,
  },
  tokenPassword: {
    path: GuestPaths.tokenPassword,
    element: <TokenPassword/>,
  },
  updatePassword: {
    path: GuestPaths.updatePassword,
    element: <UpdatePassword/>,
  },
  getStarted: {
    path: GuestPaths.getStarted,
    element: <GetStarted/>,
  },
  whoAreYou: {
    path: GuestPaths.whoAreYou,
    element: <WhoAreYou/>,
  },
  selectIdolType: {
    path: GuestPaths.selectIdolType,
    element: <SelectIdolType/>,
  },
  idolProfile: {
    path: GuestPaths.idolProfile,
    element: <OnboardingIdolProfile/>,
  },
  idolIncorporated: {
    path: GuestPaths.idolIncorporated,
    element: <IdolIncorporated/>,
  },
  idolBank: {
    path: GuestPaths.idolBank,
    element: <IdolBank/>,
  },
  idolTax: {
    path: GuestPaths.idolTax,
    element: <OnboardingIdolTax/>,
  },
  idolDocuments: {
    path: GuestPaths.idolDocuments,
    element: <UploadDocuments/>,
  },
  idolTwitter: {
    path: GuestPaths.idolTwitter,
    element: <OnboardingIdolTwitter/>,
  },
  idolReady: {
    path: GuestPaths.idolReady,
    element: <OnboardingReady/>,
  },
  explore: {
    path: GuestPaths.explore,
    element: <ExplorePage/>,
  },
  // root: {
  //   path: GuestPaths.getStarted,
  //   element: <GetStarted/>,
  // },
  viewIdolProfile: {
    path: GuestPaths.viewIdolProfile,
    element: <IdolProfilePage/>,
  },
  logout: {
    path: GuestPaths.logout,
    element: <Logout/>,
  },
  termsAndConditions: {
    path: GuestPaths.termsAndConditions,
    element: (<GuestTermsAndConditionsPage/>),
  },
  privacyPolicy: {
    path: GuestPaths.privacyPolicy,
    element: (<GuestPrivacyPage/>),
  },
}

