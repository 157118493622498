import {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";

// @mui components
import {CircularProgress} from "@mui/material";

// Components
import DashboardLayout from 'base/LayoutContainers/DashboardLayout';
import {BasePostCard} from 'base/Cards/PostCard';
import MDBox from 'components/MDBox';
import {IdolVerificationCard} from './components/idolCard';
import {IdolizerVerificationCard} from './components/idolizerCard';
import {PrimaryButton} from 'components/PrimaryButton';
import {SecondaryButton} from 'components/SecondaryButton';

// Utils
import {useApi} from "utils/hooks";
import {notifyError} from "services/notification";
import {UserTypes} from 'constants.js';
import {tabName} from "./constants";

// Assets
import pxToRem from 'assets/theme/functions/pxToRem';

export const AdminListVerificationPage = () => {

  const api = useApi();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [activeTab, setActiveTab] = useState(UserTypes.Idol);
  const [loading, setLoading] = useState(false);

  const getUsers = () => {
    setLoading(true);
    api.getUserProfiles("", 1, tabName[activeTab])
      .then(response => {
        if (response.kind === "ok") {
          const data = response.data?.results;
          const dataSorted = data?.sort((prev, next) => prev?.username > next?.username ? 1 : -1);
          setUsers(dataSorted);
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setLoading(false))
  }

  const navigateToUserVerification = (id) => navigate(`${id}/`);

  const TabButton = ({title, tabName}) => {
    let content = null
    if (tabName === activeTab) {
      content = (
        <PrimaryButton text={title} buttonStyle={styles.tab} onClick={() => setActiveTab(tabName)}/>
      )
    } else {
      content = (
        <SecondaryButton
          text={title}
          onClick={() => setActiveTab(tabName)}
          buttonStyle={{...styles.tab, borderBottom: "none"}}
        />
      )
    }
    return content
  }

  useEffect(() => {
    getUsers();
  }, [activeTab])
  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox my={pxToRem(34)} mx={pxToRem(102)}>
        <BasePostCard
          headerControls={[
            <MDBox sx={styles.headerControls}>
              <TabButton title="IDOLS" tabName={UserTypes.Idol}/>
              <TabButton title="IDOLIZERS" tabName={UserTypes.Idolizer}/>
            </MDBox>
          ]}
          {...{
            title: 'VERIFICATION',
            cardStyles: styles.card,
            containerStyles: styles.container,
            titleContainerStyles: styles.title_container,
            titleStyles: styles.title,
            headerControlsStyle: styles.controls
          }}
        >
          <MDBox display='flex' flexDirection='column' gap={pxToRem(14)} mt={pxToRem(14)} minHeight={pxToRem(50)}
                 maxHeight={pxToRem(570)} pr={pxToRem(25)} overflow='hidden auto'>
            {loading
              ? <MDBox display='flex' justifyContent='center'><CircularProgress /></MDBox>
              : users.map(user => activeTab === UserTypes.Idol
                ? <IdolVerificationCard key={user.id} {...{user, navigateToUserVerification}} />
                : <IdolizerVerificationCard key={user.id} {...{user, navigateToUserVerification}} />
            )}
          </MDBox>
        </BasePostCard>
      </MDBox>
    </DashboardLayout>
  );
}

const styles = {
  card: {
    padding: `${pxToRem(14)} ${pxToRem(24)}`,
  },
  container: {
    alignItems: "flex-end",
  },
  title_container: {
    alignItems: "flex-end",
    height: pxToRem(56),
  },
  title: {
    fontSize: pxToRem(48),
    fontWeight: 100,
  },
  controls: {
    maxHeight: pxToRem(100),
  },
  headerControls: {
    display: "flex",
    flexDirection: "row",
    width: pxToRem(500),
    gap: pxToRem(15),
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: `${pxToRem(65)}`,
    marginRight: pxToRem(50),
  },
  tab: {
    height: "65px !important",
    borderRadius: "10px 10px 0px 0px",
    fontSize: "14px"
  },
};
