import React, {memo, useEffect} from "react";
import {Box} from "@mui/material";
import {useIsMobile} from "../../utils/hooks";
import {IdolProfileMobile} from "./mobile";
import {IdolProfileDesktop} from "./desktop";
import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import {useStores} from "../../models";
import {NavigationMenu} from "../../constants";


export const IdolProfilePage = memo((props) => {
  const isMobile = useIsMobile()
  const {loginStore} = useStores();

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.PROFILE);
    loginStore.setMobileActiveRoute("PROFILE")
  }, [])
  return (
    <BaseProtectedComponentPage loginRequired={false}>
      {isMobile ?
        <IdolProfileMobile/>
        :
        <IdolProfileDesktop/>
      }
    </BaseProtectedComponentPage>
  )
})


