import colors from "../../assets/theme/base/colors";
import rgba from "../../assets/theme/functions/rgba";

const commonStyles = {
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  subtitle:{
    fontSize:12,
    width:"100%",
    color: colors.white.main,
    letterSpacing:"0.1em"
  },

  mainContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    paddingLeft:"5%",
    paddingRight:"5%",
  },
  buttonsContainer:{
    gap:"20px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-end",
    alignItems:"center"
  },
  reportButton:{
    width:"300px",
    height: "40px",
    letterSpacing: "0.1em"
  },

}

export const stylesMobile = {
  ...commonStyles,
  reportButtonText:{
    fontSize: 12,
    lineSpacing:"0.1em"
  },
  title:{
    fontSize:16,
    fontWeight:400,
    color: colors.white.main,
    marginLeft:"20%",
    textDecoration:"uppercase"
  },
  titleContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center",
    marginBottom:"34px"
  },
  backImageContainer:{
    width:"40px"
  },
  backImage:{
    cursor:"pointer",
    left:"15px",
    // top:"10px",
    marginTop:"-10px",
    marginLeft: "20px",
    width:"7px",
    height:"13px",
  },
  cancelText:{
    fontSize:12,

    color: `${rgba(colors.white.main, 0.5)}`,
    "&:hover": {
      color: `${rgba(colors.white.main, 0.5)}`
    },
  },
  cancelButton:{
    width:"145px",
    height: "40px",
    border:`2px solid ${rgba(colors.white.main, 0.5)}`,
    "&:hover": {
      border:`2px solid ${rgba(colors.white.main, 0.5)}`,
    }
  },
}
export const stylesDesktop = {
  ...commonStyles,
  cancelText:{
    fontSize:14,
    color: `${rgba(colors.white.main, 0.5)}`,
    "&:hover": {
      color: `${rgba(colors.white.main, 0.5)}`
    },
  },
  cancelButton:{
    width:"145px",
    height: "40px",
    border:`2px solid ${rgba(colors.white.main, 0.5)}`,
    "&:hover": {
      border:`2px solid ${rgba(colors.white.main, 0.5)}`,
    }
  },

  titleContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center"
  },

  title:{
    fontSize:36,
    fontWeight:300,
    width:"100%",
    color: colors.white.main,
    marginLeft:"20px",
    textDecoration:"uppercase"
  },

  backImage:{
    cursor:"pointer",
    marginLeft: "20px",
    width:"10px",
    height:"20px",
  },
  reportButtonText:{
    fontSize: 14,
    fontWeight:700,
    lineSpacing:"0.1em"
  }
}
