/* dependencies */
import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {MobileDatePriceEvent} from 'components/Mobile/DatePriceEventTrackerMobile';
import {CustomTextField} from 'components/TextField';
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import world from 'assets/images/map.png';
import {styles} from './styles';
import form from 'layouts/pages/users/new-user/schemas/form';

export const MobileLocationDetailsModal = ({
  open, 
  onClose,
  idolizerName,
  idolPoints,
  avatar,
  isVerified,
  date,
  timeStart,
  timeEnd,
  timeZone,
  price,
  cancelAction,
  meetingType,
}) => {
  

  const formik = useFormik({
    initialValues: {
        location: '',
        additional_comments: '',
    },
    validationSchema: Yup.object().shape({
        location: Yup.string().required('The meeting location is required'),
        additional_comments: Yup.string(),
    }),
    onSubmit: (values) => [console.log(values)],
  })

  const onCloseModal = () => {
    formik.resetForm();
    onClose();
  }

  const modalProps = {
      open, 
      onClose: onCloseModal,
      title: 'LOCATION DETAILS',
      containerStyles: styles.modal_content_container,
      ...styles.content_container
  };

  const cardInfoProps = {
    idolizerName,
    idolPoints,
    avatar,
    isVerified,
    isMobile: true,
    nameStyles: styles.name,
    pointStyles: styles.points,
    idpStyles: styles.points,
  };

  const timeProps = {
    date,
    timeStart,
    timeEnd,
  };

  return (
    <BaseModal {...modalProps}>
      <Box sx={styles.idol_info_container}>
        <BaseCardIdolInfo {...cardInfoProps} />
        <Typography sx={styles.event_type}>{meetingType}</Typography>
      </Box>
      <Box sx={styles.time_container}>
        <MobileDatePriceEvent {...timeProps} />
      </Box>
      <Box sx={styles.timezone_price_container}>
        <Box sx={styles.timezone_container}>
          <img src={world} alt="world icon" title="world" style={styles.world_icon} />
          <Typography sx={styles.timezone} variant="subtitle2">
            {timeZone}
          </Typography>
        </Box>
        <Typography sx={styles.price}>{price}</Typography>
      </Box>
      <Box sx={styles.inputs_container}>
        <CustomTextField 
          inputLabel='Details'
          placeholder='Location'
          name='location'
          onChange={formik.handleChange}
          value={formik.values.location}
          error={formik.errors.location && formik.touched.location}
          helperText={formik.touched.location && formik.errors.location}
          errorTextStyles={styles.error}
          labelStyle={styles.inputLabel}
        />
        <CustomTextField 
          placeholder='Additional comment (e.g. table/seat, dress code)'
          multiline
          rows={4}
          inputBase={false}
          name='additional_comments'
          onChange={formik.handleChange}
          value={formik.values.additional_comments}
        />
      </Box>
      <Typography sx={styles.small_message}>
        Location details will be shared 72 hours before the event. 
        This information is private to your idolizer
      </Typography>
      <Box sx={styles.action_container}>
        <SecondaryButton 
            text='SAVE LOCATION'
            textStyles={styles.action_button_text}
            onClick={() => [formik.handleSubmit(), onClose()]}
            disabled={formik.errors.location || formik.values.location === ''}
            {...styles.action_button} 
        />
      </Box>
    </BaseModal>
  );
}
