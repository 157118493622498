import OrderingButton from "../../components/WithdrawButton/OrderingButton";
import {ErrorMessage, filteringFields, tab} from "./constants";
import {BasePostCard} from "../../base/Cards/PostCard";
import PeopleIcon from "../../assets/icons/people.svg";
import {
  IdolFollowersRecord, IdolFollowingRecord,
  IdolIdolizersRecord,
  IdolTransactionDetailedRecord
} from "../../components/RecordCard/RecordCard";
import {Card, Typography, Box} from "@mui/material";
import {getButtonStyles, getCardHeaderStyles, getLabelStyles, getNumberStyles, getScrollStyles} from "./styles";
import React, {useState, useStores, useEffect} from "react";
import {BaseCardIdolInfo} from "../../base/Cards/InfoCards/IdolizerInfoCard";
import {MoreVert as ThreeDotsIcon} from "@mui/icons-material";
import {IdolsActions} from "../portal/dashboard/idolizer/idols/actions";

import {SearchBox} from "components/SearchBox";
import { useApi, useInfiniteScroll } from "../../utils/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";

export function PeopleSelectorButton(props) {
  const {selected, label, onClick, value} = props

  return <Card sx={getButtonStyles} onClick={onClick}>
    <Typography sx={theme => getNumberStyles(theme, selected)}>{value}</Typography>
    <Typography sx={theme => getLabelStyles(theme, selected)}>{label.toLowerCase()}</Typography>
  </Card>
}

export function PeopleIdol(props) {
  const {currentTab, setCurrentTab, actionsFn, isInfluencer } = props
  // const {Followers, Idolizers, Following} = records
  // let currentTabRecords;
  // console.log('actionsFn: ', actionsFn);
  const api = useApi()

  const [orderPeopleBy, setOrderPeopleBy] = useState(filteringFields.Type);
  const [orderPeopleByValue, setOrderPeopleByValue] = useState(filteringFields.Type);
  const [Followers, setFollowers] = useState([]);
  const [Idolizers, setIdolizers] = useState([]);
  const [Following, setFollowing] = useState([]);
  const [searchText, setSearchText] = useState('');

  const getFollowers = (data) => {
    api.getFollowers(data)
      .then((res) => {
        if (res.kind === "ok") {
          setFollowers(
            res.data.results.map((e) => {
              let tmp = { ...e.from_user };
              tmp.created_at = e.created_at;
              return tmp;
            })
          );
        } else console.log("error", res);
      })
      .catch((reason) => console.log(reason));
  };
  const getIdolizers = (data) => {
    api.getIdolizers(data)
      .then((res) => {
        if (res.kind === "ok") {
          setIdolizers(
            res.data.results.map((e) => {
              let tmp = { ...e.from_user }
              tmp.created_at = e.created_at
              return tmp
            })
          );
        } else console.log("error", res);
      })
      .catch((reason) => console.log(reason));
  };
  const getFollowing = (data) => {
    api.getFollowing(data)
      .then((res) => {
        if (res.kind === "ok") {
          setFollowing(
            res.data.results.map((e) => {
              let tmp = { ...e.to_user };
              tmp.created_at = e.created_at;
              return tmp;
            })
          );
        } else console.log("error", res);
      })
      .catch((reason) => console.log(reason));
  };

  const onApiCall = ({page})=>{
    let data = {page, search: searchText, ordering: orderPeopleByValue}
    return ([
      getFollowers(data),
      getIdolizers(data),
      getFollowing(data)
    ])
  }

  const orderByType = (sorted) => {
    setOrderPeopleBy(filteringFields.Type);
    setOrderPeopleByValue(sorted ? `${filteringFields.Type}` : `-${filteringFields.Type}`);
  };

  const orderByPoints = (sorted) => {
    setOrderPeopleBy(filteringFields.Points);
    setOrderPeopleByValue(sorted ? `${filteringFields.Points}` : `-${filteringFields.Points}`);
  };

  const { fetchNextPage, hasNextPage, isFetchingNextPage, refetch } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users" })

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, "200");
  }, [searchText, orderPeopleByValue])

  return (
    <BasePostCard title={tab.Idolizers}
                  headerControls={<>
                    <SearchBox placeholder={"I am looking for..."}
                      inputStyles={{ width: "300px", fontSize: 14, color: "white" }}
                      InputProps={{ maxLength: 10 }}
                      value={searchText}
                      onChange={e => setSearchText(e.target.value)}/>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "240px",
                      gap: "25px",
                      justifyContent: "flex-end"
                    }}>
                      <OrderingButton
                        text='Type'
                        onClick={orderByType}
                        currentFilterSelected={orderPeopleBy === filteringFields.Type}
                      />
                      <OrderingButton
                        text='Points'
                        onClick={orderByPoints}
                        currentFilterSelected={orderPeopleBy === filteringFields.Points}
                      />
                    </Box>
                  </>}
                  icon={PeopleIcon}
    >
      <Card sx={getCardHeaderStyles}>
        <PeopleSelectorButton
          selected={currentTab === tab.Followers}
          label={tab.Followers}
          onClick={() => setCurrentTab(tab.Followers)}
          value={Followers.length}
        />
        {!isInfluencer && <PeopleSelectorButton
          selected={currentTab === tab.Idolizers}
          label={tab.Idolizers}
          onClick={() => setCurrentTab(tab.Idolizers)}
          value={Idolizers.length}
        />}
        <PeopleSelectorButton
          selected={currentTab === tab.Following}
          label={tab.Following}
          onClick={() => setCurrentTab(tab.Following)}
          value={Following.length}
        />
      </Card>


      <InfiniteScroll
        dataLength={[]?.length}
        style={getScrollStyles}
        next={() => {
          if (!isFetchingNextPage) {
            return fetchNextPage()
          }
        }}
        hasMore={!!hasNextPage}
      >
        <Grid container spacing={2}>
          {currentTab === tab.Followers && Followers
            .map((record, idx) => (
              <IdolFollowersRecord key={`wc_${idx}`} record={record} actionsFn={actionsFn} />
            ))}

          {currentTab === tab.Idolizers && Idolizers
            .map((record, idx) => (
              <IdolIdolizersRecord key={`wc_${idx}`} record={record} actionsFn={actionsFn} />
            ))}

          {currentTab === tab.Following && Following
            .map((record, idx) => (
              <IdolFollowingRecord key={`wc_${idx}`} record={record} actionsFn={actionsFn} />
            ))}

          {[...Followers, ...Idolizers, ...Following].length === 0 ?? ErrorMessage}

        </Grid>
      </InfiniteScroll>


    </BasePostCard>
  )
}

export function PeopleIdolizer(props) {
  const {orderByAmount, orderByDate, orderRecordsBy, records} = props
  return (
    <BasePostCard title='Transactions'
                  icon={PeopleIcon}
                  headerControls={[
                    <OrderingButton
                      text='Amount'
                      onClick={orderByAmount}
                      currentFilterSelected={orderRecordsBy === filteringFields.Amount}
                    />,
                    <OrderingButton
                      text='Date'
                      onClick={orderByDate}
                      currentFilterSelected={orderRecordsBy === filteringFields.Date}
                    />
                  ]}>
      {records.map((record, idx) => <IdolTransactionDetailedRecord key={`wc_${idx}`} record={record}/>)}
    </BasePostCard>
  )
}
