import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import {Box} from "@mui/system";
import {useStores} from "../../models";
import {useEffect} from "react";
import { useIsMobile } from "../../utils/hooks";
import { ExploreMobilePage } from "./mobile";
import { ExploreDesktop } from "./desktop";
import {NavigationMenu} from "../../constants";
import ExploreProvider from "./mobile/ExploreProvider";

export const ExplorePage = () => {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const isMobile = useIsMobile()


  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.EXPLORE);
    loginStore.setMobileActiveRoute("EXPLORE")
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>

      {isMobile? (
      <ExploreProvider>
        <ExploreMobilePage />
      </ExploreProvider>
      ) : <ExploreDesktop />}

    </BaseProtectedComponentPage>
  )
}

