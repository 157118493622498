import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {TermsNConditions} from "./TermsNConditions";
import {useStores} from "../../../models";
import {useEffect} from "react";
import {NavigationMenu} from "../../../constants";
import {Box} from "@mui/material";

export const TermsNConditionsPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("TERMS & CONDITIONS")
  }, []);

  if (currentNavMenu === NavigationMenu.EXPLORE) {
    return (
      <Box padding={'10%'}>
        <TermsNConditions/>
      </Box>
    )
  }

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        <TermsNConditions codeValue={'DR2049813'}/>
      </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
