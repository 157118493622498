import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {IdolsPage} from 'pages/portal/dashboard/idolizer/idols';
import {idols} from 'pages/portal/dashboard/idolizer/dummyData'
import { useApi, useIsMobile , useInfiniteScroll} from "../../../../../utils/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import {MobileSectionHeader} from "components/Mobile/MobileIdolDashboardSectionHeader";
import {Box} from "@mui/material";
import {styles} from "./styles";
import {PrimaryButton} from "components/PrimaryButton";
import colors from "assets/theme/base/colors";
import IdolizerCardContainer from "components/Mobile/IdolizersMobileScreen/IdolizerCardContainer";
import {SearchBox} from "components/SearchBox";
import {useStores} from "models";
import { useEffect, useState, useReducer } from "react";
import {NavigationMenu} from "constants";
import {observer} from "mobx-react";
import { notifyError } from "services/notification";
import { initialRelatedStateIdolizer, relatedFilter, relatedFilterReducer } from "../../reducers";

export const IdolsListPage = observer(() => {
  const isMobile = useIsMobile()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [profile, setProfile] = useState(null);
  const [filter, dispatchFilter] = useReducer(relatedFilterReducer, initialRelatedStateIdolizer);
  const [searchText, setSearchText] = useState('')
  const api = useApi()

  const getProfileDetail = () => {
    api.getProfileDetail(loginStore.id).then(response => {
      if (response.kind === "ok") {
        setProfile(response.data)

      } else {
        notifyError('There is something wrong!')
      }
    }).catch(reason => {
      notifyError('There is something wrong!')
      console.log(reason)
    })
  }
    
  const onApiCall = ({page})=>{
    let data = {page, relation: filter.idolizing? "idolizing":"following", search: searchText}
    return api.getRelatedUsers(data)
  }

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, "100");
  }, [searchText])
  
  const getRelationshipType = (user) => {
    const {follower, following, idolizer, idolizing} = user;
    const types = [follower, following, idolizer, idolizing];
    const typesStr = ["follower", "following", "idolizer", "idolizing"];
    const typeIndex = types.findIndex((t, i)=> t === true);
    return typesStr[typeIndex];
  }

  const {
    items,
    fetchNextPage,
    extraData,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users", filters:filter});

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("IDOLS");
    getProfileDetail();
  }, []);  

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {isMobile ?
          <Box>
            <MobileSectionHeader
              Title="Idols"
              SearchProps={{
                value: searchText,
                onChange: e => setSearchText(e.target.value)
              }}
            />
            <Box sx={styles.filterButtonsContainer}>
              <PrimaryButton
                buttonStyle={styles.buttonFollowing}
                textStyles={styles.buttonText}
                mainColor={colors.tertiary}
                size={"small"}
                text={`FOLLOWING ${profile?.number_of_following}`}
                onClick={() => dispatchFilter({type: relatedFilter.following})}
              />
              <PrimaryButton
                buttonStyle={styles.buttonIdolizing}
                textStyles={styles.buttonText}
                mainColor={colors.tertiary}
                size={"small"}
                text={`IDOLIZING ${profile?.number_of_subscribed}`}
                onClick={() => dispatchFilter({type: relatedFilter.idolizing})}
              />
            </Box>
            <InfiniteScroll  
              dataLength={items?.length}
              style={styles.scroll}
              next={() => {
                if (!isFetchingNextPage) {
                  return fetchNextPage()
                }
              }}
              hasMore={!!hasNextPage}>
                  <Box sx={styles.idolCard}>
                    {items.map((i) => (
                      <IdolizerCardContainer
                        key={i.id}
                        isMobile={true}
                        avatar={i.image}
                        isVerified={i.isVerified}
                        idolPoints={i.idolPoints}
                        idolizerName={i.idolizerName}
                        type={getRelationshipType(i)}
                        subscriptionPrice={i.subscriptionPrice}
                      />
                    ))}
                  </Box>
              </InfiniteScroll>            
          </Box>
          :
          profile &&
            <IdolsPage profile={profile} />
        }
      </DashboardLayout>
    </BaseProtectedComponentPage>
  )
})

