import { OnboardingLayout } from "components/OnboardingLayout";
import MDBox from "components/MDBox";
import { PrimaryButton } from "components/PrimaryButton";
import { styles } from "./styles";
import { useOnboardingStore } from "../../../utils/hooks";
import { useFormik } from "formik";
import { useEffect } from "react";
import { CustomFormikTextField } from "../../../components/TextField";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Stack, useMediaQuery } from "@mui/material";
import {
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
} from "../../../models";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { GuestPaths } from "../../../paths/guestPaths";

const subtitle = "Banking information is important because it allows us to securely and reliably process payments for digital content, video calls and in-person events. Additionally, it helps us keep track of transactions and maintain records for tax purposes.";

export const IdolBank = () => {
  const onboardingStore = useOnboardingStore()
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolBank)

  const submitValues = (values) => {
    onboardingStore.setData({...values})
    navigate(NextPage);
  }

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(OnboardingPhases.Bank, onboardingStore),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Bank, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage)
  }, [])

  return (
    <OnboardingLayout
      titleBold='IDOL'
      titleNormal='BANK'
      progressBarPercent={"60%"}
      subtitle={subtitle}
      backTo={PreviousPage}
      ml={6}
    >
      <MDBox sx={{display: 'flex', flexDirection: 'column', gap: 5}}>
        <MDBox sx={{...styles.inputContainer, marginTop: isMobile ? 3 : 10}}>
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={pxToRem(14)}>
              <CustomFormikTextField name="bank_name" formik={formik}/>
              <CustomFormikTextField name="bank_account_number" formik={formik}/>
              <CustomFormikTextField name="bank_routing_number" formik={formik}/>
            </Stack>
          </form>
        </MDBox>

        {isMobile ?
          isMobileH ?
            <MDBox sx={{position: "absolute", right: 0, left: 0, bottom: 20, paddingX: "30px"}}>
              <PrimaryButton
                text={"NEXT"}
                onClick={formik.handleSubmit}
              />
            </MDBox>
            :
            <MDBox mb={4} mt={4}>
              <PrimaryButton
                text={"NEXT"}
                onClick={formik.handleSubmit}
              />
            </MDBox>
          :
          <MDBox mb={4} mt={4}>
            <PrimaryButton
              text={"NEXT"}
              onClick={formik.handleSubmit}
            />
          </MDBox>
        }

      </MDBox>
    </OnboardingLayout>
  )
}

