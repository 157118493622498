import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {ReferralLink} from "./ReferralLink";
import {useStores} from "../../../models";
import {useEffect, useState} from "react";
import {useApi} from "../../../utils/hooks";

export const ReferralLinkPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const [codeValue, setCodeValue] = useState()

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("REFERRAL LINK")
    createReferralCode()
  }, []);


  const createReferralCode = () => {
    api.createReferralCode()
      .then(res => {
        if (res.kind === "ok") {
          loginStore.setReferralCode(res.response.code)
          setCodeValue(res.response.code)
        }

      })
      .catch(reason => console.log(reason))
      .finally(() => {

      })
  }


  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        <ReferralLink codeValue={codeValue} newCode={createReferralCode}/>
      </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
