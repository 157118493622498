import {Stack} from "@mui/material";
import {GenericModal} from "../../components/GenericModal";
import {Box} from "@mui/system";
import {useEffect} from "react";

export const Redirecting = () => {
  /**
   * This is here for a very specific reason:
   * We're currently serving two fronts in the same backend, for this to work properly the browser has to
   * reload the page for it to be able to call for the other frontend, otherwise it just hangs there...
   *
   * If you come up with a better solution, please be my guest to fix this
   *
   **/
  const reload = () => {
    window.location.reload();
  }

  useEffect(() => {
    reload()
  }, [])

  return <Stack width={'100vw'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
    <GenericModal title='Redirecting...' onClose={() => 0} open={true}>
      <Stack alignItems={'center'} justifyContent={'center'} width={'100%'}>
        <Box>Please wait</Box>
      </Stack>
    </GenericModal>
  </Stack>
}
