import {Box, Modal, Typography} from "@mui/material";
import React, {memo, useState} from "react";
import calendarIcon from "../../assets/icons/calendar_thin.svg";
import dollarIcon from "../../assets/icons/dollar_white_thin.svg";
import editIcon from "../../assets/icons/edit.svg";
import trashIcon from "../../assets/icons/rounded_trash.svg"
import CloseIcon from "../../assets/icons/X.svg";
import lockedPost from "../../assets/images/lockedPost.png";
import VideoPlayer from "../VideoPlayer";
import {styles} from "./styles"
import numeral from "numeral";
import {useUserStore} from "../../utils/hooks";
import {useParams} from "react-router-dom";
import {IdolTypeEnum} from "utils/constants";


export const SpecificPostModal = memo(({
                                         isExplorePage,
                                         profile,
                                         content,
                                         openPost,
                                         handleClose,
                                         handleConfirmPaymentModal,
                                         unlockPost,
                                         goToMeetMe,
                                         goToEditPost,
                                         deletePost,
                                         isMobile
                                       }) => {
  const [loading, setLoading] = useState(true)
  const userStore = useUserStore()
  const myProfileId = userStore.idol_id_name
  const {idol_id_name} = useParams();
  const isMyProfile = idol_id_name === myProfileId
  const isMyPost = myProfileId === content.user.idol_id_name

  if (!content) {
    return null;
  }

  const closeModal = (
    event,
    reason
  ) => {
    if (reason === "backdropClick") {
      console.log(reason);
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleBackdropClick = (event) => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  return (
    <React.Fragment>
      <Modal open={openPost} onClose={closeModal}>
        <Box>
          <Box component="img" onClick={handleClose} src={CloseIcon} sx={styles.xIcon}/>
          <Box sx={isMobile ? {...styles.containerMobile} : {...styles.container}}>
            <Box sx={styles.content}>
              {content.isVideo && !content.isLocked
                ? <VideoPlayer mediaURL={content.idolPost} onLoadedData={() => setLoading(false)}/>
                : (
                  <Box
                    component={"img"}
                    src={content.isLocked ? lockedPost : content.idolPost}
                    alt="idol1"
                    onLoad={() => {
                      setLoading(false)
                    }}
                    sx={isMobile ? {...styles.mainImgMobile} : {...styles.mainImg}}
                  />
                )}
            </Box>
            {!content.isLocked && !loading && (
              <>
                <Box sx={styles.buttonsContainer}>
                  {isExplorePage ?
                    isMyPost
                      ? <></>
                      :
                      <>
                        {content?.user?.idol_type !== IdolTypeEnum.influencer && (
                          <>
                          <Box component="img" onClick={handleConfirmPaymentModal} src={dollarIcon} sx={styles.button}/>
                          <Box component="img" onClick={goToMeetMe} src={calendarIcon} sx={styles.button}/>
                          </>
                        )}
                      </>
                    : isMyProfile
                      ? (
                        <>
                          <Box component="img" onClick={goToEditPost} src={editIcon} sx={styles.button}/>
                          <Box component="img" onClick={deletePost} src={trashIcon} sx={styles.button}/>
                        </>
                      )
                      : (
                        (content?.user?.idol_type !== IdolTypeEnum.influencer) &&
                        <>
                          <Box component="img" onClick={handleConfirmPaymentModal} src={dollarIcon} sx={styles.button}/>
                          <Box component="img" onClick={goToMeetMe} src={calendarIcon} sx={styles.button}/>
                        </>

                      )
                  }
                </Box>
              </>
            )}
            <Box
              sx={
                !content.isLocked
                  ? styles.descriptionContainer
                  : {...styles.descriptionContainer, padding: "30px"} // Estilos para las imagenes bloqueadas
              }
            >
              {!loading && (
                <Typography sx={styles.descriptionText}>{content.description}</Typography>
              )}

              {content.isLocked && ( // Boton para las imagenes bloqueadas
                <Typography sx={styles.unlockButton} onClick={unlockPost}>
                  UNLOCK FOR ${numeral(content.additional_price).format("0.00")}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
});
