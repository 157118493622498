import {useSnackbar} from 'notistack'
import * as React from 'react'
import {autoHideSnackbarDuration} from "../constants";


const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp
}

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}/>
  )
}
export const notifyToast = (message, variant = 'default', options = {autoHideDuration: autoHideSnackbarDuration}) => {
  useSnackbarRef.enqueueSnackbar(message,
    {
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }, ...options
    })
}

export const notifySuccess = (message, options = {autoHideDuration: autoHideSnackbarDuration}) => {
  notifyToast(message, 'success', options)
}

export const notifyError = (message = 'An error occurred, please try again later', options = {autoHideDuration: autoHideSnackbarDuration}) => {
  notifyToast(message, 'error', options)
}
export const notifyWarning = (message, options={})=>{
  notifyToast(message, 'warning', options)
}
