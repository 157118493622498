import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import MeetingModal from "components/MeetingModal/MeetingModal";
import { UpcomingMeetings } from "components/UpcomingMeetsCard";
import React, { useEffect, useState } from "react";
import { useApi, useIsMobile, useInfiniteScroll } from "../../../utils/hooks";
import { Box } from "@mui/system";
import { useStores } from "../../../models";
import { NavigationMenu } from "../../../constants";
import {observer} from "mobx-react";
import { UpcomingMeetsCardMobile } from "./UpcomingMeetsCardMobile"

export const Meetings = observer(() => {
  const [modalManager, setModalManager] = useState({
    base: false,
    createEvent: false,
    cancelMeeting: false,
    locationDetails: false,
    confirmPayment: false,
  });
  const {base, createEvent, cancelMeeting, locationDetails, confirmPayment} = modalManager;
  const api = useApi()
  const isMobile = useIsMobile()
  const rootStore = useStores()
  const {loginStore} = rootStore

  const getUpcomingIdolizer = ({page})=>{
    let data = {page}
    return api.getUpcomingMeetings(data)
  }

  const {
    items,
    fetchNextPage,
    extraData,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:getUpcomingIdolizer});


  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("MEETINGS");
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {isMobile ?
          <Box>
            <UpcomingMeetsCardMobile></UpcomingMeetsCardMobile>
          </Box>
          :
          <Box>
            <UpcomingMeetings
              title="Meetings"
              upcomingMeetings={items}
              onLaunch={() => setModalManager({...modalManager, confirmPayment: true})}
              onDismiss={() => setModalManager({...modalManager, confirmPayment: true})}
            />
            <MeetingModal
              open={confirmPayment}
              onClose={() => setModalManager({...modalManager, confirmPayment: false})}
              title='BROOKLYN В | IDOLMEET'
              meetingPlace='San Diego, CA, Idolmeet'
              meetingAmount='8,555'
              meetingDate='07 JUNE 2023'
              meetingHourStart='09:00 PM'
              meetingHourEnd='11:00 PM'

            />
          </Box>
        }
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
});
