import React, {useState} from 'react';
import {useFormik} from 'formik';

/* MUI components */
import {Box, Typography} from '@mui/material';
import Grid from "@mui/material/Grid";

/* components */
import {BasicCheckbox} from 'components/BasicCheckbox';
import {CustomTextField} from 'components/TextField';
import Select from 'components/CustomSelect';

/* assets */
import checkbox from "assets/icons/checkbox.svg";
import edit from 'assets/icons/edit-description.svg';
import confirm from 'assets/icons/check.svg';
import checked from "assets/icons/check cuadrado.svg";
import {styles} from './styles';

export const ImportContent = ({content, setCheckedContents, checkedContents, formik, baseFormikName, index}) => {
  const key = index
  const [fieldsEditable, setFieldsEditable] = useState({editDescription: false, editPrice: false});
  const {editDescription, editPrice} = fieldsEditable;

  const selectedItem = formik.values.import_posts[key]
  selectedItem.post_type = selectedItem?.post_type?.id !== undefined ? selectedItem?.post_type.id : selectedItem?.post_type
  const tierOptions = [
    {value: 1, label: 'Unlocked'},
    {value: 2, label: 'Locked'},
    {value: 3, label: 'Pay per view'},
  ];

  const updateCheckedContent = (e, field) => {
    let value
    if (field === 'post_type' || field === 'additional_price') {
      value = Number(e.target.value)
    } else {
      value = e.target.value
    }
    const contentAlreadyChecked = checkedContents.find(c => c.id === content.id);
    if (!contentAlreadyChecked) {
      return formik.setFieldValue(`${baseFormikName}[${key}].${field}`, value)
    }
    setCheckedContents(prev => {
      const checkedContent = prev.findIndex(p => p.id === content.id);
      prev[checkedContent][field] = e.target.value;
      return prev.sort((a, b) => a.id > b.id ? 1 : 0);
    })
    formik.setFieldValue(`${baseFormikName}[${key}].${field}`, value);
  }

  return (
    <Box sx={styles.container}>
      <Grid container sx={[styles.fields_titles_container, styles.field_container]}>
        <Grid item lg={2} sx={{...styles.field_box, justifyContent: 'flex-start'}}>
          <BasicCheckbox
            borderNone={true}
            checkedIcon={checked}
            onClick={(e) => {
              formik.setFieldValue(`${baseFormikName}[${key}].checked`, e.target.checked)
            }}
            checkboxStyle={{ml: 0}}
          />
        </Grid>


        <Grid item lg={1} sx={{...styles.field_box, justifyContent: 'flex-start'}}>
          {selectedItem?.post_category === 1 &&
            <img style={styles.field_image} src={selectedItem?.file}/>
          }
          {selectedItem?.post_category === 2 &&
            <video style={styles.field_image}>
              <source src={selectedItem?.thumbnail} type="video/mp4"/>
            </video>
          }
        </Grid>

        {!editDescription ? (
          <Grid item lg={5} sx={styles.field_box}>
            <div style={styles.icon_edit}></div>
            <Typography sx={styles.description}>{selectedItem?.description}</Typography>
            <img
              src={edit}
              style={styles.icon_edit}
              onClick={() => setFieldsEditable({...fieldsEditable, editDescription: true})}
            />
          </Grid>
        ) : (
          <Grid item lg={5} sx={styles.field_box}>
            <div/>
            <div style={styles.icon_check}></div>
            <CustomTextField
              sx={styles.input}
              formStyles={styles.input_container}
              name="description"
              value={selectedItem?.description}
              onChange={(e) => {
                updateCheckedContent(e, "description")
              }}
              inputBase={false}
              multiline
              rows={3}
            />
            <img
              src={confirm}
              style={styles.icon_check}
              onClick={() => setFieldsEditable({...fieldsEditable, editDescription: false})}
            />
          </Grid>
        )}


        <Grid item lg={2} sx={styles.field_box}>
          <div/>
          <Select
            selectstyles={styles.tier}
            fullWidth={false}
            options={tierOptions}
            defaultValue={selectedItem?.post_type}
            name="post_type"
            onChange={(e) => updateCheckedContent(e, "post_type")}
          />
        </Grid>


        <Grid item lg={2} sx={[styles.field_box, styles.field_box_price]}>
          {selectedItem?.post_type === 3 && (
            !editPrice ? (
              <>
                <div style={styles.icon_edit}/>
                <Typography sx={styles.price}>
                  $ {selectedItem?.additional_price !== '' ? selectedItem?.additional_price : 0}
                </Typography>
                <img
                  src={edit}
                  style={styles.icon_edit}
                  onClick={() => setFieldsEditable({...fieldsEditable, editPrice: true})}
                />
              </>
            ) : (
              <>
                <div style={styles.icon_check}/>
                <CustomTextField
                  sx={styles.input_price}
                  type="number"
                  onChange={(e) => updateCheckedContent(e, "additional_price")}
                  name="additional_price"
                  value={selectedItem?.additional_price}
                  formStyles={{width: "100px"}}
                  startAdornment={<Typography>$</Typography>}
                />
                <img
                  src={confirm}
                  style={styles.icon_check}
                  onClick={() => [setFieldsEditable({...fieldsEditable, editPrice: false})]}
                />
              </>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
