import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
const {card} = colors;

export const styles = {
    card: {
        background: card.header,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    buttonContainer: {
        width: '120px',
        paddingRight: '20px'
    },
    name: {
        fontSize: pxToRem(14),
        fontWeight: 400,
    },
    points: {
        fontSize: pxToRem(14),
        fontWeight: 500,
    },
};