import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';

/* components */
import {BaseModal} from "base/BaseModal";
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import {desktopStyles, mobileStyles} from './styles';
import { useIsMobile } from "../../utils/hooks";

export const SimpleConfirmModal = ({
                                     open,
                                     onClose,
                                     title,
                                     onConfirm,
                                     message,
                                     confirmLabel,
                                     cancelLabel,
                                     children = null,
                                   }) => {

  const isMobile = useIsMobile()
  const styles = isMobile ? mobileStyles : desktopStyles

  const baseProps = {
    open,
    onClose,
    title,
    containerStyles: styles.container,
    modalWidth: 700,
    headerStyles: styles.header,
    ...styles.content_container,
  };

  return (
    <BaseModal {...baseProps}>
      <Typography
        sx={[styles.message]}
      >
        {message}
      </Typography>
      {children ?? null}
      <Box sx={{...styles.actions_container}}>
        <SecondaryButton
          text={confirmLabel}
          onClick={onConfirm}
          textStyles={styles.confirm_text}
          {...styles.confirm}
        />
        <Typography
          onClick={onClose}
          sx={styles.cancel}
        >
          {cancelLabel}
        </Typography>
      </Box>
    </BaseModal>
  );
}

SimpleConfirmModal.propTypes = {
  confirmAction: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

SimpleConfirmModal.defaultProps = {
  title: 'CONFIRM',
  message: 'Please confirm this action',
  confirmLabel: 'CONFIRM',
  cancelLabel: 'CANCEL',
};
