/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { Fragment, useEffect, useState } from "react";

// react-router components

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";


// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow } from "base/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { setTransparentNavbar, useMaterialUIController } from "context";
import { Button, Toolbar } from "@mui/material";
import MDBox from "../../../../components/MDBox";
import NavbarLogo from "assets/images/navbar-logo.svg";
import BackIcon from "assets/icons/chevron_back.svg";
import { AvatarOverImage } from "../../../../components/AvatarOverImage";
import {Link} from "react-router-dom";
import MDTypography from "../../../../components/MDTypography";
import {useStores} from "../../../../models";
import {Box} from "@mui/system";
import {AuthRoutes} from "../../../../routes";
import { useUserStore } from "../../../../utils/hooks";


const IdolProfileNavbar = ({ absolute,
                             light,
                             avatar,
                             goBack,
                             myProfileId,
                             idol_id_name,
                             isMyProfile,
                             atProfile
                           }) =>{
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const rootStore = useStores()
  const {loginStore} = rootStore
  const activeRoute = loginStore.getActiveRoute
  const IsIdol = loginStore.isIdol
  const IsIdolizer = loginStore.isIdolizer
  const user = useUserStore()

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);



  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Box
        style={{
         marginLeft: "13.1%" //aper
        }}
      >
      <Toolbar>
        <MDBox color="inherit"
               mb={{ xs: 1, md: 0 }}
               style={{flexGrow:1}}
               width={"100%"}
               sx={(theme) => navbarRow(theme,{isMini:false} )}>
        <MDBox style={styles.logoContainer}  pr={3}>
          <img src={NavbarLogo} width={"520px"} />
        </MDBox>
        <MDBox
          display={"flex"}
          flexDirection={"row"}
          marginTop={"22px"}
          justifyContent={loginStore.id?"space-around":"space-between"}
          ml={loginStore.id?0:10}
          mr={loginStore.id?0:10}

          width={"100%"}
          zIndex={1}
        >
          {loginStore.id && (
            <Link to={"/dashboard/home"}>
              <MDTypography color={"white"} fontWeight={(activeRoute === 'dashboard') && 'bold'}>
                DASHBOARD
              </MDTypography>
            </Link>
          )}

          <Link to="/explore/">
            <MDTypography color={"white"} fontWeight={(activeRoute === 'explore') && 'bold'}>
              EXPLORE
            </MDTypography>
          </Link>

          {!loginStore.id && (
            <Link to={`${AuthRoutes.login}`}>
              <MDTypography color={"white"}>LOGIN</MDTypography>
            </Link>
          )}

          {loginStore.id && (
            <Fragment>
              <Link to="/notifications/">
                <MDTypography color={"white"} fontWeight={(activeRoute === 'notifications') && 'bold'}>
                  NOTIFICATIONS
                </MDTypography>
              </Link>

              <Link to={IsIdol && ("/settings/get-verified/") || IsIdolizer && ("/settings/blocked-users/")}>
                <MDTypography color={"white"} fontWeight={(activeRoute === "settings") && 'bold'}>
                  SETTINGS
                </MDTypography>
              </Link>

              <Link to={`${AuthRoutes.logout}`}>
                <MDTypography color={"white"}>LOGOUT</MDTypography>
              </Link>
            </Fragment>
          )}

        </MDBox>

        </MDBox>
      </Toolbar>
      </Box>
      <MDBox onClick={goBack}>
        <img src={BackIcon}  style={styles.backImage}/>
      </MDBox>
      <MDBox
        style={styles.avatarContainer}
        zIndex={1}
      >
        <AvatarOverImage avatarImage={avatar}
                         myProfileId={myProfileId}
                         idol_id_name={idol_id_name}
                         isMyProfile={isMyProfile}
                         atProfile={atProfile}
        />
      </MDBox>
    </AppBar>
  );
}

const styles = {
  mainContainer:{
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  logoContainer:{
    marginTop: -23,
    marginBottom:-10,
    position: 'absolute',
    right: 0,
    top: 0
  },
  backImage:{
    cursor:"pointer",
    zIndex:3,
    width:"10px",
    height:"20px",
    position:"absolute",
    top:98,
    left:39
  },
  avatarContainer:{
    position:"absolute",
    marginLeft:"61px",
    marginTop:"110px"
  }
}

// Setting default values for the props of DashboardNavbar
IdolProfileNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  goBack:()=>console.log("going back")
};

// Typechecking props for the DashboardNavbar
IdolProfileNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default IdolProfileNavbar;
