/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const {info, dark, red} = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
    scrollbarGutter: "stable"
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "*:not(.noScrollbarDisplay)::-webkit-scrollbar": {
    "-webkit-appearance": `none`,
  },
  "noScrollbar::-webkit-scrollbar": {
    display: "none"
  },
  "*::-webkit-scrollbar:vertical": {
    width: "5px",
    height: '10px'
  },
  "*::-webkit-scrollbar-button:increment,*::-webkit-scrollbar-button": {
    display: "none"
  },
  "*::-webkit-scrollbar:horizontal": {
    height: "10px"
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: red.main,
    borderRadius: "20px",
  },
  "*::-webkit-scrollbar-track": {
    borderRadius: "10px"
  },
  "input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none"
  },
  ".MuiAccordionSummary-content": {
    margin: "0px !important"
  },
  ".css-ituzjc-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
    minHeight: "unset !important",
    height: "48px"
  },
}

export default globals;
