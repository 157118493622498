/* dependencies */
import PropTypes from 'prop-types';

/* components */
import {Typography, Box} from '@mui/material';

/* assets */
import {styles} from './styles';

export const MobileSubHeader = ({data, pointStyles, centerPoints, meetingType = true, ...rest}) => {
    return (
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
        <Box sx={[styles.subheader, {...rest}]}>
            <Typography sx={[styles.points, {...pointStyles}]}>{data}</Typography>
            <Typography sx={[styles.points_idp]}>idp</Typography>
        </Box>
          <Typography sx={[styles.event]}>
            {meetingType}
          </Typography>
      </Box>
    )
}

MobileSubHeader.propTypes = {
    data: PropTypes.number.isRequired,
    pointStyles: PropTypes.object,
}


