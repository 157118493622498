/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "base/Breadcrumbs";
import NotificationItem from "base/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "base/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenSidenav,
  setOpenConfigurator,
} from "context";
import MDTypography from "../../../components/MDTypography";
import {useOnboardingStore} from "../../../utils/hooks";
import { useMediaQuery } from "@mui/material";
import theme from "../../../assets/theme";
import { DesktopMainToolbar } from "./Toolbars/DesktopMainToolbar";
import { MobileMainToolbar } from "./Toolbars/MobileMainToolbar";
import {useStores} from "../../../models";


function DashboardNavbar({ absolute, light, icon, isAdmin=false }) {
  const onboardingStore = useOnboardingStore()
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1).toString();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rootStore = useStores()
  const {loginStore} = rootStore
  const activeRoute = loginStore.getActiveRoute
  const IsDashboard = (activeRoute === "dashboard")
  const IsSettings = (activeRoute === "settings")
  const mobileActiveRoute = loginStore.getMobileActiveRoute

  useEffect(() => {
    // Setting the navbar type

    //TODO: Ask JB about the use of navbarType since it changing states is causing a bug when rendering the top navigation bar and the content bellow it
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
     */
    window.addEventListener("scroll", handleTransparentNavbar);


    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);



  return (
    <>

        {isMobile && (
          <MobileMainToolbar title={mobileActiveRoute} icon={icon} IsDashboard={IsDashboard}/>
        ) || (
          <AppBar
            position={absolute ? "absolute" : ""}
            color="inherit"
            sx={(theme) => {
              return navbar(theme, { transparentNavbar, absolute, light, darkMode })
            }}
          >
            <DesktopMainToolbar isAdmin={isAdmin} />
          </AppBar>
        )}

    </>

  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
