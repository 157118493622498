import colors from "../../assets/theme/base/colors";
import pxToRem from "../../assets/theme/functions/pxToRem";

export const autocompleteStyles = (theme) => {
  return {
    background: colors.inputBackgroundColor,
    border: `1px solid ${colors.inputBackgroundColor}`,
    borderRadius: '5px',
    paddingY: pxToRem(4),
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(8),
    height: pxToRem(40),
  }
}

export const labelStyles = {
  color: colors.white.main,
  fontSize: pxToRem(16),
  marginBottom: pxToRem(1),
}