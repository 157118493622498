import { CardForm } from "../components/CardForm";
import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/TextField";
import { SaveButton } from "../components/SaveButton";
import React, { Fragment } from "react";
import { globalStyles } from "../globalStyles";
import * as Yup from "yup";
import { requiredMessage, TabName } from "../constants";
import { Form, Formik } from "formik";
import { TextField } from "../components/TextField";

const validationSchema = Yup.object({
  POINTS_PHOTO_UNLOCKED: Yup.string().required(requiredMessage),
  POINTS_VIDEO_UNLOCKED: Yup.string().required(requiredMessage),
  POINTS_PHOTO_LOCKED: Yup.string().required(requiredMessage),
  POINTS_VIDEO_LOCKED: Yup.string().required(requiredMessage),
  POINTS_PAY_PER_VIEW: Yup.string().required(requiredMessage),
});


export const PublishingForm = ({config, onSave, tab })=>{

  return (
    <CardForm  title={tab === TabName.idols?"Publishing":"Like Interaction"}>
      <Formik
        initialValues={{
          POINTS_PHOTO_UNLOCKED: config?.POINTS_PHOTO_UNLOCKED ?? 0,
          POINTS_VIDEO_UNLOCKED: config?.POINTS_VIDEO_UNLOCKED ?? 0,
          POINTS_PHOTO_LOCKED: config?.POINTS_PHOTO_LOCKED ?? 0,
          POINTS_VIDEO_LOCKED: config?.POINTS_VIDEO_LOCKED ?? 0,
          POINTS_PAY_PER_VIEW: config?.POINTS_PAY_PER_VIEW ?? 0
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Fragment>
            <Box component={Form} sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>

              <Box sx={{...globalStyles.container}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_PHOTO_UNLOCKED"}
                             inputLabel={`${tab === TabName.idols? "Publish" : "Like"} (1) Photo Unlocked` }
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={tab === TabName.idols?"Deleted":"Give to Idol"}
                    sx={globalStyles.input}
                    value={"-"+props.values.POINTS_PHOTO_UNLOCKED}
                    customEndAdornmentText={"idp"} />
                </Box>
              </Box>



              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_VIDEO_UNLOCKED"}
                             inputLabel={`${tab === TabName.idols? "Publish" : "Like"} (1) Video Unlocked` }
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={tab === TabName.idols?"Deleted":"Give to Idol"}
                    sx={globalStyles.input}
                    value={"-"+props.values.POINTS_VIDEO_UNLOCKED}
                    customEndAdornmentText={"idp"} />
                </Box>
              </Box>


              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_PHOTO_LOCKED"}
                             inputLabel={`${tab === TabName.idols? "Publish" : "Like"} (1) Photo Locked` }
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={tab === TabName.idols?"Deleted":"Give to Idol"}
                    sx={globalStyles.input}
                    value={"-"+props.values.POINTS_PHOTO_LOCKED}
                    customEndAdornmentText={"idp"} />
                </Box>
              </Box>



              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_VIDEO_LOCKED"}
                             inputLabel={`${tab === TabName.idols? "Publish" : "Like"} (1) Video Locked` }
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={tab === TabName.idols?"Deleted":"Give to Idol"}
                    sx={globalStyles.input}
                    value={"-"+props.values.POINTS_VIDEO_LOCKED}
                    customEndAdornmentText={"idp"} />
                </Box>
              </Box>



              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_PAY_PER_VIEW"}
                             inputLabel={`${tab === TabName.idols? "Publish" : "Like"} (1) Pay per View` }
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  <CustomTextField
                    inputLabel={tab === TabName.idols?"Deleted":"Give to Idol"}
                    sx={globalStyles.input}
                    value={"-"+props.values.POINTS_PAY_PER_VIEW}
                    customEndAdornmentText={"idp"} />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Remove Like"}
                      sx={globalStyles.input}
                      value={"-"+props.values.POINTS_PAY_PER_VIEW}
                      customEndAdornmentText={"idp"} />
                  )}
                </Box>

                <Box sx={{mt:2}}>
                  <SaveButton />
                </Box>
              </Box>
            </Box>
          </Fragment>
        )}
      </Formik>

    </CardForm>
  )
}

