import { BaseModal } from "../../../base/BaseModal";
import { Box, Typography } from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import React, { useEffect, useState } from "react";
import { moneyFormat } from "../../../services/helpers";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import rgba from "../../../assets/theme/functions/rgba";
import { useApi } from "../../../utils/hooks";
import { notifyError, notifySuccess } from "../../../services/notification";
import numeral from "numeral";
import moment from "moment";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { SubscriptionType } from "../../../constants";
import { useIsMobile } from "../../../utils/hooks";
import { isMobile } from "pages/VideoCall/utils";


export const SubscribeModal = ({open, onClose, profile, onSave})=>{

  const [selectedIndex, setSelectedIndex] = useState(null)
  const api = useApi()
  const [bundles, setBundles] = useState([])
  const [promotion, setPromotion] = useState(null)
  const [IsBundle, setIsBundle] = useState(false)
  const [Loading, setLoading] = useState(false)
  const isMobile = useIsMobile()


  useEffect(() => {
    getBundles()
    getPromotion()
  },[])


  const getBundles =  ()=>{
    api.getBundles({user_id:profile.id}).then(response=>{
      if (response.kind === "ok"){
        setBundles(response.data.results)
      }else{
        // notifyError("Error getting bundles")
        return []
      }
    }).catch(reason => {
      console.log(reason)
      // notifyError("Error getting bundles")
    })
  }

  const getPromotion =  ()=>{
    api.getPromotions({user_id:profile.id}).then(response=>{
      if (response.kind === "ok"){
        setPromotion(response.data)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error getting Promotions")
    })
  }

  const subscribe = ()=>{
    if (Loading) return
    setLoading(true)

    const data = { to_user: profile.id }

    if(!IsBundle){
      if (promotion){
        data["subscription_type"] = SubscriptionType.promotion
        data["promotion"] = promotion.id
      }else{
        data["subscription_type"] = SubscriptionType.monthly
      }
    }else{
      data["subscription_type"] = SubscriptionType.bundle
      data["bundle"] = bundles[selectedIndex].id
    }

    api.subscribe(data).then(response=>{
      if (response.kind === "ok"){
        notifySuccess(`Now you are idolizing ${profile.username}`)
        onSave()
      }else{
        notifyError("There is something wrong")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("There is something wrong")
    }).finally(() => {
      setLoading(false)
    })
  }

  const Item = ({bundle, index, monthly=false, isBundle=false})=>{
    const containerStyle = (index === selectedIndex && isBundle === IsBundle)
    ? "selected"
    : "default"

    return (
      <Box style={{...styles.item, ...styles[containerStyle]}} onClick={()=> [setSelectedIndex(index), setIsBundle(isBundle)]}>
        {monthly? (

          <>
          <Typography style={styles.text}>Monthly</Typography>
          <Typography style={styles.text}>{moneyFormat(promotion? promotion.amount : profile.subscription_fee)}</Typography>
          </>
        ):(
          <>
          <Typography style={styles.text}>{bundle.months_of_duration} months ({numeral(bundle.discount_percentage).format("0")}% off)</Typography>
          <Typography style={styles.text}>{moneyFormat(bundle.amount)}</Typography>
          </>
        )}
      </Box>
    )
  }
  return (
    <BaseModal open={open} onClose={onClose}
               containerStyles={styles.containerStyles}
               bodyExtraStyle={{paddingInline: !isMobile ? "5rem" : "1.5rem"}}
               removeBullshit={true}
               title={"SUBSCRIPTION BUNDLES"}>
      <Box style={styles.main}>
        {promotion && (
         <>
           <Typography style={styles.promotionTitle}>
             Limited offer - {numeral(promotion.discount_percentage).format("0")}% off
             for {promotion.days_of_validity} days!
           </Typography>
           <Typography style={styles.promotionSubTitle}>
            Offer ends {moment(promotion.created_at).add("days", promotion.remaining_days).format("MMM D")}
           </Typography>
         </>
        )}
        <Item bundle={{amount:profile.subscription_fee}} monthly={true} index={0} key={"bundle-0"}  />
        {promotion && (
            <Typography style={styles.promotionSubTitle}>
              Regular price {moneyFormat(profile.subscription_fee)} /month
            </Typography>
        )}

        {bundles.map((bundle, index)=> <Item bundle={bundle} key={"bundle-"+index} index={index} isBundle={true}  />)}

        <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", mt:1}}>
          <SecondaryButton text={"CONTINUE"}
                           type={"submit"}
                           onClick={()=>subscribe()}
                           disabled={Loading}
                           loading={Loading}
                           textStyles={{fontSize: isMobile ? pxToRem(12) : pxToRem(14), letterSpacing: "0.1em"}}
                           buttonStyle={{height: "40px", width:"292px"}}/>
          <Typography sx={{fontSize: isMobile ? pxToRem(12) : pxToRem(14), letterSpacing: "0.1em", mt:2}} onClick={onClose}>CANCEL</Typography>
        </Box>

      </Box>

    </BaseModal>
  )
}

const styles = {
  main:{
    display:"flex",
    flexDirection: "column",
    gap:"24px"
  },
  item:{
    borderRadius:"10px",
    cursor:"pointer",
    height:pxToRem(64),
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"22px",
  },
  selected:{
    background: colors.primary.main,
  },
  default:{
    background: colors.darkBlue[700],
  },
  text:{
    letterSpacing:"0.1em",
    fontSize: isMobile ? pxToRem(14) : pxToRem(16),
  },
  promotionSubTitle:{
    textAlign:"center",
    fontWeight:400,
    marginTop:"-10px",
    marginBottom:"-10px",
    fontSize:pxToRem(12)
  },
  promotionTitle:{
    textAlign:"center",
    fontWeight:600,
    fontSize: isMobile ? pxToRem(14) : pxToRem(20),
  },
  containerStyles: {
    width: "660px",
    background: rgba(colors.background.default, 1),
    overflowX: "hidden",
  },
}
