import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {useStores} from "models";
import {useEffect} from "react";
import {IdolizerVerification} from "./IdolizerVerification";

export const VerificationIdolizer = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("VERIFICATION")
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        <IdolizerVerification />
      </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
