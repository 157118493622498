import React, { useEffect, useMemo, useRef, useState } from "react";
import PlayIcon from "../../assets/icons/Play.svg"
import SpeakerIcon from "../../assets/icons/SpeakerHigh.svg"
import { Box, Slider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PauseIcon from '@mui/icons-material/Pause';
import MDTypography from "../MDTypography";

const Controls = {
  play:"play",
  pause:"pause",
}

const VolumeSlider = styled(Slider)({
  color: 'var(--primary)',
  height: 8,
  marginLeft:"18px !important",
  width:"50px",
  '& .MuiSlider-track': {
    border: 'none',
    background:"var(--primary)"
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },

});


const VideoPlayer = ({mediaURL, onLoadedData, isMobile=false}) => {

  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const videoID = "video1"
  const [volume, setVolume] = useState(30);
  const [muted, setMuted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(true);
  const styles = useMemo(()=>isMobile? mobileStyles : desktopStyles, [isMobile])

  useEffect(() => {
    setLoading(true)
  },[])

  useEffect(() => {
    if(!loading && videoRef.current){
      if(playing){
        videoRef.current.play();
      }else{
        videoRef.current.pause();
      }
    }
  },[playing])

  useEffect(() => {
    if(!loading){
      if(!videoTime){
        var vid = document.getElementById(videoID);
        setVideoTime(vid.duration);
        setCurrentTime(getFormattedTime(vid.duration))
      }
    }
  }, [loading])


  useEffect(() => {
    if(videoRef.current){
      videoRef.current.volume = volume/100
    }
  },[volume])

  useEffect(() => {
    if(videoRef.current){
      setVolume(muted? 0 : 50)
    }
  },[muted])

  const getFormattedTime = (time)=>{
    const minutes = Math.floor(time / 60) + ":"
    const seconds =   ("0" + Math.floor(time % 60)).slice(-2)
    return minutes + seconds
  }

  useEffect(() => {
    if(parseInt(progress)===100){
      videoHandler(Controls.pause)
      setFinished(true)
    }
  },[progress])

  const processTime = ()=>{
    if( !!videoRef.current){
      const aux = videoRef.current?.currentTime
      if(videoTime > 0 && aux >= 0 && aux <= videoTime){
        const reversed = videoTime - aux
        setCurrentTime(getFormattedTime(reversed));
        setProgress((aux/ videoTime) * 100);
      }
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      processTime()
    }, 1000);
    return () => {
      clearInterval(interval);
    }

  }, [playing]);



  const videoHandler = (control) => {
    if (control === Controls.play) {
      if(finished){
        setFinished(false)
        setProgress(0)
      }
      setPlaying(true)
    } else if (control === Controls.pause) {
      setPlaying(false)
    }
  };

  const handleVolume = (newValue)=>{
    setVolume(newValue)
  }

  return (
    <React.Fragment>
      <Box
        component={"video"}
        ref={videoRef}
        id={videoID}
        onLoadedData={() => {
          setLoading(false)
          onLoadedData()
        }}
        src={mediaURL}
        onClick={()=>setPlaying(!playing)}
        sx={{...styles.video, cursor:"pointer"}}
      />
      {!loading && (
        <Box style={styles.mainContainer}>

          <Box style={styles.progressBarContainer}>
            <Box style={{...styles.progressBar, width:`${progress}%` }}/>
          </Box>

          <Box style={styles.videoControls}>
            {playing? (
              <PauseIcon color={"white"}  onClick={()=>videoHandler(Controls.pause)} sx={styles.controlsButton}  />
            ) : (
              <Box component="img" onClick={()=>videoHandler(Controls.play)} src={PlayIcon} sx={styles.controlsButton} />
            )}
            <Stack spacing={2} direction="row" sx={{ml:0}} alignItems="center" >
              {muted?(
                <VolumeOffIcon color={"white"} sx={styles.controlsButton} onClick={()=>setMuted(false)}  />
              ):(
                <Box component="img" onClick={()=>setMuted(true)}  src={SpeakerIcon} sx={styles.controlsButton}  />
              )}

              <VolumeSlider aria-label="Volume"
                            value={volume} onChange={(event, newValue)=>handleVolume(newValue)} />
            </Stack>
            <Typography sx={{...styles.controlsButton}} style={{color:"white", marginLeft:"20px", fontSize:18}}>
              -{currentTime}
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  )
};

const mobileStyles = {
  mainContainer:{
    display:"flex",
    flexDirection:"column",
    bottom:"15px",
    position:"relative",
    zIndex:30,
    justifyContent:"flex-start"
  },
  video: {
    height: "100%",
    objectFit: "cover",
  },
  controlsButton: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    marginLeft:"10px"
  },
  videoControls:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent: "flex-start",
    position:"relative",
    width:"100%",
    // bottom:16,
    paddingLeft:"20px",
    paddingRight:"20px",
    marginTop:"10px",
    zIndex:3
  },
  progressBar:{
    width:"30%",
    height:"6px",
    opacity:1,
    background: "var(--primary)",
    borderRadius:"10px"
  },
  progressBarContainer:{
    justifyContent: "flex-start",
    flex:1,
    bottom:"67px",
    zIndex:3,
    backgroundColor:"rgba(255,255,255,0.33)",
    height:"6px",
    borderRadius:"10px"
  }
}

const desktopStyles = {
  mainContainer: {
    display:"flex",
    flexDirection:"column",
    gap:"20px",
    bottom:"80px",
    alignItems:"center",
    position:"relative",
    zIndex:30,
    justifyContent:"flex-start"
  },
  video: {
    height: "100vh",
    objectFit: "cover",
  },
  controlsButton: {
    width: "32px",
    height: "32px",
    cursor: "pointer",
    marginLeft:"10px"
  },
  videoControls:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent: "flex-start",
    position:"fixed",
    width:"100%",
    paddingLeft:"20px",
    marginTop:"20px",
    paddingRight:"20px",
    zIndex:3
  },
  progressBar:{
    width:"30%",
    height:"6px",
    opacity:1,
    background: "var(--primary)",
    borderRadius:"10px"
  },
  progressBarContainer:{
    width:"90%",
    // flex:1,
    bottom:"67px",
    zIndex:3,
    backgroundColor:"rgba(255,255,255,0.33)",
    height:"6px",
    borderRadius:"10px"
  },
}
export default VideoPlayer;
