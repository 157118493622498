import React, {useEffect} from "react";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {useIsMobile} from "../../../utils/hooks";
import CalendarContainer from "./calendarContainer";
import CalendarIcon from "assets/icons/calendar1-red.svg";
import {useStores} from "../../../models";
import {NavigationMenu} from "../../../constants";


export const CalendarPage = props => {
  const isMobile = useIsMobile()

  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("CALENDAR")
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={CalendarIcon} >
            <CalendarContainer isMobile={isMobile}/>
      </DashboardLayout>
    </BaseProtectedComponentPage>)
};

CalendarPage.propTypes = {

};

