import colors from "../../../assets/theme/base/colors";

export const styles = {
  mobileFirstItem:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    mb:3,
    mt:1,
    borderTop:"1px solid "+colors.grey["600"],
    height:"1px",
    width:"90%"
  },
  mobileItem:{
    display:"flex",
    flexDirection:"row",
    mb:3
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"center"
  },
  footerContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  itemRowContainer:{
    display:"flex",
    flexDirection:"row",
  },
  form:{
    display:"flex",
    flexDirection:"column",
    width:"100%",
  },
  discountGrid:{
    marginLeft:"50px",
    paddingLeft:"20px",
    paddingRight:"20px"
  },
  footerText:{
    marginTop: "5px",
    fontSize: "12px",
    fontWeight: 400,
    color: "#fff",
    lineHeight: "140%",
  },
  subtitle: {
    marginBottom: "20px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "140%",
  },
  trashButton:{
    width:"40px",
    minWidth:"40px",
    padding:0,
    margin:0
  },
  trashButtonMobile:{
    width:"20px",
    minWidth:"20px",
    padding:0,
    margin:0
  },
  addButtonContainer: {
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"flex-end"
  },
  addButton: {
    color: "var(--primary)",
    marginTop:"15px",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "93%",
    letterSpacing: "0.1rem",
    "&:hover": {
        cursor: 'pointer'
    }
  },
  buttonStyle: {
    minHeight: "32px!important",
    height: "32px",
    minWidth: '132px',
    width: '132px',
    gap:"20px"
  },
};
