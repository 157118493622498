import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

// @mui components
import {CircularProgress} from "@mui/material";

// Components
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {BasePostCard} from "base/Cards/PostCard";
import MDBox from "components/MDBox";
import {ConfirmPaymentModal as ConfirmActionModal} from "components/ConfirmPaymentModal";
import {ThinButtonTransparent} from "components/ThinButtonTransparent";
import {IdolVerification} from "./idolVerification";
import {IdolizerVerification} from "./idolizerVerification";

// Utils
import {useApi} from "utils/hooks";
import {notifyError, notifySuccess} from "services/notification";
import {UserTypes} from "constants.js";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import GoBackIcon from "assets/icons/go_back.svg";

export const AdminManageUserVerificationPage = () => {
  const api = useApi();
  const navigate = useNavigate();
  const {userId} = useParams();
  const [user, setUser] = useState(null);
  const [modalManager, setModalManager] = useState({deny: false, approve: false});
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState({approve: false, deny: false});
  const getUser = () => {
    setLoading(true);
    api.getProfile(userId)
      .then(response => {
        if(response.kind === "ok") {
          setUser(response.data)
          console.log(response.data);
        }
      })
      .catch((error) => notifyError(error.message))
      .finally(() => setLoading(false))
  }

  const handleOpenModal = (field) => {
    setModalManager({...modalManager, [field]: true})
  }

  const handleCloseModal = (field) => {
    setModalManager({...modalManager, [field]: false})
  }

  const onApprove = () => {
    setLoadingAction({...loadingAction, approve: true});
    api.approveUser(userId)
      .then(response => {
        if(response.kind === "ok") {
          notifySuccess("User approved successfully");
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setLoadingAction({...loadingAction, approve: false}))
  }

  const onDeny = () => {
    setLoadingAction({...loadingAction, deny: true})
    api.declineUser(userId)
      .then(response => {
        if(response.kind === "ok") {
          notifySuccess("User declined successfully");
        }
      }).catch(error => notifyError(error.message))
      .finally(() => setLoadingAction({...loadingAction, deny: false}))
  }

  const goBack = () => navigate(-1);

  useEffect(() => {
    getUser();
  }, [])
  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox display='flex' gap={pxToRem(24)} mt={pxToRem(34)} ml={pxToRem(56)} mr={pxToRem(98)}>
        <MDBox onClick={goBack} mt={pxToRem(42)} sx={{cursor: "pointer"}}>
          <MDBox component="img" src={GoBackIcon} alt="go back" />
        </MDBox>
        <BasePostCard
          title='VERIFICATION'
          headerControls={[
            <ThinButtonTransparent
              onClick={() => handleOpenModal('deny')}
              text={!loadingAction.deny ? "DENY" : <CircularProgress size='1rem' color='disabled' />}
              buttonStyle={{width: pxToRem(145)}}
              color="secondary"
            />,
            <ThinButtonTransparent
              onClick={() => handleOpenModal('approve')}
              text={!loadingAction.approve ? "APPROVE" : <CircularProgress size='1rem'  />}
              buttonStyle={{width: pxToRem(292)}}
            />
          ]}
          cardStyles={{padding: `24px`, width: "100%", mb: pxToRem(24)}}
        >
          <MDBox mt={pxToRem(14)}>
            {loading
              ? <MDBox display='flex' justifyContent='center'><CircularProgress /></MDBox>
              : user?.user_type?.codename === UserTypes.Idol
                ? <IdolVerification user={user} />
                : <IdolizerVerification user={user}  />
            }
          </MDBox>
        </BasePostCard>
      </MDBox>
      <ConfirmActionModal
        open={modalManager.deny}
        onClose={() => handleCloseModal('deny')}
        title='DECLINE USER'
        message='Are you sure you want decline this user'
        confirmAction={onDeny}
        confirmLabel='CONFIRM'
      />

      <ConfirmActionModal
        open={modalManager.approve}
        onClose={() => handleCloseModal('approve')}
        title='APPROVE USER'
        message='Are you sure you want approve this user'
        confirmAction={onApprove}
        confirmLabel='CONFIRM'
      />
    </DashboardLayout>
  )
}