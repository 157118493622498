import {styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import colors from "../../assets/theme/base/colors";

export const nativeSelectStyle = () => {
  return {
    borderRadius: "4px",
    '& :focus': {
      borderRadius: "4px!important"
    },
    '& .MuiSvgIcon-root': {
      color: colors.white.main,
      transform: "scale(1.5)",
      mr: 1
    },
  }
}

export const SelectInput = styled(InputBase)(({theme}) => ({
  backgroundColor: colors.inputBackgroundColor,
  height: '40px',
  '& .MuiInputBase-input': {
    alignContent: 'center',
    backgroundColor: colors.inputBackgroundColor,
    border: 'none',
    borderRadius: '4px',
    color: colors.white.main,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 16,
    padding: '7px 0 7px 10px',
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:focus': {
      borderColor: colors.inputBorderColor,
      boxShadow: `0 0 0 0.1rem ${colors.inputBorderColor}`,
    },
  },
}));

export const SelectInputMobile = styled(InputBase)(({theme}) => ({
  backgroundColor: colors.inputBackgroundColor,
  height: '40px',
  '& .MuiInputBase-input': {
    alignContent: 'center',
    backgroundColor: colors.inputBackgroundColor,
    border: 'none',
    borderRadius: '4px',
    color: colors.white.main,
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 14,
    lineHeight: 16,
    padding: '7px 0 7px 10px',
    position: 'relative',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
    ].join(','),
    '&:focus': {
      borderColor: colors.inputBorderColor,
      boxShadow: `0 0 0 0.1rem ${colors.inputBorderColor}`,
    },
  },
}));
