import React, { useEffect, useState } from "react";
import { BaseModal } from "../../base/BaseModal";
import colors from "../../assets/theme/base/colors";
import MDTypography from "../MDTypography";
// import { styles } from "../../pages/portal/calendar/styles";
import { Box, Button } from "@mui/material";
import { DayPicker, useNavigation } from "react-day-picker";
import PlusRedIcon from "../../assets/icons/plus_red.svg";
import { CustomTextField as TextField } from "../TextField";
import TrashIcon from "../../assets/icons/trash.svg";
import "./custom_daypicker.css";
import { SecondaryButton } from "../SecondaryButton";
import moment from "moment";
import { rangesSchema } from "./schemas";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import InputMask from "react-input-mask";
import stylesCss from "../../pages/portal/calendar/styles.module.css";
import { useApi } from "../../utils/hooks";
import { AMPMtimeToMoment, DATETIME_FORMAT, timeToLocal } from "../../utils/dateUtils";
import { notifyError, notifySuccess, notifyWarning } from "../../services/notification";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const defaultTimeFrame = {
  from_time: "09:00 AM",
  to_time: "05:00 PM"
}


export const CustomCaption = (props)=> {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  return (
    <Box style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
      <button
        className={"rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_previous"}
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <ArrowBackIosIcon style={{marginLeft:"4px"}}/>
      </button>
      <h2 className={"rdp-caption_label"}>
        {moment(props.displayMonth).format('MMMM')}
      </h2>
      <div className={"rdp-nav"}>
        <button
          className={"rdp-button_reset rdp-button rdp-nav_button rdp-nav_button_next"}
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ArrowForwardIosIcon />
        </button>
      </div>
    </Box>
  );
}


const AvailabilityModal = props => {
  const {disableNavigation, open, onClose, updateEvents, isMobile, isSpecific, onApply} = props
  const [selected, setSelected] = useState([]);
  const [Availabilities, setAvailabilities] = useState(null)
  const api = useApi()
  const userTimezone = moment.tz.guess()

  const getAvailability = () => {
    api.getUserAvailability({custom: true}).then(response => {
      if (response.kind === "ok") {
        const tmp_dates = []
        for (let c of response.data.custom_days) {
          tmp_dates.push(moment(c).toDate())
        }
        setSelected(tmp_dates)

        const tmp_ranges = []
        for (let c of response.data.custom_ranges) {
          tmp_ranges.push({
            from_time: timeToLocal(c.from_time),
            to_time: timeToLocal(c.to_time)
          })
        }
        setAvailabilities(tmp_ranges)
      }
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    if (open) {
      if(isSpecific){
        setAvailabilities([defaultTimeFrame])
      }else{
        getAvailability()

      }
    }

  }, [open])


  const save = (data) => {
    api.saveUserAvailability(data).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Availability saved Successfully")
        updateEvents()
      } else {
        notifyError("There is something wrong")
      }
    }).catch(reason => console.log(reason))
  }

  const apply = (values) => {
    const offset = new Date().getTimezoneOffset();

    const availabilities = []

    if (selected.length === 0) {
      notifyWarning('Must select at least 1 date')
      return
    }

    for (const item of selected) {
      const item_format = moment(item).format('yyyy-MM-DD')
      let aux_ranges = []
      for (let range of values) {
        const from_datetime = moment(AMPMtimeToMoment(range.from_time)).format("HH:mm:ss")
        const to_datetime = moment(AMPMtimeToMoment(range.to_time)).format("HH:mm:ss")
        aux_ranges.push({
          from_time: (moment(AMPMtimeToMoment(range.from_time)).add("minutes", offset).format("HH:mm:ss")),
          to_time: (moment(AMPMtimeToMoment(range.to_time)).add("minutes", offset).format("HH:mm:ss")),
          from_datetime: moment(`${item_format} ${from_datetime}`).add("minutes", offset).format(''),
          to_datetime: moment(`${item_format} ${to_datetime}`).add("minutes", offset).format('')
        })

      }
      let data = {
        date: item_format,
        ranges: aux_ranges
      }
      availabilities.push(data)
    }

    if(isSpecific){
      onApply({custom: availabilities, timezone: userTimezone})
    }else{
      save({custom: availabilities, timezone: userTimezone})
      onClose()
    }
  }

  let containerStyle = { background: colors.background.default };
  const modifiersStyles = {
     /* DayPicker styling */
    outside: {borderRadius: "5px",},
    selected: {borderRadius: "5px", backgroundColor: "#DA3F3E",},
    disabled: {borderRadius: "5px",},
    today: {},
  };
  if(isMobile){
    containerStyle = {
      ...containerStyle,
      width: "100%",
      height:"100%",
      maxHeight:"95% !important",
      marginBottom:"0 !important",
      padding: "0 !important",
    }
  }else{
    containerStyle = {
      ...containerStyle,
      height:"764px",
      display:"flex",
      flexDirection:"row",
      justifyContent:"center",
      maxHeight:"90%",
      marginBottom:5,
    }
  }

  return (
    <BaseModal
      open={open}
      centerContent={false}
      title={'CUSTOM HOURS'}
      titleStyles={styles.titleStyle}
      modalWidth={{xs: "100%", lg: '650px'}}
      headerStyles={isMobile ? {...styles.modal.titleStyleMobile} : {background: colors.darkBlue["200"], opacity: 0.5}}
      containerStyles={containerStyle}
      disableBackdropClick
      onClose={() => onClose()}
      removeBullshit={isMobile}
    >
      <Box sx={{mx: "27px"}}>
        <MDTypography style={isMobile ? {...styles.modal.titleMobile} : {...styles.modal.title}}>
          Select the date(s) you want to assign specific hours
        </MDTypography>
      </Box>
      <Box style={{...styles.rowCenter, background: colors.darkBlue["700"], borderRadius: "16px", marginTop: "24px"}}>
        <DayPicker
          mode="multiple"
          selected={selected}
          fromMonth={new Date()}
          toDate={moment().add('months', 6).endOf('month').toDate()}
          components={{
            Caption: CustomCaption
          }}
          onSelect={setSelected}
          footer={""}
          disableNavigation={disableNavigation}
          modifiersStyles={modifiersStyles}
        />
      </Box>
      <Box>
        <Box sx={isMobile?{ml:"15px"}:{}}>
          <MDTypography style={styles.modal.footerTitle}>
            What hours are you available?
          </MDTypography>
        </Box>
        {Availabilities && (
          <Formik
            validateOnChange={false}
            initialValues={{ranges: Availabilities}}
            validationSchema={rangesSchema}
            onSubmit={async (values) => {
              apply(values.ranges)
            }}
          >
            {({values, setFieldValue, errors}) => (
              <Form>
                <Box sx={isMobile?{mt:"20px", mx:"15px"}:{}}>

                  <Box style={{display: "flex", flexDirection: "column"}}>
                    <FieldArray name={`ranges`}>
                      {({insert, remove, push}) => (
                        <>
                          {values.ranges.length === 0 ? (
                            <Box sx={{...styles.rowCenter, gap: "38px", justifyContent: "space-between"}}>
                              <Box sx={{width: "100%", ...styles.rowCenter}}>
                                <Box sx={{width: "100%", ...styles.unavailable}}>
                                  <MDTypography
                                    style={{fontSize: '12px', color: colors.white.main}}>UNAVAILABLE</MDTypography>
                                </Box>
                              </Box>
                              <Box>
                                <Button style={styles.actionButton} onClick={() => push(defaultTimeFrame)}>
                                  <img src={PlusRedIcon}/>
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              {values.ranges.map((time_frame, index) => (
                                <>
                                  <Box style={{...styles.rowCenter, gap: "8px"}}>


                                    <InputMask mask={"99:99 aa"} value={values.ranges[index].from_time}
                                               onChange={(e) => {
                                                 setFieldValue(`ranges.${index}.from_time`,
                                                   e.target.value)
                                               }}>
                                      {(inputProps) => (
                                        <TextField
                                          name={`ranges.${index}.from_time`}
                                          style={{height: "30px"}}/>
                                      )}
                                    </InputMask>
                                    <MDTypography style={{...styles.rowCenter}}>-</MDTypography>
                                    <InputMask mask={"99:99 aa"} value={values.ranges[index].to_time}
                                               onChange={(e) => {
                                                 setFieldValue(`ranges.${index}.to_time`,
                                                   e.target.value)
                                               }}>
                                      {(inputProps) => (
                                        <TextField
                                          name={`ranges.${index}.to_time`}
                                          style={{height: "30px"}}/>
                                      )}
                                    </InputMask>


                                    <Box style={styles.rowCenter}>
                                      <Button style={styles.actionButton} onClick={() => remove(index)}>
                                        <img src={TrashIcon}/>
                                      </Button>
                                      <Button style={styles.actionButton} onClick={() => push(defaultTimeFrame)}>
                                        <img src={PlusRedIcon}/>
                                      </Button>
                                    </Box>
                                  </Box>
                                  <ErrorMessage
                                    name={`ranges.${index}.from_time`}
                                    component="p"
                                    className={stylesCss.fieldError}
                                  />
                                  <ErrorMessage
                                    name={`ranges.${index}.to_time`}
                                    component="p"
                                    className={stylesCss.fieldError}
                                  />

                                </>
                              ))}
                              <ErrorMessage
                                name={`ranges`}
                                component="p"
                                render={(res) => (
                                  <div className={stylesCss.fieldError}>{typeof res === "string" ? res : ""}</div>
                                )}
                                className={stylesCss.fieldError}
                              />
                            </Box>
                          )}
                        </>
                      )}

                    </FieldArray>
                  </Box>
                  <Box sx={isMobile?{display: "flex", flexDirection: "column-reverse", justifyContent: "space-between", alignItems: "center", gap: "10px"}:{...styles.rowCenter, gap: "32px"}} mt={isMobile?"25px":3}>
                    <SecondaryButton text={"CANCEL"}
                                     onClick={(e) => {
                                       if(isSpecific){
                                         setSelected([])
                                       }
                                       onClose(e)
                                     }}
                                     textStyles={isMobile?{...styles.cancelButtonTextMobile}:{...styles.cancelButtonText}}
                                     buttonStyle={isMobile?{...styles.cancelButtonMobile}:{...styles.cancelButton}}/>
                    <SecondaryButton text={"APPLY"}
                                     type={"submit"}
                                     textStyles={{fontSize: "12px"}}
                                     buttonStyle={{height: "40px", width:isMobile?"196px":"292px"}}/>
                  </Box>
                </Box>
              </Form>
            )}

          </Formik>
        )}
      </Box>

    </BaseModal>

  );
};

AvailabilityModal.propTypes = {};
AvailabilityModal.defaultProps = {
  disableNavigation: false,
};


export default AvailabilityModal;

const rowCenter = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center"
}

const styles = {
  cancelButton: {
    height: "40px",
    width: "145px",
    border: `2px solid ${colors.white.main}`,
    opacity: 0.5,
    "&:hover": {
      border: `2px solid ${colors.white.main}`,
    }
  },
  cancelButtonMobile: {
    height: "40px",
    width: "145px",
    border: `0px solid ${colors.white.main}`,
    "&:hover": {
      border: `0px solid ${colors.white.main}`,
    }
  },
  cancelButtonText: {
    fontSize: "12px",
    color: colors.white.main,
    opacity: 0.5,
    "&:hover": {
      color: colors.white.main,
    },
  },
  cancelButtonTextMobile: {
    fontSize: "12px",
    lineHeight: "15px",
    letterSpacing: "0.1em",
    color: colors.white.main,
    "&:hover": {
      color: colors.white.main,
    },
  },
  plusButton: {
    width: "30px",
    minWidth: "30px",
    padding: 0,
    margin: 0
  },
  titleStyle: {
    fontWeight: 700,
    fontSize: "20px",
    letterSpacing: "0.1em",
    color: colors.white.main,
    opacity: "1 !important"
  },
  titleStyleMobile: {
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "0.1em",
    color: colors.white.main
  },
  rowCenter: {...rowCenter},
  modal: {
    title: {
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "center",
      lineHeight: "127%",
      color: colors.white.main
    },
    titleMobile: {
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "center",
      lineHeight: "18px",
      letterSpacing: "0.1em",
      color: colors.white.main
    },
    footerTitle: {
      marginTop: "24px",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",
      color: colors.white.main
    }
  },
  actionButton: {
    width: "30px",
    minWidth: "30px",
    padding: 0,
    margin: 0
  },
  unavailable: {
    ...rowCenter,
    background: colors.inputBackgroundColor,
    borderRadius: "5px",
    height: "30px",
    width: "100%"
  },
}
