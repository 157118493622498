/* dependencies */
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* assets */
import time from 'assets/images/clock.png';
import {styles} from './styles';

export const DatePriceEvent = ({date, timeStart, timeEnd, price}) => {
  return (
    <Box sx={styles.general_info}>
        <Box sx={styles.datetime_info_container}>
        <Box>
            <img src={time} alt="clock icon" title="clock" />
        </Box>
        <Box>
            <Typography sx={styles.datetime_info_text} variant='subtitle2'>{date}</Typography>
            <Typography sx={styles.datetime_info_text} variant='subtitle2'>
            {timeStart} - {timeEnd}
            </Typography>
        </Box>
        </Box>

        <Box sx={styles.meeting_price}>{price}</Box>
  </Box>
  )
}

DatePriceEvent.propTypes = {
    price: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    timeStart: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
};
