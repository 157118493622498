import { UserTypes } from "./constants";
import { idolRoutes } from "./routes/IdolRoutes";
import { idolizerRoutes } from "./routes/idolizerRoutes";
import { guestRoutes } from "./routes/guestRoutes";
import { adminRoutes } from "./routes/adminRoutes";


/**
 * routes defines the routes and configuration for navigation by context (Idol, Idolizer, Guest).
 * There are two main sections for Idol and Idolizers: Dashboard and Settings. Each of them has their own entries.
 * This object has to be used with generateRoutes function in order to the React App be aware of the valid routes
 * for each context. Also, several complementary functions are defined in order to get the specific required data.
 */

const routes = {
  [UserTypes.Idol]:{
    ...idolRoutes
  },
  [UserTypes.Idolizer]: {
    ...idolizerRoutes
  },
  [UserTypes.Guest]: {
      ...guestRoutes
  },
  [UserTypes.Admin]: {
    ...adminRoutes
  }
}


const getRoutePathFromConfig = (routeConfig = {}, filterList = []) => {
  const tmp = {}
  filterList.forEach(key => tmp[key] = routeConfig[key].path)
  return tmp
}

const AuthRoutesList = [
  "login",
  "signup",
  "logout",
]

const AuthRoutes = getRoutePathFromConfig(
  routes[UserTypes.Guest],
  AuthRoutesList
)

const onboardingRouteList = [
  "getStarted",
  "whoAreYou",
  "selectIdolType",
  "idolProfile",
  "idolIncorporated",
  "idolBank",
  "idolTax",
  "idolDocuments",
  "idolTwitter",
  "idolReady",
]

const OnboardingRoutes = getRoutePathFromConfig(
  routes[UserTypes.Guest],
  onboardingRouteList
)

export {
  routes,
  // Environment routes
  AuthRoutes,
  OnboardingRoutes,
}
