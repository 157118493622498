import React from 'react';
import {Stack} from "@mui/material";
// @ts-ignore
import pxToRem from 'assets/theme/functions/pxToRem';
import {GenericModal} from "../../../components/GenericModal";


const IntroContainer = ({children}: any) => {
  return (
    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
      <GenericModal
        open={true}
        title='IDOLCALL'
        onClose={() => 0}
      >
        {children}
      </GenericModal>
    </Stack>
  );
};

export default IntroContainer;
