import Carousel from "react-spring-3d-carousel";
import * as React from "react";
import {useEffect, useState} from "react";
import {config} from "react-spring";
import InfluencerIcon from "../../../../assets/icons/influencer.svg";
import ProfessionalIcon from "../../../../assets/icons/professional.svg";
import {v4 as uuidv4} from "uuid";
import {Box} from "@mui/material";
import {IdolTypeCard} from "../../../../components/IdolTypeCard";
import {useOnboardingStore} from "../../../../utils/hooks";
import {IdolTypes} from "../../../../constants";

export const IdolTypeSelectCarousel = (props) => {
  const {onSelect} = props
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const onboardingStore = useOnboardingStore()

  const [Cards, setCards] = useState([
    {
      typeText: "influencer",
      type: IdolTypes.Influencer,
      selected: onboardingStore.idol_type === IdolTypes.Influencer,
      description: "As an idol influencer you can explore the platform and you can’t monetize your content.",
      accessText: "limited access",
      icon: InfluencerIcon
    },
    {
      typeText: "professional",
      type: IdolTypes.Professional,
      selected: onboardingStore.idol_type === IdolTypes.Professional,
      description: "As an idol professional you can monetize your content, host video calls, earn ",
      accessText: "verified access",
      icon: ProfessionalIcon
    },
    {
      typeText: "Entrepreneur",
      type: IdolTypes.Entrepreneur,
      selected: onboardingStore.idol_type === IdolTypes.Entrepreneur,
      description: "As an idol professional you can monetize your content, host video calls, earn ",
      accessText: "verified access",
      icon: ProfessionalIcon
    }
  ])

  useEffect(() => {
    if (onboardingStore.idol_type) {
      onSelect(onboardingStore.idol_type)
      for (let i = 0; i < Cards.length; i++) {
        if (Cards[i].type === onboardingStore.idol_type) {
          setGoToSlide(i)

          break
        }
      }
    }
  }, [])

  const select = (index) => {
    let newCards = [...Cards]
    for (let i = 0; i < newCards.length; i++) {
      newCards[i].selected = i === index
      if (newCards[i].selected) {
        onSelect(newCards[i].type)
      }
    }
    setCards(newCards)
  }

  const renderCard = (card, index) => {
    return (
      {
        key: uuidv4(),
        onClick: () => {
          setGoToSlide(index)
        },
        content: (
          <Box width={"235px"}>
            <IdolTypeCard
              type={card.typeText}
              onClick={() => select(index)}
              selected={card.selected}
              description={card.description}
              accessText={card.accessText}
              icon={card.icon}/>
          </Box>
        )
      }

    )
  }


  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{width: props.width, height: props.height, margin: props.margin}}
    >
      <Carousel
        slides={Cards.map((card, index) => renderCard(card, index))}
        goToSlide={goToSlide}
        offsetFn={(offsetFromCenter) => ({opacity: 1 - Math.abs(offsetFromCenter) / 5})}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
}
