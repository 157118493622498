import { Box, Button, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { ConfirmPaymentModal } from "components/ConfirmPaymentModal";
import Loader from "components/Loader";
import { RecordCard } from "components/RecordCard/RecordCard";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useApi, useInfiniteScroll, useIsMobile } from "utils/hooks";
import idolmarca from "../../../assets/icons/idolmarca.svg";
import { useStores } from "../../../models";
import { notifyError, notifySuccess } from "../../../services/notification";

export const BlockedUsers = () => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const idolIdName = loginStore.idol_id_name;
  const api = useApi();
  const [UserInModal, setUserInModal] = useState(null);
  const [profile, setProfile] = useState({
      id: null,
      following: null,
      blocked: [],
      followers: null,
      idolizers: null,
      idolPoints: null,
      isVerified: null,
      username: null,
      idol_id_name: null,
      tags: null,
      image: null,
      bio: null,
      location: null
    }
  )

  const getProfile = (idolIdName) => {
    api.getIdolProfile(idolIdName).then(response => {
      if (response.kind === "ok") {
        const data = response.data
        const tags = []
        for (const obj of [data.tag_1, data.tag_2, data.tag_3]) {
          if (obj) {
            tags.push(obj.toUpperCase())
          }
        }

        setProfile({
          id: data.id,
          following: data.following,
          blocked: data.blocked,
          followers: data.followers,
          idolizers: data.subscribers,
          idolPoints: data.idol_points,
          isVerified: data.is_validated,
          username: data.username,
          idol_id_name: data.idol_id_name,
          tags: tags,
          image: data.image,
          bio: data.biography,
          location: data.location
        })


      } else {
        notifyError("Error getting profile")
      }

    }).catch(reason => {
      notifyError("Error getting profile")
      console.log(reason)
    })
  }

  const onApiCall = ({page, filters})=>{
    let data = {page, ...filters}
    return api.getBlocked(data)
  }

  const unBlock = (user) => {
      api.blockUser(user.id).then((response) => {
        if(response.kind === "ok"){
          notifySuccess(`User ${profile.username} locked successfuly`);
          console.log("reload",refetch)
          refetch();
        }else{
          notifyError("Cant process the operation")
        }
      }).catch(reason => {
        console.log(reason)
        notifyError("Cant process the operation")
      })
  }
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleConfirm = () => {
    setOpen(false)
    if (UserInModal) {
      unBlock(UserInModal);
    }    
  };

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteScroll({apiCall:onApiCall})

  return (
    <Box sx={styles.container}>
      <ConfirmPaymentModal
        open={open}
        onClose={handleClose}
        cancelLabel="CANCEL"
        confirmLabel="CONFIRM"
        title="Unlock user"
        message="Are you sure you want to unblock this user?"
        cancelAction={handleClose}
        confirmAction={handleConfirm}
      />
      <Typography sx={styles.title}>Blocked Users</Typography>
      <Typography sx={styles.subtitle}>This section is to unblock the users you already blocked</Typography>
      {/*  */}
      <InfiniteScroll
        dataLength={items.length}
        style={styles.scroll}
        next={() => {
          if(!isFetchingNextPage){
            return fetchNextPage()
          }
        }}
        hasMore={!!hasNextPage}
        loader={<Loader show={isLoading}/> }
      >
        {items.map((i) => (
        <RecordCard
          key={i.id}
          containerStyles={styles.containerStyles}
          showOptionsCard={false}
          iconCard={isMobile ? false : idolmarca}
          showDivider={isMobile ? false : true}
          cardFirstContent={
            <BaseCardIdolInfo
              isMobile={true}
              avatar={i.image}
              isVerified={i.isVerified}
              idolPoints={i.idolPoints}
              idolizerName={i.username}
            />
          }
          cardThirdContentStyles={styles.cardThirdContentStyles}
          cardThirdContent={
            <>
              <Box sx={styles.blockButtonContainer}>
                <Button sx={styles.blockButton} onClick={()=>{handleOpen(); setUserInModal(i)}}>
                  UNBLOCK
                </Button>
              </Box>
              <Box sx={styles.divider}>|</Box>
            </>
          }
        />
      ))}
      </InfiniteScroll>      
    </Box>
  );
};

const styles = {
  containerStyles: {
    backgroundColor: "#192537",
  },
  container: {
    width: { xs: "100%", md: "623px", lg: "80%" },
  },
  title: {
    fontSize: "36px",
    fontWeight: 400,
    color: "#fff",
    lineHeight: "93%"
  },
  subtitle: {
    paddingTop: "35px",
    fontSize: "16px",
    fontWeight: 300,
    color: "#fff",
    lineHeight: "26px"
  },
  divider: {
    display: { xs: "flex", lg: "none" },
    backgroundColor: colors.background.default,
    color: colors.background.default,
    width: "8px",
    height: "52px",
    borderRadius: "8px",
    marginRigth: "10px",
    opacity: 0.2,
  },
  cardThirdContentStyles: {
    backgroundColor: { xs: "#192537", lg: "#0D182B" },
    display: "flex",
    flexDirection: "row-reverse",
    boxShadow: { xs: "none", lg: "0px 4px 4px rgba(0, 0, 0, 0.25)" },
    height: { xs: "76px", lg: "77px" },
  },
  blockButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  blockButton: {
    border: { xs: "1px solid #D9243D", lg: "none" },
    fontSize: { xs: "10px", lg: "16px" },
    minHeight: { xs: "32px", lg: "" },
    height: { xs: "32px", lg: "" },
    lineHeight: "26px",
    fontWeight: 400,
    borderRadius: "5px",
    fontVariant: "small-caps",
    color: "#D9243D",
    "&:hover": {
      color: "#e22740",
    },
    "&:focus": {
      color: "#D9243D!important",
    },
  },
};
