import React, {memo} from "react";
import {Box, Typography} from "@mui/material";
import CloseIcon from "../../assets/icons/X.svg";
// import { styles } from "./styles";
import VideoPlayer from "../VideoPlayer";
import lockedPost from "../../assets/images/lockedPost.png";
import IdolIdolLogo from "../../assets/icons/idol_white.svg";
import dollarIcon from "../../assets/icons/dollar_white_thin.svg";
import calendarIcon from "../../assets/icons/calendar_thin.svg";
import numeral from "numeral";
import {IdolTypeEnum} from "../../utils/constants";
import {useUserStore} from "../../utils/hooks";
import {useParams} from "react-router-dom";

export const SpecificPostContentMobile = ({content, confirmPayment, goToMeet, unlockPost, loading, handleClose}) => {

  const userStore = useUserStore()
  const {idol_id_name} = useParams();
  const myProfileId = userStore.idol_id_name
  const isMyPost = myProfileId === content.user.idol_id_name
  return (
    <Box sx={styles.container}>
      <Box sx={styles.content}>

        {content.isVideo && !content.isLocked ? (
          <VideoPlayer mediaURL={content.idolPost}
                       isMobile
                       onLoadedData={() => {
                         // setLoading(false)
                       }}
          />
        ) : (
          <Box
            component={"img"}
            src={content.isLocked ? lockedPost : content.idolPost}
            alt="idol1"
            onLoad={() => {
              // setLoading(false)
            }}
            sx={styles.mainImg}
          />
        )}
      </Box>
      {!content.isLocked && !loading && (
        <Box style={styles.dataContainer}>

          <Box style={{display: "flex", flexDirection: "row-reverse"}}>
            {isMyPost ?
              <></>
              :
            content?.user?.idol_type !== IdolTypeEnum.influencer
            ?
              <Box sx={styles.buttonsContainer}>
                <Box component="img" onClick={confirmPayment} src={dollarIcon} sx={styles.button}/>
                <Box component="img" onClick={goToMeet} src={calendarIcon} sx={styles.button}/>
              </Box>
              :
              <></>
            }
          </Box>
          <Box style={styles.descriptionBox}>
            <Typography sx={styles.descriptionText}>{content.description}</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={
          !content.isLocked
            ? styles.descriptionContainer
            : {...styles.descriptionContainer}
        }
      >


        {content.isLocked && ( // Boton para las imagenes bloqueadas
          <Typography sx={styles.unlockButton} onClick={unlockPost}>
            UNLOCK FOR ${numeral(content.additionalPrice).format("0.00")}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
const styles = {
  usernameContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  descriptionBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: "20px"
  },
  username: {
    color: "white",
    marginLeft: "10px",
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.1em"
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    bottom: "250px",
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  container: {
    scrollSnapAlign: "start",
    flex: "none",
    height: "100vh",
    width: "100vw",
  },
  content: {
    // width: "100%",
    height: "100vh",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "44px",
    cursor: "pointer",
    // position: "relative",
    zIndex: 99,
    // right: "31px",
    // bottom: "142px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "24px"
  },
  button: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },

  descriptionContainer: {
    position: "relative",
    zIndex: 99,
    bottom: "95px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  descriptionText: {
    color: "#fff",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "23px",
    textAlign: "center"
  },
  logo: {
    width: "68px",
    height: "65px"
  },
  unlockButton: {
    width: "292px",
    height: "40px",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "5px",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
    fontSize: "14px",
    padding: "15px",
    textAlign: "center",
    marginTop: "37px",
    cursor: "pointer",
    "&:hover": {
      color: "#dbdbdb",
      border: "1px solid #dbdbdb",
    }
  },

  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    cursor: "pointer",
    // position: "relative",
    zIndex: 99,
    left: "20px",
    // bottom: "115px",
  },
  mainImg: {
    height: "100%",
    objectFit: "cover",
  },

}
