import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import { Box } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { useApi } from "../../../utils/hooks";
import { WalletForm } from "./forms/WalletForm";
import { OnboardingForm } from "./forms/OnboardingForm";
import { PublishingForm } from "./forms/PublishingForm";
import { MeetingForm } from "./forms/MeetingForm";
import { InteractionForm } from "./forms/InteractionForm";
import { ReferralForm } from "./forms/ReferralForm";
import {TabName} from "./constants"
import { showMessage } from "../../../services/helpers";
import { notifySuccess } from "../../../services/notification";
import { observer } from "mobx-react";

export const AdminIdolPointsPage = observer(() => {
  const [activeTab, setActiveTab] = useState(TabName.idols)
  const [configData, setConfigData] = useState({})
  const [loading, setLoading] = useState(false)

  const api = useApi()

  useEffect(() => {
    setLoading(true)
    getConfig()
  },[])

  const getConfig = ()=>{
    api.getConfigVariables({search:"POINTS"}).then(response=> {
      console.log(response)
      if (response.kind === "ok"){
        const data = Object.keys(response.data).map(key=> ([[key], parseFloat(response.data[key].value)]))
        const obj = Object.fromEntries(data);
        setConfigData(obj)
      }
    }).catch(reason => console.log(reason))
      .finally(() => setLoading(false))
  }

  const saveConfig = (data)=>{
    setLoading(true)
    api.updateConfigVariables(data).then(response=> {
      if (response.kind === "ok"){
        getConfig()
        notifySuccess("Saved Successfully")
      }
    }).catch(reason => console.log(reason))
  }

  const TabButton = ({title, tabName})=>{
    let content = null
    if(tabName === activeTab){
      content = (
        <PrimaryButton text={title} buttonStyle={styles.tab}  onClick={()=>setActiveTab(tabName)} />
      )
    }else{
      content = (
        <SecondaryButton text={title}
                         onClick={()=>setActiveTab(tabName)}
                         buttonStyle={{...styles.tab, borderBottom: "none"}}/>
      )
    }
    return content
  }

  return (
    <DashboardLayout isAdmin hideFooter>
      <Box sx={{p:3}}>
        <BasePostCard
          title='IDOL POINTS'
          titleStyles={{fontSize:"48px"}}
          titleContainerStyles={{height:"73px!important", fontWeight:"400"}}
          headerControlsStyle={{height: "40px", mt:"8px" }}
          icon={null}
          headerControls={(
            <Box sx={styles.tabs}>
              <TabButton title={"IDOLS"} tabName={TabName.idols}/>
              <TabButton title={"IDOLIZERS"} tabName={TabName.idolizers} />
            </Box>
          )}
        >
          <Fragment>
            <WalletForm tab={activeTab} config={{POINTS_PER_USD: configData.POINTS_PER_USD}} onSave={saveConfig} />
            <OnboardingForm tab={activeTab}  config={configData} onSave={saveConfig} />
            <PublishingForm tab={activeTab} config={configData} onSave={saveConfig}  />
            <MeetingForm tab={activeTab} config={configData} onSave={saveConfig} />
            <InteractionForm tab={activeTab} config={configData} onSave={saveConfig} />
            {activeTab === TabName.idols && (
              <ReferralForm onSave={saveConfig} config={configData} />
            )}
          </Fragment>
        </BasePostCard>
      </Box>
    </DashboardLayout>
  )
})


export const styles = {
  tabs:{
    display:"flex",
    flexDirection:"row",
    width:"500px",
    gap:"15px",
    alignItems:"flex-end !important",
    justifyContent:"flex-end !important",
    height:"75px !important",
    marginBottom:"10px",
    marginRight:"50px",
    // margin:"0px"
  },
  tab:{
    height:"65px !important",
    borderRadius:"10px 10px 0px 0px",
    fontSize:"14px"
  },



};
