import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import * as Types from "./api.types"
import {ApiBase, Files, FileWithName} from "./api-base";


/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config)
  }

  async getVideoToken(room_name: string): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/token_video_twilio/`, {room_name: room_name})
  }

  async login(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/login/`, data)
  }

  async getUserTypes(data?:any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/user-types/`, data)
  }

  async checkExistingUser(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/check/`, data)
  }


  async signUp(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/`, data)
  }

  async signUpIdol(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/onboarding/`, data)
  }

  async signUpIdolMultipart(data: any, files?: Files): Promise<Types.SimplePostResult> {
    return this.simple_post_multipart_data_files(`/api/v1/onboarding/`, data, files)
  }

  async getProfile(userId: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/${userId}/retrieve/`)
  }

  async getIdolProfile(idolIdName: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/idol-profile/${idolIdName}/`)
  }

  async getScraperRequest(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/import-content/pending_posts/`)
  }

  async createScraperRequest(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/import-content/create_request/`, data)
  }

  async scrapeRequestSavePosts(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/import-content/save_posts/`, data)
  }

  async usersPendingPostsModeration(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/import-content/users_pending_moderation/`)
  }

  async postsPendingModeration(userId: number | string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/import-content/${userId}/posts_pending_moderation/`)
  }

  async scrapeRequestModeratePosts(userId: number | string, data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/import-content/${userId}/moderate_posts/`, data)
  }

  async getProfileDetail(userId: number): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/${userId}/detail/`)
  }

  async getPosts(userId: string, queryParams: string = ""): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/posts/?user_id=${userId}${queryParams}`)
  }

  async getIdolPosts(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/idol-posts/`, data)
  }

  async getExploreIdols(data: any = {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/explore-idols/`, data)
  }

  async unlockIdolPost(postId: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/idol-posts/unlock/?post_id=${postId}`)
  }

  async getPostsByPage(id: string, page: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/posts/?user_id=${id}&page=${page}`)
  }

  async updateDetailsSettings(formData: any): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/share-offer/`, formData)
  }

  async createReferralCode(formData: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/referral-code/`, formData)
  }

  async onFollow(id: number, is_following: string): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/relationships/${id}/${is_following}/`)
  }

  async getMeetingDateProfile(userId: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/${userId}/retrieve/`)
  }

  async deactivateAcc(): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/deactivate/`)
  }

  async getTypes(userId: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/event-types/?user_id=${userId}`)
  }

  async getAvailability(typeId: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/event-types/${typeId}/availabilities/`)
  }

  async getUserAvailability(data: any = {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/user/availabilities/`, data)
  }


  async saveUserAvailability(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/user/availabilities/`, data)
  }

  async getUserCalendarAvailabilities(data: any = {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/user/calendar_availabilities/`, data)
  }


  async getAvailabilityForMonth(selectedTypesId: string, firstDayOfMonth: string, lastDayOfMonth: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/event-types/${selectedTypesId}/availabilities/?from_date=${firstDayOfMonth}&to_date=${lastDayOfMonth}`)
  }

  async payMeeting(payload: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/meetings/`, payload)
  }

  async createMeetingVerification(payload: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/meeting-verifications/`, payload)
  }

  async listIdolUsers(userFilter: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/list_idol_users/?search=${userFilter}`)
  }

  async createTwilioIdolUser(userId: number): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/create_twilio_idol_users/${userId}/`)
  }

  async getMeetingByRoomName(room?: string): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/get-meeting-info/by_room/`, {room: room})
  }

  async getMeetingById(id?: number): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/get-meeting-info/${id}/`)
  }

  async getCustomMeetings(data?: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/custom-meeting/`, data)
  }

  async getPendingMeetings(data?: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/custom-meeting/pendings/`, data)
  }

  async getUpcomingMeetings(data?: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/custom-meeting/upcoming/`, data)
  }

  async getPMeetings(today: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/meetings/?from_user=true&to_evaluate=true&from_datetime=${today}`)
  }

  async getUMeetings(today: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/meetings/?from_user=true&from_datetime=${today}`)
  }

  async approveMeeting(id: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/custom-meeting/${id}/approve/`)
  }

  async joinMeeting(id: any, payload: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/meetings/${id}/join/`, payload)
  }

  async getTwilioToken(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/token_twilio/`)
  }


  async getSupportMessages(data:any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/chat/messages/`, data)
  }
  async sendSupportMessage(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/chat/messages/`, data)
  }

  async getSupportConversations(data:any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/chat/conversations/`, data)
  }


  async getTwilioChatSID(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/twilio_chat_sid/`)
  }

  async sendMessage(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/message/`, data)
  }

  async openConversation(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/conversation/`, data)
  }

  async getConversation(id: number): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/message/?conversation=${id}`)
  }

  async resetPassword(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/password/reset/`, data)
  }

  async resetPasswordConfirm(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/password/reset/confirm/`, data)
  }

  async getUserProfiles(searchData: string, page: number = 1, params: {}): Promise<Types.ListResult> {
    return this.single_list_view(`/api/v1/users/list/?page=${page}&search=${searchData}`, params)
  }

  async updateProfilePicture(formData: any, keys: string[]): Promise<Types.GenericResponse> {
    return this.patch_multipart_form_data(`/api/v1/users/`, formData, keys)
  }

  async editUserProfile(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/update/`, data)
  }

  async editProfile(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/update/`, data)
  }

  async editProfilePicture(image: FileWithName): Promise<Types.SimplePostResult> {
    return this.simple_post_multipart_data_files(`/api/v1/users/profile-picture/`, {}, {image: image})
  }

  async getUserData(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/`)
  }

  async sendTip(post_id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/tips/${post_id}/tip/`)
  }

  async sendTipToUser(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/create-tip/`, data)
  }

  async onLike(post_id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/likes/${post_id}/like/`)
  }

  async onDislike(post_id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/likes/${post_id}/unlike/`)
  }

  async onLikeDislike(post_id: number, liked: string): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/likes/${post_id}/${liked}/`)
  }

  async editPost(post_id: number, data: any): Promise<Types.SimplePostResult> {
    return this.simple_put(`/api/v1/posts/${post_id}/`, data)
  }

  async getAvailabilityDashboard(): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/availabilities/`)
  }

  async putAvailability(payload: any): Promise<Types.SimplePostResult> {
    return this.simple_put(`/api/v1/availabilities/`, payload)
  }

  async additionalFeatureAccessPay(post_id: number, data?: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/additional-feature-access/${post_id}/pay/`, data)
  }

  async uploadFilesAdditionalPage(data: any, keys: any = []): Promise<Types.SimplePostResult> {
    return this.post_multipart_form_data(`/api/v1/additional-documents/`, data, keys)
  }

  async deletePost(post_id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/posts/${post_id}/`)
  }

  async getUserDataExternal(id: number): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/users/${id}/retrieve/`)
  }

  async reinstateUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/reinstate/`)
  }

  async deleteUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/delete/`)
  }

  async suspendUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/suspend/`)
  }

  async unsuspendUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/unsuspend/`)
  }

  async sendFeedback(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/feedback-message-responses/`, data)
  }

  async getFeedbackMessage(searchData: string, page: number = 1, params: {}): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/feedback-messages/?page=${page}&search=${searchData}`, params)
  }

  async createFeedbackMessage(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/feedback-messages/`, data)
  }

  async getEventTypes(page: number = 1, params: any = {}): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/event-types/`, params)
  }

  async createEventType(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/event-types/`, data)
  }

  async cancelEventType(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/event-types/${id}/cancel/`)
  }

  async deleteEventType(id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/event-types/${id}/`)
  }

  async updateEventType(data: any): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/api/v1/event-types/${data.id}/`, data)
  }

  async updateEventTypeWithId(id: any, data: any): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/api/v1/event-types/${id}/`, data)
  }

  async uploadImage(formData: any): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/api/v1/users/`, formData)
  }

  async uploadImagePost(formData: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/posts/`, formData)
  }

  // async uploadImagePost(data: any, keys: string[]): Promise<Types.SimplePostResult> {
  //   return this.post_multipart_form_data(`/api/v1/posts/`, data, keys)
  // }

  async getNotifications(): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/notifications/`)
  }

  async setMessageSeen(id: any, formData: any= {}): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/message/${id}/seen/`, formData)
  }

  async getWithdrawals(data: any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/withdraw/`, data)
  }

  async createWithdraw(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/withdraw/`, data)
  }

  async createDigitalWithdraw(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/digital-wallets/withdraw-money/`, data)
  }

  async acceptWithdraw(id: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/withdraw/${id}/accept/`, {})
  }

  async payWithdraw(id: any, data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/withdraw/${id}/pay/`, data)
  }

  async rejectWithdraw(id: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/withdraw/${id}/reject/`, {})
  }

  async getPrices(): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/fees/`)
  }

  async getEarnings(): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/earnings/`)
  }

  async patchPrices(payload: any): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/api/v1/fees/`, payload)
  }

  async approveUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/validate/`)
  }

  async declineUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/users/${id}/invalidate/`)
  }

  async getExploreContent(searchTerm: string, currentPage: number, params: {}): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/posts/?search=${searchTerm}&page=${currentPage}`, params)
  }

  async getPaidData(): Promise<Types.ListResult> {
    return this.simple_get(`api/v1/posts/?private_posts=true`)
  }

  async addReservedName(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/reserved-name/`, data)
  }

  async deleteReservedName(id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/reserved-name/${id}/`)
  }

  async getReservedName(): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/reserved-name/`)
  }

  async getFollowers(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/relationships/followers/`, data)
  }

  async getFollowing(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/relationships/following/`, data)
  }

  async getBlocked(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/relationships/blocked/`, data)
  }

  async getRelationshipCounters(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/relationships/relationship_counters/`, {})
  }


  async getSubs(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/subscriptions/subscribed/`)
  }

  async getIdolizing(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/subscriptions/subscribed/`, data)
  }

  async getRelatedUsers(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/users/related-users/`, data)
  }

  async getIdolizers(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/subscriptions/subscribers/`, data)
  }

  async unfollow(userId: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/relationships/${userId}/unfollow/`)
  }

  async follow(userId: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/relationships/${userId}/follow/`)
  }


  async isFollowing(id: number, following: string): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/relationships/${id}/${following}/`)
  }

  async unsubscribe(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/subscriptions/${id}/unsubscribe/`)
  }

  async getTips(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/tips/tips/`, data)
  }

  async getBalance(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/transactions/transaction/current_balance/`)
  }

  async getTransactions(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/transactions/transaction/`, data)
  }

  async getIncomingTransactions(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/transactions/transaction/incoming/`, data)
  }

  async addMoney(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/transactions/transaction/add_money/`, data)
  }

  async saveBundles(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/subscription-bundles/save/`, data)
  }

  async createPromotion(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/subscription-promotions/`, data)
  }

  async deleteBundle(id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/subscription-bundles/${id}/`)
  }

  async deletePromotion(id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/subscription-promotions/${id}/`)
  }

  async getBundles(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/subscription-bundles/`, data)
  }

  async getPromotions(data: any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/subscription-promotions/`, data)
  }


  async getUserCards(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/transactions/credit-card/`)
  }

  async addCard(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/transactions/credit-card/`, data)
  }

  async deleteCard(card_id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/transactions/credit-card/${card_id}/`)
  }

  async setDefaultCard(card_id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/transactions/credit-card/${card_id}/default/`)
  }

  async getApprovedMeetings(date: string): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/meetings/?to_user=true&from_datetime=${date}`)
  }

  async getUnapprovedMeetings(date: string): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/meetings/?to_user=true&to_evaluate=true&from_datetime=${date}`)
  }

  async cancelMeeting(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/meetings/${id}/cancel/`)
  }

  async rejectMeetingRequest(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/custom-meeting/${id}/reject/`)
  }

  async cancelMeetingRequest(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/custom-meeting/${id}/cancel_request/`)
  }

  // async cancelUserMeeting(id: number): Promise<Types.SimplePostResult> {
  //   return this.simple_post(`/api/v1/meetings/${id}/cancel_meeting`)
  // }

  async createMeeting(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/custom-meeting/`, data)
  }

  // async createMeeting(data: any): Promise<Types.SimplePostResult> {
  //   return this.simple_post(`/api/v1/meetings/`, data)
  // }
  async setDevice(userId: string, pushToken: string, active: boolean): Promise<Types.GenericResponse> {
    return this.noresult_post(`/api/v1/set-device/`, {
      user_id: userId,
      push_token: pushToken,
      active,
    })
  }

  async getDealsById(id: number): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/deals/${id}/`)
  }

  async getDeals(): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/deals/`)
  }

  async createDeals(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/deals/`, data)
  }

  async deleteDeal(id: number): Promise<Types.GenericResponse> {
    return this.simple_delete(`/api/v1/deals/${id}/`)
  }

  async getConfigVariables(data:any): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/configs/`, data)
  }

  async updateConfigVariables(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/configs/`, data)
  }

  async getUserFees(searchData: string, page: number = 1, params: {}): Promise<Types.ListResult> {
    return this.single_list_view(`/api/v1/user-fees/?page=${page}&search=${searchData}`, params)
  }

  async getTransactionsAdmin(searchData: string, page: number = 1, params: {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/transactions/transaction/?page=${page}&search=${searchData}`, params)
  }

  async updateUserFee(userId: number, data: {}): Promise<Types.SimplePostResult> {
    return this.simple_patch(`/api/v1/user-fees/${userId}/`, data)
  }

  async subscribe(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/subscriptions/${data.to_user}/subscribe/`, data)
  }

  async getUserDocument(data: any, options: any = {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/additional-document-file/`, data, options)
  }

  async meetingPayment(data: any, keys: any = []): Promise<Types.SimplePostResult> {
    return this.post_multipart_form_data(`/api/v1/meeting-payment/`, data, keys)
  }

  async reportUser(data: any): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/report/`, data)
  }

  async blockUser(id: number): Promise<Types.SimplePostResult> {
    return this.simple_post(`/api/v1/block/${id}/`)
  }

  async getReportedUser(searchData: string, page: number = 1, params: {}): Promise<Types.SimpleGetResult> {
    return this.simple_get(`/api/v1/report/?page=${page}&search=${searchData}`, params)
  }

  async getCountries(data: any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/countries/`, {search: data})
  }

  async getRegions(search: string, country?: number): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/regions/`, {search: search, country: country})
  }

  async getCities(search: string, region?: number, countryName?: string): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/cities/`, {search: search, region: region, country_name: countryName})
  }

  async getGenders(data?: any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/genders/`, data)
  }

  async getLanguages(data: any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/languages/`, data)
  }

  async getEthnicity(data: any): Promise<Types.ListResult> {
    return this.simple_get(`/api/v1/ethnicities/`, data)
  }
}
