/* dependencies */
import React, {memo} from 'react'
import PropTypes from 'prop-types';

/* mui components */
import {Card, Typography, Box} from '@mui/material';

/* assets */
import {styles} from './styles';
import svg from 'assets/icons/people.svg';
import colors from 'assets/theme/base/colors';
import {observer} from "mobx-react";
const {white} = colors;

export const BasePostCard = memo(
  observer(
    ({
      title,
      children,
      icon,
      headerControls = [],
      absolute = false,
      headerControlsStyle = {},
      cardStyles,
      containerStyles,
      titleStyles,
      titleContainerStyles,
    }) => {
      return (
        <Card sx={[styles.card, cardStyles, absolute && { paddingTop: "1.5rem" }]}>
          <Box sx={[styles.container, containerStyles]}>
            <Box sx={[styles.title_container, titleContainerStyles]}>
              {icon && (
                <Box sx={styles.image_container}>
                  <img src={icon} alt="icon" style={styles.img} />
                </Box>
              )}
              <Typography variant="h1" sx={[styles.title, titleStyles]} color={white.main}>
                {title}
              </Typography>
            </Box>
            <Box
              sx={[
                styles.controls_container,
                absolute && styles.fixed_controls,
                headerControlsStyle,
              ]}
            >
              {headerControls}
            </Box>
          </Box>
          {children}
        </Card>
      );
    }
  )
);

BasePostCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  headerControls: PropTypes.node,
};

BasePostCard.defaultProps = {
  title: 'Add new post',
  // icon: svg,
};
