import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withRootStore} from "../extensions/with-root-store";
import {MeetingStoreFields} from "../fields";
import {withEnvironment} from "../extensions/with-environment";

const getViewsMeetingStoreModel = (self: any) => ({
  get currentMeeting() {
    return JSON.parse(self.meeting);
  },
  get isHost() {
    return self.is_host;
  },
})

const getActionsMeetingStoreModel = (self: any) => ({
  setCurrentMeeting(meeting: any) {
    self.meeting = JSON.stringify(meeting)
  },
  setIsHost(isHost: boolean) {
    self.is_host = isHost;
  },
  reset() {
    self.meeting = null;
    self.is_host = null;
  },
})

/**
 * Model description here for TypeScript hints.
 */
export const MeetingStoreModel = types
  .model("MeetingStore")
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({...MeetingStoreFields})
  .views(getViewsMeetingStoreModel)
  .actions(getActionsMeetingStoreModel)

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type MeetingStoreType = Instance<typeof MeetingStoreModel>

export interface MeetingStore extends MeetingStoreType {
}

type MeetingStoreSnapshotType = SnapshotOut<typeof MeetingStoreModel>

export interface MeetingStoreSnapshot extends MeetingStoreSnapshotType {
}
