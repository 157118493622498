import {Box, Stack} from "@mui/material";
import {CardNumberItem} from "components/CardNumberItem";
import {useState} from "react";
import {addCommas} from "utils/functions";
import {IdolsListCard} from "../idolsListCard";
import {initialCountersIdolizer} from "../reducers";
import MeetingsCard from "../meetingsCard";
import pxToRem from "../../../../assets/theme/functions/pxToRem";

export const IdolizerDashboard = ({profile, filterCounter}) => {
  return (
    <Stack gap={pxToRem(20)}>
      <Box sx={styles.CardContainer}>
        <CardNumberItem content={`${addCommas(profile.idol_points)}`} secondaryContent="idolpoints"/>
      </Box>
      <MeetingsCard />
      <IdolsListCard filterCounter={filterCounter} title={"Idols"} profile={profile} />
    </Stack>
  );
};

const styles = {
  CardContainer: {
    fflexGrow: 1,
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    backgroundColor: "#182536",
    borderRadius: "10px",
  }
}
