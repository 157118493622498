import { memo, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import UserAvatar from "../../../assets/images/monica_blackwell.png";
import ImageIcon from "../../../assets/icons/image.svg";
import VideoIcon from "../../../assets/icons/video.svg";
import PostImage from "../../../assets/images/idol1.jpg";
import lockedPost from "../../../assets/images/lockedPost.png";
import rgba from "../../../assets/theme/functions/rgba";
import colors from "../../../assets/theme/base/colors";
import { BaseCardIdolInfo } from "../../../base/Cards/InfoCards/IdolizerInfoCard";
import { useNavigate } from "react-router-dom";
import { PostCategoryEnum } from "../../../constants";
import { UnauthenticatedPaths } from "../../../paths/paths";

export const ExplorePost = memo(({post, small=false, onClick})=>{
  const navigate = useNavigate()
  const styles = small? smallStyles : bigStyles

  const contentProps = useMemo(()=>{
    let props = { src:post.file, component:"video"}
    if(!post.is_visible){
      props = { src:lockedPost, component:"img"}
    }else if( post.post_category.codename === PostCategoryEnum.video){
      props = { src:post.file, component:"video"}
    }else{
      props = { src:post.image, component:"img"}
    }
    return props
  },[post])

  const goToProfile = ()=>{
    navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", post.user.idol_id_name),
      {
        state:{
          fromURL:UnauthenticatedPaths.explore
        }
      })
  }

  return (
    <Box style={styles.container} onClick={onClick}>
      <Box sx={{ display:"flex",
        flexDirection:"column",
        }}>
      <Box style={styles.header}>
        <Box style={styles.counter}>
          <Typography style={styles.counterText}>{post.user.images}</Typography>
          <Box component={"img"} src={ImageIcon}  />
        </Box>
        <Box style={styles.counter}>
          <Typography style={styles.counterText}>{post.user.videos}</Typography>
          <Box component={"img"} src={VideoIcon}  styles={styles.icon}/>
        </Box>
      </Box>


      <Box onClick={onClick} {...contentProps} style={styles.img} />

      <Box style={styles.footer}>
        <BaseCardIdolInfo idolPoints={post.user.idol_points}
                          pointContainerStyles={post.pointContainer}
                          verifiedIconStyle={styles.verifiedIcon}
                          avatarStyles={styles.avatar}
                          onClick={goToProfile}
                          avatarContainerStyle={styles.avatarContainer}
                          infoCardStyles={styles.infoCard}
                          avatar={post.user.image}
                          idolizerName={post.user.username}
                          nameStyles={styles.name}
                          idpStyles={styles.idolPoints}
                          isVerified={post.user.is_validated} />
      </Box>
    </Box>
    </Box>
  )
})
ExplorePost.defaultProps = {
  post:{
    content: PostImage,
    user: {
      id:7,
      is_validated:true,
      idol_points:7500,
      videos: 124,
      images: 439,
      image: UserAvatar,
      username:"user@user.com"
    }
  }
}


const smallStyles = {
  avatarContainer :{
    marginRight: "-14px"
  },
  verifiedIcon:{
    position: 'absolute',
    right: 0,
    top: 25,
    width: '40%',
  },
  infoCard:{
    paddingLeft:"12px",
    paddingBottom:"12px",
    cursor:"pointer"
  },
  pointContainer:{
    fontSize:16,
    fontWeight:500,
    marginTop:"-10px"
  },
  idolPoints:{
    fontSize:16,
    fontWeight:500,
  },
  name:{
    fontSize:16,
    fontWeight:400,
    marginBottom:"-5px"
  },
  avatar:{
    width:"39px",
    height:"39px",
    marginRight:"4px"
  },
  icon:{
    width:"11px",
    height:"11px"
  },
  counterText:{
    marginTop:"2.3px",
    color: colors.white.main,
    fontSize:12,
    letterSpacing:"0.1em"
  },
  counter:{
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems:"center",
    gap:"3px",
    paddingRight:"9px",
    paddingLeft:"9px",
    minWidth:60,
    height:24,
    borderRadius:5,
    background: rgba(colors.background.default, 0.5)
  },
  footer:{
    position:"absolute",
    bottom:0,
    left:0,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    zIndex:2
  },
  header:{
    gap:5,
    position:"absolute",
    top:0,
    left:0,
    padding:"12px",
    width:"100%",
    height:"48px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-end",
    zIndex:2
  },
  img:{
    zIndex: 1,
    // width:"200px",
    // height:"292px",
    objectFit:"cover",
    cursor:"pointer",
    width:"200px",
    aspectRatio: 9/16
  },
  container:{
    position:"relative",
    width:"200px",
    // height:"292px",
    aspectRatio: 9/16
  }
}


const bigStyles = {
  avatarContainer :{
    marginRight: "16px"
  },
  verifiedIcon:{
    position: 'absolute',
    right: 0,
    top: 40,
    width: '40%',
  },
  infoCard:{
    paddingLeft:"12px",
    paddingBottom:"24px",
    cursor:"pointer",
  },
  pointContainer:{
    fontSize:20,
    fontWeight:500,

  },
  idolPoints:{
    fontSize:20,
    fontWeight:500
  },
  name:{
    fontSize:22,
    fontWeight:400
  },
  avatar:{
    width:"55px",
    height:"55px"
  },
  icon:{
    width:"11px",
    height:"11px"
  },
  counterText:{
    marginTop:"2.3px",
    color: colors.white.main,
    fontSize:12,
    letterSpacing:"0.1em"
  },
  counter:{
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems:"center",
    gap:"3px",
    paddingRight:"9px",
    paddingLeft:"9px",
    minWidth:60,
    height:24,
    borderRadius:5,
    background: rgba(colors.background.default, 0.5)
  },
  footer:{
    position:"absolute",
    bottom:0,
    left:0,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    zIndex:2
  },
  header:{
    gap:5,
    position:"absolute",
    top:0,
    left:0,
    padding:"12px",
    width:"100%",
    height:"48px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-end",
    zIndex:2
  },
  img:{
    zIndex: 1,
    width:"400px",
    aspectRatio: 9/16,
    objectFit:"cover",
    cursor:"pointer"
  },
  container:{
    height: "fit-content",
    position:"relative",
    width:"400px",
    aspectRatio: 9/16
  }
}
