import React from "react";
import ReactDOM from "react-dom/client"
import {BrowserRouter} from "react-router-dom";
import App from "App";

import {MaterialUIControllerProvider} from "context";
import StoreApp from "./StoreApp";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {SnackbarProvider} from "notistack";
import {SnackbarUtilsConfigurator} from "./services/notification";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

if (process.env.NODE_ENV !== 'development'){
  Sentry.init({
    dsn: "https://6d26a12297344b1a8b8d5219969a8ec2@sentry.innovatica.com.py//22",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

}


const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <SnackbarUtilsConfigurator/>
        <MaterialUIControllerProvider>
          <StoreApp>
            <App/>
          </StoreApp>
        </MaterialUIControllerProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
