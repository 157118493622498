/* dependencies */
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";

/* MUI components */

/* components */
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {IdolizerTransactionsPage} from './IdolizerTransactionsPage';
import {MobileIdolizerTransactionsCard} from "../../mobile/idolizers/mobileTransactionsCard";

/* utils */
import { useApi, useIsMobile } from "utils/hooks";

/* data */
import {transactions} from '../dummyData';
import {useStores} from "../../../../../models";
import {NavigationMenu} from "../../../../../constants";

/* assets */


export const IdolizerTransactions = observer(() => {
  const isMobile = useIsMobile();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const [Transactions, setTransactions] = useState([])

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("TRANSACTIONS");
    getTransactions()
  }, []);

  const getTransactions = (data) => { //TODO until now, returns nothing
    api.getTransactions(data)
        .then(res => {
          if (res.kind === 'ok') {
            setTransactions(res.data.results)
          } else {
            console.log("error", res)
          }
        })
        .catch(reason => console.log(reason))
  }

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {isMobile ? (
            <MobileIdolizerTransactionsCard transactionsData={Transactions}/>
        ) : (
            <IdolizerTransactionsPage transactions={Transactions}/>
        )}
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
})

