/* dependencies */
import React, {memo, useEffect, useState} from "react";
import moment from "moment";

/* MUI components */
import {Box, Typography} from "@mui/material";
import {upcomingMeetings} from 'pages/portal/dashboard/idolizer/dummyData';
import {IdolMeetingCardMobile} from "components/Mobile/IdolMeetingCardMobile";

/* modals */
import {MobileCancelMeetingModal} from "components/Mobile/MobileModals/CancelMeetingModal";
import {MobileLocationDetailsModal} from "components/Mobile/MobileModals/LocationDetailsModal";

/* utils */
import {useApi, useInfiniteScroll} from "utils/hooks";
import { useStores } from "models";
import {formatNumberToCurrency} from "utils/functions";
import InfiniteScroll from "react-infinite-scroll-component";


/* assets */
import colors from "assets/theme/base/colors"
import pxToRem from "assets/theme/functions/pxToRem";

const MobileMeetings = memo(({isWide, onApprove, onDismiss, isIdolizer, onJoin, onCancel, hasPendingMeetings=false, ...props}) => {
  const [Upcoming, setUpcoming] = useState(false)
  const [meetings, setMeetings] = useState([...upcomingMeetings])
  const {loginStore } = useStores();
  const [modalManager, setModalManager] = useState({cancelMeeting: false, locationDetails: false})
  const {cancelMeeting, locationDetails} = modalManager
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const api = useApi();

  const getMeetings = () => {
    api.getCustomMeetings().then(response => {
      if (response.kind === "ok") {
        // setMeetings(response.data)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  const cardProps = {
    approveButtonTextStyles: styles.button,
    dismissButtonTextStyles: styles.button,
    isPending: hasPendingMeetings,
  };


  useEffect(() => {
    getMeetings()
  }, [])

  const index = 1

  const onApiCall = ({page})=>{
    let data = {page}
    return api.getPendingMeetings(data)
  }

  const {
    items,
    fetchNextPage,
    hasNextPage,
    extraData,
    isFetchingNextPage
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "upcoming"})

  return (
    <Box>
      <InfiniteScroll
        dataLength={items?.length}
          next={() => {
            if (!isFetchingNextPage) {
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={null}>
        <Box sx={isWide ? { marginTop: "12px" } : { marginTop: "32px" }}>
          {items.map((m, idx) => {
            const user = loginStore.id === m.from_user.id? m.to_user : m.from_user
            const meetingInfo = {
              title: m.name,
              avatar: user.image,
              idolizerName: user.username,
              idolPoints: user.idol_points,
              isVerified: user.is_validated,              
              price: formatNumberToCurrency(m.fee),
              date: moment(m.start_datetime).format("D MMM YYYY").toUpperCase(),
              timeStart: moment(m.start_datetime).format("HH:mm A"),
              timeEnd: moment(m.end_datetime).format("HH:mm A"),
              timeZone: m.location,
              meetingType: !isIdolizer && m.meeting_type,
              isIdolizer,
            };
            return (
              <Box sx={isWide?{marginTop: "12px"}:{display: "inline-block", marginRight: "16px"}}>
              <IdolMeetingCardMobile
                {...cardProps}
                {...meetingInfo}
                key={m.id}
                isIdolizer={isIdolizer}
                onDismiss={() => {
                  setSelectedMeeting({ ...meetingInfo });
                  setModalManager({ ...modalManager, cancelMeeting: true });
                }}
                onApprove={() => {
                  setSelectedMeeting({ ...meetingInfo });
                  setModalManager({ ...modalManager, locationDetails: true });
                }}
                isWide={isWide}
              />
              </Box>
            );
          })}
        </Box>
      </InfiniteScroll>

      <MobileCancelMeetingModal
        open={cancelMeeting}
        onClose={() => setModalManager({ ...modalManager, cancelMeeting: false })}
        {...selectedMeeting}
      />

      <MobileLocationDetailsModal
        open={locationDetails}
        onClose={() => setModalManager({ ...modalManager, locationDetails: false })}
        {...selectedMeeting}
      />
    </Box>
  );
})

MobileMeetings.propTypes = {};

const styles = {

  button: {
    fontSize: pxToRem(12)
  },

  sectionHeader: {}
}

export default MobileMeetings;
