// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import {styled} from '@mui/material/styles';
import {OutlinedInput} from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

const {inputBorderColor, inputBackgroundColor} = colors;

export const getEndAdornmentTextStyle = (theme) => {
  return {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.size.sm,
    color: theme.palette.white.main,
  }
}


export const StyledTextField = styled(OutlinedInput)(({theme, inputBase}) => {
  return {
    background: inputBackgroundColor,
    height: inputBase && pxToRem(40),
    borderRadius: 5,

    input: {
      color: "white",
      borderColor: "red",
      padding: '0px 0.75rem',
      height: pxToRem(38),
    },
    opacity: 1,
    border: "1px solid " + inputBackgroundColor,
    borderColor: inputBackgroundColor,
    color: "white ",
    "& .MuiInputBase-inputMultiline ": {
      color: "white ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: inputBackgroundColor
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorderColor,
      },
      '& fieldset': {},
    },
  }
});

export const iconStyle = (theme, isError) => {

  return {
    color: inputBorderColor
  }
}
