/* dependencies */
import PropTypes from 'prop-types';

/* mui components */
import {Avatar, Box} from '@mui/material';

/* assets */
import {styles} from './styles';
import checked from 'assets/icons/checked.png'


export const UnverifiedIdolAvatar = ({avatarSrc, avatarStyles, isMobile}) => {
    return (
      <Avatar
        src={avatarSrc}
        alt={"avatar"}
        sx={[!isMobile ? styles.avatar : styles.mobile_avatar, avatarStyles]}
      />
    );
}

export const VerifiedIdolAvatar = ({avatarSrc, avatarStyles, isMobile, verifiedIconStyle, avatarContainerStyle}) => {
    return (
      <Box position="relative" style={avatarContainerStyle}>
        <Avatar
          src={avatarSrc}
          alt={"avatar"}
          sx={[!isMobile ? styles.avatar : styles.mobile_avatar, avatarStyles]}
        />
        <img src={checked} style={isMobile ? styles.mobile_checked_img :  {...styles.checked_img, ...verifiedIconStyle}} />
      </Box>
    );
}

UnverifiedIdolAvatar.propTypes = {
    avatarSrc: PropTypes.string
}

VerifiedIdolAvatar.propTypes = {
    avatarSrc: PropTypes.string
}
