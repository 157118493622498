import dollarBag from "./assets/icons/price.svg";
import people from "./assets/icons/people.svg";
import bellRed from "./assets/icons/bell-red.svg";
import calendar from "./assets/icons/calendar1.svg";
import dollarWhite from "./assets/icons/dollar_white.svg";
import InfluencerIcon from "./assets/icons/influencer.svg";
import ProfessionalIcon from "./assets/icons/professional.svg";
import EntrepreneurIcon from "./assets/icons/entrepreneur.svg";

const LayoutNames = {
  general: "dashboard",
  admin: "dashboard",
  onboarding: "onboarding",
  idolProfile: "idolProfile",
  meetMe: "meetMe",
  exploreMobile:"explore-mobile"
}

const ScreenType = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}

const NavigationMenu = {
  DASHBOARD: 'dashboard',
  SETTINGS: 'settings',
  EXPLORE: 'explore',
  PROFILE: 'profile',
  EDITING_PROFILE: 'editing-profile',
  NOTIFICATIONS: 'notifications',
}

const FeeEnum = {
  subscription: "subscription",
  tip: "tip",
}

const SubscriptionType = {
  monthly: "monthly",
  bundle: "bundle",
  promotion: "promotion",
}


const PostCategoryEnum = {
  image: "image",
  video: "video",
}

const UserTypes = {
  Admin: "admin",
  Idol: "zee",
  Idolizer: "zer",
  Guest: "guest",
}

const CHAT_ADMIN_NAME = 'AdminUserChat'


const IdolTypes = {
  Influencer: 10,
  Professional: 20,
  Entrepreneur: 30,
}

const MessageTypes = {
  VERIFICATION_APPROVED: 1,
  VERIFICATION_DENIED: 2,
  MEETING_APPROVED: 3,
  MEETING_REJECTED: 4,
}

const Units = {
  Imperial: {
    id: 10,
    measurementUnit: 'in',
    isImperial: true,
    isMetric: false,
  },
  Metric: {
    id: 20,
    measurementUnit: 'cm',
    isImperial: false,
    isMetric: true,
  }
}

const IdolTypesInformation = {
  [IdolTypes.Influencer]: {
    name: "INF",
    logo: InfluencerIcon,
  },
  [IdolTypes.Professional]: {
    name: "PRO",
    logo: ProfessionalIcon,
  },
  [IdolTypes.Entrepreneur]: {
    name: "ENT",
    logo: EntrepreneurIcon,
  },
}

const autoHideSnackbarDuration = 2000

const NotificationCategories = {
  subscription: {message: "Started Idolizing you", icon: dollarBag},
  follow: {message: "Started following you", icon: people},
  locked: {message: "Locked", icon: bellRed},
  meet: {message: "Booked a 60-min QnA", icon: calendar},
  payment: {message: "Payment", icon: bellRed},
  callreq: {message: "CallRequest", icon: bellRed},
  callrem: {message: "CallReminder", icon: bellRed},
  tip: {message: "Left you a tip", icon: dollarWhite},
  rating: {message: "Rating", icon: bellRed},
  reminder: {message: "Reminder", icon: bellRed},
  renew: {message: "Renew", icon: bellRed},
  other: {message: "Other", icon: bellRed},
}

const MessageInfo = {
  [MessageTypes.VERIFICATION_APPROVED]: {
    title: "YOU GOT VERIFIED!",
    message: "Your verification badge will be valid for 3 months.",
  },
  [MessageTypes.VERIFICATION_DENIED]: {
    title: "VERIFICATION DENIED",
    message: "Your verification has been denied by the admin. The verification fee is non refundable. You cannot access live meet & greets with idols.",
  },
  [MessageTypes.MEETING_APPROVED]: {
    title: "MEETING APPROVED",
    message: "Idol has approved your booking request.",
  },
  [MessageTypes.MEETING_REJECTED]: {
    title: "MEETING REJECTED",
    message: "Idol had denied your booking request. You won't get charged on your credit card.",
  }
}

export {
  NavigationMenu,
  ScreenType,
  UserTypes,
  IdolTypes,
  MessageTypes,
  Units,
  PostCategoryEnum,
  FeeEnum,
  CHAT_ADMIN_NAME,
  LayoutNames,
  autoHideSnackbarDuration,
  SubscriptionType,
  NotificationCategories,
  IdolTypesInformation,
  MessageInfo,
}
