import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import {basicCheckboxStyle} from "./styles";
import {FormControlLabel, FormGroup, FormHelperText} from "@mui/material";
import MDTypography from "../MDTypography";


export const BasicCheckbox = (props) => {
  const {
    label, error, helpertext, labelStyle = {}, checked, onChange,
    checkboxStyle, borderNone = false,
    formGroupStyle,
    checkedIcon, icon, ...rest
  } = props
  return (

    <FormGroup style={{...formGroupStyle}}>
      <FormControlLabel
        control={
          <Checkbox
            color={"default"}
            onChange={onChange}
            checked={checked}
            label={label}
            checkedIcon={checkedIcon && <img src={checkedIcon} alt='checked' width='24px'/>}
            icon={icon && <img src={icon} alt='checkbox'/>}
            {...rest}
            sx={(theme) => basicCheckboxStyle(theme, borderNone, checkboxStyle)}
          />
        }
        label={<MDTypography color={"white"}
                             sx={{fontSize: 16, marginLeft: "0px", ...labelStyle}}>{label}</MDTypography>}/>

      {helpertext && (
        <FormHelperText>
          <MDTypography sx={{fontSize: 14}} color={"error"}>{helpertext}</MDTypography>
        </FormHelperText>
      )}
    </FormGroup>


  )
}
