import React, {memo, useState} from "react";
import {Box, Typography} from "@mui/material";
import {styles} from "./styles";
import VerifiedIcon from "../../../assets/icons/checked.png";
import numeral from "numeral";
import CountersAndTags from "../commons/countersAndTags";
import {SecondaryButton} from "../../../components/SecondaryButton";
import {useApi, useIsMobile, useUserStore} from "../../../utils/hooks";
import {notifyError, notifySuccess} from "../../../services/notification";
import {MoreVert as ThreeDotsIcon} from "@mui/icons-material";
import {IdolsActions} from "../../portal/dashboard/idolizer/idols/actions";
import {BlockUserModal} from "../../../components/BlockUserModal";
import {useNavigate, useParams} from "react-router-dom";
import {ReportUserModal} from "../../../components/ReportUserModal";
import {SubscribeModal} from "./SubscribeModal";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import {AuthenticatedPaths, UnauthenticatedPaths} from "../../../paths/paths";
import { IdolTypeEnum } from "../../../utils/constants";


export const IdolProfileSummary = memo(({profile, getProfile}) => {
  const navigate = useNavigate()
  const [showBlockUserModal, setShowBlockUserModal] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showReportUserModal, setShowReportUserModal] = useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
  const isInfluencer = profile.idol_type === IdolTypeEnum.influencer
  const user = useUserStore()
  const myProfileId = user.idol_id_name
  const {idol_id_name} = useParams();
  const isMyProfile = idol_id_name === myProfileId
  const api = useApi()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMobile = useIsMobile();

  const followButton = isMobile ? {...styles.mobileButton} : {...styles.leftColumnButton}
  if(!isMobile && isInfluencer){
    followButton.width = 240
  }

  if (!profile || !profile.id) {
    // notifyError("No Idol info")
    return
  }

  const follow = () => {
    api.follow(profile.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Following " + profile.username)
        getProfile()
      } else {
        notifyError("Error following " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error following " + profile.username)
    })
  }


  const unfollow = () => {
    if (!profile.id) {
      notifyError("No Idol information to unfollow")
      return
    }
    api.unfollow(profile.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Unfollowing " + profile.username)
        getProfile()
      } else {
        notifyError("Error unfollowing " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unfollowing " + profile.username)
    })
  }

  const unsubscribe = () => {
    api.unsubscribe(profile.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("You stop idolizing " + profile.username)
        getProfile()
      } else {
        notifyError("Error unsubscribing " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unsubscribing " + profile.username)
    })
  }

  const goToMeetMe = () => {
    navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", profile.idol_id_name))
  }

  return (
    <Box style={isMobile ? {} : {...styles.leftColumn}}>
      <Box style={isMobile ? {backgroundColor: "#182536", borderRadius: "10px"} : {}}>
        <Box style={isMobile ? {} : {marginTop: 90}}/>
        {profile.id && (
          <React.Fragment>
            <SubscribeModal open={showSubscriptionModal}
                            profile={profile}
                            onSave={() => {
                              setShowSubscriptionModal(false)
                              getProfile()
                            }}
                            onClose={() => setShowSubscriptionModal(false)}/>
            <ConfirmPaymentModal
              open={showUnsubscribeModal}
              message={`Are you sure you want to unsubscribe from ${profile.username}`}
              confirmAction={() => {
                unsubscribe()
                setShowUnsubscribeModal(false)
              }}
              confirmLabel={"CONFIRM"}
              cancelAction={() => setShowUnsubscribeModal(false)}
            />

          </React.Fragment>

        )}


        <BlockUserModal user={profile}
                        open={showBlockUserModal}
                        onBlock={() => {
                          navigate(UnauthenticatedPaths.explore)
                        }}
                        onClose={() => setShowBlockUserModal(false)}/>

        <ReportUserModal user={profile}
                         open={showReportUserModal}
                         onReport={() => {
                           navigate(UnauthenticatedPaths.explore)
                         }}
                         onClose={() => setShowReportUserModal(false)}/>
        <IdolsActions
          anchorEl={anchorEl}
          type={profile.following ? "following" : "idolizing"}
          onReport={() => setShowReportUserModal(true)}
          onBlock={() => setShowBlockUserModal(true)}
          onClose={() => {
            setAnchorEl(null)
          }}
          open={Boolean(anchorEl)}
          profile={profile}
        />

        <Box style={{...styles.rowCenter}}>
          <Typography
            style={isMobile ? {...styles.username, marginTop: "20px"} : {...styles.username}}>{profile.username}
            {profile.isVerified && (
              <img src={VerifiedIcon} style={{width: 23, height: 23, marginLeft: "10px"}}/>
            )}
          </Typography>


          {!isMyProfile && user?.id && (
            <ThreeDotsIcon sx={styles.threeDots}
                           onClick={(event) => setAnchorEl(event.currentTarget)}/>
          )}

        </Box>
        <Box style={styles.rowCenter}>
          <Typography style={styles.idolPoints}>{numeral(profile.idolPoints).format('0,0')}  </Typography>
          <Typography style={styles.idp}>idp</Typography>
        </Box>
        <Box style={isMobile ? {} : {...styles.leftColumnCard}}>
          <CountersAndTags profile={profile}/>
          <Typography style={styles.bio}>{profile.bio}</Typography>
          <Typography style={styles.location}>{profile.location}</Typography>
        </Box>
      </Box>

      <Box
        style={isMobile
          ? {...styles.mobileButtonContainer}
          : {...styles.leftColumnButtonsContainer, justifyContent: "center"}
        }
      >
        {isMyProfile &&
          <SecondaryButton
            text={"NEW POST"}
            onClick={() => {
              navigate(AuthenticatedPaths.addNewPost)
            }}
            textStyles={styles.lefColumnButtonText}
            buttonStyle={isMobile ? { ...styles.mobileButton } : { ...styles.leftColumnButton }}
          />
        }
        {user && user.id && !isMyProfile && (
          profile.following
            ? <SecondaryButton
              text={"UNFOLLOW"}
              onClick={unfollow}
              textStyles={styles.lefColumnButtonText}
              buttonStyle={followButton}
            />

            : <SecondaryButton
              text={"FOLLOW"}
              onClick={follow}
              textStyles={styles.lefColumnButtonText}
              buttonStyle={followButton}
            />
        )
        }

        {(isMyProfile && !isInfluencer) ?
          <SecondaryButton
            text={"NEW EVENT"}
            onClick={() => {navigate(AuthenticatedPaths.dashboardEvents)}}
            textStyles={styles.lefColumnButtonText}
            buttonStyle={isMobile ? {...styles.mobileButton} : {...styles.leftColumnButton}}
          />
          : (
            (!isInfluencer && user && user.id) && (
              <SecondaryButton
                text={profile.idolizing ? "UNSUBSCRIBE" : "IDOLIZE ME"}
                onClick={() => {
                  if (profile.idolizing) {
                    setShowUnsubscribeModal(true)
                  } else {
                    setShowSubscriptionModal(true)
                  }
                }}
                textStyles={styles.lefColumnButtonText}
                buttonStyle={isMobile ? {...styles.mobileButton} : {...styles.leftColumnButton}}
              />
            ))
        }
      </Box>
      <Box sx={{marginTop: "20px"}}>
        {isMyProfile ?
          <SecondaryButton
            text={"EDIT PROFILE"}
            onClick={() => {
              navigate(AuthenticatedPaths.profile)
            }}
            buttonStyle={styles.meetMeButton}
            textStyles={styles.meetMeText}
          />
          : (
            (!isInfluencer && user && user.id && (
              <SecondaryButton
                text={"MEET ME"}
                onClick={goToMeetMe}
                buttonStyle={styles.meetMeButton}
                textStyles={styles.meetMeText}
              />
            ))

          )

        }
      </Box>


    </Box>
  )
})
