import colors from "assets/theme/base/colors";

export const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    padding: "30px",
  },
  modalContent: {
    backgroundColor: colors.background.default,
    boxShadow: 24,
    borderRadius: "10px"
  },
  titleContainer: {
    height: "69px",
    backgroundColor: "#1D283B",
    borderRadius: "10px 10px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "16px",
    color: "#fff",
    lineHeight: "127%",
    fontWeight: 700,
  },
  subtitlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "25px 0px 25px 0px",
  },
  userType: {
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
  },
  dialog: {
    color: "#fff",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "127%",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
  },
  secondaryButton: {
    minWidth: "196px",
    width: "196px",
    minHeight: "42px",
    height: "42px",
  },
  textStyles: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "96%",
  },
  cancelButton: {
    fontSize: "12px",
    color: "#fff",
    lineHeight: "127%",
    fontWeight: 400,
  },
};
