import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useNavigate, useParams} from "react-router-dom";

// @mui components
import {CircularProgress, Grid} from "@mui/material";

// Components
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {SecondaryButton} from "components/SecondaryButton";
import {BasePostCard} from "base/Cards/PostCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {ImportContent} from "../../portal/importContent/ImportContentCard";
import {IdolTypesInformation} from "../../../constants";

// Utils
import {useApi} from "utils/hooks";
import {showMessage} from "services/helpers";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import GoBackIcon from "assets/icons/go_back.svg";

export const AdminApproveContentPage = () => {

  const navigate = useNavigate();
  const {userId} = useParams();
  const api = useApi();
  const formik = useFormik({
    initialValues: {import_posts: []},
    onSubmit: (values) => approveContent(values),
  });
  const [user, setUser] = useState(null);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingScrapper, setLoadingScrapper] = useState(false);

  const getUser = () => {
    setLoading(true);
    api.getProfile(userId)
      .then(response => {
        if (response.kind === "ok") {
          setUser(response.data);
        }
      })
      .catch(error => showMessage(error.message))
  }

  const getContents = () => {
    api.postsPendingModeration(userId)
      .then(response => {
        if (response.kind === "ok") {
          setContents(response.data?.posts);
          formik.setFieldValue('import_posts', response.data?.posts);
        }
      })
      .catch(error => showMessage(error.message))
      .finally(() => setLoading(false))
  }

  const approveContent = (values) => {
    setLoadingScrapper(true);
    api.scrapeRequestModeratePosts(userId, values)
      .then(response => {
        if(response.kind === "ok") {
          showMessage("Content imported", "success")
        }
      })
      .catch(error => showMessage(error.message))
      .finally(() => setLoadingScrapper(false))
  }

  const goBack = () => navigate(-1);

  useEffect(() => {
    getUser();
    getContents();
  }, [])

  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox display='flex' gap={pxToRem(24)} mt={pxToRem(34)} ml={pxToRem(56)} mr={pxToRem(98)}>
        <MDBox onClick={goBack} mt={pxToRem(42)} sx={{cursor: "pointer"}}>
          <MDBox component="img" src={GoBackIcon} alt="go back" />
        </MDBox>
        <BasePostCard
          title="IMPORTED CONTENT"
          headerControls={[
            <SecondaryButton
              text={!loadingScrapper ? 'APPROVE CONTENT' : <CircularProgress size='1rem' />}
              width={pxToRem(292)}
              height={pxToRem(40)}
              textStyles={{fontSize: "14px", margin: loadingScrapper && "auto"}}
              disabled={!formik.values.import_posts.some(p => p?.checked) || loadingScrapper}
              onClick={formik.handleSubmit}
            />
          ]}
          cardStyles={{padding: `24px`, width: "100%", mb: pxToRem(24)}}
        >
          {loading
            ? <MDBox display='flex' justifyContent='center' mt={pxToRem(24)}><CircularProgress/></MDBox>
            : (
              <MDBox mt={pxToRem(24)}>
                <MDBox display='flex' alignItems='center' gap={pxToRem(65)} px={pxToRem(25)}>
                  <MDBox display='flex' alignItems='center' gap={pxToRem(27)}>
                    <MDBox
                      alignItems='center'
                      display='flex'
                      flexDirection='column'
                      gap={pxToRem(9)}
                      height={pxToRem(76)}
                      justifyContent='center'
                      width={pxToRem(71)}
                      sx={({palette: {darkBlue}}) => ({backgroundColor: darkBlue[200], borderRadius: '10px'})}
                    >
                      <MDBox component='img' alt='logo' src={IdolTypesInformation?.[user?.idol_type]?.logo} width={pxToRem(35)} height={pxToRem(35)}/>
                      <MDTypography color='light' variant='button'>{IdolTypesInformation?.[user?.idol_type]?.name}</MDTypography>
                    </MDBox>
                    <MDBox display='flex' alignItems='center' gap={pxToRem(21)}>
                      <MDBox component='img' src={user?.image} width={pxToRem(49)} height={pxToRem(49)} sx={{borderRadius: '50px'}} alt={`${user?.username} image`}/>
                      <MDBox display='flex' flexDirection='column' gap={pxToRem(5)}>
                        <MDTypography color='light' size='md' fontWeight='semiMedium'>{`${user?.first_name} ${user?.last_name}`}</MDTypography>
                        <MDTypography color='primary' variant='button' fontWeight='semiMedium'>{user?.idol_points} idp</MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDTypography color='gray' size='md' fontWeight='regular'>{user?.email}</MDTypography>
                </MDBox>
                <MDBox mt={pxToRem(22)} width='100%'>
                  {contents.length === 0
                    ? <MDTypography color='light' size='md'>There are not content of this idol to approve </MDTypography>
                    : (
                     <MDBox>
                       <MDBox px={pxToRem(24)}>
                         <Grid container px={pxToRem(26)}>
                           <Grid item lg={2}><MDTypography color='light' size='md'>Import</MDTypography></Grid>
                           <Grid item lg={1}><MDTypography color='light' size='md' sx={{ml: 1, width: {xl: pxToRem(77), xxl: '100%'}}}>Preview</MDTypography></Grid>
                           <Grid item lg={5}><MDTypography color='light' size='md' textAlign='center'>Description</MDTypography></Grid>
                           <Grid item lg={2}><MDTypography color='light' size='md' textAlign='center'>Tier</MDTypography></Grid>
                           <Grid item lg={2}><MDTypography color='light' size='md' textAlign='center'>Price</MDTypography></Grid>
                         </Grid>
                       </MDBox>
                       <MDBox mt={pxToRem(29)} display='flex' flexDirection='column' gap={pxToRem(10)} maxHeight={pxToRem(450)} px={pxToRem(25)} overflow='hidden auto'>
                         {contents.map((content, index) => (
                           <ImportContent
                             key={content?.id}
                             index={index}
                             content={content}
                             formik={formik}
                             baseFormikName="import_posts"
                           />
                         ))}
                       </MDBox>
                     </MDBox>
                    )
                  }

                </MDBox>
              </MDBox>
            )
          }

        </BasePostCard>
      </MDBox>
    </DashboardLayout>
  )
}