import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {CustomTextField} from "../../../components/TextField";
import {Link, useNavigate} from "react-router-dom";
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {routes} from "../../../routes";

import {useApi, useUserStore} from "../../../utils/hooks";
import {useTheme} from "@mui/material/styles";
import {Box, Grid, useMediaQuery} from "@mui/material";
import {notifyError, notifySuccess} from "../../../services/notification";
import {useStores} from "../../../models";
import {GuestPaths} from "../../../paths/guestPaths";
import { UserTypes } from "../../../constants";
import { AdminPaths } from "../../../paths/adminPaths";

const {white} = colors;

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const Login = observer(() => {
  const navigate = useNavigate()
  const api = useApi()
  const rootStore = useStores()
  const {onboarding, meetingStore} = rootStore
  const userStore = useUserStore()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileH = useMediaQuery('(min-height:700px)');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const referrer = urlParams.get('referrer')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      api.login(values).then(data => {
        console.log(' data -> ', data)
        if (data.kind === "ok") {
          userStore.setUser(data.response.user)
          userStore.setApiToken(data.response.token)
          userStore.setProfilePictureVersion(Date.now())
          notifySuccess('Login Successful!')
          if (referrer) {
            window.open(decodeURIComponent(referrer), '_blank');
          }
          if (userStore.user_type_codename === UserTypes.Admin) {
            navigate("/adm/all-users/")
          } else{
            navigate(routes[userStore.user_type_codename].home.path)
          }
        } else {
          let key = Object.keys(data.errors)[0]
          notifyError(data.errors[key])
        }
      }).catch(reason => {
        notifyError('There is something wrong!')
        console.log(reason)
      })
    },
  });

  useEffect(() => {
    onboarding.reset()
    meetingStore.reset()
  }, [])

  return (
    <OnboardingLayout titleBold={"LOG"} titleNormal={"IN"} backTo={GuestPaths.getStarted}>
      <Box style={{
        position: isMobile && !isMobileH ? "relative" : "unset",
        display: isMobile ? (isMobileH ? "unset" : "block") : "unset"
      }}>
        <Box sx={isMobile ? {width: "100%", mt: 4} : {minWidth: "400px", mt: 4}}>
          <form onSubmit={formik.handleSubmit}>
            <MDBox mb={2} mt={0}>
              <CustomTextField
                fullWidth
                inputLabel={"Email"}
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </MDBox>
            <MDBox mb={4} mt={2}>
              <CustomTextField
                fullWidth
                id="password"
                name="password"
                type="password"
                inputLabel={"Password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
              <Link to={GuestPaths.forgotPassword}>
                <MDTypography sx={{...styles.defaultText, ...styles.link, fontStyle: "italic", mt: 1}}>Forgot
                  password?</MDTypography>
              </Link>

            </MDBox>
            <MDBox mb={4} mt={8}>
              <PrimaryButton type={"submit"} text={"LOG IN"}/>
            </MDBox>
          </form>
          <Box
            sx={isMobile ? isMobileH ? {...styles.footerBottom} : {...styles.footerFixed} : {...styles.footerMobile}}>
            <Grid container gap={1}>
              <Grid item sm={6} lg={7} sx={{textAlign: "right"}}>
                <MDTypography sx={{...styles.defaultText, marginRight: "2px"}}>{`Don't have an account?`}</MDTypography>
              </Grid>
              <Grid item sm={6} lg={4}>
                <Link to={GuestPaths.whoAreYou}>
                  <MDTypography sx={{
                    ...styles.defaultText,
                    textDecoration: "underline",
                    textAlign: "left",
                    fontWeight: "bold",
                    marginLeft: "2px"
                  }}>
                    Sign Up here!</MDTypography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

    </OnboardingLayout>
  );
});

export default Login;

const styles = {
  defaultText: {
    color: white.main,
    fontSize: 16,
  },
  link: {
    textDecoration: "underline",
    textAlign: "right",
  },

  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  footerFixed: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    paddingX: "7px"
  },
  footerMobile: {
    position: "absolute",
    bottom: "20px",
    textAlign: "center",
    display: "flex",
    width: "400px",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "auto"
  },
  footerBottom: {
    position: "absolute",
    bottom: 20,
    paddingX: "30px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }
}
