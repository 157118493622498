import React, { useEffect, useState } from "react";
/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseMobileTipsTransactionsCard} from 'components/Mobile/BaseMobileTipsTransactionsCard';
import {RecordCard} from 'components/RecordCard/RecordCard';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {MobileSectionHeader} from 'components/Mobile/MobileIdolDashboardSectionHeader';
import OrderingButton from 'components/WithdrawButton/OrderingButton';

/* data */
import {idolTransactions} from 'pages/portal/dashboard/idolizer/dummyData';

/* utils */
import {formatNumberToCurrency} from 'utils/functions';

/* assets */
import TransactionsIcon from 'assets/icons/withdrawls.svg';
import VideoCallIcon from "assets/icons/events.svg";
import KissGroupIcon from "assets/icons/people.svg";
import PriceTagIcon from "assets/icons/price-tag.svg";
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import MeetIcon from "../../../../assets/icons/calendar1.svg";
import LiveCallIcon from "../../../../assets/icons/events.svg";
import FollowIcon from "../../../../assets/icons/people.svg";
import SubscriptionIcon from "../../../../assets/icons/price.svg";
import { filteringFields } from "../../../transactions/constants";
const {white, red} = colors;


const transactionOriginIcon = {
  meet_greet:MeetIcon,
  live_call:LiveCallIcon,
  follow:FollowIcon,
  subscription:SubscriptionIcon
}

export const MobileTransactionsCard = ({records, orderByType, orderByDate, orderRecordsBy }) => {
  const [filterSelected, setFilterSelected] = useState(orderRecordsBy);
  const [TypeAsc, setTypeAsc] = useState(false)
  const [DateAsc, setDateAsc] = useState(false)

  useEffect(() => {
    console.log(records)
  },[records])

  useEffect(() => {
    orderByType(TypeAsc)
  }, [TypeAsc])

  useEffect(() => {
    orderByDate(DateAsc)
  }, [DateAsc])

  const selectFilter = (value) => {
    if(filterSelected !== value){
      setFilterSelected(value)
    }else{
      if(value === filteringFields.OriginType){
        setTypeAsc(prevState => !prevState)
      }else{
        setDateAsc(prevState => !prevState)
      }
    }
  }

  const recordProps = {
    showOptionsCard: false,
    isMobile: true,
    containerStyles: styles.transactions_container,
    contentContainerStyles: styles.content_container,
    cardThirdContentStyles: styles.mini_card,
  };

  return (
    <Box>
      <MobileSectionHeader Title="Transactions" Icon={TransactionsIcon} />
      <Box sx={styles.order_buttons_container}>
        <OrderingButton
          text="TYPE"
          currentFilterSelected={filterSelected === filteringFields.OriginType}
          onClick={() => selectFilter(filteringFields.OriginType)}
          size='small'
        />
        <OrderingButton
          text="DATE"
          currentFilterSelected={filterSelected === filteringFields.Created}
          onClick={() => selectFilter( filteringFields.Created)}
          size='small'
        />
      </Box>


      <BaseMobileTipsTransactionsCard>
        {records.map((record) => (
          <RecordCard
            {...recordProps}
            cardFirstContent={
              <BaseCardIdolInfo
                nameStyles={{minWidth:pxToRem(120)}}
                idolizerName={`${record.user.first_name} ${record.user.last_name}`}
                avatar={`${record.user.thumbnail}?height=150&width=150`}
                isVerified={record.user.is_validated}
                isIdolizer={false}
                idolPoints={record.user.idol_points}
                isMobile={true}
              />
              }
            cardSecondContent={
              <Box sx={styles.transaction_info_container}>
                <Box sx={styles.transaction_amount_info_container}>
                  <Typography sx={styles.amount}>{formatNumberToCurrency(record.amount)}</Typography>
                  <Typography sx={styles.points_earned}>+{record.idol_points} idp</Typography>
                </Box>
                <Box sx={styles.icon_container}>
                  <img src={transactionOriginIcon[record.origin_type]} alt={"alt"} style={styles.icon} />
                </Box>
              </Box>
            }
          />
        ))}
      </BaseMobileTipsTransactionsCard>
    </Box>
  );
};

const styles = {
  mini_card: {
    width: 0,
    padding: 0,
  },
  order_buttons_container: {
    display: 'flex',
    gap: pxToRem(14),
    marginTop: pxToRem(14),
  },
  transactions_container: {
    margin: 0
  },
  content_container: {
    padding: `${pxToRem(9)} ${pxToRem(4.94)}`,
  },
  transaction_info_container: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    paddingBlock: pxToRem(18),
    justifyContent: 'flex-end',
  },
  transaction_amount_info_container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 auto'
  },
  amount: {
    color: white.main,
    fontSize: pxToRem(22),
    fontWeight: 400,
    lineHeight: pxToRem(26),
    textAlign: 'right'
  },
  points_earned: {
    alignSelf: 'flex-end',
    color: red.main,
    fontSize: pxToRem(12),
  },
  icon_container: {
      margin: 'auto',
      paddingLeft: pxToRem(22),
      width: '100%',
  },
  icon: {
    width: pxToRem(19),
    height: pxToRem(19),
  },
};
