import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
const {white, transparent, modal, card} = colors;

export const styles = {
  card: {
    background: card.background,
    paddingBlock: "1.5rem",
    justifyContent: "center",
    width: '100%',
  },
  content_container: {
    paddingInline: 1,
  },
  fields_titles_container: {
    paddingRight: 4
  },
  field_title: {
    color: white.main,
    // textAlign: "center",
    fontSize: pxToRem(16)
  },
  header_container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: pxToRem(80),
  },
  header_title: {
    color: white.main,
    fontSize: pxToRem(36),
    fontWeight: 400,
    lineHeight: pxToRem(42),
    textTransform: "uppercase",
  },
  actions: {
    display: "flex",
    gap: pxToRem(22),
  },
  button: {
    fontSize: pxToRem(14),
    fontWeight: 700,
  },
  cancel_button: {
    background: transparent.main,
    borderColor: modal.buttons.cancel,
    height: pxToRem(40),
    padding: 0,
    width: pxToRem(145),
  },
  cancel_button_text: {
    color: modal.buttons.cancel,
  },
  import_button: {
    height: pxToRem(40),
    padding: 0,
    width: pxToRem(292),
  },
  card_container: {
    // marginTop: pxToRem(40),
  },
  pre: {
    color: white.main,
    whiteSpace: 'pre-line',
  },
  scrapperLoader: {
  },
};
