/* dependencies */
import PropTypes from 'prop-types';

/* mui components */
import {Avatar, Box} from '@mui/material';

/* assets */
import {styles} from './styles';
import checked from 'assets/icons/checked.png'


export const UnverifiedMobileIdolAvatar = ({avatarSrc}) => {
    return <Avatar src={avatarSrc} alt={'avatar'} sx={styles.avatar} />
}

export const VerifiedMobileIdolAvatar = ({avatarSrc}) => {
    return (
        <Box position='relative'>
            <Avatar src={avatarSrc} alt={'avatar'} sx={styles.avatar} />
            <img src={checked} style={styles.checked_img} />
        </Box>
    )
}

UnverifiedMobileIdolAvatar.propTypes = {
    avatarSrc: PropTypes.string
}

VerifiedMobileIdolAvatar.propTypes = {
    avatarSrc: PropTypes.string
}
