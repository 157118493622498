import { Stack } from "@mui/material";
import { SecondaryButton } from "../SecondaryButton";
import { GuestPaths } from "../../paths/guestPaths";
import { GenericModal } from "../GenericModal";
import React from "react";
import { useNavigate } from "react-router-dom";


export const NeedLoginModal = ({ open, onClose })=>{
  const navigate = useNavigate()

  return (
    <GenericModal title='You need to log in to do this action ' onClose={onClose} open={open}>
      <Stack alignItems={'center'} justifyContent={'center'} width={'100%'}>
        <SecondaryButton  text={"Go to Login"} onClick={()=>navigate(GuestPaths.login)} buttonStyle={{width: '60%', height:"30px"}}/>
      </Stack>
    </GenericModal>
  )
}
