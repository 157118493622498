import pxToRem from "../../assets/theme/functions/pxToRem";

const avatarStyles = (theme, size) => {
  return {width: size, height: size}
}

const labelStyles = (theme) => {
  return {
    fontSize: theme.typography.fontSize2XL,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
    paddingY: "1rem",
    textAlign: "center"
  }
}
const labelMobileStyles = (theme) => {
  return {
    fontSize: "10px",
    fontWeight: 400,
    color: theme.palette.primary.white,
    paddingY: "1rem",
    textAlign: "center",
    lineHeight: "140%",
  }
}
const boxStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }
}

const avatarBoxStyles = (theme, avatarSize) => {
  return {
    width: avatarSize,
    height: avatarSize,
    background: 'rgba(255, 255, 255, 0.2)',
    border: '2px dashed #FFFFFF',
    borderRadius: '256px',
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
}

export {avatarStyles, labelStyles, boxStyles, labelMobileStyles, avatarBoxStyles};
