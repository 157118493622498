import { CardForm } from "../components/CardForm";
import { Box } from "@mui/material";
import { CustomTextField } from "../../../../components/TextField";
import { SaveButton } from "../components/SaveButton";
import React, { Fragment } from "react";
import { globalStyles } from "../globalStyles";
import * as Yup from "yup";
import { requiredMessage, TabName } from "../constants";
import { Form, Formik } from "formik";
import { TextField } from "../components/TextField";

const validationSchema = Yup.object({
  POINTS_FOLLOW: Yup.string().required(requiredMessage),
  POINTS_SUBSCRIBE: Yup.string().required(requiredMessage),
});

export const InteractionForm = ({config, onSave, tab})=>{
  return (
    <CardForm  title={"Interaction"}>
      <Formik
        initialValues={{
          POINTS_FOLLOW: config?.POINTS_FOLLOW ?? 0,
          POINTS_SUBSCRIBE: config?.POINTS_SUBSCRIBE ?? 0,
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Fragment>
            <Box component={Form} sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>

              <Box sx={{...globalStyles.container}} >
                <Box sx={globalStyles.inputGroup}>
                  <CustomTextField
                    inputLabel={tab === TabName.idolizers? "Tip an Idol":"Received a Tip "}
                    sx={globalStyles.input}
                    name="name"
                    value={"$ value"}
                  />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Give to Idol"}
                      sx={globalStyles.input}
                      name="name"
                      value={"$ value"}
                    />
                  )}

                </Box>
              </Box>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_FOLLOW"}
                             inputLabel={tab === TabName.idolizers? "Follow an Idol":"Received a Follower"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Give to Idol"}
                      sx={globalStyles.input}
                      value={props.values.POINTS_FOLLOW}
                      customEndAdornmentText={"idp"}
                    />
                  )}

                </Box>
              </Box>

              <Box sx={{...globalStyles.container, mt:2}} >
                <Box sx={globalStyles.inputGroup}>
                  <TextField name={"POINTS_SUBSCRIBE"}
                             inputLabel={tab === TabName.idolizers? "Idolize an Idol": "Receive an Idolizer"}
                             sx={globalStyles.input}
                             max={1000000}
                             type={"number"}
                             customEndAdornmentText={"idp"} />

                  {tab === TabName.idolizers && (
                    <CustomTextField
                      inputLabel={"Give to Idol"}
                      sx={globalStyles.input}
                      customEndAdornmentText={"idp"}
                      value={props.values.POINTS_SUBSCRIBE}
                    />
                  )}

                </Box>
                <Box sx={{mt:2}}>
                  <SaveButton />
                </Box>
              </Box>

            </Box>
          </Fragment>
        )}
      </Formik>

    </CardForm>
  )
}

