import React, { memo, useReducer, useState } from "react";
import { Box } from "@mui/material";
import { BasePostCard } from "../../../base/Cards/PostCard";
import { PrimaryButton } from "../../../components/PrimaryButton";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import { initialRelatedState, relatedFilter, relatedFilterReducer } from "./reducers";
import colors from "assets/theme/base/colors";
import { useApi, useInfiniteScroll } from "../../../utils/hooks";
import { IdolCard } from "./idolCard/idolCard";
import { BlockUserModal } from "../../../components/BlockUserModal";
import { ReportUserModal } from "../../../components/ReportUserModal";

export const IdolizersCard = memo((props) => {
  const {filterCounter, isInfluencer} = props
  const [filter, dispatchFilter] = useReducer(relatedFilterReducer, initialRelatedState)
  const api = useApi()
  const [selected, setSelected] = useState(1);
  const [showReportUserModal, setShowReportUserModal] = useState(false)
  const [showBlockUserModal, setShowBlockUserModal] = useState(false)

  const getRelation = ()=>{
    let relation = ""
    if(filter.all){
      relation = "followers_idolizers"
    }else if(filter.followers){
      relation = "followers"
    }else{
      relation = "idolizers"
    }
    return relation
  }

  const onApiCall = ({page})=>{
    let data = {page, relation: getRelation()}
    return api.getRelatedUsers(data)
  }

  const {
    items,
    fetchNextPage,
    hasNextPage,
    extraData,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users", filters:filter})

  return (
    <BasePostCard
      title='Idolizers'
      headerControls={(
        <Box sx={styles.headerBox}>
          <PrimaryButton text={(extraData.all??0) + ' ALL '}
                         size='small'
                         onClick={()=>{
                           dispatchFilter({type: relatedFilter.all})
                         }}
                         mainColor={filter.all?colors.primary: colors.buttons.button2}
                         width={"100px"}
          />
          <PrimaryButton text={filterCounter.followers + ' FOLLOWERS'}
                         size='small'
                         onClick={()=>{
                           dispatchFilter({type: relatedFilter.followers})
                         }}
                         mainColor={filter.followers?colors.primary: colors.buttons.button2}
                         width={"180px"}
          />
          {!isInfluencer && <PrimaryButton text={filterCounter.idolizers + ' IDOLIZERS'}
                               size='small'
                               onClick={() => {
                                 dispatchFilter({type: relatedFilter.idolizers})
                               }}
                               mainColor={filter.idolizers ? colors.primary : colors.buttons.button2}
                               width={"130px"}
          />}
        </Box>
      )}>

      {selected && (
        <React.Fragment>
          <BlockUserModal user={selected}
                          open={showBlockUserModal}
                          onBlock={() => {
                            setShowBlockUserModal(false)
                            refetch({ refetchPage: (page, index) => index === 0 })
                          }}
                          onClose={() => setShowBlockUserModal(false)}/>

          <ReportUserModal user={selected}
                           open={showReportUserModal}
                           fromURL={ '/dashboard/home'}
                           onClose={() => setShowReportUserModal(false)}/>
        </React.Fragment>
      )}

      <Box sx={styles.bodyBox}>
        <InfiniteScroll
          dataLength={items.length}
          next={() => {
            if(!isFetchingNextPage){
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={null }
        >
          <Grid container spacing={2}>
            {
              items.map((user, index) =>
                (
                  <Grid item md={6} lg={6}  key={"grid-"+index} >
                    <IdolCard
                      key={"IdolizerCard-" + index}
                      onReport={()=>{
                        setSelected(user)
                        setShowReportUserModal(true)
                      }}
                      onBlock={()=>{
                        setSelected(user)
                        setShowBlockUserModal(true)
                      }}

                      avatar={user.image}
                      idolizerName={user.username}
                      idolPoints={user.idol_points}
                      isVerified={user.is_validated}
                      iconColor={user.idolizer?"red":"gray"}
                      type={user.user_type.codename}
                    />

                  </Grid>)
              )
            }
          </Grid>
        </InfiniteScroll>

      </Box>
    </BasePostCard>
  );
})

const styles = {
  headerBox:{
    display:"flex",
    flexDirection:"row",
    width:"500px",
    gap:"15px",
    justifyContent:"flex-end"
  },
  bodyBox:{
    background:"transparent !important",
    width:"100%",
    height:"400px",
    paddingTop:"8px",
    overflowY:"scroll"
  }
}

