import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {useStores} from "../../models";

import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {useEffect, useState} from "react";
import {filteringFields} from "./constants";
import {TipList, TransactionList} from "./cards";
import {MobileTransactionsCard} from 'pages/portal/dashboard/mobile/mobileTransactionsCard';
import {MobileTipsCard} from "pages/portal/dashboard/mobile/mobileTipsCard";

import {Box} from "@mui/system";
import {useIsMobile} from "utils/hooks";
import idolPay from "assets/icons/idol-pay-red.svg"
import {NavigationMenu} from "../../constants";


export const TransactionsPage = () => {
  const {loginStore} = useStores();
  const [Transactions, setTransactions] = useState([])
  const [Tips, setTips] = useState([])
  const api = loginStore.environment.api
  const [orderTipsBy, setOrderTipsBy] = useState(filteringFields.Tips)
  const [orderTransactionsBy, setOrderTransactionsBy] = useState(filteringFields.Amount)
  const isIdol = loginStore.isIdol
  const isIdolizer = loginStore.isIdolizer
  const isMobile = useIsMobile()

  useEffect(() => {
    loginStore.setMobileActiveRoute("IDOL PAY")
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
  }, []);

  const getTransactions = (data) => { //TODO until now, returns nothing
    api.getIncomingTransactions(data)
      .then(res => {
        if (res.kind === 'ok') {
          setTransactions(res.data.results)
        } else {
          console.log("error", res)
        }
      })
      .catch(reason => console.log(reason))
  }

  const getTips = (data) => {
    api.getTips(data)
      .then(res => {
        if (res.kind === 'ok') {
          setTips(res.data.results)
          console.log("no entra aca")
          console.log("error", res)
        } else {
          console.log("error", res)
        }
      })
      .catch(reason => console.log(reason))
  }

  const getSortedTips = (field, asce) => {
    const data = {
      ordering: asce ? `${field}` : `-${field}`
    }
    getTips(data)
  }

  const getSortedTransactions = (field, asce) => {
    const data = {
      ordering: asce ? `${field}` : `-${field}`
    }
    getTransactions(data)
  }

  const orderByTipsOnTips = (sorted) => {
    setOrderTipsBy(filteringFields.Tips)
    getSortedTips(filteringFields.Amount, sorted)
  }

  const orderByDateOnTips = (sorted) => {
    setOrderTipsBy(filteringFields.Date)
    getSortedTips(filteringFields.Date, sorted)
  }

  const orderByAmountOnTransactions = (sorted) => {
    if(isMobile){
      setOrderTransactionsBy(filteringFields.OriginType)
    }else{
      setOrderTransactionsBy(filteringFields.Amount)
    }
    getSortedTransactions(filteringFields.Amount, sorted)
  }

  const orderByTypeOnTransactions = (sorted) => {
    setOrderTransactionsBy(filteringFields.OriginType)
    getSortedTransactions(filteringFields.OriginType, sorted)
  }


  const orderByDateOnTransactions = (sorted) => {
    setOrderTransactionsBy(filteringFields.Date)
    getSortedTransactions(filteringFields.Created, sorted)
  }

  useEffect(() => {
    getTransactions()
    getTips()
  }, [])

  const getContent = ()=>{
    let content = null
    if(isIdol && isMobile){
      content = (
        <Box>
          <MobileTipsCard
            orderByTips={orderByTipsOnTips}
            orderByDate={orderByDateOnTips}
            orderRecordsBy={orderTipsBy}
            records={Tips}
          />
          <Box sx={{marginTop: '24px'}}>
            <MobileTransactionsCard
              orderByType={orderByTypeOnTransactions}
              orderByDate={orderByDateOnTransactions}
              orderRecordsBy={orderTransactionsBy}
              records={Transactions}
            />
          </Box>
        </Box>
      )
    } else if(isIdol && !isMobile){
      content = (
        <Box sx={{display: "flex", flexDirection: "column", gap: "40px"}}>
          <TipList
            orderByTips={orderByTipsOnTips}
            orderByDate={orderByDateOnTips}
            orderRecordsBy={orderTipsBy}
            records={Tips}
          />
          <TransactionList
            orderByAmount={orderByAmountOnTransactions}
            orderByDate={orderByDateOnTransactions}
            orderRecordsBy={orderTransactionsBy}
            records={Transactions}
          />
        </Box>
      )
    }else if(isIdolizer && isMobile){
      content = (<Box>Idolizers Mobile View Mode</Box>)
    } else if(isIdolizer && isMobile){
      content = (<Box>Idolizers Desktop View Mode</Box>)
    }
    return content
  }

  return <BaseProtectedComponentPage loginRequired={false}>
    <DashboardLayout icon={idolPay}>
      {getContent()}
    </DashboardLayout>
  </BaseProtectedComponentPage>
}

