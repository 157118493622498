import { Box } from "@mui/system";
import UpcomingIcon from "assets/icons/upcoming-meets.svg";
import React, { memo, useEffect, useState } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { MobileSectionHeader } from "../../../components/Mobile/MobileIdolDashboardSectionHeader";
import MobileMeetings from "../dashboard/mobile/mobileMeetings";
import { useApi, useUserStore, useInfiniteScroll } from "../../../utils/hooks";
import { useStores } from "models";
import {formatNumberToCurrency} from "../../../utils/functions";
import { moneyFormat } from "../../../services/helpers";
//
import { AuthenticatedPaths } from "../../../paths/paths";
import { notifyError, notifySuccess } from "../../../services/notification";
import {IdolMeetingCardMobile} from "components/Mobile/IdolMeetingCardMobile";
import { CancelMeetingModal } from "../../../components/ActionsEventModal/Cancel";
//

export const UpcomingsCardMobile = memo(({isWide = true, isIdolizer}) => {
    const [meetings, setMeetings] = useState([])
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [selectedMeet, setSelectedMeet] = useState(null)
  const api = useApi()
  const userStore = useUserStore();
  const {loginStore } = useStores();

  const getMeetings = () => {
    api.getUpcomingMeetings().then(response => {
      if (response.kind === "ok") {
        setMeetings(response.data.results)
        setShowCancelModal(false)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

   const onApiCall = ({page})=>{
    console.log("onApiCall")
    let data = {page}
    return api.getUpcomingMeetings(data);
  }

  const cancelMeeting = (id) => {
    api.cancelMeeting(id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Meeting cancelled successfully")
        getMeetings()
      } else {
        notifyError("Error canceling this meet")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error canceling this meet")
    })
  }

  const checkIfCanEnter = (meeting, minutes) => {
    const meeting_start_datetime = new Date(meeting.start_datetime).getTime()
    const now = new Date().getTime()
    const initialGapInMiliseconds = minutes * 60 * 1000
    return (now > (meeting_start_datetime - initialGapInMiliseconds))
  }

  const isUpcoming = (meeting, minutes) => {
    return !checkIfCanEnter(meeting, minutes)
  }

  const launch = (meeting) => {
    if (checkIfCanEnter(meeting, 10)) {
      window.open(`${AuthenticatedPaths.videoCall}?room=${meeting.room}`, '_blank');
    } else {
      notifyError("You cannot enter into this meeting yet")
    }
  }

   const {
    items,
    fetchNextPage,
    hasNextPage,
    extraData,
    isFetchingNextPage
  } = useInfiniteScroll({apiCall:onApiCall}); //, queryKey: "upcoming"

//   useEffect(() => {
//     getMeetings()
//   }, []);

    return (
    <Box sx={{marginTop: "25px"}}>
        <MobileSectionHeader
            Icon={UpcomingIcon}
            Title={"Upcoming"}
        />
        <Box>
            <InfiniteScroll
                dataLength={items?.length}
                next={() => {
                    if (!isFetchingNextPage) {
                    return fetchNextPage()
                    }
                }}
                hasMore={!!hasNextPage}
                loader={null}>
                <Box sx={isWide ? { marginTop: "12px" } : { marginTop: "32px" }}>
                {items.map((m, idx) => {
                    const user = loginStore.id === m.from_user.id? m.to_user : m.from_user
                    const meetingInfo = {
                    title: m.name,
                    avatar: user.image,
                    idolizerName: user.username,
                    idolPoints: user.idol_points,
                    isVerified: user.is_validated,              
                    price: formatNumberToCurrency(m.fee),
                    date: moment(m.start_datetime).format("D MMM YYYY").toUpperCase(),
                    timeStart: moment(m.start_datetime).format("HH:mm A"),
                    timeEnd: moment(m.end_datetime).format("HH:mm A"),
                    timeZone: m.location,
                    meetingType: !isIdolizer && m.meeting_type,
                    isIdolizer,
                    };
                    const cardProps = {};
                    return (
                    <Box sx={isWide?{marginTop: "12px"}:{display: "inline-block", marginRight: "16px"}}>
                    <IdolMeetingCardMobile
                        {...cardProps}
                        {...meetingInfo}
                        key={m.id}
                        isIdolizer={isIdolizer}
                        isUpcoming={isUpcoming(m, 10)}
                        onDismiss={() => {
                            setSelectedMeet(m);
                            setShowCancelModal(true);
                        }}
                        onApprove={() => {
                            launch(m);
                        }}
                        isWide={isWide}
                    />
                    </Box>
                    );
                })}
                </Box>
            </InfiniteScroll>
        </Box>
        {selectedMeet && (
          <CancelMeetingModal
              open={showCancelModal}
              onClose={() => setShowCancelModal(false)}
              onCancel={() => cancelMeeting(selectedMeet.id)}
              avatar={selectedMeet.from_user.image}
              idolizerName={selectedMeet.from_user.username}
              idolPoints={selectedMeet.from_user.idol_points}
              isVerified={selectedMeet.from_user.is_validated}
              date={selectedMeet.start_datetime}
              timeStart={selectedMeet.start_datetime}
              timeEnd={selectedMeet.end_datetime}
              price={moneyFormat(selectedMeet.fee)}
            />
      )}
    </Box>
    );
})