import React, { useEffect, useState } from "react";

/* MUI components */
import { Avatar, Box, Typography } from "@mui/material";

/* components */
import {RecordCard} from 'components/RecordCard/RecordCard';
import {BaseMobileTipsTransactionsCard} from 'components/Mobile/BaseMobileTipsTransactionsCard';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import OrderingButton from 'components/WithdrawButton/OrderingButton';


/* data */
import {tips} from 'pages/portal/dashboard/idolizer/dummyData';

/* utils */
import {formatNumberToCurrency} from 'utils/functions';

/* assets */
import Dollar from 'assets/icons/dollar_bg_white.svg';
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import {MobileSectionHeader} from "../../../../components/Mobile/MobileIdolDashboardSectionHeader";
import { filteringFields } from "../../../transactions/constants";

const {card} = colors;
const {white} = colors;

export const MobileTipsCard = ({records, orderTipsBy, orderByTips, orderByDate}) => {

  const [filterSelected, setFilterSelected] = useState(orderTipsBy);
  const [TipAsc, setTipAsc] = useState(false)
  const [DateAsc, setDateAsc] = useState(false)

  useEffect(() => {
    console.log(records)
  },[records])

  useEffect(() => {
    orderByTips(TipAsc)
  }, [TipAsc])

  useEffect(() => {
    orderByDate(DateAsc)
  }, [DateAsc])

  const selectFilter = (value) => {
    if(filterSelected !== value){
      setFilterSelected(value)
    }else{
      if(value === filteringFields.Tips){
        setTipAsc(prevState => !prevState)
      }else{
        setDateAsc(prevState => !prevState)
      }
    }
  }

  const recordProps = {
    cardThirdContentStyles: styles.mini_card,
    showOptionsCard: false,
    contentContainerStyles: styles.content_container,
    containerStyles: styles.tips_container,
    isMobile: true,
  };

  return (
    <Box>
      <MobileSectionHeader Title='Tips' Icon={Dollar} />
      <Box sx={styles.order_buttons_container}>
        <OrderingButton
          text="TIPS"
          currentFilterSelected={filterSelected === filteringFields.Tips}
          onClick={() => selectFilter(filteringFields.Tips)}
          size='small'
        />
        <OrderingButton
          text="DATE"
          currentFilterSelected={filterSelected === filteringFields.Date}
          onClick={() => selectFilter(filteringFields.Date)}
          size='small'
        />
      </Box>
      <Box sx={styles.container}>
        {records.map((record) => (
          <RecordCard
            {...recordProps}
            cardFirstContent={
              <BaseCardIdolInfo
                nameStyles={{minWidth:pxToRem(110)}}
                idolizerName={`${record.user.first_name} ${record.user.last_name}`}
                avatar={`${record.user.thumbnail}?height=150&width=150`}
                isVerified={record.user.is_validated}
                isIdolizer={false}
                idolPoints={record.user.idol_points}
                isMobile={true}
              />

            }
            cardSecondContent={
              <Typography sx={styles.amount}>{formatNumberToCurrency(record.amount)}</Typography>
            }
            cardThirdContent={
              <img
                src={`${record.post.thumbnail}?height=70&width=60`}
                alt={"avatar"}
                style={styles.img}
              />

            }
          />
        ))}
      </Box>
    </Box>
  );
}

const styles = {
  amount: {
    color: white.main,
    fontSize: pxToRem(12),
    fontWeight: 500,
  },
  order_buttons_container: {
    display: 'flex',
    gap: pxToRem(14),
    marginTop: pxToRem(14),
  },
  tips_container: {
    display: 'grid',
    gridTemplateColumns: '4.5fr 1fr',
    marginTop: 0,
  },
  mini_card: {
    background: 'inherit',
    boxShadow: 'none',
    height: pxToRem(65),
    justifyContent: 'flex-end',
    left: 0,
    padding: 0,
    width: '100%',
  },
  content_container: {
    display: 'grid',
    gridTemplateColumns: '3fr 0.7fr 1fr',
    padding: `${pxToRem(9)} ${pxToRem(4.94)}`,
  },
  info_card: {
    avatar: {
      height: pxToRem(55),
      width: pxToRem(50),
    },
  },
  img: {
    borderRadius: '2px',
    height: "70px",
    width: "60px",
    paddingRight: pxToRem(4.94),
  },
  container: {
    background: card.background,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '390px',
    overflow: 'auto',
    gap: pxToRem(12),
    marginTop: '1rem',
    padding: `${pxToRem(12)} ${pxToRem(9)}`,
  },
};
