import React from 'react';
import PropTypes from 'prop-types';
import {Box, Popover, Typography} from '@mui/material';
import {styles} from './styles';
import {IdolTypeEnum} from "../../../../../../utils/constants";

export const IdolsActions = ({
                               open,
                               onClose,
                               anchorEl,
                               type = "following",
                               onUnfollow,
                               onUnSubscribe,
                               onReport,
                               onBlock,
                               children,
                               profile,
                             }) => {
  const popoverProps = {
    open,
    onClose,
    anchorEl,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  return (
    <Popover {...popoverProps} sx={styles.popover}>
      <Box sx={styles.arrow} />
      <Box sx={styles.container}>
        {children ? (
          children
        ) : (
          <>
            {onUnfollow && (
              <Typography sx={styles.text} onClick={onUnfollow}>
                Unfollow
              </Typography>
            )}
            {(onUnSubscribe && profile?.idol_type !== IdolTypeEnum.influencer) && (
              <Typography sx={styles.text} onClick={onUnSubscribe}>
                Unsubscribe
              </Typography>
            )}
            {onReport &&
              <Typography sx={styles.text} onClick={onReport}>
                Report User
              </Typography>
            }
            {onBlock &&
              <Typography sx={styles.text} onClick={onBlock}>
                Block User
              </Typography>
            }
          </>
        )}
      </Box>
    </Popover>
  );
}

IdolsActions.propTypes = {
  open: PropTypes.bool.isRequired,
  // anchorEl: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['following', 'idolizing']),
  onClose: PropTypes.func.isRequired,
  // onUnfollow: PropTypes.func.isRequired,
  // onUnSubscribe: PropTypes.func.isRequired,
  onReport: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
};

