import { Box } from "@mui/material";
import React from "react";
import { CardForm } from "../components/CardForm";
import { SaveButton } from "../components/SaveButton";
import { globalStyles } from "../globalStyles";
import * as Yup from "yup";
import { requiredMessage } from "../constants";
import { Formik, Form } from "formik";
import { TextField } from "../components/TextField";

const validationSchema = Yup.object({
  POINTS_NEW_IDOL_PROFILE: Yup.string().required(requiredMessage),
  POINTS_VERIFICATION_IDOL_PROFILE: Yup.string().required(requiredMessage),
});

export const OnboardingForm = ({ config, onSave })=>{

  return (
    <CardForm  title={"Onboarding"}>
      <Formik
        initialValues={{
          POINTS_NEW_IDOL_PROFILE: config.POINTS_NEW_IDOL_PROFILE??0,
          POINTS_VERIFICATION_IDOL_PROFILE: config.POINTS_VERIFICATION_IDOL_PROFILE??0,
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          const data = Object.keys(values).map(key=> ([[key], { value: values[key]}]))
          const obj = Object.fromEntries(data);
          onSave(obj)
        }}
      >
        {(props) => (
          <Box component={Form} sx={globalStyles.container} >
            <Box sx={globalStyles.inputGroup}>
              <TextField
                sx={globalStyles.input}
                inputLabel={"New Idol Profile"}
                name="POINTS_NEW_IDOL_PROFILE"
                max={1000000}
                type={"number"}
                customEndAdornmentText={"idp"}
              />

              <TextField
                sx={globalStyles.input}
                inputLabel={"Approved Verification"}
                name="POINTS_VERIFICATION_IDOL_PROFILE"
                max={1000000}
                type={"number"}
                customEndAdornmentText={"idp"}
              />
            </Box>
            <SaveButton onClick={onSave} />
          </Box>
        )}
      </Formik>
    </CardForm>
  );
}

