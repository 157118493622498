import {Box, Grid} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {SearchBox} from "../../../components/SearchBox";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { useApi, useInfiniteScroll, useUserStore } from "../../../utils/hooks";
import {FilterType} from "../constants";
import colors from "../../../assets/theme/base/colors";
import {ExplorePost} from "./Post";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {SpecificPostModal} from "../../../components/PostsGrill/SpecificPostModal";
import {PostCategoryEnum} from "../../../constants";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import {notifyError, notifySuccess} from "../../../services/notification";
import {useNavigate} from "react-router-dom";
import {moneyFormat} from "../../../services/helpers";
import {AuthenticatedPaths} from "../../../paths/paths";
import PostContent from "../../../components/PostContent/PostContent";
import debounce from 'lodash.debounce';
import { NeedLoginModal } from "../../../components/NeedLoginModal";

const splitArray = (target, size) => {
  const result = target.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size)
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
  return result
}


const SMALL_WIDTH = 200
export const ExploreDesktop = () => {
  const api = useApi()
  const scrollEl = useRef()
  const user = useUserStore()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const [Items, setItems] = useState([])
  const [value, setValue] = useState('')
  const [filter, setFilter] = useState(FilterType.all)
  const [loading, setLoading] = useState(true)
  const [ItemsPerRow, setItemsPerRow] = useState(0)
  const [GridWidth, setGridWidth] = useState("100%")
  const [SelectedPost, setSelectedPost] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showPayConfirmModal, setShowPayConfirmModal] = useState(false)
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const onApiCall = ({page}) => {
    setLoading(true)
    let data = {page, explore: true}
    if (filter === FilterType.paidContent) {
      data["paid_content"] = true
    }
    if (value) {
      data["search"] = value
    }
    return api.getIdolPosts(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall: onApiCall, queryKey: "posts", filter})

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, 100);
  }, [filter, value])

  useEffect(() => {
    if (filter !== FilterType.paidContent) {
      const width = containerRef.current.clientWidth
      const itemsPerRow = parseInt(width / SMALL_WIDTH) * 2 - 3
      setGridWidth((parseInt(width / SMALL_WIDTH) - 2) * SMALL_WIDTH)
      setItemsPerRow(itemsPerRow)
      const newArray = splitArray(items, itemsPerRow)
      setItems(newArray)
    } else {
      setItems(items)
    }
    setLoading(false)
  }, [items, width])

  const changeHandler = (keywords) => {
    setValue(keywords)
  };
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500)
    , []);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);


  const onClickPost = (post) => {
    const isVideo = post.post_category.codename === PostCategoryEnum.video
    setSelectedPost({
      ...post,
      tipPrice: post.tip_price,
      isVideo: isVideo,
      isLocked: !post.is_visible,
      idolPost: isVideo ? post.file : post.image
    })

  }

  const goToMeetMe = () => {
    if(user?.id){
      navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", SelectedPost.user.idol_id_name))
    }else{
      setShowLoginModal(true)
    }
  }

  const unlockPost = ()=>{
    if(user?.id){
      api.additionalFeatureAccessPay(SelectedPost.id).then((response) => {
        if (response.kind === "ok"){
          window.location.reload();
        }
      }).catch(reason => {
        console.log(reason)
        notifyError("Can't unlock this post")
      })
    }else{
      setShowLoginModal(true)
    }

  }

  const confirmTipPayment = () => {
    api.sendTip(SelectedPost.id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Tip sent successfully")
        setShowPayConfirmModal(false)
      } else {
        notifyError("Error sending Tip")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error sending Tip")
    })
  }

  const RowEven = ({row}) => {
    return (
      <Box style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <Grid container style={{width: GridWidth}}>
          {row.map((post, index) => {
            if (index < (row.length - 1)) {
              return (
                <Grid item key={"post-" + index}>
                  <ExplorePost small post={post} onClick={() => onClickPost(post)}/>
                </Grid>
              )
            }
            return null
          })}
        </Grid>
        {row.length === ItemsPerRow && (
          <ExplorePost post={row[row.length - 1]} onClick={() => onClickPost(row[row.length - 1])}/>
        )}

      </Box>
    )
  }

  const RowOdd = ({row}) => {

    return (
      <Box style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <ExplorePost post={row[0]} onClick={() => onClickPost(row[0])}/>
        <Grid container style={{width: GridWidth}}>
          {row.map((post, index) => {
            if (index > 0) {
              return (
                <Grid item key={"post-" + index}>
                  <ExplorePost small post={post} onClick={() => onClickPost(post)}/>
                </Grid>
              )
            }
          })}
        </Grid>
      </Box>
    )
  }


  const Tab = ({selected, title, onClick}) => {
    return (
      <Box style={styles.tab} disabled={loading} onClick={onClick}>
        <MDTypography style={selected ? styles.tabSelectedText : styles.tabText}>
          {title}
        </MDTypography>
        {selected && (
          <Box style={styles.underline}/>
        )}
      </Box>
    )
  }

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setWidth(width);
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (

    <DashboardLayout hideFooter={true}>
      {showLoginModal && (<NeedLoginModal open={showLoginModal} onClose={()=>setShowLoginModal(false)} />)}
      {SelectedPost && (
        <>
          <ConfirmPaymentModal
            open={showPayConfirmModal}
            confirmAction={confirmTipPayment}
            onClose={() => {
              setShowPayConfirmModal(false)
            }}
            message={`Are you sure you want to tip this user for ${moneyFormat(SelectedPost?.tipPrice ?? 0)}?`}
          />
          <SpecificPostModal
            isExplorePage={true}
            content={SelectedPost}
            openPost={SelectedPost}
            unlockPost={unlockPost}
            goToMeetMe={goToMeetMe}
            handleClose={() => setSelectedPost(null)}
            handleConfirmPaymentModal={() => {
              if(user?.id) {
                setShowPayConfirmModal(true)
              }else{
                setShowLoginModal(true)
              }
            }}
          />

        </>
      )}


      <Box style={styles.main}>
        <Box style={styles.navbar}>
          <Box style={styles.searchBox}>
            <SearchBox placeholder={"I am looking for..."}
                       inputStyles={{width: "402px", fontSize: 14, color: "white"}}
                       InputProps={{ maxLength: 10 }}
                       value={value}
                       onChange={e => setValue(e.target.value)}/>

          </Box>
          <Box style={styles.tabs}>
            <Tab selected={filter === FilterType.all}
                 onClick={() => {
                   setItems([])
                   setFilter(FilterType.all);
                 }}
                 title={"EXPLORE"}/>
            {user.id && (
              <Tab selected={filter === FilterType.paidContent}
                   onClick={() => {
                     setItems([])
                     setFilter(FilterType.paidContent);
                   }}
                   title={"PAID CONTENT"}/>
            )}
          </Box>
        </Box>
        <Box style={styles.content} ref={containerRef} >
          <Box ref={ref} id={"scroll-content"}>
          {filter && (
            <InfiniteScroll
              dataLength={Items.length}
              next={() => {
                if (!isFetchingNextPage) {
                  return fetchNextPage()
                }
              }}
              hasMore={!!hasNextPage}
              loader={<Loader/>}
              // scrollableTarget={"scroll-content"}
            >
                {filter === FilterType.paidContent ? (
                  Items.map((post, index) => (
                    <PostContent key={"post-" + post.id} handleOpen={onClickPost} post={post}/>
                  ))
                ) : (
                  Items.map((row, index) => {
                    if (row.length > 0) {
                      return (index + 1) % 2 === 0
                        ? <RowEven key={"explore-row-" + index} index={index} row={row}/>
                        : <RowOdd key={"explore-row-" + index} index={index} row={row}/>
                    }
                    return null
                  })
                )}

            </InfiniteScroll>
          )}
          </Box>
        </Box>

      </Box>
    </DashboardLayout>


  )
}
const styles = {
  postsContainer: {
    marginLeft: "24px",
    height: "calc(100vh - 257px)",
    boxSizing: "content-box",
    // overflowY:"scroll",
    marginRight: "24px"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
    width: "100%",
    height: "auto",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    // height:"90vh",
    marginTop: pxToRem(-20),
    height: "auto",
  },
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "50px"
  },
  searchBox: {},
  tab: {
    width: "200px",
    cursor: "pointer"
  },
  tabs: {
    width: "450px",
    marginTop: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  tabText: {
    color: colors.white.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "0.1em",
    textAlign: "center"
  },
  tabSelectedText: {
    color: colors.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "0.1em",
    textAlign: "center"
  },
  underline: {
    width: "200px",
    marginTop: "18px",
    height: "2px",
    backgroundColor: colors.primary.main
  },
}
