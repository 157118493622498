import HomeIcon from "../assets/icons/home-icon.svg";
import ExploreIcon from "../assets/icons/compass.svg";
import GearIcon from "../assets/icons/gear_six.svg";
import DashboardIcon from "../assets/icons/chart_pie_slice.svg";
import IdolBankIcon from "../assets/icons/wallet-idol-bank.svg";
import TransactionIcon from "../assets/icons/list.svg";
import EventsIcon from "../assets/icons/events.svg";
import PeopleIcon from "../assets/icons/people.svg";
import LogOutIcon from "../assets/icons/sign_out.svg";
import NotificationsIcon from "../assets/icons/bell.svg";
import colors from "../assets/theme/base/colors";
import ProhibitIcon from "../assets/icons/Prohibit.svg";
import ClipboardIcon from "../assets/icons/ClipboardText.svg";
import TrashIcon from "../assets/icons/trash.svg";

export const idolizerMobileMenu = {
  main: {
    d00: {type: "divider"},
    explore: {
      name: 'Explore',
      icon: ExploreIcon,
      type: 'collapse',
    },
    dashboard: {
      noCollapse: true,
      name: 'Dashboard',
      icon: DashboardIcon,
      collapse: {
        home: {
          name: 'Home',
          icon: HomeIcon,
          type: 'collapse',
        },
        wallet: {
          name: 'Wallet',
          icon: IdolBankIcon,
          type: 'collapse',
        },
        transactions: {
          name: 'Transactions',
          icon: TransactionIcon,
          type: 'collapse',
        },
        meetings: {
          name: 'Meetings',
          icon: EventsIcon,
          type: 'collapse',
        },
        idols: {
          name: 'Idols',
          icon: PeopleIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    notifications: {
      name: 'Notifications',
      icon: NotificationsIcon,
      type: 'collapse',
    },
    settings: {
      noCollapse: true,
      name: 'Settings',
      icon: GearIcon,
      collapse: {
        blockedUsers: {
          name: 'Blocked Users',
          icon: ProhibitIcon,
          type: 'collapse',
        },
        termsAndConditions: {
          name: 'Terms and Conditions',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        privacyPolicy: {
          name: 'Privacy Policy',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        deactivateAccount: {
          name: 'Deactivate Account',
          icon: TrashIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    logout: {
      name: 'Logout',
      icon: LogOutIcon,
      type: 'collapse',
      color: colors.primary.main
    },
  }
}
