import colors from "assets/theme/base/colors"
import pxToRem from "../../../assets/theme/functions/pxToRem";
const {white, red, transparent, modal} = colors;

export const styles = {
    modal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
    },
    text: {
        textAlign: 'center',
        color: white.main,
        fontSize: '1rem',
        fontWeight: 400,
        letterSpacing: '0.1rem',
        marginY: '1.5rem',
    },
    meeting_info: {
        color: red.main,
        fontWeight: 'bold',
    },
    paddingButton: '8px',
    dismissButton: {
        background: transparent.main,
        color: modal.buttons.cancel,
        width: pxToRem(300)
    },
    buttonText: {
        color:  modal.red,
        fontSize: '1rem'
    },
    placement_info: {
        alignItems: 'end',
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '3%',
    },
    checked_icon: {
        width:pxToRem(107),
        height:pxToRem(107),
        zIndex:3,
        marginTop: pxToRem(20),
    },
}