export default function dividerStyle() {

  return {
    background:"grey",
    height:"0.4px",
    backgroundImage:"unset !important",
    opacity:"unset",
    marginLeft:"24px",
    marginRight:"24px"
  }
}

