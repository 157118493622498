import {useState} from "react";
import {Box} from "@mui/system";
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import {Typography, Input} from "@mui/material";
import {SecondaryButton} from "components/SecondaryButton";
import numeral from "numeral";

const { white, background } = colors;

export const BalanceCardMobile = ({
  outerContainer,
  currentBalance = 0,
  lifetimeBalance = 0,
  buyActionHandler,
  inputAmount,
  changeInputAmount,
  loading
}) => {
  return (
    <Box sx={{...outerContainer}}>
      <Box sx={{...styles.innerContainer, ...styles.firstContainer}}>
        <Box sx={{...styles.separator, ...styles.rowContainer}}>
          <Box sx={{...styles.rowContent, paddingBottom: pxToRem(20)}}>
            <Typography sx={{...styles.text}}>
              Current Balance
            </Typography>
            <Typography sx={{...styles.textBalance}}>
              {currentBalance}
            </Typography>
          </Box>
        </Box>
        <Box sx={{...styles.rowContainer}}>
          <Box sx={{...styles.rowContent}}>
            <Typography sx={{...styles.text}}>
              Lifetime Balance
            </Typography>
            <Typography sx={{...styles.textBalance}}>
              {lifetimeBalance}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{...styles.innerContainer, ...styles.secondContainer}}>
        <Box sx={{...styles.withdrawContainer}}>
            <Box sx={styles.input_container}>
              <Input
                sx={styles.input}
                placeholder="$0.00"
                value={inputAmount}
                onChange={changeInputAmount}
              />
            </Box>
            <SecondaryButton
              text='WITHDRAW'
              onClick={buyActionHandler}
              width={styles.withdrawButton}
              textStyles={styles.withdrawButtonText}
              disabled={loading}
              loading={loading}
            />
        </Box>
      </Box>
    </Box>
  )
}

const styles = {
  input_container: {
    display: 'flex',
    height: pxToRem(49),
    justifyContent: 'center',
    width: pxToRem(202),
},
input: {
    background: background.default,
    color: white.main,
    border: 'none',
    borderRadius: '5px',
    fontSize: pxToRem(36),
    fontWeight: 100,
    lineHeight: pxToRem(36),
    textAlign: 'center',
    '.MuiInput-input': {textAlign: 'center'},
  },
  textBalance: {
    height: pxToRem(36),
    fontWeight: 100,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(36),
    color: white.main,
  },
  innerContainer: {
    borderRadius: pxToRem(10),
    background: colors.background.container,
  },
  firstContainer: {
    minHeight: pxToRem(144),
  },
  secondContainer: {
    marginTop: pxToRem(14),
    minHeight: pxToRem(81),
  },
  rowContainer: {
    marginX: pxToRem(16),
  },
  separator: {
    borderBottom: `1px solid ${white.main}`
  },
  rowContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: pxToRem(16),
    flexWrap: "wrap"
  },
  text: {
    height: pxToRem(36),
    width: pxToRem(64),
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    color: white.main,
    letterSpacing: "0.1em",
    wordWrap: "wrap",
  },

  amountContainer: {
    background: colors.background.default,
    height: pxToRem(49),
    borderRadius: "5px",
    minWidth: pxToRem(202),
    paddingY: pxToRem(8),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  withdrawContainer: {
    padding: pxToRem(16),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: "wrap"
  },
  withdrawButton: {
    maxWidth: pxToRem(98),
  },
  withdrawButtonText: {
    fontSize: pxToRem(10),
    lineHeight: pxToRem(9),
    letterSpacing: "0.1em",
    fontWeight: 700,
  }
}
