import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";

export const styles = {
  modal: {
    width: pxToRem(660),
    height: pxToRem(318),
  },
  container: {
    background: colors.modal.confirm_payment.background,
    width: pxToRem(660),
  },
  header: {
    background: colors.modal.confirm_payment.header,
  },
  content_container: {
    marginInline: "unset",
    marginBlockEnd: pxToRem(34),
  },
  idolCardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px 0px 0px",
  },
  dateTimeContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "30px",
  },
  dateTime: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clockIcon: {
    width: "14px",
    height: "14px",
    marginRight: "10px",
  },
  idolIconSize: {
    width: "55px",
    height: "55px",
  },
  meetingPlace: {
    color: colors.red.main,
    width: "131px",
    height: "32px",
    marginRight: "10px",
    letterSpacing: "0.1em",
    lineHeight: "127.25%",
    textAlign: "right",
    fontSize: "12px",
  },
  meetingAmount: {
    fontSize: "22px",
    fontWeight: "400px",
    lineHeight: "26px",
  },
  meetingDate: {
    fontSize: "14px",
    fontWeight: "300px",
    lineHeight: "140.62%",
  },
  message_desktop: {
    fontSize: pxToRem(16),
  },
  message_mobile: {
    fontSize: pxToRem(20),
  },
  actions_container: {
    display: "flex",
    justifyContent: "center",
    gap: pxToRem(20),
    marginTop: pxToRem(52),
  },
  confirm: {
    alignSelf: "center",
    fontSize: pxToRem(14),
    fontWeight: 700,
    height: "40px",
    lineHeight: "93.75%",
  },
  confirm_text: {
    fontSize: pxToRem(14),
  },
  cancel: {
    color: colors.grey["1000"],
    fontSize: "14px",
    height: "40px",
    cursor: "pointer",
    textAlign: "center",
    padding: "10px 0px 0px 0px",
  },
};
