import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { SecondaryButton } from "components/SecondaryButton";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { styles } from "./styles";

const CancelSubscriptionModal = ({
                                   open,
                                   handleClose,
                                   type,
                                   isMobile,
                                   avatar,
                                   isVerified,
                                   idolPoints,
                                   idolizerName,
                                   modalContentStyle
                                 }) => {
  const actionTypeText =
    type === "idolizing" ? "cancel your subscription?" : "unfollow this person?";
  const titleType = {
    following: "UNFOLLOW",
    idolizing: "CANCEL SUBSCRIPTION",
  };
  const buttonType = {
    following: "UNFOLLOW",
    idolizing: "UNSUBSCRIBE",
  };
  return (
    <Modal open={open} onClose={handleClose} sx={{ width: '100%'}}>
      <Box sx={styles.modal}>
        <Box sx={{...styles.modalContent, ...modalContentStyle}}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.title}>{titleType[type]}</Typography>
          </Box>
          <Box padding="24px">
            <BaseCardIdolInfo
              isMobile={isMobile}
              avatar={avatar}
              isVerified={isVerified}
              idolPoints={idolPoints}
              idolizerName={idolizerName}
            />
            <Box sx={styles.subtitlesContainer}>
              <Box sx={styles.userType}>{type}</Box>
              <Box sx={{ ...styles.userType, lineHeight: "26px" }}>$75.00</Box>
            </Box>
            <Typography sx={styles.dialog}>Are you sure you want to {actionTypeText}</Typography>
            <Box sx={styles.buttonsContainer}>
              <SecondaryButton
                buttonStyle={styles.secondaryButton}
                textStyles={styles.textStyles}
                onClick={handleClose}
                text={buttonType[type]}
              />
              <Button sx={styles.cancelButton} onClick={handleClose}>
                CANCEL
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default CancelSubscriptionModal;
