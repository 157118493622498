export const UnauthenticatedPaths = {
  root: "/",
  signUp: '/sign-up/',
  login: '/login/',
  forgotPassword: '/forgot-password/',
  tokenPassword: '/token-password/',
  updatePassword: '/update-password/',
  getStarted: "/start/get-started/",
  whoAreYou: "/start/who-are-you/",
  selectIdolType: "/start/idol-type/",
  idolProfile: "/start/idol-profile/",
  idolIncorporated: "/start/idol-inc/",
  idolBank: "/start/idol-bank/",
  idolTax: "/start/idol-tax/",
  idolDocuments: "/start/upload-documents/",
  idolTwitter: "/start/idol-twitter/",
  explore: "/explore/",
  viewIdolProfile: "/i/:idol_id_name/",
  termsAndConditions: '/terms-conditions/',
  privacyPolicy: '/privacy-policy/',
}


export const AuthenticatedPaths = {
  allUsers: "/adm/all-users/",
  liveChat: "/adm/live-chat/",
  idolPoints: "/adm/idol-points/",
  meetingTypes: "/adm/meeting-types/",
  earnings: "/adm/earnings/",
  fees: "/adm/fees/",
  reservedNames: "/adm/reserved-names/",
  importedContent: "/adm/imported-content/",
  reportedUsers: "/adm/reported-users/",
  transactions: "/adm/transactions/",
  approveContent: "/adm/imported-content/:userId/",
  feedback: "/adm/feedback/",
  verification: "/adm/verification/",
  manageVerification: "/adm/verification/:userId/",
  idolReady: "/start/ready/",
  logout: "/logout/",
  dashboard: "/dashboard/home",
  dashboardBank: '/dashboard/idolbank/',
  dashboardCalendar: '/dashboard/calendar/',
  dashboardEvents: '/dashboard/events/',
  dashboardIdolPay: '/dashboard/idolpay/',
  dashboardIdolizers: '/dashboard/idolizers/',
  dashboardPrices: '/dashboard/prices/',
  dashboardTransactions: '/dashboard/transactions/',
  dashboardWallet: '/dashboard/wallet/',
  dashboardMeetings: '/dashboard/meetings/',
  dashboardIdols: '/dashboard/idols/',
  blockedUsers: '/settings/blocked-users/',
  termsAndConditions: '/settings/terms-conditions/',
  privacyPolicy: '/settings/privacy-policy/',
  deactivateAccount: '/settings/deactivate/',
  notifications: '/notifications/',
  profile: '/profile/',
  reportUser: "/report-user/",
  meetMe: "/meet-me/:idol_id_name/",
  videoCall: "/videocall/",
  getVerified: '/settings/get-verified/',
  referralLink: '/settings/referral-link/',
  addNewPost: "/idol-profile/add-new-post/",
  importContent: "/import-content/",
}
