/* mui components */
import {Box, Typography} from '@mui/material';

/* assets */
import {styles} from './styles';

export const MobileIdolizerText = () =>
    <Box sx={styles.verified_text_container}>
        <Typography sx={styles.verified_text}>
            IDOLIZER
        </Typography>
    </Box>
