import React, {useEffect, useRef} from 'react';
import BackgroundSelectionDialog from '../BackgroundSelectionDialog/BackgroundSelectionDialog';
import ChatWindow from '../ChatWindow/ChatWindow';
import clsx from 'clsx';
import {Grid, makeStyles, Theme, useMediaQuery} from '@material-ui/core';
import {Participant as IParticipant, Room as IRoom} from 'twilio-video';
import {ParticipantAudioTracks} from '../ParticipantAudioTracks/ParticipantAudioTracks';
import {useAppState} from '../state';
import useScreenShareParticipant from '../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../hooks/useVideoContext/useVideoContext';
import useDominantSpeaker from "../hooks/useDominantSpeaker/useDominantSpeaker";
import Participant from '../Participant/Participant';
import useParticipantContext from "../hooks/useParticipantsContext/useParticipantsContext";
import {Box, Stack} from "@mui/material";
import ToggleAudioButton from "../Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "../Buttons/ToggleVideoButton/ToggleVideoButton";
import EndCallButton from "../Buttons/EndCallButton/EndCallButton";
import useRoomState from "../hooks/useRoomState/useRoomState";
// @ts-ignore
import VideocallLogo from "assets/images/videocall_logo.png";
import {useStores} from "../../../models";
import theme from "../../../assets/theme";
import {Countdown} from "../../../utils/hooks";


// @ts-ignore
const useStyles = makeStyles((theme: Theme) => {
  const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
  theme.sidebarMobilePadding * 2 +
  theme.participantBorderWidth}px`;
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `100%`,
      gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
    },
    title1: {
      color: 'white',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '24px',
      lineHeight: '26px',
    },
    titleMobile: {
      color: 'white',
      textShadow: '0px 0px 4px black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '16px',
      lineHeight: '26px',
    },
    clock1: {
      color: 'white',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '16px',
      lineHeight: '26px',
    },
    clockMobile: {
      color: 'white',
      textShadow: '0px 0px 4px black',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px',
    },
    rightDrawerOpen: {gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px`},
  };
});

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export function useSetSpeakerViewOnScreenShare(
  screenShareParticipant: IParticipant | undefined,
  room: IRoom | null,
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>,
  isGalleryViewActive: boolean
) {
  const isGalleryViewActiveRef = useRef(isGalleryViewActive);

  // Save the user's view setting whenever they change to speaker view or gallery view:
  useEffect(() => {
    isGalleryViewActiveRef.current = isGalleryViewActive;
  }, [isGalleryViewActive]);

  useEffect(() => {
    if (screenShareParticipant && screenShareParticipant !== room!.localParticipant) {
      // When screensharing starts, save the user's previous view setting (speaker or gallery):
      const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
      // Turn off gallery view so that the user can see the screen that is being shared:
      setIsGalleryViewActive(false);
      return () => {
        // If the user was using gallery view prior to screensharing, turn gallery view back on
        // once screensharing stops:
        if (prevIsGalleryViewActive) {
          setIsGalleryViewActive(prevIsGalleryViewActive);
        }
      };
    }
  }, [screenShareParticipant, setIsGalleryViewActive, room]);
}

export default function Room() {
  const {meetingStore} = useStores()
  const classes = useStyles();
  const {room} = useVideoContext();
  const {isGalleryViewActive, setIsGalleryViewActive} = useAppState();
  const dominantSpeaker = useDominantSpeaker(true);
  const screenShareParticipant = useScreenShareParticipant();
  const {mobileGalleryViewParticipants} = useParticipantContext();
  const remoteParticipantCount = mobileGalleryViewParticipants.length;
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const ready = !!meetingStore.currentMeeting;
  const participants: IParticipant[] = [];

  const mobileVersion = () => {
    return <div style={{width: '100%', height: '100%',}}>
      <Grid container>
        {participants[0] ?
          <Grid item xs={12} key={participants[0].sid} style={{height: '50vh',}}>
            <Participant
              participant={participants[0]}
              isLocalParticipant={room!.localParticipant === participants[0]}
              isDominantSpeaker={dominantSpeaker === participants[0]}
              isHost={true}
            />
          </Grid>
          :
          <Grid item xs={12} style={{height: '50vh',}}></Grid>
        }
        {participants[1] ?
          <Grid item xs={12} key={participants[1].sid} style={{height: '50vh',}}>
            <Participant
              participant={participants[1]}
              isLocalParticipant={room!.localParticipant === participants[1]}
              isDominantSpeaker={dominantSpeaker === participants[1]}
              isHost={false}
            />
          </Grid>
          :
          <Grid item xs={12} style={{height: '50vh',}}></Grid>
        }
      </Grid>
    </div>
  }

  const controlsMobileVersion = () => {
    return <Grid item xs={12} style={{height: '16vh', marginTop: '-5vh'}}>
      <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Stack flexDirection={"row"} justifyContent="center" style={{
          background: '#0C192B',
          boxShadow: '3px 4px 21px rgba(0, 0, 0, 0.71)',
          borderRadius: '20px',
          width: '355px',
          height: '92px',
        }}>
          <ToggleAudioButton disabled={isReconnecting}/>
          <ToggleVideoButton disabled={isReconnecting}/>
          <EndCallButton/>
        </Stack>
      </Box>
    </Grid>
  }

  useSetSpeakerViewOnScreenShare(screenShareParticipant, room, setIsGalleryViewActive, isGalleryViewActive);

  if (ready) {
    participants[meetingStore.isHost ? 0 : 1] = room!.localParticipant
    if (remoteParticipantCount === 1) {
      participants[meetingStore.isHost ? 1 : 0] = mobileGalleryViewParticipants[0]
    }
  }

  if (!ready) return

  return (<>
    {isMobile &&
      <Grid container style={{height: '0',}}>
        <Grid item xs={6} style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingLeft: '5vh',
          paddingTop: '7vh',
          marginBottom: '-10vh',
          zIndex: 1,
        }}>
          <span className={clsx(classes.titleMobile)}>IDOLCALL</span>
        </Grid>
        <Grid item xs={6} style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          paddingRight: '5vh',
          paddingTop: '7vh',
          marginBottom: '-10vh',
          zIndex: 1,
        }}>
          <Countdown targetDate={meetingStore.currentMeeting.end_datetime} className={clsx(classes.clockMobile)} />
        </Grid>
      </Grid>
    }
    {isMobile ? mobileVersion() :
      <Grid container>
        <Grid item xs={6} style={{
          height: '10vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          paddingLeft: '3%',
          paddingBottom: '2%',
        }}>
          <span className={clsx(classes.title1)}>IDOLCALL</span>
        </Grid>
        <Grid item xs={6} style={{
          height: '10vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          paddingRight: '3%',
          paddingBottom: '2%',
        }}>
          <Countdown targetDate={meetingStore.currentMeeting.end_datetime} className={clsx(classes.clock1)} />
        </Grid>
        {participants[0] ?
          <Grid item xs={6} key={participants[0].sid} style={{height: '74vh',}}>
            <Participant
              participant={participants[0]}
              isLocalParticipant={room!.localParticipant === participants[0]}
              isDominantSpeaker={dominantSpeaker === participants[0]}
              isHost={true}
            />
          </Grid>
          :
          <Grid item xs={6} style={{height: '74vh',}}></Grid>
        }
        {participants[1] ?
          <Grid item xs={6} key={participants[1].sid} style={{height: '74vh',}}>
            <Participant
              participant={participants[1]}
              isLocalParticipant={room!.localParticipant === participants[1]}
              isDominantSpeaker={dominantSpeaker === participants[1]}
              isHost={false}
            />
          </Grid>
          :
          <Grid item xs={6} style={{height: '74vh',}}></Grid>
        }
      </Grid>
    }
    {isMobile ? controlsMobileVersion() :
      <div style={{background: 'linear-gradient(90deg, #0C192B 50%, #253140 50%)',}}>
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          paddingTop={'2vh'}
          height={'14vh'}
          position={'absolute'}
          width={'100%'}
          zIndex={0}
        >
          <img style={{width: '12vh'}} src={VideocallLogo}/>
        </Stack>

        <Grid container>
          {meetingStore.isHost ? null : <Grid item sm={6}></Grid>}
          <Grid item sm={6} style={{height: '16vh',}}>
            <Box width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <Stack
                flexDirection={"row"}
                justifyContent="center"
                boxShadow={'3px 4px 21px rgba(0, 0, 0, 0.71)'}
                borderRadius={'20px'}
                width={'355px'}
                height={'92px'}
                zIndex={'1'}
                sx={{background: '#0C192B'}}
              >
                <ToggleAudioButton disabled={isReconnecting}/>
                <ToggleVideoButton disabled={isReconnecting}/>
                <EndCallButton/>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </div>
    }
    <ParticipantAudioTracks/>
    <ChatWindow/>
    <BackgroundSelectionDialog/>
  </>)
}
