import * as Yup from 'yup';

export const CreateEventUtils = () => {

    const initialValues = {
      name: "",
      description: "",
      duration: "",
      price: "",
      city: "",
      dateRange: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        description: Yup.string().required("This field is required"),
        duration: Yup.string().required("This field is required"),
        price: Yup.number().required("This field is required"),
        city: Yup.string().required("This field is required"),
        dateRange: Yup.string().required("This field is required"),
    });

    const durationsData = [
        { value: "30 minutes", label: "30 minutes" },
        { value: "1 hour", label: "1 hour" },
        { value: "2 hours", label: "2 hours" },
      ];
      
      const citiesData = [
        { value: "New York", label: "New York" },
        { value: "Los Angeles", label: "Los Angeles" },
      ];
      
      const dateRangeData = [
        { value: "Calendar days", label: "Calendar days" },
        { value: "Option2", label: "Option2" },
        { value: "Option3", label: "Option3" },
      ];

    return {
        initialValues,
        validationSchema,
        durationsData, 
        citiesData,
        dateRangeData
    };
}