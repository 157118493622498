import {Checkbox, FormControlLabel} from "@mui/material";
import Unchecked from "../../assets/icons/Circulo.svg";
import Checked from "../../assets/icons/Circulo-con-check.svg";
import formControlLabelStyle from "./styles";


const RoundCheck = ({
  onClick, 
  label="", 
  checked, 
  labelColor="white", 
  labelFontSize="22px", 
  radioButtonSize="41px", 
  IconChecked,
  ref, 
  formStyles,
  ...rest
}) => {
  return (
    <FormControlLabel
      sx={[() => formControlLabelStyle(labelFontSize, labelColor), { ...formStyles, display: "flex" }]}
      label={label !== "" && label}
      checked={checked}
      ref={ref}
      control={
        <Checkbox
          icon={<img src={Unchecked} height={radioButtonSize} width={radioButtonSize} />}
          checkedIcon={<img src={IconChecked ?? Checked} height={radioButtonSize} width={radioButtonSize} />}
          onClick={onClick}
        />
      }
    />
  );
}

export default RoundCheck;
