/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* mui components */
import {Card, CardHeader} from '@mui/material';

/* assets */
import {styles} from './styles';

export const BaseIdolCard = ({title, icon, children, cardStyles, headerStyles, titleHeaderStyles, uppercaseTitle = true, onClick,...rest}) => {
  return (
    <Card sx={[styles.card, {...cardStyles}]} onClick={onClick}>
        <CardHeader
          title={uppercaseTitle ? `${title.toUpperCase()}` : title}
          titleTypographyProps={{color: styles.color, ...titleHeaderStyles}}
          action={icon && <img src={icon} alt='icon' />}
          sx={[styles.cardHeader, {...headerStyles}]}
        />
        {children}
    </Card>
  )
}

BaseIdolCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cardStyles: PropTypes.object,
  headerStyles: PropTypes.object,
  titleHeaderStyles: PropTypes.object,
  uppercaseTitle: PropTypes.bool,
};
