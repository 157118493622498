import MDBox from "../MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "../MDInput";
import React, {useState} from "react";
import {autocompleteStyles, labelStyles} from "./styles";
import MDTypography from "../MDTypography";
import {FormikFields} from "../../models/fields/models";
import {styled} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {Chip, FormControl} from "@mui/material";
import pxToRem from "../../assets/theme/functions/pxToRem";
import colors from "../../assets/theme-dark/base/colors";

export const AutocompleteFormik = (opts) => {
  const {
    labelFieldName,
    name,
    options,
    defaultValue,
    onInputChange,
    touched,
    label,
    errors,
    ...rest
  } = opts
  const [cleanInputValue, setCleanInputValue] = useState(false)
  const {value} = rest

  return (
    <MDBox style={{width: '100%'}}>
      <MDTypography style={labelStyles}>{label}</MDTypography>
      <Autocomplete
        filterSelectedOptions
        name={name}
        options={options}
        sx={autocompleteStyles}
        getOptionLabel={option => option[labelFieldName] ?? ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onInputChange={(event, value, reason) => {
          if (onInputChange) {
            onInputChange(event, value, reason)
          }
          setCleanInputValue(reason === 'clear')
        }}
        renderInput={(params) => {
          if (value === undefined && defaultValue) {
            params.inputProps.value = defaultValue
          }
          if (value === null && cleanInputValue) {
            params.inputProps.value = ""
          }
          return (<MDInput
            type="text"
            variant="standard"
            fullWidth
            error={touched[name] === true && errors[name] !== undefined}
            helperText={touched[name] === true && errors[name] && errors[name][labelFieldName]}
            {...params}
          />)
        }}
        {...rest}
      />
    </MDBox>
  )
}

/**
 * CustomAutocompleteFormik provides integration with formik
 * @param name
 * @param formik
 * @param options
 * @param onChange
 * @param onClear
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */

const CustomMDInputRoot = styled(TextField)(({theme}) => {
  return {
    background: theme.palette.inputBackgroundColor,
    height: pxToRem(40),
    borderRadius: 5,
    input: {
      color: "white",
      borderColor: "red",
      padding: '0px 0.75rem',
      height: pxToRem(6),
    },
    opacity: 1,
    border: "1px solid " + theme.palette.inputBackgroundColor,
    borderColor: theme.palette.inputBackgroundColor,
    color: "#FFFFFF",
    "& .MuiInputBase-inputMultiline ": {
      color: "white ",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.inputBackgroundColor
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBorderColor,
      },
      '& fieldset': {},
    },
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
      fontSize: "30px!important",
      margin: "-5px!important",
    },
  };
});

const CustomAutocompleteChipsMDInputRoot = styled(TextField)(({theme}) => {
  return {
    background: theme.palette.inputBackgroundColor,
    minHeight: pxToRem(40),
    borderRadius: 5,
    opacity: 1,
    border: "1px solid " + theme.palette.inputBackgroundColor,
    borderColor: theme.palette.transparent.main,
    "& .MuiOutlinedInput-root": {
      padding: pxToRem(1),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.transparent.main,
      minHeight: pxToRem(45),
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBorderColor,
      },
      '& fieldset': {},
    },
    "& .MuiSvgIcon-root": {
      color: "#FFFFFF",
      fontSize: "30px!important",
      margin: "-5px!important",
      padding: "0!important",
    },
  };
});

const CustomAutocompleteChipsInput = (props) => {
  const {inputLabel, isMobile, labelStyle} = props
  return <FormControl variant="outlined" fullWidth={true}>
    {inputLabel &&
      <MDTypography sx={{
        fontSize: !isMobile ? pxToRem(16) : pxToRem(12),
        color: '#FFFFFF',
        ...labelStyle
      }}>{inputLabel}</MDTypography>
    }
    <CustomAutocompleteChipsMDInputRoot
      {...props}
    />
  </FormControl>
}

const getChipStyles = (theme) => {
  return {
    background: theme.palette.autocomplete.chip + "!important",
    borderColor: theme.palette.inputBackgroundColor,
    fontSize: pxToRem(14),
    padding: "0.25rem 0.5rem",
    margin: 0,
    "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
      padding: "0px!important"
    }
  }
}


const CustomAutocompleteInput = (props) => {
  const {inputLabel, isMobile, labelStyle} = props
  return <FormControl variant="outlined" fullWidth={true}>
    {inputLabel &&
      <MDTypography sx={{
        fontSize: !isMobile ? pxToRem(16) : pxToRem(12),
        mb: pxToRem(1),
        color: '#FFFFFF',
        ...labelStyle
      }}>{inputLabel}</MDTypography>
    }
    <CustomMDInputRoot
      {...props}
    />
  </FormControl>
}

export const CustomAutocompleteFormik = ({
                                           name,
                                           formik,
                                           options,
                                           onChange,
                                           onClear,
                                           ...rest
                                         }) => {
  const inputLabel = FormikFields[name].label
  const placeholder = FormikFields[name].placeholder
  const defaultValue = Array.isArray(formik.initialValues[name]) ?
    formik.initialValues[name] : [formik.initialValues[name]]

  return (
    <MDBox sx={{width: '100%'}}>
      <Autocomplete
        filterSelectedOptions
        name={name}
        options={options}
        getOptionLabel={option => option.name ?? ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        defaultValue={defaultValue}
        value={formik.values[name]}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(event, newValue)
          }
          formik.setFieldValue(name, newValue)
        }}
        onInputChange={(event, value, reason) => {
          const clearing = reason === 'clear'
          if (clearing && onClear) {
            onClear(event, value, reason)
          }
        }}
        renderInput={(params) =>
          <CustomAutocompleteInput
            type={'text'}
            name={name}
            inputLabel={inputLabel}
            placeholder={placeholder}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            {...params}
          />
        }
        {...rest}
      />
    </MDBox>
  )
}

export const CustomAutocompleteWithChipFormik = ({
                                           name,
                                           formik,
                                           options,
                                           onChange,
                                           onClear,
                                           freeSolo=undefined,
                                           ...rest
                                         }) => {
  const placeholder = FormikFields[name].placeholder
  const inputLabel = FormikFields[name].label
  const defaultValue = Array.isArray(formik.initialValues[name]) ?
    formik.initialValues[name] : [formik.initialValues[name]]

  return (
    <MDBox sx={{width: '100%'}}>
      <Autocomplete
        freeSolo={freeSolo}
        multiple
        filterSelectedOptions
        name={name}
        options={options}
        getOptionLabel={option => option.name ?? ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        defaultValue={defaultValue}
        value={formik.values[name]}
        onChange={(event, newValue) => {
          if (onChange) {
            onChange(event, newValue);
          }
          formik.setFieldValue(name, newValue);
        }}
        renderInput={(params) =>
          <CustomAutocompleteChipsInput
            type={'text'}
            inputLabel={inputLabel}
            placeholder={placeholder}
            onChange={formik.handleChange}
            error={formik.touched[name] && Boolean(formik.errors[name]) && Boolean(formik.values[name].length === 0)}
            helperText={formik.touched[name] && formik.errors[name]}
            {...params}
          />
        }
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.name}
              sx={getChipStyles}
              {...getTagProps({index})}
            />
          ))
        }
        {...rest}
      />
    </MDBox>
  )
}