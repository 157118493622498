import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import CustomSelect from "../../../components/CustomSelect";
import MDTypography from "../../../components/MDTypography";
import { styles } from "./styles";
import TrashIcon from "../../../assets/icons/trash.svg";
import React, { useEffect } from "react";
import { useIsMobile } from "../../../utils/hooks";
import { discountOptions } from "./constants";
import { ErrorMessage } from "formik";
import stylesCss from "./styles.module.css";
import colors from "../../../assets/theme/base/colors";

const MobileItemRow =  ({item, index, remove, durationOptions,onChange, errors, footerText})=>{
  return (
    <>
      {index > 0 && (
        <Box style={styles.rowCenter}>
          <Box sx={styles.mobileFirstItem} />
        </Box>
      )}
      <Box sx={styles.mobileItem}>
        <Box style={{width:"85%"}}>
          <Typography variant={"h6"} color={colors.white.main}>
            Duration
          </Typography>
          <CustomSelect
            name={`items.${index}.duration`}
            value={item.duration}
            options={durationOptions}
            onChange={(e)=>onChange({fieldName:`items.${index}.duration`, value: e.target.value})}
          />
          { errors.length > 0  && Boolean(errors[index].duration) && (
            <FormHelperText>
              <MDTypography sx={{ fontSize: 14 }} color={"error"}>
                {errors[index].duration}
              </MDTypography>
            </FormHelperText>
          )}
          <ErrorMessage
            name={`items.${index}.duration`}
            component="p"
            className={stylesCss.fieldError}
          />
          <Typography
            variant="h6"
            color={colors.white.main}
            sx={styles.footerText}>
            {footerText}
          </Typography>


          <Typography variant={"h6"} color={colors.white.main} sx={{mt:2}}>
            Discount Percent
          </Typography>
          <CustomSelect
            value={item.discount_percentage}
            name={`items.${index}.discount_percentage`}
            options={discountOptions}
            onChange={(e)=>onChange({fieldName:`items.${index}.discount_percentage`, value: e.target.value})}
          />

          <ErrorMessage
            name={`items.${index}.discount_percentage`}
            component="p"
            className={stylesCss.fieldError}
          />
        </Box>

        <Box style={{width:"15%", ...styles.rowCenter, justifyContent:"flex-end"}}>
          <Button style={styles.trashButtonMobile} onClick={()=>remove(index)}>
            <img src={TrashIcon} width={"30px"} />
          </Button>
        </Box>
      </Box>
    </>

  )
}


const DesktopItemRow =  ({item, index, remove, durationOptions,onChange, errors, footerText})=>{
  return (
    <Box sx={{...styles.itemRowContainer, mt:2}}>
      <Grid container>
        <Grid item lg={5.5} style={{ paddingRight:"20px"}}>
          <Typography variant={ "h6" } color={colors.white.main}>
            Duration
          </Typography>
          <CustomSelect
            name={`items.${index}.duration`}
            value={item.duration}
            options={durationOptions}
            onChange={(e)=>onChange({fieldName:`items.${index}.duration`, value: e.target.value})}
          />
          { errors.length > 0  && Boolean(errors[index].duration) && (
            <FormHelperText>
              <MDTypography sx={{ fontSize: 14 }} color={"error"}>
                {errors[index].duration}
              </MDTypography>
            </FormHelperText>
          )}
          <ErrorMessage
            name={`items.${index}.duration`}
            component="p"
            className={stylesCss.fieldError}
          />
          <Typography
            variant="h6"
            color={colors.white.main}
            sx={styles.footerText}>
            {footerText}
          </Typography>
        </Grid>

        <Grid item lg={5.5} style={styles.discountGrid}>
          <Typography variant={"h6"} color={colors.white.main}>
            Discount Percent
          </Typography>
          <CustomSelect
            value={item.discount_percentage}
            name={`items.${index}.discount_percentage`}
            options={discountOptions}
            onChange={(e)=>onChange({fieldName:`items.${index}.discount_percentage`, value: e.target.value})}
          />

          <ErrorMessage
            name={`items.${index}.discount_percentage`}
            component="p"
            className={stylesCss.fieldError}
          />
        </Grid>

        <Grid item lg={0.4} sx={styles.rowCenter}>
          <Button style={styles.trashButton} onClick={()=>remove(index)}>
            <img src={TrashIcon} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}




export const ItemRow = ({item, index, remove, durationOptions,onChange, errors, footerText,isMobile=false})=>{

  return (isMobile?(
    <MobileItemRow item={item}
                   remove={remove}
                   index={index}
                   onChange={onChange}
                   errors={errors}
                   footerText={footerText}
                   durationOptions={durationOptions} />
  ):(
    <DesktopItemRow item={item}
                   remove={remove}
                   index={index}
                   onChange={onChange}
                   errors={errors}
                   footerText={footerText}
                   durationOptions={durationOptions} />
  ))
}
