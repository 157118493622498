

export default function sidenavLogoContainer() {

  return {
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around",
    alignItems:"flex-start"
  }
}



