import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import {IdolTypeEnum} from "../../../utils/constants";


const CountersAndTags = ({profile}) => {
  const {followers, idolizers, tags} = profile
  return (
    <Box style={styles.mainContainer}>
      <Box style={styles.firstContainer}>
        <Box style={styles.counterContainer}>
          <Typography style={styles.title}>{followers}</Typography>
          <Box style={styles.divider} />
          <Typography style={styles.subtitle}>followers</Typography>
        </Box>
        {profile?.idol_type !== IdolTypeEnum.influencer && (
          <Box style={styles.counterContainer}>
            <Typography style={styles.title}>{idolizers}</Typography>
            <Box style={styles.divider} />
            <Typography style={styles.subtitle}>idolizers</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{mt:0, ...styles.rowCenter}}>
        {tags && tags.map((value, index)=>(
          <Box style={styles.rowCenter} key={"tag-"+index}>
            {index > 0 && (
              <Typography style={{...styles.tags, margin:"0px 4px 0px 4px"}}> | </Typography>
            )}
          <Typography style={styles.tags}>{value}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CountersAndTags.defaultProps = {
  followers:  1853,
  idolizers: 692,
  tags:["MODEL", "DANCER", "FITNESS"]
};

CountersAndTags.propTypes = {
  followers: PropTypes.number,
  idolizers: PropTypes.number,
  tags: PropTypes.array,
};


export default CountersAndTags;

const styles = {
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center"
  },
  tags:{
    color:colors.white.main,
    letterSpacing:"0.05em",
    fontSize:11,
    fontWeight:500
  },
  mainContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    alignItems: "center"
  },
  firstContainer:{
    width:"100%",
    display:"flex",
    justifyContent: "center",
    paddingBlock: "20px"
  },
  counterContainer:{
    width:"50%",
    display:"flex",
    flexDirection:"column",
    paddingLeft:"20px",
    paddingRight:"20px",
    alignItems:"center"
  },
  divider:{
    height:2,
    background:colors.primary.main,
    width:"90px"
  },
  title:{
    fontSize:24,
    fontWeight:300,
    color:colors.white.main
  },
  subtitle:{
    marginTop:5,
    fontSize:10,
    fontWeight:500,
    color:colors.primary.main,
    letterSpacing:"0.1rem"
  }
}
