/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from "context";
import Footer from "../../Footer";
import {Paper} from "@mui/material";
import DashboardNavbar from "../../Navbars/DashboardNavbar";
import { LayoutNames } from "../../../constants";
import {useIsMobile} from "../../../utils/hooks";

function DashboardLayout({ children, icon, hideFooter, isAdmin= false}) {

  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const isMobile = useIsMobile()

  useEffect(() => {
    setLayout(dispatch, LayoutNames.general);
  }, [pathname]);

  return (
    <MDBox display={"flex"} minHeight={"calc(100vh - 70px)"}
            flexDirection={"column"}
            justifyContent={"space-around"}>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(250),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        <DashboardNavbar isAdmin={isAdmin} icon={icon}/>
      </MDBox>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          padding: isMobile ? "16px" : "60px",
          position: "relative",

          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(250),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          position: "relative",
          marginTop: 'auto',
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(250),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {!hideFooter && (
          <Footer />
        )}
      </MDBox>



    </MDBox>


  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
