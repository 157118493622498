import {useExploreApp} from "../utils/hooks";
import { useApi, useInfiniteScroll, useUserStore } from "../../../../utils/hooks";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {BaseCardIdolInfo} from "../../../../base/Cards/InfoCards/IdolizerInfoCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader";
import colors from "../../../../assets/theme/base/colors";
import {SecondaryButton} from "../../../../components/SecondaryButton";
import {notifyError, notifySuccess} from "../../../../services/notification";
import {UnauthenticatedPaths} from "../../../../paths/paths";
import {useNavigate} from "react-router-dom";

export const SearchUsers = (() => {
  const {searchValue} = useExploreApp()
  const api = useApi()
  const navigate = useNavigate()
  const user = useUserStore()

  const onApiCall = ({page}) => {
    let data = {page}
    data["search"] = searchValue
    return api.getExploreIdols(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall: onApiCall, queryKey: "users"})

  const refreshList = () => {
    refetch({refetchPage: (page, index) => index === 0})
  }
  useEffect(() => {
    refreshList()
  }, [searchValue])

  const goToProfile = (profile) => {
    navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", profile.idol_id_name),
      {
        state: {
          fromURL: UnauthenticatedPaths.explore
        }
      })
  }

  const follow = (profile) => {
    if (!profile || !profile.id) {
      notifyError("No Idol info")
      return
    }
    api.follow(profile.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Following " + profile.username)
        refreshList()
      } else {
        notifyError("Error following " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error following " + profile.username)
    })
  }


  const unfollow = (profile) => {
    if (!profile.id) {
      notifyError("No Idol information to unfollow")
      return
    }
    api.unfollow(profile.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Unfollowing " + profile.username)
        refreshList()
      } else {
        notifyError("Error unfollowing " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unfollowing " + profile.username)
    })
  }


  return (
    <Box>
      <Box id={"scroll-search-users"} style={{maxHeight: "100vh"}}>
        <InfiniteScroll
          dataLength={items.length}
          next={() => {
            if (!isFetchingNextPage) {
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={<Loader show={isLoading}/>}
          scrollableTarget={"scroll-search-users"}
        >
          <Box sx={styles.cardContainer}>
            {items.map((item, index) => (
              <Box sx={styles.card}>
                <BaseCardIdolInfo
                  onClick={() => goToProfile(item)}
                  infoCardStyles={{padding: "16px 0px 16px 12px"}}
                  nameStyles={{fontSize: 14}}
                  pointStyles={{fontSize: 14}}
                  idpStyles={{fontSize: 12}}
                  key={"user-" + index}
                  idolizerName={item.username}
                  avatar={`${item.image}?height=150&width=150`}
                  isVerified={item.is_validated}
                  isIdolizer={false}
                  idolPoints={item.idol_points}
                />
                <Box height={"54px"} width={"5px"} sx={{background: colors.background.default, borderRadius: "8px"}}/>

                <SecondaryButton text={item.following ? "UNFOLLOW" : "FOLLOW"}
                                 disabled={!user.id}
                                 onClick={() => {
                                   if (item.following) {
                                     unfollow(item)
                                   } else {
                                     follow(item)
                                   }
                                 }}
                                 buttonStyle={{width: "98px", height: "32px", borderRadius: "5px"}}
                                 textStyles={{fontSize: "10px", letterSpacing: "0.1em", fontWeight: 700}}/>
              </Box>
            ))}
          </Box>
        </InfiniteScroll>
      </Box>
    </Box>
  )
})

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    px: 2,
    mt: 2
  },
  card: {
    borderRadius: "5px",
    background: colors.card.background,
    justifyContent: "space-between",
    pr: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  scroll: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    flex: "none",
    width: "100vw",
    height: "100vh",
    flexGrow: "column nowrap",
    scrollSnapType: "y mandatory",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {display: "none"},
  },
}
