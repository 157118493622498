import React, {useReducer} from "react";

/* MUI components */
import { Box } from "@mui/system";

/* components */
import { IdolizerCard } from "components/IdolizerCardOld";

/* data */
import { idols } from "pages/portal/dashboard/idolizer/dummyData";
import { useApi, useInfiniteScroll } from "../../../../../utils/hooks";
import { initialRelatedState, relatedFilter, relatedFilterReducer } from "../../reducers";


/* assets */
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import IdolizerCardContainerSummary from "components/Mobile/IdolizersMobileScreen/IdolizerCardContainerSummary";
import avatar from "../../../../../assets/images/avatar_example.png";
import InfiniteScroll from "react-infinite-scroll-component";
import {PrimaryButtonWithCounter} from "../../../../../components/PrimaryButtonWithCounter";
const { card } = colors;

export const MobileIdolsCard = ({ Idols = [...idols], isIdolizer, filterCounter, isInfluencer }) => {
  const cardProps = {
    hasButton: true,
    containerStyles: styles.card_container,
    isMobile: true,
    centerPoints: true,
    buttonContainerStyles: styles.button_container,
    buttonStyles: styles.button,
  };
  const [filter, dispatchFilter] = useReducer(relatedFilterReducer, initialRelatedState)
  const api = useApi();
  const getRelation = ()=>{
    let relation = ""
    if(filter.all){
      relation = "followers_idolizers"
    }else if(filter.followers){
      relation = "followers"
    }else{
      relation = "idolizers"
    }
    return relation
  }
  const onApiCall = ({page})=>{
    let data = {page, relation: getRelation()}
    return api.getRelatedUsers(data)
  }

  const {
    items,
    fetchNextPage,
    hasNextPage,
    extraData,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users", filters:filter})

  return (
    <Box>
      <Box sx={{ ...styles.buttons }}>
        <PrimaryButtonWithCounter
          buttonStyle={styles.buttonAll}
          textStyles={styles.buttonText}
          text={'ALL'}
          counter={extraData.all??0}
          onClick={()=>{
            dispatchFilter({type: relatedFilter.all})
          }}
        />
        <PrimaryButtonWithCounter
          buttonStyle={styles.buttonFollowing}
          textStyles={styles.buttonText}
          mainColor={colors.tertiary}
          size={"small"}
          text={'FOLLOWERS'}
          counter={filterCounter?.followers}
          onClick={()=>{
            dispatchFilter({type: relatedFilter.followers})
          }}
        />
        {!isInfluencer && <PrimaryButtonWithCounter
          buttonStyle={styles.buttonIdolizing}
          textStyles={styles.buttonText}
          mainColor={colors.tertiary}
          size={"small"}
          text={'IDOLIZING'}
          counter={filterCounter?.idolizers}
          onClick={() => {
            dispatchFilter({type: relatedFilter.idolizers})
          }}
        />}
      </Box>
      <InfiniteScroll
          dataLength={items.length}
          next={() => {
            if(!isFetchingNextPage){
              return fetchNextPage()
            }
          }}
          hasMore={!!hasNextPage}
          loader={null }>
          <Box sx={styles.container}>
            {!isIdolizer
              ? items.map((i) => (
                  <IdolizerCardContainerSummary
                    key={i.id}
                    isMobile={true}
                    avatar={i.image}
                    isVerified={i.is_validated}
                    idolPoints={i.idol_points}
                    idolizerName={i.username}
                    type={!!i.idolizing ? "idolizing" : "following"}
                  />
                ))
              : items.map((i) => <IdolizerCard key={i.id} {...i} {...cardProps} />)}
          </Box>
      </InfiniteScroll>
    </Box>
  );
};

const styles = {
  buttons: {
    display: "flex",
    flexDirection: "row",
    maxHeight: "25px",
    gap: "13px",
    marginTop: "16px",
  },
  'buttons > *': {
    flexGrow: 1,
  },
  buttonText: {
    fontSize: "10px",
    lineHeight: "9px",
    letterSpacing: "0.1em",
  },
  buttonAll: {
    minHeight: "25px !important",
    height: "25px",
    paddingX: "16px !important",
  },
  buttonFollowing: {
    minHeight: "25px !important",
    height: "25px",
  },
  buttonIdolizing: {
    minHeight: "25px !important",
    height: "25px",
  },
  container: {
    background: card.background,
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: '18px',
    height: '310px',
    marginTop: "1rem",
    padding: pxToRem(12),
    overflow: 'auto'
  },
  card_container: {
    height: pxToRem(73),
    padding: `${pxToRem(9)} ${pxToRem(12.9)}`,
  },
  button_container: {
    padding: 0,
  },
  button: {
    height: pxToRem(25),
    padding: 0,
    width: pxToRem(98),
  },
};
