/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {memo, useEffect, useState} from "react";

// react-router-dom components
import {NavLink, useLocation} from "react-router-dom";

import AdminLogo from "assets/images/idolidol_admin.png"

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "base/Sidenav/SidenavCollapse";
import SidenavList from "base/Sidenav/SidenavList";
import SidenavItem from "base/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "base/Sidenav/SidenavRoot";
// Material Dashboard 2 PRO React context
import {
  setMiniSidenav,
  setOpenSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from "context";
import MenuIcon from "assets/icons/menu.svg";
import dividerStyle from "./styles/divider";
import sidenavLogoContainer from "./styles/sidenavLogoContainer";
import {AvatarOverImage} from "../../components/AvatarOverImage";
import { SupportChat } from "../../components/SupportChat";
import { useUserStore } from "../../utils/hooks";
import { NavigationMenu, UserTypes } from "../../constants";
import {observer} from "mobx-react";


function Sidenav({
                   color,
                   brand,
                   brandName,
                   isIdol,
                   isMobile,
                   userAvatar,
                   backgroundColor,
                   menu,
                   myProfileId,
                   idol_id_name,
                   isMyProfile,
                   atProfile,
                   editingProfile,
                   isAdmin,
                   ...rest
                 }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentSidenav, whiteSidenav, openSidenav, darkMode} = controller;
  const location = useLocation();
  const [showChatSupport, setShowChatSupport] = useState(true)
  const {pathname} = location;
  const currentUser = useUserStore()
  // const collapsedName = pathname.split("/").slice(1);
  // const collapseName = collapsedName[collapsedName.length - 2];
  // const items = pathname.split("/").slice(1);
  // const itemParentName = items[1];
  // const itemName = items[items.length - 2];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(pathname);
    // setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({name, path, key, href}) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{textDecoration: "none"}}
        >
          <SidenavItem name={name} nested/>
        </Link>
      ) : (
        <NavLink to={path} key={key} sx={{textDecoration: "none"}}>
          <SidenavItem name={name} active={path === pathname} nested/>
        </NavLink>
      )
    );
    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) => {
    return collapses.map(({name, collapse, icon, path, href, key, noCollapse}) => {
      let returnValue = null
      if (collapse) {
        returnValue = (
          <SidenavItem
            icon={<IconNav icon={icon}/>}
            key={key}
            color={color}
            name={name}
            active={key === pathname}
            open={openNestedCollapse === key}
            onClick={
              ({currentTarget}) => {
                if (openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")) {
                  setOpenNestedCollapse(false)
                } else {
                  setOpenNestedCollapse(key)
                }
              }
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{textDecoration: "none"}}
          >
            <SidenavItem icon={<IconNav icon={icon}/>} color={color} name={name} active={key === pathname}/>
          </Link>
        ) : (
          <NavLink to={path} key={key} sx={{textDecoration: "none"}}>
            <SidenavItem icon={<IconNav icon={icon}/>} color={color} name={name} active={key === pathname}/>
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });
  }

  const IconNav = ({icon, isMobile, style, noCollapse = false}) => {
    let defaultStyle = {marginLeft: 32, marginRight: 15, ...style}
    if (isMobile) {
      defaultStyle = {
        width: "18px",
        ...defaultStyle
      }
    }
    return <img src={icon} style={defaultStyle}/>
  }

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = menu.map(
    ({type, name, icon, title, collapse, noCollapse, key, href, path}) => {
      let returnValue;

      if (!isMobile) {
        return (
          <NavLink to={path} key={key}>
            <SidenavCollapse
              isMobile={isMobile}
              name={name}
              icon={<IconNav icon={icon}/>}
              noCollapse={noCollapse}
              active={pathname.includes(path)}
            >
              {collapse ? renderCollapse(collapse, key) : null}
            </SidenavCollapse>
          </NavLink>)
      }

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{textDecoration: "none"}}
            >
              <SidenavCollapse
                name={name}
                icon={<IconNav icon={icon}/>}
                active={key === pathname}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse === undefined && path) {
          returnValue = (
            <NavLink to={path} key={key}>
              <SidenavCollapse
                isMobile={isMobile}
                name={name}
                key={key}
                icon={<IconNav icon={icon}/>}
                noCollapse={noCollapse}
                active={key === pathname}
                onClick={() => setOpenSidenav(dispatch, !!noCollapse)}
              >
                {collapse ? renderCollapse(collapse, key) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              isMobile={isMobile}
              key={key}
              name={name}
              icon={<IconNav icon={icon}/>}
              active={key === pathname}
              open={openCollapse === key}
              noCollapse={noCollapse}
              onClick={() => {
                setOpenSidenav(dispatch, !!noCollapse)
                setOpenCollapse(openCollapse === key ? false : key)
              }}
            >
              {collapse ? renderCollapse(collapse, key) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            sx={() => dividerStyle()}
            key={key}
            light={true}
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      variant="permanent"
      ownerState={{transparentSidenav, whiteSidenav, miniSidenav, darkMode, openSidenav}}
    >
      <MDBox id={"drawer-mobile"} pt={3} pb={1} px={isMobile ? 0 : 4}
             sx={() => sidenavLogoContainer()}>
        <MDBox display="row" ml={1}>
          {!isAdmin? (
            <AvatarOverImage
              avatarImage={userAvatar}
              backgroundImage={brand}
              myProfileId={myProfileId}
              idol_id_name={idol_id_name}
              isMyProfile={isMyProfile}
              atProfile={atProfile}
              editingProfile={editingProfile}
              isIdol={isIdol}
            />
          ):(
            <img src={AdminLogo} />
          )}

          {/*{brand && <MDBox component="img" src={brand} alt="Brand" width={isMobile?"152px":"162px"} />}*/}
        </MDBox>
        {isMobile &&
        <MDBox onClick={() => setOpenSidenav(dispatch, false)} component="img" src={MenuIcon} alt="Brand"/>}

      </MDBox>
      <List>{renderRoutes}</List>
      {currentUser.userType === UserTypes.Idol && (
        <SupportChat
          show={showChatSupport}
          onOpen={()=>setShowChatSupport(true)}
          onClose={()=>setShowChatSupport(false)}
        />
      )}

    </SidenavRoot>
  );
}


// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default observer(Sidenav);
