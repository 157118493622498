import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
const {white, dark} = colors;

export const styles = {
    balance_container: {
        display: 'grid',
        gridTemplateColumns: '2fr 0.08fr 1.5fr',
        gap: 7,
        justifyContent: 'center'
    },
    balance_layout: {
        display: 'flex',
        flexDirection: 'column',
    },
    balance: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 1
    },
    balance_title: {
        color: white.main,
        fontSize: pxToRem(15.0463),
        fontWeight: 400,
    },
    balanceAmount: {
        color: white.main,
        fontSize: pxToRem(40),
        fontWeight: 'lighter',
    },
    balance_divider: {
        background: white.main, 
        margin: 0,
        opacity: 0.2, 
    },
    divisor_line: {
        backgroundColor: white.main,
        borderRadius: '8px',
        height: '100%',
        margin: 0,
        opacity: 0.2,
        width: '10px',
    },
    container: {
        background: dark.input, 
        borderRadius: '8px', 
        display: 'flex', 
        justifyContent: 'center', 
        opacity: 0.2, 
        padding: '12px',
        width: '100%', 
    },
    input_container: {
        display: 'flex', 
        height: '100%', 
        justifyContent: 'center',
    },
    input: {
        color: white.main, 
        fontSize: pxToRem(40),
        textAlign: 'center',
        width: '100%', 
        background: dark.input,
        textAlign: 'center',
        border: 'none',
        '.MuiInput-input': {textAlign: 'center'}
    },
    actions_container: {
        marginTop: '1rem',
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr',
    },
};