import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import { BasePostCard } from "base/Cards/PostCard";
import { WithdrawlCardDetailedRecord } from "../../components/RecordCard/RecordCard";
import OrderingButton from "../../components/WithdrawButton/OrderingButton";
import WithdrawlsIcon from "../../assets/icons/withdrawls.svg";
import React, { useEffect, useState } from "react";
import { ChargeWallet } from "../../components/ChargeWallet";
import WalletRed from "assets/icons/wallet-idol-bank-red.svg";
import Wallet from "assets/icons/wallet-idol-bank.svg";
import Withdraw from "assets/icons/withdrawls.svg";
import { useApi, useInfiniteScroll, useIsMobile, useUserStore } from "../../utils/hooks";
import { Box } from "@mui/system";
import { MobileSectionHeader } from "../../components/Mobile/MobileIdolDashboardSectionHeader";
import { BalanceCardMobile } from "./mobile/balance";
import pxToRem from "../../assets/theme/functions/pxToRem";
import { SavedCardMobile } from "./mobile/SavedCardMobile";
import AddCreditCardMobile from "./mobile/AddCreditCardMobile/AddCreditCardMobile";
import { WithdrawalsMobile } from "./mobile/WithdrawalsMobile";
import { moneyFormat } from "../../services/helpers";
import { notifyError, notifySuccess, notifyWarning } from "../../services/notification";
import {NavigationMenu} from "../../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import colors from "../../assets/theme/base/colors";

export const BankWalletPage = () => {

  const filteringFields = {
    Amount: "amount",
    Date: "invoice_date",
  }
  const loginStore = useUserStore()
  const [Withdrawals, setWithdrawals] = useState([])
  const [Amount, setAmount] = useState(0);
  const [Balance, setBalance] = useState({amount:0, credits:0});
  const [UserCards, setUserCards] = useState([]);
  const [orderWithdrawlsBy, setOrderWithdrawlsBy] = useState(filteringFields.Amount)
  const [cardSelected, setCardSelected] = useState('');
  const [Loading, setLoading] = useState(false);
  const isMobile = useIsMobile()
  const api = useApi()
  const [filters, setFilters] = useState()



  const onApiCall = ({page, filters})=>{
    let data = {page, ...filters}
    return api.getWithdrawals(data)
  }


  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "withdrawals", filters})



  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("IDOL BANK");
  }, []);



  const createWithdraw = ()=>{
    setLoading(true)
    api.createWithdraw({amount: Amount}).then(response => {
      if(response.kind === "ok"){
        getBalance()
        refetch({refetchPage: (page, index) => index === 0})
        notifySuccess("Successful transaction")
      }else{
        notifyError("Error creating withdraw")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error creating withdraw")
    }).finally(() => {
      setLoading(false)
    })
  }

  const getBalance = () => {
    api.getBalance().then(res => {
      if (res.kind === "ok") {
        setBalance(res.data)
        setAmount(res.data.amount)
      } else {
        console.log("error", res)
      }
    })
      .catch(reason => console.log(reason))
  };


  const getUserCards = () => {
    api.getUserCards().then(res => {
      if (res.kind === "ok") {
        const cardList = []
        for(const card of res.data.results){
          cardList.push({
            ...card,
            card_number:card.hashed_card_number
          })
        }
        setUserCards(cardList)
      } else {
        console.log("error", res)
      }
    })
      .catch(reason => console.log(reason))
  }


  const orderByAmount = (sorted) => {
    setOrderWithdrawlsBy(filteringFields.Amount)
    setFilters(value=>{
      return {...value, ordering: sorted ? `${filteringFields.Amount}` : `-${filteringFields.Amount}`}
    })
  }

  const orderByDate = (sorted) => {
    setOrderWithdrawlsBy(filteringFields.Date)
    setFilters(value=>{
      return {...value, ordering: sorted ? `${filteringFields.Date}` : `-${filteringFields.Date}`}
    })
  }

  const deleteCard = (id) => {
    api.deleteCard(id).then(response =>{
      if(response.kind === "ok"){
        getUserCards()
      }else{
        notifyError("Error deleting this card")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error deleting this card")
    })
  }

  const outerContainer = {
    marginTop: pxToRem(15)
  }

  const containerStyles = {
    marginTop: pxToRem(14)
  }


  useEffect(() => {
      getBalance()
      getUserCards()
  }, [])

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={WalletRed}>
        {isMobile ? (
          <Box>
            <MobileSectionHeader Icon={Wallet} Title={"Idol Bank"} />
            <BalanceCardMobile outerContainer={outerContainer}
                               currentBalance={moneyFormat(Balance.amount)}
                               lifetimeBalance={moneyFormat(Balance.credits)}
                               inputAmount={Amount}
                               changeInputAmount={(e) => {
                                let value = parseInt(!isNaN(e.target.value)?e.target.value:0)
                                if(!value || value < 0){
                                  value = 0
                                }else if(value > Balance.amount){
                                  notifyWarning('Withdrawal amount cannot be greater than current balance')
                                  value = Balance.amount
                                }
                                setAmount(value)
                              }}
                                loading={Loading}
                               buyActionHandler={() =>createWithdraw()}
                               />
            <MobileSectionHeader containerStyles={containerStyles} Title={"Saved Cards"} />
            {UserCards.map((card) => (
              <SavedCardMobile key={card.id} card={card}
                               cardSelected={cardSelected}
                               setCardSelected={setCardSelected}
                               deleteCard={deleteCard}
                               isMobile={isMobile} />
            ))}
            <Box sx={{ marginTop: "24px" }}>
              <AddCreditCardMobile  refreshList={()=>getUserCards()} />
            </Box>
            <MobileSectionHeader
              Icon={Withdraw}
              containerStyles={containerStyles}
              Title={"Withdrawals"}
            />
            <WithdrawalsMobile withdrawals={items} />
          </Box>
        ) : (
          <Box sx={{display: "flex", flexDirection: "column", gap: "40px"}}>
          <ChargeWallet
              buttonTitle="WITHDRAW"
              title="Idol Bank"
              cardSelected={cardSelected}
              setCardSelected={setCardSelected}
              currentBalance={moneyFormat(Balance.amount)}
              lifetimeBalance={moneyFormat(Balance.credits)}
              inputAmount={Amount}
              changeInputAmount={(e) => {
                let value = parseInt(!isNaN(e.target.value)?e.target.value:0)
                if(!value || value < 0){
                  value = 0
                }else if(value > Balance.amount){
                  notifyWarning('Withdrawal amount cannot be greater than current balance')
                  value = Balance.amount
                }
                setAmount(value)
              }}
              buyActionHandler={() =>createWithdraw()}
              userCards={UserCards}
              refreshList={()=>getUserCards()}
              deleteCard={deleteCard}
            />
            <BasePostCard
              title="Withdrawals"
              icon={WithdrawlsIcon}
              headerControls={[
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "240px",
                  gap: "25px",
                  justifyContent: "flex-end"
                }}>
                  <OrderingButton
                    text="Amount"
                    onClick={orderByAmount}
                    currentFilterSelected={orderWithdrawlsBy === filteringFields.Amount}
                  />
                  <OrderingButton
                    text="Date"
                    onClick={orderByDate}
                    currentFilterSelected={orderWithdrawlsBy === filteringFields.Date}
                  />
                </Box>
              ]}
            >
              <InfiniteScroll
                dataLength={items.length}
                style={styles.scroll}
                next={() => {
                  if(!isFetchingNextPage){
                    return fetchNextPage()
                  }
                }}
                hasMore={!!hasNextPage}
                loader={<Loader show={isLoading}/> }
              >
                {items.map((transaction, index)=>(
                  <WithdrawlCardDetailedRecord
                    titleStyle={{fontSize:16}}
                    dateStyle={{fontSize:14}}
                    key={transaction.id}
                    record={transaction}
                    centered={false}
                    amountStyles={styles.amount}
                  />
                ))}
              </InfiniteScroll>
            </BasePostCard>
          </Box>
        )}
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
}

const styles = {
  scroll:{
    padding:"0px 25px 0px 10px",
    margin:"24px -10px 0px -10px",
    maxHeight:"80vh"
  },
  amount: {
    color: colors.white.main,
    fontSize:22,
    paddingLeft: pxToRem(59),
  },
}


