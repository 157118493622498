import * as Yup from 'yup';
import {IdolTypes} from '../../constants';
import {AuthRoutes, OnboardingRoutes} from '../../routes';
import {FormikFields, StoreFields, StoreFieldsOnboarding} from "./models";
import { IdolPaths } from "../../paths/idolPaths";
import { GuestPaths } from "../../paths/guestPaths";

/**
 * List of fields used in specific scenarios
 */

const OnboardingPhases = {
  Profile: GuestPaths.idolProfile,
  Incorporated: GuestPaths.idolIncorporated,
  Bank: GuestPaths.idolBank,
  Tax: GuestPaths.idolTax,
  Verify: GuestPaths.idolDocuments,
  Twitter: GuestPaths.idolTwitter,
}

const OnboardingFormikPhaseFields = {
  [OnboardingPhases.Profile]: {
    [IdolTypes.Influencer]: [
      'idol_type',
      'image',
      'username',
      'idol_id_name',
      'email',
      'password',
      'repeat_password',
      'termsAndConditions',
      'adult',
    ],
    [IdolTypes.Professional]: [
      'tag_1',
      'tag_2',
      'tag_3',
      'biography',
    ],
    [IdolTypes.Entrepreneur]: [
      'measurement_type',
      'age',
      'height',
      'bust',
      'waist',
      'hips',
      'shoe_size',
      'gender',
      'location',
      'ethnicity',
      'language',
    ],
  },
  [OnboardingPhases.Incorporated]: {
    [IdolTypes.Influencer]: [],
    [IdolTypes.Professional]: [
      'inc_first_name',
      'inc_middle_name',
      'inc_last_name',
      'inc_date_of_birth',
      'inc_address_line_1',
      'inc_address_line_2',
      'inc_country',
      'inc_state',
      'inc_city',
      'inc_zip_code',
      'inc_phone_number',
    ],
    [IdolTypes.Entrepreneur]: [],
  },
  [OnboardingPhases.Bank]: {
    [IdolTypes.Influencer]: [],
    [IdolTypes.Professional]: [
      'bank_name',
      'bank_account_number',
      'bank_routing_number',
    ],
    [IdolTypes.Entrepreneur]: [],
  },
  [OnboardingPhases.Tax]: {
    [IdolTypes.Influencer]: [],
    [IdolTypes.Professional]: [
      'tax_social_security',
      'tax_w8_w9_file',
    ],
    [IdolTypes.Entrepreneur]: [
      'tax_article_of_incorporation',
      'tax_company_name',
      'tax_id',
      'tax_address_line_1',
      'tax_address_line_2',
      'tax_country',
      'tax_state',
      'tax_city',
      'tax_zip_code',
      'tax_phone_number',
    ],
  },
  [OnboardingPhases.Verify]: {
    [IdolTypes.Influencer]: [],
    [IdolTypes.Professional]: [
      'verify_gov_id',
      'verify_consent_form',
    ],
    [IdolTypes.Entrepreneur]: [],
  },
  [OnboardingPhases.Twitter]: {
    [IdolTypes.Influencer]: [],
    [IdolTypes.Professional]: [
      'verify_twitter_username',
    ],
    [IdolTypes.Entrepreneur]: [],
  },
}

/**
 * Reverse definitions.
 * This can be dark the first time you read it.
 */

/**
 * Get the index.js phase from field name.
 */

const getIndexPhaseFromField = (fieldName) => {
  const fieldListByPhase = Object.values(OnboardingFormikPhaseFields)
    .map(phase => Object.values(phase))
    .map(fieldLists => [].concat(fieldLists.pop()).concat(fieldLists.pop()).concat(fieldLists.pop()))
  return fieldListByPhase.findIndex(listOfFields => listOfFields.some(field => field === fieldName))
}

/**
 * Get the phase URL from field name.
 */

const getPhaseFromField = (fieldName) => {
  const phases = Object.keys(OnboardingFormikPhaseFields)
  return phases[getIndexPhaseFromField(fieldName)]
}

/**
 * Get the first phase URL from field name.
 */

const getFirstPhaseFromFieldList = (fieldList = []) => {
  const phases = Object.keys(OnboardingFormikPhaseFields)
  const idx = fieldList.map(field => getIndexPhaseFromField(field)).reduce((a, b) => Math.min(a, b))
  return phases[idx]
}

const OnboardingStoreFieldList = [
  'last_url_visted',
  'last_time_visited',
  'user_type',
  'idol_type',
  'image',
  'username',
  'idol_id_name',
  'email',
  'password',
  'repeat_password',
  'tag_1',
  'tag_2',
  'tag_3',
  'biography',
  'age',
  'measurement_type',
  'height',
  'bust',
  'waist',
  'hips',
  'shoe_size',
  'gender',
  'location',
  'ethnicity',
  'language',
  'termsAndConditions',
  'adult',
  'inc_first_name',
  'inc_middle_name',
  'inc_last_name',
  'inc_date_of_birth',
  'inc_address_line_1',
  'inc_address_line_2',
  'inc_country',
  'inc_state',
  'inc_city',
  'inc_zip_code',
  'inc_phone_number',
  'bank_name',
  'bank_account_number',
  'bank_routing_number',
  'tax_social_security',
  'tax_w8_w9_file',
  'tax_article_of_incorporation',
  'tax_company_name',
  'tax_id',
  'tax_address_line_1',
  'tax_address_line_2',
  'tax_country',
  'tax_state',
  'tax_city',
  'tax_zip_code',
  'tax_phone_number',
  'verify_gov_id',
  'verify_consent_form',
  'verify_twitter_username',
]

const LoginStoreFieldList = [
  'id',
  'email',
  'username',
  'user_type_id',
  'user_type_name',
  'user_type_codename',
  'gender_of_interest_id',
  'gender_of_interest_name',
  'gender_of_interest_codename',
  'time_zone_id',
  'time_zone_name',
  'time_zone_codename',
  'phone_number',
  'image',
  'imageVersion',
  'thumbnail',
  'description',
  'biography',
  'birth',
  'gender',
  'address',
  'is_confirmed',
  'is_validated',
  'is_active',
  'is_declined',
  'is_suspended',
  'is_pending',
  'number_of_followers',
  'number_of_following',
  'number_of_subscribers',
  'number_of_subscribed',
  'number_of_posts',
  'number_of_image_posts',
  'number_of_video_posts',
  'number_of_likes',
  'number_of_tips',
  'number_of_idol_points',
  'current_balance',
  'is_followed',
  'is_subscribed',
  'is_deleted',
  'referral_code',
  'is_staff',
  'date_joined',
  'is_created_by_social_account',
  'vendor_id',
  'idol_type',
  'idol_id_name',
  'tag_1',
  'tag_2',
  'tag_3',
  'age',
  'height',
  'bust',
  'waist',
  'hips',
  'shoe_size',
  'measurement_type',
  'location',
  'language',
  'ethnicity',
  'inc_first_name',
  'inc_middle_name',
  'inc_last_name',
  'inc_date_of_birth',
  'inc_address_line_1',
  'inc_address_line_2',
  'inc_city',
  'inc_state',
  'inc_country',
  'inc_zip_code',
  'inc_phone_number',
  'bank_name',
  'bank_account_number',
  'bank_routing_number',
  'tax_social_security',
  'tax_w8_w9_file',
  'tax_article_of_incorporation',
  'tax_company_name',
  'tax_id',
  'tax_address_line_1',
  'tax_address_line_2',
  'tax_city',
  'tax_state',
  'tax_country',
  'tax_zip_code',
  'tax_phone_number',
  'verify_consent_form',
  'verify_gov_id',
  'verify_twitter_username',
  'token',
  'activeRoute',
  'mobileActiveRoute',
]

const MeetingStoreFieldList = [
  'meeting',
  'is_host',
]

/**
 * Helper Functions
 */

const getValidationFields = (fieldEnum, fields) => {
  const tmp = {}
  fields.forEach(field => {
    try {
      tmp[field] = fieldEnum[field].validation
    } catch (e) {
      console.log('getValidationFields field', field)
      console.log('getValidationFields Fields[field]', fieldEnum[field])
    }
  })
  return tmp
}

const getFieldTypes = (fieldEnum, fields) => {
  const tmp = {}
  fields.forEach(field => {
    try {
      tmp[field] = fieldEnum[field].type
    } catch (e) {
      console.log('getFieldTypes field', field)
      console.log('getFieldTypes Fields[field]', fieldEnum[field])
    }
  })
  return tmp
}

const getInitialFormValues = (fieldEnum, fields) => {
  const tmp = {}
  fields.forEach(field => {
    try {
      tmp[field] = fieldEnum[field].initialValue
    } catch (e) {
      console.log('getInitialFormValues field', field)
      console.log('getInitialFormValues Fields[field]', fieldEnum[field])
    }
  })
  return tmp
}

const getFieldsByIdolType = (idolType) => {
  let tmp = []
  const arr = Object.values(OnboardingFormikPhaseFields)
  arr.forEach(e => {
    tmp = tmp.concat(e[IdolTypes.Influencer])
    if (idolType > IdolTypes.Influencer) {
      tmp = tmp.concat(e[IdolTypes.Professional])
    }
    if (idolType > IdolTypes.Professional) {
      tmp = tmp.concat(e[IdolTypes.Entrepreneur])
    }
  })
  return tmp
}

const fileFields = [
  'image',
  'tax_w8_w9_file',
  'tax_article_of_incorporation',
  'verify_gov_id',
  'verify_consent_form',
]

/**
 * Application constant and functions
 */

const OnboardingStoreFields = getFieldTypes(StoreFieldsOnboarding, OnboardingStoreFieldList)

const LoginStoreFields = getFieldTypes(StoreFields, LoginStoreFieldList)

const MeetingStoreFields = getFieldTypes(StoreFields, MeetingStoreFieldList)

const ProfileCards = {
  GeneralInformation: [
    'username',
    'idol_id_name',
    'tag_1',
    'tag_2',
    'tag_3',
    'biography',
    'age',
    'height',
    'shoe_size',
    'bust',
    'waist',
    'hips',
    'gender',
    'location',
    // 'ethnicity',
    'language',
  ],
  Incorporated: [
    'email',
    // 'password',
    // 'repeat_password',
    'inc_first_name',
    'inc_middle_name',
    'inc_last_name',
    'inc_date_of_birth',
    'inc_address_line_1',
    'inc_address_line_2',
    // 'inc_country',
    // 'inc_state',
    // 'inc_city',
    // 'inc_zip_code',
    'inc_phone_number',
  ],
  Bank: [
    'bank_name',
    'bank_account_number',
    'bank_routing_number',
  ],
  Tax: [
    'tax_social_security',
    // 'tax_w8_w9_file',
    // 'tax_article_of_incorporation',
    'tax_company_name',
    'tax_id',
    'tax_zip_code',
    'tax_address_line_1',
    'tax_address_line_2',
    'tax_country',
    'tax_state',
    'tax_city',
    'tax_phone_number',
  ],
}

/**
 * getIdolValidationFields receives the current phase and the Idol Type and returns the formik validation list
 * @param phase The current phase on the onboarding process
 * @param idolType Influencer, Professional, Entrepeneur
 * @returns {YupSchemeValidationObject}
 */

const getCompleteFieldsByIdolType = (phase, idolType) => {
  let fields = OnboardingFormikPhaseFields[phase][IdolTypes.Influencer]
  if (idolType === IdolTypes.Professional) {
    fields = fields
      .concat(OnboardingFormikPhaseFields[phase][IdolTypes.Professional])
  } else if (idolType === IdolTypes.Entrepreneur) {
    fields = fields
      .concat(OnboardingFormikPhaseFields[phase][IdolTypes.Professional])
      .concat(OnboardingFormikPhaseFields[phase][IdolTypes.Entrepreneur])
  }
  return fields
}

/**
 * Onboarding formik helpers
 * @param phase
 * @param idolType
 * @returns {*}
 */

const getOnboardingIdolValidationFields = (phase, idolType) => {
  return Yup.object().shape(getValidationFields(FormikFields, getCompleteFieldsByIdolType(phase, idolType)))
}

const getOnboardingIdolInitialValues = (phase, store, specialCases) => {
  return getInitialFormValuesFromStore({...store}, getCompleteFieldsByIdolType(phase, store.idol_type), specialCases)
}

const getInitialFormValuesFromStore = (store, values, specialCases) => {
  const tmp = {}
  values.forEach((fieldName) => {
    tmp[fieldName] = store[fieldName]
  })

  specialCases?.forEach(([field, value]) => {
    tmp[field] = value
  })

  return tmp
}


/**
 * Profile page formik helper
 * @param fieldList
 * @returns {*}
 */

const getFormikValidationFields = (fieldList = []) => {
  return Yup.object().shape(getValidationFields(FormikFields, fieldList));
}

const getFormikValidationBooleanFields = (field, message = "",) => {
  return Yup.object().shape({
  [field]: Yup.boolean()
    .required(message)
    .oneOf([true], message)
  });
}

/**
 * Helper for navigation pages on onboarding
 * @param idolType: number
 * @param currentPage: string
 * @returns [prevPage: string, currentPage: string, nextPage: string]
 */

const getIdolOnboardingNavigationPages = (idolType, currentPage = "") => {
  if (!idolType) {
    return [GuestPaths.whoAreYou, currentPage, GuestPaths.idolProfile]
  }

  if (idolType === IdolTypes.Influencer) {
    switch (currentPage) {
      case GuestPaths.idolProfile:
        return [GuestPaths.selectIdolType, currentPage, GuestPaths.idolReady]
      case GuestPaths.idolReady:
        return [GuestPaths.idolProfile, currentPage, GuestPaths.login]
      default:
        return null
    }
  }

  const pages = Object.values(OnboardingRoutes)
  const currentPageIndex = pages.indexOf(currentPage)
  let prevPage = currentPageIndex - 1
  let nextPage = currentPageIndex + 1

  if (currentPage === GuestPaths.idolReady || currentPage === GuestPaths.idolTwitter) {
    return [pages[prevPage], currentPage, GuestPaths.login]
  }
  return [pages[prevPage], currentPage, pages[nextPage]]
}

export {
  getIndexPhaseFromField,
  getPhaseFromField,
  getFirstPhaseFromFieldList,
  getValidationFields,
  getFieldTypes,
  getInitialFormValues,
  getOnboardingIdolValidationFields,
  getOnboardingIdolInitialValues,
  getInitialFormValuesFromStore,
  getFormikValidationFields,
  getFormikValidationBooleanFields,
  getIdolOnboardingNavigationPages,
  getFieldsByIdolType,
  fileFields,
  OnboardingPhases,
  OnboardingStoreFieldList,
  LoginStoreFieldList,
  OnboardingStoreFields,
  LoginStoreFields,
  MeetingStoreFields,
  ProfileCards,
}

