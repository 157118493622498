export const getIdolProfileValues = (profile) => ({
  username: profile ? profile?.username : " ",
  idol_id_name: profile ? profile?.idol_id_name : " ",
  tag_1: profile ? profile?.tag_1 : " ",
  tag_2: profile ? profile?.tag_2 : " ",
  tag_3: profile ? profile?.tag_3 : " ",
  biography: profile ? profile?.biography : " ",
  age: profile ? profile?.age : 0,
  height: profile ? profile?.height : 0,
  shoe_size: profile ? profile?.shoe_size : 0,
  bust: profile ? profile?.bust : " ",
  waist: profile ? profile?.waist : " ",
  hips: profile ? profile?.hips : 0,
  gender: profile ? profile?.gender?.name ?? " " : " ",
  location: profile ? profile?.location : " ",
  ethnicity: profile ? profile?.ethnicity?.name ?? " " : " ",
  language: profile ? profile?.language?.map(l => l?.name).join(', ') : " ",
})

export const getIdolIncValues = (profile) => ({
  email: profile ? profile?.email : " ",
  password: null,
  repeat_password: null,
  inc_first_name: profile ? profile?.inc_first_name : " ",
  inc_middle_name: profile ? profile?.inc_middle_name : " ",
  inc_last_name: profile ? profile?.inc_last_name : " ",
  inc_date_of_birth: profile ? profile?.inc_date_of_birth : " ",
  inc_address_line_1: profile ? profile?.inc_address_line_1 : " ",
  inc_address_line_2: profile ? profile?.inc_address_line_2 : " ",
  inc_country: profile ? profile?.inc_country?.name : " ",
  inc_state: profile ? profile?.inc_state?.name : " ",
  inc_city: profile ? profile?.inc_city?.name : " ",
  inc_zip_code: profile ? profile?.inc_zip_code : " ",
  inc_phone_number: profile ? profile?.inc_phone_number : " ",
});

export const getIdolBankValues = (profile) => ({
  bank_name: profile ? profile?.bank_name : "",
  bank_account_number: profile ? profile?.bank_account_number : "",
  bank_routing_number: profile ? profile?.bank_routing_number : "",
})

export const getIdolTaxValues = (profile) => ({
  tax_social_security: profile ? profile?.tax_social_security : "",
  tax_w8_w9_file: profile ? profile?.tax_w8_w9_file : "",
  tax_article_of_incorporation: profile ? profile?.tax_article_of_incorporation : "",
  tax_company_name: profile ? profile?.tax_company_name : "",
  tax_id: profile ? profile?.tax_id : "",
  tax_zip_code: profile ? profile?.tax_zip_code : "",
  tax_address_line_1: profile ? profile?.tax_address_line_1 : "",
  tax_address_line_2: profile ? profile?.tax_address_line_2 : "",
  tax_country: profile ? profile?.tax_country?.name : "",
  tax_state: profile ? profile?.tax_state?.name : "",
  tax_city: profile ? profile?.tax_city?.name : "",
  tax_phone_number: profile ? profile?.tax_phone_number : "",
})

export const getIdolizerDetailsValues = (profile) => ({
  professional_phone_number: profile ? profile?.professional_phone_number : '',
  phone_number: profile ? profile?.phone_number : '',
  address: profile ? profile?.address : '',
  linkedin_link: profile ? profile?.linkedin_link : '',
  job_role: profile ? profile?.job_role : '',
  employer: profile ? profile?.employer : '',
  professional_email: profile ? profile?.professional_email : '',
})