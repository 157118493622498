import { Box, Grid, Typography } from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../utils/hooks";
import { EventTypeCard } from "./eventTypeCard";
import {desktopStyles, mobileStyles} from "./styles/step1Styles"
import { DATE_FORMAT } from "../../../utils/dateUtils";

export const MeetStep1 = ({isMobile, onSelect, userId})=>{
  const styles = isMobile? mobileStyles : desktopStyles
  const api = useApi()
  const [Events, setEvents] = useState([])

  useEffect(() => {
    getEvents()
  },[])


  const getEvents  = ()=>{
    api.getEventTypes(1, {all:true, user_id:userId}).then(response => {
      if (response.kind === "ok") {
        setEvents(response.data)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }


  return (
    <Box>
      <Typography variant={"h1"} style={styles.title}>
        1. SELECT MEETING TYPE
      </Typography>
      <Box style={styles.listContainer}>
          {Events.map((event, index)=>(
              <EventTypeCard
                isMobile
                key={"eventTypeCard"+index}
                onSelect={()=>onSelect(event)}
                eventType={event}
              />
          ))}


      </Box>
    </Box>

  )
}
