import React, {useEffect, useState} from "react";
import {notifyError} from "../../../services/notification";
import {useApi} from "../../../utils/hooks";
import {PopupMessageModal} from "../PopupMessageModal";

export const MessagePopups = ({
                                          messagesProps,
                                          profile,

                                        }) => {

  const [messages, setMessages] = useState(messagesProps);
  const [showMessageModal, setShowMessageModal] = useState(true);
  const api = useApi();

  useEffect(() => {
    if (messagesProps && messagesProps.length > 0) {
      setShowMessageModal(true)
    } else {
      setShowMessageModal(false)
    }
    setMessages(messagesProps)
  }, [messagesProps])

  const setMessageAsSeen = (messageId) => {
    api.setMessageSeen(messageId).then(response => {
      if (response.kind === "ok") {
        setMessages(messages.filter(message => message.id !== messageId))
        if (messages.length > 1) {
          setShowMessageModal(true)
        } else {
          setShowMessageModal(false)
        }
      } else {
        notifyError('There is something wrong!')
      }
    }).catch(reason => {
      notifyError('There is something wrong!')
    })
  }

  return (

    messages.map((message, index) => (
      <PopupMessageModal
        key={index} // Added key prop to prevent warnings/errors
        messageType={message?.message_type}
        open={showMessageModal}
        onClose={() => setShowMessageModal(false)}
        onConfirm={() => {
          setMessageAsSeen(message?.id);
        }}
        avatar={profile?.image}
        idolizerName={profile?.username}
        idolHost={message?.idol_host}
      />
    ))
  )
}