import {secondaryButtonStyle, secondaryButtonTextStyle} from "./styles";
import MDTypography from "../MDTypography";
import {Button} from "@mui/material";


export const ThinButtonTransparent = ({text, size, onClick, textStyles, buttonStyle, color, fontWeight,  ...rest})=>{
  return (
    <Button
      onClick={onClick}
      size={size}
      variant="outlined"
      sx={[(theme)=>secondaryButtonStyle(theme, size, buttonStyle, color)]}
      {...rest}
    >
      <MDTypography  sx={[(theme)=>secondaryButtonTextStyle(theme, size, fontWeight, color), {...textStyles}]}>
        {text}
      </MDTypography>
    </Button>
  )
}
