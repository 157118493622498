import {OnboardingLayout} from "components/OnboardingLayout";
import MDBox from "components/MDBox";
import {PrimaryButton} from "components/PrimaryButton";
import {styles} from "./styles";
import {useOnboardingStore} from "../../../utils/hooks";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {CustomFormikDateField, CustomFormikTextField} from "../../../components/TextField";
import {CustomAutocompleteFormik} from "../../../components/AutocompleteFormik";
import {
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
  useStores
} from "../../../models";
import {useNavigate} from "react-router-dom";
import {Grid, Stack} from "@mui/material";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {GuestPaths} from "../../../paths/guestPaths";
import moment from "moment-timezone";
import {notifyError} from "../../../services/notification";
import { parsePhoneNumber } from 'libphonenumber-js';
import CustomSelect from "../../../components/CustomSelect";

const subtitle =
  "We'll need some business information from you to start setting up how you receive payment.\n" +
  "We take data security seriously and use the latest encryption technologies to protect your information.";

export const IdolIncorporated = () => {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const api = loginStore.environment.api;
  const onboardingStore = useOnboardingStore();
  const [Countries, setCountries] = useState([]);
  const [Regions, setRegions] = useState([]);
  const [Cities, setCities] = useState([]);
  const navigate = useNavigate();
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolIncorporated)

  const calculateAge = (date) => {
    var years = moment().diff(date, 'years', false);
    return years;
  }

  const submitValues = (values) => {
    if (!preCheckSomeFields()) {
      return
    }
    onboardingStore.setData({...values})
    navigate(NextPage);
  }

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(OnboardingPhases.Incorporated, onboardingStore),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Incorporated, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  const preCheckSomeFields = () => {
    if (!moment(formik.values.inc_date_of_birth, "MM/DD/YYYY", true).isValid()) {
      notifyError("Please check the date format on Date of birth")
      return false
    }
    const age = calculateAge(formik.values.inc_date_of_birth);
    if (age < 18) {
      notifyError("You must be at least 18 years old");
      return false;
    }
    try {
      const phoneNumber = parsePhoneNumber(formik.values.inc_phone_number)
      if (!phoneNumber.isValid()) {
        return false
      }
    } catch (e) {
      const tmp = "+1" + formik.values.inc_phone_number
      try {
        if (!parsePhoneNumber(tmp).isValid()) {
          notifyError("Please check the phone number format")
          return false
        }
      } catch (ez) {
        notifyError("Please check the phone number format")
        return false
      }
      formik.setFieldValue('inc_phone_number', tmp)
    }
    return true
  }

  const getCountries = (event, search = "") => {
    api
      .getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {
          setCountries(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRegions = (country, search = "") => {
    if (!country) {
      setCities([])
      return;
    }
    api
      .getRegions(search, country?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setRegions(res.data.results);
          setCities([])
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (region, search = "") => {
    if (!region) {
      setCities([])
      return;
    }
    api
      .getCities(search, region?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setCities(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const incOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage);
    getCountries();
  }, []);

  return (
    <OnboardingLayout
      titleBold="IDOL"
      titleNormal="INC"
      progressBarPercent={"40%"}
      backTo={PreviousPage}
      subtitle={subtitle}
    >
      <MDBox sx={styles.inputContainer} autocomplete={"off"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="inc_first_name" formik={formik}/>
            <CustomFormikTextField name="inc_middle_name" formik={formik}/>
            <CustomFormikTextField name="inc_last_name" formik={formik}/>
            <CustomFormikDateField name="inc_date_of_birth" formik={formik}/>
            <CustomFormikTextField name="inc_address_line_1" formik={formik}/>
            <CustomFormikTextField name="inc_address_line_2" formik={formik}/>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={12} lg={6}>
                <CustomAutocompleteFormik
                  name={'inc_country'}
                  formik={formik}
                  options={Countries}
                  onChange={(e, v) => {
                    formik.setFieldValue("inc_country", v)
                    getRegions(v)
                    formik.setFieldValue("inc_state", null)
                    formik.setFieldValue("inc_city", null)
                  }}
                  onClear={() => {
                    setRegions([])
                    setCities([])
                    formik.setFieldValue("inc_state", null)
                    formik.setFieldValue("inc_city", null)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12} lg={6}>
                <CustomAutocompleteFormik
                  name={'inc_state'}
                  formik={formik}
                  options={Regions}
                  onChange={(e, v) => {
                    formik.setFieldValue("inc_state", v)
                    getCities(v)
                    formik.setFieldValue("inc_city", null)
                  }}
                  onClear={() => {
                    getRegions(formik.values.inc_country)
                    formik.setFieldValue("inc_city", null)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <CustomAutocompleteFormik
                  name={'inc_city'}
                  formik={formik}
                  options={Cities}
                  onClear={() => {
                    getCities(formik.values.inc_state)
                    formik.setFieldValue("inc_city", null)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <CustomFormikTextField name="inc_zip_code" formik={formik}/>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <CustomFormikTextField name="inc_phone_number" formik={formik}/>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <CustomSelect
                  label="Are you incorporated?"
                  options={incOptions}
                  name="inc_incorporated"
                  formik={formik}
                  onChange={(e) => {
                    formik.setFieldValue("inc_incorporated", e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
          <MDBox mb={4} mt={4}>
            <PrimaryButton text={"NEXT"} type={"submit"} onClick={preCheckSomeFields}/>
          </MDBox>
        </form>
      </MDBox>
    </OnboardingLayout>
  )
};
