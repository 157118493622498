import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Typography, Box} from '@mui/material';

/* components */
import {BaseIdolCard} from 'base/Cards/IdolCard';
import {CardEventActionsBox} from 'components/BaseCardEventActionsBox';

/* assets */
import videocallIcon from 'assets/icons/video-chat.svg';
import meetIcon from 'assets/icons/meet.svg';
import dollar from "assets/icons/dollar.svg";
import {styles} from './styles';


export const EventCard = ({
  title,
  eventDuration,
  eventType,
  meetPlace,
  eventDescription,
  eventPrice,
  onEdit,
  onDelete,
}) => {
  const baseProps = {
    title,
    icon: eventType === 'idolcall'?videocallIcon:meetIcon,
    cardStyles: styles.baseCard,
    headerStyles: styles.headerCard,
  };

  const actionPropsMeet = {
    approveText: 'Set Location',
    dismissText: 'Delete',
    approveAction: onEdit,
    dismissAction: onDelete,
    containerStyles: styles.actions_container,
  };

  const actionPropsCall = {
    dismissText: 'Delete',
    dismissAction: onDelete,
    containerStyles: styles.actions_container,
  };

  const formattedPrice = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(eventPrice);

  return (
    <BaseIdolCard {...baseProps}>
      <Box sx={styles.container}>
        <Box sx={styles.event_date_info_container}>
          <Typography sx={styles.duration}>{eventDuration} Event</Typography>
          {eventType === "idolcall" && <Typography sx={styles.event_type}>{eventType}</Typography>}
          {eventType === "idolmeet" && (
            <Box sx={styles.event_meet_container}>
              <Typography sx={[styles.event_type, styles.event_type_meet]}>{meetPlace}</Typography>
              <Typography sx={[styles.event_type, styles.event_type_meet]}>{eventType}</Typography>
            </Box>
          )}
        </Box>
        <Typography sx={styles.description}>{eventDescription}</Typography>
        <Box sx={styles.price_container}>
          <img
            src={dollar}
            alt='icon dollar'
            title={`$ icon for ${title} event`}
          />
          <Typography sx={styles.price}>{formattedPrice}</Typography>
        </Box>
        <CardEventActionsBox {...(eventType === 'idolcall' ? actionPropsCall : actionPropsMeet)} showActionButton={eventType === 'idolmeet'}/>
      </Box>
    </BaseIdolCard>
  );
};

EventCard.propTypes = {
  eventDuration: PropTypes.string.isRequired,
  eventType: PropTypes.oneOf(['idocall', 'idolmeet']).isRequired,
  eventDescription: PropTypes.string.isRequired,
  eventPrice: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  meetPlace: PropTypes.string,
};
