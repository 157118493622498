/* dependencies */
import {useState} from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography, Divider} from '@mui/material';

/* components */
import {BasePostCard} from 'base/Cards/PostCard';
import {GetBalanceSection} from './Balance';
import {SavedCard} from './SavedCard';

/* assets */
import wallet from 'assets/icons/wallet-idol-bank.svg';
import {styles} from './styles';
import AddCreditCard from 'components/AddCreditCard/AddCreditCard';

export const ChargeWallet = ({buttonTitle, currentBalance, lifetimeBalance, buyActionHandler,
                               refreshList, addMoney, cardSelected, setCardSelected,
                               userCards, deleteCard, title, inputAmount, changeInputAmount, isBuy}) => {



  return (
    <BasePostCard title={title} icon={wallet}>
      <Box sx={styles.card_container}>
        <GetBalanceSection
          buttonTitle={buttonTitle}
          currentBalance={currentBalance}
          lifetimeBalance={lifetimeBalance}
          buyActionHandler={buyActionHandler}
          cardSelected={cardSelected}
          inputAmount={inputAmount}
          isBuy={isBuy}
          changeInputAmount={changeInputAmount}
        />
        <Box sx={styles.saved_cards_section}>
          <Typography sx={styles.saved_cards_title}>Saved Cards</Typography>
          <Divider sx={styles.divider} />
          {userCards.map((card) => (
            <>
              <SavedCard
                key={card.id}
                card={card}
                cardSelected={cardSelected}
                addMoney={addMoney}
                setCardSelected={setCardSelected}
                deleteCard={deleteCard}
              />
              <Divider sx={styles.divider} />
            </>
          ))}
          <AddCreditCard refreshList={refreshList}/>
        </Box>
      </Box>
    </BasePostCard>
  );
}

ChargeWallet.propTypes = {
  userCards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    user: PropTypes.number.isRequired,
    card_type: PropTypes.string.isRequired,
    card_number: PropTypes.string.isRequired,
    holders_name: PropTypes.string.isRequired,
    expiry_year: PropTypes.string.isRequired,
    expiry_month: PropTypes.string.isRequired,
    cvv: PropTypes.string,
  })),
  deleteCard: PropTypes.func.isRequired,
};
