import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors'
const {white, red, card} = colors;

export const styles = {
    baseCard: {
        background: card.meeting,
        height: '100%',
        paddingTop: 1,
        paddingBottom: 2,
    },
    idolInfo: {
        marginTop: 8.5,
        display: 'flex',
        justifyContent: 'space-between'

    },
    name: {
        fontSize: pxToRem(22),
    },
    points: {
        fontSize: '1.2rem',
    },
    header: {
        paddingBlock: `${pxToRem(31)} ${pxToRem(20)}`,
        paddingLeft: pxToRem(22)
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        paddingLeft: '12%',
        paddingTop: '2%',
    },
    general_info: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between',
    },
    datetime_info_container: {
        alignItems: "center",
        display: "flex",
        gap: 1,
    },
    datetime_info_text: {
        color: white.main,
        fontWeight: 'light',
        fontSize: pxToRem(14),
        lineHeight: 1.3,

    },
    timezoneContainer: {
        alignItems: 'center',
    },
    meetingType: {
        color: red.main,
        paddingRight: '4%',
        fontWeight: 'bold',
        fontSize: '0.9rem',
    },
    idolcallText: {
        color: red.main,
        paddingTop: '15px',
        paddingRight: '4%',
        fontWeight: 'bold',
        fontSize: '0.9rem',
    },
    actions_container: {
        margin: `${pxToRem(17)} 0 0 ${pxToRem(21)}`,
    },
};
