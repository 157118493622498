import HomeIcon from "../assets/icons/home-icon.svg";
import IdolBankIcon from "../assets/icons/wallet-idol-bank.svg";
import IdolPayIcon from "../assets/icons/idol-pay.svg";
import EventsIcon from "../assets/icons/events.svg";
import CalendarIcon from "../assets/icons/calendar1.svg";
import PricesIcon from "../assets/icons/price.svg";
import PeopleIcon from "../assets/icons/people.svg";
import BellRedIcon from "../assets/icons/bell_with_point.svg";
import GearIcon from "../assets/icons/gear_six.svg";
import GetVerifiedIcon from "../assets/icons/CheckCircle.svg";
import LinkIcon from "../assets/icons/Link.svg";
import ProhibitIcon from "../assets/icons/Prohibit.svg";
import ClipboardIcon from "../assets/icons/ClipboardText.svg";
import TrashIcon from "../assets/icons/trash.svg";
import LogOutIcon from "../assets/icons/sign_out.svg";
import ExploreIcon from "../assets/icons/compass.svg";
import DashboardIcon from "../assets/icons/chart_pie_slice.svg";
import NotificationsIcon from "../assets/icons/bell.svg";
import colors from "../assets/theme/base/colors";


export const idolInfluencerMobileMenu = {
  main: {
    d00: {type: "divider",},
    explore: {
      name: 'Explore',
      icon: ExploreIcon,
      type: 'collapse',
    },
    dashboard: {
      noCollapse: true,
      name: 'Dashboard',
      icon: DashboardIcon,
      collapse: {
        home: {
          name: 'Home',
          icon: HomeIcon,
          type: 'collapse',
        },
        wallet: {
          name: 'Wallet',
          icon: IdolBankIcon,
          type: 'collapse',
        },
        transactions: {
          name: 'Transactions',
          icon: IdolPayIcon,
          type: 'collapse',
        },
        meetings: {
          name: 'Meetings',
          icon: CalendarIcon,
          type: 'collapse',
        },
        idolizers: {
          name: 'Idolizers',
          icon: PeopleIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    notifications: {
      name: 'Notifications',
      icon: NotificationsIcon,
      type: 'collapse',
    },
    settings: {
      noCollapse: true,
      name: 'Settings',
      icon: GearIcon,
      collapse: {
        getVerified: {
          name: 'Get Verified',
          icon: GetVerifiedIcon,
          type: 'collapse',
        },
        referralLink: {
          name: 'Referral Link',
          icon: LinkIcon,
          type: 'collapse',
        },
        blockedUsers: {
          name: 'Blocked Users',
          icon: ProhibitIcon,
          type: 'collapse',
        },
        termsAndConditions: {
          name: 'Terms & Conditions',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        privacyPolicy: {
          name: 'Privacy Policy',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        deactivateAccount: {
          name: 'Deactivate Account',
          icon: TrashIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    logout: {
      name: 'Logout',
      icon: LogOutIcon,
      type: 'collapse',
      color: colors.primary.main
    },
  }
}


export const idolMobileMenu = {
  main: {
    d00: {type: "divider",},
    explore: {
      name: 'Explore',
      icon: ExploreIcon,
      type: 'collapse',
    },
    dashboard: {
      noCollapse: true,
      name: 'Dashboard',
      icon: DashboardIcon,
      collapse: {
        home: {
          name: 'Home',
          icon: HomeIcon,
          type: 'collapse',
        },
        idolBank: {
          name: 'Idol Bank',
          icon: IdolBankIcon,
          type: 'collapse',
        },
        idolPay: {
          name: 'Idol Pay',
          icon: IdolPayIcon,
          type: 'collapse',
        },
        events: {
          name: 'Events',
          icon: EventsIcon,
          type: 'collapse',
        },
        calendar: {
          name: 'Calendar',
          icon: CalendarIcon,
          type: 'collapse',
        },
        prices: {
          name: 'Prices',
          icon: PricesIcon,
          type: 'collapse',
        },
        idolizers: {
          name: 'Idolizers',
          icon: PeopleIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    notifications: {
      name: 'Notifications',
      icon: NotificationsIcon,
      type: 'collapse',
    },
    settings: {
      noCollapse: true,
      name: 'Settings',
      icon: GearIcon,
      collapse: {
        getVerified: {
          name: 'Get Verified',
          icon: GetVerifiedIcon,
          type: 'collapse',
        },
        referralLink: {
          name: 'Referral Link',
          icon: LinkIcon,
          type: 'collapse',
        },
        blockedUsers: {
          name: 'Blocked Users',
          icon: ProhibitIcon,
          type: 'collapse',
        },
        termsAndConditions: {
          name: 'Terms & Conditions',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        privacyPolicy: {
          name: 'Privacy Policy',
          icon: ClipboardIcon,
          type: 'collapse',
        },
        deactivateAccount: {
          name: 'Deactivate Account',
          icon: TrashIcon,
          type: 'collapse',
        },
      },
      type: 'collapse',
    },
    logout: {
      name: 'Logout',
      icon: LogOutIcon,
      type: 'collapse',
      color: colors.primary.main
    },
  }
}
