import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {useApi, useIsMobile, useUserStore} from "utils/hooks";
import {MobileSummary} from "../mobile/mobileSummary";
import {Box} from "@mui/material";
import {MobileSectionHeader} from "../../../../components/Mobile/MobileIdolDashboardSectionHeader";
import EventsIcon from "../../../../assets/icons/events.svg";
import MobileMeetings from "../mobile/mobileMeetings";
import {MobileIdolsCard} from "../mobile/idolizers/mobileIdolsCard";
import {initialCountersIdolizer, initialSummary} from "../reducers";
import {notifyError} from "../../../../services/notification";
import {useStores} from "../../../../models";
import {IdolizerDashboard} from "./idolizerDashboard";
import {MessagePopups} from "../../../../components/MessageModal/MessagePopups";



export const DashboardIdolizerPage = observer((props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore
  const userStore = useUserStore()
  const api = useApi()
  const isMobile = useIsMobile();
  const [summary, setSummary] = useState(initialSummary)
  const [FilterCounter, setFilterCounter] = useState(initialCountersIdolizer)
  const [profile, setProfile] = useState(null)
  const [messages, setMessages] = useState([]);

  const getProfileDetail = () => {
    api.getProfileDetail(userStore.id).then(response => {
      if (response.kind === "ok") {
        userStore.setUser(response.data)
        setProfile(response.data)
        setSummary({
          idolPoints: userStore.idol_points,
          idolPay: userStore.current_balance,
          idolizers: userStore.number_of_subscribers
        })
        setFilterCounter({
          all: response.data.number_of_subscribers + response.data.number_of_followers,
          idolizers: response.data.number_of_subscribers,
          followers: response.data.number_of_followers
        })
        setMessages(response.data.messages)
      } else {
        notifyError('There is something wrong!')
      }
    }).catch(reason => {
      notifyError('There is something wrong!')
    })
  }

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute('DASHBOARD')
    getProfileDetail()
  }, [])


  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {isMobile ? (
          <Box>
            <MobileSummary data={summary}/>
            <Box sx={{marginTop: "28px"}}>
              <MobileSectionHeader Icon={EventsIcon} Title={"Meetings"}/>
              <Box sx={{overflowX: "auto", whiteSpace: "nowrap"}}>
                <MobileMeetings isWide={false} isIdolizer={true} hasPendingMeetings={true}/>
              </Box>
              <Box>
                <MobileIdolsCard isIdolizer={true} filterCounter={FilterCounter}/>
              </Box>
            </Box>
          </Box>
        ) : (
          profile &&
                  <IdolizerDashboard profile={profile} filterCounter={FilterCounter}/>
        )}
      </DashboardLayout>
      <MessagePopups messagesProps={messages} profile={profile}/>
    </BaseProtectedComponentPage>
  );
})

