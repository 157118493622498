import {Box, Grid, Typography} from "@mui/material";
import UploadDocument from "../../components/UploadDocument";
import {CustomTextField} from "../../components/TextField";
import {SecondaryButton} from "../../components/SecondaryButton";
import {useApi, useIsMobile} from "../../utils/hooks";
import {PrimaryButton} from "../../components/PrimaryButton";
import uploadIcon from "../../assets/icons/uploadCI.svg";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {notifySuccess} from "../../services/notification";
import {useStores} from "../../models";
const initialValues = {
  document: '',
  professionalPhoneNumber: '',
  phoneNumber: '',
  address: '',
  linkedinLink: '',
  jobRole: '',
  employer: '',
  professionalEmail: '',

}
export const IdolizerVerification = () => {
  const api = useApi()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      verificate(values)
    },
  });
  const isMobile = useIsMobile();
  const [errorsa, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('wallet');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userCards, setUserCards] = useState([]);
  const [userSelectedCard, setSelectedCard] = useState(null);
  const [Params, setParams] = useState({})
  const location = useLocation();
  const [profile, setProfile] = useState(null);
  const [balance, setBalance] = useState("");
  const [idFront, setIdFront] = useState({ id_front: null });
  const [formData, setFormData] = useState({})

  useEffect(() => {
    getProfile();
    getUserCards();
    getBalance();
  }, []);



  useEffect(() => {
    setParams({
      // eventTypeId: location.state.eventTypeId,
      fee: 0,
      // date: location.state.date,
      userId: loginStore.userId,
      // time: location.state.time
    })
  }, []);



  const onSubscribe = () => {
    const data = {
      // id: profile?.id,
      amount: Params.fee,
      user_id: Params.userId,
      payment_method: paymentMethod,
      selected_card: userSelectedCard,
      // event_type:  Params.eventTypeId,
      ...formData
    }




    console.log(' onSubscribe meet verification ===>>> ', data)

    setIsLoading(true);
    api.meetingPayment(data, ['id_front'])
      .then((result) => {
        if (result.kind === "ok") {
          // createMeeting(data.response.transaction_id)

          // history.replace(`/profile/${Params.userId}`, { meeting: result.response.meet })


          notifySuccess('Successful Payment')

          setShowPaymentModal(false);
        } else {
          Notification.Error()
        }
      })
      .catch((error) => {
        Notification.Error()
      }).finally(() => setIsLoading(false))

  };





  const getUserCards = () => {
    setIsLoading(true)
    loginStore.environment.api.getUserCards().then((result) => {
      if (result.kind === "ok") {
        setIsLoading(false)
        const cards = result.data.results
        cards.map(card => {
          if (card.default) {
            setSelectedCard(card.id)
          }
        })
        setUserCards(cards)
      } else {
        setIsLoading(false)
        Notification.Error()
      }
    }).catch(() => {
      setIsLoading(false)
      Notification.Error()
    });
  };
  const getProfile = () => {
    loginStore.environment.api.getProfile(Params.userId)
      .then(({ data }) => {
        setProfile(data);
      })
      .catch((error) => {
        // setErrors(error.response.data);
        // setDisableFields(false);
      });
  };

  const getBalance = () => {
    loginStore.environment.api.getBalance().then((result) => {
      if (result.kind === "ok") {
        setBalance(result.data.amount)
      }
    }).catch(() => {
      Notification.Error()
    });
  };




  const verificate = (values) => {
    console.log("verificate", idFront)
    const data = {
      professional_phone_number: values.professionalPhoneNumber,
      phone_number: values.phoneNumber,
      address: values.address,
      location: values.location,
      linkedin: values.linkedinLink,
      id_front: idFront.id_front,
      job_role: values.jobRole,
      employer: values.employer,
      professional_email: values.professionalEmail,
      date: Params.date.toISOString(),
      event_type: "asd",
      // start_time: Params.time.start_time,
      // end_time: Params.time.end_time,
    }
    setFormData(data)
    setShowPaymentModal(true)
  }
  return (
    <Box component={"form"} onSubmit={formik.handleSubmit}>
      <Typography sx={styles.title}>MEETING VERIFICATION</Typography>
      {!isMobile && <Typography sx={styles.subtitle}>Natoque at accumsan velit fames metus in. Nulla iaculis sed ut facilisis elit sed.</Typography>}

      <Box sx={{ width: {xs: "100%", lg: "50%", padding: "0px 10px 0px 0px" }}}>
        <UploadDocument text={"Click here to drop files or upload your  ID "} src={uploadIcon} onClick={(e) => setIdFront(e)} />
      </Box>

      <Grid container spacing={2} >
        <Grid item xs={12} lg={6} >
          <CustomTextField name={"professionalPhoneNumber"} onChange={formik.handleChange} inputLabel={"Professional Phone Number"} formStyles={styles.formStyles} />
          <CustomTextField name={"phoneNumber"} onChange={formik.handleChange} inputLabel={"Phone Number"} formStyles={styles.formStyles} />
          <CustomTextField name={"address"} onChange={formik.handleChange} inputLabel={"Address"} formStyles={styles.formStyles} />
          <CustomTextField name={"linkedinLink"} onChange={formik.handleChange} inputLabel={"Linkedin link"} formStyles={styles.formStyles} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField name={"jobRole"} onChange={formik.handleChange} inputLabel={"Job Role"} formStyles={styles.formStyles} />
          <CustomTextField name={"employer"} onChange={formik.handleChange} inputLabel={"Employer"} formStyles={styles.formStyles} />
          <CustomTextField name={"professionalEmail"} onChange={formik.handleChange} inputLabel={"Professional Email"} formStyles={styles.formStyles} />
          <Box sx={styles.buttonsContainer}>
            <Typography sx={styles.redNotice}>Until the admin confirms your information your meeting will be pending.</Typography>
            {isMobile ?
              <PrimaryButton type={"submit"} text={"SUBMIT"} buttonStyle={styles.mobileButtonStyle} textStyles={styles.textStyles} />
              :
              <SecondaryButton type={"submit"} text={"SUBMIT"} buttonStyle={styles.buttonStyle} textStyles={styles.textStyles} />
            }
          </Box>

        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  container: {
    width: "100%"
  },
  title: {
    textAlign: {xs: "center", lg: "left" },
    fontSize: {xs: "16px", lg: "48px"},
    fontWeight: {xs: 400, lg: 300},
    lineHeight: {xs: "22px", lg: "56px"},
    paddingBottom: {xs: "34px", lg: "0px"},
    color: "#fff",
  },
  subtitle: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21px",
    color: "#fff",
    margin: "12px 0px 40px 0px"
  },
  formStyles: {
    marginTop: "26px"
  },
  redNotice: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "17px",
    color: "#D9243D",
    marginTop: "17px"
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: { xs: "center", lg: "flex-end" }
  },
  buttonStyle: {
    width: "193px",
    height: "40px",
    marginTop: "20px"
  },
  mobileButtonStyle: {
    width: "80%",
    height: "42px",
    marginTop: "20px"
  },
  textStyles: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "13px",
    letterSpacing: "0.1em"
  }
}
