import * as React from "react";
import { useEffect, useState } from "react";
import { setLayout, useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { OnboardingLayout } from "../../../components/OnboardingLayout";
import { useOnboardingStore } from "../../../utils/hooks";
import { IdolTypeCard } from "../../../components/IdolTypeCard";
import InfluencerIcon from "../../../assets/icons/influencer.svg";
import ProfessionalIcon from "../../../assets/icons/professional.svg";
import EntrepeneurIcon from "../../../assets/icons/entrepreneur.svg";
import { Box, useMediaQuery} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MDBox from "../../../components/MDBox";
import { PrimaryButton } from "../../../components/PrimaryButton";
import Grid from "@mui/material/Grid";
import { IdolTypeSelectCarousel } from "./carousel/IdolTypeSelectCarousel";
import { IdolTypes, LayoutNames } from "../../../constants";
import { getIdolOnboardingNavigationPages } from "../../../models";
import { GuestPaths } from "../../../paths/guestPaths";


export const SelectIdolType = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {pathname} = useLocation();
  const [IdolType, setIdolType] = useState()
  const onboardingStore = useOnboardingStore()
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(null, GuestPaths.selectIdolType)

  const goToNext = () => {
    onboardingStore.setData({idol_type: IdolType})
    navigate(NextPage)
  }

  useEffect(() => {
    setLayout(dispatch, LayoutNames.onboarding);
  }, [pathname]);

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage)
  }, [])

  return (
    <OnboardingLayout
      titleBold={"IDOL"}
      titleNormal={"SELECT"}
      ml={6}
      backTo={PreviousPage}
      subtitleStyle={{width: isMobile ? "100%" : 380}}
      subtitle={"Whether you're an influencer, professional or entrepreneur, we provide the tools you\n" +
        "need to succeed. Our platform is designed specifically for the modern idol."}>
      {isMobile ?
        <Box>
          <Box mt={"25px"}>
            <IdolTypeSelectCarousel
              onSelect={(idolType) => {
                setIdolType(idolType)
              }}
              height="500px"
              offset={2}
              showArrows={false}
            />
          </Box>
          <Box mt={5} mb={4} style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <PrimaryButton style={{width: "350px"}} disabled={!IdolType} text={"CONTINUE"} onClick={() => goToNext()}/>
          </Box>
        </Box>
        :
        <Box mt={4} sx={!isMobile ? {width: "62%", left: "34%", position: "absolute", maxHeight: 490, overflow: "auto"} : {textAlign: "center"}}>
          <Grid container spacing={4} justifyContent={{xs: 'center', md: 'center'}}>
            <Grid item sm={10} md={8} lg={6} xl={4} >
              <IdolTypeCard
                type={"Influencer"}
                onClick={() => setIdolType(IdolTypes.Influencer)}
                selected={IdolType === IdolTypes.Influencer}
                description={"As an idol influencer you can explore the platform and you can’t monetize your content."}
                accessText={"limited access"}
                icon={InfluencerIcon} />
            </Grid>
            <Grid item sm={10} md={8} lg={6} xl={4}>
              <IdolTypeCard
                type={"Professional"}
                onClick={() => setIdolType(IdolTypes.Professional)}
                selected={IdolType === IdolTypes.Professional}
                description={"As an idol professional you can monetize your content, host video calls, earn " +
                  "tips and establish idolizer connections."}
                accessText={"verified access"}
                icon={ProfessionalIcon}/>
            </Grid>
            <Grid item sm={10} md={8} lg={6} xl={4}>
              <IdolTypeCard
                type={"Entrepreneur"}
                onClick={() => setIdolType(IdolTypes.Entrepreneur)}
                selected={IdolType === IdolTypes.Entrepreneur}
                description={"As an idol entrepreneur you can have access to the full idolidol experience and " +
                  "meet your idolizers, securely with verification."}
                accessText={"full access"}
                icon={EntrepeneurIcon}/>
            </Grid>
          </Grid>
          <MDBox mt={5} mb={2} style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <PrimaryButton style={{width: "350px"}} disabled={!IdolType} text={"CONTINUE"} onClick={() => goToNext()}/>
          </MDBox>
        </Box>
      }
    </OnboardingLayout>
  )
}
