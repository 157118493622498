import pxToRem from "../../assets/theme/functions/pxToRem";

export const getCardHeaderStyles = (theme) => {
  return {
    background: theme.palette.transparent.main,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginY: pxToRem(16),
    gap: pxToRem(26)
  }
}

export const getButtonStyles = (theme) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    background: 'rgba(12, 25, 43, 0.84)',
    borderRadius: pxToRem(10),
    paddingY: pxToRem(21),
    letterSpacing: '0.1em',
    width: '100%'
  }
}

export const getNumberStyles = (theme, selected) => {
  return {
    color: selected ? theme.palette.buttons.filterButton.selected : theme.palette.buttons.filterButton.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: pxToRem(45),
    letterSpacing: '0.1em',
    lineHeight: '1'
  }
}

export const getLabelStyles = (theme, selected) => {
  return {
    color: selected ? theme.palette.buttons.filterButton.selected : theme.palette.buttons.filterButton.main,
    fontWeight: theme.typography.fontWeightRegularMedium,
    fontSize: theme.typography.fontSizeMD,
    letterSpacing: '0.1em',
    alignSelf: 'end',
  }
}


export const getScrollStyles = {
  padding:"0px 25px 0px 10px",
  margin:"24px -10px 0px -10px",
  maxHeight:"80vh",
  minHeight:"300px"
}
