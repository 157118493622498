import React, {useState, useEffect} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {CircularProgress} from "@mui/material";

// Base Components
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {BasePostCard} from "base/Cards/PostCard";

// Components
import {CustomTextField} from "components/TextField";
import {PrimaryButton} from "components/PrimaryButton";
import {ReservedNameCard} from "./reservedNameCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {useApi, useInfiniteScroll} from "utils/hooks";
import {showMessage} from "services/helpers";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import {notifyError, notifySuccess} from "../../../services/notification";

export const AdminReservedNamesPage = () => {
  const api = useApi();
  const [reservedNames, setReservedNames] = useState([]);
  const [Name, setName] = useState("")
  const [loading, setLoading] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [reservedNameSelectedToDelete, setReservedNameSelectedToDelete] = useState();

  const fetchReservedNames = () => {
    setLoading(true)
    api.getReservedName().then((result) => {
      if (result.kind === "ok") {
        setReservedNames(result.data.results)
      }
      setLoading(false)
    }).catch(() => {
      notifyError();
      setLoading(false)
    });
  }
  // const {items, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage,} = useInfiniteScroll({apiCall: getNames, queryKey: "names"});

  const reserve = ()=>{
    api.addReservedName({name: Name}).then((result) => {
      if (result.kind === "ok") {
        fetchReservedNames()
        setName('')
        notifySuccess('Reserved name added successfully')
      } else if (result.kind === "bad-data") {
        if (result.errors.name) {
          notifyError(result.errors.name);
        }
      } else {
        notifyError();
      }
    }).catch(() => {
      notifyError();
    });
  }

  const deleteReservedName = () => {
    api.deleteReservedName(reservedNameSelectedToDelete).then((result) => {
      if (result.kind === "ok") {
        fetchReservedNames()
        notifySuccess('Reserved name removed successfully')
      } else {
        notifyError();
      }
    }).catch(() => {
      notifyError();
    });
    setShowConfirmDeleteModal(false)
  }

  const confirmDelete = () => {
    deleteReservedName()
  }

  const showDeleteModal = (name) => {
    setReservedNameSelectedToDelete(name.id)
    setShowConfirmDeleteModal(true)
  }

  useEffect(() => {
    fetchReservedNames();
  }, [])

  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox my={pxToRem(34)} mx={pxToRem(102)}>
        <BasePostCard title='RESERVED NAMES' titleStyles={{fontSize: 48}}>
          <ConfirmPaymentModal
            open={showConfirmDeleteModal}
            message={`Are you sure you want to delete this name?`}
            confirmAction={confirmDelete}
            confirmLabel={"CONFIRM"}
            cancelAction={() => setShowConfirmDeleteModal(false)}
          />
          <MDBox display='flex' alignItems='center' gap={pxToRem(20)} mt={pxToRem(14)}>
            <CustomTextField value={Name} onChange={(event) => setName(event.target.value)}  placeholder='Reserve Name' formStyles={styles.form} />
            <PrimaryButton text='RESERVE' onClick={reserve} disabled={Name === ""} height={pxToRem(32)} textStyles={styles.buttonActionText} width={pxToRem(132)} />
          </MDBox>
          {loading
            ? <MDBox mt={pxToRem(30)} width='100%' display='flex' justifyContent='center'><CircularProgress/></MDBox>
            : (
              <MDBox display='flex' flexDirection='column' gap={pxToRem(14)} mt={pxToRem(24)}>
                {reservedNames.length === 0
                  ? <MDTypography color='light' variant='h6'>No data found</MDTypography>
                  : reservedNames.map(reservedName => (
                    <ReservedNameCard key={reservedName?.id} reservedName={reservedName} handleRemove={showDeleteModal} />
                  ))}
              </MDBox>
            )
          }
        </BasePostCard>
      </MDBox>
    </DashboardLayout>
  )
}

const styles = {
  form: {
    width: pxToRem(402)
  },
  buttonActionText: {
    fontSize: 10,
    fontWeight: 700,
  }
}
