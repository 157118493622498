import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
const {white} = colors;

export const styles = {
    form_container: {
        display:"flex",
        flexDirection:"column",
        gap: 25,
    },
    flex_section: {
        alignItems:"flex-start",
        display:"flex",
        gap: 5,
        justifyContent: 'space-around',
    },
    dateRangeTitle: {
        color: white.main,
        fontSize:16,
        marginBottom: '-1rem'
    },
    date_range_section: {
        display:"flex",
        flexDirection:"column",
    },
    date_range_grid_first_item: {
        alignItems:"center",
        display:"grid",
        gap: 2,
        gridTemplateColumns:"0.3fr 0.5fr 2fr",
    },
    date_range_grid_first_item_content: {
        alignItems:"end",
        display:"flex",
        justifyContent:"start",
        width:"100%",
    },
    date_range_grid_third_item: {
        alignItems: "center",
        display:"flex",
        position:"relative",
        gap: 5,
    },
    logo: {
        bottom: 20,
        position: 'absolute',
        right: 0,
        width: '5rem',
    },
    actions_section: {
        display:"grid",
        gap: 5,
        gridTemplateColumns:"0.6fr 1fr",
    },
    cancel_action_button: {
        padding: 0.5,
        backgroundColor: colors.transparent.main,
        borderColor:"#3d4856",
    },
    error: {
        color: colors.error.main,
        fontSize: 14,
        height: pxToRem(22),
    },

}
