/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* assets */
import checked from 'assets/icons/checked.png';
import {getIsValidatedUserBoxStyle, getNameMobileStyle, getMainBoxStyle, getNameStyle, getUserNameStyle, styles} from './styles';
import UploadProfilePicture from "../UploadProfilePicture";
import {useIsMobile} from "../../utils/hooks";

export const UserHeaderInfo = (props) => {
  const isMobile = useIsMobile();
  const {profile_photo, username, is_validated, handleUpdateProfilePicture} = props
  return (
    <Box sx={getMainBoxStyle}>
      <UploadProfilePicture src={profile_photo} padding={0} avatarSize={isMobile ? 90 : 147} onClick={handleUpdateProfilePicture} alt={'profile-pic'}/>
      <Box sx={getUserNameStyle}>
        <Typography sx={isMobile ? getNameMobileStyle : getNameStyle}>{username}{is_validated && <img src={checked} style={isMobile ? styles.imgMobile : styles.img}/>}</Typography>
      </Box>
    </Box>
  );
}

UserHeaderInfo.propTypes = {
  name: PropTypes.string,
  lastname: PropTypes.string,
  isVerified: PropTypes.bool,
  profile_photo: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
};
