
export const globalStyles  = {
  input:{
    height:"40px",
    width:"250px"
  },
  inputGroup:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    gap:"65px"
  },
  container:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between"
  }
}
