export const MeetingTypeEnum = {
  liveCall: 'LC',
  meetAndGreet: 'MG',
}

export const IdolTypeEnum = {
  influencer: 10, //idol normal no tiene tips ni meeting
  professional: 20, // idolp  si
  entrepreneur:30 // idole si
}


