import { observer } from "mobx-react";
import { useApi, useOnboardingStore } from "../../../utils/hooks";
import { OnboardingLayout } from "../../../components/OnboardingLayout";
import MDTypography from "../../../components/MDTypography";
import { IdolTaxStyles as styles } from "./styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "../../../context";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import { CustomFormikTextField } from "../../../components/TextField";
import { PrimaryButton } from "../../../components/PrimaryButton";
import UploadDocument from "../../../components/UploadDocument";
import DocumentUpload from "../../../assets/icons/uploadfile.svg";
import { Box, FormHelperText, Grid, Stack, useMediaQuery } from "@mui/material";
import { toBase64 } from "../../../utils/functions";
import { CustomAutocompleteFormik } from "../../../components/AutocompleteFormik";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { IdolTypes } from "../../../constants";
import {
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
} from "../../../models";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { GuestPaths } from "../../../paths/guestPaths";
import {notifyError} from "../../../services/notification";
import {parsePhoneNumber} from "libphonenumber-js";


const SUB_TITLE = `As an entrepreneur, it's important to share accurate tax information. This ensures that everyone is
                  on the same page and that you're able to stay compliant with the law. By being transparent and sharing
                  accurate tax information, you're able to build trust with your idolizers and keep your business 
                  running smoothly!`
export const OnboardingIdolTax = observer(() => {
  const [controller, dispatch] = useMaterialUIController();
  const onboardingStore = useOnboardingStore()
  const navigate = useNavigate()
  const [IdolType, setIdolType] = useState(onboardingStore.idol_type)
  const api = useApi()
  const [Countries, setCountries] = useState([])
  const [Regions, setRegions] = useState([])
  const [Cities, setCities] = useState([])
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolTax)

  const preCheckSomeFields = () => {
    if (IdolType === IdolTypes.Entrepreneur) {
      try {
        const phoneNumber = parsePhoneNumber(formik.values.tax_phone_number)
        if (!phoneNumber.isValid()) {
          return false
        }
      } catch (e) {
        const tmp = "+1" + formik.values.tax_phone_number
        try {
          if (!parsePhoneNumber(tmp).isValid()) {
            notifyError("Please check the phone number format")
            return false
          }
        } catch (ez) {
          notifyError("Please check the phone number format")
          return false
        }
        formik.setFieldValue('tax_phone_number', tmp)
      }
    }
    return true
  }

  const submitValues = (values) => {
    if (!preCheckSomeFields()) {
      return
    }
    onboardingStore.setData({...values})
    navigate(NextPage);
  }

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(OnboardingPhases.Tax, onboardingStore),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Tax, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  const getCountries = (event, search = "") => {
    api
      .getCountries(search)
      .then((res) => {
        if (res.kind === "ok") {
          setCountries(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRegions = (country, search = "") => {
    if (!country) {
      setCities([])
      return;
    }
    api
      .getRegions(search, country?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setRegions(res.data.results);
          setCities([]);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = (region, search = "") => {
    if (!region) {
      return;
    }
    api
      .getCities(search, region?.id)
      .then((res) => {
        if (res.kind === "ok") {
          setCities(res.data.results);
        } else {
          console.log("error", res);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage);
    getCountries()
  }, [])

  return (
    <OnboardingLayout
      titleBold={"IDOL"} titleNormal={"TAX"}
      progressBarPercent={"80%"}
      backTo={PreviousPage}
      subtitle={SUB_TITLE}>
      <MDBox mt={4} style={{minWidth: isMobile?"100%":"400px", maxWidth: isMobile?"100%":"522px"}}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="tax_social_security" formik={formik}/>
            <MDBox mb={0} mt={3}>
              <Box>
                <UploadDocument
                  text={"Click here to  upload your W-8 or W-9 form"}
                  onClick={async (file) => {
                    const b64file = await toBase64(file)
                    formik.setFieldValue('tax_w8_w9_file', {fileName: file.name, file: b64file})
                  }}
                  src={DocumentUpload}
                  alt=""
                  onlyPDF={true}/>
              </Box>
              {formik.touched.tax_w8_w9_file && Boolean(formik.errors.tax_w8_w9_file) && (
                <FormHelperText>
                  <MDTypography sx={{fontSize: 14}}
                                color={"error"}>{formik.touched.tax_w8_w9_file && formik.errors.tax_w8_w9_file}</MDTypography>
                </FormHelperText>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <MDTypography sx={styles.text}>Click here to generate your W-8 Form</MDTypography>
                  <Link target="_blank" href="https://insignia-30234.botics.co/static/documents/fw8.pdf">
                    <MDTypography sx={styles.link}>Generate w8 Form</MDTypography>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <MDTypography sx={styles.text}>Click here to generate your W-9 Form</MDTypography>
                  <Link target="_blank" href="https://insignia-30234.botics.co/static/documents/fw9.pdf">
                    <MDTypography sx={styles.link}>Generate w9 Form</MDTypography>
                  </Link>
                </Grid>
              </Grid>
            </MDBox>
            {IdolType === IdolTypes.Entrepreneur &&
              [
                <MDBox m={0}>
                  <UploadDocument
                    text={"Click here to  upload your Articles of Incorporation"}
                    onClick={async (file) => {
                      const b64file = await toBase64(file)
                      formik.setFieldValue('tax_article_of_incorporation', {fileName: file.name, file: b64file})
                    }}
                    src={DocumentUpload}
                    alt=""
                    onlyPDF={true}/>

                  {formik.touched.tax_article_of_incorporation && Boolean(formik.errors.tax_article_of_incorporation) && (
                    <FormHelperText>
                      <MDTypography sx={{fontSize: 14}}
                                    color={"error"}>{formik.touched.tax_article_of_incorporation && formik.errors.tax_article_of_incorporation}</MDTypography>
                    </FormHelperText>
                  )}
                </MDBox>,
                <CustomFormikTextField name="tax_company_name" formik={formik}/>,
                <CustomFormikTextField name="tax_id" formik={formik}/>,
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={12}>
                    <CustomAutocompleteFormik
                      name={'tax_country'}
                      formik={formik}
                      options={Countries}
                      onChange={(e, v) => {
                        formik.setFieldValue("tax_country", v)
                        getRegions(v)
                        formik.setFieldValue("tax_state", null)
                        formik.setFieldValue("tax_city", null)
                      }}
                      onClear={() => {
                        setRegions([])
                        setCities([])
                        formik.setFieldValue("tax_state", null)
                        formik.setFieldValue("tax_city", null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <CustomAutocompleteFormik
                      name={'tax_state'}
                      formik={formik}
                      options={Regions}
                      onChange={(e, v) => {
                        formik.setFieldValue("tax_state", v)
                        getCities(v)
                        formik.setFieldValue("tax_city", null)
                      }}
                      onClear={() => {
                        getRegions(formik.values.tax_country)
                        formik.setFieldValue("tax_city", null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <CustomAutocompleteFormik
                      name={'tax_city'}
                      formik={formik}
                      options={Cities}
                      onClear={() => {
                        getCities(formik.values.tax_city)
                        formik.setFieldValue("tax_city", null)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <CustomFormikTextField name="tax_zip_code" formik={formik}/>
                  </Grid>
                </Grid>,
                <CustomFormikTextField name="tax_address_line_1" formik={formik}/>,
                <CustomFormikTextField name="tax_address_line_2" formik={formik}/>,
                <CustomFormikTextField name="tax_phone_number" formik={formik}/>,
              ]
            }
            <MDBox mb={4} mt={4}>
              <PrimaryButton type={"submit"} onClick={preCheckSomeFields} text={"NEXT"}/>
            </MDBox>
          </Stack>
        </form>
      </MDBox>
    </OnboardingLayout>
  )
})
