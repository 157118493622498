import {Stack} from "@mui/material";
import {GenericModal} from "../../components/GenericModal";
import {SecondaryButton} from "../../components/SecondaryButton";
import {useUserStore} from "../../utils/hooks";
import {useNavigate} from "react-router-dom";
import {routes} from "../../routes";
import pxToRem from "../../assets/theme/functions/pxToRem";

export const NotFound = () => {
  const userStore = useUserStore()
  console.log(userStore.isLoggedIn)
  const navigate = useNavigate()
  const handleGoBack = () => {
    // eslint-disable-next-line no-restricted-globals
    history.back()
  }

  const handleLogout = () => {
    // navigate(routes[userStore.user_type_codename].logout.path)
  }

  const handleGoToHome = () => {
    // navigate(routes[userStore.user_type_codename].home.path)
  }

  return <Stack width={'100vw'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
    {userStore.isLoggedIn ? handleGoToHome() :
      (<GenericModal title='Resource not found' onClose={() => 0} open={true}>
        <Stack alignItems={'center'} justifyContent={'center'} width={'100%'}>
          <SecondaryButton text={"Go back"} onClick={handleGoBack} buttonStyle={{width: '60%'}}/>
        </Stack>
      </GenericModal>)
    }

  </Stack>
}
