
function primaryButtonStyle(theme,buttonStyle={}, size="large", mainColor) {
  const { palette } = theme;
  let sizeStyle = {}
  if(size === "small"){
    sizeStyle = {    minHeight: "25px !important", height:"25px"}
  }else if(size === "medium"){
    sizeStyle = {    minHeight: "40px !important", height:"40px"}
  }
  if(!mainColor){
    mainColor = palette.primary
  }

  return {
    width:"100%",
    borderRadius: 1,
    backgroundColor: mainColor.main,
    border:`2px solid ${mainColor.main}`,
    "&:hover": {
      backgroundColor: mainColor.hover,
      border:`2px solid ${mainColor.hover}`,
    },
    ...sizeStyle,
    ...buttonStyle,
  }
}

function primaryButtonTextStyle(theme, size="large", fontWeight=400) {
  const { palette } = theme;
  const fontSize = size === "small"? "0.85rem":"1.25rem"

  return {
    borderRadius: 0,
    color: palette.white.main,
    fontSize:fontSize,
    fontWeight:fontWeight
  }
}


export {  primaryButtonStyle, primaryButtonTextStyle };
