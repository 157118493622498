import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import { PrivacyPolicy } from "./PrivacyPolicy";
import {useStores} from "../../../models";
import {useEffect} from "react";

export const PrivacyPolicyPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("PRIVACY POLICY")
  }, []);
  return (
    <BaseProtectedComponentPage loginRequired={false}>
        <DashboardLayout>
          <PrivacyPolicy codeValue={'DR2049813'}/>
        </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
