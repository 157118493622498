import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import BillingDetails from "./BillingDetails";
import CardDetails from "./CardDetails";
import { styles } from "./styles";

function AddCreditCardView({ formik }) {
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Accordion sx={styles.accordion}>
        <AccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}>
        <Typography sx={styles.title}>Add Card</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.inputsContainer}>
            <CardDetails formik={formik} />
            <BillingDetails formik={formik} />
          </Box>
          <Box sx={styles.buttonContainer}>
            <Button type="submit" sx={styles.button}>
              SUBMIT
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AddCreditCardView;
