import HomeIcon from "../assets/icons/home-icon.svg";
import IdolBankIcon from "../assets/icons/wallet-idol-bank.svg";
import IdolPayIcon from "../assets/icons/idol-pay.svg";
import EventsIcon from "../assets/icons/events.svg";
import CalendarIcon from "../assets/icons/calendar1.svg";
import PricesIcon from "../assets/icons/price.svg";
import PeopleIcon from "../assets/icons/people.svg";
import GetVerifiedIcon from "../assets/icons/CheckCircle.svg";
import LinkIcon from "../assets/icons/Link.svg";
import ProhibitIcon from "../assets/icons/Prohibit.svg";
import ClipboardIcon from "../assets/icons/ClipboardText.svg";
import TrashIcon from "../assets/icons/trash.svg";


export const idolInfluencerDesktopMenu = {
  dashboard: {
    home: {
      noCollapse: true,
      name: 'Home',
      icon: HomeIcon,
      type: 'collapse',
    },
    wallet: {
      noCollapse: true,
      name: 'Wallet',
      icon: IdolBankIcon,
      type: 'collapse',
    },
    transactions: {
      noCollapse: true,
      name: 'Transactions',
      icon: IdolPayIcon,
      type: 'collapse',
    },
    meetings: {
      noCollapse: true,
      name: 'Meetings',
      icon: CalendarIcon,
      type: 'collapse',
    },
    idolizers: {
      noCollapse: true,
      name: 'Idolizers',
      icon: PeopleIcon,
      type: 'collapse',
    },
  },
  settings: {
    getVerified: {
      noCollapse: true,
      name: 'Get Verified',
      icon: GetVerifiedIcon,
      type: 'collapse',
    },
    referralLink: {
      noCollapse: true,
      name: 'Referral Link',
      icon: LinkIcon,
      type: 'collapse',
    },
    blockedUsers: {
      noCollapse: true,
      name: 'Blocked Users',
      icon: ProhibitIcon,
      type: 'collapse',
    },
    termsAndConditions: {
      noCollapse: true,
      name: 'Terms & Conditions',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    privacyPolicy: {
      noCollapse: true,
      name: 'Privacy Policy',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    deactivateAccount: {
      noCollapse: true,
      name: 'Deactivate Account',
      icon: TrashIcon,
      type: 'collapse',
    }
  }
}


export const idolDesktopMenu = {
  dashboard: {
    home: {
      noCollapse: true,
      name: 'Home',
      icon: HomeIcon,
      type: 'collapse',
    },
    idolBank: {
      noCollapse: true,
      name: 'Idol Bank',
      icon: IdolBankIcon,
      type: 'collapse',
    },
    idolPay: {
      noCollapse: true,
      name: 'Idol Pay',
      icon: IdolPayIcon,
      type: 'collapse',
    },
    events: {
      noCollapse: true,
      name: 'Events',
      icon: EventsIcon,
      type: 'collapse',
    },
    calendar: {
      noCollapse: true,
      name: 'Calendar',
      icon: CalendarIcon,
      type: 'collapse',
    },
    prices: {
      noCollapse: true,
      name: 'Prices',
      icon: PricesIcon,
      type: 'collapse',
    },
    idolizers: {
      noCollapse: true,
      name: 'Idolizers',
      icon: PeopleIcon,
      type: 'collapse',
    },
  },
  settings: {
    getVerified: {
      noCollapse: true,
      name: 'Get Verified',
      icon: GetVerifiedIcon,
      type: 'collapse',
    },
    referralLink: {
      noCollapse: true,
      name: 'Referral Link',
      icon: LinkIcon,
      type: 'collapse',
    },
    blockedUsers: {
      noCollapse: true,
      name: 'Blocked Users',
      icon: ProhibitIcon,
      type: 'collapse',
    },
    termsAndConditions: {
      noCollapse: true,
      name: 'Terms & Conditions',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    privacyPolicy: {
      noCollapse: true,
      name: 'Privacy Policy',
      icon: ClipboardIcon,
      type: 'collapse',
    },
    deactivateAccount: {
      noCollapse: true,
      name: 'Deactivate Account',
      icon: TrashIcon,
      type: 'collapse',
    }
  }
}
