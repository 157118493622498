import React, { useEffect, useState } from "react";
import colors from "../../assets/theme/base/colors";
import Modal from "@mui/material/Modal";
import MDBox from "../MDBox";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';
import './day-picker.css';
import moment from "moment";
import { CustomCaption } from "../AvailabilityModal";
import { SecondaryButton } from "../SecondaryButton";


const DateRangeModalPicker = props => {
  const {open, onClose, onApply} = props
  const [range, setRange] = useState({  })

  useEffect(()=>{
    console.log(range)
  }, [range])

  return (
    <Modal open={open} onClose={onClose} style={styles.modal}>
      <MDBox style={styles.modalContainer}>
        <DayPicker
          mode="range"
          selected={range}
          fromDate={new Date()}
          // fromMonth={new Date()}
          toDate={moment().add('months', 6).endOf('month').toDate()}
          components={{
            Caption: CustomCaption
          }}
          onSelect={setRange}
          footer={""}
        />
        <MDBox style={{display:"flex", flexDirection:"row", padding:"0px 20px 20px 20px", gap:"20px"}}>
          <SecondaryButton
            text="Cancel"
            onClick={() => {
              setRange({})
              onClose()
            }}
            padding={0.3}
            buttonStyle={styles.cancelButton}
            backgroundColor={colors.transparent.main}
            borderColor={colors.modal.buttons.cancel}
            textStyles={styles.cancelButtonText}
          />
          <SecondaryButton text="Apply"
                           disabled={!range}
                           buttonStyle={{height:"40px"}}
                           padding={0.5}
                           onClick={()=>{
                             if(range.from && range.to){
                               onApply(range)
                             }
                           }} />
        </MDBox>
      </MDBox>
    </Modal>
  );
};

DateRangeModalPicker.defaultProps = {

};

export default DateRangeModalPicker;

const styles = {
  cancelButton:{
    height:"40px",
    "&:hover": {
      borderColor: colors.modal.buttons.cancel,
    },
  },
  cancelButtonText:{
    color:colors.modal.buttons.cancel,
    "&:hover": {
      borderColor: colors.modal.buttons.cancel,
      color: colors.modal.buttons.cancel,
    },
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: colors.card.background,
    borderRadius: '12px',
    height: 'fit-content',
    marginBottom: 5,
    overflowY: 'auto',
    position: 'relative',
  }
}
