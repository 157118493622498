/* dependencies */
import { useState } from "react";
import PropTypes from "prop-types";

/* MUI components */
import { Box, Divider, Input, Typography } from "@mui/material";

/* components */
import { PrimaryButton } from "components/PrimaryButton";
import { ConfirmPaymentModal } from "components/ConfirmPaymentModal";

/* assets */
import { styles } from "./styles";
import { moneyFormat } from "../../../services/helpers";
import { notifyWarning } from "../../../services/notification";

export const  GetBalanceSection = ({ buttonTitle,
                                    currentBalance, lifetimeBalance, buyActionHandler,
                                    isBuy,
                                    cardSelected, inputAmount, changeInputAmount}) => {
  const [ConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleBuy = ()=>{
    if(isBuy && !cardSelected){
      notifyWarning("You need to select a credit card")
    }else{
      setConfirmationModalOpen(true)
    }
  }
  return (
    <Box sx={styles.balance_container}>
      <Box sx={styles.balance_layout}>
        <Box sx={styles.balance}>
          <Typography sx={styles.balance_title}>
            Current Balance
          </Typography>

          <Typography sx={styles.balanceAmount}>
            {currentBalance}
          </Typography>
        </Box>

        <Divider sx={styles.balance_divider} />
        <Box sx={styles.balance}>
          <Typography sx={styles.balance_title}>
            Lifetime Balance
          </Typography>

          <Typography sx={styles.balanceAmount}>
            {lifetimeBalance}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.divisor_line} />
      <ConfirmPaymentModal
        open={ConfirmationModalOpen}
        message={`Are you sure you want ${buttonTitle} ${moneyFormat(inputAmount)}`}
        confirmAction={() => [buyActionHandler.call(), setConfirmationModalOpen(false)]}
        confirmLabel={buttonTitle}
        onClose={()=>setConfirmationModalOpen(false)}
        cancelAction={() => setConfirmationModalOpen(false)}
      />

      <Box>
        <Box sx={styles.container}>
          <Box sx={styles.input_container}>
            <Input sx={styles.input} type='number' value={inputAmount}
                   onChange={changeInputAmount} />
          </Box>
        </Box>
        <Box sx={styles.actions_container}>
          <Box />
          <PrimaryButton text={buttonTitle}
                         disabled={inputAmount <= 0}
                         onClick={() => handleBuy()} />
        </Box>
      </Box>
    </Box>
  )
}

GetBalanceSection.propTypes = {
  currentBalance: PropTypes.string.isRequired,
  lifetimeBalance: PropTypes.string.isRequired,
  buyActionHandler: PropTypes.func.isRequired,
}
