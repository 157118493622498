import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import React from "react";
import BillingDetailsMobile from "./BillingDetailsMobile";
import CardDetailsMobile from "./CardDetailsMobile";
import colors from "assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import {SecondaryButton} from "../../../../components/SecondaryButton";
const white = colors

function AddCreditCardViewMobile({ formik }) {
  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Accordion sx={styles.accordion}>
        <AccordionSummary sx={styles.accordionSummary} expandIcon={<ExpandMoreIcon sx={styles.expandIcon} />}>
        <Typography sx={styles.title}>Add Card</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.inputsContainer}>
            <CardDetailsMobile formik={formik} />
            <BillingDetailsMobile formik={formik} />
          </Box>
          <Box sx={styles.buttonContainer}>
            <SecondaryButton
              text='SAVE'
              onClick={formik.handleSubmit}
              textStyles={styles.buttonText}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AddCreditCardViewMobile;

const styles = {
  title: {
    alignSelf: "flex-start",
    color: "#fff",
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(26),
  },
  accordion: {
    backgroundColor: "unset",
    boxShadow: "0px 0px 0px 0px",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
    margin: "0px !important",
  },
  expandIcon: {
    color: "#fff",
    width: "20px",
    height: "20px",
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  titleDetails: {
    color: "#fff",
    fontWeight: "600",
  },
  cardDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  doubleInput: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
  },
  billingDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  input: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    padding: "15px",
    fontSize: "18px",
    width: "100%",
    color: "#fff",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "35px",
    paddingX: "25%"
  },
  button: {
    backgroundColor: colors.primary.main,
    height: "42px",
    width: "193px",
    color: "#fff",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: colors.withdrawlcard.greybutton,
      color: "#fff",
    },
    "&:focus": {
      color: "#fff!important",
    },
  },
  buttonText: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "13px",
    letterSpacing: "0.1em",
  }
};

