import React, {useState} from "react";

/* MUI components */
import {Box, Typography} from "@mui/material";

/* components */
import {PrimaryButton} from "components/PrimaryButton";
import {SecondaryButton} from "components/SecondaryButton";
import {BaseIdolCard} from "base/Cards/IdolCard";
import {MobileCreateEventModal} from "./CreateEventModal";

/* assets */
import pxToRem from "assets/theme/functions/pxToRem";
import Logo from "assets/icons/idolmarca.svg"
import colors from 'assets/theme/base/colors'
const {card, white, red, transparent, modal} = colors

export const EventsMobile = ({}) => {
  const [openModal, setOpenModal] = useState(false);
  const modalProps = {
    open: openModal,
    onClose: () => setOpenModal(false),
  };
  return (
    <Box>
      <Box sx={{...styles.topButtons}}>
        <SecondaryButton 
          text={"CREATE EVENT"} 
          textStyles={styles.topButtonText} 
          buttonStyle={styles.buttonBorders}
          onClick={() => setOpenModal(true)}
        />
        <PrimaryButton text={"ACTIVE EVENTS"} textStyles={styles.topButtonText} buttonStyle={styles.buttonBorders}/>
      </Box>
      <Box sx={{marginTop: "20px"}}>
        <BaseIdolCard {...cardProps} >
          <Box sx={{...styles.cardContainer}}>
            <Box sx={{...styles.content}}>
              <Typography sx={{...styles.eventTime}}>
                15 Minute Event
              </Typography>
              <Typography sx={{...styles.eventType}}>
                idolcall
              </Typography>
            </Box>
            <Box sx={{...styles.descriptionContainer}}>
              <Typography sx={{...styles.descriptionText}}>
                My popular ice-breaker! Book me for a video consultation. I can’t wait to meet you!
              </Typography>
            </Box>
            <Box sx={{...styles.priceContainer}}>
              <Typography sx={{...styles.priceText}}>
                $150.00
              </Typography>
            </Box>
            <Box sx={{...styles.contentButtons}}>
              <Box sx={{paddingY: "5px"}}>
                <img src={Logo}/>
              </Box>
              <Box sx={{...styles.buttonContainer}}>
                <PrimaryButton
                  text={"Edit"}
                  buttonStyle={styles.primaryButton}
                  textStyles={styles.buttonText}
                />
                <SecondaryButton
                  text={"Delete"}
                  buttonStyle={styles.secondaryButton}
                  textStyles={{color: modal.buttons.cancel, ...styles.buttonText}}
                  backgroundColor={styles.dismissButton.background}
                  borderColor={styles.dismissButton.color}
                />
              </Box>
            </Box>
          </Box>
        </BaseIdolCard>
      </Box>
      <MobileCreateEventModal {...modalProps}/>
    </Box>
  )
}

const styles = {
  baseCard: {
    background: card.meeting,
    paddingTop: 1,
    paddingBottom: pxToRem(24),
  },
  header: {
    paddingX: pxToRem(22),
    paddingTop: pxToRem(14),
  },
  headerText: {
    fontSize: pxToRem(14),
  },
  cardContainer: {
    borderRadius: "10px",
    marginTop: "40px",
  },
  content: {
    paddingX: pxToRem(24),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  eventTime: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    paddingY: pxToRem(16),
    fontWeight: 400,
    color: white.main,
  },
  eventType: {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(11),
    paddingY: pxToRem(19),
    fontWeight: 600,
    color: red.main,
    letterSpacing: "0.1em",
  },
  descriptionContainer: {
    paddingX: pxToRem(24),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  descriptionText: {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    fontWeight: 400,
    color: white.main,
  },
  priceContainer: {
    paddingX: pxToRem(24),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "36px",
  },
  priceText: {
    fontSize: pxToRem(22),
    lineHeight: pxToRem(26),
    fontWeight: 400,
    color: white.main,
  },
  contentButtons: {
    paddingX: pxToRem(24),
    marginTop: "21px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    gap: "14px",
  },
  primaryButton: {
    height: "40px",
  },
  secondaryButton: {
    height: "40px",
  },
  buttonText: {
    letterSpacing: "0.1em",
    fontSize: "12px",
    lineHeight: "11px",
  },
  dismissButton: {
    background: transparent.main,
    color: modal.buttons.cancel,
  },
  topButtons: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    marginX: "30px",
    height: "50px",
  },
  buttonBorders: {
    borderRadius:"0px 0px 10px 10px",
    paddingX: "0"
  },
  topButtonText: {
    fontSize: "12px",
    lineHeight: "11px",
    letterSpacing: "0.1em",
    fontWeight: "600",
  },
}

const cardProps = {
  title: "Coffee Consultation!",
  uppercaseTitle: false,
  titleHeaderStyles: styles.headerText,
  cardStyles: styles.baseCard,
  headerStyles: styles.header,
};
