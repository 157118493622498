import React from "react";

import {RecordCard} from "components/RecordCard/RecordCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import GreyLogo from "assets/images/idol_grey.png";
import DeleteIcon from "assets/icons/trash_red.svg";

export const ReservedNameCard = ({reservedName, handleRemove}) => {
  return (
    <RecordCard
      {...
        {
          cardFirstContent: (
            <MDBox display="flex" alignItems="center" justifyContent="space-between" width="100%" ml={pxToRem(7)} mr={pxToRem(20)}>
              <MDTypography variant='d7' color='light' fontWeight='light'>{reservedName?.name}</MDTypography>
              <img src={GreyLogo} width={29} height={29} alt="Logo"/>
            </MDBox>
          ),
          cardThirdContent: (
            <MDBox sx={styles.removeBox} onClick={() => handleRemove(reservedName)}>
              <img src={DeleteIcon} alt="Suspend Icon"/>
              <MDTypography color='primary' variant='button' fontWeight='regular'>Remove</MDTypography>
            </MDBox>
          ),
          containerStyles: {mt: 0},
          firstContentStyle: {width: "100%"},
          secondContentStyle: {width: 0},
          cardThirdContentStyles: styles.thirdContent,
          dividerStyles: {mr: pxToRem(34)},
          showOptionsCard: false,
        }
      }
    />
  )
}

const styles = {
  removeBox: ({palette: {tertiary}}) => ({
    alignItems: "center",
    background: tertiary.main,
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    gap: pxToRem(8),
    height: pxToRem(40),
    justifyContent: "center",
    padding: `${pxToRem(12)} ${pxToRem(14)}`,
    width: pxToRem(103),
  }),
  thirdContent: {
    display: "flex",
    justifyContent: "flex-end",
    width: pxToRem(254),
  },
  logo: {
    height: pxToRem(29),
    width: pxToRem(29),
  }
}
