import colors from "../../../../assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import rgba from "../../../../assets/theme/functions/rgba";

export const desktopStyles = {
  location:{
    fontWeight:500,
    color: colors.primary.main,
    fontSize:12,
    lineSpacing:"0.1em"
  },
  meetType:{
    fontWeight:500,
    color: colors.primary.main,
    fontSize:12,
    lineSpacing:"0.1em"
  },
  description:{
    fontWeight:400,
    color: colors.white.main,
    fontSize:16,
    paddingLeft:"23px",
    paddingRight:"23px"
  },
  fee:{
    fontWeight:400,
    fontSize:22,
    color:"white"
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  footer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"23px"

  },
  baseCard: {
    paddingBlock: `${pxToRem(30)} ${pxToRem(8)}`,
    background: colors.card.event,
    margin:"0 auto",
    width:pxToRem(420),
    minHeight:pxToRem(240),
    marginTop:pxToRem(30),
    marginBottom:pxToRem(30),
    cursor:"pointer"
  },
  headerCard: {
    borderRadius: '13px 13px 0 0',
    width:pxToRem(420),
    height:pxToRem(70),
    background:rgba(colors.darkBlue[200], 0.5),
  },
}

export const mobileStyles = {
  meetType:{
    fontWeight:500,
    color: colors.primary.main,
    fontSize:12,
    lineSpacing:"0.1em"
  },
  description:{
    fontWeight:400,
    color: colors.white.main,
    fontSize:14,
    paddingLeft:"23px",
    paddingRight:"23px"
  },
  fee:{
    fontWeight:400,
    fontSize:22,
    color:"white"
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  footer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"23px"

  },
  baseCard: {
    paddingBlock: `${pxToRem(30)} ${pxToRem(8)}`,
    background: colors.card.event,
    margin:"0 auto",
    width:"100%",
    minHeight:pxToRem(240),
    marginTop:pxToRem(30),
    marginBottom:pxToRem(30),
    cursor:"pointer"
  },
  headerCard: {
    borderRadius: '13px 13px 0 0',
    width:"100%",
    height:pxToRem(70),
    background:rgba(colors.darkBlue[200], 0.5),
  },
}
