import * as Yup from 'yup';

export const LocationDetailsRoot = () => {
    const initialValues = {
        location: '',
        additional_comments: ''
      };
    
    const validationShema = Yup.object().shape({
      location: Yup.string().required('Location is required'),
      additional_comments: Yup.string('Additional comment must be a string'),
    });

    const handleOnSubmit = (values) => {
        console.log(values);
        /* your endpoint */
    } 

    return {
        initialValues, 
        validationShema,
        handleOnSubmit
    }
}