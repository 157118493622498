import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const filterBox = {
  width:"200px",
  display:"flex",
  flexDirection:"row",
  justifyContent:"center",
  alignItems:"center",
  height:"100%",
  marginBottom:"8px",
  cursor:"pointer"
}
export const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    // position: "relative",
    flexDirection:"row",
    justifyContent:"center",
    gap:"3px"
  },

  popoverText: {
    color: colors.white.main,
    cursor: 'pointer',
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: pxToRem(16),
  },
  filterBoxSelected:{
    ...filterBox,
    borderBottom: `2px solid ${colors.primary.main}`
  },
  filterBox:{
   ...filterBox
  },
  filterButton:{
    width:"106px",
    height:"28px !important",
    padding:0,
  },
  filterButtonText:{
    fontSize:12,
    letterSpacing:"0.1em",
    fontWeight:500
  },
  leftColumnButton:{
    width:120,
    height:42,
    padding:0
  },
  influencerButton:{
    width: 240
  },
  mobileButton:{
    width:150,
    height:42,
    padding:0
  },
  threeDots: {
    color: "#fff",
    cursor: 'pointer',
    height: "32px",
    width: "32px",
  },
  lefColumnButtonText:{
    fontSize:12,
    letterSpacing:"0.1em",
    fontWeight:700
  },
  leftColumnButtonsContainer:{
    display:"flex",
    flexDirection:"row",
    gap: "24px",
    justifyContent:"space-between",
    marginTop:"24px"
  },
  mobileButtonContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around",
    marginTop:"24px"
  },
  meetMeText:{
    fontSize:12,
    letterSpacing:"0.1em",
    fontWeight:700,
    color: colors.white.main,
    "&:hover": {
      color: colors.white.main,
    },
  },
  meetMeButton:{
    marginTop:"10px",
    border:`2px solid ${colors.white.main}`,
    "&:hover": {
      border:`2px solid ${colors.white.main}`,
    }
  },
  postsContainer:{
    marginLeft:"24px",
    height:"calc(100vh - 197px)",
    boxSizing:"content-box",
    overflowY:"scroll",
    marginRight:"24px"
  },
  filterNav:{
    marginLeft:"30px",
    marginRight:"24px",
    display:"flex",
    flexDirection:"row",
    height:"80px",
    width:"auto",
    justifyContent:"space-between",
    alignItems:"center"
  },
  filterText:{
    color: colors.white.main,
    fontSize:14,
    letterSpacing:"0.1em",
    textAlign:"center"
  },
  filterTextSelected:{
    color: colors.primary.main,
    fontSize:14,
    letterSpacing:"0.1em",
    textAlign:"center"
  },
  username:{
    overflowWrap: "anywhere",
    width:"200px",
    color:colors.white.main,
    fontWeight: 400,
    textAlign:"center",
    fontSize:32,
    lineHeight:"32px",
  },
  idp:{
    fontSize:20,
    color:colors.primary.main,
    marginBottom:"15px",
    marginLeft:"5px"
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems: "center"
  },
  idolPoints:{
    color:colors.primary.main,
    fontSize:36,
    fontWeight:300,
    padding:"8px 0px 8px 0px"
  },
  location:{
    marginBottom: "20px",
    marginTop:"5px",
    fontSize:11,
    opacity:0.6,
    color:colors.white.main,
    fontWeight: 400,
    textAlign:"center",
  },
  bio:{
    fontSize:11,
    fontWeight:400,
    color:colors.white.main,
    textAlign:"center",
    padding:"12px"
  },
  mainContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"start",
    height:"100%"
  },
  leftColumnCard:{
    background:colors.background.container,
    width:"252px",
    borderRadius:'5px',
  },
  leftColumn:{
    padding:"33px",
    display:"flex",
    width:"320px",
    flexDirection:"column",
    background:colors.background.default,
    height:"100%"
  },
  rightColumn:{
    display:"flex",
    flexDirection:"column",
    height:"auto",
    width:"83vw"
  }
}
