export const tabSection = {
  None: 0,
  IdolProfile: 1,
  IdolBank: 2,
  IdolTax: 3,
  IdolInc: 4,
};

export const filterOptions = {
  ALL: "All",
  IDOL: "Idols",
  IDOLIZER: "Idolizers",
  VERIFIED: "Verified",
  UNVERIFIED: "Unverified",
  DELETED: "Deleted",
  SUSPENDED: "Suspended",
}

export const filterRules = {
  ALL: {},
  VERIFIED: {is_validated: true},
  UNVERIFIED: {is_validated: false},
  IDOLIZER: {is_idol: false},
  IDOL: {is_idol: true},
  DELETED: {is_deleted: true},
  SUSPENDED: {is_suspended: true},
};
