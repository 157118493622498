import {secondaryButtonStyle, secondaryButtonTextStyle} from "./styles";
import MDTypography from "../MDTypography";
import CircularProgress from '@mui/material/CircularProgress'
import {Button} from "@mui/material";


export const SecondaryButton = (props) => {
  const {text, type = "button", size, onClick, disabled, textStyles, buttonStyle, fontWeight, loading=false, ...rest} = props
  return (
    <Button
      onClick={onClick}
      size={size}
      type={type}
      disabled={disabled}
      sx={[(theme) => secondaryButtonStyle(theme, size, buttonStyle), { ...rest }]}
    >
      {loading
        ? <CircularProgress size={20} />
        : <MDTypography sx={[(theme) => secondaryButtonTextStyle(theme, size, fontWeight), { ...textStyles }]}>
          {text}
        </MDTypography>
      }
    </Button>
  )
}
