import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack} from 'twilio-video';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import Typography from '@material-ui/core/Typography';

import useIsTrackSwitchedOff from '../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../hooks/usePublications/usePublications';
import useTrack from '../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import {useAppState} from '../state';
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useStores} from "../../../models";
import {Box, Stack, useMediaQuery} from "@mui/material";
import theme from "../../../assets/theme";

const borderWidth = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      isolation: 'isolate',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 0,
      overflow: 'hidden',
      marginBottom: '0.5em',
      '& video': {
        objectFit: 'cover !important',
      },
      // borderRadius: '4px',
      // border: `${theme.participantBorderWidth}px solid rgb(245, 248, 255)`,
      // paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      // background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: theme.sidebarMobileHeight,
        width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '12px',
        paddingTop: `${theme.sidebarMobileHeight - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: 'blue',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em 0.18em 0',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    typography: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    galleryView: {
      // border: `${theme.participantBorderWidth}px solid ${theme.galleryViewBackgroundColor}`,
      // borderRadius: '8px',
      padding: '0!important',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: '0',
        fontSize: '12px',
        margin: '0',
        '& video': {
          objectFit: 'cover !important',
        },
      },
    },
    dominantSpeaker: {
      border: `solid ${borderWidth}px #7BEAA5`,
    },
  })
);

const AvatarHostMobile = (props: any) => {
  const {image} = props
  return (<div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
    <Stack
      width={'100%'}
      height={'100%'}
      overflow={'hidden'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)',
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(25% - 20vw)'}
      left={'calc(50% - 20vw)'}
      height={'40vw'}
      width={'40vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </div>)
}

const AvatarGuestMobile = (props: any) => {
  const {image} = props
  return (<div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
    <Stack
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)',
        overflow: 'hidden',
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(75% - 20vw)'}
      left={'calc(50% - 20vw)'}
      height={'40vw'}
      width={'40vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </div>)
}

const AvatarHostDesktop = (props: any) => {
  const {image} = props
  return (<div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
    <Stack
      width={'100%'}
      height={'100%'}
      overflow={'hidden'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)',
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(50% - 10vw)'}
      left={'calc(25% - 10vw)'}
      height={'20vw'}
      width={'20vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </div>)
}

const AvatarGuestDesktop = (props: any) => {
  const {image} = props
  return (<div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
    <Stack
      width={'100%'}
      height={'100%'}
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        filter: 'blur(8px)',
        overflow: 'hidden',
      }}
    >
    </Stack>
    <Box
      position={'absolute'}
      top={'calc(50% - 10vw)'}
      left={'calc(75% - 10vw)'}
      height={'20vw'}
      width={'20vw'}
      overflow={'hidden'}
      borderRadius={'50%'}
      border={`${pxToRem(2)} solid white`}
    >
      <img src={image} alt={'No profile picture'} height={'100%'} width={'100%'}/>
    </Box>
  </div>)
}

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
  isHost?: boolean;
}

export default function ParticipantInfo({
                                          participant,
                                          onClick,
                                          isSelected,
                                          children,
                                          isLocalParticipant,
                                          hideParticipant,
                                          isDominantSpeaker,
                                          isHost,
                                        }: ParticipantInfoProps) {
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const {meetingStore} = useStores()
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const {isGalleryViewActive} = useAppState();

  const classes = useStyles();

  // @ts-ignore
  const meeting = meetingStore.currentMeeting

  return (!isVideoEnabled || isVideoSwitchedOff) ?
    (isMobile ?
      (isHost ? <AvatarHostMobile image={meeting.to_user.image}/> :
        <AvatarGuestMobile image={meeting.from_user.image}/>)
      :
      (isHost ? <AvatarHostDesktop image={meeting.to_user.image}/> :
        <AvatarGuestDesktop image={meeting.from_user.image}/>))
    :
    (<div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
        [classes.dominantSpeaker]: isDominantSpeaker,
        [classes.galleryView]: isGalleryViewActive,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
      style={{height: "100%"}}
    >
      <div className={classes.infoContainer}>
        <NetworkQualityLevel participant={participant}/>
      </div>
      <div className={classes.innerContainer}>

        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>);
}
