import {useState, useEffect} from "react";

// @mui components
import {CircularProgress} from "@mui/material";

// Components
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {BasePostCard} from "base/Cards/PostCard";
import {BaseCardIdolInfo} from "base/Cards/InfoCards/IdolizerInfoCard";
import MDBox from "components/MDBox";
import {SecondaryButton} from "components/SecondaryButton";
import {CustomTextField} from "components/TextField";
import MDTypography from "components/MDTypography";

// Utils
import {useApi} from "utils/hooks";
import {notifyError, notifySuccess} from "services/notification";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";

export const AdminFeedbackPage = () => {
  const api = useApi();
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState({feedback: null, message: ""});
  const [loading, setLoading] = useState(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);

  const getFeedbacks = () => {
    setLoading(true);
    api.getFeedbackMessage("")
      .then(response => {
        if (response.kind === "ok") {
          setFeedbacks(response.data?.results);
        }
      })
      .catch(error => notifyError(error.message))
      .finally(() => setLoading(false))
  }

  const sendFeedbackResponse = () => {
    setIsLoadingResponse(true);
    api.sendFeedback({feedback_message: selectedFeedback.feedback?.id, message: selectedFeedback.message})
      .then(response => {
        if(response.kind === "ok") {
          notifySuccess("Response sent successfully");
          getFeedbacks()
        }
      }).catch(error => notifyError(error.message))
      .finally(() => {
        setIsLoadingResponse(false);
        setSelectedFeedback({feedback: null, message: ""});
      })
  }

  useEffect(() => {
    getFeedbacks();
  }, [])
  return (
    <DashboardLayout isAdmin hideFooter>
      <MDBox my={pxToRem(34)} mx={pxToRem(102)}>
        <BasePostCard title='FEEDBACK'>
          <MDBox width='100%' mt={pxToRem(14)} minHeight={pxToRem(50)} maxHeight={pxToRem(600)} pr={pxToRem(25)} overflow='hidden auto'>
            {loading
              ? <MDBox display='flex' justifyContent='center'><CircularProgress/></MDBox>
              : feedbacks.length === 0
                ? <MDTypography color='light' size='md'>No feedbacks found</MDTypography>
                : (
                  <MDBox display='flex' flexDirection='column' gap={pxToRem(13)}>
                    {feedbacks.map((feedback) => (
                      <MDBox
                        key={feedback?.id}
                        borderRadius="10px"
                        minHeight={pxToRem(185)}
                        maxHeight={pxToRem(356)}
                        p={`${pxToRem(17)} ${pxToRem(47)} ${pxToRem(21)}`}
                        sx={({palette: {tertiary}}) => ({background: tertiary.main})}
                      >
                        <MDBox display='flex' flexDirection='column' gap={pxToRem(10)}>
                          <BaseCardIdolInfo
                            idolizerName={feedback?.full_name}
                            showPoints={false}
                            infoCardStyles={{p: 0}}
                            avatarStyles={{width: pxToRem(34), height: pxToRem(34)}}
                            nameStyles={{fontSize: pxToRem(14)}}
                            idpStyles={{fontSize: pxToRem(12)}}
                            pointStyles={{fontSize: pxToRem(14)}}
                          />
                          <MDTypography size='xs' color='gray'>{feedback?.email}</MDTypography>
                          <MDBox minHeight={pxToRem(50)} maxHeight={pxToRem(50)} overflow='hidden auto' pr={pxToRem(15)}>
                            <MDTypography size='sm' color='light'>{feedback?.message}</MDTypography>
                          </MDBox>
                        </MDBox>
                        {feedback?.responses?.length > 0 && feedback?.responses.map(response => {
                          return (
                            <MDBox>
                              <MDTypography size='md' color='light' key={'-m-' + response.id}>Response: {response?.message}</MDTypography>
                            </MDBox>
                          )
                        })}
                        {selectedFeedback.feedback?.id === feedback?.id && (
                          <MDBox mt={pxToRem(13)}>
                            <CustomTextField
                              inputLabel='Response'
                              placeholder='Type here...'
                              labelStyle={{fontSize: '12px'}}
                              multiline
                              rows={4}
                              value={selectedFeedback.message}
                              onChange={e => setSelectedFeedback({...selectedFeedback, message: e.target.value})}
                              inputBase={false}
                            />
                          </MDBox>
                        )}
                        <MDBox display='flex' justifyContent='flex-end' mt={pxToRem(14)}>
                          {feedback?.responses?.length === 0 && <SecondaryButton
                            onClick={() => selectedFeedback.feedback?.id !== feedback?.id
                              ? setSelectedFeedback({feedback, message: ""})
                              : sendFeedbackResponse()
                            }
                            text={selectedFeedback.feedback?.id === feedback?.id
                              ? isLoadingResponse
                                ? <CircularProgress size='14px' />
                                : 'SEND'
                              : 'RESPONSE'
                            }
                            disabled={selectedFeedback.feedback?.id === feedback?.id
                              ? selectedFeedback?.message.length < 5
                              : false
                            }
                            width={pxToRem(98)}
                            height={pxToRem(32)}
                            background='transparent'
                            textStyles={{fontSize: pxToRem(10), display: 'flex', alignItems: 'center'}}
                          />}
                        </MDBox>
                      </MDBox>
                    ))}
                  </MDBox>
                )
            }
          </MDBox>
        </BasePostCard>
      </MDBox>
    </DashboardLayout>
  )
}
