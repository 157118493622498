import { Box, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDTypography from "components/MDTypography";
import moment from "moment";


export const SupportChatBubble = ({me, message})=>{
  const mine = me.username === message.author
  const bubbleStyle = mine? styles.rightBubble : styles.leftBubble
  return (
    <Box sx={{...styles.message, alignItems: mine?"flex-end":"flex-start"}}>
      <Box  sx={bubbleStyle}>
        <MDTypography variant={"caption"} style={{ display: "inline-block", whiteSpace: "pre-line", width:"90%" }}

                      color={"white"} sx={styles.text}>{message.body}</MDTypography>
      </Box>
      <MDTypography  sx={styles.date}>{moment(message.date_created).format("hh:mm a")}</MDTypography>
    </Box>
  )
}

const styles = {
  message:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
    justifyContent:"flex-end",
    m:1,
    mt:0,
    p:0
  },
  date:{
    fontSize:11,
    mt:0,
    mr:1,
    color:"black",
    fontWeight:400
  },
  text:{
    fontWeight:400,
    fontSize:11,
    color:"white",
  },
  rightBubble:{
    p:1,
    width:'90%',
    background:colors.background.card,
    borderRadius:"8px 0px 8px 8px"
  },
  leftBubble:{
    width:'90%',

    p:1,
    background:colors.primary.main,
    borderRadius:"0px 8px 8px 8px"
  },
}
