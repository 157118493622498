import { Box } from "@mui/material";
import colors from "../../assets/theme/base/colors";
import * as React from "react";
import { BaseModal } from "../../base/BaseModal";
import MDTypography from "../MDTypography";
import { SecondaryButton } from "../SecondaryButton";
import { useNavigate } from "react-router-dom";

export const BlockedUserModal = ({title, open, description, onClose})=>{
  const navigate = useNavigate()

  return (
    <BaseModal
      open={open}
      title={title || 'BLOCKED USER'}
      containerStyles={{width:"660px", height:"316px",  backgroundColor:colors.background.default}}
      onClose={onClose}
    >
      <Box style={styles.body}>
        <MDTypography style={styles.text}>{
          (description && description !== '')
            ? description
            : 'You had blocked that user'
        }</MDTypography>
        <SecondaryButton
          sx={{ ...styles.button }}
          text="CLOSE"
          onClick={onClose}
          padding={0.5}
          width={290}
          />
      </Box>
    </BaseModal>

  )
}



const styles = {
  text:{
    fontSize:16,
    textAlign:"center",
    letterSpacing:"0.1em",
    color:colors.white.main,
    width:"292px",
  },
  button:{
    height:"40px"
  },
  body:{
    height:"180px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
  }
}
