/* dependencies */
import PropTypes from 'prop-types';
import moment from 'moment';

/* MUI components */
import {Box} from '@mui/material';

/* components */
import {BaseModal} from 'base/BaseModal';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {DatePriceEvent} from 'components/DatePriceEventTracker'

/* assets */
import {styles} from './styles';
import avatarexample from 'assets/images/avatar_example.png';
import logo from 'assets/images/logo.png';

export const BaseActionsEventModal = ({
  open,
  onClose,
  title, 
  avatar,
  idolizerName,
  idolPoints,
  isVerified,
  date, 
  timeStart, 
  timeEnd, 
  price,
  children,
}) => {
  const imgInfo = {info: 'idol idol logo'};
  const baseProps = {
    open, 
    onClose, 
    title,
    centerContent: false,
    modalWidth: '45rem',
    marginInline: 0,
    marginBlockStart: 8,
  };
  const infoProps = {
    idolizerName,
    idolPoints,
    isVerified,
    avatar
  };
  const eventProps = {
    date: moment(date).format("D MMM YYYY").toUpperCase(), 
    timeStart: moment(timeStart).format("HH:mm A"), 
    timeEnd: moment(timeEnd).format("HH:mm A"), 
    price
  };
  return (
    <BaseModal {...baseProps}>
      <Box sx={styles.header}>
        <BaseCardIdolInfo {...infoProps} />
        <Box sx={styles.img_container}>
          <img src={logo} alt={imgInfo.info} title={imgInfo.info} style={styles.img}/>
        </Box>
      </Box>
      <Box sx={styles.info_container}>
        <DatePriceEvent {...eventProps} />
        {children}
      </Box>
    </BaseModal>
  )
}

BaseActionsEventModal.propTypes = {
  children: PropTypes.node.isRequired,
}
