import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Box} from '@mui/material';
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {useApi, useIsMobile, useUserStore} from "utils/hooks";
import {IdolizersCard} from "./idolizersCard";
import MeetingsCard from "./meetingsCard";
import {initialCounters, initialCountersIdolizer, initialSummary} from "./reducers";
import {Summary} from "./summary";
import {notifyError} from "services/notification";
import HomeRedIcon from "assets/icons/home_red.svg";
import HeaderIcon from 'assets/icons/people.svg';
import {MobileSummary} from "./mobile/mobileSummary";
import MobileMeetings from "./mobile/mobileMeetings";
import {MobileIdolsCard} from './mobile/idolizers/mobileIdolsCard';
import {MobileSectionHeader} from "components/Mobile/MobileIdolDashboardSectionHeader";
import EventsIcon from "assets/icons/events.svg";
import {NavigationMenu} from "../../../constants";
import {MessagePopups} from "../../../components/MessageModal/MessagePopups";


export const DashboardPage = observer(() => {
  const api = useApi()
  const userStore = useUserStore()
  const [summary, setSummary] = useState(initialSummary)
  const [FilterCounter, setFilterCounter] = useState(initialCounters)
  const [FilterCounterIdolizer, setFilterCounterIdolizer] = useState(initialCountersIdolizer)
  const isMobile = useIsMobile()
  const [modalManager, setModalManager] = useState({ cancelMeeting: false, locationDetails: false })
  const { cancelMeeting, locationDetails } = modalManager
  const loginStore = useUserStore()
  const IsIdol = loginStore.isIdol
  const IsIdolizer = loginStore.isIdolizer
  const isInfluencer = loginStore.isInfluencer
  const [messages, setMessages] = useState([]);
  const [profile, setProfile] = useState(null)

  const getProfileDetail = () => {
    api.getProfileDetail(userStore.id).then(response => {

      if (response.kind === "ok") {
        userStore.setUser(response.data)

        setProfile(response.data)

        setSummary({
          idolPoints: response.data.idol_points,
          idolPay: response.data.current_balance,
          idolizers: response.data.number_of_subscribers
        })

        setFilterCounter({
          all: response.data.number_of_subscribers + response.data.number_of_followers,
          idolizing: response.data.number_of_subscribed,
          idolizers: response.data.number_of_subscribers,
          followers: response.data.number_of_followers
        })

        setMessages(response.data.messages)
      }



    }).catch(reason => {
      notifyError('There is something wrong!')
      console.log(reason)
    })
  }

  const getIdolizers = () => { //TODO: Modify this to bring the content for <IdolizersCard />
    api.getProfileDetail(userStore.id).then(response => {
      if (response.kind === "ok") {
        userStore.setUser(response.data)
      }
    }).catch(reason => {
      notifyError('There is something wrong!')
      console.log(reason)
    })
  }

  useEffect(() => {
    userStore.setMobileActiveRoute("DASHBOARD")
    userStore.setActiveRoute(NavigationMenu.DASHBOARD);
    getProfileDetail()
  }, [])

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={HomeRedIcon}>
        {isMobile ? (
          <Box>
            <MobileSummary data={summary} isInfluencer={isInfluencer} />
            <Box sx={{ marginTop: "28px" }}>
              <MobileSectionHeader Icon={EventsIcon} Title={"Meetings"} />
              <Box sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                <MobileMeetings isWide={false} isIdol={IsIdol} isIdolizer={IsIdolizer} hasPendingMeetings={true} />
              </Box>
            </Box>
            <Box sx={{ marginTop: "40px" }}>
              <MobileSectionHeader ShowLogo={true} Title="Idolizers" Icon={HeaderIcon} />
              <MobileIdolsCard filterCounter={FilterCounter} isInfluencer={isInfluencer} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Summary data={summary} isInfluencer={isInfluencer} />
            <IdolizersCard filterCounter={FilterCounter} title={"Idolizers"} isIdol={IsIdol} isIdolizer={IsIdolizer} isInfluencer={isInfluencer} />
            <MeetingsCard />
          </Box>
        )}
      </DashboardLayout>
      <MessagePopups messagesProps={messages} profile={profile}/>
    </BaseProtectedComponentPage>
  );
})
