import React from "react";
import { styles } from "./styles";
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "../../../components/PrimaryButton";
import IdolMarca from "../../../assets/icons/idolmarca.svg";



const DesktopRow = ({ showAddButton=true, push, isMobile, text, showSaveButton }) => {
  return (
    <Box style={{...styles.footerContainer, marginTop:"20px"}}>
      <Box style={styles.addButtonContainer}>
        {showAddButton  && (
          <Typography sx={styles.addButton}
                      onClick={()=>push({duration:0, discount_percentage:0})} >
            {text}
          </Typography>
        )}
      </Box>
      <Box style={{...styles.rowCenter, gap:"20px"}}>
        {showSaveButton && (
          <Box sx={{...styles.rowCenter, gap:"20px"}}>
            <PrimaryButton
              text="SAVE"
              type={"submit"}
              buttonStyle={styles.buttonStyle}
              textStyles={{ fontSize: "12px" }}
            />
          </Box>
        )}

        <Box component="img" src={IdolMarca} alt="idol-marca"  />
      </Box>
    </Box>
  );
};

const MobileRow = ({ showAddButton=true, push, isMobile, text , showSaveButton}) => {
  return (
    <Box style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
      {showSaveButton && (
        <Box sx={{...styles.rowCenter, p:"10px"}}>
          <PrimaryButton
            text="SAVE"
            type={"submit"}
            buttonStyle={styles.buttonStyle}
            textStyles={{ fontSize: "10px" }}
          />
        </Box>
      )}
      <Box style={styles.footerContainer}>
        <Box style={styles.addButtonContainer}>
          {showAddButton  && (
            <Typography sx={styles.addButton}
                        onClick={()=>push({duration:0, discount_percentage:0})} >
              {text}
            </Typography>
          )}
        </Box>
        <Box style={{...styles.rowCenter, gap:"20px"}}>
          <Box component="img" src={IdolMarca} alt="idol-marca"  />
        </Box>
      </Box>
    </Box>
  );
};



export const SectionRow = ({ showAddButton=true, push, isMobile, text, showSaveButton=true }) => {
  return (isMobile? (
      <MobileRow showAddButton={showAddButton} push={push} text={text} showSaveButton={showSaveButton} />
    ):(
      <DesktopRow showAddButton={showAddButton} push={push} text={text} showSaveButton={showSaveButton} />
    )
  );
};
