import PriceBaseCard from "../../../base/Cards/PriceBaseCard/PriceBaseCard";
import { useApi, useIsMobile } from "../../../utils/hooks";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import IdolMarca from "../../../assets/icons/idolmarca.svg";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { FieldArray, Form, Formik } from "formik";
import { BundlesMonths } from "./constants";
import { ItemRow } from "./ItemRow";
import { notifyError, notifySuccess } from "../../../services/notification";
import { PriceItemSchema } from "./schemas";
import { moneyFormat } from "../../../services/helpers";
import { SectionRow } from "./sectionRow";


export const IdolBundlesSection = ({monthlyCharge})=>{
  const api = useApi()
  const isMobile = useIsMobile()
  const [InitialValues, setInitialValues] = useState(null)

  const getBundles = ()=>{
    api.getBundles().then(response => {
      if (response.kind === "ok") {
        const bundles = []
        for (const obj of response.data.results){
          bundles.push({
            id:obj.id,
            duration:obj.months_of_duration,
            discount_percentage:parseInt(obj.discount_percentage)
          })
        }
        setInitialValues({items:bundles})
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  const removeBundle = ({id, remove, index})=>{
    if(id){
      api.deleteBundle(id).then(response => {
        if (response.kind === "ok") {
          remove(index)
          notifySuccess("Deleted successfully")
        }else{
          notifyError("Error deleting this item")
        }
      }).catch(reason => {
        console.log(reason)
      })
    }else{
      remove(index)
    }
  }

  const saveBundles = (bundles)=>{
    api.saveBundles({bundles}).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Saved successfully")
        getBundles()
      }else{
        notifyError("Error saving this item")
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  useEffect(() => {
    getBundles()
  },[])

  const getFooterText = (item)=>{
    return `Subscription for ${item.duration} months for 
    ${moneyFormat(monthlyCharge * item.duration * (1 - (item.discount_percentage/100)))} total`
  }


  return (
    <PriceBaseCard title='Your Idol Bundles' isMobile={isMobile}>
      {InitialValues && (
        <Formik
          validateOnChange={false}
          initialValues={InitialValues}
          validationSchema={PriceItemSchema}
          onSubmit={async (values) => {
            const items = []
            for (const obj of values.items){
              items.push({
                id: obj.id,
                months_of_duration: obj.duration,
                discount_percentage: obj.discount_percentage
              })
            }
            saveBundles(items)
          }}
        >
          {({ values ,setFieldValue, errors, touched}) => (
            <Form style={styles.form}>
              <Typography sx={styles.subtitle}>
                Offer several months of subscription as a discounted bundle.
              </Typography>
              <Box sx={{ flexGrow: 1 ,display:"flex", flexDirection:"row"}}>
                <FieldArray name={`items`} style={{width:"100%"}}>
                  {( {  remove, push }) => (
                    <Box style={{display:"flex", flexDirection:"column", width:"100%"}}>
                      {values.items.map((item, index) => (
                        <ItemRow durationOptions={BundlesMonths}
                                 footerText={getFooterText(item)}
                                 item={item}
                                 isMobile={isMobile}
                                 touched={touched}
                                 errors={errors}
                                 remove={(index)=>removeBundle({index, id: values.items[index].id, remove})}
                                 onChange={({value, fieldName})=>{
                                   setFieldValue(fieldName, value)
                                 }}
                                 index={index}
                                 key={"item-"+index}/>)
                      )}
                      <SectionRow isMobile={isMobile}
                                  push={push}
                                  showSaveButton={values.items.length > 0}
                                  showAddButton={true}
                                  text={`+ ADD ${values.items.length > 0? "ANOTHER":""} BUNDLE`}/>
                    </Box>
                  )}
                </FieldArray>
              </Box>
            </Form>
          )}

        </Formik>
      )}

    </PriceBaseCard>
  )
}
