/* dependencies */
import PropTypes from 'prop-types';

/* mui components */
import {CardMedia, Typography, Box} from '@mui/material';

/* data */
import {IdolCardData} from './IdolCardRoot';

/* components */
import {BaseIdolCard} from 'base/Cards/IdolCard';
import {PrimaryButton} from "../PrimaryButton";
import {SecondaryButton} from '../SecondaryButton';

/* assets */
import {styles} from './styles';

export const IdolTypeCard = ({type, onClick, description, icon, accessText, selected}) => {
  return (
    <BaseIdolCard cardStyles={styles.card} headerStyles={styles.cardHeader} title={type}>
      <Box sx={styles.img_container}>
        <CardMedia
          component='img'
          image={icon}
          height={80}
          alt='idolType'
        />
      </Box>

      <Box sx={styles.content}>
        <Typography sx={styles.content_text}>
          {description}
        </Typography>
      </Box>

      <Box pb={3} marginX={7}>
        <Box mb={2} onClick={onClick}>
          {selected && (
            <PrimaryButton text={"SELECTED"} />
          ) || (
            <SecondaryButton text='SELECT' />
          )}
        </Box>
        <Box>
          <Typography sx={styles.footer_text}>
            {accessText}
          </Typography>
        </Box>

      </Box>
    </BaseIdolCard>
  )
}

IdolTypeCard.propTypes = {
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  accessText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

IdolTypeCard.defaultProps = {
  type: 'Influencer'.toUpperCase(),
  onClick: () => console.log('do something')
}



