import React, { memo } from "react";
import DashboardLayout from "../../base/LayoutContainers/DashboardLayout";
import { ReportUserForm } from "./blockUserForm";

export const ReportUserDesktop = memo(({ data }) => {
  return (
    <DashboardLayout>
        <ReportUserForm data={data} isMobile={false}/>
    </DashboardLayout>
  );
})
