import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { LayoutNames } from "../../../constants";
import colors from "../../../assets/theme/base/colors";
import { setLayout, useMaterialUIController } from "../../../context";
import { useExploreApp } from "./utils/hooks";
import { ExploreContent } from "./components/ExploreContent";
import { SearchContent } from "./components/SearchContent";
import { ExploreLayout } from "./utils/constants";

export const ExploreMobilePage = ()=>{
  const [controller, dispatch] = useMaterialUIController();
  const {exploreLayout} = useExploreApp()

  useEffect(() => {
    setLayout(dispatch, LayoutNames.general);
  }, []);


  return (
    <Box>
      {
        exploreLayout === ExploreLayout.explore
          ? <ExploreContent />
          : <SearchContent />
      }
    </Box>

  )
}
