import DashboardLayout from "../../../base/LayoutContainers/DashboardLayout";
import {Box, Divider, Grid, Typography} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {BasePostCard} from "../../../base/Cards/PostCard";
import {CardNumberItem} from "../../../components/CardNumberItem";
import {addCommas} from "../../../utils/functions";
import colors from "../../../assets/theme/base/colors";
import {useApi} from "../../../utils/hooks";
import MDTypography from "../../../components/MDTypography";
import {CustomTextField} from "../../../components/TextField";
import moment from "moment";
import {BaseCardIdolInfo} from "../../../base/Cards/InfoCards/IdolizerInfoCard";
import rgba from "../../../assets/theme/functions/rgba";
import {fecha_fmt, moneyFormat} from "../../../services/helpers";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {notifyError, notifySuccess} from "../../../services/notification";
import {ConfirmPaymentModal} from "../../../components/ConfirmPaymentModal";
import MDBox from "../../../components/MDBox";

const columnTitles = [
  {title: "Idol Username", width: 3},
  {title: 'Invoice Date', width: 2},
  {title: 'Invoice Number', width: 1.4},
  {title: 'Amount', width: 2.3},
  {title: 'Status', width: 0.6},
  {title: 'Action', width: 2.7},
]

export const AdminEarningsPage = () => {
  const [totalEarned, setTotalEarned] = useState(0)
  const [Withdrawals, setWithdrawals] = useState([])
  const [CurrentWithdrawId, setCurrentWithdrawId] = useState(null)
  const [earnings, setEarnings] = useState(null)
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState("")
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showAcceptModal, setShowAccepttModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [selectedWithdraw, setSelectedWithdraw] = useState(null);

  const [Page, setPage] = useState(1)
  const [Pages, setPages] = useState(1)

  const api = useApi()

  const getEarnings = () => {
    api.getEarnings().then(value => {
      if (value.kind === "ok") {
        setTotalEarned(value.data.total_balance)
        setEarnings(value.data)
        console.log('data ', value.data)
      }

    }).catch(reason => console.log(reason))
  }

  const getWithdrawals = (page) => {
    setLoading(true)
    setPage(page)
    api.getWithdrawals({search:"", page: page}).then((result) => {
      if (result.kind === "ok") {
        setWithdrawals(result.data.results)
        setPages(result.data.total_pages)
      }
    }).catch(() => {
      notifyError()
    }).finally(() => setLoading(false));
  }

  const payWithdraw = () => {
    setLoading(true)
    api.payWithdraw(CurrentWithdrawId, {token: token}).then((result) => {
      if (result.kind === "ok") {
        notifySuccess(result.response.message)
        getWithdrawals(Page)
        setShowTokenModal(false)
      } else {
        notifyError(result.errors?.detail)
      }
    }).catch((err) => {
      console.log("catch", err)
      notifyError()
    }).finally(() => setLoading(false))
  }

  const acceptWithdraw = () => {
    setLoading(true)
    api.acceptWithdraw(selectedWithdraw).then((result) => {
      if (result.kind === "ok") {
        notifySuccess(result.response.message)
        getWithdrawals(Page)
        setShowTokenModal(true)
      } else {
        notifyError()
      }
    }).catch((err) => {
      notifyError()
    }).finally(() => setLoading(false))
  }

  const rejectWithdraw = () => {
    setLoading(true)
    api.rejectWithdraw(selectedWithdraw).then((result) => {
      if (result.kind === "ok") {
        notifySuccess(result.response.message)
        getWithdrawals(Page)
      }
    }).catch(() => {
      notifyError()
    }).finally(() => setLoading(false))
  }

  const cancelWithdraw = () => {
    setShowRejectModal(false)
    setShowAccepttModal(false)
    setShowAccepttModal(false)
    setShowTokenModal(null)
    setToken("")
  }

  useEffect(() => {
    getWithdrawals(Page)
    getEarnings()
  }, [])

  const renderRow = (row) => {
    return (
      <Grid container my={1} sx={styles.row} key={'id-' + row.id}>
        <Grid item md={3}>
          <BaseCardIdolInfo
            idolizerName={row.idol_username}
            avatar={`${row.image}`}
            isVerified={row.is_validated}
            isIdolizer={false}
            idolPoints={row.idol_points}
          />
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider}/>
        </Grid>

        <Grid item md={1.4} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{fecha_fmt(row.invoice_date, 'MM/DD/YYYY')}</MDTypography>
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider}/>
        </Grid>

        <Grid item md={1.4} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{row.invoice_number ? row.invoice_number : '-'}</MDTypography>
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider}/>
        </Grid>
        <Grid item md={1.1} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{row.status}</MDTypography>
        </Grid>
        <Grid item md={.4}>
          <Divider sx={styles.verticalDivider}/>
        </Grid>
        <Grid item md={1.1} sx={styles.textContainer}>
          <MDTypography sx={styles.text}>{moneyFormat(row.amount)}</MDTypography>
        </Grid>

        <Grid item md={2.4} sx={styles.actionContainer}>
          {row.status === "pending" && (
            <>
              <PrimaryButton
                text="Cashout"
                textStyles={styles.actionButtonText}
                buttonStyle={styles.actionButton}
                ml={1}
                onClick={() => {
                  setShowAccepttModal(row.id)
                  setShowTokenModal(true)
                }}
                disabled={loading}
              />
              <PrimaryButton
                text="Reject"
                textStyles={styles.actionButtonText}
                buttonStyle={styles.actionButton}
                ml={2}
                mr={1}
                onClick={() => {
                  setCurrentWithdrawId(row.id)
                  setSelectedWithdraw(row.id)
                  setShowAccepttModal(true)
                }}
                disabled={loading}
              />
            </>
          )}
        </Grid>
      </Grid>
    )
  }
  
  const loadMoreButton = () => {
    if (Page !== Pages) {
    return <Grid container my={1}>
      <Grid item md={3} />
      <Grid item md={6} sx={{marginTop: '40px'}} display='flex' justifyContent={'center'}>
        <PrimaryButton
          text="LOAD MORE"
          textStyles={styles.actionButtonText}
          buttonStyle={styles.actionButtonLoad}
          loading={loading}
          disabled={loading}
          onClick={() => getWithdrawals(Page + 1)}
        />
      </Grid>
      <Grid item md={3} />
    </Grid>
    }
  }

  return (
    <DashboardLayout isAdmin hideFooter>
      <Box sx={{p: 3}}>
        <BasePostCard
          title='EARNINGS'
          titleStyles={{fontSize: "48px"}}
          titleContainerStyles={{height: "73px!important", fontWeight: "400"}}
          headerControlsStyle={{height: "40px", mt: "8px"}}
          icon={null}
        >
          <Box style={styles.container}>
            <CardNumberItem
              content={`$ ${addCommas(totalEarned)}`}
              secondaryContent='Total Amount Earned'
              containerStyles={styles.cardContainer}
            />
          </Box>

          {/*<Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", mt:3}}>*/}
          {/*  <MDTypography fontSize={16} color={"white"}>Minimum amount for a weekly payment</MDTypography>*/}
          {/*  <Box sx={{width:"400px"}}>*/}

          {/*  </Box>*/}
          {/*</Box>*/}

          <ConfirmPaymentModal
            disabled={loading}
            open={showRejectModal}
            message={`Are you sure you want to reject this withdraw request?`}
            confirmAction={rejectWithdraw}
            confirmLabel={"REJECT"}
            cancelAction={() => cancelWithdraw()}
          />

          <ConfirmPaymentModal
            disabled={loading}
            open={showAcceptModal}
            message={`Are you sure you want to confirm this withdraw request?`}
            confirmAction={acceptWithdraw}
            confirmLabel={"CONFIRM"}
            cancelAction={() => cancelWithdraw()}
          />

          <ConfirmPaymentModal
            disabled={loading}
            open={showTokenModal}
            message={`ENTER RECEIVED TOKEN`}
            confirmAction={payWithdraw}
            confirmLabel={"SEND"}
            cancelAction={() => cancelWithdraw()}
            children={
              <MDBox>
                <CustomTextField
                  onChage={(e) => {
                    setToken(e.target.value)
                  }}
                  sx={{width: "400px", marginLeft: 'auto', marginRight: 'auto', marginTop: 5}}
                />
              </MDBox>
            }
          />

          <MDTypography variant="h1" sx={[styles.title]} color={"white"} mt={3}>
            USER PAYMENT MANAGEMENT
          </MDTypography>

          <Grid container mt={2}>
            {columnTitles.map((value) => (
              <Fragment>
                <Grid item md={value.width}>
                  <MDTypography textAlign={"center"} fontSize={14} color={"white"}>{value.title}</MDTypography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
          {Withdrawals.map((item) => renderRow(item))}
          {loadMoreButton()}
        </BasePostCard>
      </Box>
    </DashboardLayout>
  )
}

const styles = {
  container: {
    backgroundColor: colors.card.header,
    height: "174px",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "30px",
    paddingRight: "30px",
    marginTop: "20px"
  },
  verticalDivider: {
    width: "4px",
    height: "60%",
    background: rgba(colors.background.default, 1)
  },
  divider: {
    height: "98%",
    width: "5px",
    borderRadius: "8px",
    backgroundColor: `${colors.verticalDivider} !important`,
    border: "0px green solid",
  },
  cardContainer: {
    margin: '0 auto',
  },
  title: {
    color: colors.white.main,
    fontWeight: 'lighter',
    textDecoration: "uppercase",
    fontSize: "36px",
    borderBottom: `2px solid ${colors.primary.main}`,
  },
  row: {
    background: colors.card.header,
    borderRadius: "5px"
  },
  text: {
    color: rgba("white", .6),
    fontSize: 16,
    fontWeight: "400"
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  actionContainer: {
    background: colors.background.default,
    borderRadius: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  actionButtonLoad: {
    borderRadius: "10px",
    width: "70%",
    height: "32px"
  },
  actionButton: {
    borderRadius: "10px",
    width: "122px",
    height: "32px"
  },
  actionButtonText: {
    fontSize: 14,
    fontWeight: 400
  }
}
