import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {CustomFormikTextField} from "../../../components/TextField";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {BasicCheckbox} from "../../../components/BasicCheckbox";
import {FormHelperText, Stack, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CustomAutocompleteFormik} from "../../../components/AutocompleteFormik";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useOnboardingStore} from "../../../utils/hooks";
import {getFormikValidationFields, getFormikValidationBooleanFields, useStores} from "../../../models";
import {notifyError} from "../../../services/notification";
import {showAllErrors} from "../../../services/helpers";
import {UserTypes} from "../../../constants";
import {IdolizerPaths} from "../../../paths/idolizerPaths";
import {guestRoutes} from "../../../routes/guestRoutes";
import {GuestPaths} from "../../../paths/guestPaths";

const {white} = colors;


const ForgotPassword = observer(() => {
  const onboardingStore = useOnboardingStore()
  const {loginStore} = useStores()
  const api = onboardingStore.environment.api
  const navigate = useNavigate()
  const {state} = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userFields = [
    'email',
  ];
  const initialValues = {}
  userFields.forEach(field => initialValues[field] = "");

  const sendData = (values) => {
    api.resetPassword(values)
      .then(res => {
        if (res.kind === 'ok') {
          navigate(GuestPaths.tokenPassword);
        } else if (res.kind === 'bad-data') {
          showAllErrors(res?.errors)
        } else {
          notifyError('Something wrong happen!')
        }
      })
      .catch(e => {
        notifyError('Something wrong happen!')
        console.log(e)
      })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getFormikValidationFields(userFields),
    onSubmit: (values) => {
      loginStore.setEmailPasswordToken(values.email);
      sendData(values)
    },
  });

  return (
    <OnboardingLayout titleBold={"FORGOT"} titleNormal={"PASSWORD?"} backTo={(state && state.fromURL) || "/login/"}>
      <MDTypography fontWeight={"light"} sx={isMobile ? styles.subtitleMobile : styles.subtitle}>
       Please enter your email address below and we will send you a token to reset your password.
      </MDTypography>
      <MDBox mt={isMobile ? 4 : 2} width={isMobile ? "100%" : "400px"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="email" formik={formik}/>            
          </Stack>
          <MDBox mb={4} mt={4}>
            <PrimaryButton type={"submit"} text={"Submit"}/>
          </MDBox>
        </form>
      </MDBox>     
    </OnboardingLayout>
  );
});

const styles = {
  defaultText: {
    color: white.main,
    fontSize: 16,
  },
  link: {
    textDecoration: "underline",
    textAlign: "right",
  },
  subtitle:{
    fontSize:14,
    letterSpacing:"0.1em",
    width:"480px",
    color:colors.white.main
  },
  subtitleMobile:{
    fontSize:12,
    letterSpacing:"0.1em",
    width:"100%",
    color:colors.white.main
  },
  footer: {
    marginBottom: "20px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "auto"
  }
}

export default ForgotPassword;
