import MDBox from "../../../../components/MDBox";
import colors from "assets/theme/base/colors"
import {CardNumberItem} from "../../../../components/CardNumberItem";
import {Box} from "@mui/material";
import {useUserStore} from "../../../../utils/hooks";
import {memo, useEffect, useMemo, useState} from "react";
import {initialSummary} from "../reducers";
import {addCommas} from "../../../../utils/functions";

export const MobileSummary = memo((props)=>{
  const userStore = useUserStore()
  const {data, isInfluencer} = props

  return (
    <Box>
      {(userStore.isIdol && !isInfluencer) && (
        <Box style={styles.container}>
          <CardNumberItem
            isMobile={true}
            content={`$${addCommas(data.idolPay)}`}
            secondaryContent="idolpay"
            containerStyles={styles.card_number_container}
          />
        </Box>
      )}

      <Box style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <Box style={{ ...styles.container, width: (!userStore.isIdol || isInfluencer) ? '100%' : '50%', marginTop: "10px" }}>
          <CardNumberItem
            isMobile={true}
            content={`${addCommas(data.idolPoints || 0)}`}
            secondaryContent="idolpoints"
            containerStyles={styles.card_number_container}
          />
        </Box>

        {(userStore.isIdol && !isInfluencer) && (
          <Box style={{ ...styles.container, width: "50%", marginTop: "10px" }}>
            <CardNumberItem
              isMobile={true}
              content={`${addCommas(data.idolizers)}`}
              secondaryContent="idolizers"
              containerStyles={styles.card_number_container}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
})

const styles = {
  container:{
    backgroundColor:colors.background.container,
    height:"113px",
    marginTop:"0px",
    borderRadius:"10px",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between",
  },
  divider:{
    height: "98%",
    width:"5px",
    borderRadius:"8px",
    backgroundColor:`${colors.verticalDivider} !important`,
    border:"0px green solid",
  },
  card_number_container: {
    margin: '0 auto'
  }
}
