import React from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
// @ts-ignore
import EndCall from '../../images/videocall_end.png';

import {Button} from '@material-ui/core';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: 'transparent',
      color: 'transparent',
      '&:hover': {
        filter: 'brightness(90%)',
        background: 'transparent',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Button onClick={() => room!.disconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>
      <img src={EndCall} style={{ height: '48px' }}/>
    </Button>
  );
}
