import * as Yup from "yup";
import {types} from "mobx-state-tree";

/**
 * Application-wide field definitions
 * This includes fields managed for backend API and html forms
 * Each key on Fields object has properties that are configured
 * once, so, errors can be easily handed.
 * The key of Fields object is the html formik field name. If
 * the name on the store is different, storeFieldName is
 * available.
 */

const MeasurementObjectType = types
  .model("MeasurementUnitType")
  .props({
    id: types.number,
    measurementUnit: types.string,
    isImperial: types.boolean,
    isMetric: types.boolean,
  })

const SimpleObjectIdNameType = types
  .model("SimpleObjectType")
  .props({id: types.number, name: types.string})

const ObjectIdNameCodenameType = types
  .model("ObjectCodenameType")
  .props({id: types.number, name: types.string, codename: types.string})

const FileBase64Type = types
  .model("FileBase64Type")
  .props({fileName: types.string, file: types.string})

const FormikSimpleObjectIdName = {id: Yup.number(), name: Yup.string()}

const FormikSimpleObjectIdNameCodename = {id: Yup.number(), name: Yup.string(), codename: Yup.string()}

const FormikFileBase64 = {fileName: Yup.string(), file: Yup.string()}

const defaultFieldRequiredMessage = 'This field is required'

/**
 * Store fields
 */

const StoreFieldsOnboarding = {
  id: {type: types.maybeNull(types.number)},
  last_url_visted: {type: types.maybeNull(types.string)},
  last_time_visited: {type: types.maybeNull(types.string)},
  email: {type: types.maybeNull(types.string)},
  username: {type: types.maybeNull(types.string)},
  idol_id_name: {type: types.maybeNull(types.string)},
  password: {type: types.maybeNull(types.string)},
  repeat_password: {type: types.maybeNull(types.string)},
  user_type: {type: types.maybeNull(types.string)},
  user_type_id: {type: types.maybeNull(types.number)},
  user_type_name: {type: types.maybeNull(types.string)},
  user_type_codename: {type: types.maybeNull(types.string)},
  gender_of_interest: {type: types.maybeNull(ObjectIdNameCodenameType)},
  gender_of_interest_id: {type: types.maybeNull(types.number)},
  gender_of_interest_name: {type: types.maybeNull(types.string)},
  gender_of_interest_codename: {type: types.maybeNull(types.string)},
  time_zone_id: {type: types.maybeNull(types.number)},
  time_zone_name: {type: types.maybeNull(types.string)},
  time_zone_codename: {type: types.maybeNull(types.string)},
  phone_number: {type: types.maybeNull(types.string)},
  image: {type: types.maybeNull(FileBase64Type)},
  thumbnail: {type: types.maybeNull(types.string)},
  description: {type: types.maybeNull(types.string)},
  biography: {type: types.maybeNull(types.string)},
  birth: {type: types.maybeNull(types.string)},
  gender: {type: types.maybeNull(ObjectIdNameCodenameType)},
  address: {type: types.maybeNull(types.string)},
  is_confirmed: {type: types.maybeNull(types.boolean)},
  is_validated: {type: types.maybeNull(types.boolean)},
  is_active: {type: types.maybeNull(types.boolean)},
  is_declined: {type: types.maybeNull(types.boolean)},
  is_suspended: {type: types.maybeNull(types.boolean)},
  is_pending: {type: types.maybeNull(types.boolean)},
  number_of_followers: {type: types.maybeNull(types.number)},
  number_of_following: {type: types.maybeNull(types.number)},
  number_of_subscribers: {type: types.maybeNull(types.number)},
  number_of_subscribed: {type: types.maybeNull(types.number)},
  number_of_posts: {type: types.maybeNull(types.number)},
  number_of_image_posts: {type: types.maybeNull(types.number)},
  number_of_video_posts: {type: types.maybeNull(types.number)},
  number_of_likes: {type: types.maybeNull(types.number)},
  number_of_tips: {type: types.maybeNull(types.number)},
  number_of_idol_points: {type: types.maybeNull(types.number)},
  current_balance: {type: types.maybeNull(types.number)},
  is_followed: {type: types.maybeNull(types.boolean)},
  is_subscribed: {type: types.maybeNull(types.boolean)},
  is_deleted: {type: types.maybeNull(types.boolean)},
  referral_code: {type: types.maybeNull(types.string)},
  is_staff: {type: types.maybeNull(types.boolean)},
  date_joined: {type: types.maybeNull(types.string)},
  is_created_by_social_account: {type: types.maybeNull(types.boolean)},
  vendor_id: {type: types.maybeNull(types.string)},
  idol_type: {type: types.maybeNull(types.number)},
  tag_1: {type: types.maybeNull(types.string)},
  tag_2: {type: types.maybeNull(types.string)},
  tag_3: {type: types.maybeNull(types.string)},
  age: {type: types.maybeNull(types.number)},
  height: {type: types.maybeNull(types.number)},
  bust: {type: types.maybeNull(types.number)},
  waist: {type: types.maybeNull(types.number)},
  hips: {type: types.maybeNull(types.number)},
  shoe_size: {type: types.maybeNull(types.number)},
  measurement_type: {type: types.maybeNull(types.number)},
  location: {type: types.maybeNull(types.string)},
  language: {type: types.maybeNull(types.array(SimpleObjectIdNameType))},
  ethnicity: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_first_name: {type: types.maybeNull(types.string)},
  inc_middle_name: {type: types.maybeNull(types.string)},
  inc_last_name: {type: types.maybeNull(types.string)},
  inc_date_of_birth: {type: types.maybeNull(types.string)},
  inc_address_line_1: {type: types.maybeNull(types.string)},
  inc_address_line_2: {type: types.maybeNull(types.string)},
  inc_country: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_state: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_city: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_zip_code: {type: types.maybeNull(types.string)},
  inc_phone_number: {type: types.maybeNull(types.string)},
  inc_incorporated: {type: types.maybeNull(types.boolean)},
  bank_name: {type: types.maybeNull(types.string)},
  bank_account_number: {type: types.maybeNull(types.string)},
  bank_routing_number: {type: types.maybeNull(types.string)},
  tax_social_security: {type: types.maybeNull(types.string)},
  tax_w8_w9_file: {type: types.maybeNull(FileBase64Type)},
  tax_article_of_incorporation: {type: types.maybeNull(FileBase64Type)},
  tax_company_name: {type: types.maybeNull(types.string)},
  tax_id: {type: types.maybeNull(types.string)},
  tax_address_line_1: {type: types.maybeNull(types.string)},
  tax_address_line_2: {type: types.maybeNull(types.string)},
  tax_country: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_state: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_city: {type: types.maybeNull(SimpleObjectIdNameType)},
  incorporated: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_zip_code: {type: types.maybeNull(types.string)},
  tax_phone_number: {type: types.maybeNull(types.string)},
  verify_consent_form: {type: types.maybeNull(FileBase64Type)},
  verify_gov_id: {type: types.maybeNull(FileBase64Type)},
  verify_twitter_username: {type: types.maybeNull(types.string)},
  termsAndConditions: {type: types.maybeNull(types.boolean)},
  adult: {type: types.maybeNull(types.boolean)},
  token: {type: types.maybeNull(types.string)},
  activeRoute: {type: types.maybeNull(types.string)},
  mobileActiveRoute: {type: types.maybeNull(types.string)},
  meeting: {type: types.maybeNull(types.string)},
  is_host: {type: types.maybeNull(types.boolean)},
}

const StoreFields = {
  id: {type: types.maybeNull(types.number)},
  last_url_visted: {type: types.maybeNull(types.string)},
  last_time_visited: {type: types.maybeNull(types.string)},
  email: {type: types.maybeNull(types.string)},
  username: {type: types.maybeNull(types.string)},
  idol_id_name: {type: types.maybeNull(types.string)},
  password: {type: types.maybeNull(types.string)},
  repeat_password: {type: types.maybeNull(types.string)},
  user_type: {type: types.maybeNull(types.string)},
  user_type_id: {type: types.maybeNull(types.number)},
  user_type_name: {type: types.maybeNull(types.string)},
  user_type_codename: {type: types.maybeNull(types.string)},
  gender_of_interest: {type: types.maybeNull(ObjectIdNameCodenameType)},
  gender_of_interest_id: {type: types.maybeNull(types.number)},
  gender_of_interest_name: {type: types.maybeNull(types.string)},
  gender_of_interest_codename: {type: types.maybeNull(types.string)},
  time_zone_id: {type: types.maybeNull(types.number)},
  time_zone_name: {type: types.maybeNull(types.string)},
  time_zone_codename: {type: types.maybeNull(types.string)},
  phone_number: {type: types.maybeNull(types.string)},
  image: {type: types.maybeNull(types.string)},
  imageVersion: {type: types.maybeNull(types.number)},
  thumbnail: {type: types.maybeNull(types.string)},
  description: {type: types.maybeNull(types.string)},
  biography: {type: types.maybeNull(types.string)},
  birth: {type: types.maybeNull(types.string)},
  gender: {type: types.maybeNull(ObjectIdNameCodenameType)},
  address: {type: types.maybeNull(types.string)},
  is_confirmed: {type: types.maybeNull(types.boolean)},
  is_validated: {type: types.maybeNull(types.boolean)},
  is_active: {type: types.maybeNull(types.boolean)},
  is_declined: {type: types.maybeNull(types.boolean)},
  is_suspended: {type: types.maybeNull(types.boolean)},
  is_pending: {type: types.maybeNull(types.boolean)},
  number_of_followers: {type: types.maybeNull(types.number)},
  number_of_following: {type: types.maybeNull(types.number)},
  number_of_subscribers: {type: types.maybeNull(types.number)},
  number_of_subscribed: {type: types.maybeNull(types.number)},
  number_of_posts: {type: types.maybeNull(types.number)},
  number_of_image_posts: {type: types.maybeNull(types.number)},
  number_of_video_posts: {type: types.maybeNull(types.number)},
  number_of_likes: {type: types.maybeNull(types.number)},
  number_of_tips: {type: types.maybeNull(types.number)},
  number_of_idol_points: {type: types.maybeNull(types.number)},
  current_balance: {type: types.maybeNull(types.number)},
  is_followed: {type: types.maybeNull(types.boolean)},
  is_subscribed: {type: types.maybeNull(types.boolean)},
  is_deleted: {type: types.maybeNull(types.boolean)},
  referral_code: {type: types.maybeNull(types.string)},
  is_staff: {type: types.maybeNull(types.boolean)},
  date_joined: {type: types.maybeNull(types.string)},
  is_created_by_social_account: {type: types.maybeNull(types.boolean)},
  vendor_id: {type: types.maybeNull(types.string)},
  idol_type: {type: types.maybeNull(types.number)},
  tag_1: {type: types.maybeNull(types.string)},
  tag_2: {type: types.maybeNull(types.string)},
  tag_3: {type: types.maybeNull(types.string)},
  age: {type: types.maybeNull(types.number)},
  height: {type: types.maybeNull(types.number)},
  bust: {type: types.maybeNull(types.number)},
  waist: {type: types.maybeNull(types.number)},
  hips: {type: types.maybeNull(types.number)},
  shoe_size: {type: types.maybeNull(types.number)},
  measurement_type: {type: types.maybeNull(types.number)},
  location: {type: types.maybeNull(types.string)},
  language: {type: types.maybeNull(types.array(SimpleObjectIdNameType))},
  ethnicity: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_first_name: {type: types.maybeNull(types.string)},
  inc_middle_name: {type: types.maybeNull(types.string)},
  inc_last_name: {type: types.maybeNull(types.string)},
  inc_date_of_birth: {type: types.maybeNull(types.string)},
  inc_address_line_1: {type: types.maybeNull(types.string)},
  inc_address_line_2: {type: types.maybeNull(types.string)},
  inc_country: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_state: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_city: {type: types.maybeNull(SimpleObjectIdNameType)},
  inc_zip_code: {type: types.maybeNull(types.string)},
  inc_phone_number: {type: types.maybeNull(types.string)},
  inc_incorporated: {type: types.maybeNull(types.boolean)},
  bank_name: {type: types.maybeNull(types.string)},
  bank_account_number: {type: types.maybeNull(types.string)},
  bank_routing_number: {type: types.maybeNull(types.string)},
  tax_social_security: {type: types.maybeNull(types.string)},
  tax_w8_w9_file: {type: types.maybeNull(types.string)},
  tax_article_of_incorporation: {type: types.maybeNull(types.string)},
  tax_company_name: {type: types.maybeNull(types.string)},
  tax_id: {type: types.maybeNull(types.string)},
  tax_address_line_1: {type: types.maybeNull(types.string)},
  tax_address_line_2: {type: types.maybeNull(types.string)},
  tax_country: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_state: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_city: {type: types.maybeNull(SimpleObjectIdNameType)},
  incorporated: {type: types.maybeNull(SimpleObjectIdNameType)},
  tax_zip_code: {type: types.maybeNull(types.string)},
  tax_phone_number: {type: types.maybeNull(types.string)},
  verify_consent_form: {type: types.maybeNull(types.string)},
  verify_gov_id: {type: types.maybeNull(types.string)},
  verify_twitter_username: {type: types.maybeNull(types.string)},
  termsAndConditions: {type: types.maybeNull(types.boolean)},
  adult: {type: types.maybeNull(types.boolean)},
  token: {type: types.maybeNull(types.string)},
  activeRoute: {type: types.maybeNull(types.string)},
  mobileActiveRoute: {type: types.maybeNull(types.string)},
  meeting: {type: types.maybeNull(types.string)},
  is_host: {type: types.maybeNull(types.boolean)},
}

const FormikFields = {
  measurement_type: {
    placeholder: '',
    type: 'number',
    label: '',
    storeField: 'measurement_type',
    validation: Yup.number().nullable(),
    initialValue: null,
  },
  idol_type: {
    placeholder: 'Idol Type',
    type: 'number',
    label: '',
    storeField: 'idol_type',
    validation: Yup.number().nullable(),
    initialValue: null,
  },
  email: {
    placeholder: 'Enter your email',
    type: 'text',
    label: 'Email',
    storeField: 'email',
    validation: Yup.string()
      .email('Enter a valid email')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  username: {
    placeholder: 'Your unchangeable username',
    type: 'text',
    label: 'Display Name',
    storeField: 'username',
    validation: Yup.string()
        .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  token: {
    placeholder: '',
    type: 'text',
    label: 'Token',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  password: {
    placeholder: 'Type a strong password',
    type: 'password',
    label: 'Password',
    storeField: 'password',
    validation: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  old_password: {
    placeholder: 'Type the old password',
    type: 'password',
    label: 'Password',
    storeField: 'password',
    validation: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  new_password: {
    placeholder: 'Type a strong password',
    type: 'password',
    label: 'New Password',
    storeField: 'password',
    validation: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  confirm_new_password: {
    placeholder: 'Type a strong password',
    type: 'password',
    label: 'Repeat New Password',
    storeField: 'password',
    validation: Yup.string()
      .min(8, 'Password should be of minimum 8 characters length')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  repeat_password: {
    placeholder: 'Repeat your password',
    type: 'password',
    label: 'Confirm Password',
    storeField: 'repeat_password',
    validation: Yup.string()
      .required('You need to confirm your password').nullable()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    initialValue: null,
  },
  gender_of_interest: {
    placeholder: '',
    type: 'text',
    label: 'I am interested in',
    storeField: 'gender_of_interest',
    validation: Yup.object().shape(FormikSimpleObjectIdNameCodename)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  image: {
    placeholder: '',
    type: 'text',
    label: 'Upload Photo',
    storeField: 'image',
    validation: Yup.object().shape(FormikFileBase64)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  description: {
    placeholder: '',
    type: 'text',
    label: 'Description',
    storeField: 'description',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage),
    initialValue: null,
  },
  biography: {
    placeholder: 'Tell us about you',
    type: 'text',
    label: 'Bio',
    storeField: 'biography',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  gender: {
    placeholder: '',
    type: 'SimpleObjectType',
    label: 'Gender',
    storeField: 'gender',
    validation: Yup.object().shape(FormikSimpleObjectIdNameCodename)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  is_confirmed: {
    placeholder: '',
    type: 'text',
    label: '',
    storeField: 'is_confirmed',
    validation: Yup.boolean()
      .oneOf([true], 'You must confirm'),
    initialValue: false,
  },
  idol_id_name: {
    placeholder: 'This is your customizable URL identifier',
    type: 'text',
    label: 'Idol ID Name',
    storeField: 'idol_id_name',
    validation: Yup.string()
      .min(5, 'Your ID name should be of minimum 5 characters length')
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tag_1: {
    placeholder: 'TAG 1',
    type: 'text',
    storeField: 'tag_1',
    validation: Yup.string()
      .max(12, 'This tag must have a maximum length of 12 characters.').nullable(),
    initialValue: null,
  },
  tag_2: {
    placeholder: 'TAG 2',
    type: 'text',
    storeField: 'tag_2',
    validation: Yup.string()
      .max(12, 'This tag must have a maximum length of 12 characters.').nullable(),
    initialValue: null,
  },
  tag_3: {
    placeholder: 'TAG 3',
    type: 'text',
    storeField: 'tag_3',
    validation: Yup.string()
      .max(12, 'This tag must have a maximum length of 12 characters.').nullable(),
    initialValue: null,
  },
  age: {
    placeholder: 'Stage age',
    type: 'number',
    min: 0,
    label: 'Age',
    storeField: 'age',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  height: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 500,
    label: 'Height',
    storeField: 'height',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  height_ft: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 10,
    label: 'Height (feet)',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  height_in: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 11.99,
    label: 'Height (inches)',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  bust: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 500,
    label: 'Chest',
    storeField: 'bust',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  waist: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 500,
    label: 'Waist',
    storeField: 'waist',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  hips: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 500,
    label: 'Hips',
    storeField: 'hips',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  shoe_size: {
    placeholder: '',
    type: 'number',
    min: 0,
    max: 80,
    label: 'Shoe Size',
    storeField: 'shoe_size',
    validation: Yup.number()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  location: {
    placeholder: '',
    type: 'text',
    label: 'Location',
    storeField: 'location',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  language: {
    placeholder: '',
    type: 'text',
    label: 'Languages',
    storeField: 'language',
    validation: Yup.array().of(Yup.object().shape(FormikSimpleObjectIdName))
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  ethnicity: {
    placeholder: '',
    type: 'text',
    label: 'Ethnicity',
    storeField: 'ethnicity',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  termsAndConditions: {
    placeholder: '',
    type: 'text',
    label: 'I accept the Terms & Conditions',
    storeField: 'termsAndConditions',
    validation: Yup.bool().required(defaultFieldRequiredMessage),
    initialValue: null,
  },
  adult: {
    placeholder: '',
    type: 'text',
    label: 'I am 18+',
    storeField: 'adult',
    validation: Yup.bool().required(defaultFieldRequiredMessage),
    initialValue: null,
  },
  inc_first_name: {
    placeholder: 'Enter your first name',
    type: 'text',
    label: 'First Name',
    storeField: 'inc_first_name',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_middle_name: {
    placeholder: 'Enter your middle name',
    type: 'text',
    label: 'Middle Name',
    storeField: 'inc_middle_name',
    validation: Yup.string().nullable(),
    initialValue: null,
  },
  inc_last_name: {
    placeholder: 'Enter your last name',
    type: 'text',
    label: 'Last Name',
    storeField: 'inc_last_name',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_date_of_birth: {
    placeholder: 'MM/DD/YYYY',
    type: 'text',
    label: 'Date of birth',
    storeField: 'inc_date_of_birth',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_address_line_1: {
    placeholder: 'Enter your Address 1',
    type: 'text',
    label: 'Address Line 1',
    storeField: 'inc_address_line_1',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_address_line_2: {
    placeholder: 'Enter your Address 2',
    type: 'text',
    label: 'Address Line 2',
    storeField: 'inc_address_line_2',
    validation: Yup.string().nullable(),
    initialValue: null,
  },
  inc_country: {
    placeholder: '',
    type: 'text',
    label: 'Country',
    storeField: 'inc_country',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_state: {
    placeholder: '',
    type: 'text',
    label: 'State',
    storeField: 'inc_state',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  city: {
    placeholder: '',
    type: 'text',
    label: 'City',
    storeField: 'city',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_city: {
    placeholder: '',
    type: 'text',
    label: 'City',
    storeField: 'inc_city',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_zip_code: {
    placeholder: '',
    type: 'text',
    label: 'ZIP',
    storeField: 'inc_zip_code',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_phone_number: {
    placeholder: 'Enter phone number',
    type: 'text',
    label: 'Phone number',
    storeField: 'inc_phone_number',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  inc_incorporated: {
    placeholder: '',
    type: 'text',
    label: 'Are You Incorporated?',
    storeField: 'inc_incorporated',
    validation: Yup.bool(),
    initialValue: false,
  },
  bank_name: {
    placeholder: 'Enter your bank name',
    type: 'text',
    label: 'Bank Name',
    storeField: 'bank_name',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  bank_account_number: {
    placeholder: 'Enter your account number',
    type: 'text',
    label: 'Account Number',
    storeField: 'bank_account_number',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  bank_routing_number: {
    placeholder: 'Enter your routing number',
    type: 'text',
    label: 'Routing Number',
    storeField: 'bank_routing_number',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_social_security: {
    placeholder: '',
    type: 'text',
    label: 'Social Security',
    storeField: 'tax_social_security',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_w8_w9_file: {
    placeholder: 'Uploaded W-8 or W-9 form',
    type: 'text',
    label: 'Click here to drop files or upload your W-8 or W-9 form',
    storeField: 'tax_w8_w9_file',
    validation: Yup.object().shape(FormikFileBase64)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_article_of_incorporation: {
    placeholder: 'Uploaded Articles of Incorporation',
    type: 'text',
    label: 'Click here to drop files or upload your Articles of Incorporation',
    storeField: 'tax_article_of_incorporation',
    validation: Yup.object().shape(FormikFileBase64)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_company_name: {
    placeholder: '',
    type: 'text',
    label: 'Company Name',
    storeField: 'tax_company_name',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_id: {
    placeholder: '',
    type: 'text',
    label: 'Tax ID',
    storeField: 'tax_id',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_address_line_1: {
    placeholder: '',
    type: 'text',
    label: 'Address Line 1',
    storeField: 'tax_address_line_1',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_address_line_2: {
    placeholder: '',
    type: 'text',
    label: 'Address Line 2',
    storeField: 'tax_address_line_2',
    validation: Yup.string().nullable(),
    initialValue: null,
  },
  tax_country: {
    placeholder: '',
    type: 'text',
    label: 'Country',
    storeField: 'tax_country',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_state: {
    placeholder: '',
    type: 'text',
    label: 'State',
    storeField: 'tax_state',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_city: {
    placeholder: '',
    type: 'text',
    label: 'City',
    storeField: 'tax_city',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  incorporated: {
    placeholder: '',
    type: 'text',
    label: 'Are you incorporated?',
    storeField: 'incorporated',
    validation: Yup.object().shape(FormikSimpleObjectIdName)
      .nullable(),
    initialValue: null,
  },
  tax_zip_code: {
    placeholder: '',
    type: 'text',
    label: 'ZIP',
    storeField: 'tax_zip_code',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  tax_phone_number: {
    placeholder: 'Enter phone number',
    type: 'text',
    label: 'Phone number',
    storeField: 'tax_phone_number',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  verify_gov_id: {
    placeholder: '',
    type: 'text',
    label: 'Click here to drop files or upload your Issued GOV ID ',
    storeField: 'verify_gov_id',
    validation: Yup.object().shape(FormikFileBase64)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  verify_consent_form: {
    placeholder: '',
    type: 'text',
    label: 'Click here to drop files or upload your Consent Form',
    storeField: 'verify_consent_form',
    validation: Yup.object().shape(FormikFileBase64)
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  verify_twitter_username: {
    placeholder: '',
    type: 'text',
    label: 'Twitter username',
    storeField: 'verify_twitter_username',
    validation: Yup.string()
      .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },

  of_username: {
    placeholder: 'Your onlyfans username',
    type: 'text',
    label: 'Display Name',
    storeField: 'username',
    validation: Yup.string()
        .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  of_email: {
    placeholder: 'Enter onlyfans account email',
    type: 'text',
    label: 'Email',
    storeField: 'email',
    validation: Yup.string()
        .email('Enter a valid email')
        .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  of_password: {
    placeholder: 'Type your onlyfans account password',
    type: 'password',
    label: 'Password',
    storeField: 'password',
    validation: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required(defaultFieldRequiredMessage).nullable(),
    initialValue: null,
  },
  professional_phone_number: {
    placeholder: 'Professional Phone Number',
    type: 'text',
    label: 'Professional Phone Number',
    storeField: 'professional_phone_number',
    validation: Yup.string(),
    initialValue: null,
  },
  phone_number: {
    placeholder: 'Phone Number',
    type: 'text',
    label: 'Phone Number',
    storeField: 'phone_number',
    validation: Yup.string(),
    initialValue: null,
  },
  address: {
    placeholder: 'Address',
    type: 'text',
    label: 'Address',
    storeField: 'address',
    validation: Yup.string(),
    initialValue: null,
  },
  linkedin_link: {
    placeholder: 'Linkedin link',
    type: 'text',
    label: 'Linkedin link',
    storeField: 'linkedin_link',
    validation: Yup.string(),
    initialValue: null,
  },
  job_role: {
    placeholder: 'Job Role',
    type: 'text',
    label: 'Job Role',
    storeField: 'job_role',
    validation: Yup.string(),
    initialValue: null,
  },
  employer: {
    placeholder: 'Employer',
    type: 'text',
    label: 'Employer',
    storeField: 'employer',
    validation: Yup.string(),
    initialValue: null,
  },
  professional_email: {
    placeholder: 'Professional email',
    type: 'email',
    label: 'Professional Email',
    storeField: 'professional_email',
    validation: Yup.string(),
    initialValue: null,
  },
}

export {
  FormikFields,
  StoreFields,
  StoreFieldsOnboarding,
  SimpleObjectIdNameType,
  ObjectIdNameCodenameType,
  MeasurementObjectType,
  FormikSimpleObjectIdName
}
