import React, { useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box } from "@mui/material";
import MDBox from "../../components/MDBox";
import BackIcon from "../../assets/icons/chevron_back.svg";
import MDTypography from "../../components/MDTypography";
import { CustomTextField as TextField } from "../../components/TextField";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../../components/SecondaryButton";
import { stylesDesktop, stylesMobile } from "./styles";
import { useApi } from "../../utils/hooks";
import { notifyError, notifySuccess } from "../../services/notification";
import { observer } from "mobx-react";
import { UnauthenticatedPaths } from "../../paths/paths";

const requiredMessage = "This field is required"
const validationSchema = Yup.object({
  name: Yup
    .string()
    .required(requiredMessage),
  email: Yup
    .string()
    .email()
    .required(requiredMessage),
  username: Yup
    .string()
    .required(requiredMessage),
  url: Yup
    .string()
    .required(requiredMessage),
  description: Yup
    .string()
    .required(requiredMessage),

});

export const ReportUserForm = observer(({data, isMobile})=>{
  const navigate = useNavigate()
  const initialValues = {...data, url:"", description:""}
  const styles = useMemo(() => {
    return isMobile? stylesMobile : stylesDesktop
  },[isMobile])
  const api = useApi()

  const report = (values)=>{
    const params = {...values,  reported_user: data.userId}
    api.reportUser(params).then((response)=>{
      if(response.kind === "ok"){
        notifySuccess(`User ${values.username} reported successfully`)
        navigate(UnauthenticatedPaths.explore)
      }else if (response.kind === "bad-data"){
        formik.setErrors({...response.errors})
      }else{
        notifyError("Error reporting user")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error reporting user")
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:validationSchema,
    onSubmit: values => {
      report(values)
    }
  })

  const goBack = ()=>{
    navigate(-1)
  }

  return (
    <React.Fragment>
      <Box style={styles.mainContainer}>
        <Box style={styles.titleContainer}>
          <MDBox onClick={goBack} styles={styles.backImageContainer}>
            <img src={BackIcon}  style={styles.backImage}/>
          </MDBox>
          <MDTypography style={styles.title}>REPORT ABUSE</MDTypography>
          <Box style={{flexGrow:1}} />
        </Box>

        <MDTypography style={styles.subtitle}>
          If you are aware that one of our users has posted content that violates
          our Policy, please fill out the form below.</MDTypography>

        <form onSubmit={formik.handleSubmit}>
          <MDBox mb={2} mt={4}>
            <TextField
              fullWidth
              inputLabel={"Your Name"}
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </MDBox>
          <MDBox mb={2} mt={2}>
            <TextField
              fullWidth
              inputLabel={"Email"}
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </MDBox>


          <MDBox mb={2} mt={2}>
            <TextField
              fullWidth
              inputLabel={"Username of the User that You wish to report"}
              id="username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <TextField
              fullWidth
              inputLabel={"URLs of the Content You wish to report"}
              id="url"
              name="url"
              value={formik.values.url}
              onChange={formik.handleChange}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText={formik.touched.url && formik.errors.url}
            />
          </MDBox>

          <MDBox mb={2} mt={2}>
            <TextField
              fullWidth
              multiline
              rows={2}
              inputBase={false}
              inputLabel={"Briefly describe the issue with this content"}
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </MDBox>


          <MDBox mb={4} mt={8} style={styles.buttonsContainer}>
            <SecondaryButton type={"button"} text={"CANCEL"}
                             onClick={goBack}
                             textStyles={styles.cancelText}
                             buttonStyle={styles.cancelButton} />
            <SecondaryButton type={"submit"}
                             textStyles={styles.reportButtonText}
                             text={"REPORT"} buttonStyle={styles.reportButton}/>
          </MDBox>
        </form>
      </Box>
    </React.Fragment>
  )
})
