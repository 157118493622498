import { Box, Modal } from "@mui/material";
import colors from "../../assets/theme/base/colors";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { BaseModal } from "../../base/BaseModal";
import MDTypography from "../MDTypography";
import { SecondaryButton } from "../SecondaryButton";
import { useApi } from "../../utils/hooks";
import { notifyError, notifySuccess } from "../../services/notification";


export const BlockUserModal = ({user, open, onClose, onBlock})=>{
  const api = useApi()

  const confirmBlock = ()=>{
    api.blockUser(user.id).then((response) => {
      if(response.kind === "ok"){
        notifySuccess(`User ${user.username} locked successfuly`)
        onBlock()
      }else{
        notifyError("Cant process the operation")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Cant process the operation")
    })

  }
  return (
    <BaseModal
      open={open}
      title={'BLOCK USER'}
      containerStyles={{width:"660px", height:"316px",  backgroundColor:colors.background.default}}
      onClose={() => onClose()}
    >
      <Box style={styles.body}>
        <MDTypography style={styles.text}>Are you sure you want to block {user.username}?</MDTypography>
        <Box >
          <SecondaryButton
            sx={{...styles.button, marginTop:"250px"}}
            text="BLOCK"
            onClick={()=>confirmBlock()}
            padding={0.5}
          />
          <Typography
            onClick={onClose}
            style={{...styles.text, fontSize:14, cursor:"pointer", marginTop:"20px"}}>CANCEL</Typography>
        </Box>
      </Box>

    </BaseModal>

  )
}



const styles = {
  text:{
    fontSize:16,
    textAlign:"center",
    letterSpacing:"0.1em",
    color:colors.white.main,
    width:"292px",
  },
  button:{
    width:"292px",
    height:"40px"
  },
  body:{
    height:"180px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    alignItems:"center",
  }
}
