/* dependencies */
import React from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseActionsEventModal} from '../BaseActionModalEvent/';
import {SecondaryButton} from 'components/SecondaryButton';
import {CustomTextField} from 'components/TextField';

/* data */
import {LocationDetailsRoot} from './root';

/* assets */
import {styles} from './styles';

export const MeetingLocationDetailsModal = ({
  open,
  onClose,
  avatar,
  idolizerName,
  idolPoints,
  isVerified,
  date, 
  timeStart, 
  timeEnd, 
  price,
  onSave,
  meetingPlace = '',
  title='LOCATION DETAILS',
  eventType='idolmeet'
}) => {

  const {initialValues, validationShema} = LocationDetailsRoot();
  const baseProps = {
    open, 
    onClose,
    title, 
    avatar,
    idolizerName,
    idolPoints,
    isVerified,
    date,
    timeStart,
    timeEnd, 
    price,
  };

  return (
    <BaseActionsEventModal {...baseProps}>
      <Formik initialValues={initialValues} validationSchema={validationShema} onSubmit={onSave}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
          isSubmitting,
        }) => (
            <>
            <Form style={styles.form}>
                <Typography variant='h4' sx={styles.title}>Details</Typography>
                <Box>
                    <CustomTextField name='location' onChange={handleChange} placeholder='Location'/>
                    {(errors.location && touched.location) && 
                        <Typography sx={styles.error}>{errors.location}</Typography>
                    }
                </Box>
                <CustomTextField
                  multiline
                  rows={4}
                  placeholder="Additional comment (e.g. table/seat, dress code)"
                  name='additional_comments'
                  onChange={handleChange}
                  inputBase={false}
                />
            </Form>
            <Box sx={styles.container}>
                <SecondaryButton 
                    text='SAVE LOCATION' 
                    width={styles.button.width}
                    onClick={() => [handleSubmit(values), onClose()]}
                />
                <Box sx={styles.placement_info}>
                    <Typography variant='subtitle2' sx={styles.meeting_info}>{meetingPlace}</Typography>
                    <Typography variant='subtitle2' sx={styles.meeting_info}>{eventType}</Typography>
                </Box>
            </Box>
            <Box sx={styles.footer_container}>
                <Typography variant='subtitle2' sx={styles.footer}>
                    Location details will be shared 72 hours before the event.
                    This information is private to your idolizer
                </Typography>
            </Box>
            </>   
        )}
      </Formik>
    </BaseActionsEventModal>
  );
}

MeetingLocationDetailsModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    meetingPlace: PropTypes.string,
    eventType: PropTypes.string,
}