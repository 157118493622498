import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { styles } from "./styles";

const PriceBaseCard = ({ isMobile, title, children, titleStyles }) => {
  return (
    <Card sx={ isMobile ? {...styles.container, marginTop: '14px'} : styles.container}>
      <Box sx={isMobile ? {...styles.titleBox, height: '52px'} : styles.titleBox}>
        <Typography variant="h3" sx={{ ...styles.title, ...titleStyles, ...(isMobile && styles.mobileTitle) }}>
          {title}
        </Typography>
      </Box>
      <Box sx={isMobile ? {...styles.childrenBox, padding: '18px 15px 15px 15px'} : styles.childrenBox }>
        {children}
      </Box>
    </Card>
  );
};

export default PriceBaseCard;
