import {Box} from '@mui/material';
import {MoreVert as ThreeDotsIcon} from "@mui/icons-material";
import colors from "../../../../assets/theme/base/colors";
import {BaseCardIdolInfo} from "../../../../base/Cards/InfoCards/IdolizerInfoCard";
import iconGrey from "../../../../assets/images/idol_grey.png";
import iconRed from "../../../../assets/images/idol_red.png";
import React, {useState} from "react";
import {IdolsActions} from "../idolizer/idols/actions";

const idolImgType = {
  red: iconRed,
  gray: iconGrey,
};

export const IdolCard = ({
                           avatar,
                           isVerified,
                           idolPoints,
                           idolizerName,
                           type,
                           iconColor="gray",
                           onReport,
                           onBlock,
                           onUnSubscribe,
                           onUnfollow,
                           onClickUser
                         }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => {
    setAnchorEl(null);
  };

  const handleToggleShowOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box sx={styles.container}>
      <BaseCardIdolInfo idolizerName={idolizerName}
                        avatar={avatar}
                        onClick={onClickUser}
                        idolPoints={idolPoints}
                        isVerified={isVerified}/>
      <Box sx={styles.secondContainer}>
        <Box component={'img'} src={idolImgType[iconColor]} alt={'logo'}/>
        <ThreeDotsIcon sx={styles.threeDots} onClick={(e) => handleToggleShowOptions(e)}/>
        <IdolsActions  anchorEl={anchorEl}
                       onClose={() => setAnchorEl(null)}
                       open={Boolean(anchorEl)}
                       onReport={onReport}
                       onBlock={onBlock}
                       onUnfollow={onUnfollow}
                       onUnSubscribe={onUnSubscribe}
                       type={type} />
      </Box>
    </Box>
  )
}


const styles = {
  container: {
    backgroundColor: "#2F3A4A",
    height: "73px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  secondContainer: {
    backgroundColor: "#0C192B",
    width: "45%",
    marginLeft: "10px",
    height: "74px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "24px"
  },
  threeDots: {
    color: "#2F3A4A",
    height: "30px",
    width: "30px",
    cursor:"pointer"
  },
};
