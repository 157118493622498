import React, { memo } from "react";
import lockedPost from "../../assets/images/lockedPost.png";
import { PostCategoryEnum } from "../../constants";
import { Box } from "@mui/material";
import {useIsMobile} from "../../utils/hooks";


const PostContent = ({ post, handleOpen }) => {
  const isMobile = useIsMobile()
  let params = {component: "img", src: post.image}
  if (!post.is_visible) {
    params["src"] = lockedPost
  } else if (post.post_category.codename === PostCategoryEnum.video) {
    params["component"] = "video"
    params["src"] = post.file
  }

  return (
    <Box
      key={post.id}
      src={post.idolPost}
      {...params}
      sx={isMobile?{...styles.postImgMobile}:{...styles.postImg}}
      onClick={() => handleOpen(post)}
    />
  )
};

const styles = {
  postImg: {
    width: "200px",
    objectFit: "cover",
    aspectRatio: 16/9,
    cursor:"pointer"
  },
  postImgMobile: {
    width: "128px",
    objectFit: "cover",
    aspectRatio: 16/9,
    cursor:"pointer"
  },
}
PostContent.propTypes = {

};

export default memo(PostContent);
