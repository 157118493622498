
import colors from 'assets/theme/base/colors';
import tiphography from 'assets/theme/base/typography'

export const styles = {
    launch: {
        marginTop: 40,
        width: '50%',
        fontFamily: tiphography.fontFamily,
    },
    launchMobile: {
        marginTop: 5,
        width: '100%',
        fontFamily: tiphography.fontFamily,
    },
    subtitle: {
        color: colors.white.main,
        fontSize: 25,
        fontWeight: 'bold',
        marginTop: 4,
        letterSpacing: "0.1em",
        width: '70%',
        fontFamily: tiphography.fontFamily
    },
    subtitleMobile: {
        color: colors.white.main,
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 4,
        letterSpacing: "0.1em",
        width: '100%',
        fontFamily: tiphography.fontFamily
    },
    text: {
        color: colors.white.main,
        marginTop: 4,
        fontSize: "16px",
        letterSpacing: "0.1em",
        width: '70%',
        fontFamily: tiphography.fontFamily
    },
    textMobile: {
        color: colors.white.main,
        marginTop: 4,
        fontSize: "14px",
        letterSpacing: "0.1em",
        width: '100%',
        fontFamily: tiphography.fontFamily
    },
    title: {
        color: colors.white.main,
        fontFamily: tiphography.fontFamily,
    },
};
