import colors from "assets/theme/base/colors"
import pxToRem from "assets/theme/functions/pxToRem"

export const styles = {
    modal: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        // marginInline: '1rem',
    },
    modal_container: {
        backgroundColor: colors.card.background,
        borderRadius: '12px',
        height: 'fit-content',
        marginBottom: 5,
        maxHeight: '90%',
        overflowY: 'auto',
        position: 'relative',
        // width: '47%',
    },
    modal_header: {
        background: colors.card.header,
        borderRadius: '12px 12px 0 0',
        padding: 2,
        position: 'absolute',
        top: 0,
        textAlign: 'center',
        width: '100%',

    },
    modal_header_title: {
        color: colors.white.main,
        fontSize: pxToRem(20),
        fontWeight: 700,
        lineHeight: '127.25%',
    },
    modal_header_title_mobile: {
        fontSize: pxToRem(16),
        fontWeight: 700,
        lineHeight: '127.25%',
        letterSpacing: '0.1em',
    },
    modal_content_container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBlockEnd: 5,
        marginBlockStart: 12,
        paddingInline: {xs : '', lg: pxToRem(24)},
        marginInline: {xs: '10px', lg: 15},
        height: 'fit-content',
    },
    content: {
        // marginInline: 'auto',

    },
    colorWhite: {
        color: colors.white.main,
    },
}
