import {Box, Input, Typography, FormHelperText} from "@mui/material";
import React from "react";
import colors from "../../../../assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import MDTypography from "../../../../components/MDTypography";
import PhoneNumberField from "../../../../components/PhoneNumberField";
import InputMask from "react-input-mask";
import {CustomTextField} from "../../../../components/TextField";

const CustomTextFieldMobile = ({
                                 type, sx, name, value, onChange, placeholder, error, helperText
                               }) => {
  return <Box>
    <Input
      type={type}
      sx={sx}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    {error && (
      <FormHelperText>
        <Typography
          sx={[{fontSize: pxToRem(12), color: colors.error.main}]}
          variant='span'>{helperText}</Typography>
      </FormHelperText>
    )}
  </Box>
}

function CardDetailsMobile({formik}) {

  return (
    <Box sx={styles.cardDetails}>
      <Typography sx={styles.titleDetails}>Card Details</Typography>
      <CustomTextFieldMobile
        type="text"
        sx={styles.input}
        name="cardHolder"
        value={formik.values.cardHolder}
        onChange={formik.handleChange}
        placeholder="Card Holder Name"
        error={formik.touched.cardHolder && Boolean(formik.errors.cardHolder)}
        helperText={formik.touched.cardHolder && formik.errors.cardHolder}
      />
      <Box sx={styles.doubleInput}>
        <InputMask mask={"99/9999"}
                   name="expiryDate"
                   value={formik.values.expiryDate}
                   onChange={(e)=> formik.setFieldValue('expiryDate', e.target.value)}
        >
          {() => (
            <CustomTextField
              type="text"
              sx={styles.input}
              placeholder="Expiry date"
              id={`id_expiryDate`}
              error={formik.touched.expiryDate && Boolean(formik.errors.expiryDate)}
              helperText={formik.touched.expiryDate && formik.errors.expiryDate } />
          )}
        </InputMask>
        <InputMask mask={"999"}
                   name="CVV"
                   value={formik.values.CVV}
                   onChange={(e)=>formik.setFieldValue('CVV', e.target.value)} >
          {() => (
            <CustomTextField
              type="text"
              sx={styles.input}
              placeholder="CVV"
              error={formik.touched.CVV && Boolean(formik.errors.CVV)}
              helperText={formik.touched.CVV && formik.errors.CVV } />
          )}
        </InputMask>
      </Box>
      <InputMask mask={"9999-9999-9999-999?"}
                 formatChars={{
                   "9": "[0-9]",
                   "?": "[0-9]"
                 }}
                 name="cardNumber"
                 value={formik.values.cardNumber}
                 onChange={(e)=>formik.setFieldValue('cardNumber', e.target.value)} >
        {() => (
          <CustomTextField
            type="text"
            sx={styles.input}
            placeholder="Card Number"
            id={`id_cardNumber`}
            error={formik.touched.cardNumber && Boolean(formik.errors.cardNumber)}
            helperText={formik.touched.cardNumber && formik.errors.cardNumber } />
        )}
      </InputMask>
      <PhoneNumberField
        country={'us'}
        inputStyle={styles.phoneInput}
        buttonStyle={styles.phoneInputButton}
        dropdownStyle={styles.phoneDropdown}
        placeholder={"Phone Number"}
        value={formik.values.phoneNumber}
        onChange={(phone) =>
          formik.setFieldValue('phoneNumber', phone)
        }
        id={`id_phoneNumber`}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}/>
    </Box>
  );
}

export default CardDetailsMobile;


const styles = {
  title: {
    alignSelf: "flex-start",
    color: "#fff",
    fontWeight: "400",
    fontSize: "25px",
  },
  accordion: {
    backgroundColor: "#182536",
    boxShadow: "0px 0px 0px 0px",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: "0px",
  },
  expandIcon: {
    color: "#fff",
    width: "30px",
    height: "30px",
  },
  inputsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "30px",
  },
  titleDetails: {
    color: "#fff",
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
  },
  cardDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  doubleInput: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: "100%",
  },
  billingDetails: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  input: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    padding: "15px",
    fontSize: pxToRem(14),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    width: "100%",
    color: "#fff",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  phoneInput: {
    backgroundColor: colors.inputBackgroundColor,
    borderRadius: "4px",
    height: "40px",
    fontSize: "18px",
    width: "100%",
    color: "#fff",
    border: "0px",
    input: {
      "&::placeholder": {
        opacity: 1,
        color: "#fff",
      },
    },
  },
  phoneInputButton: {
    backgroundColor: colors.inputBackgroundColor,
    border: "0px",
  },
  phoneDropdown: {
    backgroundColor: colors.inputBackgroundColor,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  button: {
    backgroundColor: colors.primary.main,
    height: "42px",
    width: "193px",
    color: "#fff",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: colors.withdrawlcard.greybutton,
      color: "#fff",
    },
    "&:focus": {
      color: "#fff!important",
    },
  },
};

