import React, {useState} from "react";
import {Box, Card, Grid, Typography} from "@mui/material";
import moment from "moment";

import {styles} from "./styles";
import PropTypes from 'prop-types'
import {ArrowForwardIosRounded, MoreVert as ThreeDotsIcon} from "@mui/icons-material";
import dollar from "assets/icons/dollar.svg";
import IdolTipCard from "base/Cards/IdolTipCard/IdolTipCard";
import {BaseCardIdolInfo} from "base/Cards/InfoCards/IdolizerInfoCard";
import FollowIcon from "assets/icons/people.svg";
import MeetIcon from "assets/icons/calendar1.svg";
import LiveCallIcon from "assets/icons/events.svg";
import SubscriptionIcon from "assets/icons/price.svg";
import logo from "assets/images/logo.png";
import {datetimeToLocal} from "../../utils/dateUtils";
import { IdolsActions } from "pages/portal/dashboard/idolizer/idols/actions";
import MDBox from "../MDBox";

const transactionOriginIcon = {
  meet_greet: MeetIcon,
  live_call: LiveCallIcon,
  follow: FollowIcon,
  subscription: SubscriptionIcon
}
export const RecordCard = (props) => {
  const {
    key,
    popoverProps,
    cardFirstContent,
    cardSecondContent,
    cardThirdContent,
    showCard = true,
    showDivider = true,
    containerStyles,
    contentContainerStyles,
    iconCard,
    cardThirdContentStyles,
    showOptionsCard = true,
    optionsContent = null,
    alignEndContent = false,
    cardContentsStyle = {},
    firstContentStyle,
    secondContentStyle,
    dividerStyles,
    isMobile,
    onClick,
    iconStyles,
    clickable = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const popover = {
    anchorEl,
    open: Boolean(anchorEl),
    onClose: () => setAnchorEl(null),
    ...popoverProps
  };

  return (

      <Grid key={key} id='record_card'  sx={[styles.container, containerStyles, clickable ? styles.cursorPointer : {}]} onClick={onClick}>
        <Box sx={[styles.content_container, contentContainerStyles]}>
          <Box
            sx={[
              styles.titleCard,
              isMobile && styles.titleCardMobile,
              cardContentsStyle,
              firstContentStyle,
            ]}
          >
            {cardFirstContent}
          </Box>

          {showDivider && <Box sx={[styles.divider, dividerStyles, isMobile && styles.dividerMobile]}>|</Box>}

          <Box
            sx={[
              styles.dateCard,
              alignEndContent && {justifyContent: "end"},
              cardContentsStyle,
              secondContentStyle,
            ]}
          >
            {cardSecondContent}
          </Box>
        </Box>
        {showCard && (
          <Card sx={[styles.amountCard, {...cardThirdContentStyles}]}>
            {cardThirdContent}
            {iconCard ? (
              <img src={iconCard} style={iconStyles}/>
            ) : (
              showOptionsCard && (
                <>
                  <ThreeDotsIcon onClick={handleOpen} sx={styles.threeDots}/>
                  <IdolsActions {...popover} />
                  {optionsContent}
                </>
              )
            )}
          </Card>
        )}
      </Grid>
  );
}
RecordCard.propTypes = {
  cardFirstContent: PropTypes.node,
  cardSecondContent: PropTypes.node,
  cardThirdContent: PropTypes.node,
}

export const WithdrawlCardDetailedRecord = (props) => {
  const {key, record, showArrow = true, amountStyles, titleStyle,dateStyle, ...rest} = props
  const {invoice_date, amount, description} = record
  return <RecordCard
    key={key}
    {...rest}
    cardFirstContent={
      [
        showArrow && <ArrowForwardIosRounded color={'white'} size={30}/>,
        <Typography sx={{...styles.title, ...titleStyle}}>{description}</Typography>
      ]
    }
    cardSecondContent={
      <Typography
        sx={{...styles.date, ...dateStyle}}>{datetimeToLocal(invoice_date).format("DD MMM YYYY - hh:mm A").toUpperCase()}</Typography>
    }
    cardThirdContent={
      [
        <MDBox component='img' src={dollar} alt="dollar icon" sx={styles.dollarImage} />,
        // <img src={dollar} alt='dollar icon' style={styles.dollarImage}/>,
        <Typography sx={[styles.amount, {...amountStyles}]}>{new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(amount)}</Typography>,
      ]
    }
  />
}

export const IdolFollowersRecord = (props) => {
  const {key, record, actionsFn} = props
  const {
    id,
    username,
    is_validated,
    thumbnail,
    email,
    user_type,
    gender_of_interest,
    time_zone,
    first_name,
    last_name,
    image,
    idol_points,
    created_at
  } = record

  return <RecordCard
    key={`itdr_${key}`}
    cardFirstContent={
      [
        <ArrowForwardIosRounded color={'white'} size={30}/>,
        <BaseCardIdolInfo
          idolizerName={username}
          avatar={`${thumbnail}?height=150&width=150`}
          isVerified={is_validated}
          isIdolizer={false}
          idolPoints={idol_points}
        />
      ]
    }
    popoverProps={actionsFn}
    cardThirdContent={
      [
        <img src={logo} alt="logo" title="logo" style={styles.logo_img}/>
      ]
    }
  />
}

export const IdolIdolizersRecord = (props) => {
  const {key, record, actionsFn} = props
  const {
    id,
    username,
    is_validated,
    thumbnail,
    email,
    user_type,
    gender_of_interest,
    time_zone,
    first_name,
    last_name,
    subscription_duration,
    image,
    created_at,
    idol_points,
    suscription_type
  } = record

  // PURE DEMO PURPOSES UNTIL BACKEND READY
  const xxx = (Math.random() * 10 | 0) + 1
  const suscription_type_TEST_XXX = xxx > 6 ? "Monthly" : `${xxx + 1} months`

  return <RecordCard
    key={`itdr_${key}`}
    cardFirstContent={
      [
        <ArrowForwardIosRounded color={'white'} size={30}/>,
        <BaseCardIdolInfo
          idolizerName={username}
          avatar={`${thumbnail}?height=150&width=150`}
          isVerified={is_validated}
          isIdolizer={false}
          idolPoints={idol_points}
        />
      ]
    }
    cardSecondContent={
      <Typography>IDOLIZER</Typography>
    }
    cardThirdContent={
      [
        <img src={logo} alt="logo" title="logo" style={styles.logo_img}/>,
        <Typography sx={styles.suscription_duration}>{subscription_duration}</Typography>
      ]
    }
  />
}

export const IdolFollowingRecord = (props) => {
  const {key, record, actionsFn} = props
  const {
    id,
    username,
    is_validated,
    thumbnail,
    email,
    user_type,
    gender_of_interest,
    time_zone,
    first_name,
    last_name,
    image,
    created_at,
    subscription_amount,
    idolizing,
    idol_points,
    suscription_type
  } = record

  // PURE DEMO PURPOSES UNTIL BACKEND READY
  let amount = "0"
  if (idolizing) {
    amount = `${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(subscription_amount)}`
  }

  return <RecordCard
    popoverProps={actionsFn}
    key={`itdr_${key}`}
    cardFirstContent={
      [
        <ArrowForwardIosRounded color={'white'} size={30}/>,
        <BaseCardIdolInfo
          idolizerName={username}
          avatar={`${thumbnail}?height=150&width=150`}
          isVerified={is_validated}
          isIdolizer={false}
          idolPoints={idol_points}
        />
      ]
    }
    cardSecondContent={
      idolizing && <Typography>IDOLIZING</Typography>
    }
    cardThirdContent={
      [
        <img src={logo} alt="logo" title="logo" style={styles.logo_img}/>,
        <Typography sx={styles.suscription_value}>{amount}</Typography>
      ]
    }
  />
}

export const IdolTipDetailedRecord = (props) => {
  const {key, record, actionsFn, showArrow = true} = props
  const {id, user, post, amount, created_at, idol_points} = record
  // const {reportUser, blockUser} = actionsFn
  return <RecordCard
    key={`itdr_${key}`}
    cardFirstContent={
      [
        showArrow && <ArrowForwardIosRounded color={'white'} size={30}/>,
        <BaseCardIdolInfo
          idolizerName={user.username}
          avatar={`${user.thumbnail}?height=150&width=150`}
          isVerified={user.is_validated}
          isIdolizer={false}
          idolPoints={user.idol_points}
        />
      ]
    }
    cardSecondContent={
      <Typography sx={styles.date}>
        {datetimeToLocal(created_at).format("DD MMM YYYY - hh:mm A").toUpperCase()}
      </Typography>
    }
    cardThirdContent={
      [
        <img src={dollar} alt='dollar icon' style={styles.dollarImage}/>,
        <IdolTipCard amount={amount} points={idol_points} avatar={`${post.image}`}/>,
      ]
    }
  />
}

export const IdolTransactionDetailedRecord = (props) => {
  const {key, record} = props
  const {id, amount, description, origin_type, environment, type, idol_points, created, user, card} = record
  return <RecordCard
    key={`itrdr_${key}`}
    cardFirstContent={
      [
        <ArrowForwardIosRounded color={'white'} size={30}/>,
        <BaseCardIdolInfo
          idolizerName={user.username}
          avatar={`${user.thumbnail}?height=50&width=50`}
          isVerified={user.is_validated}
          isIdolizer={false}
          idolPoints={user.idol_points}
        />
      ]
    }
    cardSecondContent={
      <Typography sx={styles.date}>
        {datetimeToLocal(created).format("DD MMM YYYY - hh:mm A").toUpperCase()}
      </Typography>
    }
    cardThirdContent={
      [
        <img src={dollar} alt='dollar icon' style={styles.dollarImage}/>,
        <IdolTipCard amount={amount} points={idol_points} avatar={transactionOriginIcon[origin_type]} type='icon'
                     notCentered={true}/>,
        <Typography sx={styles.amount}></Typography>,
      ]
    }
  />
}

export const IdolizerTransactionDetailedRecord = (props) => {
  const {key, record} = props
  const {title, date, amount} = record
  return <RecordCard
    key={`iztrdr_${key}`}
    cardFirstContent={
      [
        <ArrowForwardIosRounded color={'white'} size={30}/>,
        <Typography sx={styles.title}>{title}</Typography>
      ]
    }
    cardSecondContent={
      <Typography sx={styles.date}>{moment(new Date(date)).format("DD MMM YYYY - h:mm A").toUpperCase()}</Typography>
    }
    cardThirdContent={
      [
        <img src={dollar} alt='dollar icon' style={styles.dollarImage}/>,
        <Typography sx={styles.amount}>{new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(amount)}</Typography>,
      ]
    }
  />
}
