import { Box, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';


function IdolTipCard({ amount, points, avatar, type='avatar', notCentered = false}) {
  console.log('amount', amount);
  return (
    <Box sx={[styles.container, notCentered && {paddingLeft: '3rem'}]}>

        <Box sx={styles.textContainer}>
            <Typography sx={styles.amount}>
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)}
            </Typography>
            <Box sx={styles.pointsContainer}>
                <Typography
                    sx={styles.points}
                >+{points}
                </Typography>
                <Typography
                    sx={styles.idp}
                >idp
                </Typography>
            </Box>
        </Box>
       <Box sx={type === 'avatar' ? styles.avatar : styles.icon} component='img' src={`${avatar}?height=50&width=50`} alt='avatar'/>
    </Box>
  )
}

export default IdolTipCard;
