/* dependencies */
import PropTypes from 'prop-types';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {BaseMessageModal} from '../index';

/* assets */
import {styles} from './styles';
import {PrimaryButton} from "../../PrimaryButton";
import {MessageInfo, MessageTypes} from "../../../constants";
import VerifiedIcon from "../../../assets/icons/checked.png";
import React from "react";

export const PopupMessageModal = ({
                                          open,
                                          onClose,
                                          messageType,
                                          avatar,
                                          idolizerName,
                                          idolHost=null,
                                          isVerified,
                                          onConfirm,
                                          buttonText = 'OK',
                                        }) => {

  const baseProps = {
    open,
    onClose,
    idolizerName,
    isVerified,
  };

  const isMeeting = (messafeType) => {
    return +messafeType === MessageTypes.MEETING_APPROVED || +messafeType === MessageTypes.MEETING_REJECTED
  }

  return (
    <BaseMessageModal
      title={MessageInfo[messageType]?.title}
      nameDisplayed={messageType && isMeeting(messageType) ? idolHost?.username : idolizerName}
      avatar={messageType && isMeeting(messageType) ? idolHost?.image : avatar}
      {...baseProps}
      sx={styles.modal}>
      {messageType && +messageType === MessageTypes.VERIFICATION_APPROVED && <img src={VerifiedIcon} style={styles.checked_icon}/>}
      <Typography sx={styles.text} variant='h5'>
        {MessageInfo[messageType]?.message}
      </Typography>
      <Box sx={styles.container}>
        <PrimaryButton
          variant={'outlined'}
          text={buttonText}
          onClick={onConfirm}
          padding={styles.paddingButton}
          width={styles.dismissButton.width}
          backgroundColor={styles.dismissButton.background}
          textStyles={styles.buttonText}
          borderColor={styles.meeting_info.color}
        />
      </Box>
    </BaseMessageModal>
  )
}