import idol1 from "../../assets/images/idol1.jpg";
import idol2 from "../../assets/images/idol2.jpg";
import idol3 from "../../assets/images/idol3.jpg";
import idol4 from "../../assets/images/idol4.jpg";
import idol5 from "../../assets/images/idol5.jpg";
import idol6 from "../../assets/images/idol6.jpg";
import idol7 from "../../assets/images/idol7.jpg";
import idol8 from "../../assets/images/idol8.jpg";
import idol9 from "../../assets/images/idol9.jpg";
import idol10 from "../../assets/images/idol10.jpg";
import moment from "moment/moment";

export const events = [
  {
    id: 0,
    title: 'Coffee Consultation!',
    eventDuration: '15 Minute',
    eventType: 'idocall',
    eventDescription: 'My popular ice-breaker! Book me for a video consultation. I cant wait to meet you!',
    eventPrice: 150,
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted')
  },
  {
    id: 1,
    title: 'Rainforest Escape!',
    eventDuration: '4 hour',
    eventType: 'idolmeet',
    meetPlace: 'Austin, TX',
    eventPrice: 2500,
    eventDescription: 'Luckily for you, I live near a rainforest! I offer private tours to some of my fav spots',
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted'),
  },
  {
    id: 2,
    title: 'Gambling Cruise Getaway!',
    eventDuration: '4 day',
    eventType: 'idolmeet',
    meetPlace: 'Fort Lauderdale, FL',
    eventDescription: 'Bring your poker face! Designed for the finest of idolizers looking for an incredible evening',
    eventPrice: 10000,
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted'),
  },
  {
    id: 3,
    title: 'Cocktail Cosplay Party!',
    eventDuration: '2 hour',
    eventType: 'idolmeet',
    meetPlace: 'San Diego, CA',
    eventDescription: 'Perfect for Comic Con afterhour parties! You can help choose my cosplay',
    eventPrice: 8500,
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted'),
  },
  {
    id: 4,
    title: 'Cocktail Cosplay Party!',
    eventDuration: '2 hour',
    eventType: 'idolmeet',
    meetPlace: 'San Diego, CA',
    eventDescription: 'Perfect for Comic Con afterhour parties! You can help choose my cosplay',
    eventPrice: 8500,
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted'),
  },
  {
    id: 5,
    title: 'Cocktail Cosplay Party!',
    eventDuration: '2 hour',
    eventType: 'idolmeet',
    meetPlace: 'San Diego, CA',
    eventDescription: 'Perfect for Comic Con afterhour parties! You can help choose my cosplay',
    eventPrice: 8500,
    onEdit: () => console.log('Edited'),
    onDismiss: () => console.log('Deleted'),
  },
]

export const testWithdrawls = [
  {id: 1, title: "hello", date: "2022-11-12 06:31:55.451910+00:00", amount: 100.25},
  {id: 2, title: "bye", date: "2022-10-07 07:41:55.451910+00:00", amount: 1120},
  {id: 3, title: "get", date: "2022-05-23 11:17:55.451910+00:00", amount: 5400.11},
  {id: 4, title: "fill", date: "2022-02-29 03:10:55.451910+00:00", amount: 371.56},
]

export const citiesOptions = [
  {value: "New York", label: "New York"},
  {value: "Los Angeles", label: "Los Angeles"},
];
export const dateRangeOptions = [
  {value: "Calendar days", label: "Calendar days"},
  {value: "Option2", label: "Option2"},
  {value: "Option3", label: "Option3"},
];
export const durationOptions = [
  {value: "1 hour", label: "1 hour"},
  {value: "2 hours", label: "2 hours"},
];

export const baseInfo = {
  title: 'Coffee Meeting!',
  idolizerName: 'Guy Hawkins',
  idolPoints: 3700,
  isVerified: true,
  price: 350.00,
  date: '18 MAR 2022',
  timeStart: moment().format("hh:mm").toUpperCase(),
  timeEnd: moment().format("hh:mm").toUpperCase(),
  timeZone: 'Estados Unidos, California'
};

export const upcomingMeetings = [
  {id: 1, ...baseInfo, upcoming: false, meetingType: 'idocall', meetingPoints: 150},
  {
    id: 2,
    title: 'Rainforest Escape!',
    idolizerName: 'Micheal Hernandez',
    idolPoints: 12500,
    isVerified: true,
    price: 8500,
    date: '18 MAR 2022',
    timeStart: moment().format("hh:mm").toUpperCase(),
    timeEnd: moment().format("hh:mm").toUpperCase(),
    upcoming: true,
    meetingType: 'idolevent',
    meetingPoints: 2500,
    timeZone: 'Rio de Janeiro, Brasil',
    // TEMP
    details: [
      {
        profilePicture: '',
        username: '',
        idp: '',
        meetingDate: '',
        meetingPrice: '',
      }
    ]
  },
];

export const examplesCards = [
  {
    id: 1,
    'user': 1,
    'card_type': 'mastercard',
    'card_number': '1111 2222 3333 4444',
    'holders_name': 'CAYO LEGAL',
    'expiry_year': '23',
    'expiry_month': '11',
    'cvv': '123'
  },
  {
    id: 2,
    'user': 1,
    'card_type': 'visa',
    'card_number': '2222 4444 3333 4444',
    'holders_name': 'CAYO BRIZUELA',
    'expiry_year': '24',
    'expiry_month': '09',
    'cvv': '234'
  },
  {
    id: 3,
    'user': 1,
    'card_type': 'visa',
    'card_number': '3333 4444 3333 4444',
    'holders_name': 'MISAEL iLEGAL',
    'expiry_year': '25',
    'expiry_month': '07',
    'cvv': '345'
  },
];
export const idolsPosts = [
  {id: 1, idolPost: idol1, isLocked: false, userType: "idol", tipPrice: 12, description: "idol post 1"},
  {
    id: 2,
    idolPost: idol2,
    isLocked: false,
    userType: "idolizer",
    tipPrice: 10,
    description: "Arch game? I think so baby 💘 don’t u wish u were lucky enough to hit it from the back like this? I bet 🤍🤍"
  },
  {id: 3, idolPost: idol3, isLocked: false, userType: "idol", tipPrice: 9, description: "idol post 3"},
  {id: 4, idolPost: idol4, isLocked: false, userType: "idolizer", tipPrice: 5, description: "idol post 4"},
  {id: 5, idolPost: idol5, isLocked: false, userType: "idol", tipPrice: 4, description: "idol post 5"},
  {id: 6, idolPost: idol6, isLocked: false, userType: "idol", tipPrice: 6, description: "idol post 6"},
  {id: 7, idolPost: idol7, isLocked: false, userType: "idolizer", tipPrice: 10, description: "idol post 7"},
  {id: 8, idolPost: idol8, isLocked: false, userType: "idol", tipPrice: 5, description: "idol post 8"},
  {
    id: 9,
    idolPost: idol9,
    isLocked: false,
    userType: "idol",
    tipPrice: 4,
    description: "🤪💀 flashing my titties in the hot tub"
  },
  {
    id: 10,
    idolPost: idol10,
    isLocked: true,
    userType: "idol",
    tipPrice: 8,
    description: "Arch game? I think so baby 💘 don’t u wish u were lucky enough to hit it from the back like this? I bet 🤍🤍"
  }];
