import React, {useEffect, useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import {styles} from "./styles";
import colors from "../../../assets/theme/base/colors";
import MDBox from "../../../components/MDBox";
import PlusRedIcon from "../../../assets/icons/plus_red.svg";
import TrashIcon from "../../../assets/icons/trash.svg";
import GlobeIcon from "assets/icons/mundo.svg";
import {BasicCheckbox} from "../../../components/BasicCheckbox";
import {useApi, useIsMobile} from "../../../utils/hooks";
import {CustomTextField as TextField} from "../../../components/TextField";
import {SecondaryButton} from "../../../components/SecondaryButton";
import {PrimaryButton} from "../../../components/PrimaryButton";
import moment from "moment";
import InputMask from "react-input-mask";
import stylesCss from "./styles.module.css";

import AvailabilityModal from "../../../components/AvailabilityModal";
import {notifyError, notifySuccess} from "../../../services/notification";
import { AMPMtimeToMoment, DATETIME_FORMAT, timeToLocal, timeToUTC } from "../../../utils/dateUtils";

import {ErrorMessage, Field, FieldArray, Form, Formik} from "formik";
import {availabilitySchema} from "../../../components/AvailabilityModal/schemas";
import {MobileSectionHeader} from "../../../components/Mobile/MobileIdolDashboardSectionHeader";
import CalendarIcon from "../../../assets/icons/calendar1.svg";

const defaultTimeFrame = {
  from_time: "09:00 AM",
  to_time: "05:00 PM"
}

const CustomCheckbox = (props) => (
  <BasicCheckbox
    formGroupStyle={{width: "100px"}}
    labelStyle={{fontSize: "12px", display: "unset", marginLeft: "-8px", overflow: "visible"}} {...props} />
);

const AvailabilityGrid = props => {
  const {getEvents, isMobile, modalState} = props
  // const [Times, setTimes] = useState([])
  const api = useApi()
  const [ShowModal, setShowModal] = useState(false)
  const [customDays, setCustomDays] = useState([])
  const [customRanges, setCustomRanges] = useState([])
  const [Availabilities, setAvailabilities] = useState(null)
  const userTimezone = moment.tz.guess()

  const getAvailability = () => {
    api.getUserAvailability({weekly: true}).then(response => {
      if (response.kind === "ok") {
        const availability = response.data.weekly
        for (let i = 0; i < availability.length; i++) {
          for (let j = 0; j < availability[i].ranges.length; j++) {
            availability[i].ranges[j].from_time = timeToLocal(availability[i].ranges[j].from_time)
            availability[i].ranges[j].to_time = timeToLocal(availability[i].ranges[j].to_time)
          }
        }
        setAvailabilities(response.data.weekly)
      }
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    getAvailability()
  }, [])

  const updateEvents = () => {
    console.log("UPDATE EVENTS")
    getEvents({
      from_time: moment().startOf('month').format('yyyy-MM-DD'),
      to_time: moment().endOf('month').format('yyyy-MM-DD'),
    })
  }

  const save = (data) => {
    api.saveUserAvailability(data).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Availability saved Successfully")
        updateEvents()
      } else {
        console.log(response)
        notifyError("There is something wrong")
      }
    }).catch(reason => console.log(reason))
  }

  const process = (availabilities) => {
    const offset = new Date().getTimezoneOffset();

    const days = []
    for (let i = 0; i < availabilities.length; i++) {
      let aux_ranges = []
      for(let range of availabilities[i].ranges){
        aux_ranges.push({
            from_time:(moment(AMPMtimeToMoment(range.from_time)).add("minutes",offset).format(DATETIME_FORMAT)),
            to_time:(moment(AMPMtimeToMoment(range.to_time)).add("minutes",offset).format(DATETIME_FORMAT)),
            from_datetime: moment(AMPMtimeToMoment(range.from_time)).add("minutes", offset).format(''),
            to_datetime: moment(AMPMtimeToMoment(range.to_time)).add("minutes", offset).format('')
          })
      }
      if (availabilities[i].checked) {
        days.push({
          weekday: availabilities[i].name,
          ranges: aux_ranges
        })
      }
    }
    save({weekly: days})
  }

  return (
    <Box>
      {isMobile &&
      <MobileSectionHeader Title={"Calendar"} Icon={CalendarIcon} Button={true} onClick={() => setShowModal(true)}/>
      }
      {Availabilities && (
        <Formik
          validateOnChange={false}
          initialValues={{weekdays: Availabilities}}
          validationSchema={availabilitySchema}
          onSubmit={async (values) => {
            process(values.weekdays)
          }}
        >
          {({values, setFieldValue, errors}) => (
            <Form>
              <Box p={isMobile ? 0 : 2.4} mt={isMobile ? "14px" : ""}
                   style={{borderRadius: "10px", background: colors.darkBlue[900]}}>
                <Box sx={isMobile?{display: "flex", flexDirection: "row", justifyContent: "space-between"}:{}}>
                  <MDTypography style={isMobile ? {...styles.headerMobileText} : {...styles.headerWebText}}>Set Your
                    Availability</MDTypography>
                  <Box mt={isMobile?"":3} mb={0} sx={{...styles.rowCenter, gap:isMobile?"10px":""}}>
                    <img src={GlobeIcon} width={isMobile ? "14px" : "20px"}/>
                    <MDTypography style={{
                      color: colors.white.main,
                      marginLeft:isMobile?"":"20px",
                      fontSize: isMobile ? "14px" : "20px"
                    }}>{userTimezone}</MDTypography>
                  </Box>
                </Box>
                <MDBox mt={0} style={{overflowY: "scroll", height: isMobile ? "67.5vh" : "500px",}}>
                  <FieldArray name="weekdays">
                    {() => (
                      <Box style={{width: "100%"}}>
                        {values.weekdays.map((weekday, index) => (
                          <Box sx={{borderBottom: isMobile ? "1px solid #46515E" : ""}} key={"time-" + index}>
                            <Box>

                              <Field type="checkbox"
                                     label={weekday.name}
                                     name={`weekdays.${index}.checked`}
                                     checked={weekday.checked}
                                     onChange={(e) => {
                                       setFieldValue(`weekdays.${index}.checked`, e.target.checked)
                                     }}
                                     component={CustomCheckbox}/>

                            </Box>
                            <Box style={{...styles.rowCenter}}>
                              <Box style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <FieldArray name={`weekdays.${index}.ranges`}>
                                  {( { insert, remove, push }) => (
                                    <>
                                      {values.weekdays[index].ranges.length === 0 ? (
                                        <Box
                                          style={{...styles.rowCenter, gap: "38px", justifyContent: "space-between"}}>
                                          <Box style={{width: "100%", ...styles.rowCenter}}>
                                            <Box style={{width: "100%", ...styles.unavailable}}>
                                              <MDTypography style={{
                                                fontSize: '12px',
                                                color: colors.white.main,
                                                textAlign: "center",
                                                lineHeight: "14px"
                                              }}>UNAVAILABLE</MDTypography>
                                            </Box>
                                          </Box>
                                          <Button style={styles.actionButton} onClick={() => push(defaultTimeFrame)}>
                                            <img src={PlusRedIcon}/>
                                          </Button>
                                        </Box>


                                      ) : (
                                        <Box>
                                          {values.weekdays[index].ranges.map((time_frame, index2) => (
                                            <>
                                              <Box style={{...styles.rowCenter, gap: "8px"}}>
                                                <InputMask mask={"99:99 aa"}
                                                           value={values.weekdays[index].ranges[index2].from_time}
                                                           onChange={(e) => {
                                                             setFieldValue(`weekdays.${index}.ranges.${index2}.from_time`,
                                                               e.target.value)
                                                           }}>
                                                  {(inputProps) => (
                                                    <TextField
                                                      name={`weekdays.${index}.ranges.${index2}.from_time`}
                                                      style={{height: "30px"}}/>
                                                  )}
                                                </InputMask>
                                                <MDTypography style={{...styles.rowCenter}}>-</MDTypography>
                                                <InputMask mask={"99:99 aa"}
                                                           value={values.weekdays[index].ranges[index2].to_time}
                                                           onChange={(e) => {
                                                             setFieldValue(`weekdays.${index}.ranges.${index2}.to_time`,
                                                               e.target.value)
                                                           }}>
                                                  {(inputProps) => (
                                                    <TextField
                                                      name={`weekdays.${index}.ranges.${index2}.to_time`}
                                                      style={{height: "30px"}}/>
                                                  )}
                                                </InputMask>
                                                <Box style={styles.rowCenter}>
                                                  <Button style={styles.actionButton} onClick={() => remove(index2)}>
                                                    <img src={TrashIcon}/>
                                                  </Button>
                                                  <Button style={styles.actionButton}
                                                          onClick={() => push(defaultTimeFrame)}>
                                                    <img src={PlusRedIcon}/>
                                                  </Button>
                                                </Box>
                                              </Box>
                                              <ErrorMessage
                                                name={`weekdays.${index}.ranges.${index2}.from_time`}
                                                component="p"
                                                className={stylesCss.fieldError}
                                              />
                                              <ErrorMessage
                                                name={`weekdays.${index}.ranges.${index2}.to_time`}
                                                component="p"
                                                className={stylesCss.fieldError}
                                              />


                                            </>
                                          ))}
                                          <ErrorMessage
                                            name={`weekdays.${index}.ranges`}
                                            component="p"
                                            render={(res) => {
                                              console.log("RENDER", res)
                                              return <div
                                                className={stylesCss.fieldError}>{typeof res === "string" ? res : ""}</div>
                                            }}
                                            className={stylesCss.fieldError}
                                          />
                                        </Box>
                                      )}
                                    </>
                                  )}

                                </FieldArray>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </FieldArray>
                </MDBox>
              </Box>
              <Box style={{...styles.rowCenter, gap: "16px"}} mt={3}>
                <SecondaryButton text={"SAVE"}
                                 textStyles={{fontSize: "12px"}}
                                 type={"submit"}
                                 buttonStyle={{height: "40px", width: "120px"}}/>
                {!isMobile &&
                  <PrimaryButton text={"CUSTOM HOURS"}
                                 onClick={() => {
                                   setShowModal(true)
                                 }}
                                 textStyles={{fontSize: "12px"}}
                                 buttonStyle={{height: "40px", width: "145px"}}/>
                }
              </Box>
            </Form>
          )}
        </Formik>
      )}
      <AvailabilityModal open={ShowModal}
                         updateEvents={() => updateEvents()}
                         customRanges={customRanges}
                         onClose={() => setShowModal(false)}
                         isMobile={isMobile}
      />

    </Box>
  );
};

AvailabilityGrid.propTypes = {};

export default AvailabilityGrid;
