
function secondaryButtonStyle(theme, size, buttonStyle={}) {
  const { palette } = theme;
  let sizeStyle = {}
  if(size === "small" ){
    sizeStyle = {    minHeight: "25px !important", height:"25px !important"}
  }
  return {
    borderRadius: 1,
    width:"100%",
    backgroundColor:palette.secondary.main,
    border:`2px solid ${palette.primary.main}`,
    "&:hover": {
      borderColor:`${palette.primary.hover}`,
    },
    ...buttonStyle,
    ...sizeStyle,
  }
}

function secondaryButtonTextStyle(theme, size, fontWeight=400) {
  const { palette } = theme;
  const fontSize = size === "small"? "0.85rem":"1.25rem"
  return {
    borderRadius: 0,
    fontWeight:fontWeight,
    fontSize:fontSize,
    color: palette.primary.main,
    "&:hover": {
      color: palette.primary.hover,
    },

  }
}


export {  secondaryButtonStyle, secondaryButtonTextStyle };
