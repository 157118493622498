import { CustomTextField } from "../../../../components/TextField";
import React from "react";
import { useField } from "formik";


export const TextField = (props) => {
  const [field, meta, helpers] = useField(props);

  return (
    <CustomTextField
      {...field}
      {...props}
      error={meta.touched && meta.error}
      helperText={meta.touched && meta.error}
    />
  )
}
