import {Instance, SnapshotOut, types} from "mobx-state-tree"
import {withRootStore} from "../extensions/with-root-store";
import {fileFields, getFieldsByIdolType, OnboardingStoreFields} from "../fields";
import {withEnvironment} from "../extensions/with-environment";
import {parsePhoneNumber} from "libphonenumber-js";

const getViewsOnboardingStoreModel = (self: any) => ({
  get lastTimeVisited() {
    return new Date(self.last_time_visited);
  },
})

const getActionsOnboardingStoreModel = (self: any) => ({
  setLastURLVisited(url: string) {
    self.last_url_visted = url
    self.last_time_visited = new Date().toISOString()
  },
  setData(data: any) {
    Object.keys(data).forEach(key => {
      try {
        self[key] = data[key]
      } catch (e) {
        console.log("key", key)
        console.log("data[key]", data[key])
      }
    })
  },
  getIdolDataForBackend() {
    const dataForBackend: any = {}
    const fieldList = getFieldsByIdolType(self.idol_type)
    fieldList.forEach(field => {
      if (!fileFields.includes(field)) {
        dataForBackend[field] = self[field]
      }
    })
    const objectFields = [
      'ethnicity',
      'gender',
      'inc_country',
      'inc_state',
      'inc_city',
      'tax_country',
      'tax_state',
      'tax_city',
      'incorporated',
    ]
    const arrayFields = [
      'language',
    ]
    const dateFields = [
      'inc_date_of_birth',
    ]
    const phoneNumberFields = [
      'inc_phone_number',
      'tax_phone_number',
    ]
    fieldList
      .filter(element => objectFields.includes(element))
      .forEach(field => {
        try {
          dataForBackend[field] = self[field].id
        } catch (e) {
          console.log("field", field)
          console.log("self[field]", self[field])
        }
      })
    fieldList
      .filter(element => dateFields.includes(element))
      .forEach(dateField => {
        let [month, day, year] = self[dateField]?.split('/')
        dataForBackend[dateField] = `${year}-${month}-${day}`
      })
    fieldList
      .filter(element => arrayFields.includes(element))
      .forEach(field => dataForBackend[field] = self[field].map((el: any) => el.id))
    fieldList
      .filter(element => phoneNumberFields.includes(element))
      .forEach(field => dataForBackend[field] = parsePhoneNumber(self[field]).number)
    if (self['gender']) {
      dataForBackend['gender'] = self['gender'].id;
    }
    return dataForBackend
  },
  getIdolFilesForBackend() {
    const dataForBackend: any = {}
    const fieldList = getFieldsByIdolType(self.idol_type)
    fieldList.forEach(field => {
      if (fileFields.includes(field)) {
        dataForBackend[field] = self[field]
      }
    })
    return dataForBackend
  },
  reset() {
    Object.keys(OnboardingStoreFields).forEach((field: string) => {
      self[field] = null
    })
  },
})

/**
 * Model description here for TypeScript hints.
 */
export const OnboardingStoreModel = types
  .model("OnboardingStore")
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({...OnboardingStoreFields})
  .views(getViewsOnboardingStoreModel)
  .actions(getActionsOnboardingStoreModel)

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type OnboardingStoreType = Instance<typeof OnboardingStoreModel>

export interface OnboardingStore extends OnboardingStoreType {
}

type OnboardingStoreSnapshotType = SnapshotOut<typeof OnboardingStoreModel>

export interface OnboardingStoreSnapshot extends OnboardingStoreSnapshotType {
}
