import { useFormik } from 'formik';
import React from 'react';
import AddCreditCardView from './AddCreditCardView';
import { initialValues } from './initialValues';
import * as Yup from "yup";
import { getCardType } from "../../utils/functions";
import { useApi } from "../../utils/hooks";
import { notifyError, notifySuccess } from "../../services/notification";

const requiredMessage = "This field is required"

Yup.addMethod(Yup.string, "creditCardType", function (errorMessage) {
  return this.test(`test-card-type`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      getCardType(value).length > 0 ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "creditCardLength", function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && value.replaceAll("-","").length >= 13 && value.replaceAll("-","").length <= 16) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, "creditCardExpiryDate", function (errorMessage) {
  return this.test(`test-card-expiry`, errorMessage, function (value) {
    const { path, createError } = this;
    return (
      (value && parseInt(value.split("/")[0]) <= 12) ||
      createError({ path, message: errorMessage })
    );
  });
});

 const validationSchema = Yup.object({
   cardHolder: Yup
    .string()
    .required(requiredMessage),
   expiryDate: Yup
    .string()
     .creditCardExpiryDate("Invalid Month")
    .required(requiredMessage),
   CVV: Yup
    .string()
     .min(3)
     .max(4)
    .required(requiredMessage),
   cardNumber: Yup.string()
     .creditCardType("We do not accept this card type")
     .creditCardLength('Invalid card number length')
     .required(requiredMessage),
   phoneNumber: Yup
    .string()
    .required(requiredMessage),
   address1: Yup
     .string()
     .required(requiredMessage),
   stateProvice: Yup
     .string()
     .required(requiredMessage),
   ZIP: Yup
     .string()
     .required(requiredMessage),
   country: Yup
     .number()
     .required(requiredMessage),
});
function AddCreditCard({refreshList}) {

  const api = useApi()

  const saveCreditCard = (values)=>{
    let data = {
      card_type: getCardType(values.cardNumber),
      card_number: values.cardNumber.replaceAll("-", "").replaceAll("_",""),
      holders_name: values.cardHolder,
      expiry_month: values.expiryDate.split("/")[0],
      expiry_year: values.expiryDate.split("/")[1],
      cvv: values.CVV,
      phone_number: values.phoneNumber,
      address_1: values.address1,
      address_2: values.address2,
      state_province: values.stateProvice,
      zip: values.ZIP,
      country: values.country,
    }
    
    api.addCard(data).then(response =>{
      if(response.kind === "ok"){
        notifySuccess("Credit card added successfully")
        formik.resetForm()
        refreshList()
      }else{
        notifyError("Can't add this credit card")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Can't add this credit card")
    })
  }

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange:false,
    validationSchema:validationSchema,
    onSubmit: values => saveCreditCard(values)
  })
  
  return (
    <AddCreditCardView formik={formik} />
  )
}

export default AddCreditCard;
