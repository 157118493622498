import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from '@fullcalendar/interaction'
import "./fullcalendar.css"
import moment from "moment";

const Calendar = forwardRef((props, ref) => {
  const {setTitle,events, getEvents} = props
  const {innerWidth: width, innerHeight: height} = window;
  const [calendarApi, setCalendarApi] = useState(null)
  const [CalendarHeight, setCalendarHeight] = useState("660px" )
  const CalendarEl = useRef(null);


  useImperativeHandle(ref, () => ({
    goToNext() {
      next()
    },
    goToPrevious() {
      previous()
    }
  }));



  const initCalendar = () => {
    let api = CalendarEl.current.getApi()
    setCalendarApi(api)
    setTitle(api.currentDataManager.data.viewTitle)
  }

  useEffect(() => {
    initCalendar()

  }, [])


  const next = () => {

    calendarApi.next()
    let current = calendarApi.currentDataManager.data.dateProfile.currentRange
    getEvents({
      from_time:moment(current.start).add(1,'day').format('yyyy-MM-DD'),
      to_time:moment(current.end).format('yyyy-MM-DD'),
    })
    setTitle(calendarApi.currentDataManager.data.viewTitle)

  }

  const previous = () => {

    calendarApi.prev()
    let current = calendarApi.currentDataManager.data.dateProfile.currentRange
    console.log("previous", {})
    getEvents({
      from_time:moment(current.start).add(1,'day').format('yyyy-MM-DD'),
      to_time:moment(current.end).format('yyyy-MM-DD'),
    })
    setTitle(calendarApi.currentDataManager.data.viewTitle)
  }



  return (
    <Box >
      <FullCalendar
        ref={CalendarEl}
        headerToolbar={{
          end: '',
          start: '',
          center: '',
        }}
        contentHeight={"100px"}
        showNonCurrentDates={false}
        eventColor={"transparent"}
        // eventContent={renderEventContent}
        height={CalendarHeight}
        dayHeaderFormat={{weekday: 'short'}}
        selectable={false}
        plugins={[dayGridPlugin, interactionPlugin, momentTimezonePlugin]}
        events={events}
        initialView="dayGridMonth"
      />
    </Box>
  );
})

Calendar.propTypes = {

};

export default Calendar;
