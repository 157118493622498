import colors from "../../../../assets/theme/base/colors";

export const desktopStyles = {
  listContainer:{
    overflow:"scroll",
    width:"100%",
    alignContent:"center",
    marginTop:"34px",
    paddingLeft:"20%",
    paddingRight:"20%",
    gap:"30px"
  },
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"20px",
    fontSize:20,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1em"
  }
}

export const mobileStyles = {
  listContainer:{
    height:"auto",
    overflow:"scroll",
    alignContent:"center",
    marginTop:"0px",
    gap:"30px",
    padding:"16px"
  },
  title:{
    textAlign:"left",
    marginTop:"15px",
    fontSize:14,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1em",
    paddingLeft:"16px",
    paddingRight:"16px"
  }
}
