import React, { useState } from "react";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {useApi, useUserStore} from "../../../utils/hooks";
import { AvatarOverImage } from "../../../components/AvatarOverImage";
import checked from "../../../assets/icons/checked.png";
import numeral from "numeral";
import GoBackIcon from "../../../assets/icons/chevron_back.svg";
import BustIcon from "../../../assets/icons/bust.svg";
import HipIcon from "../../../assets/icons/hip.svg";
import WaistIcon from "../../../assets/icons/waist.svg";
import UpIcon from "../../../assets/icons/arrow_up.svg";
import DownIcon from "../../../assets/icons/arrow_down.svg";
import { BodyShapeCard, DetailCard, SizeBox } from "./components";
import {desktopStyles, mobileStyles} from "./styles/leftColumnStyles"

const heightTransform = (value)=>{
  if (!value){
    return 0
  }
  const values = value.split(".")
  return `${values[0]}'${values[1][0]}"`
}

const shoeSizeTransform = (value)=>{
  return `${Math.round(value * 10) / 10}`
}

const languageTransform = (languages)=>{
  return (
    <Grid container style={{display:"flex", flexDirection:"row", marginLeft:"-10px"}}>
      {languages && languages.map((value)=>(
        <Grid item style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"4px"}} ml={2}>
          <Box style={{background:"white"}} height={"5px"} width={"5px"} borderRadius={"50%"} />
          <Typography style={{fontWeight:400, fontSize:16}}>{value.name}</Typography>
        </Grid>
      ))}
    </Grid>
  )
}
export const LeftColumn = ({ isMobile, profile })=>{
  const [collapse, setCollapse] = useState(true)
  const api = useApi();
  const userStore = useUserStore();

  const navigate = useNavigate()
  const styles = isMobile? mobileStyles : desktopStyles

  return (
    <React.Fragment>
      <Box style={styles.main}>
        <img src={GoBackIcon} style={styles.arrow} onClick={() => navigate(-1)} />
        <Box style={styles.section1}>
          <AvatarOverImage
            avatarImage={profile.image}
            isMyProfile={profile?.id === userStore?.id}
            isIdol={Boolean(profile?.idol_type)}
          />
          <Box style={styles.basicInfo}>
            <Typography color={"white"} style={styles.username}>
              {profile.username}
              {profile.isVerified && (
                <img src={checked} style={styles.verified} />
              )}
            </Typography>
            <Box style={styles.idolPointsContainer}>
              <Typography color={"white"} style={styles.idolPoints}>
                {numeral(profile.idolPoints).format('0,0')}
              </Typography>
              <Typography color={"white"} style={styles.idp}>idp</Typography>
            </Box>
            <Box style={styles.tagsContainer}>
              {profile.tags?.map((value, index)=>(
                <>
                  {index > 0 && (
                    <Typography style={{...styles.tags, margin:"0px 4px 0px 4px"}}> | </Typography>
                  )}
                  <Typography style={styles.tags}>{value}</Typography>
                </>
              ))}
            </Box>
            <Typography style={styles.location}>{profile.location}</Typography>
          </Box>
        </Box>


        <Box style={styles.section2}>
          <Typography style={styles.bio}>{profile.bio}</Typography>
          {isMobile && (
            <Box mt={3} style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}} onClick={()=>setCollapse(!collapse)}>
              <Typography style={{textDecoration:"underline", fontSize:14, color:"white", fontWeight:600}}>
                Idol Specifics
              </Typography>
              <Box component={"img"} ml={1} width={12} height={12} src={collapse? UpIcon : DownIcon} />
            </Box>
          )}
          <Collapse in={collapse}>

            <Box style={styles.sizesRow}>
              <SizeBox value={profile.age ?? 0} label={"Age"} />
              <SizeBox value={heightTransform(profile.height)} label={"Height"} />
              <SizeBox value={shoeSizeTransform(profile.shoeSize)} label={"Shoe Size"} />
            </Box>
            <Box style={styles.shapeRow}>
              <BodyShapeCard value={parseInt(profile.bust || 0)} icon={BustIcon} />
              <BodyShapeCard value={parseInt(profile.waist || 0)} icon={WaistIcon} />
              <BodyShapeCard value={parseInt(profile.hips || 0)} icon={HipIcon} />
            </Box>
            <Box style={styles.detailColumn}>
              <DetailCard label={"Gender"} content={profile.gender?.name}/>
              <DetailCard label={"Ethnicity"} content={profile.ethnicity?.name}/>
              <DetailCard label={"Languages"} content={languageTransform(profile.language)}/>
            </Box>
          </Collapse>
        </Box>

      </Box>
    </React.Fragment>
  )
}

