import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {CustomFormikTextField} from "../../../components/TextField";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {BasicCheckbox} from "../../../components/BasicCheckbox";
import {FormHelperText, Stack, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CustomAutocompleteFormik} from "../../../components/AutocompleteFormik";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useOnboardingStore} from "../../../utils/hooks";
import {getFormikValidationFields, getFormikValidationBooleanFields, useStores} from "../../../models";
import {notifyError} from "../../../services/notification";
import {showAllErrors} from "../../../services/helpers";
import {UserTypes} from "../../../constants";
import {IdolizerPaths} from "../../../paths/idolizerPaths";
import {guestRoutes} from "../../../routes/guestRoutes";
import {GuestPaths} from "../../../paths/guestPaths";

const {white} = colors;

const Title = () => {
  return (
    <MDBox style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
      <MDTypography color={"white"} fontWeight={"bold"} sx={{fontSize: 94}}>ENTER</MDTypography>
      <MDTypography ml={3} color={"white"} fontWeight={"light"} sx={{fontSize: 105}}>TOKEN</MDTypography>
    </MDBox>)
}
const TokenPassword = observer(() => {
  const onboardingStore = useOnboardingStore()
  const {loginStore} = useStores()
  const api = onboardingStore.environment.api
  const navigate = useNavigate()
  const {state} = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tokenFields = [
    'token',
  ];
  const initialValues = {}
  tokenFields.forEach(field => initialValues[field] = "");

  const sendData = (values) => {
    loginStore.setPasswordToken(values.token);
    navigate(GuestPaths.updatePassword);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getFormikValidationFields(tokenFields),
    onSubmit: (values) => {
      sendData(values)
    },
  });

  return (
    <OnboardingLayout titleBold={"ENTER"} titleNormal={"TOKEN"} backTo={(state && state.fromURL) || GuestPaths.forgotPassword}>
      <MDTypography fontWeight={"light"} sx={isMobile ? styles.subtitleMobile : styles.subtitle}>
       Please enter the token that was sent to your email address.
      </MDTypography>
      <MDBox mt={isMobile ? 4 : 2} width={isMobile ? "100%" : "400px"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="token" formik={formik}/>            
          </Stack>
          <MDBox mb={4} mt={4}>
            <PrimaryButton type={"submit"} text={"SUBMIT"}/>
          </MDBox>
        </form>
      </MDBox>
    </OnboardingLayout>
  );
});

const styles = {
  defaultText: {
    color: white.main,
    fontSize: 16,
  },
  link: {
    textDecoration: "underline",
    textAlign: "right",
  },
   subtitle:{
    fontSize:14,
    letterSpacing:"0.1em",
    width:"480px",
    color:colors.white.main
  },
  subtitleMobile:{
    fontSize:12,
    letterSpacing:"0.1em",
    width:"100%",
    color:colors.white.main
  },
}

export default TokenPassword;
