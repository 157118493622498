/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {RecordCard} from 'components/RecordCard/RecordCard';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';

/* assets */
import GreyLogo from "assets/images/idol_grey.png";
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {date, tertiary, white, modal: {confirm_payment}} = colors;

export const IdolizerVerificationCard = ({user, navigateToUserVerification}) => {

  const recordProps = {
    showOptionsCard: false,
    containerStyles: styles.record,
    contentContainerStyles: styles.contents_container,
    cardContentsStyle: styles.contents,
    cardThirdContentStyles: styles.third_content,
    iconStyles: styles.third_content_icon
  };

  const stylesProps = {
    infoCardStyles: styles.info_card,
    avatarStyles: styles.avatar,
    nameStyles: styles.name,
    pointStyles: styles.points,
    idpStyles: styles.points,
  };

  return (
    <RecordCard
      {...recordProps} 
      cardFirstContent={
        <BaseCardIdolInfo
          {...{
            idolPoints: user?.idol_points,
            idolizerName: `${user?.first_name || user?.username} ${user?.last_name ?? ''}`,
            avatar: user?.image,
          }}
          {...stylesProps}
        />
      }
      cardSecondContent={<Typography sx={styles.email}>{user?.email}</Typography>}
      iconCard={GreyLogo}
      cardThirdContent={(
        <Box sx={styles.view_box} onClick={() => navigateToUserVerification(user?.id)}>
            <Typography sx={styles.view_box_text}>View</Typography>
        </Box>
      )}
    />
  )
}

const styles = {
  record: {
    marginTop: 0,
  },
  contents_container: {
    display: "grid",
    gridTemplateColumns: "2fr 0.08fr 1.5fr",
    justifyContent: "flex-start",
  },
  contents: {
    width: "100%",
  },
  info_card: {
    padding: 0,
  },
  name: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  points: {
    fontSize: pxToRem(14),
    fontWeight: 500,
  },
  avatar: {
    width: pxToRem(49),
    height: pxToRem(49),
  },
  email: {
    color: date,
    fontSize: pxToRem(16),
    fontWeight: 400,
  },
  third_content: {
    background: confirm_payment.background,
    gap: pxToRem(28),
    justifyContent: "initial",
    width: pxToRem(250),
    padding: `${pxToRem(22)} ${pxToRem(12)}`,
    left: 0,
  },
  third_content_icon: {
    order: -1,
  },
  view_box: {
    alignItems: "center",
    background: tertiary.main,
    borderRadius: "10px",
    cursor: "pointer",
    display: "flex",
    height: pxToRem(32),
    justifyContent: "center",
    padding: `${pxToRem(8)} ${pxToRem(20)}`,
    width: pxToRem(122),
  },
  view_box_text: {
    color: white.main,
    fontSize: pxToRem(14),
    fontWeight: 400,
  },
};