import pxToRem from "assets/theme/functions/pxToRem";
import colors from "../../assets/theme/base/colors";

export const styles = {
  logo_img: {
    width: pxToRem(24),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    backgroundColor: colors.grey["1000"],
    borderRadius: '10px',
    height: '77px',
  },
  content_container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  },
  titleCard: {
    color: colors.grey["650"],
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',
    paddingLeft: '16px'
  },
  titleCardMobile: {
    paddingLeft: 0,
  },
  title: {
    backgroundColor: colors.grey["1000"],
    color: colors.grey["480"],
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '21px',
    paddingLeft: '20px'
  },
  divider: {
    backgroundColor: colors.background.default,
    color: colors.background.default,
    width: '8px',
    height: '52px',
    borderRadius: '8px',
    opacity: 0.2,
  },
  dividerMobile: {
    width: '4px',
  },
  dateCard: {
    color: colors.grey["650"],
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    width: '50%',
  },
  date: {
    backgroundColor: colors.grey["1000"],
    color: colors.grey["480"],
    textAlign: 'center',
    fontSize: '18px',
  },
  amountCard: {
    width: pxToRem(400),
    height: '78px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: colors.withdrawlcard.darkblue,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    left: '8px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    position: 'relative',
  },
  amount: {
    color: colors.withdrawlcard.softgrey,
    fontSize: '25px',
    textAlign: 'center',
  },
  suscription_duration: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    color: colors.white.main
  },
  suscription_value: {
    fontWeight: 400,
    fontSize: pxToRem(22),
    color: colors.white.main
  },
  threeDots: {
    color: colors.withdrawlcard.softgrey,
    cursor: 'pointer',
    height: '32px',
    width: '32px'
  },
  dollarImage: {
    height: '30px',
    width: '30px'
  },
  cursorPointer: {
    cursor: "pointer",
  },
};