import {useState} from "react";
import {useFormik} from "formik";

// Components
import {ProfileCard} from "../../../edit-profile/components";
import {CustomFormikTextField} from "components/TextField";
import {DownloadFile} from "components/DownloadFileCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Utils
import {getIdolizerDetailsValues} from "../utils";
import {tabSection} from "../constants";

// Assets
import pxToRem from "assets/theme/functions/pxToRem";
import NoImage from "assets/images/explore-default.png";

export const IdolizerVerification = ({user}) => {

  const formik = useFormik({
    initialValues: getIdolizerDetailsValues(user),
    enableReinitialize: true,
  })

  const [currentTab, setCurrentTab] = useState(tabSection.None);

  const handleChangeTab = (value) => {
    if(currentTab === value) return setCurrentTab(tabSection.None);
    setCurrentTab(value);
  }

  return (
    <MDBox mt={pxToRem(24)}>
      <MDBox display='flex' alignItems='center' gap={pxToRem(65)} mt={pxToRem(24)}>
        <MDBox display='flex' alignItems='center' gap={pxToRem(21)}>
          <MDBox component='img' src={user?.image ?? NoImage} width={pxToRem(49)} height={pxToRem(49)} sx={{borderRadius: '50px'}} alt={`${user?.username} image`}/>
          <MDBox display='flex' flexDirection='column' gap={pxToRem(5)}>
            <MDTypography color='light' size='md' fontWeight='semiMedium'>
              {`${user?.first_name || user?.username} ${user?.last_name ?? ""}`}
            </MDTypography>
            <MDTypography color='primary' variant='button' fontWeight='semiMedium'>{user?.idol_points} idp</MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography color='gray' size='md' fontWeight='regular'>{user?.email}</MDTypography>
      </MDBox>
      <MDBox width={pxToRem(562)} mt={pxToRem(47)}>
        <MDBox display='flex' flexDirection='column' gap={pxToRem(14)}>
          <ProfileCard title='IDOLIZER Verify' isExpanded={currentTab === tabSection.IdolizerVerify} onChange={() => handleChangeTab(tabSection.IdolizerVerify)} background='light'>
            <DownloadFile />
          </ProfileCard>
          <ProfileCard title='IDOLIZER Details' isExpanded={currentTab === tabSection.IdolizerDetails} onChange={() => handleChangeTab(tabSection.IdolizerDetails)} background='light'>
            <CustomFormikTextField name='professional_phone_number' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='phone_number' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='address' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='linkedin_link' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='job_role' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='employer' formik={formik} inputProps={{readOnly: true}} />
            <CustomFormikTextField name='professional_email' formik={formik} inputProps={{readOnly: true}} />
          </ProfileCard>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

