import React from 'react';
import { CHAT_ACTIONS } from "./utils/constants";
import { chatReducer } from "./utils/reducers";
import ChatContext from "./ChatContext";

const init = (appState) => appState;

const ChatProvider = ({children}) => {
    const [chatApp, setChatApp] = React.useReducer(chatReducer, {
        selectedConversation: false,
        recipient: null,
        loading:false,
        incomingMessage: null,
    }, init);

    const setSelectedConversation = React.useCallback((selectedConversation) => {
        setChatApp({type: CHAT_ACTIONS.SET_SELECTED_CONVERSATION, payload: selectedConversation})
    }, [setChatApp])

    const setRecipient = React.useCallback((recipient) => {
        setChatApp({type: CHAT_ACTIONS.SET_RECIPIENT, payload: recipient})
    }, [setChatApp]);

    const setLoading = React.useCallback((loading) => {
        setChatApp({type: CHAT_ACTIONS.SET_LOADING, payload: loading})
    }, [setChatApp]);

    const setIncomingMessage = React.useCallback((message) => {
        setChatApp({type: CHAT_ACTIONS.SET_INCOMING_MESSAGE, payload: message})
    }, [setChatApp]);

    const contextValue = React.useMemo(() => ({
        ...chatApp,
        setSelectedConversation,
        setRecipient,
        setLoading,
        setIncomingMessage
    }), [chatApp, setIncomingMessage, setLoading, setRecipient, setSelectedConversation]);

    return (
      <ChatContext.Provider value={contextValue}>
          {children}
      </ChatContext.Provider>
    );
};

export default ChatProvider;
