import { CHAT_ACTIONS } from "./constants";

export const chatReducer = (state, action) => {
  switch (action.type) {

    case CHAT_ACTIONS.SET_SELECTED_CONVERSATION:
      return {
        ...state,
        selectedConversation: action.payload,
      };
    case CHAT_ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
      case CHAT_ACTIONS.SET_INCOMING_MESSAGE:
      return {
        ...state,
        incomingMessage: action.payload,
      };
    default:
      return state;
  }
};
