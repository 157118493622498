/* Dependencies */
import { useFormik } from "formik";
import React, { memo, useEffect, useState } from "react";
import * as Yup from 'yup';

/* Layouts */
import { BaseModal } from 'base/BaseModal';

/* Components */
import Select from 'components/CustomSelect';
import MDBox from 'components/MDBox';
import RoundCheck from 'components/RoundCheck';
import { SecondaryButton } from "components/SecondaryButton";
import { CustomTextField } from 'components/TextField';

/* Assets */
import colors from "assets/theme/base/colors";
import moment from "moment";
import { MeetingTypeEnum } from "../../utils/constants";
import { formatDurationFromMinutes } from "../../utils/dateUtils";
import { LiveCalOptions, MeetAndGreetOptions } from "./constants";
import { styles } from './styles';
const {modal, white} = colors;



const ValidationSchema = Yup.object().shape({
  meeting_type: Yup.string().required('The event type is required'),
  name: Yup.string().required('The event name is required'),
  description: Yup.string().required('The event description is required'),
  duration: Yup.number().required('Event duration is required'),
  fee: Yup.number().required('Price of the event is required'),
});


const CreateMeetingType = ({
                       open,
                       onClose,
                       onSave,
                       selectedEvent,
                       submitFunction,
                     }) => {

  const [durationOptions, setDurationOptions] = useState([...LiveCalOptions, ...MeetAndGreetOptions]);
  

  const formik = useFormik({
    initialValues: selectedEvent,
    validationSchema:ValidationSchema,
    onSubmit: values => {
      saveEvent(values)
    }
  })

  useEffect(()=> {
    formik.setValues(selectedEvent);
  }, [selectedEvent])

  const saveEvent = (values)=>{
    let data = {
      id: selectedEvent.id,
      name: values.name,
      description: values.description,
      fee: values.fee,
      meeting_type: values.meeting_type,
      duration: values.duration
    }
    //
    let duration = moment().startOf("day")

    if(values.meeting_type === MeetingTypeEnum.liveCall){
      duration.add("minutes", parseInt(values.duration))
    }else{
      duration.add("minutes", parseInt(values.duration) * 60)
    }

    data["duration"] = formatDurationFromMinutes(+values.duration)

    submitFunction(data);
  }


  return (
    <BaseModal open={open} onClose={onClose} title="CREATE EVENT" centerContent={false}>
      <form onSubmit={formik.handleSubmit} style={{padding:"0 50px 0 50px"}}>
        <MDBox style={{display:"flex", flexDirection:"row", justifyContent:"center", gap:"24px"}}>
          <RoundCheck
            radioButtonSize="18px"
            label="Live Call"
            labelFontSize="1.2rem"
            onClick={() => {
              formik.setFieldValue('meeting_type', MeetingTypeEnum.liveCall)
              // formik.setFieldValue('duration', LiveCalOptions[0].value)
              // setDurationOptions(LiveCalOptions)

            }}
            checked={formik.values.meeting_type === MeetingTypeEnum.liveCall}
          />
           <RoundCheck
              radioButtonSize="18px"
              label="Meet & Greet"
              labelFontSize="1.2rem"
              onClick={() => {
                formik.setFieldValue('meeting_type', MeetingTypeEnum.meetAndGreet)
                // formik.setFieldValue('duration', MeetAndGreetOptions[0].value)
                // setDurationOptions(MeetAndGreetOptions)                
              }}
              checked={formik.values.meeting_type === MeetingTypeEnum.meetAndGreet}
            />
        </MDBox>
        <MDBox mt={2}>
          <CustomTextField
            inputLabel="Event Name"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </MDBox>
        <MDBox mt={2}>
          <CustomTextField
            inputLabel="Description"
            id="description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </MDBox>
        <MDBox mt={2}>
          <Select
            label="Duration"
            defaultValue=''
            name={"duration"}
            id={"duration"}
            value={formik.values.duration}
            onChange={formik.handleChange}
            options={[...durationOptions, ]}
            error={formik.touched.duration && Boolean(formik.errors.duration)}
            helperText={formik.touched.duration && formik.errors.duration}
          />
        </MDBox>
        <MDBox sx={styles.flex_section} mt={2}>
          <MDBox sx={{width: '100%'}}>
            <CustomTextField
              inputLabel="Price"
              id="fee"
              name="fee"
              placeholder="$"
              onChange={formik.handleChange}
              value={formik.values.fee}
              type='number'
              error={formik.touched.fee && Boolean(formik.errors.fee)}
              helperText={formik.touched.fee && formik.errors.fee}
            />
          </MDBox>
        </MDBox>
        <MDBox sx={styles.actions_section} mt={4}>
          <SecondaryButton
            text="CANCEL"
            onClick={() => [onClose(), formik.resetForm()]}
            padding={0.5}
            backgroundColor={colors.transparent.main}
            borderColor={modal.buttons.cancel}
            textStyles={{ color: modal.buttons.cancel, fontSize: ".8rem", fontWeight: "700" }}
          />
          <SecondaryButton type="submit" text="SAVE" padding={0.5} textStyles={{ fontSize: ".8rem", fontWeight: "700" }} />
        </MDBox>
      </form>
   </BaseModal>
  );
};

export const CreateMeetingTypeModal = memo(CreateMeetingType);

