import { useTheme } from "@emotion/react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { BaseModal } from "base/BaseModal";
import { SecondaryButton } from "components/SecondaryButton";
import { PropTypes } from "mobx-react";
import React from "react";
import colors from "assets/theme/base/colors";
import { styles } from "./styles";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import idolIcon from "../../assets/icons/idolmarca.svg";
import avatarExample from "../../assets/images/brooklyn.png";
import clock from "../../assets/images/clock.png";

const MeetingModal = ({
  open,
  onClose,
  title,
  confirmAction,
  meetingPlace,
  meetingAmount,
  meetingDate,
  meetingHourStart,
  meetingHourEnd,
  idolName,
  idolAvatar,
  idolPoints,
  confirmLabel,
  cancelLabel,
  cancelAction,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const baseProps = {
    open,
    onClose,
    title,
    content: styles.content,
    containerStyles: styles.container,
    modalWidth: styles.container.width,
    headerStyles: styles.header,
    ...styles.content_container,
  };
  return (
    <BaseModal {...baseProps}>
      <Box sx={styles.idolCardContainer}>
        <BaseCardIdolInfo
          idolizerName={idolName}
          avatar={idolAvatar}
          isVerified={true}
          isIdolizer={false}
          idolPoints={idolPoints}
        />
        <Box display='flex'>
          <Typography sx={styles.meetingPlace}>{meetingPlace}</Typography>
          <Box component="img" sx={styles.idolIconSize} src={idolIcon} />
        </Box>
      </Box>
      <Box sx={styles.dateTimeContainer}>
        <Box sx={styles.dateTime}>
          <Box component="img" sx={styles.clockIcon} src={clock} />
          <Box>
            <Typography sx={styles.meetingDate}>{meetingDate}</Typography>
            <Typography sx={styles.meetingDate}>{meetingHourStart} - {meetingHourEnd}</Typography>
          </Box>
        </Box>
        <Typography sx={styles.meetingAmount}>${meetingAmount}</Typography>
      </Box>
      <Box sx={styles.actions_container}>
        <Box sx={{ width: "145px" }}>
          <SecondaryButton
            text="CANCEL MEETING"
            onClick={() => onClose()}
            padding={0}
            backgroundColor={colors.transparent.main}
            textStyles={styles.cancel}
            borderColor={colors.modal.buttons.cancel}
          />
        </Box>
        <Box sx={{ width: "292px" }}>
          <SecondaryButton
            text="RESCHEDULE MEETING"
            onClick={confirmAction}
            textStyles={styles.confirm_text}
            sx={styles.confirm}
          />
        </Box>
      </Box>
    </BaseModal>
  );
};

export default MeetingModal;

MeetingModal.propTypes = {
  confirmAction: PropTypes.func,
  meetingPlace: PropTypes.string,
  meetingAmount: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
};

MeetingModal.defaultProps = {
  title: "IDOLMEET  ",
  meetingPlace: "Somewhere, Idolmeet",
  confirmLabel: "REESCHEDULE",
  cancelLabel: "CANCEL",
};
