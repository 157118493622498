import {Box, Typography} from "@mui/material";
import {InputAdornment} from "@mui/material";
import {useUserStore} from "../../../utils/hooks";
import React, {memo, useState} from "react";
import {addCommas} from "../../../utils/functions";
import {SearchBox} from "components/SearchBox";
import SearchIcon from 'assets/icons/Search_Icon.svg';
import colors from 'assets/theme/base/colors';
import IdolMarca from "../../../assets/icons/idolmarca.svg";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {SecondaryButton} from "../../SecondaryButton";

const {white, red} = colors;

export const MobileSectionHeader = ({Title, Icon, containerStyles, ShowLogo = true, Button=false, onClick, SearchProps}) => {
  const [showSearchBox, setShowSearchBox] = useState(false)

  return (
    <Box style={{...styles.container, ...containerStyles}}>
      <Box sx={styles.primary_content_container}>
        <Box sx={styles.title_container}>
          {Icon && (
            <Box sx={styles.image_container}>
              <img src={Icon} alt='icon' style={styles.img}/>
            </Box>
          )}
          <Box sx={styles.text_contentContainer}>
            <Typography variant="h1" sx={styles.title} color={white.main}>
              {Title}
            </Typography>
            <Box sx={styles.button_logoContainer}>
              {Button && (
              <Box sx={styles.buttonContainer}>
              <SecondaryButton
                onClick={onClick}
                text={"CUSTOM HOURS"}
                buttonStyle={styles.button}
                textStyles={styles.buttonText}
              />
              </Box>
              )}
              {SearchProps &&
                <InputAdornment position="end" onClick={() => setShowSearchBox(!showSearchBox)}>
                  <img alt='src' src={SearchIcon} style={styles.icon} />
                </InputAdornment>
              }
            {ShowLogo && (
              <Box sx={styles.image_container2}>
                <img src={IdolMarca} alt='icon' style={styles.img}/>
              </Box>
            )}
            </Box>
          </Box>
        </Box>
      </Box>
      {showSearchBox &&
        <SearchBox placeholder={"Search for Idols..."}
          inputStyles={{ width: "100%", fontSize: 14, color: "white", marginTop: '10px' }}
          InputProps={{ maxLength: 10 }}
          showAdornment={false}
          {...SearchProps}
        />
      }
    </Box>
  )
}


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  primary_content_container: {
    borderBottom: `2px solid ${red.main}`,
    paddingBottom: 2,
  },
  image_container: {
    height: '28px ',
  },
  image_container2: {
    height: '30px ',
  },
  title_container: {
    alignItems: 'center',
    display: 'flex',
    gap: 2,
    justifyContent: 'space-between',
    height: "36px",
  },
  text_contentContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: "36px",
    alignItems: 'center',
  },
  img: {
    height: '100%',
  },
  title: {
    color: white.main,
    fontWeight: 100,
    fontSize: pxToRem(24),
  },
  button_logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    gap: "13px"
  },
  buttonContainer: {
    maxWidth: "100px !important",
    width: "100px"
  },
  button: {
    height: "32px",
    maxHeight: "32px !important",
    paddingX: 0,
    borderRadius: "5px"
  },
  buttonText: {
    fontSize: "10px",
    lineHeight: "9px",
    fontWeight: 700,
    letterSpacing: "0.1em",
    alignSelf: "center",
  },
  icon: {
    height: '20px',
    width: '20px',
  },
}
