import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import MDTypography from "../MDTypography";
import TrashIcon from "../../assets/icons/rounded_trash.svg";
import Cropper from "react-easy-crop";

import {BaseModal} from "../../base/BaseModal";
import rgba from "../../assets/theme/functions/rgba";
import getCroppedImg from "./cropImage";
import {useIsMobile} from "../../utils/hooks";
import colors from "../../assets/theme/base/colors";

export const UploadPhotoWithCropper = ({
                                         text,
                                         src,
                                         formik,
                                         handleUploadPhoto,
                                         containerStyles,
                                         boxStyles,
                                         children,
                                         openModal,
                                         handleClose,
                                         handleConfirm,
                                         handleOpen,
                                         sendVideo,
                                         uploadingName,
                                         photo,
                                         videoSrc,
                                         setPhoto,
                                         setVideoSrc,
                                       }) => {
  const isMobile = useIsMobile()



  const inputRef = useRef(`input_${Math.random().toString().substring(2, 15)}`);
  const [fileName, setFileName] = useState(text);
  let postImg

  //cropper stuff
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0)
  const [imageSrc, setImageSrc] = useState("")
  const [isVideo, setIsVideo] = useState(false)

  //onCropComplete
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  //showCroppedImage
  // const [croppedImage, setCroppedImage] = useState(null)
  // const [ready, setReady] = useState(false);
  // const [tempFile, setTempFile] = useState({});

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedAreaPixels, croppedArea, "crop area")
    console.log(croppedAreaPixels.width, croppedAreaPixels.height, "w/h")
  }, [])

  const lackWidth = (croppedAreaPixels?.width < 720)
  const lackHeight = (croppedAreaPixels?.height < 1280)

  const showCroppedImage = useCallback(async () => {
    try {
      const {croppedImageURL, file} = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      )

      console.log('donee', { croppedImageURL, file })
      setZoom(1)
      setPhoto(croppedImageURL)
      handleClose()
      handleUploadPhoto(file)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels])


  //read file to crop it
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  //input on file change
  const onFileChange = async (e) => {
    console.log(e, "file changed")
    if (e) {
      if ((e.type === "image/jpeg") || (e.type === "image/jpg") || (e.type === "image/png") || (e.type === "image/webp")) {
        setIsVideo(false)
        await readFile(e)
        setImageSrc(e.preview)
        handleOpen()
      }
      else if ((e.type === "video/mp4") || (e.type === "video/webm") || (e.type === "video/mpeg")) {
        console.log("you are trying to upload a video, this functionality is not yet done. TODO: cropping on backend")
        setIsVideo(true)
        sendVideo(true)
        await readFile(e)

        setVideoSrc(e.preview)
        handleUploadPhoto(e)
        // handleOpen()
        getVideoCropArea()
      }
    }
  }

const getVideoCropArea = () => {
//do something
}

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/webp": [".webp"],
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/webm": [".webm"],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles[0], "accepted something")
      postImg = acceptedFiles[0];
      // handleUploadPhoto(postImg); this sends the image to parent component
      onFileChange(acceptedFiles[0])
      postImg.preview = URL.createObjectURL(postImg);
      uploadingName(postImg.name);
    },
  });

  const handleDelete = () => {
    setFileName(text);
    setPhoto(null);
    setVideoSrc(null);
    formik.setFieldValue("photo", "");
  };

  return (
    <Box>
      <Box sx={[styles.container, containerStyles]}>
        <div {...getRootProps()}>
          <label htmlFor={inputRef.current}>
            <Box padding="1rem" sx={[styles.boxStyle, boxStyles]}>
              {((photo !== null) || (videoSrc !== null)) && (
                <>
                  {isVideo ?
                    <Box
                      component="video"
                      src={videoSrc}
                      alt="video-preview"
                      sx={styles.previewUploadedImg}
                    />
                  :
                    <Box
                      component="img"
                      src={photo}
                      alt="photo-preview"
                      sx={styles.previewUploadedImg}
                    />
                  }
                  <Box
                    component="img"
                    src={TrashIcon}
                    alt="trash-icon"
                    onClick={handleDelete}
                    sx={styles.trashIcon}
                  />
                </>
              )}
              <Box sx={styles.imgLabelContainer}>
                <img src={src} alt="upload-image" style={styles.imgStyles}/>
                {!photo &&
                  <input
                    {...getInputProps()}
                  />
                }
                <MDTypography color={"text"} sx={styles.labelStyles}>
                  {fileName}
                </MDTypography>
              </Box>
              {children}
            </Box>
          </label>
        </div>
      </Box>
      <BaseModal onClose={handleClose} title={"Crop your Image"} open={openModal} removeBullshitAgain={true} removeBullshit={true} containerStyles={isMobile ? styles.containerStyles: ""}>
        {isMobile ?
          <Box sx={{minHeight: "480px"}}>
            <Cropper
              style={
                {
                  containerStyle: {maxHeight: "480px", marginTop: "100px", color: ""},
                  // mediaStyle: {maxHeight: "480px"},
                  cropAreaStyle: {color: rgba(0, 0.3)},
                }
              }
              objectFit="contain"
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={9 / 16}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete} //do something with the resulting crop
              onImageLoaded={res => {
                console.log(res)
              }}
            />
          </Box>
          :
          <Box sx={{minHeight: "480px"}}>
            {isVideo ?
            <Cropper
              style={
                {
                  containerStyle: {maxHeight: "480px", marginTop: "100px", color: ""},
                  // mediaStyle: {maxHeight: "480px"},
                  cropAreaStyle: {color: rgba(0, 0.3)},
                }
              }
              objectFit="contain"
              video={videoSrc}
              crop={crop}
              zoom={zoom}
              aspect={9 / 16}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete} //do something with the resulting crop
              onImageLoaded={res => {
                console.log(res)
              }}
            />
              :
            <Cropper
              style={
                {
                  containerStyle: {maxHeight: "480px", marginTop: "100px", color: ""},
                  cropAreaStyle: {color: rgba(0, 0.3)},
                }
              }
              objectFit="contain"
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={9 / 16}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete} //do something with the resulting crop
              onImageLoaded={res => {
                console.log(res)
              }}
            />
            }
          </Box>
        }
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
          <Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-around", marginY: "20px", gap: {xs: "0px", lg: "0px"}}}>
            <Typography sx={{textAlign: "center", fontSize: "12px", color: colors.error.main}}>
              {lackWidth ?
                "The minimum upload width is 720 pixels"
                :
                "​"
              }
            </Typography>
            <Typography sx={{textAlign: "center", fontSize: "12px", color: colors.error.main}}>
              {lackHeight ?
                "The minimum upload height is 1280 pixels"
                :
                "​"
              }
            </Typography>
            <Typography sx={{textAlign: "center", fontSize: "12px", color: colors.error.main}}>
              {lackHeight || lackWidth ?
                "Try zooming out or upload an image with higher resolution"
                :
                "​"
              }
            </Typography>
          </Box>
        </Box>
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginY: "20px", gap: {xs: "20px", lg: "0px"}}}>
          <Button onClick={handleClose} sx={styles.cancelBtn}>
            Cancel
          </Button>
          <Button onClick={isVideo ? handleClose : showCroppedImage} autoFocus sx={styles.confirmBtn} disabled={(lackHeight || lackWidth)}>
            Confirm
          </Button>
        </Box>
      </BaseModal>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
  },
  containerStyles: {
    width: { xs: "90%", lg: "100%"  }
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "528px",
    border: "2px dashed #FFFFFF",
    borderRadius: "6px",
    position: "relative",
  },
  previewUploadedImg: {
    position: "absolute",
    zIndex: 90,
    width: "297px",
    height: "528px",
    objectFit: "cover",
    borderRadius: "5px",
    left: "-2px",
    top: "-2px",
  },
  labelStyles: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#fff",
    paddingY: "1rem",
    textAlign: "center",
    paddingTop: "28px",
    lineHeight: "30px",
    height: "120px",
  },
  imgLabelContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "110px",
    paddingBottom: "30px",
  },
  imgStyles: {
    width: "70px",
    height: "55px",
  },
  trashIcon: {
    position: "absolute",
    right: 10,
    bottom: 10,
    zIndex: 99,
    width: "32px",
    height: "32px",
    cursor: "pointer"
  },
  cancelBtn: {
    color: "#3d4856",
    border: "1px solid #3d4856",
    "&:hover": {
      border:"1px solid #4a586a",
      color: "#4a586a",
    }
  },
  confirmBtn: {
    color: "#D9243D!important",
    border: "1px solid #D9243D",
    "&:hover": {
      border:"1px solid #ff1636",
      color: "#ff1636!important",
    }
  },
};
