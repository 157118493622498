export const styles = {
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding:"0px 50px 0px 50px"
  },
  img_container: {
    padding: '2% 3% 0 0',
    width: '5.5rem',
    height: '5.5rem',
  },
  img: {
    width: '100%'
  },
  info_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    // paddingLeft: '13.5%',
    paddingTop: '1%',
    padding:"0px 50px 0px 50px"
  },

};
