import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import rgba from "../../../../assets/theme/functions/rgba";
import colors from "../../../../assets/theme/base/colors";
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useChatApp } from "../utils/hooks";
import SendIcon from "assets/icons/paper_plane_red.svg"
import { ChatBubble } from "./ChatBubble";
import { useApi, useUserStore } from "../../../../utils/hooks";
import MDInput from "../../../../components/MDInput";


export const ChatView = observer(()=>{
  const {selectedConversation, setLoading, recipient, loading, incomingMessage} = useChatApp()
  const currentUser = useUserStore()
  const [messages, setMessages] = useState([])
  const [incomingMessages, setIncomingMessages] = useState([])

  // message input
  const [value, setValue] = useState(null)

  const api = useApi()
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const scrollRef = useRef(null);

  const scrollToBottom = () => scrollRef.current?.scrollIntoView({ behavior: "instant" })

  useEffect(() => {
    const timer = setTimeout(() =>{
      if(selectedConversation) getMessages(selectedConversation.sid)
    }, 1000)
    // return () => clearTimeout(timer)

  }, [selectedConversation])

  useEffect(() => {
    if (!incomingMessage) return
    //
    if (!incomingMessages.some((msg) => msg.state.sid === incomingMessage.state.sid)) {
      setIncomingMessages((msgs) => [...msgs, incomingMessage]);
    }
  }, [incomingMessage])

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [incomingMessages]);


  const sendMessage = ()=>{
    const temp = inputRef.current.value
    inputRef.current.value = ""
    if(selectedConversation && value){
      api.sendSupportMessage({message: value, sid:selectedConversation.sid}).then(response => {
        console.log('response', response)
      }).catch(reason => console.log(reason))
    }
  }

  const getMessages = (sid)=>{
    setLoading(true)
    setMessages([])
    console.log(selectedConversation)
    api.getSupportMessages({sid}).then(response => {
      console.log(response)
      if(response.kind === "ok"){
        setMessages(response.data.messages)
      }

    }).catch(reason => console.log(reason))
      .finally(() => {
        setLoading(false)
      });

  }




  return (
    <Box sx={styles.main}>
      {selectedConversation && (
        <Fragment>
          <Box sx={{mx:"24px"}}>
            <Typography sx={styles.title}>{selectedConversation.user?.username}</Typography>
            <Box sx={styles.divider} />
          </Box>

          <Box sx={{display:"flex", justifyContent:"space-between", flexDirection:"column", pt:2, overflowY:"scroll", maxHeight:`calc(100vh - ${pxToRem(320)})`}}>

            {loading && (<CircularProgress color="primary" sx={styles.loading} />)}

            {[...messages, ...incomingMessages].map((message, index) => (
              <ChatBubble me={currentUser} message={message} key={"chatBubble-" + index} />
            ))}
            <div ref={scrollRef}></div>
          </Box>

          <Box sx={styles.footer}>
            <Box sx={{...styles.divider, m:1,mt:0, mb:2}} />
            <Box sx={styles.chatBoxActions}>
              <Box component={"img"} sx={{cursor:"pointer"}} src={SendIcon} width={"26px"} ref={buttonRef} onClick={()=>sendMessage()} />
            </Box>
            <Box sx={{height:"90px", marginTop:"-46px"}} >
              <MDInput
                variant="standard"
                color={"white"}
                inputRef={inputRef}
                placeholder='Type a message here...'
                onKeyDown={e => {
                  if (e.ctrlKey && e.key === 'Enter') setValue(`${value}\n`)
                }}
                value={value}
                onChange={e => {
                  if (e.target.value === `${value}\n`) {
                    sendMessage()
                    setValue('')
                  } else setValue(e.target.value)
                }}
                sx={{background: colors.background.default, width:"100%", padding:2}}
                rows={4}
                multiline />
            </Box>
          </Box>

        </Fragment>
      )}
    </Box>
  )
})

const styles = {
  footer:{
    position:"fixed",
    bottom:"20px",
    width:`calc(100vw - ${pxToRem(250)} - 398px)`,
    background: colors.background.default
  },
  main:{
    width:"100%",
    height:`calc(100vh - ${pxToRem(107)})`,
    pt:"8px"
  },

  title:{
    fontWeight:100,
    fontSize:"48px",
    fontFamily:"Roboto",
    color:rgba("white", 1)
  },
  divider:{
    height: "2px",
    zIndex:5,
    width: "100%",
    background: colors.primary.main
  },
  roomList:{
    px:"24px"
  },
  chatBoxActions:{
    zIndex:2,
    width:"40px",
    right:"0px",
    marginLeft:"auto",
    position:"relative"
  },
  loading:{
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    zIndex:10
  }
}
