/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React context
import { setLayout, useMaterialUIController } from "context";
import { LayoutNames } from "../../../../constants";
import IdolProfileNavbar from "./navbar";

function IdolProfileDesktopLayout({ children,
                                    goBack,
                                    avatar,
                                    myProfileId,
                                    idol_id_name,
                                    isMyProfile,
                                    atProfile,
                                    CommonRoutes,
                                  }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, LayoutNames.idolProfile);
  }, [pathname]);

  return (
    <MDBox  display={"flex"}
            minHeight={"calc(100vh - 107px)"}
            flexDirection={"column"}
            justifyContent={"space-around"}>
      <MDBox>
        <IdolProfileNavbar avatar={avatar}
                           goBack={goBack}
                           myProfileId={myProfileId}
                           idol_id_name={idol_id_name}
                           isMyProfile={isMyProfile}
                           atProfile={atProfile}
        />
      </MDBox>
      <MDBox
        sx={{
          position: "relative",
          height: "calc(100vh - 107px)"}}
      >
        {children}
      </MDBox>
    </MDBox>


  );
}

IdolProfileDesktopLayout.deafultProps = {
  goBack:()=>console.log("going back")
}
IdolProfileDesktopLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(IdolProfileDesktopLayout);
