import * as React from "react";
import {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import {setLayout, useMaterialUIController} from "context";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MDTypography from "components/MDTypography";
import {PrimaryButton} from "components/PrimaryButton";
import {OnboardingLayout} from "components/OnboardingLayout";
import {useOnboardingStore} from "utils/hooks";
import {IdolProfileStyles as styles} from "./styles";
import {useFormik} from "formik";
import {CustomFormikTextField} from "components/TextField";
import {BasicCheckbox} from "components/BasicCheckbox";
import {FormHelperText, Grid, Stack, useMediaQuery} from "@mui/material";
import UploadProfilePicture from "components/UploadProfilePicture";
import {toBase64} from "utils/functions";
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {useTheme} from "@mui/material/styles";
import {IdolTypes, LayoutNames, Units, UserTypes} from "../../../constants";
import {
  getFirstPhaseFromFieldList,
  getIdolOnboardingNavigationPages,
  getOnboardingIdolInitialValues,
  getOnboardingIdolValidationFields,
  OnboardingPhases,
  useStores,
} from "../../../models";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {CustomAutocompleteFormik, CustomAutocompleteWithChipFormik} from "../../../components/AutocompleteFormik";
import {notifyError} from "../../../services/notification";
import {showAllErrors} from "../../../services/helpers";
import {GuestPaths} from "../../../paths/guestPaths";


export const OnboardingIdolProfile = observer(() => {
  const [controller, dispatch] = useMaterialUIController();
  const {loginStore} = useStores()
  const api = loginStore.environment.api
  const onboardingStore = useOnboardingStore()
  const [ProfilePicture, setProfilePicture] = useState(onboardingStore.image?.file)
  const [Percentage, setPercentage] = useState("0%")
  const {pathname} = useLocation();
  const [IdolType, setIdolType] = useState(null)
  const navigate = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [CurrentUnits, setCurrentUnits] = useState(onboardingStore.measurement_type);
  const [GenderList, setGenderList] = useState([]);
  const [EthnicityList, setEthnicityList] = useState([]);
  const [LanguageList, setLanguageList] = useState([]);
  const [userTypes, setUserTypes] = useState([])
  const [PreviousPage, CurrentPage, NextPage] = getIdolOnboardingNavigationPages(onboardingStore.idol_type, GuestPaths.idolProfile)

  const getUserTypes = () => {
    api.getUserTypes().then(res => {
      if (res.kind === 'ok') {
        setUserTypes(res.data.results)
      } else {
        notifyError("Could not get data from server")
      }
    })
  }

  const sendData = (data, files, goToNextPage) => {
    api.signUpIdolMultipart(data, files)
      .then(res => {
        if (res.kind === 'ok') {
          goToNextPage()
        } else if (res.kind === 'bad-data') {
          showAllErrors(res?.errors)
          const errorFields = Object.keys(res.errors)
          navigate(getFirstPhaseFromFieldList(errorFields))
        } else {
          notifyError('Something wrong happen!')
        }
      })
      .catch(e => {
        notifyError('Something wrong happen!')
        console.log(e)
      })
  }

  const getOnboardingData = (goToNextPage) => {
    const user_type = userTypes.find(it => it.codename === UserTypes.Idol)
    const data = onboardingStore.getIdolDataForBackend()
    const files = onboardingStore.getIdolFilesForBackend()
    data.user_type = user_type.id
    sendData(data, files, goToNextPage)
  }

  const preCheckSomeFields = () => {
    const regex = new RegExp('^[A-Za-z0-9\-\_]+$')
    if (!regex.test(formik.values.idol_id_name)) {
      notifyError("Your Idol ID Name can only contain letters, numbers and dashes")
      return false
    }
    if (!formik.values.adult) {
      notifyError("Confirm that you are 18+ to continue")
      return false
    }
    if (!formik.values.termsAndConditions) {
      notifyError("Confirm that you have read Terms and Conditions")
      return false
    }
    if (onboardingStore.idol_type === IdolTypes.Entrepreneur && (!formik.values.language || formik.values.language.length === 0)) {
      notifyError("You must select at least one language")
      return false
    }
    if (!ProfilePicture) {
      notifyError("You must select a profile picture")
      return false
    }
    return true
  }

  const submitValues = (values) => {
    if (!preCheckSomeFields()) {
      return
    }
    values.measurement_type = CurrentUnits
    onboardingStore.setData({...values})

    Promise.all([
      api.checkExistingUser({idol_id_name: values.idol_id_name}),
      api.checkExistingUser({email: values.email}),
    ]).then(responseList => {
      const existingIdName = responseList[0].kind !== 'ok'
      const existingEmail = responseList[1].kind !== 'ok'
      if (existingIdName) {
        notifyError(`Idol ID Name already used`)
      }
      if (existingEmail) {
        notifyError(`Email already used`)
      }
      if (!existingIdName && !existingEmail) {
        if (onboardingStore.idol_type === IdolTypes.Influencer) {
          getOnboardingData(() => navigate(NextPage))
        } else {
          navigate(NextPage)
        }
      }
    })
  }

  const specialCasesGeneralInformation = [
    ['language', onboardingStore.language ? onboardingStore.language.map((e) => {
      return {...e}
    }) : []],
    ['height_ft', Math.floor((onboardingStore.height / 2.54) / 12).toFixed(2)],
    ['height_in', ((onboardingStore.height / 2.54) % 12).toFixed(2)],
    ['adult', onboardingStore.adult === null ? false : onboardingStore.adult],
    ['termsAndConditions', onboardingStore.termsAndConditions === null ? false : onboardingStore.termsAndConditions],
  ]

  const formik = useFormik({
    initialValues: getOnboardingIdolInitialValues(
      OnboardingPhases.Profile,
      onboardingStore,
      specialCasesGeneralInformation
    ),
    validationSchema: getOnboardingIdolValidationFields(OnboardingPhases.Profile, onboardingStore.idol_type),
    onSubmit: submitValues
  })

  const getGenders = () => {
    api.getGenders().then((res) => {
      if (res.kind === "ok") {
        setGenderList(res.data.results);
      } else {
        console.log("error", res);
      }
    })
  }

  const getEthnicity = () => {
    api
      .getEthnicity()
      .then((res) => {
        if (res.kind === "ok") {
          setEthnicityList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const getLanguages = () => {
    api.getLanguages()
      .then((res) => {
        if (res.kind === "ok") {
          setLanguageList(res.data.results);
        } else {
          console.log("error", res);
        }
      })
  }

  const handleClickOnUnits = (units) => {
    setCurrentUnits(units)
    onboardingStore.setData({measurement_type: units})
  }

  const handleClickOnMetricUnits = () => {
    if (CurrentUnits !== Units.Metric.id) {
      formik.setFieldValue('bust', (formik.values.bust * 2.54).toFixed(2))
      formik.setFieldValue('waist', (formik.values.waist * 2.54).toFixed(2))
      formik.setFieldValue('hips', (formik.values.hips * 2.54).toFixed(2))
    }
    handleClickOnUnits(Units.Metric.id)
  }

  const handleClickOnImperialUnits = () => {
    if (CurrentUnits !== Units.Imperial.id) {
      formik.setFieldValue('bust', (formik.values.bust / 2.54).toFixed(2))
      formik.setFieldValue('waist', (formik.values.waist / 2.54).toFixed(2))
      formik.setFieldValue('hips', (formik.values.hips / 2.54).toFixed(2))
    }
    handleClickOnUnits(Units.Imperial.id)
  }

  useEffect(() => {
    setLayout(dispatch, LayoutNames.onboarding);
  }, [pathname]);

  useEffect(() => {
    onboardingStore.setLastURLVisited(CurrentPage)
    setIdolType(onboardingStore.idol_type)
    setPercentage(onboardingStore.idol_type === IdolTypes.Influencer ? '100%' : '20%')
    getGenders()
    getEthnicity()
    getLanguages()
    getUserTypes()
  }, [])

  return (
    <OnboardingLayout
      titleBold={"IDOL"}
      titleNormal={"PROFILE"}
      ml={6}
      progressBarPercent={Percentage}
      backTo={PreviousPage}
      subtitle={
        <>
          <MDTypography fontWeight={"light"} sx={isMobile ? styles.subtitleMobile : styles.subtitle}>
            This is the perfect place for the alternative professional, and we provide all the
            tools you need to engage with your idolizers.
          </MDTypography>
          <MDTypography
            sx={isMobile ? ({...styles.subtitleMobile, fontWeight: 700}) : ({...styles.subtitle, fontWeight: 700})}
            mt={2}>Let’s get started setting up your profile!</MDTypography>
        </>
      }
    >
      <MDBox mt={4} style={{minWidth: isMobile ? "100%" : "480px", width: "100%"}} autocomplete={"off"}>
        <form onSubmit={formik.handleSubmit} style={isMobile ? ({width: "100%"}) : ({width: "514px"})}>
          <MDBox mt={0} style={styles.profilePicture}>
            <UploadProfilePicture
              src={ProfilePicture}
              avatarSize={200}
              labelStyle={{marginRight: "auto"}}
              alt="Profile 1"
              onClick={async (file) => {
                const b64file = await toBase64(file)
                formik.setFieldValue('image', {fileName: file.name, file: b64file})
                setProfilePicture(b64file)
              }}/>
            {formik.touched.image && Boolean(formik.errors.image) && (
              <FormHelperText>
                <MDTypography sx={{fontSize: 14, ml: "20px", mt: "-30px", fontWeight: 300}}
                              color={"error"}>
                  {formik.touched.image && formik.errors.image}
                </MDTypography>
              </FormHelperText>
            )}
          </MDBox>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="username" formik={formik}/>
            <CustomFormikTextField name="idol_id_name" formik={formik}/>
            <CustomFormikTextField name="email" formik={formik}/>
            <CustomFormikTextField name="password" formik={formik}/>
            <CustomFormikTextField name="repeat_password" formik={formik}/>
            {IdolType > IdolTypes.Influencer && [
              <>
                <MDTypography sx={{fontSize: pxToRem(16), mb: pxToRem(4), color: "#FFFFFF"}}>{"Tags"}</MDTypography>
                <Grid container spacing={2} gap={{xs: 0, lg: 4}} columns={{xs: 12, lg: 4}}>
                  <Grid style={{paddingTop: 0}} item xs={4} sm={12} md={4}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_1" formik={formik}/>
                  </Grid>
                  <Grid style={{paddingTop: 0}} item xs={4} md={4} sm={12}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_2" formik={formik}/>
                  </Grid>
                  <Grid style={{paddingTop: 0}} item xs={4} md={4} sm={12}>
                    <CustomFormikTextField inputProps={{maxLength: 12}} name="tag_3" formik={formik}/>
                  </Grid>
                </Grid>
              </>,
              <CustomFormikTextField
                name="biography"
                formik={formik}
                style={{minHeight: pxToRem(110)}}
                multiline
                rows={4}
              />
            ]}
            {IdolType > IdolTypes.Professional && [
              <CustomAutocompleteFormik name={'gender'} formik={formik} options={GenderList}/>,
              <CustomFormikTextField name="age" formik={formik}/>,
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PrimaryButton
                    text={"Metric"}
                    size={"small"}
                    buttonStyle={{height: "30px"}}
                    onClick={handleClickOnMetricUnits}
                    mainColor={CurrentUnits === Units.Metric.id ? colors.primary : colors.tertiary}/>
                </Grid>
                <Grid item xs={6}>
                  <PrimaryButton
                    text={"Imperial"}
                    size={"small"}
                    buttonStyle={{height: "30px"}}
                    onClick={handleClickOnImperialUnits}
                    mainColor={CurrentUnits === Units.Imperial.id ? colors.primary : colors.tertiary}/>
                </Grid>
                {CurrentUnits === null ?
                  <Grid item sm={12}><MDTypography sx={(theme) => {
                    return {
                      color: theme.palette.primary.main,
                      fontSize: pxToRem(14),
                      textAlign: "center",
                    }
                  }}>Please select the measurement
                    unit</MDTypography></Grid>
                  :
                  <>
                    {(CurrentUnits === Units.Metric.id) &&
                      <Grid item xs={12} md={6}>
                        <CustomFormikTextField
                          name="height"
                          formik={formik}
                          customEndAdornmentText={Units.Metric.measurementUnit}
                          onChange={(evt) => {
                            const inches = (evt.target.value / 2.54) % 12
                            const feets = Math.floor((evt.target.value / 2.54) / 12)
                            formik.setFieldValue('height_ft', feets.toFixed(2))
                            formik.setFieldValue('height_in', inches.toFixed(2))
                          }}
                          inputProps={{maxLength: 6}}
                        />
                      </Grid>
                    }
                    {(CurrentUnits === Units.Imperial.id) && [
                      <Grid item xs={12} md={4}>
                        <CustomFormikTextField
                          name="height_ft"
                          formik={formik}
                          customEndAdornmentText={'ft'}
                          onChange={(evt) => {
                            const centimeters = evt.target.value * 30.48 + formik.values.height_in * 2.54
                            formik.setFieldValue('height', centimeters.toFixed(2))
                          }}
                          inputProps={{maxLength: 6}}
                        />
                      </Grid>,
                      <Grid item md={4} sm={12}>
                        <CustomFormikTextField
                          name="height_in"
                          formik={formik}
                          customEndAdornmentText={Units.Imperial.measurementUnit}
                          onChange={(evt) => {
                            const centimeters = evt.target.value * 2.54 + formik.values.height_ft * 30.48
                            formik.setFieldValue('height', centimeters.toFixed(2))
                          }}
                          inputProps={{maxLength: 6}}
                        />
                      </Grid>
                    ]}

                    <Grid item md={(CurrentUnits === Units.Metric.id) ? 6 : 4} xs={12} sm={12}>
                      <CustomFormikTextField
                        inputProps={{maxLength: 6}}
                        name="shoe_size"
                        customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : "US"}
                        formik={formik}
                      />
                    </Grid>
                  </>
                }
                {(CurrentUnits !== null) && [
                  <Grid item xs={12} md={4}>
                    <CustomFormikTextField
                      name="bust"
                      formik={formik}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                      inputProps={{maxLength: 6}}
                    />
                  </Grid>,
                  <Grid item xs={12} md={4}>
                    <CustomFormikTextField
                      name="waist"
                      formik={formik}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                      inputProps={{maxLength: 6}}
                    />
                  </Grid>,
                  <Grid item xs={12} md={4}>
                    <CustomFormikTextField
                      name="hips"
                      formik={formik}
                      customEndAdornmentText={CurrentUnits === Units.Metric.id ? Units.Metric.measurementUnit : Units.Imperial.measurementUnit}
                      inputProps={{maxLength: 6}}
                    />
                  </Grid>
                ]}
              </Grid>,
              <CustomFormikTextField name="location" formik={formik}/>,
              <CustomAutocompleteFormik name={'ethnicity'} formik={formik} options={EthnicityList}/>,
              <CustomAutocompleteWithChipFormik name={'language'} formik={formik} options={LanguageList}/>,
            ]}
          </Stack>

          <Stack flexDirection={"row"} justifyContent="space-around" marginY={pxToRem(14)}>
            <BasicCheckbox name={"adult"}
                           label={"I am 18+"}
                           checked={formik.values.adult === true}
                           value={formik.values.adult}
                           onChange={formik.handleChange}
            />
            <BasicCheckbox name={"termsAndConditions"}
                           label={"I accept T&C"}
                           checked={formik.values.termsAndConditions === true}
                           value={formik.values.termsAndConditions}
                           onChange={formik.handleChange}
            />
          </Stack>
          <MDBox mb={4} mt={4}>
            <PrimaryButton type={"submit"} onClick={preCheckSomeFields} text={"NEXT"}/>
            <Stack flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mb={4} mt={4}>
              <Stack flexDirection={'row'}>
                <MDTypography sx={{
                  color: '#FFFFFF',
                  fontSize: 16,
                }}>Already have an account?</MDTypography>
                <Link to={GuestPaths.login}>
                  <MDTypography sx={{
                    color: '#FFFFFF',
                    fontSize: 16, textDecoration: "underline",
                    textAlign: "right",
                    fontWeight: "bold",
                    ml: 2,
                  }}>Sign In
                    here!</MDTypography>
                </Link>
              </Stack>
              <Stack flexDirection={'row'}>
                <MDTypography sx={{
                  color: '#FFFFFF',
                  fontSize: 16,
                }}>Click here to read the</MDTypography>
                <Link to={"#"} onClick={() => window.open(GuestPaths.termsAndConditions, '_blank')}>
                  <MDTypography sx={{
                    color: '#FFFFFF',
                    fontSize: 16, textDecoration: "underline",
                    textAlign: "right",
                    fontWeight: "bold",
                    ml: 2,
                  }}>
                    Terms and Conditions
                  </MDTypography>
                </Link>
              </Stack>
            </Stack>
          </MDBox>
        </form>
      </MDBox>
    </OnboardingLayout>
  )
})
