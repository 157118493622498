import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {OnboardingLayout} from "../../../components/OnboardingLayout";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {CustomFormikTextField} from "../../../components/TextField";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import colors from "assets/theme/base/colors";
import {observer} from "mobx-react";
import {BasicCheckbox} from "../../../components/BasicCheckbox";
import {FormHelperText, Stack, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {CustomAutocompleteFormik} from "../../../components/AutocompleteFormik";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import {useOnboardingStore} from "../../../utils/hooks";
import {getFormikValidationFields, getFormikValidationBooleanFields, useStores} from "../../../models";
import {notifyError} from "../../../services/notification";
import {showAllErrors} from "../../../services/helpers";
import {UserTypes} from "../../../constants";
import {IdolizerPaths} from "../../../paths/idolizerPaths";
import {guestRoutes} from "../../../routes/guestRoutes";
import {GuestPaths} from "../../../paths/guestPaths";

const {white} = colors;

const SignUp = observer(() => {
  const onboardingStore = useOnboardingStore()
  const {loginStore} = useStores()
  const api = onboardingStore.environment.api
  const navigate = useNavigate()
  const {state} = useLocation();
  const theme = useTheme();
  const [Genders, setGenders] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileW = useMediaQuery('(min-width:370px)');
  const idolizerFields = [
    'email',
    'username',
    'password',
    'repeat_password',
    'gender_of_interest',
    'termsAndConditions',
    'adult'
  ];
  const idolizerBooleanFields = ['termsAndConditions', 'adult'];
  const initialValues = {}
  idolizerFields.forEach(field => initialValues[field] = !idolizerBooleanFields.includes(field) ? null : false);

  const sendData = (values) => {
    api.signUp(values)
      .then(res => {
        if (res.kind === 'ok') {
          loginStore.setApiToken(res.response.token)
          loginStore.setUser(res.response.user)
          navigate(IdolizerPaths.home)
        } else if (res.kind === 'bad-data') {
          showAllErrors(res?.errors)
        } else {
          notifyError('Something wrong happen!')
        }
      })
      .catch(e => {
        notifyError('Something wrong happen!')
        console.log(e)
      })
  }

  const getBooleanValidationSchema = () => {
    const termSchema = getFormikValidationBooleanFields("termsAndConditions", "The terms and conditions must be accepted.");
    const adultSchema = getFormikValidationBooleanFields("adult", "You must be at least 18 years old.");
    return termSchema.concat(adultSchema);
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getFormikValidationFields(idolizerFields).concat(getBooleanValidationSchema()),
    onSubmit: (values) => {
      values.gender_of_interest_id = values.gender_of_interest?.id
      values.user_type = userTypes.find(it => it.codename === UserTypes.Idolizer).id
      delete values.gender_of_interest
      sendData(values)
    },
  });

  const getGenders = () => {
    api.getGenders().then(res => {
      if (res.kind === 'ok') {
        setGenders(res.data.results)
      }
    })
  }

  const getUserTypes = () => {
    api.getUserTypes().then(res => {
      if (res.kind === 'ok') {
        setUserTypes(res.data.results)
      } else {
        notifyError("Could not get data from server")
      }
    })
  }

  useEffect(() => {
    getUserTypes()
    getGenders()
  }, [])

  return (
    <OnboardingLayout titleBold={"SIGN"} titleNormal={"UP"} backTo={(state && state.fromURL) || "/login/"}>
      <MDBox mt={isMobile ? 4 : 2} width={isMobile ? "100%" : "400px"}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={pxToRem(14)}>
            <CustomFormikTextField name="email" formik={formik}/>
            <CustomFormikTextField name="username" formik={formik}/>
            <CustomFormikTextField name="password" formik={formik}/>
            <CustomFormikTextField name="repeat_password" formik={formik}/>
            <CustomAutocompleteFormik name={'gender_of_interest'} formik={formik} options={Genders} />
          </Stack>
          <MDBox style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
            <MDBox ml={isMobileW ? 3 : 0} mt={0}>
              <BasicCheckbox name={"adult"} label={"I am 18+"} value={formik.values.adult} onChange={formik.handleChange}/>
            </MDBox>
            <MDBox ml={isMobileW ? 3 : 0} mt={0}>
              <BasicCheckbox name={"termsAndConditions"} label={"I accept T&C"} value={formik.values.termsAndConditions} onChange={formik.handleChange}/>
            </MDBox>
          </MDBox>
          {formik.touched.adult && Boolean(formik.errors.adult) && (
            <FormHelperText>
              <MDTypography sx={{fontSize: 14}} color={"error"}>
                {formik.touched.adult && formik.errors.adult}
              </MDTypography>
            </FormHelperText>
          )}
          {formik.touched.termsAndConditions && Boolean(formik.errors.termsAndConditions) && (
            <FormHelperText>
              <MDTypography sx={{fontSize: 14}} color={"error"}>
                {formik.touched.termsAndConditions && formik.errors.termsAndConditions}
              </MDTypography>
            </FormHelperText>
          )}

          <MDBox mb={4} mt={4}>
            <PrimaryButton type={"submit"} text={"SIGN UP"}/>
          </MDBox>
        </form>
      </MDBox>
      <MDBox style={styles.footer}>
        <Stack flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Stack flexDirection={'row'}>
            <MDTypography sx={styles.defaultText}>Already have an account?</MDTypography>
            <Link to={guestRoutes.login.path}>
              <MDTypography sx={{...styles.defaultText, ...styles.link, fontWeight: "bold", ml: 2}}>
                Sign In here!
              </MDTypography>
            </Link>
          </Stack>
          <Stack flexDirection={'row'}>
            <MDTypography sx={styles.defaultText}>Click here to read the</MDTypography>
            <Link to={"#"} onClick={() => window.open(GuestPaths.termsAndConditions, '_blank')}>
            <MDTypography sx={{...styles.defaultText, ...styles.link, fontWeight: "bold", ml: 2}}>
              Terms and Conditions
            </MDTypography>
            </Link>
          </Stack>
        </Stack>
      </MDBox>
    </OnboardingLayout>
  );
});

const styles = {
  defaultText: {
    color: white.main,
    fontSize: 16,
  },
  link: {
    textDecoration: "underline",
    textAlign: "right",
  },
  footer: {
    marginBottom: "20px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "auto"
  }
}

export default SignUp;
