import { Box } from "@mui/material";
import MenuIcon from "../../../../assets/icons/menu.svg";
import { setLayout, setOpenSidenav, useMaterialUIController } from "../../../../context";
import { FilterType } from "../../constants";
import SearchIcon from "../../../../assets/icons/searchLeft.svg";
import React, { useEffect } from "react";
import { ExploreTab } from "./Tab";
import { useExploreApp } from "../utils/hooks";
import { ExploreLayout } from "../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader";
import PostContent from "components/PostContent/PostContent";
import { LayoutNames } from "../../../../constants";
import { useApi, useInfiniteScroll, useUserStore } from "../../../../utils/hooks";
import { ExploreMediaContainer } from "./ExploreMediaContainer";
import {IdolTypeEnum} from "utils/constants";

export const ExploreContent = ()=> {
  const {exploreFilter, setExploreFilter, setExploreLayout} = useExploreApp()
  const [controller, dispatch] = useMaterialUIController();
  const api = useApi()
  const user = useUserStore()

  useEffect(() => {
    setLayout(dispatch, LayoutNames.exploreMobile);
  }, []);

  const onApiCall = ({page})=>{
    let data = {page, explore: true}
    if(exploreFilter !== FilterType.all){
      data["type_codename"] = "additional_feature"
    }
    return api.getIdolPosts(data)
  }

  const {
    items,
    extraData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "posts", exploreFilter})

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, 100);
    console.log("items ======>", items)
  }, [exploreFilter])

  return (
    <React.Fragment>
      <Box style={styles.navbar}>
        <Box component={"img"} src={MenuIcon} onClick={() => setOpenSidenav(dispatch, true)} />
        <Box style={styles.tabs}>
          <ExploreTab selected={exploreFilter === FilterType.all}
                      width={user.id?"105px":"210px"}
                      onClick={()=>setExploreFilter(FilterType.all)}
                      title={"Explore"}/>
          {user.id && (
            <ExploreTab selected={exploreFilter === FilterType.paidContent}
                        onClick={()=>setExploreFilter(FilterType.paidContent)}
                        title={"Paid Content"}/>
          )}

        </Box>
        <Box component={"img"} src={SearchIcon} onClick={()=> {setExploreLayout(ExploreLayout.search)}}/>
      </Box>

      <Box style={{maxHeight:"100vh"}}>
        <InfiniteScroll
          dataLength={items.length}
          next={() => {
            if(!isFetchingNextPage){
              return fetchNextPage()
            }
          }}
          height={"100vh"}
          className={"noScrollbarDisplay"}
          hasMore={!!hasNextPage}
          loader={<Loader/> }
          style={styles.scroll}
        >
            {items.map(post => <ExploreMediaContainer user={user} key={"post-"+post.id} post={post} />)}
        </InfiniteScroll>
      </Box>
    </React.Fragment>
  )
}

const styles = {
  main:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between"
  },
  scroll:{
    display:"flex",
    flexDirection:"column",
    overflow:"auto",
    flex:"none",
    scrollSnapType:"y mandatory",
  },
  tabs:{
    paddingLeft:"20px",
    paddingRight:"30px",
    flexGrow:1,
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between"
  },
  navbar: {
    position: "fixed",
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100vw",
    padding: "24px",
    zIndex: "5"
  }
}
