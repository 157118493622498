
export const PromotionDays = [
  { value: 0, label: "Duration" },
  { value: 1, label: "1 day" },
  { value: 2, label: "2 days" },
  { value: 3, label: "3 days" },
  { value: 4, label: "4 days" },
  { value: 5, label: "5 days" },
  { value: 6, label: "6 days" },
  { value: 7, label: "7 days" },
  { value: 8, label: "8 days" },
  { value: 9, label: "9 days" },
  { value: 10, label: "10 days" },
  { value: 11, label: "11 days" },
  { value: 12, label: "12 days" },
];

export const BundlesMonths = [
  { value: 0, label: "Duration" },
  { value: 1, label: "1 month" },
  { value: 2, label: "2 months" },
  { value: 3, label: "3 months" },
  { value: 4, label: "4 months" },
  { value: 5, label: "5 months" },
  { value: 6, label: "6 months" },
  { value: 7, label: "7 months" },
  { value: 8, label: "8 months" },
  { value: 9, label: "9 months" },
  { value: 10, label: "10 months" },
  { value: 11, label: "11 months" },
  { value: 12, label: "12 months" },
];

export const discountOptions = [
  { value: 0, label: "Discount percent" },
  { value: 10, label: "10% discount" },
  { value: 20, label: "20% discount" },
  { value: 30, label: "30% discount" },
  { value: 40, label: "40% discount" },
  { value: 50, label: "50% discount" },
  { value: 60, label: "60% discount" },
  { value: 70, label: "70% discount" },
  { value: 80, label: "80% discount" },
  { value: 90, label: "90% discount" },
  { value: 100, label: "100% discount" },
]
