import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import { primaryContainer, styles, primaryTextStyle, secondaryTextStyle } from "./styles";

export const CardNumberItem = ({content, secondaryContent, containerStyles, isMobile}) => {
  return (
    <Box sx={[styles.container, {...containerStyles}]}>
        <Box sx={()=>primaryContainer(isMobile)}>
            <Typography variant='h1' sx={()=>primaryTextStyle(isMobile)}>
                {content}
            </Typography>
        </Box>
        <Typography sx={()=>secondaryTextStyle(isMobile)}>{secondaryContent}</Typography>
    </Box>
  )
}

CardNumberItem.propTypes = {
  content: PropTypes.string.isRequired,
  secondaryContent: PropTypes.string.isRequired,
  containerStyles: PropTypes.object,
}
