import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white} = colors;

export const styles = {

    card_container: {
        padding: '2% 3% 0 3%',
    },
    saved_cards_section: {
        padding: '4% 5% 0 3%',
    },
    saved_cards_title: {
        color: white.main,
        fontSize: pxToRem(22), 
        fontWeight: 400, 
        paddingBottom: 2
    }, 
    divider: {
        background: white.main, 
        margin: 0, 
        opacity: 0.2,
    },
}; 