import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white, card, dark, transparent, modal} = colors;

export const styles = {
    card: {
        background: card.background, 
        paddingBlock: '1.5rem',
    },
    content_container: {
        paddingInline: 1 
    },
    fields_titles_container: {
      paddingRight: 4
    },
    field_title: {
        color: white.main,
        textAlign: 'center',  
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(10),
        // paddingTop: 2,
        // paddingInline: 1.5
    
    },
    field_container: {
        background: card.header,
        borderRadius: '8px',
        paddingBlock: 1,
        px: pxToRem(37),
    },
    field_box: {
        alignItems: 'center',
        display: 'flex', 
        gap: pxToRem(25),
        justifyContent: 'center',
    },
    field_image: {
      width: 60,
      height: 100,
      objectFit: 'contain'
    },
    field_box_price: {
        gap: pxToRem(26),
    },
    icon_edit: {
      cursor: 'pointer',
      height: pxToRem(24),
      width: pxToRem(24),
    },
    icon_check: {
      cursor: 'pointer',
      height: pxToRem(18),
      width: pxToRem(18),
    },
    description: {
      color: white.main,
      fontSize: pxToRem(12),
      fontWeight: 400,
      lineHeight: '140.62%',
      width: pxToRem(296),
      textAlign: 'center',
    },
    input_container: {
      width: pxToRem(312),
    },
    input: {
      background: dark.secondary,
      border: 'none',
      borderRadius: '10px',
      fontSize: pxToRem(12),
      fontWeight: 400,
      height: pxToRem(86),
      lineHeight: '140.62%',
      paddingBlock: pxToRem(26),
      paddingInline: pxToRem(10),
    },
    tier: {
      borderRadius: '10px',
      height: pxToRem(34),
      width: pxToRem(136),
      '& .MuiSvgIcon-root': {
        color: colors.white.main,
        transform: "scale(1.5)",
        mr: 1
      },
      '& .MuiInputBase-input': {
        borderRadius: '10px',
      }
    },
    price: {
      color: white.main,
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: '140.62%',
      textAlign: 'center',
      width: pxToRem(100),
    },
    input_price: {
      fontSize: pxToRem(16),
      fontWeight: 400,
      lineHeight: '140.62%',
      textAlign: 'center',
    },
    adornment: {
      color: white.main,
      fontSize: pxToRem(16),
    }
};