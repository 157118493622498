import TipsIcon from "../../assets/icons/idol-pay.svg";
import OrderingButton from "../../components/WithdrawButton/OrderingButton";
import {ErrorMessage, filteringFields} from "./constants";
import {BasePostCard} from "../../base/Cards/PostCard";
import TransactionsIcon from "../../assets/icons/withdrawls.svg";
import {IdolTipDetailedRecord, IdolTransactionDetailedRecord} from "../../components/RecordCard/RecordCard";
import {Box} from "@mui/system";

export function TipList(props) {
  const {orderByTips, orderByDate, orderRecordsBy, records} = props
  return <BasePostCard title='Tips'
                       icon={TipsIcon}
                       headerControls={
                         <Box sx={{
                           display: "flex",
                           flexDirection: "row",
                           width: "240px",
                           gap: "25px",
                           justifyContent: "flex-end"
                         }}>
                           <OrderingButton
                             text='Tips'
                             onClick={orderByTips}
                             currentFilterSelected={orderRecordsBy === filteringFields.Tips}
                           />
                           <OrderingButton
                             text='Date'
                             onClick={orderByDate}
                             currentFilterSelected={orderRecordsBy === filteringFields.Date}
                           />
                         </Box>
                       }
  >
    {records.map((record, idx) => <IdolTipDetailedRecord showArrow={false} key={`wc_${idx}`} record={record}/>)}
    {records.length === 0 ?? ErrorMessage}
  </BasePostCard>
}

export function TransactionList(props) {
  const {orderByAmount, orderByDate, orderRecordsBy, records} = props
  return <BasePostCard title='Transactions'
                       icon={TransactionsIcon}
                       headerControls={
                         <Box sx={{
                           display: "flex",
                           flexDirection: "row",
                           width: "240px",
                           gap: "25px",
                           justifyContent: "flex-end"
                         }}>
                           <OrderingButton
                             text='Amount'
                             onClick={orderByAmount}
                             currentFilterSelected={orderRecordsBy === filteringFields.Amount}
                           />
                           <OrderingButton
                             text='Date'
                             onClick={orderByDate}
                             currentFilterSelected={orderRecordsBy === filteringFields.Date}
                           />
                         </Box>
                       }
  >
    {records.map((record, idx) => <IdolTransactionDetailedRecord key={`wc_${idx}`} record={record}/>)}
  </BasePostCard>
}
