import colors from "assets/theme/base/colors";

export const styles = {
  container: {
    backgroundColor: colors.card.header,
    color: "#fff",
    borderRadius: "10px",
    width: "100%",
    marginTop: "30px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.40)",
  },
  title: {
    color: "#fff",
    fontWeight: "100",
  },
  mobileTitle: {
    fontWeight: 300,
    fontSize: '22px',
    lineHeight: '26px',
    color: "#fff",
  },
  titleBox: {
    padding: "13px 0px 13px 16px",
    backgroundColor: "#1F2B3B",
    borderRadius: "10px 10px 0px 0px",
    color: "#fff",
    height: "72px",
  },
  childrenBox: {
    padding: "25px",
    backgroundColor: colors.background.default,
    borderRadius: "0px 0px 10px 10px",
    height: "100%",
    color: "#fff",
    display: "flex",
    position: "relative",
  },
};
