import IdolProfileLogo from "assets/images/idol_mirror_logo.png";
import DefaultAvatarLogo from "assets/images/default_user.png";
import MDBox from "../MDBox";
import {Avatar, Box, Dialog} from "@mui/material";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {memo, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AuthenticatedPaths, UnauthenticatedPaths} from "../../paths/paths";
import {useIsMobile} from "../../utils/hooks";


export const AvatarOverImage = memo(({
                                       backgroundImage,
                                       avatarImage,
                                       bordered,
                                       containerStyle,
                                       avatarStyle,
                                       avatarContainerStyle,
                                       myProfileId,
                                       atProfile,
                                       isIdol,
                                       editingProfile,
                                     }) => {

  const [show, setShow] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile()

  const doAction = () => {
    if (!atProfile) {
      if (isIdol) {
        if (location.pathname.includes("meet-me")) {
          return setShow(true);
        }
        navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", myProfileId))
      } else if (!editingProfile) {
        navigate(AuthenticatedPaths.profile)
      } else {
        setShow(true)
      }
    } else {
      setShow(true)
    }
  }

  const AvatarContainer = ({children}) => {
    return (
      <MDBox style={{
        ...styles.avatarContainer,
        background: bordered ? "white" : "transparent",
        cursor: "pointer",
        ...avatarContainerStyle
      }}>
        {children}
      </MDBox>
    )
  }

  return (
    <MDBox style={{...styles.container, ...containerStyle}}>
      <Box component={"img"} src={backgroundImage} style={styles.background}/>
      <AvatarContainer>
        <Avatar src={avatarImage} alt={'avatar'}
                onClick={() => doAction()}
                style={{...styles.avatar, ...avatarStyle}}/>
      </AvatarContainer>
      {isMobile ?
        <Dialog onClose={() => setShow(false)}
                open={show}
                PaperProps={{sx: {background: "transparent !important"}}}
                sx={{background: "transparent !important"}}>
          <AvatarContainer>
            <Avatar src={avatarImage} alt={'avatar'}
                    onClick={() => doAction()}
                    style={{...styles.avatarFullModile}}/>
          </AvatarContainer>
        </Dialog>
        :
        <Dialog onClose={() => setShow(false)}
                open={show}
                PaperProps={{sx: {background: "transparent !important"}}}
                sx={{background: "transparent !important"}}>
          <AvatarContainer>
            <Avatar src={avatarImage} alt={'avatar'}
                    onClick={() => doAction()}
                    style={{...styles.avatarFull}}/>
          </AvatarContainer>
        </Dialog>
      }
    </MDBox>
  )
})

AvatarOverImage.defaultProps = {
  backgroundImage: IdolProfileLogo,
  avatarImage: DefaultAvatarLogo,
  bordered: true
}
const styles = {
  container: {
    width: 195,
    height: 185,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  background: {
    position: "absolute",
    width: 195,
    height: 185,
    zIndex: 1,
    // top:0,
    // left:0
  },
  avatar: {
    width: pxToRem(96),
    height: pxToRem(96),
    zIndex: 3
  },
  avatarFull: {
    width: pxToRem(411),
    height: pxToRem(411),
    zIndex: 3
  },
  avatarFullModile: {
    width: pxToRem(200),
    height: pxToRem(200),
    zIndex: 3
  },
  avatarContainer: {
    padding: 4,
    borderRadius: "50%",
    zIndex: 2
  }
}
