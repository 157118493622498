import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import { GetVerified } from "./GetVerified";
import {useStores} from "../../../models";
import {useEffect} from "react";

export const GetVerifiedPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("GET VERIFIED")
  }, []);

  return (
    <BaseProtectedComponentPage loginRequired={false}>
        <DashboardLayout>
          <GetVerified />
        </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
