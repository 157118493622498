import colors from "assets/theme-dark/base/colors";

export const IdolProfileStyles = {
  subtitle:{
    fontSize:14,
    letterSpacing:"0.1em",
    width:"480px",
    color:colors.white.main
  },
  subtitleMobile:{
    fontSize:12,
    letterSpacing:"0.1em",
    width:"100%",
    color:colors.white.main
  },
  chip:{
    background:colors.inputBackgroundColor,
    borderColor:colors.inputBackgroundColor,
    fontSize:"14px",
    padding:"8px 12px"
  },
  languageInput:{
    color: colors.white.main,
    backgroundColor: colors.chipBackgroundColor
  },
  profilePicture:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-start",
    marginLeft:"-20px",
    marginRight:"auto"
  },
  unitsContainer:{
    display:"flex",
    flexDirection:"column",
    gap:"10px",
    alignItems:"center",
    height:"100%",
    marginTop:"10px"
  },
  unitsLabel:{
    fontSize:16,
    mb:0.5,
    width:"40px",
    color: colors.white.main,
    marginTop:"40px"
  }
}
