import {useEffect, useMemo, useState} from "react";
import {  observe } from "mobx";
// react-router components
import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import Sidenav from "base/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
// Material Dashboard 2 PRO React contexts
import {setMiniSidenav, setOpenConfigurator, useMaterialUIController} from "context";
import colors from "assets/theme/base/colors";
import "assets/theme/base/colors.css"
import 'react-day-picker/dist/style.css';
import 'react-phone-input-international/lib/style.css'


// Images
import brandWhite from "assets/images/dashboard_logo.png";
import brandIdolMobile from "assets/images/idol_dashboard_mobile.png";
import {useIsMobile} from "./utils/hooks";

/* admin */
import {LayoutNames, NavigationMenu, ScreenType, UserTypes} from "./constants";
import {useStores} from "./models";
import {routes} from "./routes";
import {NotFound} from "./pages/not-found";
import {Redirecting} from "./pages/redirecting";
import { MENUS } from "./menus";
import {ImportContentPage} from "./pages/portal/importContent";
import { IdolTypeEnum } from "./utils/constants";
import { idolInfluencerRoutes } from "./routes/IdolRoutes";
import {ToLanding} from "./pages/to-landing";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const {pathname} = useLocation();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const activeRoute = loginStore.getActiveRoute
  const IsIdol = loginStore.isIdol
  const IsIdolizer = loginStore.isIdolizer
  const IsDashboard = (activeRoute === "dashboard")
  const IsSettings = (activeRoute === "settings")
  const isMobile = useIsMobile()
  const myProfileId = loginStore.idol_id_name
  const [userAvatar, setUserAvatar] = useState(`${loginStore.image}?q=${loginStore.ProfilePictureVersion}`)
  const {idol_id_name} = useParams();
  const isMyProfile = idol_id_name === myProfileId
  const atProfile = (activeRoute === "profile")
  const editingProfile = (activeRoute === "editing-profile")
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
    // navigate('/login/')
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const navigationMenu = () => {
    const userType = loginStore.userType ?? UserTypes.Guest
    const idolType = loginStore.idol_type === IdolTypeEnum.influencer?"influencer":"other"

    let section = NavigationMenu.SETTINGS
    if(activeRoute !== NavigationMenu.SETTINGS){
      section = NavigationMenu.DASHBOARD
    }

    let userMenu = MENUS[isMobile? ScreenType.MOBILE : ScreenType.DESKTOP][userType]

    if(userType === UserTypes.Idol){
      userMenu = userMenu[idolType]
    }

    if (!isMobile) {
      userMenu = userMenu[section]
    }

    if(isMobile && userMenu.main){
      userMenu = userMenu.main
    }
    return userMenu
  }

  const getAppRoutes = (routes = {}) => {
    return Object.keys(routes).map((currentRouteKey, index) =>
      (<Route
        exact
        key={`route_${currentRouteKey}_${index}`}
        path={routes[currentRouteKey].path}
        element={routes[currentRouteKey].element}/>)
    )
  }

  const getUserRoutes = () => {
    let userRoutes
    const userType = loginStore.userType ?? UserTypes.Guest

    switch (userType) {
      case UserTypes.Idol:
        if (loginStore.idol_type === IdolTypeEnum.influencer){
          userRoutes = idolInfluencerRoutes
        }else{
          userRoutes = routes[UserTypes.Idol]
        }
        break
      case UserTypes.Idolizer:
        userRoutes = routes[UserTypes.Idolizer]
        break;
      case UserTypes.Admin:
        userRoutes = routes[UserTypes.Admin]
        break;
      default:
        userRoutes = routes[UserTypes.Guest]
    }
    return getAppRoutes(userRoutes)

  }

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Observing changes in loginstore to update avatar
  const disposer = observe(loginStore, changes => {
    if (changes.name === "imageVersion") {
      setUserAvatar(`${loginStore.image}?q=${changes.newValue.storedValue}`);
    }
  });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline/>

      {layout === LayoutNames.general && (
        <>
          <Sidenav
            isAdmin={loginStore.user_type_codename === UserTypes.Admin}
            userAvatar={userAvatar}
            isMobile={isMobile}
            isIdol={IsIdol}
            backgroundColor={isMobile && (IsIdol || IsIdolizer) ? colors.secondary.main : colors.primary.main}
            brand={isMobile && (IsIdol || IsIdolizer) ? brandIdolMobile : brandWhite}
            brandName=""
            menu={navigationMenu()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            myProfileId={myProfileId}
            idol_id_name={idol_id_name}
            isMyProfile={isMyProfile}
            atProfile={atProfile}
            editingProfile={editingProfile}
          />
        </>
      )}

      <Routes>
        {getUserRoutes()}

        {/**
         *
         * All these routes reload the page
         * This is here for a very specific reason:
         * We're currently serving two fronts in the same backend, for this to work properly the browser has to
         * reload the page for it to be able to call for the other frontend, otherwise it just hangs there...
         *
         * If you come up with a better solution, please be my guest to fix this
         *
         **/}
        {/*<Route key={`login_admin`} path={"/login-admin/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_all_users`} path={"/adm/all-users/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_verification`} path={"/adm/verification/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_chat`} path={"/adm/chat/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_fees`} path={"/adm/fees/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_earnings`} path={"/adm/earnings/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_feedback`} path={"/adm/feedback/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_reservednames`} path={"/adm/reservednames/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_transactions`} path={"/adm/transactions/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_meet_types`} path={"/adm/meet_types/"} element={<Redirecting/>}/>*/}
        {/*<Route key={`adm_reported_users`} path={"/adm/reported_users/"} element={<Redirecting/>}/>*/}
        <Route key={`/`} path={"/"} element={<ToLanding/>}/>
        <Route key={`landing`} path={"/landing/"} element={<Redirecting/>}/>
        <Route key={`landing_faq`} path={"/landing/faq/"} element={<Redirecting/>}/>
        <Route key={`landing_howdoesitwork`} path={"/landing/how-does-it-work/"} element={<Redirecting/>}/>
        <Route key={`landing_whyidolidol`} path={"/landing/why-idolidol/"} element={<Redirecting/>}/>
        <Route key={`landing_support`} path={"/landing/support/"} element={<Redirecting/>}/>
        {/*<Route key={`import-content`} path={"/import-content/"} element={<ImportContentPage/>}
        {/**
         *
         * End of reloading pages
         *
         **/}

        <Route key={`route_notfound`} path={"*"} element={<NotFound/>}/>
      </Routes>
    </ThemeProvider>
  )
}
