import {useEffect, useState} from "react";
import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import {Box} from '@mui/material';
import BaseProtectedComponentPage from "components/BaseProtectedComponentPage";
import {Notifications} from "./notifications";
import {NotificationsMobile} from "./NotificationsMobile";
import {MeetingDetailsModal} from "components/ActionsEventModal/Cancel";
import {useStores} from "models";
import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";
import {useIsMobile} from "../../utils/hooks";
import {NavigationMenu} from "../../constants";
import MDTypography from "../../components/MDTypography";

const {card, white, red} = colors;

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showMeetingDetails, setShowMeetingDetails] = useState(false);
  const {loginStore} = useStores();
  const isIdol = loginStore.isIdol;
  const api = loginStore.environment.api;
  const isMobile = useIsMobile();

  const getNotifications = () => {
    api.getNotifications().then(response => {
      if (response.kind === "ok") {
        setNotifications(response.data.results)
      }
    })
  }

  useEffect(() => {
    getNotifications();
    loginStore.setActiveRoute(NavigationMenu.NOTIFICATIONS);
    loginStore.setMobileActiveRoute("NOTIFICATIONS")
  }, [])

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout>
        {(notifications.length === 0) && <MDTypography>No notification found</MDTypography>}

        <Box sx={styles.container}>
          {isMobile ?
            notifications.map(item => {
              const {id, user, action_user, category, message, is_read, created_at, meet} = item
              return (
                <NotificationsMobile
                  item={item}
                  avatar={action_user.image}
                  isVerified={action_user.is_validated}
                  idolizerName={action_user.username}
                  idolPoints={action_user.idol_points}
                  dateTime={created_at}
                  category={category}
                  tipAmount={meet?.fee}
                  setSelected={setSelected}
                  openModal={() => setShowMeetingDetails(true)}
                />
              )
            })
            :
            notifications.map(item => {
              const {id, user, action_user, category, message, is_read, created_at, meet} = item
              return (
                <Notifications
                  item={item}
                  avatar={action_user.image}
                  isVerified={action_user.is_validated}
                  idolizerName={action_user.username}
                  idolPoints={action_user.idol_points}
                  dateTime={created_at}
                  category={category}
                  tipAmount={meet?.fee}
                  setSelected={setSelected}
                  openModal={() => setShowMeetingDetails(true)}
                />
              )
            })
          }
        </Box>

        <MeetingDetailsModal
          open={showMeetingDetails}
          onClose={() => setShowMeetingDetails(false)}
          item={selected}
        />
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
}


const styles = {
  container: {
    background: card.background,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: "auto",
    whiteSpace: "nowrap",
    maxHeight: "75vh",
    paddingInline: pxToRem(34),
    paddingBlock: `${pxToRem(14)} ${pxToRem(34)}`,
  },
  record_container: {
    display: 'grid',
    gridTemplateColumns: '4fr 2.5fr',
  },
  base: {
    fontSize: pxToRem(16),
    fontWeight: 500,
  },
  date: {
    color: colors.date,
    fontSize: pxToRem(14),
    fontWeight: 400,
    textAlign: 'center',
  },
  third_card: {
    width: '100%',
    height: '95%',
    marginBottom: pxToRem(4),
    paddingBlock: pxToRem(25),
    paddingInline: `${pxToRem(25)} ${pxToRem(12)}`
  },
  description: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: '26px',
  },
  price_container: {
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(20),
  },
  price: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: '26px',
  },
  view_meet: {
    color: red.main,
    cursor: 'pointer',
  },
  icon: {
    width: pxToRem(24),
    height: pxToRem(24)
  },
};


export default NotificationsPage;
