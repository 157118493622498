import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import { useStores } from "../../models";

import BaseProtectedComponentPage from "../../components/BaseProtectedComponentPage";
import { useEffect, useState } from "react";
import { filteringFields, tab } from "./constants";
import { PeopleIdol } from "./cards";
import {useApi, useIsMobile, useUserStore} from "../../utils/hooks";
import { Box } from "@mui/material";
import besode3 from "assets/icons/people-red.svg"
import IdolizersMobileScreen from "components/Mobile/IdolizersMobileScreen/IdolizersMobileScreen";
import {NavigationMenu} from "../../constants";
import {BlockUserModal} from "../../components/BlockUserModal";
import {UnauthenticatedPaths} from "../../paths/paths";
import {ReportUserModal} from "../../components/ReportUserModal";
import {IdolTypeEnum} from "../../utils/constants";
import {notifyError} from "../../services/notification";
import {useLocation, useNavigate} from "react-router-dom";

function People() {
  const api = useApi();
  const { loginStore } = useStores();
  const isIdol = loginStore.isIdol;
  const [showReportUserModal, setShowReportUserModal] = useState(false);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [profile, setProfile] = useState({});
  const [isInfluencer, setIsInfluencer] = useState(false)
  const user = useUserStore()
  const location = useLocation()
  const navigate = useNavigate();
  const myProfileId = user.idol_id_name

  const [currentTab, setCurrentTab] = useState(isIdol ? tab.Followers : tab.Following);

  const isMobile = useIsMobile();

  const getProfile = () => {

    api.getIdolProfile(myProfileId).then(response => {
      if (response.kind === "ok") {
        const data = response.data
        const tags = []
        for (const obj of [data.tag_1, data.tag_2, data.tag_3]) {
          if (obj) {
            tags.push(obj.toUpperCase())
          }
        }

        setIsInfluencer(data.idol_type === IdolTypeEnum.influencer)

        setProfile({
          ...data,
          blocked: data.blocked,
          followers: data.followers,
          idolizers: data.subscribers,
          idolPoints: data.idol_points,
          isVerified: data.is_validated,
          tags: tags,
          bio: data.biography
        })


      } else {
        notifyError("Error getting profile")
      }

    }).catch(reason => {
      // notifyError("Error getting profile")
      console.log(reason)
    })
  }

  useEffect(() => {
    loginStore.setActiveRoute(NavigationMenu.DASHBOARD);
    loginStore.setMobileActiveRoute("IDOLIZERS")
    getProfile();
  }, []);

  const onReport = () => {setShowReportUserModal(true)};

  const onBlock = () => {setShowBlockUserModal(true)};

  return (
    <BaseProtectedComponentPage loginRequired={false}>
      <DashboardLayout icon={besode3}>
        {isMobile ? (
          <>
            <BlockUserModal user={profile}
                            open={showBlockUserModal}
                            onBlock={() => {
                              navigate(UnauthenticatedPaths.explore)
                            }}
                            onClose={() => setShowBlockUserModal(false)}/>

            <ReportUserModal user={profile}
                             open={showReportUserModal}
                             onReport={() => {
                               navigate(UnauthenticatedPaths.explore)
                             }}
                             onClose={() => setShowReportUserModal(false)}/>
            <IdolizersMobileScreen
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              actionsFn={{ onReport, onBlock }}
              isInfluencer={isInfluencer}
            />
          </>
        ) : isIdol ? (
          <>
            <BlockUserModal user={profile}
                            open={showBlockUserModal}
                            onBlock={() => {
                              navigate(UnauthenticatedPaths.explore)
                            }}
                            onClose={() => setShowBlockUserModal(false)}/>

            <ReportUserModal user={profile}
                             open={showReportUserModal}
                             onReport={() => {
                               navigate(UnauthenticatedPaths.explore)
                             }}
                             onClose={() => setShowReportUserModal(false)}/>
            <PeopleIdol
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              actionsFn={{ onReport, onBlock }}
              isInfluencer={isInfluencer}
            />
          </>

        ) : (
          <Box></Box>
        )}
        {!isIdol && <span>IDOLIZER Content</span>}
      </DashboardLayout>
    </BaseProtectedComponentPage>
  );
}

export default People;
