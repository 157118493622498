import React, {useState, useEffect} from 'react';
import moment from 'moment';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {MobileSectionHeader} from 'components/Mobile/MobileIdolDashboardSectionHeader';
import {RecordCard} from 'components/RecordCard/RecordCard';

/* utils */
import {formatNumberToCurrency} from 'utils/functions';

/* assets */
import pricetag from 'assets/icons/price-tag.svg';
import eventIcon from 'assets/icons/events.svg';
import meet from 'assets/icons/meet_white.svg';
import dollar from 'assets/icons/dollar_white.svg';
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {white, mobile: {transaction_date}} = colors;
const Icons = {
  subscription:pricetag,
  deposit:pricetag,
  post:pricetag,
  meet_greet:meet,
  live_call:eventIcon,
  tip:dollar
}

export const MobileIdolizerTransactionsCard = ({ transactionsData }) => {
  const [transactions, setTransactions] = useState([...transactionsData]);
  const recordProps = {
    isMobile: true, 
    showCard: false,
    contentContainerStyles: styles.transaction,
    containerStyles: styles.card_container,
  };

  const getTransactions = () => {};

  useEffect(() => {
    getTransactions();
  }, []);
  return (
    <Box sx={styles.container}>
      <MobileSectionHeader Title="Transactions" />
      <Box sx={styles.transactions_container}>
        {transactions.map(t => {
          let icon = (t.type === 'subscription' && pricetag)
          || (t.type === 'meet' && meet)
          || (t.type === 'tip' && dollar);
          icon = Icons[t.origin_type]??Icons.meet
          return (
            <RecordCard 
              {...recordProps}
              key={t.id}
              cardFirstContent={(
                <Box sx={styles.transaction_message_date_container}>
                  <Typography sx={styles.transaction_message}>{t.description}</Typography>
                  <Typography sx={styles.transaction_date}>
                    {moment(new Date(t?.created)).format("DD MMM YYYY").toUpperCase()}
                  </Typography>
                </Box>
              )}
              cardSecondContent={(
                <Box sx={styles.amount_container}>
                  <Typography sx={styles.amount}>{formatNumberToCurrency(t.amount)}</Typography>
                  <img src={icon} style={styles.icon} />
                </Box>
              )}
            />
          )
        })}
      </Box>
    </Box>
  );
};


export const styles = {
  container: {
    marginTop: pxToRem(14),
  },
  transactions_container: {
    marginTop: pxToRem(14),
    display: 'flex',
    flexDirection: 'column', 
    height: pxToRem(550),
    overflowY: 'auto',
    paddingInline: pxToRem(5),
  },
  transaction: {
    paddingBlock: `${pxToRem(16)} ${pxToRem(13)}`,
    paddingInline: pxToRem(14),
  },
  transaction_message_date_container: {
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(14),
    overflowY: 'auto',
    width: '100%',
  },
  transaction_message: {
    color: white.main,
    fontSize: pxToRem(12),
    fontWeight: 500,
    lineHeight: '100%',
    letterSpacing: '0.1em',
  },
  transaction_date: {
    color: transaction_date,
    fontSize: pxToRem(10),
    fontWeight: 400,
    lineHeight: pxToRem(12),
  },
  amount_container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: pxToRem(18),
    paddingBlock: pxToRem(8),
    width: '100%',
  },
  amount: {
    color: white.main,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: '26px',
  },
  icon: {
    width: pxToRem(18),
    height: pxToRem(18),
  },
};