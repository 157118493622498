import React from 'react'

/* MUI components */
import {Box, FormControlLabel, Typography} from '@mui/material';

/* components */
import RoundCheck from 'components/RoundCheck';


import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';

/* assets */
import circle from 'assets/icons/circulo-blanco.svg';
import visa from 'assets/icons/visa.svg';
import amex from 'assets/icons/amex.svg';
import mastercard from 'assets/icons/mastercard.svg';
import discover from 'assets/icons/discover.svg';
import diners from 'assets/icons/diners.svg';


const {white} = colors;
const cardIcon = {
  amex,
  diners,
  visa,
  mastercard,
  discover
}

export const SavedCardMobile = ({
                                  card,
                                  cardSelected,
                                  setCardSelected,
                                  deleteCard
                                }) => {
  const {card_type, card_number, expiry_year, expiry_month} = card;
  const card_type_formatted = card.card_type
    ? card_type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    : "";

  const card_number_formatted = card_number
    ? card_number.substring(card_number.length - 4, card_number.length)
    : "";

  const selectCard = () => {
    if (cardSelected === card_number) return
    setCardSelected(card)
  }

  return (
    <Box sx={styles.container}>
      <Box>
        <Box sx={styles.saved_card_container}>
          <FormControlLabel
            label={<img src={cardIcon[card_type]} width={45}/>}
            control={
              <RoundCheck
                IconChecked={circle}
                radioButtonSize="20px"
                checked={cardSelected.card_number === card_number}
                onClick={selectCard}
              />
            }
            sx={styles.form_control}
          />
          <Box>
            <Typography
              sx={styles.color_white}>
              {`${card_type_formatted} XXXX ${card_number_formatted}`}</Typography>
            <Typography sx={[styles.color_white, styles.expiration]}>
              Exp. {`${expiry_month}/${expiry_year}`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{...styles.delete}}>
        <Typography sx={[styles.color_white]} onClick={() => deleteCard(card.id)}>
          Delete
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: "row",
    background: colors.background.container,
    borderRadius: pxToRem(10),
    marginTop: pxToRem(12),
    justifyContent: "space-between",
    paddingRight: pxToRem(10)
  },
  saved_card_container: {
    alignItems: 'center',
    display: 'flex',
    gap: 1,
  },
  form_control: {
    display: 'flex',
    padding: '10px 0 10px 25px',
    ".MuiFormControlLabel-root": {marginRight: 0},
  },
  img: {
    width: pxToRem(20),
    height: pxToRem(20),
  },
  color_white: {
    color: white.main,
    fontSize: pxToRem(10),
    lineHeight: pxToRem(12),
  },
  expiration: {
    display: 'flex',
    alignItems: 'center',
    marginTop: pxToRem(5),
  },
  delete: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
  },
};

