import React, { useReducer, useState, useEffect, useMemo } from "react";

/* MUI components */
import { Box } from "@mui/material";

/* components */
import { BasePostCard } from "base/Cards/PostCard";
import { RecordCard } from "components/RecordCard/RecordCard";
import { PeopleSelectorButton } from "pages/people/cards";
import { BaseCardIdolInfo } from "base/Cards/InfoCards/IdolizerInfoCard";
import { IdolsActions } from "./actions";
import {SearchBox} from "components/SearchBox";

/* assets */
import { styles } from "./styles";
import { useApi, useInfiniteScroll } from "../../../../../utils/hooks";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import { initialRelatedStateIdolizer, relatedFilter, relatedFilterReducer } from "../../reducers";
import iconRed from "../../../../../assets/images/idol_red.png";
import iconGrey from "../../../../../assets/images/idol_grey.png";
import { BlockUserModal } from "../../../../../components/BlockUserModal";
import { ReportUserModal } from "../../../../../components/ReportUserModal";
import { notifyError, notifySuccess } from "../../../../../services/notification";
import {UnauthenticatedPaths} from "../../../../../paths/paths";
import {useNavigate} from "react-router-dom";
import debounce from 'lodash.debounce';

const idolImgType = {
  red: iconRed,
  gray: iconGrey,
};

export const IdolsPage = ({ profile}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(1);
  const [showReportUserModal, setShowReportUserModal] = useState(false)
  const [showBlockUserModal, setShowBlockUserModal] = useState(false)
  const [filter, dispatchFilter] = useReducer(relatedFilterReducer, initialRelatedStateIdolizer)
  const [searchText, setSearchText] = useState('')
  const api = useApi()
  console.log('followers: ', filter.followers);
  console.log('idolizing: ', filter.idolizing);

  const unfollow = () => {
    if (!selected.id) {
      notifyError("No Idol information to unfollow")
      return
    }
    api.unfollow(selected.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Unfollowing " + profile.username)
      } else {
        notifyError("Error unfollowing " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unfollowing " + profile.username)
    })
  }

  const unSubscribe = () => {
    if (!selected.id) {
      notifyError("No Idol information to unsubscribe")
      return
    }
    api.unsubscribe(selected.id).then((response) => {
      if (response.kind === "ok") {
        notifySuccess("Unsubscribing " + profile.username)
      } else {
        notifyError("Error unsubscribing " + profile.username)
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error unsubscribing " + profile.username)
    })
  }

  const onApiCall = ({page})=>{
    let data = {page, relation: filter.idolizing? "idolizing":"following", search: searchText}
    return api.getRelatedUsers(data)
  }
  const threeDotActions = (user) => {
    const actions = {
      onReport: () => {
        setSelected(user)
        setShowReportUserModal(true)
      },
      onBlock: () => {
        setSelected(user)
        setShowBlockUserModal(true)
      },
    }
    if (filter.idolizing){
      return {
        ...actions,
        onUnSubscribe: () => {
          setSelected(user)
          unSubscribe();
        },
      }
    } else {
      return {
        ...actions,
        onUnfollow: () => {
          setSelected(user)
          unfollow();
        },
      }
    }
  }

  const {
    items,
    fetchNextPage,
    extraData,
    hasNextPage,
    isFetchingNextPage,
    refetch
  } = useInfiniteScroll({apiCall:onApiCall, queryKey: "related_users", filters:filter })
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      refetch({refetchPage: (page, index) => index === 0})
    }, "100");
  }, [searchText])

  return (
    <BasePostCard
      title="Idols"
      headerControls={
        <SearchBox placeholder={"Search for Idols..."}
                   inputStyles={{ width: "300px", fontSize: 14, color: "white" }}
                   InputProps={{ maxLength: 10 }}
                   value={searchText}
                   onChange={e => setSearchText(e.target.value)}/>
      }
    >

      <BlockUserModal user={selected}
                      open={showBlockUserModal}
                      onBlock={() => {
                        setShowBlockUserModal(false)
                        refetch({ refetchPage: (page, index) => index === 0 })
                      }}
                      onClose={() => setShowBlockUserModal(false)}/>

      <ReportUserModal user={selected}
                       open={showReportUserModal}
                       fromURL={ '/dashboard/idols/'}
                       onClose={() => setShowReportUserModal(false)}/>
      <Box sx={styles.selector_styles}>
        <PeopleSelectorButton
          value={profile.number_of_following}
          label="following"
          selected={filter.following}
          onClick={() => dispatchFilter({type: relatedFilter.following})}
        />
        <PeopleSelectorButton
          value={profile.number_of_subscribed}
          label="idolizing"
          selected={filter.idolizing}
          onClick={() => dispatchFilter({type: relatedFilter.idolizing})}
        />
      </Box>

      <InfiniteScroll
        dataLength={items?.length}
        style={styles.scroll}
        next={() => {
          if (!isFetchingNextPage) {
            return fetchNextPage()
          }
        }}
        hasMore={!!hasNextPage}
      >
        <Grid container spacing={2}>
          {
            items.map((user, index) =>
            (
              <RecordCard
                handleOpen={(event) => setAnchorEl(event.currentTarget)}
                popoverProps={threeDotActions(user)}
                optionsContent={
                  <IdolsActions anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                    onReport={() => {
                      setSelected(user)
                      setShowReportUserModal(true)
                    }}
                    onBlock={() => {
                      setSelected(user)
                      setShowBlockUserModal(true)
                    }}
                    onUnfollow={() => {
                      setSelected(user)
                      unfollow();
                    }}
                    onUnSubscribe={() => {
                      setSelected(user)
                      unSubscribe();
                    }}
                  />
                }
                key={user.id}
                showCard={true}
                iconCard={false}
                showOptionsCard={true}
                cardFirstContent={<BaseCardIdolInfo {...user}
                  idolizerName={user.username}
                  idolPoints={user.idol_points}
                  avatar={user.image}
                  avatarStyles={styles.avatar}
                  nameStyles={styles.name}
                  pointStyles={styles.name}
                  infoCardStyles={styles.card_container}
                  onClick={() => navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", user.idol_id_name))}
                  pointContainerStyles={styles.points_container} />}
                cardSecondContent={user.idolizing ? "IDOLIZING" : "FOLLOWING"}
                cardThirdContent={<img src={idolImgType[user.idolizing ? "red" : "gray"]} />}
                cardThirdContentStyles={styles.card}
              />
            )
            )
          }
        </Grid>
      </InfiniteScroll>


    </BasePostCard>
  );
}
