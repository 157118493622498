import DashboardLayout from "base/LayoutContainers/DashboardLayout";
import BaseProtectedComponentPage from "../../../components/BaseProtectedComponentPage";
import { DeactivateAccount } from "./DeactivateAccount";
import {useStores} from "../../../models";
import {useEffect} from "react";
import {IdolizerVerification} from "../../verification/IdolizerVerification";

export const DeactivateAccountPage = (props) => {
  const {currentNavMenu} = props
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.setActiveRoute(currentNavMenu);
    loginStore.setMobileActiveRoute("DEACTIVATE ACCOUNT")
  }, []);
  return (
    <BaseProtectedComponentPage loginRequired={false}>
        <DashboardLayout>
          <DeactivateAccount />
        </DashboardLayout>
    </BaseProtectedComponentPage>
  )
}
