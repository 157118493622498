import React from 'react';

/* MUI components */
import {Box, Typography} from '@mui/material';

/* components */
import {SecondaryButton} from 'components/SecondaryButton';

/* utils */
import {formatNumberToCurrency} from 'utils/functions';

/* assets */
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {background, white} = colors;

export const IdolizerBalance = ({selectedCard, onBuy, balance}) => {
  return (
    <Box sx={styles.container}>
        <Typography sx={styles.balance_text}>Your Balance</Typography>
        <Typography sx={styles.balance}>{formatNumberToCurrency(balance)}</Typography>
        <SecondaryButton  
          text='Buy'
          onClick={onBuy}
          {...styles.button}
          textStyles={styles.button_txt}
        />
    </Box>
  )
}


export const styles = {
    container: {
        alignItems: 'center',
        background: background.container,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(25),
        justifyContent: 'center',
        height: pxToRem(176),
        margin: '0 auto',
        paddingBlock:`${pxToRem(24)} ${pxToRem(20)}`,
    },
    balance_text: {
        color: white.main,
        fontSize: pxToRem(12),
        fontWeight: 700,
        lineHeight: '93.75%',
        letterSpacing: '0.1em',
    },
    balance: {
        color: white.main,
        fontSize: pxToRem(36),
        fontWeight: 100,
        lineHeight: '36px',
    },
    button: {
        padding: 0,
        height: pxToRem(32),
        width: pxToRem(98),
    },
    button_txt: {
        fontSize: pxToRem(10),
        fontWeight: 700,
        lineHeight: '93.75%',
        letterSpacing: '0.1em',
    },
};