import { Box, Typography } from "@mui/material";
import colors from "../../../../assets/theme/base/colors";
import MDTypography from "../../../../components/MDTypography";
import rgba from "../../../../assets/theme/functions/rgba";
import moment from "moment";
import { CHAT_ADMIN_NAME } from "../../../../constants";

export const ChatBubble = ({me, message})=>{

  const mine = CHAT_ADMIN_NAME === message.author
  const bubbleStyle = mine? styles.rightBubble : styles.leftBubble

  return (
    <Box sx={{...styles.message, alignItems: mine?"flex-end":"flex-start"}}>
      <Box  sx={bubbleStyle}>
        <MDTypography color={"white"} sx={styles.text}>{message.body}</MDTypography>
      </Box>
      <MDTypography color={"white"} sx={styles.date}>{moment(message.date_created).format("hh:mm a")}</MDTypography>
    </Box>
  )
}

const styles = {
  message:{
    display:"flex",
    flexDirection:"column",
    alignItems:"flex-end",
    justifyContent:"flex-end",
    m:3,
    mt:0,
    p:0
  },
  date:{
    fontSize:11,
    mt:1,
    color:"white",
    fontWeight:400
  },
  text:{
    fontWeight:400,
    fontSize:14,
    color:"white"
  },
  rightBubble:{
    width:"55%",
    p:2,
    background:colors.background.card,
    borderRadius:"8px 0px 8px 8px"
  },
  leftBubble:{
    width:"55%",
    p:2,
    background:rgba("white", .3),
    borderRadius:"0px 8px 8px 8px"
  },
}
