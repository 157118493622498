export const initialValues = {
    cardHolder: "",
    expiryDate: '',
    CVV: '',
    cardNumber: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    stateProvice: '',
    ZIP: '',
    country: ''
}
//TODO: ADD CARD .VC
