/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* mui components */
import {Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, Stack} from '@mui/material';

/* assets */
import {getCardStyles, getHeaderStyles, getTitleStyles, getAccordionStyles, getAccordionSummaryStyles, getExpandIconStyles} from './styles';
import {ExpandMore} from "@mui/icons-material";
import pxToRem from "../../assets/theme/functions/pxToRem";

export const ProfileCard = ({title, isExpanded = undefined, onChange = undefined, background = 'bold', children}) => {
  return isExpanded === undefined ?
    (<Card sx={getCardStyles}>
      {title ? <CardHeader
        title={title}
        titleTypographyProps={getTitleStyles()}
        sx={getHeaderStyles}
      /> : null}
      {children}
    </Card>)
    :
    <Accordion expanded={isExpanded} onChange={onChange} sx={(theme) => getAccordionStyles(theme, background)}>
      <AccordionSummary sx={getAccordionSummaryStyles} expandIcon={<ExpandMore fontSize={"medium"} sx={getExpandIconStyles}/>}>
        <CardHeader title={title} titleTypographyProps={getTitleStyles()} sx={getHeaderStyles}/>
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0}}>
        <Stack gap={pxToRem(12)}>
          {children}
        </Stack>
      </AccordionDetails>
    </Accordion>
}

ProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
