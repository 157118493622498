/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* mui components */
import {CardHeader, Typography} from '@mui/material';

/* components */
import {MobileSubHeader} from './MobileSubHeader';
import {MobileIdolizerText} from './MobileIdolizerText';

/* assets */
import DefaultAvatar from 'assets/images/explore-default.png';
import {styles} from './styles';
import {UnverifiedMobileIdolAvatar, VerifiedMobileIdolAvatar} from "./MobileIdolAvatars";

export const MobileBaseCardIdolInfo = ({
  avatar,
  idolizerName,
  idolPoints,
  meetingType,
  isVerified,
  isIdolizer,
  pointStyles,
  nameStyles,
  centerPoints,
  isWide,
}) => {
  return (
    <CardHeader
      avatar={
        !isVerified ? (
          <UnverifiedMobileIdolAvatar avatarSrc={avatar} />
        ) : (
          <VerifiedMobileIdolAvatar avatarSrc={avatar} />
        )
      }
      sx={isWide?{...styles.headerWide}:{...styles.header}}
      title={<Typography sx={[styles.name, nameStyles]}>{idolizerName}</Typography>}
      subheader={<MobileSubHeader data={idolPoints} pointStyles={pointStyles} centerPoints={centerPoints} meetingType={meetingType} />}
      action={isIdolizer && <MobileIdolizerText />}
    />
  );
};

MobileBaseCardIdolInfo.propTypes = {
    idolizerName: PropTypes.string.isRequired,
    idolPoints: PropTypes.number.isRequired,
    isVerified: PropTypes.bool,
    isIdolizer: PropTypes.bool,
    pointStyles: PropTypes.object,
    nameStyles: PropTypes.object,
}

MobileBaseCardIdolInfo.defaultProps = {
    isVerified: false,
    isIdolizer: false,
    avatar: DefaultAvatar,
}
