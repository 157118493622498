import React from 'react';

import Button from '@material-ui/core/Button';
// @ts-ignore
import MicIcon from '../../images/videocall_voice_enable.png';
// @ts-ignore
import MicOffIcon from '../../images/videocall_voice_disable.png';
// @ts-ignore
import MicDisabledIcon from '../../images/videocall_voice_no.png';

import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: 'transparent',
      color: 'transparent',
      '&:hover': {
        filter: 'brightness(110%)',
        background: 'transparent',
      },
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const {localTracks} = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Button
      className={clsx(classes.button, props.className)}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      {!hasAudioTrack ? <img alt='No Audio' src={MicDisabledIcon} style={{height: '48px'}}/> :
        isAudioEnabled ?
          <img alt='Mute' src={MicIcon} style={{height: '48px'}}/> :
          <img alt='Unmute' src={MicOffIcon} style={{height: '48px'}}/>
      }
    </Button>
  );
}
