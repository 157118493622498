export const EXPLORE_ACTIONS = {
  SET_LIST_REFRESH : 'set-list-refresh',
  SET_SEARCH_USERS: 'set-search-users',
  SET_SEARCH_POSTS: 'set-search-posts',
  SET_SEARCH_FILTER: 'set-search-filter',
  SET_SEARCH_VALUE: 'set-search-value',
  SET_EXPLORE_FILTER: 'set-explore-filter',
  SET_LAYOUT: 'set-layout',
}

export const ExploreLayout = {
  explore: "explore",
  search: "search"
}
