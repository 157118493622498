import pxToRem from "../../../../assets/theme/functions/pxToRem";
import rgba from "../../../../assets/theme/functions/rgba";
import colors from "../../../../assets/theme/base/colors";

export const desktopStyles = {
  detailColumn:{
    marginTop:"33px",
    display:"flex",
    flexDirection:"column",
    gap:"16px"
  },
  shapeRow:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginTop:"50px",
    gap:"10px"
  },
  sizesRow:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginTop:"40px",
    gap:"20%"
  },
  section2:{
    marginTop: pxToRem(30),
    paddingLeft: pxToRem(60),
    paddingRight: pxToRem(60),
  },
  bio:{
    marginTop:"8px",
    fontSize:12,
    color:"white",
    fontWeight:400,
    lineHeight:"20px"
  },
  tagsContainer:{
    display:"flex",
    flexDirection:"row",
    width:"100%",
    justifyContent:"flex-start",
    marginTop:"24px"
  },
  idolPointsContainer:{
    display:"flex",
    alignItems:"flex-end"
  },
  arrow: {
    cursor: 'pointer',
    width:"10px",
    height:"20px",
    marginTop: pxToRem(45),
    marginLeft: pxToRem(39),
  },
  location:{
    marginTop:"10px",
    fontSize:12,
    color:rgba(colors.white.main, 0.6)
  },
  tags:{
    color:"white",
    fontSize:14,
    fontWeight:500,
    letterSpacing:"0.05em"
  },
  basicInfo:{
    width:"250px",
    marginLeft:"26px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  verified: {
    width: "23px",
    height: "23px",
    marginLeft:"23px"
  },
  username: {
    color:"white",
    fontWeight:400,
    fontSize:32,
    overflowWrap:"anywhere",
    lineHeight:"2rem"
  },
  idp:{
    color:colors.primary.main,
    fontWeight:300,
    fontSize:16,
    marginBottom:"-8px",
    marginLeft:"8px"
  },
  idolPoints: {
    marginTop:"20px",
    color:colors.primary.main,
    fontWeight:300,
    fontSize:36,
    lineHeight:"26px",
  },
  main:{
    display:"flex",
    flexDirection: "column",
    width:"40vw",
    justifyContent: "center"
  },
  section1:{
    marginTop:"28px",
    width:"40vw",
    paddingLeft:60,
    paddingRight:60,
    display:"flex",
    flexDirection:"row",
    justifyContent:"center"

  }
}

export const mobileStyles = {
  detailColumn:{
    marginTop:"33px",
    display:"flex",
    flexDirection:"column",
    gap:"16px"
  },
  shapeRow:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginTop:"50px",
    gap:"2%"
  },
  sizesRow:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    marginTop:"40px",
    gap:"20%"
  },
  section2:{
    padding:16
  },
  bio:{
    marginTop:"8px",
    fontSize:12,
    color:"white",
    fontWeight:400,
    textAlign:"justify"
  },
  tagsContainer:{
    display:"flex",
    flexDirection:"row",
    width:"100%",
    justifyContent:"center",
    marginTop:"8px"
  },
  idolPointsContainer:{
    display:"flex",
    alignItems:"flex-end"
  },
  arrow: {
    cursor: 'pointer',
    width:"10px",
    height:"20px",
    marginTop: pxToRem(45),
    marginLeft: pxToRem(39),
  },
  location:{
    marginTop:"8px",
    fontSize:12,
    color:rgba(colors.white.main, 0.6)
  },
  tags:{
    color:"white",
    fontSize:14,
    fontWeight:500,
    letterSpacing:"0.05em"
  },
  basicInfo:{
    marginLeft:"26px",
    marginTop:"8px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
  verified: {
    width: "23px",
    height: "23px",
    marginLeft:"8px"
  },
  username: {
    color:"white",
    fontWeight:500,
    fontSize:24,
    overflowWrap:"anywhere",
    lineHeight:"2rem"
  },
  idp:{
    color:colors.primary.main,
    fontWeight:500,
    fontSize:16,
    marginBottom:"-5px",
    marginLeft:"8px"
  },
  idolPoints: {
    marginTop:"8px",
    color:colors.primary.main,
    fontWeight:300,
    fontSize:24,
    lineHeight:"26px",
  },
  main:{
    display:"flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  section1:{
    marginTop:"28px",
    width:"100vw",
    padding:"16px",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  }
}
