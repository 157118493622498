import {Box, Button, Stack, Typography} from "@mui/material";
import {SecondaryButton} from "components/SecondaryButton";
import {CustomTextField} from "components/TextField";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {ThinButtonTransparent} from "../../components/ThinButtonTransparent";
import {useApi} from "../../utils/hooks";
import {PostCategoryEnum} from "../../constants";

export const EditPost = ({post, handleSave, sendValues, handleClean}) => {
  const isVideo = post.isVideo
  const post_id = post.id
  const item = isVideo ? post.file : post.image
  const component = () => {
    let componentType = "img"
    if (isVideo) {
      componentType = "video"
    }
    return componentType
  }
  const initialValues = {
    price: post.additional_price,
    file: item,
    description: post.description,
    tier: post.post_type.id,
  };
  const api = useApi()
  const [payPerViewInputOpen, setPayPerViewInputOpen] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  });
  const handlePostState = (openClose, lockedUnlockedPayPerView) => {
    setPayPerViewInputOpen(openClose);
    formik.setFieldValue("tier", lockedUnlockedPayPerView);
  };

  const handleSubmit = (values) => {
    sendValues(values)
    handleSave({values, post_id})
  }

  const description = formik.values.description;
  const file = formik.values.file;
  const additionalPrice = formik.values.price;
  const postTier = formik.values.tier;


  return (
    <Box sx={styles.container}>
      <Box component="form" onSubmit={formik.handleSubmit} sx={styles.content}>
        <Typography sx={styles.title}>Edit post</Typography>
        <Box display={"flex"} gap="34px">
          <Box sx={styles.postContainer}>
            <Box component={component()} src={file} alt="image-preview" sx={styles.postStyles}/>
          </Box>
          <Box></Box>
          <Box sx={styles.descriptionContainer}>
            <Box>
              <Typography sx={styles.subtitle}>Description</Typography>
              <CustomTextField
                placeholder="Compose new post..."
                name="description"
                value={description}
                onChange={formik.handleChange}
                sx={styles.descriptionInput}
              />
              <Box sx={styles.tierInputsContainer}>
                <Typography sx={styles.subtitle}>Select tier</Typography>
                <Stack direction="row" spacing={2}>
                  <Button
                    sx={postTier === 1 ? {...styles.tierInput, backgroundColor: "#D9243D"} : styles.tierInput}
                    name="tier"
                    onClick={() => handlePostState(false, 1)}
                  >
                    Unlocked
                  </Button>
                  <Button
                    sx={postTier === 2 ? {...styles.tierInput, backgroundColor: "#D9243D"} : styles.tierInput}
                    name="tier"
                    onClick={() => handlePostState(false, 2)}
                  >
                    Locked
                  </Button>
                  <Button
                    sx={postTier === 3 ? {...styles.tierInput, backgroundColor: "#D9243D"} : styles.tierInput}
                    name="tier"
                    onClick={() => handlePostState(true, 3)}
                  >
                    Pay Per View
                  </Button>
                </Stack>
                {(payPerViewInputOpen || postTier === 3) && (
                  <Box sx={styles.payPerViewContainer}>
                    <Typography sx={styles.subtitle}>Set price</Typography>
                    <CustomTextField
                      placeholder="$0.00"
                      name="price"
                      type="number"
                      required
                      value={additionalPrice}
                      onChange={formik.handleChange}
                      sx={styles.textField}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{display: "flex", gap: "22px"}}>
              <ThinButtonTransparent
                text="CANCEL"
                onClick={() => {
                  handleClean()
                }}
                textStyles={styles.cancelTextStyles}
                buttonStyle={styles.cancelBtn}
              />
              <ThinButtonTransparent
                text="SAVE"
                type="submit"
                textStyles={styles.textStyles}
                buttonStyle={styles.buttonStyle}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    padding: "44px 44px 44px 20px",
  },
  content: {
    backgroundColor: "#192537",
    borderRadius: "10px",
    padding: "30px",
  },
  title: {
    fontSize: "50px",
    fontWeight: 100,
    lineHeight: "36px",
    color: "#fff",
    paddingBottom: "21px",
    borderBottom: "2px solid #D9243D",
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "140%",
    color: "#fff",
    paddingBottom: "11px",
  },
  postContainer: {
    paddingTop: "28px",
  },
  postStyles: {
    backgroundColor: "#192537",
    width: "297px",
    minWidth: "295px!important",
    height: "528px",
    borderRadius: "5px",
    objectFit: "cover"
  },
  descriptionInput: {
    width: "100%",
    height: "107px",
  },
  tierInputsContainer: {
    marginTop: "25px",
  },
  tierInput: {
    width: "100%",
    height: "90px",
    backgroundColor: "#3D4755",
    borderRadius: "10px",
    border: "none",
    fontSize: "20px",
    color: "#fff",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.12px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#D9243D",
      color: "#fff!important",
    },
    "&:focus": {
      backgroundColor: "#D9243D",
      color: "#fff!important",
    },
  },
  descriptionContainer: {
    width: "100%",
    marginTop: "28px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    maxWidth: "292px!important",
    height: "40px",
    minHeight: "40px",
    backgroundColor: "#192537",
    textTransform: "capitalize",
    marginBottom: "50px",
  },
  cancelBtn: {
    width: "145px",
    minWidth: "145px!important",
    border: "2px solid #3D4755",
    color: "#3D4755!important",
    height: "40px",
    minHeight: "40px",
    textTransform: "uppercase",
    "&:hover": {
      border: "2px solid #f52642",
      color: "#f52642!important",
    },
    "&:focus": {
      color: "#f52642!important",
    },
  },
  cancelTextStyles: {
    fontSize: "14px",
    color: "#3D4755!important",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    "&:hover": {
      color: "#f52642!important",
    },
  },
  textStyles: {
    fontSize: "14px",
    color: "#D9243D",
    fontWeight: 700,
    lineHeight: "93%",
    letterSpacing: "0.1em",
    textTransform: "uppercase"
  },
  payPerViewContainer: {
    paddingTop: "24px",
  },
};
