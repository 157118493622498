import colors from "assets/theme/base/colors";

export const styles = {
    inputContainer: {
        width: '100%',
        marginTop: 5
    },
    actions: {
        width: '350px',
        // height:"56px",
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: {
            xl: 30,
            lg: 12
        },
        marginBottom: 10
    },
    skip: {
        cursor: 'pointer',
        color: colors.white.main,
        textAlign: 'center'
    },
};
