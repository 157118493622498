import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";
export const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        gap: '20px'
    },
    textContainer: {
        diplay: 'flex',
        justifyContent: 'center'
    },
    amount: {
        color: '#fff',
        fontWeight: '400',
        fontSize: '20px'
    },
    pointsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'baseline',
    },
    points: {
        color: colors.primary.main,
        fontWeight: '500',
        fontSize: '16px'
    },
    idp: {
        color: colors.primary.main,
        fontWeight: '500',
        fontSize: '12px'
    },
    avatar: {
        width: '70px',
        height: '65px',
        borderRadius: '5px'
    },
    icon: {
        width: pxToRem(24),
        height: pxToRem(24),
        marginBottom: pxToRem(7)
    },
}