/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box, fabClasses, Typography} from '@mui/material';

/* components */
import {BaseIdolCard} from 'base/Cards/IdolCard';

/* assets */
import {styles} from './styles';
import world from 'assets/images/map.png';
import avatarexample from 'assets/images/avatar_example.png';
import {MobileCardEventActionsBox} from "../MobileBaseCardEventActionsBox";
import {MobileBaseCardIdolInfo} from "../MobileInfoCards/IdolizerInfoCard";
import {MobileDatePriceEvent} from "../DatePriceEventTrackerMobile";

export const IdolMeetingCardMobile = ({
  title,
  idolizerName,
  idolPoints,
  avatar,
  isVerified,
  price,
  date,
  timeStart,
  timeEnd,
  timeZone,
  meetingType,
  onDismiss,
  onApprove,
  approveButtonTextStyles,
  dismissButtonTextStyles,
  isWide,
  isIdolizer,
  isPending = false,
  isUpcoming = false,
}) => {
  const cardProps = {
    title,
    uppercaseTitle: false,
    titleHeaderStyles: styles.headerText,
    cardStyles: isWide?(styles.baseCardWide):(styles.baseCard),
    headerStyles: styles.header,
  };


  const infoProps = {
    isWide,
    idolizerName,
    idolPoints,
    isVerified,
    meetingType,
    nameStyles: styles.name,
    pointStyles: styles.points,
    avatar: avatar,
  };

  const eventProps = {
    price,
    date,
    timeStart,
    timeEnd
  };

  const getApproveText = () => {
    let approveText = '';
    //
    if (!!isPending) {
      if(!isIdolizer) {
        approveText = 'Approve';
      } else {
        approveText = 'Pending';
      }
    } else 
    if (!isUpcoming) {
      if(!isIdolizer) {
        approveText = 'Launch';
      } else {
        approveText = 'Join Call';
      }
    } else {
      approveText = 'Upcoming';
    }
    //
    return approveText
  }

  const getApproveDisabled = () => {
    let approveDisabled = true;
    // 
    if (!!isPending) {
       // Approve/Abled = Idol, Pending/Disabled = Idolizer
       if(!isIdolizer) {
          approveDisabled = false;
       }           
    } else if (!!isUpcoming) {
        approveDisabled = true;
      } else {
        approveDisabled = false;
      }
    //
    return approveDisabled;
  }

  const actionProps = {
    isWide,
    approveText: getApproveText(),
    dismissText: !isIdolizer ? 'Dismiss' : 'Cancel',
    approveAction: onApprove,
    approveDisabled: getApproveDisabled(),
    dismissAction: onDismiss,
    approveTextStyles: approveButtonTextStyles,
    dismissTextStyles: dismissButtonTextStyles,
    containerStyles: styles.buttonContainer
  };

  return (
    <BaseIdolCard {...cardProps}>
      <Box sx={styles.idolInfo}>
        <MobileBaseCardIdolInfo {...infoProps} />
      </Box>
      <Box sx={isWide?{...styles.contentWide}:{...styles.content}}>
        <MobileDatePriceEvent {...eventProps} />
        <Box sx={[styles.general_info, styles.timezoneContainer]}>
            <Box sx={styles.datetime_info_container}>
                    <img src={world} alt="world icon" title="world" />
                <Box>
                    <Typography sx={styles.datetime_info_text} variant='subtitle2'>{timeZone}</Typography>
                </Box>
            </Box>
              <Box sx={styles.meeting_price}>{price}</Box>
        </Box>
      </Box>
      <Box sx={isWide?{...styles.actions_containerWide}:{...styles.actions_container}}>
        <MobileCardEventActionsBox {...actionProps}/>
      </Box>
    </BaseIdolCard>
  );
};

IdolMeetingCardMobile.propTypes = {
  title: PropTypes.string.isRequired,
  idolizerName: PropTypes.string.isRequired,
  idolPoints: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  meetingType: PropTypes.oneOf(['idolevent', 'idolcall']).isRequired,
  onDismiss: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  avatar: PropTypes.string,
  isVerified: PropTypes.bool,
};
