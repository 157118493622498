import React from 'react'

/* MUI components */
import {Box, FormControlLabel, Typography} from '@mui/material';

/* components */
import RoundCheck from 'components/RoundCheck';

/* assets */
import {styles} from './styles';
import circle from 'assets/icons/circulo-blanco.svg';
import visa from 'assets/icons/visa.svg';
import amex from 'assets/icons/amex.svg';
import mastercard from 'assets/icons/mastercard.svg';
import discover from 'assets/icons/discover.svg';
import diners from 'assets/icons/diners.svg';

const cardIcon = {
  amex,
  diners,
  visa,
  mastercard,
  discover
}

export const SavedCard = ({
 card,
 cardSelected,
 setCardSelected,
 deleteCard
}) => {
  const {card_type, card_number, expiry_year, expiry_month} = card;
  const card_type_formatted = card.card_type
    ? card_type.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    : "";

  const card_number_formatted = card_number
    ? card_number.substring(card_number.length - 4, card_number.length)
    : "";

  const selectCard = () => {
    console.log(cardSelected, card_number)
    if(cardSelected === card_number) return
    setCardSelected(card)
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.saved_card_container}>
        <FormControlLabel
          label={<img src={cardIcon[card_type]} width={45} />}
          control={
            <RoundCheck
              IconChecked={circle}
              radioButtonSize="20px"
              checked={cardSelected.card_number === card_number}
              onClick={selectCard}
            />
          }
          sx={styles.form_control}
        />
        <Typography
          sx={styles.color_white}
        >{`${card_type_formatted} XXXX ${card_number_formatted}`}</Typography>
      </Box>
      <Typography sx={[styles.color_white, styles.expiration]}>
        Exp. {`${expiry_month}/${expiry_year}`}
      </Typography>
      <Typography sx={[styles.color_white, styles.delete]} onClick={() => deleteCard(card.id)}>
        Delete
      </Typography>
    </Box>
  );
};
