import { observer } from "mobx-react";
import { Box, Button, Card, CardActions, CardContent } from "@mui/material";
import MDTypography from "../MDTypography";
import React, { Fragment, useEffect, useRef, useState } from "react";
import colors from "../../assets/theme/base/colors";
import closeIcon from "assets/icons/X.svg"
import LiveChatIcon from "assets/icons/live_chat.svg";
import SendIcon from "../../assets/icons/PaperPlaneTilt.svg";
import MDInput from "../MDInput";
import moment from "moment";
import { MESSAGES } from "./data";
import { ChatBubble } from "../../pages/admin/live-chat/components/ChatBubble";
import { SupportChatBubble } from "./SupportChatBubble";
import { useApi, useUserStore } from "../../utils/hooks";
import { Client as ConversationsClient } from "@twilio/conversations";

const INITIAL_STATE = {
  name: "",
  loggedIn: false,
  token: "",
  statusString:"",
  status:"",
  conversationsReady: false,
  messages: [],
  newMessage: "",
  selectedConversationSid: null,
  conversations: []
}

const ConversationStatus = {
  connecting: "connecting",
  connected:"connected",
  disconnecting:"disconnecting",
  disconnected:"disconnected",
  denied:"denied",


}

let conversationsClient = null


export const SupportChat = observer(({show, onClose, onOpen})=> {
  const [Messages, setMessages] = useState([]);
  const [NewMessages, setNewMessages] = useState([]);
  const [TwilioChatSID, setTwilioChatSID] = useState(null);
  const currentUser = useUserStore();
  const scrollRef = useRef(null);
  //
  const api = useApi()

  const [chat, setChat] = useState(INITIAL_STATE);
  const [value, setValue] = useState(null)

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "instant" })
  }
  
  const getTwilioToken = ()=> {
    api.getTwilioToken().then(response => {
      if(response.kind === "ok") {
        const token = response.data.twilio_token;
        conversationsClient = new ConversationsClient(token);
        getTwilioChatSID();
      }
    }).catch(reason => console.log(reason))
      // .finally(() => 
      // setLoading(false)
      // )
  }  

  const onSelectConversation = async (sid)=> {
    console.log("onSelectConversation")
    // setSelectedConversation(room);
    let conversation = await conversationsClient.getConversationBySid(sid);
    // Join the participant to the conversation
    try {
      await conversation.join();
      // Participant joined the conversation
    } catch (error) {
      // Error joining participant to conversation
    }
    // Getting incoming messages
    conversation.on("messageAdded", async (incomingMessage) => {
      setNewMessages(value => [...value, incomingMessage]);
    });
    

  }

  const getTwilioChatSID = () => {    
    api.getTwilioChatSID().then(response => {
      if(response.kind === "ok") {
        const sid = response.data.sid;
        setTwilioChatSID(sid)        
      }
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getMessages()
    }, 1000)
    getTwilioToken();
  }, [])

  useEffect(() => {
    if (!!TwilioChatSID) {
      onSelectConversation(TwilioChatSID);
    }      
  }, [TwilioChatSID])

  useEffect(() => {
    scrollToBottom();
  }, [Messages]);

  useEffect(() => {
    scrollToBottom();
  }, [NewMessages]);


  const getMessages = ()=> {
    api.getSupportMessages().then(response => {
      if(response.kind === "ok"){
        setMessages(response.data.messages)
      }
    }).catch(reason => console.log(reason))
  }


  const sendMessage = ()=> {
    if(value) {
      api.sendSupportMessage({ message: value }).then(response => {
        if(response.kind === "ok") {
          setValue("");
        }

      }).catch(reason => console.log(reason))
    }
  }

  return (
    <Fragment>
      <Box sx={{p:2}}>
        {show ? (
          <Card variant="outlined" sx={{  minWidth: 150, boxShadow: 5, background:colors.background.card, maxHeight:"200px"}}>
            <CardContent sx={{maxHeight:"200px", mx:-3}}>
              <Box sx={{background: colors.background.card, display:"flex", flexDirection:"column", alignItems:"center", height:"30px"}}>
                <Box component={"img"} src={closeIcon} onClick={onClose} width={"18px"} sx={styles.closeIcon}/>
                <MDTypography sx={styles.title} color={"white"}>SUPPORT CHAT</MDTypography>
              </Box>

              <Box sx={{background: "white", height:"180px", overflowY:"scroll", pt:2}}>
                <MDTypography sx={styles.title} color={"primary"}>{chat.statusString}</MDTypography>                           
                {Messages.map((message, index)=><SupportChatBubble me={currentUser} message={message} key={"chatBubble-"+index} />)}
                {NewMessages.map((message, index)=><SupportChatBubble me={currentUser} message={message} key={"chatBubble-"+index} />)}
                <div ref={scrollRef}></div>
              </Box>
              <CardActions sx={{background:"white", borderRadius:"0px 0px 8px 8px"}}>
                <Box sx={styles.footer}>
                  <Box sx={{...styles.divider, mt:0,  width:"100%"}} />

                  <Box sx={{height:"40px"}} >
                    <MDInput
                      variant="standard"
                      color={"primary"}
                      placeholder={"Type a message here..."}
                      sx={styles.chatText}
                      value={value}
                      onKeyDown={e => {
                        if (e.ctrlKey && e.key === 'Enter') setValue(`${value}\n`)
                      }}
                      onChange={e => {
                        if (e.target.value === `${value}\n`) {
                          sendMessage()
                          setValue('')
                        } else setValue(e.target.value)
                      }}
                      multiline />
                  </Box>

                  <Box sx={styles.chatBoxActions}>
                    <Box component={"img"} sx={{cursor:"pointer"}} src={SendIcon} width={"20px"} onClick={()=>sendMessage()} />
                  </Box>
                </Box>
              </CardActions>
            </CardContent>

          </Card>
        ) : (
          <Box sx={styles.openChat} onClick={onOpen}>
            <Box component={"img"} src={LiveChatIcon}  width={"18px"}  />
            <MDTypography sx={{...styles.title,ml:2}} color={"white"}>OPEN CHAT SUPPORT</MDTypography>
          </Box>
        )}
      </Box>
    </Fragment>
  )
})

const styles = {
  title:{
    fontWeight:400,
    fontSize:14,
  },
  openChat:{
    borderRadius:"12px 12px 12px 12px",
    cursor:"pointer",
    p:2,
    background: colors.background.card,
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    height:"50px"
  },
  closeIcon:{
    top:"8px",
    right:"8px",
    position:"absolute"
  },
  divider:{
    height: "2px",
    zIndex:5,
    width: "100%",
    background: colors.grey["480"]
  },
  chatBoxActions:{
    zIndex:2,
    width:"40px",
    mt:1,
    right:"0px",
    marginLeft:"auto",
    position:"relative"
  },
  chatText:{
    width:"100%",
    padding:1,
    maxHeight:"50px",
    overflowY:"scroll",
    ".MuiInput-input": {
      color: colors.background.default
    },
    '.MuiSvgIcon-root': {
      color: colors.background.default
    }}
}
