import moment from "moment"


export const  MESSAGES = [
  {
    from: "DwightSchrute",
    to: "admin",
    message:"Congue nisi cursus malesuada tempus sed nunc velit cursus.",
    date: moment().format("hh:mm a")
  },
  {
    from: "DwightSchrute",
    to: "admin",
    message:"Congue nisi cursus malesuada tempus sed nunc velit cursus.",
    date: moment().format("hh:mm a")
  },
  {
    to: "DwightSchrute",
    from: "admin",
    message:"Hello, my name is Mark, how can I help you today?",
    date: moment().format("hh:mm a")
  }
]
