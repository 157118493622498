import { Box, Typography } from "@mui/material";
import { setLayout, useMaterialUIController } from "../../../../context";
import React, { memo, useEffect, useState } from "react";
import colors from "../../../../assets/theme/base/colors";
import { ExploreLayout } from "../utils/constants";
import { LayoutNames } from "../../../../constants";
import { useApi } from "../../../../utils/hooks";
import ChevronBackIcon from "../../../../assets/icons/chevron_back.svg";
import { SearchBoxMobile } from "../../../../components/SearchBoxMobile";
import { useExploreApp } from "../utils/hooks";
import { ExploreTab } from "./Tab";
import { FilterType, SearchFilterType } from "../../constants";
import { SearchUsers } from "./SearchUsers";
import { SearchPosts } from "./SearchPosts";

export const SearchContent = memo(() => {
  const {searchFilter, setSearchFilter, setExploreLayout, setSearchValue} = useExploreApp()
  const [controller, dispatch] = useMaterialUIController();
  const [query, setQuery] = useState("")
  const api = useApi()

  useEffect(() => {
    setLayout(dispatch, LayoutNames.general);
  }, []);


  return (
    <React.Fragment>
      <Box style={styles.navbar}>
          <Box component={"img"}  sx={{ml:"5px", mr:"5px"}} src={ChevronBackIcon} onClick={() => {
            setExploreLayout(ExploreLayout.explore)
            setTimeout(() => {
              window.location.reload();
            }, 10);
          }} />
          <SearchBoxMobile placeholder={"I am looking for..."}
                           inputStyles={{width:"auto", fontSize:14, color:"white"}}
                           value={query}
                           InputProps={{
                             maxLength:10
                           }}
                           handleSearch={(value)=>setQuery(value)} />
          <Typography
            onClick={()=>setSearchValue(query)}
            sx={{color: colors.primary.main, fontWeight:"600",fontSize:"14px"}}>Search</Typography>
      </Box>
      <Box style={styles.tabs}>
        <ExploreTab selected={searchFilter === SearchFilterType.users}
                    onClick={()=>setSearchFilter(SearchFilterType.users)}
                    title={"Users"}/>
        <ExploreTab selected={searchFilter === SearchFilterType.posts}
                    onClick={()=>setSearchFilter(SearchFilterType.posts)}
                    title={"Posts"}/>
      </Box>

      {
        searchFilter === SearchFilterType.users
          ? <SearchUsers />
          : <SearchPosts />
      }

    </React.Fragment>
  )
})

const styles = {
  main:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between"
  },
  scroll:{
    display:"flex",
    flexDirection:"column",
    overflow:"auto",
    flex:"none",
    width:"100vw",
    height:"70vh",
    flexGrow: "column nowrap",
    scrollSnapType:"y mandatory",
    overflowX:"hidden",
    "&::-webkit-scrollbar": {display: "none"},
  },

  tabs:{
    paddingLeft:"20px",
    paddingRight:"30px",
    flexGrow:1,
    display:"flex",
    flexDirection:"row",
    justifyContent:"center"
  },
  navbar: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100vw",
    alignItems: "center",
    padding: "24px",
  }
}
