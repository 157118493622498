// filter names on backend
export const filterField = {
  all:"all", // this field is not in backend, must be exclude in the api call
  video:"video",
  image:"image",
  locked:"tier_2",
  unlocked:"tier_1",
  payPerView:"additional_feature"
}

export const filterActions = {
  TYPE_ALL:"type_all",
  TYPE_VIDEO:"type_video",
  TYPE_IMAGE:"type_image",
  CONTENT_UNLOCKED:"content_unlocked",
  CONTENT_LOCKED:"content_locked",
  CONTENT_PAY:"content_pay",
}

export const initialFilters = {
  typeAll: true,
  typeName: filterField.all,
  typeVideo:false,
  typeImage:false,
  contentPay:false,
  contentLocked:false,
  contentUnlocked:false,
  contentName: filterField.all,
}

export const filtersReducer = (state, action) =>{
  // eslint-disable-next-line default-case
  switch (action.type) {
    case filterActions.TYPE_ALL: {
      return {
        ...state,
        typeAll:true,
        typeVideo:false,
        typeImage:false,
        typeName:filterField.all
      }
    }
    case filterActions.TYPE_VIDEO: {
      return {
        ...state,
        typeAll:false,
        typeVideo:true,
        typeImage:false,
        typeName:filterField.video
      }
    }
    case filterActions.TYPE_IMAGE: {
      return {
        ...state,
        typeAll:false,
        typeVideo:false,
        typeImage:true,
        typeName:filterField.image
      }
    }
    case filterActions.CONTENT_UNLOCKED: {
      return {
        ...state,
        contentPay:false,
        contentLocked:false,
        contentUnlocked:!state.contentUnlocked,
        contentName:state.contentUnlocked?filterField.all:filterField.unlocked
      }
    }
    case filterActions.CONTENT_LOCKED: {
      return {
        ...state,
        contentPay:false,
        contentLocked:!state.contentLocked,
        contentUnlocked:false,
        contentName:state.contentLocked?filterField.all:filterField.locked
      }
    }
    case filterActions.CONTENT_PAY: {
      return {
        ...state,
        contentPay:!state.contentPay,
        contentLocked:false,
        contentUnlocked:false,
        contentName:state.contentPay? filterField.all : filterField.payPerView
      }
    }
  }
  throw Error('Unknown action.');
}
