import React, { Fragment, memo } from "react";
import {Box} from "@mui/material";
import {styles} from "./styles";
import {filterActions} from "../reducers";
import {PrimaryButton} from "../../../components/PrimaryButton";
import colors from "../../../assets/theme/base/colors";
import MDTypography from "../../../components/MDTypography";
import {useStores} from "../../../models";
import {useParams} from "react-router-dom";
import {useIsMobile} from "../../../utils/hooks";
import {IdolTypeEnum} from "../../../utils/constants";

const FilterButton = ({selected, text, onClick}) => {
  return (
    <PrimaryButton buttonStyle={styles.filterButton}
                   size={"small"}
                   onClick={onClick}
                   mainColor={selected ? colors.primary : colors.buttons.button3}
                   textStyles={styles.filterButtonText}
                   text={text}/>
  )
}

const NavFilter = memo(({selected, title, onClick, loading}) => {
  return (
    <Box style={selected ? styles.filterBoxSelected : styles.filterBox}
         disabled={loading}
         onClick={onClick}>
      <MDTypography
        style={selected ? styles.filterTextSelected : styles.filterText}>
        {title}
      </MDTypography>
    </Box>
  )
})

const NavFilterRow = ({filters, dispatch, counters, loading, user, profile}) => {
  const rootStore = useStores()
  const {loginStore} = rootStore
  const myProfileId = loginStore.idol_id_name
  const {id, idol_id_name} = useParams();
  const isMyProfile = idol_id_name === myProfileId
  const isMobile = useIsMobile();

  return (
    <Box style={isMobile?{marginTop: "40px", marginBottom: "10px"}:{...styles.filterNav}}>
      <Box style={isMobile?{...styles.rowCenter}:{...styles.rowCenter, height: "100%"}}>

        {profile?.idol_type !== IdolTypeEnum.influencer && (
          <NavFilter
            selected={filters.typeAll}
            onClick={() => dispatch({"type": filterActions.TYPE_ALL})}
            title={`ALL ${counters.all}`}
          />
        )}

        {profile?.idol_type !== IdolTypeEnum.influencer && (
          <NavFilter
            selected={filters.typeVideo}
            onClick={() => dispatch({"type": filterActions.TYPE_VIDEO})}
            title={`VIDEO ${counters.videos}`}
          />
        )}

        {profile?.idol_type !== IdolTypeEnum.influencer && (
          <NavFilter
            selected={filters.typeImage}
            onClick={() => dispatch({"type": filterActions.TYPE_IMAGE})}
            title={`PHOTO ${counters.photos}`}
          />
        )}

      </Box>
      <Box>
        {!isMobile &&
        !isMyProfile &&
        <Box style={{...styles.rowCenter, gap: "13px", marginLeft: "44px", width: "360px"}}>
          {(user && user.id && profile?.idol_type !== IdolTypeEnum.influencer)&& (
            <Fragment>
              <FilterButton
                selected={filters.contentUnlocked}
                onClick={() => dispatch({"type": filterActions.CONTENT_UNLOCKED})}
                text={"UNLOCKED"}
              />

              <FilterButton
                selected={filters.contentLocked}
                onClick={() => dispatch({"type": filterActions.CONTENT_LOCKED})}
                text={"LOCKED"}
              />

              <FilterButton
                selected={filters.contentPay}
                onClick={() => dispatch({"type": filterActions.CONTENT_PAY})}
                text={"PAY PER VIEW"}
              />
            </Fragment>
          )}
        </Box>
        }
      </Box>
    </Box>
  );
};

export default NavFilterRow;
