import { Box, Typography } from "@mui/material";
import colors from "../../../assets/theme/base/colors";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../utils/hooks";
import {
  DATE_FORMAT, DATETIME_FORMAT,
  getDaysFromWeekdays,
  getDisabledDaysBetween,
  splitAvailableTimes, timeZoneTimezoneConverter,
} from "../../../utils/dateUtils";
import GlobeIcon from "../../../assets/icons/mundo.svg";
import { SecondaryButton } from "../../../components/SecondaryButton";
import rgba from "../../../assets/theme/functions/rgba";
import { DayPicker } from "react-day-picker";
import { CustomCaption } from "../../../components/AvailabilityModal";
import {desktopStyles, mobileStyles, datePickerCss} from "./styles/step2Styles"
import { DateRangeOption } from "../../../components/CreateEventModal/constants";

export const BROWSER_TIMEZONE = moment.tz.guess()


export const MeetStep2 = ({isMobile, onSelect, goBack, userId, event, onSelectDay})=>{
  const styles = isMobile? mobileStyles : desktopStyles
  const api = useApi()
  const currentTime = moment().format("hh:mm A")
  const [selected, setSelected] = useState([]);
  const [disabledDays, setDisabledDays] = useState(null);
  const fromDate = moment()
  const [meetings, setMeetings] = useState([])
  const toDate = moment().add("months", 6).endOf("month")
  const [original, setOriginal] = useState({})

  useEffect(() => {
    if(selected){
      const from_time = moment(selected).startOf("day").format(DATETIME_FORMAT)
      const to_time = moment(selected).endOf("day").format(DATETIME_FORMAT)
      getIdolApprovedMeetings({
        start_datetime: from_time,
        end_datetime: to_time
      })
    }

  }, [selected])

  const getIdolApprovedMeetings = (dates)=>{
    api.getUpcomingMeetings({
      ...dates,
      to_user_id: userId,
      is_approved: true,
      all:true
    }).then(response => {
      if(response.kind === "ok"){
        const approvedMeetings = []
        for(const obj of response.data){
          const aux = {
            from_time:moment.tz(obj.start_datetime, BROWSER_TIMEZONE),
            to_time:moment.tz(obj.end_datetime, BROWSER_TIMEZONE).toDate(),
          }
          approvedMeetings.push(aux)
        }
      setMeetings(approvedMeetings)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }




  const getAvailability = () => {
    let data = {
      event_type_id:event.id,
      custom:true,
      user_id: userId,
      from_day:fromDate.format(DATE_FORMAT),
      to_day:toDate.format(DATE_FORMAT),
    }
    if([DateRangeOption.indefinitely,
        DateRangeOption.date_range].indexOf(event.date_range_type.codename ) >= 0){
      // ask for weekly availabilities
      data["weekly"] = true
    }

    api.getUserAvailability(data).then(response => {
      if (response.kind === "ok") {
        setOriginal(response.data)

        let days = response.data.weekly.map((value, index)=> value.ranges.length > 0? index: null)
        days = days.filter(value => value !== null)
        let limit = {fromDate, toDate}
        if(event.date_range_type.codename === DateRangeOption.date_range){
          limit["fromDate"] = moment(event.from_date)
          limit["toDate"] = moment(event.to_date)
        }

        let dates = getDaysFromWeekdays({ weekdays:days, fromDate:limit.fromDate, toDate:limit.toDate })
        const customDays = response.data.custom_days

        for (const obj of customDays){
          if(dates.indexOf(obj) === -1){
            dates.push(moment(obj).toDate())
          }
        }
        setDisabledDays(getDisabledDaysBetween({fromDate, toDate, dates}))

      }
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    getAvailability()
  }, [])

  const getTimesFromSelected = ()=>{
    const current = moment(selected)

    let days = original.weekly.map((value, index)=> {
      return value.ranges.length > 0? {...value, day:index}: null
    })

    days = days.filter(value => value !== null)
    let timeRanges = []
    for (const day of days){
      if(current.weekday() === day.day){
        timeRanges = timeRanges.concat(day.ranges)
      }
    }
    if (original.custom_days.indexOf(current.format(DATE_FORMAT)) >= 0){
      timeRanges = timeRanges.concat(original.custom_ranges)
    }

    const times = splitAvailableTimes({
      timeRanges,
      event_duration:event.duration,
      event_date: selected,
      event_step:event.time_between_events,
      approvedMeetings:meetings
    })
    onSelect(times)
  }


  return (
    <Box style={styles.step2Main}>
      <Typography variant={"h1"} style={styles.title}>
        2. SELECT DATE
      </Typography>

      <Box style={styles.listContainer}>
        <Box style={styles.calendarContainer}>
          <style>{datePickerCss}</style>
          {disabledDays && (
            <DayPicker
              mode="single"
              components={{
                Caption: CustomCaption
              }}
              selected={selected}
              fromMonth={new Date()}
              toDate={toDate.toDate()}
              disabled={disabledDays}

              footer={""}
              onSelect={(data)=>{
                onSelectDay(moment(data).format('YYYY-MM-DD'))
                setSelected(data)
              }}
              disableNavigation={false}
            />
          )}

          <Box style={styles.timeZoneContainer}>
            <Box component={"img"} src={GlobeIcon} style={{width:"13px", height:"13px", marginRight:"8px"}} />
            <Typography variant={"h3"} style={styles.timeZone}>
              {BROWSER_TIMEZONE}
            </Typography>
            <Typography style={{...styles.timeZone,fontWeight:"400", marginLeft:"8px"}}>{currentTime}</Typography>
          </Box>
        </Box>


      </Box>
      <Box style={styles.buttonsContainer}>
        <SecondaryButton text={"CONTINUE"}
                         disabled={!selected}
                         onClick={()=>getTimesFromSelected()}
                         textStyles={styles.buttonText}
                         buttonStyle={styles.buttonContinue} />
        <SecondaryButton text={"CANCEL"}
                         onClick={goBack}
                         textStyles={styles.buttonTextCancel}
                         buttonStyle={styles.buttonCancel} />
      </Box>

    </Box>

  )
}
