import { memo, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import UserAvatar from "assets/images/idol7.jpg";
import ImageIcon from "assets/icons/image.svg";
import VideoIcon from "assets/icons/video.svg";
import PostImage from "assets/images/idol1.jpg";
import { useNavigate } from "react-router-dom";
import { BaseCardIdolInfo } from "../../../../base/Cards/InfoCards/IdolizerInfoCard";
import { PostCategoryEnum } from "../../../../constants";
import { UnauthenticatedPaths } from "../../../../paths/paths";
import colors from "../../../../assets/theme/base/colors";
import rgba from "../../../../assets/theme/functions/rgba";


export const SearchMediaContainer = memo(({post, onClick})=>{
  const navigate = useNavigate()

  const contentProps = useMemo(()=>{
    let props = { src:post.file, component:"video"}
    if( post.post_category.codename === PostCategoryEnum.video){
      props = { src:post.file, component:"video"}
    }else{
      props = { src:post.image, component:"img"}
      // props = { src:UserAvatar, component:"img"}
    }
    return props
  },[post])

  const goToProfile = ()=>{
    navigate(UnauthenticatedPaths.viewIdolProfile.replace(":idol_id_name", post.user.idol_id_name),
      {
        state:{
          fromURL:UnauthenticatedPaths.explore
        }
      })
  }

  return (
    <Box style={styles.container} onClick={onClick}>
      <Box style={styles.header}>
        <Box style={styles.counter}>
          <Typography style={styles.counterText}>{post.user.images}</Typography>
          <Box component={"img"} src={ImageIcon}  />
        </Box>
        <Box style={styles.counter}>
          <Typography style={styles.counterText}>{post.user.videos}</Typography>
          <Box component={"img"} src={VideoIcon}  styles={styles.icon}/>
        </Box>
      </Box>


      <Box onClick={onClick} {...contentProps} style={styles.img} />

      <Box style={styles.footer}>
        <BaseCardIdolInfo idolPoints={post.user.idol_points}
                          pointContainerStyles={post.pointContainer}
                          verifiedIconStyle={styles.verifiedIcon}
                          avatarStyles={styles.avatar}
                          onClick={goToProfile}
                          avatarContainerStyle={styles.avatarContainer}
                          infoCardStyles={styles.infoCard}
                          avatar={post.user.image}
                          idolizerName={post.user.username}
                          nameStyles={styles.name}
                          pointStyles={{fontSize:12}}
                          idpStyles={styles.idolPoints}
                          isVerified={post.user.is_validated} />
      </Box>
    </Box>
  )
})

SearchMediaContainer.defaultProps = {
  post:{
    content: PostImage,
    user: {
      id:7,
      is_validated:true,
      idol_points:7500,
      videos: 124,
      images: 439,
      image: UserAvatar,
      username:"user@user.com"
    }
  }
}


const styles = {
  avatarContainer :{
    marginRight: "-14px",
    marginTop:"-4px",
    marginLeft:"-5px"
  },
  verifiedIcon:{
    position: 'absolute',
    right: 0,
    top: 15,
    width: '40%',
  },
  infoCard:{
    paddingLeft:"12px",
    width:"100%",
    paddingBottom:"0px",
    marginTop:"-16px",
    cursor:"pointer"
  },
  pointContainer:{
    fontSize:12,
    fontWeight:500,
    marginTop:"-10px"
  },
  idolPoints:{
    fontSize:10,
    fontWeight:500,
  },
  name:{
    fontSize:12,
    fontWeight:500,
    marginBottom:"-5px"
  },
  avatar:{
    width:"26px",
    height:"26px",
    marginRight:"4px",
  },
  icon:{
    width:"8px",
    height:"8px"
  },
  counterText:{
    marginTop:"2.3px",
    color: colors.white.main,
    fontSize:12,
    fontWeight:600,
    letterSpacing:"0.1em"
  },
  counter:{
    display:"flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems:"center",
    gap:"3px",
    paddingRight:"9px",
    paddingLeft:"9px",
    minWidth:46,
    height:20,
    borderRadius:5,
    background: rgba(colors.background.default, 0.5)
  },
  footer:{
    position:"absolute",
    bottom:10,
    left:0,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    zIndex:2
  },
  header:{
    gap:5,
    position:"absolute",
    top:0,
    left:0,
    padding:"5px 5px 5px 12px ",
    width:"100%",
    height:"48px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-end",
    zIndex:2
  },
  img:{
    zIndex: 1,
    // width:"200px",
    // height:"292px",
    objectFit:"cover",
    cursor:"pointer",
    width:"100%",
    aspectRatio: 9/16
  },
  container:{
    position:"relative",
    width:"100%",
    // height:"292px",
    aspectRatio: 9/16
  }
}
