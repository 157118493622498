import { Box, Card, Typography } from "@mui/material";
import React from "react";
import colors from "../../../../assets/theme/base/colors";


export const CardForm = ({title, children})=>{
  return (

    <Card sx={styles.container}>
      <Box sx={styles.titleBox}>
        <Typography variant="h3" sx={styles.title}>
          {title}
        </Typography>
      </Box>
      <Box sx={styles.childrenBox }>
        {children}
      </Box>
    </Card>
  )
}

const styles = {
  container:{
    display: 'flex',
    flexDirection:"column",
    width: '100%',
    mt:3
  },
  titleBox: {
    padding: "13px 0px 13px 16px",
    backgroundColor: colors.card.upcomingButton,
    borderRadius: "10px 10px 0px 0px",
    color: colors.white.main,
    height: "52px",
  },
  childrenBox: {
    padding: "25px",
    backgroundColor: colors.background.default,
    borderRadius: "0px 0px 10px 10px",
    color: colors.white.main,
  },
  title: {
    color: colors.white.main,
    fontWeight: "100",
    fontSize:20
  },
}
