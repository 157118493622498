import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { useApi, useUserStore } from "../../utils/hooks";
import { moneyFormat } from "../../services/helpers";

/* MUI components */
import {ArrowForwardIosRounded} from "@mui/icons-material";

/* components */
import {BasePostCard} from 'base/Cards/PostCard';
import {RecordCard} from 'components/RecordCard/RecordCard';
import {BaseCardIdolInfo} from 'base/Cards/InfoCards/IdolizerInfoCard';
import {UpcomingMeetCard} from './MeetCard';
import {HeaderControls} from './HeaderControls';
import { CancelMeetingModal } from "../../components/ActionsEventModal/Cancel";

/* assets */
import upcomingIcon from 'assets/icons/upcoming-meets.svg';
import EventsIcon from "assets/icons/events.svg";
import {MeetingTypeEnum} from "utils/constants";
import {notifySuccess, notifyError} from "services/notification";
import {AuthenticatedPaths} from "paths/paths";
import {styles} from './styles';
import MeetingModal from "../MeetingModal/MeetingModal";

export const UpcomingMeetings = ({
  title,
  upcomingMeetings = [],
  onLaunch,
  onDismiss,
}) => {

  const [selectedFilterOrder, setSelectedFilterOrder] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedMeet, setSelectedMeet] = useState(null);
  const api = useApi()

  const upcomingMeetProps = {
    onLaunch,
    onDismiss,
  };

  const headerProps = {
    selectedFilterOrder,
    setSelectedFilterOrder,
  };
  const iconType = {
    Upcoming: upcomingIcon,
    Meetings: EventsIcon,
  }

  const checkIfCanEnter = (meeting, minutes) => {
    const meeting_start_datetime = new Date(meeting.start_datetime).getTime()
    const now = new Date().getTime()
    const initialGapInMiliseconds = minutes * 60 * 1000
    return (now > (meeting_start_datetime - initialGapInMiliseconds))
  }

  const isUpcoming = (meeting, minutes) => {
    return !checkIfCanEnter(meeting, minutes)
  }

  const cancelMeeting = (id) => {
    api.cancelMeeting(id).then(response => {
      if (response.kind === "ok") {
        notifySuccess("Meeting cancelled successfully")
        // getMeetings()
      } else {
        notifyError("Error canceling this meet")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error canceling this meet")
    })
  }

  const launch = (meeting) => {
    if (checkIfCanEnter(meeting, 10)) {
      window.open(`${AuthenticatedPaths.videoCall}?room=${meeting.room}`, '_blank');
    } else {
      notifyError("You cannot enter into this meeting yet")
    }
  }

  return (
    <BasePostCard title={title} icon={iconType[title]} headerControls={title === 'Upcoming' ? <HeaderControls {...headerProps} /> : <></>}>
      {upcomingMeetings.map(meeting => {
        const {to_user, } = meeting;
        const meetingInfo = {
          avatar: to_user.image,
          idolizerName: to_user.username,
          idolPoints: to_user.idol_points,
          isVerified: to_user.is_validated,
          isIdolizer: true,
          // isMobile: false,
        }
        return <RecordCard
          showCard={false}
          alignEndContent={true}
          containerStyles={styles.container}
          contentContainerStyles={styles.content_container}
          onClick={() => {
            setSelectedMeet(meeting);
            setShowDetailModal(true)
          }}
          clickable={true}
          cardFirstContent={[
            <ArrowForwardIosRounded color={'white'} size={30}/>,
            <BaseCardIdolInfo {...meetingInfo} />,
          ]}
          cardSecondContent={( 
                  <UpcomingMeetCard
                      title={meeting.name}
                      date={moment(meeting.start_datetime).format("D MMM YYYY").toUpperCase()}
                      timeStart={moment(meeting.start_datetime).format('hh:mm A')}
                      timeEnd={moment(meeting.end_datetime).format('hh:mm A')}
                      price={meeting.fee}
                      meetingPoints={2450}
                      eventType={MeetingTypeEnum.liveCall === "LC" ? "idolcall" : "idolmeet"}
                      upcoming={isUpcoming(meeting, 10)}
                      primaryBtnSize={"small"}
                      secondaryBtnSize={"small"}
                      onLaunch={() => launch(meeting)}
                      onDismiss={() => {
                        setSelectedMeet(meeting);
                        setShowCancelModal(true);
                      }}/>
                  )}
       />
      })}
      {selectedMeet && (
        <CancelMeetingModal
          open={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onCancel={() => cancelMeeting(selectedMeet.id)}
          avatar={selectedMeet.to_user.image}
          idolizerName={selectedMeet.from_user.username}
          idolPoints={selectedMeet.from_user.idol_points}
          isVerified={selectedMeet.from_user.is_validated}
          date={selectedMeet.start_datetime}
          timeStart={selectedMeet.start_datetime}
          timeEnd={selectedMeet.end_datetime}
          price={moneyFormat(selectedMeet.fee)}
        />
      )}
      {showDetailModal && (
        <MeetingModal
          open={showDetailModal}
          onClose={() => setShowDetailModal(false)}
          idolAvatar={selectedMeet.to_user.image}
          idolName={selectedMeet.to_user.username}
          idolPoints={selectedMeet.to_user.idol_points}
          title={`${selectedMeet.to_user.username} - ${selectedMeet.name}`}
          meetingPlace={selectedMeet?.location}
          meetingAmount={selectedMeet.fee}
          meetingDate={moment(selectedMeet.start_datetime).format("D MMM YYYY").toUpperCase()}
          meetingHourStart={moment(selectedMeet.start_datetime).format('hh:mm A')}
          meetingHourEnd={moment(selectedMeet.end_datetime).format('hh:mm A')}
        />
      )}
    </BasePostCard>
  );
}


UpcomingMeetings.propTypes = {
  upcomingMeetings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    idolizerName: PropTypes.string.isRequired,
    idolPoints: PropTypes.number.isRequired,
    isVerified: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    timeStart: PropTypes.string.isRequired,
    timeEnd: PropTypes.string.isRequired,
    upcoming: PropTypes.string.isRequired,
    eventType: PropTypes.oneOf(['idocall', 'idolmeet']).isRequired,
    meetingPoints: PropTypes.number.isRequired,
  })).isRequired,
  onLaunch: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
}
