import React, {useEffect, useRef, useState} from "react";
import CalendarIcon from "assets/icons/calendar1.svg";
import NextIcon from "assets/icons/next_red.svg";
import PreviuosIcon from "assets/icons/previous_red.svg";
import {BasePostCard} from "../../../base/Cards/PostCard";
import {Box, Button, Grid} from "@mui/material";
import {PrimaryButton} from "../../../components/PrimaryButton";
import {styles} from "./styles";
import AvailabilityGrid from "./availability";
import colors from "../../../assets/theme/base/colors";
import Calendar from "./calendar";
import moment from "moment";
import {useApi} from "../../../utils/hooks";
import {next} from "stylis";
import { timeToLocal } from "../../../utils/dateUtils";
import {MobileSectionHeader} from "../../../components/Mobile/MobileIdolDashboardSectionHeader";
import AvailabilityModal from "../../../components/AvailabilityModal";

const HeaderControls = ({title, onClickNext, onClickPrevious})=>{
  return (
    <Box style={styles.headerControls.container}>
      <PrimaryButton
        buttonStyle={styles.headerControls.primaryButton}
        text={title}
        textStyle={{fontSize:"14px"}}
        onClick={()=>console.log("asdf")}
      />

      <Box style={styles.headerControls.navButtons}>
        <Button onClick={onClickPrevious}>
          <img src={PreviuosIcon}/>
        </Button>
        <Button onClick={onClickNext}>
          <img src={NextIcon}/>
        </Button>
      </Box>
    </Box>
  )
}

const CalendarContainer = ({isMobile}) => {
  const api = useApi()

  const calendarRef = useRef(null);
  const [CalendarEvents, setCalendarEvents] = useState([])
  const [Title, setTitle] = useState(moment().format("MMMM y"))
  const [modalState, setModalState] = useState(false)

  const getEvents = (data)=>{

    api.getUserCalendarAvailabilities(data).then(response => {
      if(response.kind === "ok"){
        const data = response.data
        for (let i = 0; i < data.length; i++) {
          data[i].from_time = timeToLocal(data[i].from_time)
          data[i].to_time = timeToLocal(data[i].to_time)
          data[i]["title"] = `${data[i].from_time} - ${data[i].to_time}`
        }
        setCalendarEvents(data)
      }
    }).catch(reason => console.log(reason))
  }

  useEffect(() => {
    let start = moment().startOf('month')
    let end = moment().endOf('month')
    getEvents({
      from_time: start.format('yyyy-MM-DD'),
      to_time: end.format('yyyy-MM-DD'),
    })
  }, [])

  const onClickNext = () => {
    console.log(" Next", calendarRef.current)
    calendarRef.current.goToNext()
  }

  const onClickPrevious = () => {
    console.log(" onClickPrevious")
    calendarRef.current.goToPrevious()

  }


  return (
    <Box>
      {isMobile ?
        <Box>
          <AvailabilityGrid
            isMobile={isMobile}
            getEvents={(data) => {
              console.log("calendar container", data)
              getEvents(data)
            }}
            modalState={modalState}
          />
        </Box>
        :
        <Box>
          <BasePostCard
            title='Calendar'
            icon={CalendarIcon}
            headerControls={<HeaderControls onClickNext={onClickNext}
                                            title={Title}
                                            onClickPrevious={onClickPrevious}/>}>
            <Grid container spacing={3} mt={1}>
              <Grid item lg={4.5} xs={12}>
                <AvailabilityGrid
                  getEvents={(data) => {
                    console.log("calendar container", data)
                    getEvents(data)
                  }}
                />
              </Grid>
              <Grid item xs={7.5}>
                <Calendar
                  getEvents={getEvents}
                  events={CalendarEvents}
                  setTitle={setTitle}
                  ref={calendarRef}/>

              </Grid>

            </Grid>
          </BasePostCard>
        </Box>
      }
    </Box>
  );
};

CalendarContainer.propTypes = {};

export default CalendarContainer;
