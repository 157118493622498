import Dashboard from "../../../../assets/icons/home-icon.svg";
import DashboardRed from "../../../../assets/icons/home_red.svg";
import {IdolPaths} from "../../../../paths/idolPaths";
import Wallet from "../../../../assets/icons/wallet-idol-bank.svg";
import WalletRed from "../../../../assets/icons/wallet-idol-bank-red.svg";
import Dollar from "../../../../assets/icons/dollar_white.svg";
import DollarRed from "../../../../assets/icons/dollar_rounded-red.svg";
import Meeting from "../../../../assets/icons/video-chat-tabs.svg";
import MeetingRed from "../../../../assets/icons/video-chat-tabs-red.svg";
import Calendar from "../../../../assets/icons/calendar1.svg";
import CalendarRed from "../../../../assets/icons/calendar1-red.svg";
import PriceTag from "../../../../assets/icons/price.svg";
import PriceTagRed from "../../../../assets/icons/price-red.svg";
import BesoDe3 from "../../../../assets/icons/people.svg";
import BesoDe3Red from "../../../../assets/icons/people-red.svg";
import IdolPayIcon from "../../../../assets/icons/idol-pay.svg";
import IdolPayIconRed from "../../../../assets/icons/idol-pay-red.svg";
import {IdolizerPaths} from "../../../../paths/idolizerPaths";
import CheckCircle from "../../../../assets/icons/CheckCircle.svg"
import CheckCircleRed from "../../../../assets/icons/CheckCircleRed.svg"


const idolizerToolbarRoutes = [
  {
    icon: Dashboard,
    iconRed: DashboardRed,
    path: "/",
    key: "dashboard",
    routeTitle: "DASHBOARD",
    routePath: IdolizerPaths.home,
  },
  {
    icon: Wallet,
    iconRed: WalletRed,
    path: "/",
    key: "wallet",
    routeTitle: "WALLET",
    routePath: IdolizerPaths.wallet,
  },
  {
    icon: IdolPayIcon,
    iconRed: IdolPayIconRed,
    path: "/",
    key: "transactions",
    routeTitle: "TRANSACTIONS",
    routePath: IdolizerPaths.transactions,
  },
  {
    icon: Calendar,
    iconRed: CalendarRed,
    path: "/",
    key: "meeting",
    routeTitle: "MEETINGS",
    routePath: IdolizerPaths.meetings,
  },
  {
    icon: BesoDe3,
    iconRed: BesoDe3Red,
    path: "/",
    key: "beso",
    routeTitle: "IDOLS",
    routePath: IdolizerPaths.idols,
  },
  {
    icon: CheckCircle,
    iconRed: CheckCircleRed,
    path: "/",
    key: "get-verified",
    routeTitle: "IDOLIZERS",
    routePath: IdolPaths.getVerified,
  },
]
const idolInfluencerToolbarRoutes = [
  {
    icon: Dashboard,
    iconRed: DashboardRed,
    path: "/",
    key: "dashboard",
    routeTitle: "DASHBOARD",
    routePath: IdolPaths.home,
  },
  {
    icon: Wallet,
    iconRed: WalletRed,
    path: "/",
    key: "wallet",
    routeTitle: "WALLET",
    routePath: IdolPaths.wallet,
  },
  {
    icon: IdolPayIcon,
    iconRed: IdolPayIconRed,
    path: "/",
    key: "transactions",
    routeTitle: "TRANSACTIONS",
    routePath: IdolPaths.transactions,
  },
  {
    icon: Calendar,
    iconRed: CalendarRed,
    path: "/",
    key: "meeting",
    routeTitle: "MEETINGS",
    routePath: IdolPaths.meetings,
  },
  {
    icon: BesoDe3,
    iconRed: BesoDe3Red,
    path: "/",
    key: "beso",
    routeTitle: "IDOLIZERS",
    routePath: IdolPaths.idolizers,
  },
  {
    icon: CheckCircle,
    iconRed: CheckCircleRed,
    path: "/",
    key: "get-verified",
    routeTitle: "IDOLIZERS",
    routePath: IdolPaths.getVerified,
  },
]
const idolToolbarRoutes = [
  {
    icon: Dashboard,
    iconRed: DashboardRed,
    path: "/",
    key: "dashboard",
    routeTitle: "DASHBOARD",
    routePath: IdolPaths.home,
  },
  {
    icon: Wallet,
    iconRed: WalletRed,
    path: "/",
    key: "idolbank",
    routeTitle: "IDOL BANK",
    routePath: IdolPaths.idolBank,
  },
  {
    icon: Dollar,
    iconRed: DollarRed,
    path: "/",
    key: "dollar",
    routeTitle: "IDOL PAY",
    routePath: IdolPaths.idolPay,
  },
  {
    icon: Meeting,
    iconRed: MeetingRed,
    path: "/",
    key: "meeting",
    routeTitle: "EVENTS",
    routePath: IdolPaths.events,
  },
  {
    icon: Calendar,
    iconRed: CalendarRed,
    path: "/",
    key: "calendar",
    routeTitle: "CALENDAR",
    routePath: IdolPaths.calendar,
  },
  {
    icon: PriceTag,
    iconRed: PriceTagRed,
    path: "/",
    key: "price",
    routeTitle: "PRICES",
    routePath: IdolPaths.prices,
  },
  {
    icon: BesoDe3,
    iconRed: BesoDe3Red,
    path: "/",
    key: "beso",
    routeTitle: "IDOLIZERS",
    routePath: IdolPaths.idolizers,
  },
  {
    icon: CheckCircle,
    iconRed: CheckCircleRed,
    path: "/",
    key: "get-verified",
    routeTitle: "IDOLIZERS",
    routePath: IdolPaths.getVerified,
  },
]

export { idolToolbarRoutes, idolInfluencerToolbarRoutes, idolizerToolbarRoutes }
