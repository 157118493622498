import React, { memo, useEffect, useState } from "react";
import { BasePostCard } from "../../../base/Cards/PostCard";
import EventsIcon from "assets/icons/events.svg";
import { Box } from "@mui/material";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { useApi, useUserStore } from "../../../utils/hooks";
import Grid from "@mui/material/Grid";
import { EventCard } from "../../../components/EventCard";
import colors from "assets/theme/base/colors";
import { SecondaryButton } from "../../../components/SecondaryButton";
import { MeetingTypeEnum } from "../../../utils/constants";
import moment from "moment";
import { baseInfo, citiesOptions, dateRangeOptions, durationOptions } from "../../home/dummyData";
import { IdolMeetingCard } from "../../../components/IdolMeetingCard";
import { CancelMeetingModal } from "../../../components/ActionsEventModal/Cancel";
import { MeetingLocationDetailsModal } from "../../../components/ActionsEventModal/LocationDetails";
import { CreateEventModal } from "../../../components/CreateEventModal";
import { notifyError, notifySuccess } from "../../../services/notification";
import { SimpleConfirmModal } from "../../../components/SimpleConfirmModal";
import {humanizeDuration} from "../../../utils/dateUtils";

const {card} = colors;


export const EventsCard = memo((props) => {
  const [Upcoming, setUpcoming] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [events, setEvents] = useState([])
  const api = useApi()
  const user = useUserStore()
  console.log("user", user.idol_type)
  const [modalManager, setModalManager] = useState({
    base: false,
    createEvent: false,
    cancelMeeting: false,
    locationDetails: false,
  });
  const {base, createEvent, cancelMeeting, locationDetails} = modalManager;


  const getEvents  = ()=>{ // TODO until now, returns void data
    api.getEventTypes(1).then(response => {
      if (response.kind === "ok") {
        setEvents(response.data)
      }
    }).catch(reason => {
      console.log(reason)
    })
  }

  const cancelEventType = (id)=>{
    api.cancelEventType(id).then(response => {
      if (response.kind === "ok") {
        getEvents()
        setModalManager({...modalManager, cancelMeeting: false})
        notifySuccess("Event canceled successfully")
      }else{
        notifyError("An error occurred while canceling the event")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("An error occurred while canceling the event")
    })
  }

  useEffect(() => {
    getEvents()
  },[])

  return (

    <BasePostCard
      title='Events'
      icon={EventsIcon}
      headerControlsStyle={{maxHeight:"100px"}}
      headerControls={(
        <Box sx={styles.headerControls}>
          <SecondaryButton text='CREATE EVENT'
                           onClick={()=>{
                             setModalManager({...modalManager, createEvent: true})
                           }}
                           buttonStyle={{...styles.button,borderBottom:"none"}} />
          <PrimaryButton text='ACTIVE EVENTS' buttonStyle={styles.button} />
        </Box>
      )}>



      <CreateEventModal
        open={createEvent}
        idolType={user.idol_type}
        onClose={() => setModalManager({...modalManager, createEvent: false})}
        durationOptions={durationOptions}
        dateRangeOptions={dateRangeOptions}
        onSave={()=>{
          setModalManager({...modalManager, createEvent: false})
          getEvents()
        }}
        citiesOptions={citiesOptions}
      />


      <MeetingLocationDetailsModal
        open={locationDetails}
        onClose={() => setModalManager({...modalManager, locationDetails: false})}
        onSave={(values) => {{
          notifySuccess("Locatation saved successfully")
          console.log('values submitted ', values)
        }}}
        {...selectedEvent}
        meetingPlace={`${selectedEvent?.city_name}, ${selectedEvent?.geo_name_code}`}
      />
      {selectedEvent && (
        <SimpleConfirmModal
          open={cancelMeeting}
          title={"Cancel Event"}
          message={"Are you sure that you want to cancel this Event"}
          onClose={() => setModalManager({...modalManager, cancelMeeting: false})}
          onConfirm={() =>cancelEventType(selectedEvent.id)}
        />
      )}


      <Box sx={styles.eventsBox}>
        <Grid container spacing={6}>
          {events && events.map((event, index)=> {
            return (
              <Grid item md={6} lg={6} sm={6} key={"Grid-"+index}>
                <EventCard
                  title={event.name}
                  eventDuration={humanizeDuration(event.duration)}
                  eventType={event.meeting_type === MeetingTypeEnum.liveCall?"idolcall":"idolmeet"}
                  eventDescription={event.description}
                  eventPrice={event.fee}
                  onEdit={() => {
                    setSelectedEvent(event)
                    setModalManager({...modalManager, locationDetails: true})
                  }}
                  onDelete={() => {
                    setSelectedEvent(event)
                    setModalManager({...modalManager, cancelMeeting: true})
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </BasePostCard>

  );
})


export const styles = {
  eventsBox:{
    width:"100%",
    height:"385px",
    marginTop:"30px",
    overflowY:"scroll"
  },
  headerControls:{
    display:"flex",
    flexDirection:"row",
    width:"500px",
    gap:"15px",
    alignItems:"flex-end !important",
    justifyContent:"flex-end !important",
    height:"75px !important",
    marginBottom:"-15px",
    marginRight:"50px"
    // margin:"0px"
  },

  button: {
    height:"75px !important",
    borderRadius:"10px 10px 0px 0px"
  },

};
