import { SpecificPostContentMobile } from "../../../../components/PostsGrill/SpecificPostContent";
import { PostCategoryEnum } from "../../../../constants";
import { moneyFormat } from "../../../../services/helpers";
import { ConfirmPaymentModal } from "../../../../components/ConfirmPaymentModal";
import React, { useState } from "react";
import { notifyError, notifySuccess } from "../../../../services/notification";
import { useApi } from "../../../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { AuthenticatedPaths } from "../../../../paths/paths";
import { Stack } from "@mui/material";
import { SecondaryButton } from "../../../../components/SecondaryButton";
import { GenericModal } from "../../../../components/GenericModal";
import { GuestPaths } from "../../../../paths/guestPaths";
import { NeedLoginModal } from "../../../../components/NeedLoginModal";

export const ExploreMediaContainer = ({post, user})=>{
  const api = useApi()
  const [showPayConfirmModal, setShowPayConfirmModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)

  const navigate = useNavigate()
  let data = {...post, tipPrice: post.additional_price,  userType: "idol"}


  if( post.post_category.codename === PostCategoryEnum.video){
    data["idolPost"] = post.file
    data["isVideo"] = true
  }else{
    data["idolPost"] = post.image
  }

  const confirmTipPayment = ()=>{
    api.sendTip(post.id).then(response => {
      if(response.kind === "ok"){
        notifySuccess("Tip sent successfully")
        setShowPayConfirmModal(false)
      }else{
        notifyError("Error sending Tip")
      }
    }).catch(reason => {
      console.log(reason)
      notifyError("Error sending Tip")
    })
  }

  const goToMeetMe = () => {
    if(user.id){
      navigate(AuthenticatedPaths.meetMe.replace(":idol_id_name", post.user.idol_id_name))
    }else{
      setShowLoginModal(true)
    }
  }

  return (
    <React.Fragment>
      {showLoginModal && (<NeedLoginModal open={showLoginModal} onClose={()=> setShowLoginModal(false)} />)}
      <ConfirmPaymentModal
        open={showPayConfirmModal}
        confirmAction={confirmTipPayment}
        onClose={()=> {
          setShowPayConfirmModal(false)
        }}
        message={`Are you sure you want to tip this user for ${moneyFormat(post?.tip_price??0)}?`}
      />

      <SpecificPostContentMobile
        content={data}
        user={user}
        unlockPost={()=> console.log("onlock post")}
        goToMeet={goToMeetMe}
        handleClose={()=>{}}
        confirmPayment={()=> {
          if (user.id){
            setShowPayConfirmModal(true);
          }else{
            setShowLoginModal(true)
          }
        }}
      />
    </React.Fragment>

  )
}
