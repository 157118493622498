import React, {useCallback, useRef} from 'react';

import Button from '@material-ui/core/Button';
// @ts-ignore
import VideoOffIcon from '../../images/videocall_video_disable.png';
// @ts-ignore
import VideoOnIcon from '../../images/videocall_video_enable.png';
// @ts-ignore
import VideoDisabledIcon from '../../images/videocall_video_no.png';

import useDevices from '../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: 'transparent',
      color: 'transparent',
      '&:hover': {
        filter: 'brightness(110%)',
        background: 'transparent',
      },
    },
  })
);

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={clsx(classes.button, props.className)}
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      {!hasVideoInputDevices ? <img alt='No Video' src={VideoDisabledIcon} style={{height: '48px'}}/> :
        isVideoEnabled ?
          <img alt='Stop Video' src={VideoOnIcon} style={{height: '48px'}}/> :
          <img alt='Start Video' src={VideoOffIcon} style={{height: '48px'}}/>
      }
    </Button>
  );
}
