import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import colors from "../../assets/theme/base/colors";
import {nativeSelectStyle, SelectInput, SelectInputMobile} from "./styles";
import MDTypography from "../MDTypography";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useIsMobile} from 'utils/hooks';
import { FormHelperText } from "@mui/material";


export default function CustomSelect(props, isMobile, selectStyle) {
  const isMobileHandler = useIsMobile();
  const {options, error, label, placeholder, helperText, onChange, errorTextStyles, selectStyles, fullWidth = true} = props
  return (
    <FormControl variant="standard" fullWidth={fullWidth} >
      <MDTypography style={{...styles.label, fontSize: isMobileHandler ? pxToRem(12) : pxToRem(16), marginBottom: '0.0625rem'}}>{label}</MDTypography>
      <NativeSelect
        onChange={onChange}
        sx={[nativeSelectStyle(), selectStyles]}
        {...props}
        input={isMobile?<SelectInputMobile/>:<SelectInput/>}>
        <option style={styles.placeholder} disabled={true}>{placeholder}</option>
        {options && options.map((option, index) =>
          <option
            key={`${index}-${option.value}`}
            style={styles.option}
            value={option.value}
            disabled={option.disabled ?? false}
          >
            {option.label}
          </option>
        )}
      </NativeSelect>

      {error && (
        <FormHelperText>
          <MDTypography
            sx={[{fontSize: !isMobile ? pxToRem(14) : pxToRem(12), color: colors.error.main}, errorTextStyles]}
            variant='span'>{helperText}</MDTypography>
        </FormHelperText>
      )}
    </FormControl>
  );
}

const styles = {
  option: {
    background: colors.inputBackgroundColor,
    zoom: "1.2",
    padding: "4px"
  },
  label: {
    color: colors.white.main,
    borderRadius: 0
  },
  placeholder: {
    background: colors.inputBackgroundColor,
    zoom: "1.1",
  },
}
