import { useStores } from "../models/root-store/root-store-context";
import { useEffect, useState } from "react";
import Toast from "../components/Toast";
import moment from "moment";
import numeral from "numeral";
import MDBox from "../components/MDBox";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {notifyError} from "./notification";

export const getFirstError = (errors) => {
  if (Array.isArray(errors)) {
    return getFirstError([...errors].pop())
  } else {
    return errors
  }
}

export const setFormikErrors = (formik, errors) => {
  Object.entries(errors).forEach(([field, errors]) => formik.setFieldError(field, getFirstError(errors)))
}

export const showAllErrors = (errors) => {
  if (typeof errors === 'string') {
    notifyError(errors)
  } else {
    Object.values(errors).forEach((errorList) => notifyError(`${getFirstError(errorList)}`))
  }
}

export const showMessage = (error = 'An error occurred, please try again later', type = 'error', checkError = false) => {
  let msg = error
  if (checkError) {
    const key = Object.keys(error)[0]
    msg = `${key}: ${error?.[key][0]}`
  }
  Toast.fire({
    icon: type,
    title: msg
  })
}

export const APISuccessMessage = () => {
  Toast.fire({
    icon: 'success',
    title: 'Operation successful.'
  })
}

export const APIErrorMessage = () => {
  Toast.fire({
    icon: 'error',
    title: 'There is something wrong.'
  })
}

export const moneyFormat = (amount) => {
  return numeral(amount).format('$0,0.00')
}

export const checkMoneyFormat = (amount = "") => {
  const re = /^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/;
  return amount.match(re);
}

export const decimalToFeetInch = (value)=>{
  var inches = (value * 0.393700787).toFixed(0);
  var feet = Math.floor(inches / 12);
  inches %= 12;
  return `${feet}'${inches}"`
}

export const fecha_fmt = (fecha, formato_opcional) => {
  const mm = moment(fecha);
  return mm.format(formato_opcional ? formato_opcional : 'DD/MM/YYYY - HH:mm')
}


export const renderBooleanIcon = (obj) => {
  if (obj) {
    return (
      <MDBox circular={'true'} variant="gradient" color="success" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CheckCircle fontSize={'small'}/>
      </MDBox>
    )
  } else {
    return (
      <MDBox circular={'true'}  variant="gradient" color="error" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CancelIcon fontSize={'small'}/>
      </MDBox>
    )
  }
}
