import {Avatar, Box} from "@mui/material";
import {avatarBoxStyles, avatarStyles, boxStyles, labelMobileStyles, labelStyles} from "./styles";
import AvatarImage from "../../assets/icons/Uploadprofilephoto.svg"
import MDTypography from "../MDTypography";
import {useRef} from "react";
import pxToRem from "../../assets/theme/functions/pxToRem";
import {useIsMobile} from "../../utils/hooks";

const UploadProfilePicture = ({
                                onClick,
                                src,
                                alt,
                                avatarSize = 100,
                                labelStyle = {},
                                padding = 12
                              }) => {
  const inputRef = useRef(`pp_input_${Math.random().toString().substring(2, 15)}`)
  const isMobile = useIsMobile();
  const handlePhotoUpload = (event) => {
    if (event.target.files && event.target.files.length) {
      onClick(event.target.files[0]);
    }
  }

  const avatarWithNoPicture = <Box sx={(theme) => avatarBoxStyles(theme, pxToRem(avatarSize))}><img src={AvatarImage} alt=""/></Box>

  return <>
    <input type="file" accept="image/*" id={inputRef.current} style={{display: "none"}} onChange={handlePhotoUpload}/>
    <label htmlFor={inputRef.current} style={labelStyle}>
      <Box padding={pxToRem(padding)} sx={boxStyles}>
        {src ?
          <Avatar alt={alt} src={src} sx={(theme) => avatarStyles(theme, pxToRem(avatarSize))}/> :
          avatarWithNoPicture
        }
        <MDTypography color={"text"} sx={isMobile ? labelMobileStyles : labelStyles}>Upload Photo</MDTypography>
      </Box>
    </label>
  </>
}

export default UploadProfilePicture;
