import rgba from "../../../../assets/theme/functions/rgba";
import colors from "../../../../assets/theme/base/colors";


export const desktopStyles = {
  timeText:{
    fontSize:20,
    fontWeight:300,
    color:"white"
  },
  timesContainer:{
    width:"200px",
    height:"90px",
    cursor:"pointer",
    margin:"20px",
    borderRadius:"13px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  mainContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center"
  },
  buttonTextCancel:{
    color: rgba(colors.white.main, 0.25),
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700,
    "&:hover": {
      color: rgba(colors.white.main, 0.25),
    },
  },
  buttonContinue:{
    width:"290px",
    height:"40px",
  },
  timesGrid:{
    // width:"500px",
    display:"flex",
    justifyContent:"center"
  },
  buttonCancel:{
    width:"145px",
    height:"40px",
    border:`2px solid ${rgba(colors.white.main, 0.25)}`,
    "&:hover": {
      border:`2px solid  ${rgba(colors.white.main, 0.25)}`,
    },
  },
  buttonText:{
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700
  },
  buttonsContainer:{
    marginTop:"70px",
    width:"100%",
    gap:"20px",
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignContent:"center",
  },
  timeZoneContainer:{
    display:"flex",
    flexDirection:"row",
    width:"420px",
    alignItems:"center",
    marginLeft:"90px",
    justifyContent:"flex-start",
    marginBottom:"20px"
  },
  calendarContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    width:"420px",
    background: colors.darkBlue["700"],
    borderRadius: "16px",
    marginTop: "24px"
  },
  timeZone:{
    fontSize:14,
    fontWeight:300,
    color:"white"
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  listContainer:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignContent:"center",
    marginTop:"34px",
    // paddingLeft:"20%",
    // paddingRight:"20%",
    // gap:"30px"
  },
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"20px",
    fontSize:20,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1"
  }
}

export const mobileStyles = {

  timeText:{
    fontSize:16,
    fontWeight:300,
    color:"white",
    letterSpacing:"0.12px"
  },
  timesContainer:{
    width:"153px",
    height:"68px",
    cursor:"pointer",
    margin:"10px",
    borderRadius:"13px",
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  mainContainer:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    padding:"16px"
  },
  buttonTextCancel:{
    color: rgba(colors.white.main, 0.25),
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700,
    "&:hover": {
      color: rgba(colors.white.main, 0.25),
    },
  },
  buttonContinue:{
    width:"290px",
    height:"40px",
  },
  timesGrid:{
    width:"100vw",
    display:"flex",
    justifyContent:"center"
  },
  buttonCancel:{
    width:"145px",
    height:"40px",
    border:`2px solid ${rgba(colors.white.main, 0.25)}`,
    "&:hover": {
      border:`2px solid  ${rgba(colors.white.main, 0.25)}`,
    },
  },
  buttonText:{
    fontSize:14,
    letterSpacing:"0.1rem",
    fontWeight:700
  },
  buttonsContainer:{
    marginBottom:"50px",
    marginTop:"70px",
    width:"100%",
    gap:"20px",
    alignItems:"center",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignContent:"center",
  },
  timeZoneContainer:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    width:"90vw",
    justifyContent:"flex-start",
    marginBottom:"20px"
  },
  calendarContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    background: colors.darkBlue["700"],
    borderRadius: "16px",
    marginTop: "24px"
  },
  timeZone:{
    fontSize:14,
    fontWeight:300,
    color:"white"
  },
  rowCenter:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems:"center"
  },
  listContainer:{

    width:"100%",
    alignContent:"center",
    marginTop:"34px",
    gap:"30px"
  },
  title:{
    width:"100%",
    textAlign:"center",
    marginTop:"20px",
    fontSize:20,
    fontWeight:500,
    color: colors.white.main,
    letterSpacing:"0.1"
  }
}
