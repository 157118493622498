import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from "mobx-react";
import {routes} from "../../../routes";

import {useStores} from "../../../models";
import {UserTypes} from "../../../constants";


const Logout = observer(() => {
  const navigate = useNavigate()
  const rootStore = useStores()

  useEffect(() => {
    rootStore.reset()
    navigate(routes[UserTypes.Guest].login.path)
  }, [])

  return null;
});

export default Logout;