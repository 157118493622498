import {Box, Toolbar} from "@mui/material";
import MDBox from "../../../../components/MDBox";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {setOpenSidenav, useMaterialUIController} from "../../../../context";
import MDTypography from "../../../../components/MDTypography";
import MenuIcon from "assets/icons/menu.svg";
import BellIcon from "assets/icons/bell_with_point.svg";
import {navbarMobileContainer, navbarRow} from "base/Navbars/DashboardNavbar/styles";
import {useStores} from "../../../../models";
import { AuthenticatedPaths } from "../../../../paths/paths";
import {IdolTypeEnum} from "../../../../utils/constants";
import {idolizerToolbarRoutes ,idolInfluencerToolbarRoutes, idolToolbarRoutes} from "./idolizerMainToolbarRoutes";
import {UserTypes} from "../../../../constants";


export const MobileMainToolbar = ({title, icon, IsDashboard}) => {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const {loginStore} = useStores();
  const userType = loginStore.userType ?? UserTypes.Guest
  const isInfluencer = loginStore.idol_type === IdolTypeEnum.influencer

  useEffect(() => {
    handleScroll()
  }, [])

  const dontOpenList = [
    "menu-mobile",
    "drawer-mobile",
  ]

  window.onclick = function (e) {
    const classList = [...e.target.classList]
    if (e.target && dontOpenList.some(forbidClass => classList.includes(forbidClass))) {
      setOpenSidenav(dispatch, false);
    }
  }

  let dashboardRoutes
  if(userType === UserTypes.Idolizer){
    dashboardRoutes = idolizerToolbarRoutes
  }else if(userType === UserTypes.Idol){
    dashboardRoutes = isInfluencer ? idolInfluencerToolbarRoutes : idolToolbarRoutes
  }

  //todo scroll to selected item after rendering page
  const handleScroll = (currentRef) => {
    // console.log('currentRef: ', currentRef);
    // loginStore.setMobileNavScroll(currentRef)
    currentRef?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }

  return (
    <Toolbar id={"drawer"} sx={(theme) => navbarMobileContainer(theme)}>
      <MDBox color="inherit" mb={{xs: 0, md: 0}} style={{flexGrow: 1}} width={"100%"}
             sx={(theme) => navbarRow(theme, {isMini: false})}>
        <MDBox
          id={"drawer"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-around"}
          width={"100%"}
          zIndex={1}
        >
          <MDBox onClick={(event) => setOpenSidenav(dispatch, true)} id={"menu-mobile"} component="img" src={MenuIcon}
                 alt="Brand"/>
          <MDBox>
            <MDTypography style={styles.title}>{title}</MDTypography>
          </MDBox>
          <MDBox width="20px" component="img" src={BellIcon} alt="Brand" onClick={()=>{
            navigate(AuthenticatedPaths.notifications)
          }}/>
        </MDBox>
      </MDBox>
      <Box sx={styles.container} id={"routes"}>
        <MDBox sx={styles.routesContainer}>
          {IsDashboard ?
            <>
              {dashboardRoutes.map(({icon, iconRed, routeTitle, routePath, path, key, currentRef}) => {
                return (
                  // <Box id={`id-${key}`} ref={currentRef} key={key} // TODO Handle refs for scrolling
                  <Box id={`id-${key}`} key={key}
                       sx={routeTitle === title ? styles.activeRoute : styles.route}>
                    <MDBox
                      width="20px"
                      sx={{marginBottom: "8px"}}
                      component="img"
                      src={routeTitle === title ? iconRed : icon}
                      alt="Current" onClick={() => {
                      // handleScroll(currentRef); // TODO handle refs for scrolling
                      navigate(routePath);
                    }}
                    />
                  </Box>
                )
              })}
            </>
            :
            <></>
          }

        </MDBox>
      </Box>
    </Toolbar>
  )
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    width: "91.5%",
    marginX: "16px",
    overflowX: "scroll",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {display: "none"},
  },
  routesContainer: {
    display: "flex",
    width: "100%",
    mt: 1,
  },
  title: {
    color: "white",
    width: "200px",
    textAlign: "center"
  },
  activeRoute: {
    paddingX: "23px",
    display: "flex",
    borderBottom: "2px solid #D9243D"
  },
  route: {
    paddingX: "23px", display: "flex"
  }
}
