import { UserTypes } from "../constants";
import { GuestPaths } from "./guestPaths";
import { IdolPaths} from "./idolPaths";
import { IdolizerPaths} from "./idolizerPaths";
import { AdminPaths } from "./adminPaths";



export const Paths = {
  [UserTypes.Guest]:{
    ...GuestPaths
  },
  [UserTypes.Idol]:{
    ...IdolPaths
  },
  [UserTypes.Idolizer]:{
    ...IdolizerPaths
  },
  [UserTypes.Admin]:{
    ...AdminPaths
  }
}
