/* dependencies */
import React from 'react';
import PropTypes from 'prop-types';

/* MUI components */
import {Box} from '@mui/material';

/* components */
import {PrimaryButton} from 'components/PrimaryButton';
import {SecondaryButton} from 'components/SecondaryButton';

/* assets */
import {styles} from './styles';
import logo from 'assets/icons/small_logo.svg';

export const MobileCardEventActionsBox = ({
                                      isWide,
                                      dismissAction,
                                      approveAction,
                                      approveText,
                                      dismissText,
                                      containerStyles,
                                      approveStyles,
                                      dismissStyles,
                                      approveTextStyles,
                                      dismissTextStyles,
                                      primaryBtnStyle,
                                      secondaryBtnStyle,
                                      approveDisabled = false,
                                      primaryBtnSize = "medium",
                                      secondaryBtnSize = "medium",
                                    }) => {
  return (
    <Box sx={[styles.actions, { ...containerStyles }]}>
      <Box sx={isWide?{...styles.buttons_containerWide}:{...styles.buttons_container}} >
        <PrimaryButton
          sx={styles.button}
          padding={0}
          buttonStyle={primaryBtnStyle}
          text={approveText}
          onClick={approveAction}
          disabled={approveDisabled}
          size={primaryBtnSize}
          textStyles={approveTextStyles}
          {...approveStyles}
        />
        <SecondaryButton
          sx={styles.button}
          padding={0}
          size={secondaryBtnSize}
          text={dismissText}
          buttonStyle={secondaryBtnStyle}
          onClick={dismissAction}
          backgroundColor={styles.dismissButton.background}
          textStyles={{color: styles.dismissButton.color, ...dismissTextStyles}}
          borderColor={styles.dismissButton.color}
          {...dismissStyles}
        />
      </Box>
    </Box>
  );
};

MobileCardEventActionsBox.propTypes = {
  dismissAction: PropTypes.func.isRequired,
  approveAction: PropTypes.func.isRequired,
  approveText: PropTypes.string.isRequired,
  dismissText: PropTypes.string.isRequired,
  containerStyles: PropTypes.object,
  approveStyles: PropTypes.object,
  dismissStyles: PropTypes.object,
  approveTextStyles: PropTypes.object,
  dismissTextStyles: PropTypes.object,
};
